import { Set } from 'immutable';
import filledDateRange from 'app/filters/definitions/claim/filledDateRange';
import { IFilterConfiguration } from 'app/filters/types';
import adjudicationDateRange from './adjudicationDateRange';
import assignees from './assignees';
import benefitContractIds from './benefitContractIds';
import carriers from './carriers';
import quantityLimitPerDayExceeded from './claimExceedsDailyQuantityLimit';
import daySupplyLimitExceeded from './claimExceedsDaySupplyLimit';
import claimHasPriorAuth from './claimHasPriorAuth';
import claimStatus from './claimStatus';
import coordinationOfBenefitsIndicators from './cobIndicator';
import copay from './copay';
import coverageStatusSchedule from './coverageStatusSchedule';
import daysSupply from './daysSupply';
import diseaseStates from './diseaseStates';
import drugContainsOpioid from './drugContainsOpioid';
import drugLists from './drugLists';
import drugNames from './drugNames';
import exceedsSeniorSavingsModel from './exceedsSeniorSavingsModel';
import excludeBenefitContractIds from './excludeBenefitContractIds';
import excludeCarriers from './excludeCarriers';
import excludeDiseaseStates from './excludeDiseaseStates';
import excludeDrugLists from './excludeDrugLists';
import excludeDrugNames from './excludeDrugNames';
import excludeGpi from './excludeGpi';
import excludeNdc from './excludeNdc';
import excludePharmacyId from './excludePharmacyId';
import excludeRejectCodes from './excludeRejectCodes';
import formularyPriorAuthTypeCode from './formularyPriorAuthTypeCode';
import formularyRequiresPriorAuth from './formularyRequiresPriorAuth';
import formularyTiers from './formularyTiers';
import gpi from './gpi';
import groups from './groups';
import hasBeenRefilled from './hasBeenRefilled';
import hasOpioidCombinatorImpact from './hasOpioidCombinatorImpact';
import historicalOpioidPharmacies from './historicalOpioidPharmacies';
import historicalOpioidPrescribers from './historicalOpioidPrescribers';
import initialOpioidSupply from './initialOpioidSupply';
import isDrugCompound from './isDrugCompound';
import isDrugOpioidCombinator from './isDrugOpioidCombinator';
import isDrugOverTheCounter from './isDrugOverTheCounter';
import isDrugProtectedClass from './isDrugProtectedClass';
import isOnFormulary from './isOnFormulary';
import isPatientEligible from './isPatientEligible';
import isPatientInLongTermCare from './isPatientInLongTermCare';
import isPatientInPlanTransition from './isPatientInPlanTransition';
import isPatientOpioidNaive from './isPatientOpioidNaive';
import isPrimaryPayment from './isPrimaryPayment';
import licsLevels from './licsLevels';
import lineOfBusiness from './lineOfBusiness';
import matchesPaidClaim from './matchesPaidClaim';
import multiSourceCode from './multiSourceCode';
import ndc from './ndc';
import patientAge from './patientAge';
import patientHasCancer from './patientHasCancer';
import patientId from './patientId';
import patientTotalMorphineMilligramEquivalent from './patientTotalMorphineMilligramEquivalent';
import pharmacyId from './pharmacyId';
import pharmacyTypes from './pharmacyTypes';
import prescriberNpi from './prescriberNpi';
import quantityLimitEditType from './quantityLimitEditType';
import receivedTransitionFill from './receivedTransitionFill';
import rejectCodes from './rejectCodes';
import rejectMessage from './rejectMessages';
import rxNumber from './rxNumber';
import rxcui from './rxcui';
import stepTherapyRequired from './stepTherapyRequired';
import tierMismatch from './tierMismatch';
import tiers from './tiers';
import workItemStatus from './workItemStatus/workItemStatus';

export default Set<IFilterConfiguration<any, any, any, any>>([
  adjudicationDateRange,
  assignees,
  benefitContractIds,
  carriers,
  claimHasPriorAuth,
  claimStatus,
  coordinationOfBenefitsIndicators,
  copay,
  coverageStatusSchedule,
  daysSupply,
  diseaseStates,
  drugContainsOpioid,
  drugLists,
  drugNames,
  exceedsSeniorSavingsModel,
  excludeBenefitContractIds,
  excludeCarriers,
  excludeDiseaseStates,
  excludeDrugLists,
  excludeDrugNames,
  excludeGpi,
  excludeNdc,
  excludePharmacyId,
  excludeRejectCodes,
  formularyPriorAuthTypeCode,
  formularyRequiresPriorAuth,
  formularyTiers,
  filledDateRange,
  gpi,
  groups,
  hasBeenRefilled,
  hasOpioidCombinatorImpact,
  historicalOpioidPharmacies,
  historicalOpioidPrescribers,
  initialOpioidSupply,
  isDrugCompound,
  isDrugOpioidCombinator,
  isDrugOverTheCounter,
  isDrugProtectedClass,
  isOnFormulary,
  isPatientEligible,
  isPatientInLongTermCare,
  isPatientInPlanTransition,
  isPatientOpioidNaive,
  isPrimaryPayment,
  licsLevels,
  lineOfBusiness,
  matchesPaidClaim,
  multiSourceCode,
  ndc,
  patientAge,
  patientHasCancer,
  patientId,
  patientTotalMorphineMilligramEquivalent,
  pharmacyId,
  pharmacyTypes,
  prescriberNpi,
  quantityLimitEditType,
  quantityLimitPerDayExceeded,
  daySupplyLimitExceeded,
  receivedTransitionFill,
  rejectCodes,
  rejectMessage,
  rxcui,
  rxNumber,
  stepTherapyRequired,
  tierMismatch,
  tiers,
  workItemStatus,
]);
