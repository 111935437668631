import { isNil } from 'ramda';
import {
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAIL,
  TOKEN_FETCH_FAIL,
  TOKEN_REFRESH_FAIL,
  TOKEN_REFRESH_RETRY,
} from 'app/auth/constants';
import {
  BESTPRACTICES_CATEGORY_FETCH_START,
  BESTPRACTICES_CATEGORY_UPDATE_DATE,
  BESTPRACTICES_CATEGORY_FETCH_FAIL,
} from 'app/bestpractices/constants';
import { COMMENT_ADD, COMMENT_ADD_FAIL } from 'app/comments/constants';
import { UNHANDLED_ERROR } from 'app/common/constants';
import getConfig from 'app/configuration';
import { FILTERS_FETCH_FAIL } from 'app/filters/constants';
import { INTERACTION_SAVE } from 'app/interactions/constants';
import {
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_FAIL,
  CAMPAIGN_EDIT_FILTER_SET_APPLIED,
  CAMPAIGN_EDIT_FILTER_UPDATE,
  WORKITEMLIST_FILTERS_UPDATE,
  WORKITEMLIST_FILTER_FETCH_OPTIONS_FAIL,
  WORKITEMLIST_FILTER_SET_APPLIED,
} from 'app/ui/constants';
import { getUserId } from 'app/user/selectors';
import { USERS_FETCH_FAIL } from 'app/users/constants';
import {
  VIEWTEMPLATE_APPLY,
  VIEWTEMPLATE_SAVE_START,
  VIEWTEMPLATE_CREATE_START,
  VIEWTEMPLATE_QUICKSAVE_START,
  VIEWTEMPLATES_FETCH_FAIL,
  VIEWTEMPLATE_SAVE_FAIL,
  VIEWTEMPLATE_QUICKSAVE_FAIL,
} from 'app/viewtemplates/constants';
import {
  WORKITEMS_QUERY_FETCH_START,
  WORKITEM_DETAILS_FETCH_START,
  WORKITEM_SET_ASSIGNEE,
  WORKITEM_SET_STATUS,
  WORKITEMS_QUERY_FETCH_FAIL,
  WORKITEM_DETAILS_FETCH_FAIL,
  WORKITEM_UPSERT_FAIL,
  WORKITEM_STATUSES_FETCH_FAIL,
} from 'app/workitems/constants';
import { APPLICATION } from './constants';

export const blacklistActions = ['rrf/*'];

const defaultLogProperties = (state, action) => ({
  application: APPLICATION,
  actionType: action.type,
  tenantName: getConfig().TENANT_NAME,
  userId: getUserId(state),
  resolution: `${window?.screen?.width}x${window?.screen?.height}`,
  availableResolution: `${window?.screen?.availWidth}x${window?.screen?.availHeight}`,
  url: window?.location?.toString(),
  elapsedTime: isNil(action.meta) ? null : action.meta.elapsedTime,
  userAgent: navigator?.userAgent,
});

const errorLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  error: action.payload.error,
});

const unhandledErrorLogProperties = (state, action) => ({
  ...errorLogProperties(state, action),
  componentStack: action.payload.errorInfo.componentStack,
});

const userSignInSuccessLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  userId: action.payload.userId,
  email: action.payload.email,
});

const commentAddLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  workItemId: action.payload.workItemId,
});

const filtersUpdateLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  filterName: action.payload.name,
  filterValue:
    action.payload.value === Object(action.payload.value)
      ? action.payload.value
      : action.payload.value,
});

const filtersComponentSetAppliedLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  filterName: action.payload.name,
  filterIsApplied: action.payload.isApplied,
});

const workItemsQueryFetchStartLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  filters: action.payload.filter,
  hasContinuationToken:
    action.payload.continuationToken !== null &&
    action.payload.continuationToken !== undefined,
});

const workItemDetailsFetchStartLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  workItemId: action.payload.id,
});

const workItemSetAssigneeLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  workItemId: action.payload.id,
  assigneeUserId: action.payload.userId,
});

const workItemSetStatusLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  workItemId: action.payload.id,
  status: action.payload.status,
});

const viewTemplateLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  viewTemplate: action.payload.viewTemplate,
});

const retryLogProperties = (state, action) => ({
  ...errorLogProperties(state, action),
  retryCount: action.payload.retryCount,
});

const bestPracticeLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  category: action.payload.category,
});

const bestPracticeDateRangeProperties = (state, action) => ({
  ...bestPracticeLogProperties(state, action),
  startDate: action.payload.dateRange.start,
  endDate: action.payload.dateRange.end,
});

const interactionLogProperties = (state, action) => ({
  ...defaultLogProperties(state, action),
  status: action.payload.interaction.status,
  id: action.payload.id,
});

export const printLogProperties = (state, location) => ({
  application: APPLICATION,
  tenantName: getConfig().TENANT_NAME,
  userId: getUserId(state),
  location,
});

export const getLogProperties = (currentState, action) => {
  switch (action.type) {
    case USER_SIGN_IN_SUCCESS: {
      return userSignInSuccessLogProperties(currentState, action);
    }
    case COMMENT_ADD: {
      return commentAddLogProperties(currentState, action);
    }
    case WORKITEMLIST_FILTERS_UPDATE:
    case CAMPAIGN_EDIT_FILTER_UPDATE: {
      return filtersUpdateLogProperties(currentState, action);
    }
    case WORKITEMLIST_FILTER_SET_APPLIED:
    case CAMPAIGN_EDIT_FILTER_SET_APPLIED: {
      return filtersComponentSetAppliedLogProperties(currentState, action);
    }
    case VIEWTEMPLATE_APPLY:
    case VIEWTEMPLATE_SAVE_START:
    case VIEWTEMPLATE_CREATE_START:
    case VIEWTEMPLATE_QUICKSAVE_START: {
      return viewTemplateLogProperties(currentState, action);
    }
    case WORKITEMS_QUERY_FETCH_START: {
      return workItemsQueryFetchStartLogProperties(currentState, action);
    }
    case WORKITEM_DETAILS_FETCH_START: {
      return workItemDetailsFetchStartLogProperties(currentState, action);
    }
    case WORKITEM_SET_ASSIGNEE: {
      return workItemSetAssigneeLogProperties(currentState, action);
    }
    case WORKITEM_SET_STATUS: {
      return workItemSetStatusLogProperties(currentState, action);
    }
    case USER_SIGN_IN_FAIL:
    case TOKEN_FETCH_FAIL:
    case TOKEN_REFRESH_FAIL:
    case COMMENT_ADD_FAIL:
    case FILTERS_FETCH_FAIL:
    case WORKITEMLIST_FILTER_FETCH_OPTIONS_FAIL:
    case CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_FAIL:
    case USERS_FETCH_FAIL:
    case VIEWTEMPLATES_FETCH_FAIL:
    case VIEWTEMPLATE_SAVE_FAIL:
    case VIEWTEMPLATE_QUICKSAVE_FAIL:
    case WORKITEMS_QUERY_FETCH_FAIL:
    case WORKITEM_DETAILS_FETCH_FAIL:
    case WORKITEM_UPSERT_FAIL:
    case WORKITEM_STATUSES_FETCH_FAIL:
    case BESTPRACTICES_CATEGORY_FETCH_FAIL: {
      return errorLogProperties(currentState, action);
    }
    case UNHANDLED_ERROR: {
      return unhandledErrorLogProperties(currentState, action);
    }
    case TOKEN_REFRESH_RETRY: {
      return retryLogProperties(currentState, action);
    }
    case BESTPRACTICES_CATEGORY_FETCH_START: {
      return bestPracticeLogProperties(currentState, action);
    }
    case BESTPRACTICES_CATEGORY_UPDATE_DATE: {
      return bestPracticeDateRangeProperties(currentState, action);
    }
    case INTERACTION_SAVE: {
      return interactionLogProperties(currentState, action);
    }
    default: {
      return defaultLogProperties(currentState, action);
    }
  }
};
