import { Record } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import { booleanFilterDefaults } from 'app/filters/definitions/defaults';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class PatientHasCancerConfig
  extends Record({
    ...booleanFilterDefaults,
    name: 'patientHasCancer',
    title: 'Patient Has Cancer',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: boolean) => {
      const { claim } = workItem;
      if (isNil(claim.patientId)) return false;

      if (value === true) return claim.patientHasCancer === true;
      else
        return (
          isNil(claim.patientHasCancer) || claim.patientHasCancer === false
        );
    },
  })
  implements IClaimFilterConfiguration<boolean, undefined, boolean, boolean>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new PatientHasCancerConfig();
