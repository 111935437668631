import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import TextComparison from 'app/filters/components/TextComparison';
import { numericOperatorConfig, IssueFilterType } from 'app/filters/constants';
import { numericComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  handleNumericComparisonOnChange,
  numericToTextComparisonConverter,
} from 'app/filters/definitions/utility';
import {
  NumericComparison,
  NumericComparisonBackendRecord,
  IClaimFilterConfiguration,
  NumericOperator,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';
import { WorkItemRecord } from 'app/workitems/types';

const summaryFormatter = (value: NumericComparison) => {
  if (isNil(value.value)) return 'All';
  return `${numericOperatorConfig(value.operator.value).summary} 
    ${value.value} years old`;
};

class AgeConfig
  extends Record({
    ...numericComparisonDefaults,
    name: 'patientAge',
    title: 'Patient Age',
    storedValueType: StoredFilterType.NumericComparison,
    targetType: IssueFilterType.Claim,
    summaryFormatter,
    component: (props: any) => (
      <TextComparison
        {...props}
        inputType="number"
        placeholder="Enter age"
        onChange={handleNumericComparisonOnChange(props)}
        value={numericToTextComparisonConverter(props.value)}
        min="0"
      />
    ),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: NumericComparisonBackendRecord,
    ) => {
      if (isNil(value.number)) return true;
      if (isNil(workItem.claim.patientAge)) return false;
      const operator = numericOperatorConfig(value.operator);
      if (isNil(operator)) throw new Error('Unknown operator');

      return (operator.filter as (a: number, b: number) => boolean)(
        workItem.claim.patientAge,
        value.number,
      );
    },
  })
  implements
    IClaimFilterConfiguration<
      NumericComparison,
      OrderedSet<NumericOperator>,
      NumericComparisonBackendRecord,
      NumericComparisonBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new AgeConfig();
