import { filter, pipe } from 'ramda';
import { WorkflowTypes } from './types';

const getWorkflowDefinitionState = (state) => state.workflowState;

const getWorkflowDefinitions = (state) =>
  getWorkflowDefinitionState(state).workflowDefinitions;

export const getCaseWorkflowDefinitions = pipe(
  getWorkflowDefinitions,
  filter((workflow) => workflow.workflowType === WorkflowTypes.Case),
  (x) => x.toSet(),
);
