import {
  QUEUE_COMPLETE_INTERACTION,
  QUEUE_COMPLETE_INTERACTION_FAIL,
  QUEUE_COMPLETE_INTERACTION_SUCCESS,
  QUEUE_FETCH_NEXT_SESSION,
  QUEUE_FETCH_NEXT_SESSION_FAIL,
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION,
  QUEUE_SKIP_SESSION_FAIL,
  QUEUE_SKIP_SESSION_SUCCESS,
  QUEUE_FETCH_STATS,
  QUEUE_FETCH_STATS_SUCCESS,
  QUEUE_FETCH_STATS_FAIL,
  QUEUE_FETCH_CONTEXT_FOR_CONTACT,
  QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS,
  QUEUE_FETCH_CONTEXT_FOR_CONTACT_FAIL,
  QUEUE_SESSION_DISMOUNT,
} from './constants';

export function fetchNextSessionInQueue() {
  return {
    type: QUEUE_FETCH_NEXT_SESSION,
    payload: {},
  };
}

export function fetchNextSessionInQueueSuccess(data) {
  return {
    type: QUEUE_FETCH_NEXT_SESSION_SUCCESS,
    payload: { data },
  };
}

export function fetchNextSessionInQueueFail(error) {
  return {
    type: QUEUE_FETCH_NEXT_SESSION_FAIL,
    payload: { error },
  };
}

export function completeInteraction(interaction) {
  return {
    type: QUEUE_COMPLETE_INTERACTION,
    payload: { interaction },
  };
}

export function completeInteractionSuccess() {
  return {
    type: QUEUE_COMPLETE_INTERACTION_SUCCESS,
    payload: {},
  };
}

export function completeInteractionFail(error) {
  return {
    type: QUEUE_COMPLETE_INTERACTION_FAIL,
    payload: { error },
  };
}

export function skipSessionInQueue(id) {
  return {
    type: QUEUE_SKIP_SESSION,
    payload: { id },
  };
}

export function skipSessionInQueueSuccess(id, data) {
  return {
    type: QUEUE_SKIP_SESSION_SUCCESS,
    payload: { id, data },
  };
}

export function skipSessionInQueueFail(id, error) {
  return {
    type: QUEUE_SKIP_SESSION_FAIL,
    payload: { id, error },
  };
}

export function fetchQueueStats() {
  return {
    type: QUEUE_FETCH_STATS,
    payload: {},
  };
}

export function fetchQueueStatsSuccess(stats) {
  return {
    type: QUEUE_FETCH_STATS_SUCCESS,
    payload: { stats },
  };
}

export function fetchQueueStatsFail(error) {
  return { type: QUEUE_FETCH_STATS_FAIL, payload: { error } };
}

export function fetchContextForContact(id) {
  return {
    type: QUEUE_FETCH_CONTEXT_FOR_CONTACT,
    payload: { id },
  };
}

export function fetchContextForContactSuccess(id, data) {
  return {
    type: QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS,
    payload: { id, data },
  };
}

export function fetchContextForContactFail(id, error) {
  return {
    type: QUEUE_FETCH_CONTEXT_FOR_CONTACT_FAIL,
    payload: { id, error },
  };
}

export function clearSessionLock() {
  return {
    type: QUEUE_SESSION_DISMOUNT,
    payload: {},
  };
}
