import { Skeleton } from '@material-ui/lab';
import styles from './OutreachSession.css';

const LoadingOutreachSession = () => (
  <div className={styles.container}>
    <div className={styles.caseSelectorContainer}>
      <Skeleton
        variant="rect"
        width="100%"
        height={140}
        classes={{ root: styles.loadingContact }}
      />
    </div>
    <div className={styles.interactionContainer}>
      <div className={styles.loadingStepper}>
        <Skeleton variant="circle" width={30} height={30} />
        <Skeleton variant="circle" width={30} height={30} />
        <Skeleton variant="circle" width={30} height={30} />
        <Skeleton variant="circle" width={30} height={30} />
      </div>
      <div className={styles.loadingContent}>
        <Skeleton variant="text" width="100%" height={70} />
        <Skeleton variant="rect" width="100%" height={70} />
      </div>
      <div className={styles.interactionFooter}>
        <div className={styles.interactionButtons}>
          <Skeleton
            variant="rect"
            height={50}
            classes={{ root: styles.loadingButton }}
          />
          <Skeleton
            variant="rect"
            height={50}
            classes={{ root: styles.loadingButton }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default LoadingOutreachSession;
