import {
  subDays,
  startOfWeek,
  startOfDay,
  startOfMonth,
  endOfDay,
  endOfWeek,
  endOfMonth,
  differenceInCalendarMonths,
  isSameMonth,
  isWithinInterval,
  isSameWeek,
  parseISO,
} from 'date-fns';
import { Set, Map } from 'immutable';
import {
  prop,
  pipe,
  mean,
  isNil,
  groupBy,
  reduce,
  reduceBy,
  curry,
  map,
  toPairs,
  flatten,
  filter,
  sum,
  path,
  defaultTo,
} from 'ramda';
import { createSelector } from 'reselect';
import { Interval } from '../common/types';
import { mapToValuesArray } from '../common/utilities/generic';
import { getSelectedDate } from '../ui/selectors';
import { AGGREGATION_PERIODS } from './constants';
import {
  RiskModifierResultsRecord,
  BestPracticeRuleSummarizedRecord,
  RiskRecord,
  CategoryRiskRecord,
  BestPracticeCategoryPageRecord,
  BestPracticeRuleResultsRecord,
  RiskScoreRecord,
} from './types';
import { aggregateSummaries, bestPracticeHistoricDateRange } from './utilities';

const { ceil } = Math;

const toMapById = pipe(
  map((x) => [x.id, x]),
  Map,
);

// Simple Selectors
const getBestPracticeState = prop('bestPracticeState');
const getBestPracticeCategories = pipe(
  getBestPracticeState,
  prop('categories'),
);

const getBestPractices = pipe(getBestPracticeState, prop('bestPractices'));
const getBestPracticeRules = pipe(getBestPracticeState, prop('rules'));
const getCategory = (state, id) => getBestPracticeCategories(state).get(id);

const getRulesForCategory = createSelector(
  getCategory,
  getBestPractices,
  getBestPracticeRules,
  (category, bestPractices, rules) => {
    if (isNil(category)) return null;
    return category.bestPractices
      .map((id) => bestPractices.get(id))
      .map((bp) => bp.rules)
      .flatten()
      .map((rule) => rules.get(rule));
  },
);

// Risk Helpers
const filterDailyResultsByDateRange = curry((dateRange, days) =>
  days.filter((day) => isWithinInterval(day.date, dateRange)),
);
const filterRulesByDate = curry((dateRange, rules) =>
  rules.map((rule) =>
    pipe(
      filterDailyResultsByDateRange(dateRange),
      mapToValuesArray,
    )(rule.dailyResults),
  ),
);

const getLast30DayResults = createSelector(getBestPracticeRules, (rules) => {
  const dateRange = new Interval({
    start: startOfDay(subDays(new Date(), 30)),
    end: endOfDay(new Date()),
  });

  return filterRulesByDate(dateRange, rules);
});

const calculateScore = curry((dayStats) => {
  const sumStats = (stats) =>
    stats.notReviewed * 10 + stats.appropriate * 2 + stats.inappropriate * 5;

  const baseScore = sumStats(dayStats);
  const modifiersScore = dayStats.riskModifiers.reduce(
    (acc, x) => acc + sumStats(x) * 2,
    0,
  );

  return baseScore + modifiersScore;
});

const getBestPracticesByCategory = createSelector(
  getBestPracticeCategories,
  getBestPractices,
  (categories, bestPractices) =>
    categories.map((category) => {
      const applicableBestPractices = bestPractices.filter((bestPractice) =>
        category.bestPractices.includes(bestPractice.id),
      );

      return category.merge({
        bestPractices: applicableBestPractices,
      });
    }),
);

const riskScoreAccumulator = (acc, val) => {
  if (isNil(val)) return acc;
  const riskAcc = new RiskScoreRecord(acc);

  return riskAcc.merge({
    score: riskAcc.score + val.riskScore.score,
    max: riskAcc.max + val.riskScore.max,
    min: riskAcc.min + val.riskScore.min,
  });
};

const groupDateBy = curry((grpBy, x) =>
  pipe(prop('date'), grpBy, (date) => date.toISOString())(x),
);

const riskRecordFactory = curry(
  (period, date, score) =>
    new RiskRecord({
      date,
      period,
      riskScore: score,
    }),
);

const pairToRiskRecord = curry((period, pair) =>
  riskRecordFactory(period, parseISO(pair[0]), pair[1]),
);

const getMaxRisk = (x) =>
  new BestPracticeRuleResultsRecord({
    date: x.date,
    notReviewed: x.notReviewed + x.inappropriate + x.appropriate,
    appropriate: 0,
    inappropriate: 0,
    riskModifiers: map(
      (y) =>
        new RiskModifierResultsRecord({
          id: y.id,
          notReviewed: y.notReviewed + y.inappropriate + y.appropriate,
          appropriate: 0,
          inappropriate: 0,
        }),
    )(x.riskModifiers),
  });

const getMinRisk = (x) =>
  new BestPracticeRuleResultsRecord({
    date: x.date,
    notReviewed: 0,
    appropriate: x.appropriate,
    inappropriate: x.notReviewed + x.inappropriate,
    riskModifiers: map(
      (y) =>
        new RiskModifierResultsRecord({
          id: y.id,
          notReviewed: 0,
          appropriate: y.appropriate,
          inappropriate: y.notReviewed + y.inappropriate,
        }),
    )(x.riskModifiers),
  });

// day => risk
const getRiskScore = (dayStats) => {
  const riskScore = calculateScore(dayStats);
  const maxRiskScore = calculateScore()(getMaxRisk(dayStats));
  const minRiskScore = calculateScore()(getMinRisk(dayStats));

  return new RiskRecord({
    date: dayStats.date,
    period: AGGREGATION_PERIODS.DAILY,
    riskScore: new RiskScoreRecord({
      score: riskScore,
      max: maxRiskScore,
      min: minRiskScore,
    }),
  });
};

// [risk] => score
const sumRiskScores = reduce(riskScoreAccumulator, 0);

const avgScoresBy = curry((g) => pipe(map(g), mean, ceil, defaultTo(0)));

const avgRiskScores = (risk) => {
  return new RiskScoreRecord({
    score: avgScoresBy(path(['riskScore', 'score']))(risk),
    min: avgScoresBy(path(['riskScore', 'min']))(risk),
    max: avgScoresBy(path(['riskScore', 'max']))(risk),
  });
};

// [risk] => ((f) => {string: score})
const sumRiskScoresBy = reduceBy(riskScoreAccumulator, new RiskScoreRecord());

const avgRiskScoresBy = curry((g) => pipe(groupBy(g), map(avgRiskScores)));
// [risk] => [risk]
const reduceScoresByDay = pipe(
  sumRiskScoresBy(groupDateBy(endOfDay)),
  toPairs,
  map(pairToRiskRecord(AGGREGATION_PERIODS.DAILY)),
);

const reduceScoresByWeek = pipe(
  avgRiskScoresBy(groupDateBy(endOfWeek)),
  toPairs,
  map(pairToRiskRecord(AGGREGATION_PERIODS.WEEKLY)),
);

const reduceScoresByMonth = pipe(
  avgRiskScoresBy(groupDateBy(endOfMonth)),
  toPairs,
  map(pairToRiskRecord(AGGREGATION_PERIODS.MONTHLY)),
);

// [day] => [risk]
const scoresByDay = pipe(map(getRiskScore), reduceScoresByDay);

const scoresByWeek = pipe(
  map(getRiskScore),
  reduceScoresByDay,
  reduceScoresByWeek,
);

const scoresByMonth = pipe(
  map(getRiskScore),
  reduceScoresByDay,
  reduceScoresByMonth,
);

const historicalRisk = (dailyStats) => {
  const today = new Date();
  // monthly
  const monthlyAverageRisk = pipe(
    filter((x) => differenceInCalendarMonths(today, x.date) > 0),
    scoresByMonth,
  )(dailyStats);

  // weekly
  const dayIsInWeekOfThisMonth = (x) =>
    (isSameMonth(today, x.date) || isSameWeek(x.date, startOfMonth(today))) &&
    startOfWeek(today) > x.date;

  const weeklyAverageRisk = pipe(
    filter(dayIsInWeekOfThisMonth),
    scoresByWeek,
  )(dailyStats);

  // last 7 days
  const last7DaysRisk = pipe(
    filter((x) =>
      isWithinInterval(x.date, { start: subDays(today, 7), end: today }),
    ),
    scoresByDay,
    avgRiskScores,
    riskRecordFactory(AGGREGATION_PERIODS.LAST_7_DAYS, endOfDay(today)),
  )(dailyStats);

  // Projected
  const projectedRisk = pipe(
    filter((x) =>
      isWithinInterval(x.date, { start: subDays(today, 30), end: today }),
    ),
    scoresByDay,
    avgRiskScores,
    riskRecordFactory(AGGREGATION_PERIODS.PROJECTED, endOfMonth(today)),
  )(dailyStats);

  return new Set(monthlyAverageRisk)
    .concat(weeklyAverageRisk)
    .add(last7DaysRisk)
    .add(projectedRisk);
};

// UI Selectors
export const bestPracticeHistoricalRiskByRule = createSelector(
  getRulesForCategory,
  (rules) => {
    if (isNil(rules)) return null;

    const dateRange = bestPracticeHistoricDateRange();

    return pipe(
      toMapById,
      filterRulesByDate(dateRange),
      map(historicalRisk),
    )(rules);
  },
);

export const getCurrentRisk = createSelector(
  getLast30DayResults,
  pipe(mapToValuesArray, flatten, scoresByDay, avgRiskScores),
);

export const getBestPracticesHistoricalRisk = createSelector(
  getBestPracticeRules,
  (rules) => {
    if (rules.isEmpty()) return new Set();

    const dateRange = bestPracticeHistoricDateRange();
    return pipe(
      filterRulesByDate(dateRange),
      mapToValuesArray,
      flatten,
      historicalRisk,
    )(rules);
  },
);

export const getCurrentRiskByCategory = createSelector(
  getBestPracticesByCategory,
  getLast30DayResults,
  (categories, dailyResults) =>
    categories
      .map((category) => {
        const applicableRules = category.bestPractices
          .map((bp) => bp.rules)
          .flatten()
          .toSet();

        const rules = flatten(
          mapToValuesArray(
            dailyResults.filter((val, key) => applicableRules.includes(key)),
          ),
        );

        return new CategoryRiskRecord({
          id: category.id,
          name: category.name,
          riskScore: avgRiskScores(scoresByDay(rules)),
        });
      })
      .toSet(),
);

export const getBestPracticeCategory = createSelector(
  getCategory,
  getBestPractices,
  getRulesForCategory,
  getSelectedDate,
  (category, bestPractices, rules, selectedDate) => {
    const applicableBestPractices = bestPractices.filter((bestPractice) =>
      category.bestPractices.includes(bestPractice.id),
    );

    if (category && applicableBestPractices) {
      const riskScores = pipe(
        toMapById,
        filterRulesByDate(selectedDate),
        map(scoresByDay),
        map(avgRiskScores),
      )(rules);

      const mergedBestPratices = applicableBestPractices
        .map((bestPractice) =>
          bestPractice.merge({
            rules: rules
              .filter((rule) => bestPractice.rules.includes(rule.id))
              .map((rule) => {
                // get days selected
                const applicableDays = filterDailyResultsByDateRange(
                  selectedDate,
                  rule.dailyResults,
                );

                // get a list of all modifiers
                const riskModifierIds = applicableDays
                  .map((day) => day.riskModifiers.keySeq())
                  .flatten()
                  .toSet();

                // summarize modifiers
                const modifiers = riskModifierIds.map((id) => {
                  const summaries = aggregateSummaries(
                    applicableDays.map((day) => day.riskModifiers.get(id)),
                  );
                  return new RiskModifierResultsRecord({
                    id,
                    appropriate: summaries.appropriate,
                    inappropriate: summaries.inappropriate,
                    notReviewed: summaries.notReviewed,
                  });
                });

                // summarize rule
                const aggregatedRule = aggregateSummaries(applicableDays);
                return new BestPracticeRuleSummarizedRecord({
                  description: rule.description,
                  id: rule.id,
                  riskModifiers: modifiers,
                  appropriate: aggregatedRule.appropriate,
                  inappropriate: aggregatedRule.inappropriate,
                  notReviewed: aggregatedRule.notReviewed,
                  riskScore: riskScores.get(rule.id),
                });
              })
              .toSet(),
          }),
        )
        .toSet();
      return new BestPracticeCategoryPageRecord({
        categoryRiskScore: pipe(
          mapToValuesArray,
          map(prop('score')),
          sum,
        )(riskScores),
        category: category.merge({
          bestPractices: mergedBestPratices,
        }),
      });
    }
    return new BestPracticeCategoryPageRecord();
  },
);
