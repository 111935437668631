import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import MultiSelect from 'app/filters/components/MultiSelect';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import { coverageStatusSchedulesQuery } from 'app/filters/queries';
import {
  IFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(
    coverageStatusSchedulesQuery,
    { id: 'v1:CoverageStatusSchedule:Reference' },
    token,
  )
    .then(throwIfErrors)
    .then((response) => response.data.node.coverageStatusSchedules)
    .then((coverageStatusSchedules: Array<{ key: string; value: string }>) =>
      OrderedSet(
        coverageStatusSchedules.map(
          (t) =>
            new SelectValueRecord({
              text: t.key,
              value: t.value,
            }),
        ),
      ),
    );

class DrugCoverageStatusConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'coverageStatusSchedule',
    title: 'Drug Coverage Status',
    targetType: IssueFilterType.Claim,
    options: (token: string) => fetchOptions(token),
    component: (props: any) => <MultiSelect {...props} />,
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.coverageStatusSchedule)) return false;

      return value.contains(workItem.claim.coverageStatusSchedule);
    },
  })
  implements
    IFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new DrugCoverageStatusConfig();
