import { Checkbox } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';

const formatDate = (date) => format(date, 'MM/dd/yyyy');

const columnDefinitions = (onChange, selected) => [
  {
    id: 'IssueSelect',
    name: 'Select',
    valueSelector: (c) => c.id,
    render: ({ id }) => (
      <Checkbox
        onChange={(e) => onChange(id, e.target.checked)}
        checked={selected.includes(id)}
      />
    ),
    width: 0.25,
  },
  {
    id: 'IssueNumber',
    name: 'Issue Number',
    valueSelector: (c) => c.number,
    render: ({ number }) => number,
    width: 1,
  },
  {
    id: 'Campaign',
    name: 'Campaign',
    valueSelector: (c) => c.campaign.name,
    render: (c) => c.campaign.name,
    width: 2,
  },
  {
    id: 'CreatedDate',
    name: 'Date Created',
    valueSelector: (c) => c.createdDate,
    render: (c) => formatDate(c.createdDate),
    width: 1,
  },
  {
    id: 'Target',
    name: 'Target',
    valueSelector: (c) => c.target,
    render: (c) => c.target?.name,
    width: 1,
  },
];

export default columnDefinitions;
