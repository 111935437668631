import { formatDuration, formatISODuration } from 'date-fns';
import { OrderedSet, Record, Set } from 'immutable';
import { DurationRecord, parseISODuration } from 'app/common/duration';
import { pluralize } from 'app/common/utilities/generic';
import DosingSchedule from 'app/filters/components/DosingSchedule';
import { IssueFilterType } from 'app/filters/constants';
import { commonClassDefaultsFactory } from 'app/filters/definitions/defaults';
import {
  DosingScheduleBackendRecord,
  DosingScheduleFilterBackendRecord,
  DosingScheduleFilterRecord,
  DosingScheduleRecord,
  IFilterConfiguration,
  KeyValueRecord,
  SelectValueRecord,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';

const receivedFormat = (exceeds: boolean) => (exceeds ? 'not' : '');

class VaccineDosingScheduleConfig
  extends Record({
    ...commonClassDefaultsFactory(DosingScheduleFilterRecord),
    name: 'vaccineDosingSchedule',
    title: 'Vaccine Dosing Schedule',
    targetType: IssueFilterType.Patient,
    component: (props: any) => <DosingSchedule {...props} />,
    storedValueType: StoredFilterType.DosingSchedule,
    storedValueConstructor: (value: {
      exceedsSchedule: boolean;
      dosingSchedule: {
        gpiPrefixes: { key: string; value: string }[];
        dosingInterval: string;
        maxDoses: null | number;
      };
    }) => {
      return new DosingScheduleFilterBackendRecord({
        exceedsSchedule: value.exceedsSchedule,
        dosingSchedule: new DosingScheduleBackendRecord({
          gpiPrefixes: OrderedSet(
            value.dosingSchedule.gpiPrefixes.map(
              (x) =>
                new KeyValueRecord({
                  key: x.key,
                  value: x.value,
                }),
            ),
          ),
          dosingInterval: value.dosingSchedule.dosingInterval,
          maxDoses: value.dosingSchedule.maxDoses,
        }),
      });
    },
    templateToFilterValueConverter: (
      value: DosingScheduleFilterBackendRecord,
    ) =>
      new DosingScheduleFilterRecord({
        exceedsSchedule: value.exceedsSchedule,
        dosingSchedule: new DosingScheduleRecord({
          maxDoses: value.dosingSchedule.maxDoses,
          dosingInterval:
            value.dosingSchedule.dosingInterval !== null
              ? parseISODuration(value.dosingSchedule.dosingInterval)
              : null,
          gpiPrefixes: value.dosingSchedule.gpiPrefixes.map(
            (p) =>
              new SelectValueRecord({
                text: p.key,
                value: p.value,
              }),
          ),
        }),
      }),
    filterToTemplateValueConverter: (value: DosingScheduleFilterRecord) =>
      new DosingScheduleFilterBackendRecord({
        exceedsSchedule: value.exceedsSchedule,
        dosingSchedule: new DosingScheduleBackendRecord({
          maxDoses: value.dosingSchedule.maxDoses,
          dosingInterval:
            value.dosingSchedule.maxDoses === 1
              ? null
              : formatISODuration(
                  value.dosingSchedule.dosingInterval as DurationRecord,
                ),
          gpiPrefixes: value.dosingSchedule.gpiPrefixes
            .map(
              (p) =>
                new KeyValueRecord({
                  key: p.text,
                  value: p.value,
                }),
            )
            .toOrderedSet(),
        }),
      }),
    defaultValue: () =>
      new DosingScheduleFilterRecord({
        exceedsSchedule: true,
        dosingSchedule: new DosingScheduleRecord({
          gpiPrefixes: Set(),
          maxDoses: 2,
          dosingInterval: new DurationRecord({
            days: 30,
          }),
        }),
      }),
    summaryFormatter: (value: DosingScheduleFilterRecord) => {
      let summary = `Has ${receivedFormat(value.exceedsSchedule)} received`;
      summary +=
        value.dosingSchedule.maxDoses === null
          ? ` recurring dose`
          : ` ${value.dosingSchedule.maxDoses} ${pluralize(
              'dose',
              value.dosingSchedule.maxDoses,
            )}`;

      if (value.dosingSchedule.maxDoses !== 1) {
        summary += ` with ${formatDuration(
          value.dosingSchedule.dosingInterval as DurationRecord,
        )} between doses`;
      }
      summary += ` for ${pluralize(
        'GPI',
        value.dosingSchedule.gpiPrefixes.count(),
      )} ${value.dosingSchedule.gpiPrefixes.map((x) => x.text).join(', ')}`;

      return summary;
    },
  })
  implements
    IFilterConfiguration<
      DosingScheduleFilterRecord,
      undefined,
      DosingScheduleFilterRecord,
      DosingScheduleFilterBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new VaccineDosingScheduleConfig();
