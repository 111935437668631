import { curry, prop, pipe, isNil } from 'ramda';
import { InteractionRecord } from './types';

const getInteractionsState = prop('interactionsState');
const getInteractions = pipe(getInteractionsState, prop('interactions'));
const getOptimisticInteractions = pipe(
  getInteractionsState,
  prop('optimistic'),
);

const getInteractionFieldDefinitions = pipe(
  getInteractionsState,
  prop('interactionFieldDefinitions'),
);

export const getPhoneInteractionFieldDefinitions = (contactType, state) =>
  pipe(getInteractionFieldDefinitions, prop('phone'))(state).get(contactType);

export const getInteraction = curry((state, id) => {
  const interaction = getInteractions(state).get(id);
  const optimistic = getOptimisticInteractions(state)
    .get(id)
    ?.updates.toObject();

  if (isNil(interaction) && isNil(optimistic)) {
    return null;
  }

  return (interaction ?? new InteractionRecord()).merge(optimistic);
});
