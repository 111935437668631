import { FetchRecord } from '../types';
import { getFetchKeyForAction } from '../utility';

const includeActions = ['_FETCH', '_UPSERT', '_DELETE'];

const reducer = (state = new FetchRecord(), action) => {
  const matchResult = includeActions.filter((x) => action.type.indexOf(x) >= 0);

  if (matchResult.length === 1) {
    const key = getFetchKeyForAction(action);

    if (action.type.endsWith('_START')) {
      return state.merge({
        isRunning: state.isRunning.add(key),
        error: state.error.delete(key),
      });
    }
    if (action.type.endsWith('_SUCCESS')) {
      return state.merge({
        isRunning: state.isRunning.delete(key),
        hasCompleted: state.hasCompleted.add(key),
      });
    }
    if (action.type.endsWith('_FAIL')) {
      return state.merge({
        isRunning: state.isRunning.delete(key),
        hasCompleted: state.hasCompleted.delete(key),
        error: state.error.set(key, action.payload?.error),
      });
    }
  }
  return state;
};

export default reducer;
