import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { flatten } from 'ramda';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { userHasPermissions } from '../../user/selectors';

export const Authorize = ({
  userIsAuthorized,
  children,
  renderOnUnauthorize,
}) => (userIsAuthorized ? children : renderOnUnauthorize);

Authorize.propTypes = {
  children: PropTypes.node.isRequired,
  renderOnUnauthorize: PropTypes.node,
  permissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Authorize.defaultProps = {
  renderOnUnauthorize: <></>,
};

export default connect((state, props) => ({
  userIsAuthorized: userHasPermissions(state, ...flatten([props.permissions])),
}))(Authorize);
