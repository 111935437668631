import { Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { v4 as uuid } from 'uuid';
import config from './config.json';

const Configuration = Record({
  LOG_TO_CONSOLE: false,
  AUTH_REDIRECT_URL_BASE: null,
  AUTH_REDIRECT_CLIENT_ID: null,
  AUTH_AUDIENCE: null,
  TENANT_NAME: null,
  DISABLED_FILTERS: new Set(),
  EXCEL_EXPORT_ENABLED: false,
  API_BASE_URL: '/api',
  CONTEXT_ID: null,
});

function feelsOctopus(value) {
  // Until Octopus Deploy learns what a bool is, we have to do this
  if (!isNil(value)) {
    return value !== 'False' && value !== 'false';
  }
  return false; // default false
}

export default function getConfiguration() {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    return new Configuration({
      LOG_TO_CONSOLE: true,
      AUTH_REDIRECT_URL_BASE: 'https://tricast-dev.auth0.com', // dev auth url
      AUTH_REDIRECT_CLIENT_ID: 'GH094Q1sJHZxM6uLcqNePGoAbmAvqU18', // dev id
      // AUTH_REDIRECT_URL_BASE: 'https://tricast-qa.auth0.com', // qa auth url
      // AUTH_REDIRECT_CLIENT_ID: 'VSGT2yDwDnVz1ay8Q66vIdQ8W9ST9Xv0', // qa id
      AUTH_AUDIENCE: 'urn:reveal-api',
      TENANT_NAME: 'contoso',
      EXCEL_EXPORT_ENABLED: true,
      API_BASE_URL: 'https://contoso-dev.tcreveal.com/api', // dev services
      // API_BASE_URL: 'https://contoso-qa.tcreveal.com/api', // qa services
      // API_BASE_URL: 'http://localhost:3000/api', // local services
      CONTEXT_ID: uuid(),
    });
  }
  return new Configuration({
    ...config,
    EXCEL_EXPORT_ENABLED: feelsOctopus(config.EXCEL_EXPORT_ENABLED),
    DISABLED_FILTERS: config.DISABLED_FILTERS.split(','),
    CONTEXT_ID: uuid(),
  });
}
