import getConfig from '../configuration';

export const AUTH_STORAGE_KEY_TOKEN_ID = 'AUTH/AUTH_STORAGE_KEY_TOKEN_ID';
export const AUTH_STORAGE_KEY_TOKEN_ACCESS =
  'AUTH/AUTH_STORAGE_KEY_TOKEN_ACCESS';
export const AUTH_STORAGE_KEY_STATE = 'AUTH/AUTH_STORAGE_KEY_STATE';
export const AUTH_STORAGE_KEY_VERIFIER = 'AUTH/AUTH_STORAGE_KEY_VERIFIER';

export const USER_SIGN_IN = 'AUTH/USER_SIGN_IN';
export const USER_SIGN_IN_SUCCESS = 'AUTH/USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAIL = 'AUTH/USER_SIGN_IN_FAIL';

export const USER_SIGN_OUT = 'AUTH/USER_SIGN_OUT';
export const USER_IS_NOT_AUTHENTICATED = 'AUTH/USER_IS_NOT_AUTHENTICATED';
export const USER_INACTIVE = 'AUTH/USER_INACTIVE';
export const USER_INACTIVE_SIGN_OUT = 'AUTH/USER_INACTIVE_SIGN_OUT';

export const TOKEN_FETCH_START = 'AUTH/TOKEN_FETCH_START';
export const TOKEN_FETCH_SUCCESS = 'AUTH/TOKEN_FETCH_SUCCESS';
export const TOKEN_FETCH_FAIL = 'AUTH/TOKEN_FETCH_FAIL';

export const TOKEN_REFRESH_RETRY = 'AUTH/TOKEN_REFRESH_RETRY';
export const TOKEN_REFRESH_FAIL = 'AUTH/TOKEN_REFRESH_FAIL';

export const {
  AUTH_REDIRECT_URL_BASE,
  AUTH_REDIRECT_CLIENT_ID,
  AUTH_AUDIENCE,
} = getConfig();

export const AUTH_REDIRECT_TOKEN_URL = `${AUTH_REDIRECT_URL_BASE}/oauth/token`;
export const AUTH_REDIRECT_SIGN_OUT_URL = `${AUTH_REDIRECT_URL_BASE}/v2/logout`;
export const AUTH_REDIRECT_RESPONSE_TYPE_CODE = 'code';
export const AUTH_REDIRECT_RESPONSE_TYPE_TOKEN = 'id_token token';
export const AUTH_REDIRECT_PROMPT_NONE = 'none';

export const AUTH_OAUTH_SCOPES = 'openid profile email';
