import { List, Map, Record, Set } from 'immutable';
import { schema } from 'normalizr';
import { GraphqlTypes, OptimisticUpdateRecord } from 'app/common/types';
import { PharmacyRecord, pharmacySchema } from 'app/pharmacies/types';
import { SessionRecord } from 'app/session/types';
import { userSchema } from 'app/users/types';
import { UserTypeRecord } from 'app/workitems/types';
import { CaseRecord, caseSchema } from '../cases/types';
import { PatientRecord, patientSchema } from '../patient/types';
import { PrescriberRecord, prescriberSchema } from '../prescribers/types';
import { CASE_LEVEL_RESPONSE, INTERACTION_LEVEL_RESPONSE } from './constants';

export enum InteractionStatus {
  Created = 'CREATED',
  InProgress = 'INPROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export class CaseInteractionFieldResponse extends Record({
  caseId: undefined as string | undefined,
  level: CASE_LEVEL_RESPONSE,
  name: undefined as string | undefined,
  value: undefined as string | undefined,
}) {}

export class InteractionFieldResponse extends Record({
  level: INTERACTION_LEVEL_RESPONSE,
  name: undefined as string | undefined,
  value: undefined as string | undefined,
}) {}

export type InteractionResponses =
  | CaseInteractionFieldResponse
  | InteractionFieldResponse;

export class InteractionRecord extends Record({
  id: undefined as string | undefined,
  cases: List<CaseRecord>() as
    | List<CaseRecord>
    | string[]
    | CaseRecord[]
    | undefined,
  responses: Set<InteractionResponses>() as
    | Set<InteractionResponses>
    | InteractionResponses[]
    | undefined,
  contact: undefined as
    | string
    | PatientRecord
    | PrescriberRecord
    | PharmacyRecord
    | undefined,
  dateContacted: undefined as Date | null | undefined,
  user: undefined as UserTypeRecord | undefined,
  status: undefined as InteractionStatus | undefined,
  caseSession: undefined as string | SessionRecord | undefined,
  suggestedCases: Set<CaseRecord>() as
    | Set<CaseRecord>
    | CaseRecord[]
    | undefined,
}) {}

export class ContactMethodFieldDefinitionRecord extends Record({
  phone: Map(),
}) {}

export const InteractionsStateRecord = Record({
  interactions: Map<string, InteractionRecord>(),
  optimistic: Map<string, OptimisticUpdateRecord>(),
  // TODO: Are we going to have different defintions for patient/prescriber/pharmacy?
  // we will have different definitions for phone/email/fax/etc
  interactionFieldDefinitions: new ContactMethodFieldDefinitionRecord(),
});

export const contactSchema = new schema.Union(
  {
    patient: patientSchema,
    prescriber: prescriberSchema,
    pharmacy: pharmacySchema,
  },
  (value, parent, key) => {
    switch (value.__typename) {
      case GraphqlTypes.Patient: {
        return 'patient';
      }
      case GraphqlTypes.Prescriber: {
        return 'prescriber';
      }
      case GraphqlTypes.Pharmacy: {
        return 'pharmacy';
      }
      default: {
        throw new Error('Unsupported value type in contactSchema');
      }
    }
  },
);

export const interactionSchema = new schema.Entity('interactions', {
  cases: [caseSchema],
  contact: contactSchema,
  user: userSchema,
  suggestedCases: [caseSchema],
});

caseSchema?.define({
  interactions: new schema.Array(interactionSchema),
});
