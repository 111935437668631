import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { MatchStrings } from '../../constants';
import ChipInput from '../ChipInput';
import styles from './MatchChipInput.css';

class MatchChipInput extends Component {
  static propTypes = {
    value: ImmutablePropTypes.recordOf({
      match: PropTypes.string.isRequired,
      values: ImmutablePropTypes.orderedSetOf(PropTypes.string).isRequired,
    }).isRequired,
    options: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ),
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    filter: PropTypes.func,
    noOptionsFoundComponent: PropTypes.node,
  };

  static defaultProps = {
    options: Set(),
  };

  handleMatchChange = (event, match) => {
    const { onChange, value } = this.props;
    onChange(value.merge({ match }));
  };

  handleSelectedValuesChange = (values) => {
    const { onChange, value } = this.props;
    onChange(value.merge({ values }));
  };

  render() {
    const {
      value,
      options,
      placeholder,
      onFocus,
      isFetching,
      noOptionsFoundComponent,
    } = this.props;
    return (
      <div className={styles.container}>
        <ChipInput
          value={value.values}
          options={options}
          optionsListOnly
          placeholder={placeholder}
          onFocus={onFocus}
          onChange={this.handleSelectedValuesChange}
          isFetching={isFetching}
          noOptionsFoundComponent={noOptionsFoundComponent}
        />
        <div className={styles.matchHeader}>Matching</div>
        <FormControl fullWidth>
          <RadioGroup
            name="Radial"
            value={value.match}
            onChange={this.handleMatchChange}
          >
            <FormControlLabel
              value={MatchStrings.Any}
              label={
                <div>
                  <b>Any</b> Selected
                </div>
              }
              control={
                <Radio classes={{ root: styles.slimRadio }} color="primary" />
              }
            />
            <FormControlLabel
              value={MatchStrings.All}
              label={
                <div>
                  <b>All</b> Selected
                </div>
              }
              control={
                <Radio classes={{ root: styles.slimRadio }} color="primary" />
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

export default MatchChipInput;
