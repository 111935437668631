import { Map, Set } from 'immutable';
import { CASE_FETCH_SUCCESS } from 'app/cases/constants';
import { QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS } from 'app/queue/constants';
import { USER_SIGN_IN_SUCCESS } from '../auth/constants';
import {
  CAMPAIGN_QUERY_FETCH_SUCCESS,
  CAMPAIGN_DETAILS_FETCH_SUCCESS,
  CAMPAIGN_EDIT_SUCCESS,
  CAMPAIGN_CLONE_UPSERT_SUCCESS,
  CAMPAIGN_ADD_SUCCESS,
} from '../campaigns/constants';
import { COMMENT_ADD_SUCCESS } from '../comments/constants';
import { VIEWTEMPLATES_FETCH_SUCCESS } from '../viewtemplates/constants';
import {
  WORKITEMS_QUERY_FETCH_SUCCESS,
  WORKITEM_DETAILS_FETCH_SUCCESS,
} from '../workitems/constants';
import {
  USERS_FETCH_START,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAIL,
} from './constants';
import { UsersStateRecord, UserRecord } from './types';

const mergeUser = (newUser, existingUser = new UserRecord()) => {
  return existingUser
    .merge(newUser)
    .set('permissions', new Set(newUser.permissions));
};

const reducer = (state = new UsersStateRecord(), action) => {
  switch (action.type) {
    case USERS_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case USERS_FETCH_SUCCESS: {
      const users = action.payload.users;
      const update = users.map((user) => {
        const existingUser = state.users.get(user.id);
        if (existingUser) {
          return {
            [user.id]: mergeUser(user, existingUser),
          };
        }
        return { [user.id]: mergeUser(user) };
      });

      return state.merge({
        isFetching: false,
        error: null,
        // now that we have permissions we probably can rewrite this one...
        activeUsers: new Set(users.map((user) => user.id)),
        users: state.users.merge(...update),
      });
    }
    case USERS_FETCH_FAIL:
      return state.merge({
        isFetching: false,
        error: action.payload.error,
      });
    case WORKITEM_DETAILS_FETCH_SUCCESS:
    case WORKITEMS_QUERY_FETCH_SUCCESS:
    case COMMENT_ADD_SUCCESS:
    case CAMPAIGN_QUERY_FETCH_SUCCESS:
    case CAMPAIGN_ADD_SUCCESS:
    case CAMPAIGN_EDIT_SUCCESS:
    case CAMPAIGN_CLONE_UPSERT_SUCCESS:
    case VIEWTEMPLATES_FETCH_SUCCESS:
    case CAMPAIGN_DETAILS_FETCH_SUCCESS:
    case QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS:
    case CASE_FETCH_SUCCESS: {
      const users = new Map(action.payload.data.entities.users);

      return state.mergeDeep({
        users: users.map((user) => {
          const existingUser = state.users.get(user.id) || new UserRecord();

          return existingUser.merge({
            ...user,
            permissions: new Set(user.permissions),
          });
        }),
      });
    }
    case USER_SIGN_IN_SUCCESS: {
      return state.merge({
        users: state.users.set(
          action.payload.userId,
          new UserRecord({
            id: action.payload.userId,
            name: action.payload.name,
            email: action.payload.email,
            pictureUrl: action.payload.picture,
            permissions: new Set(action.payload.permissions),
          }),
        ),
      });
    }
    default:
      return state;
  }
};

export default reducer;
