import PropTypes from 'prop-types';
import { mergeDeepLeft, values } from 'ramda';
import { Fragment, Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { VictoryLine, VictoryScatter, Data, VictoryArea } from 'victory';
import { AGGREGATION_PERIODS } from '../../constants';

const riskToPoint = (display) => (risk) => {
  if (display) {
    return [
      {
        x: new Date(risk.date),
        y: risk.riskScore?.max,
      },
      {
        x: new Date(risk.date),
        y: risk.riskScore?.min,
      },
    ];
  } else {
    return [
      {
        x: new Date(risk.date),
        y: risk.riskScore?.score,
      },
    ];
  }
};

const filterMonthlyAndProjected = (risk) =>
  risk.period === AGGREGATION_PERIODS.MONTHLY ||
  risk.period === AGGREGATION_PERIODS.PROJECTED;

class RiskLine extends Component {
  // Victory uses static props to get info about children... SAD
  static role = 'line';

  static continuous = true;

  static displayName = 'RiskLine';

  static getDomain = (props, axis) =>
    props.historicalRisk
      .flatMap(riskToPoint(props.displayRiskRange))
      .map((point) => point[axis])
      .toArray();

  static getData = (props) =>
    Data.getData(
      props.historicalRisk
        .filter(filterMonthlyAndProjected)
        .map(riskToPoint(props.displayRiskRange))
        .toArray(),
    );

  render() {
    const {
      displayRiskRange,
      historicalRisk,
      style,
      labels,
      labelComponent,
      ...props
    } = this.props;
    return (
      <Fragment>
        {displayRiskRange && (
          <Fragment>
            <VictoryArea
              style={{
                data: {
                  fill: '#f72c2c',
                  fillOpacity: '.2',
                },
              }}
              data={historicalRisk
                .filter((risk) => risk.period !== AGGREGATION_PERIODS.PROJECTED)
                .map((x) => ({
                  x: new Date(x.date),
                  y: x.riskScore.max,
                  y0:
                    x.riskScore.max -
                    (x.riskScore.max - x.riskScore.min) * 0.15,
                }))
                .toArray()}
              {...props}
            />
            <VictoryArea
              style={{
                data: { fill: '#f4ac00', fillOpacity: '.2' },
              }}
              data={historicalRisk
                .filter((risk) => risk.period !== AGGREGATION_PERIODS.PROJECTED)
                .map((x) => ({
                  x: new Date(x.date),
                  y:
                    x.riskScore.max -
                    (x.riskScore.max - x.riskScore.min) * 0.15,
                  y0:
                    x.riskScore.max - (x.riskScore.max - x.riskScore.min) * 0.6,
                }))
                .toArray()}
              {...props}
            />
            <VictoryArea
              style={{
                data: { fill: '#80c644', fillOpacity: '.2' },
              }}
              data={historicalRisk
                .filter((risk) => risk.period !== AGGREGATION_PERIODS.PROJECTED)
                .map((x) => ({
                  x: new Date(x.date),
                  y:
                    x.riskScore.max - (x.riskScore.max - x.riskScore.min) * 0.6,
                  y0: x.riskScore.min,
                }))
                .toArray()}
              {...props}
            />
          </Fragment>
        )}
        <VictoryLine
          style={mergeDeepLeft(style, { data: { stroke: 'black' } })}
          data={historicalRisk
            .filter((risk) => risk.period !== AGGREGATION_PERIODS.PROJECTED)
            .toArray()}
          x="date"
          y="riskScore.score"
          {...props}
        />
        <VictoryLine
          style={mergeDeepLeft(style, {
            data: { stroke: 'black', strokeDasharray: '10' },
          })}
          data={[
            historicalRisk
              .filter((risk) => risk.period === AGGREGATION_PERIODS.MONTHLY)
              .maxBy((risk) => risk.date),
            ...historicalRisk.filter(
              (risk) => risk.period === AGGREGATION_PERIODS.PROJECTED,
            ),
          ]}
          x="date"
          y="riskScore.score"
          {...props}
        />
        <VictoryScatter
          labels={labels}
          style={{ ...style, data: { fill: '#000' } }}
          data={historicalRisk
            .filter(filterMonthlyAndProjected)
            .map((x) => x.toJS())}
          x="date"
          y="riskScore.score"
          {...props}
          labelComponent={labelComponent}
        />
      </Fragment>
    );
  }
}

RiskLine.propTypes = {
  historicalRisk: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      date: PropTypes.instanceOf(Date),
      period: PropTypes.oneOf(values(AGGREGATION_PERIODS)),
      riskScore: ImmutablePropTypes.recordOf({
        score: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
      }),
    }),
  ).isRequired,
  style: PropTypes.object,
  labelComponent: PropTypes.element,
  labels: PropTypes.func,
};

RiskLine.defaultProps = {
  displayRiskRange: false,
};

export default RiskLine;
