import { parseISO } from 'date-fns';
import { Map, Set } from 'immutable';
import { isNil } from 'ramda';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../cases/constants';
import { Interval } from '../common/types';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../queue/constants';
import {
  ISSUES_FILTER_UPDATE,
  ISSUES_QUERY_FETCH_SUCCESS,
  ISSUE_UPSERT_SUCCESS,
} from './constants';
import {
  IssuesStateRecord,
  IssueRecord,
  IssuesFilterStateRecord,
} from './types';

function findAndMerge(state, issue) {
  const initialRecord = state.issues.get(issue.id) || new IssueRecord();

  return initialRecord.mergeDeep({
    ...issue,
    supportingDocuments:
      issue.supportingDocuments !== undefined
        ? Set(issue.supportingDocuments.map((x) => x.id))
        : initialRecord.supportingDocuments,
    target:
      issue.target?.id !== undefined ? issue.target.id : initialRecord.target,
    contact:
      issue.contact?.id !== undefined
        ? issue.contact.id
        : initialRecord.contact,
    createdDate: parseISO(issue.createdDate),
    effectiveDates:
      issue.effectiveDates !== undefined
        ? Set(
            issue.effectiveDates.map(
              (ed) =>
                new Interval({
                  start: isNil(ed.start) ? null : parseISO(ed.start),
                  end: isNil(ed.end) ? null : parseISO(ed.end),
                }),
            ),
          )
        : initialRecord.effectiveDates,
  });
}

function reducer(state = new IssuesStateRecord(), action) {
  switch (action.type) {
    case QUEUE_SKIP_SESSION_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS:
    case ISSUE_UPSERT_SUCCESS:
    case ISSUES_QUERY_FETCH_SUCCESS: {
      const issues = new Map(action.payload.data.entities.issues);

      return state.mergeDeep({
        issues: issues.map((x) => findAndMerge(state, x)),
      });
    }
    case ISSUES_FILTER_UPDATE: {
      // spreading on Record didn't return a seperate object with everything in it
      // converting to JS allows this type of destructuring.
      const { campaigns, targets, isEffective } = action.payload.filters;

      return state.merge({
        filters: new IssuesFilterStateRecord({
          isEffective,
          campaigns: Set(campaigns),
          targets: Set(targets),
        }),
      });
    }
    default:
      return state;
  }
}

export default reducer;
