import base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import jwtDecode from 'jwt-decode';
import {
  AUTH_REDIRECT_CALLBACK_URL,
  AUTH_REDIRECT_CALLBACK_SILENT_URL,
} from '../common/constants';
import { urlEncodeBase64 } from '../common/utilities/generic';
import {
  AUTH_REDIRECT_URL_BASE,
  AUTH_REDIRECT_CLIENT_ID,
  AUTH_OAUTH_SCOPES,
  AUTH_REDIRECT_RESPONSE_TYPE_CODE,
  AUTH_REDIRECT_SIGN_OUT_URL,
  AUTH_AUDIENCE,
} from './constants';

export function isTokenExpired(token) {
  const { exp } = jwtDecode(token);
  if (exp == null) throw new Error('token does not contain exp claim');
  return new Date().getTime() >= exp * 1000;
}

/**
 * Determines the number of milliseconds until the expiration of the token
 * @param  {string} token The users token
 * @return {number}       The number of milliseconds until the token expires.
 */
export function tokenExpiresIn(token) {
  const d = new Date();
  const { exp } = jwtDecode(token);
  if (exp == null) throw new Error('token does not contain exp claim');
  return exp * 1000 - d.getTime();
}

export function decodeJwtToken(token) {
  return jwtDecode(token);
}

export function getUserEmail(token) {
  const { email } = jwtDecode(token);
  if (email == null) throw new Error('token does not contain email claim');
  return email;
}

export function getUserPicture(token) {
  const { picture } = jwtDecode(token);
  if (picture == null) throw new Error('token does not contain picture claim');
  return picture;
}

export function createChallenge(verifier) {
  if (verifier == null) throw new Error('Invalid verifier provided');
  return urlEncodeBase64(sha256(verifier).toString(base64));
}

function createAuthRedirectUrlHelper(
  baseUrl,
  clientId,
  responseType,
  callbackUrl,
  scope,
  challenge,
  state,
  prompt,
  nonce,
  audience,
) {
  let url = `${baseUrl}/authorize/?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${callbackUrl}&audience=${audience}`;
  if (responseType === AUTH_REDIRECT_RESPONSE_TYPE_CODE) {
    url += `&code_challenge=${challenge}&code_challenge_method=S256`;
  }
  if (state) {
    url += `&state=${state}`;
  }
  if (prompt) {
    url += `&prompt=${prompt}`;
  }
  if (nonce) {
    url += `&nonce=${nonce}`;
  }
  return url;
}

export function createLogoutUrl(returnTo) {
  let url = `${AUTH_REDIRECT_SIGN_OUT_URL}?client_id=${AUTH_REDIRECT_CLIENT_ID}`;

  if (returnTo) {
    url += `&returnTo=${returnTo}`;
  }

  return url;
}

export function createAuthRedirectUrl(
  responseType,
  challenge,
  state,
  prompt,
  nonce,
  isSilent,
) {
  return createAuthRedirectUrlHelper(
    AUTH_REDIRECT_URL_BASE,
    AUTH_REDIRECT_CLIENT_ID,
    responseType,
    `${window.location.origin}${
      isSilent ? AUTH_REDIRECT_CALLBACK_SILENT_URL : AUTH_REDIRECT_CALLBACK_URL
    }`,
    AUTH_OAUTH_SCOPES,
    challenge,
    state,
    prompt,
    nonce,
    AUTH_AUDIENCE,
  );
}

export function redirectToExternalUrl(url) {
  window.location.assign(url);
}

export function redirectToAuthLogin(responseType, challenge, state) {
  redirectToExternalUrl(
    createAuthRedirectUrl(responseType, challenge, state, null, false),
  );
}

// TODO consider making this more generic,
// parse keys and build out a more dynamic object
export function parseHash(hash) {
  const fragments = hash.replace('#', '&').replace('?', '&').split('&');
  let idToken = null;
  let accessToken = null;
  try {
    idToken = fragments
      .find((f) => f.includes('id_token'))
      .replace('id_token=', '');
  } catch (e) {
    // continue regardless of errors parsing individual fields
  }

  try {
    accessToken = fragments
      .find((f) => f.includes('access_token'))
      .replace('access_token=', '');
  } catch (e) {
    // continue regardless of errors parsing individual fields
  }

  return {
    idToken,
    accessToken,
  };
}

function addHiddenIframe(url, onSuccess = () => {}) {
  const iframe = document.createElement('iframe');
  const dispose = () => document.body.removeChild(iframe);
  let resolver;
  iframe.style.display = 'none';
  iframe.src = url;
  iframe.addEventListener(
    'load',
    (event) => resolver(event),
    {
      once: true,
    },
    false,
  );

  return new Promise((resolve) => {
    resolver = resolve;
    document.body.appendChild(iframe);
  })
    .then((event) => onSuccess(event))
    .finally(dispose);
}

export function silentIframeLogout(url) {
  return addHiddenIframe(url);
}

export function silentIframeAuthentication(url) {
  const onSuccess = (event) => {
    return parseHash(event.target.contentWindow.location.hash);
  };

  return addHiddenIframe(url, onSuccess);
}
