import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { is, isNil } from 'ramda';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import filterDefinitions from 'app/filters/definitions';
import { getFilters } from 'app/filters/selectors';
import { workItemListFetchFilterOptionsStart } from 'app/ui/actions';
import {
  getIsTemplateDeleting,
  getTemplateDeleteError,
} from '../../../ui/selectors';
import { getAccessToken, getUserId } from '../../../user/selectors';
import { getActiveViewTemplateId } from '../../../user/selectors';
import {
  fetchViewTemplates,
  applyViewTemplate,
  deleteViewTemplate,
  deleteViewTemplateCancel,
} from '../../actions';
import ViewTemplateSelect from '../../components/ViewTemplateSelect';
import { getIsFetchingViewTemplates, getViewTemplates } from '../../selectors';

export class ViewTemplateSelectContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    viewTemplates: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.contains({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        isPublic: PropTypes.bool.isRequired,
      }),
      PropTypes.string.isRequired,
    ).isRequired,
    selectedValue: PropTypes.string,
    onSelectTemplate: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onTemplateEdit: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    isDeleting: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    selectedValue: null,
    deleteError: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDeleteDialogOpen: false,
      idToDelete: null,
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(fetchViewTemplates());
  };

  componentWillReceiveProps = (nextProps) => {
    const { deleteError, isDeleting } = nextProps;
    const { isDeleteDialogOpen } = this.state;

    if (isDeleteDialogOpen && isNil(deleteError) && isDeleting === false) {
      this.setState({ isDeleteDialogOpen: false, idToDelete: null });
    }
  };

  handleDeleteRequest = (id) => {
    this.setState({ isDeleteDialogOpen: true, idToDelete: id });
  };

  handleDeleteConfirm = () => {
    const { dispatch } = this.props;
    const { idToDelete } = this.state;
    dispatch(deleteViewTemplate(idToDelete));
  };

  handleDeleteCancel = () => {
    const { dispatch } = this.props;
    this.setState({ isDeleteDialogOpen: false, idToDelete: null });
    dispatch(deleteViewTemplateCancel());
  };

  handleOnValueChange = (value) => {
    const { dispatch, viewTemplates, onSelectTemplate, token, filtersSchema } =
      this.props;
    const viewTemplate = viewTemplates.get(value);
    if (onSelectTemplate) {
      onSelectTemplate(value);
    }

    let fetchOperations = Set();
    const filters = viewTemplate.filters.map((filter) => {
      const config = filterDefinitions.find((x) => x.name === filter.name);

      const options = config.options(token);
      const needToFetchOptions = is(Promise, options);

      if (needToFetchOptions) {
        fetchOperations = fetchOperations.add({ id: config.id, options });
      }

      return {
        id: config.id,
        name: filter.name,
        value: filter.value,
        options: !needToFetchOptions ? options : undefined,
      };
    });

    dispatch(applyViewTemplate(viewTemplate.merge({ filters }), dispatch));

    fetchOperations.forEach((operation) => {
      dispatch(
        workItemListFetchFilterOptionsStart(operation.id, operation.options),
      );
    });
  };

  render() {
    const {
      viewTemplates,
      isFetching,
      selectedValue,
      isOpen,
      onRequestClose,
      userId,
      onTemplateEdit,
      deleteError,
      isDeleting,
    } = this.props;
    const { isDeleteDialogOpen } = this.state;
    return (
      <ViewTemplateSelect
        isFetching={isFetching}
        selectedValue={selectedValue}
        viewTemplates={viewTemplates}
        onValueChange={this.handleOnValueChange}
        onEdit={onTemplateEdit}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        isDeleteDialogOpen={isDeleteDialogOpen}
        isDeleting={isDeleting}
        deleteError={deleteError}
        onDeleteRequest={this.handleDeleteRequest}
        onDeleteConfirm={this.handleDeleteConfirm}
        onDeleteCancel={this.handleDeleteCancel}
        userId={userId}
      />
    );
  }
}

export default connect((state) => ({
  isFetching: getIsFetchingViewTemplates(state),
  viewTemplates: getViewTemplates(state),
  selectedValue: getActiveViewTemplateId(state),
  userId: getUserId(state),
  isDeleting: getIsTemplateDeleting(state),
  deleteError: getTemplateDeleteError(state),
  token: getAccessToken(state),
  filtersSchema: getFilters(state),
}))(ViewTemplateSelectContainer);
