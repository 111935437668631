import {
  T,
  always,
  concat,
  cond,
  converge,
  defaultTo,
  equals,
  filter,
  flatten,
  map,
  pipe,
  prop,
  propEq,
  props,
  uniqBy,
  without,
} from 'ramda';
import { GraphqlTypes } from 'app/common/types';
import { InteractionStatus } from 'app/interactions/types';
import {
  convertPatientToSearchResult,
  convertPharmacyToSearchResult,
  convertPrescriberToSearchResult,
} from 'app/search/selectors';

export const isClaimRecord = pipe(
  prop('__typename'),
  equals(GraphqlTypes.Claim),
);
export const isPatientRecord = pipe(
  prop('__typename'),
  equals(GraphqlTypes.Patient),
);
export const isPrescriberRecord = pipe(
  prop('__typename'),
  equals(GraphqlTypes.Prescriber),
);
export const isPharmacyRecord = pipe(
  prop('__typename'),
  equals(GraphqlTypes.Pharmacy),
);

const getContactsFromClaim = pipe(
  props(['patient', 'prescriber', 'pharmacy']),
  without([undefined, null]),
);

const getContactIdFromSupportingDocument = cond([
  [isClaimRecord, getContactsFromClaim],
  [isPatientRecord, Array],
  [
    T,
    () => {
      throw new Error('Unknown supporting document type provided');
    },
  ],
]);

const uniqueById = uniqBy(prop('id'));

const getAllContactsFromSupportingDocsOnCase = pipe(
  prop('issues'),
  flatten,
  map(prop('supportingDocuments')),
  flatten,
  map(getContactIdFromSupportingDocument),
  flatten,
  without([undefined, null]),
);

export const getAllContactsForCase = pipe(
  converge(concat, [
    pipe(prop('target'), Array),
    getAllContactsFromSupportingDocsOnCase,
  ]),
  without([undefined, null]),
  uniqueById,
);

export const getAllContactsForCases = pipe(
  map(getAllContactsForCase),
  flatten,
  uniqueById,
);

export const getSuggestedSearchResultsForAllContactsInCases = pipe(
  defaultTo([]),
  getAllContactsForCases,
  map(
    cond([
      [isPatientRecord, convertPatientToSearchResult],
      [isPrescriberRecord, convertPrescriberToSearchResult],
      [isPharmacyRecord, convertPharmacyToSearchResult],
      [
        T,
        () => {
          throw new Error('Unknown contact type provided');
        },
      ],
    ]),
  ),
);

export const getContactType = cond([
  [isPatientRecord, always('Patient')],
  [isPrescriberRecord, always('Prescriber')],
  [isPharmacyRecord, always('Pharmacy')],
]);

export const getContactTypeFromInteraction = pipe(
  prop('contact'),
  getContactType,
);

export const getCompletedInteractions = pipe(
  defaultTo([]),
  filter(propEq(InteractionStatus.Completed, 'status')),
);
