import { List } from 'immutable';
import {
  InteractionFieldDefinitionRecord,
  InteractionFieldType,
} from '../../types';

export const defaultInteractionFields = new List([
  new InteractionFieldDefinitionRecord({
    name: 'Consultation Result',
    type: InteractionFieldType.Select,
    options: new List(['Agreed', 'Refused']),
  }),
  new InteractionFieldDefinitionRecord({
    name: 'Recommendation',
    type: InteractionFieldType.Select,
    options: new List([
      'Adverse Reaction to Med',
      'Allergic Reaction to Med',
      'Already Refilled',
      'BP Cuff Order/Education',
      'Cannot Afford Med',
      'CMR & TIPs to Complete',
      'CMR to Complete',
      'Education to Provide',
      'Initiate Medication(s) Switch',
      'Med Rec to Complete',
      'Medication(s) to Transfer',
      'Member Refusal',
      'No Issues Identified',
      'Non-Formulary Med',
      'Not Interested',
      'Oversupply',
      'Stopping Med',
      'TIPs to Complete',
      'Transportation Issues',
      'Unable to See MD',
      'Unwilling to Take Med',
      'Waiting on PA',
    ]),
  }),
  new InteractionFieldDefinitionRecord({
    name: 'Recommendation Action',
    type: InteractionFieldType.Select,
    options: new List([
      'Contacted Pharmacy',
      'Contacted Prescriber',
      'Filed a Grievance',
      'Member to Take Own Action',
      'Referred to CM',
      'RSC Completed Recommendation',
      'Transferred to Member Services',
      'Transferred to RPh',
    ]),
  }),
  new InteractionFieldDefinitionRecord({
    name: 'Notes',
    type: InteractionFieldType.Text,
  }),
]);
