import { fetchGraphqlQuery } from '../common/api';
import {
  upsertInteractionMutation,
  fetchInteractionLevelFieldDefinitions as fetchInteractionLevelFieldDefinitionsQuery,
} from './queries';

export async function saveInteractionAsync(interaction, token) {
  const data = await fetchGraphqlQuery(
    upsertInteractionMutation,
    { interaction },
    token,
  );
  return { errors: data.errors, interaction: data.data.saveInteraction };
}

export async function fetchInteractionFieldDefinitionsAsync(
  contact,
  activeCase,
  token,
) {
  const data = await fetchGraphqlQuery(
    fetchInteractionLevelFieldDefinitionsQuery,
    { contact, activeCase },
    token,
  );

  return {
    errors: data.errors,
    fieldDefinitions: data.data.interactionLevelFields,
  };
}
