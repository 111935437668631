import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  fetchPatientError,
  hasFetchedPatient,
  hasCompletedAll,
  isErrored,
  isRunningAny,
} from '../../../ui/selectors';
import { fetchViewTemplates } from '../../../viewtemplates/actions';
import {
  fetchPatient,
  fetchPatientDrugOverview,
  fetchPatientClaimView,
  fetchPatientOpioidView,
} from '../../actions';
import PatientProfile from '../../components/PatientProfile';
import {
  getPatient,
  getOpioidIssueCount,
  getOpioidIssues,
  getOutstandingCaseCount,
} from '../../selectors';

export const PatientProfileContainer = ({
  id,
  dispatch,
  patient,
  opioidIssues,
  opioidIssueCount,
  hasFetched,
  hasFetchedOpioidView,
  isFetchingOpioidView,
  isOpioidFetchErrored,
  caseCount,
}) => {
  const location = useLocation();
  useEffect(() => {
    // TODO revisit why we need both id's here
    dispatch(fetchPatient(id));
    dispatch(fetchViewTemplates(false));
  }, [id]);

  useEffect(() => {
    if (!isNil(patient.patientId)) {
      dispatch(fetchPatientDrugOverview(id, patient.patientId));
      dispatch(fetchPatientClaimView(id, patient.patientId));
      dispatch(fetchPatientOpioidView(id, patient.patientId));
    }
  }, [patient.patientId]);

  return (
    <PatientProfile
      patient={patient}
      opioidIssues={opioidIssues}
      opioidIssueCount={opioidIssueCount}
      hasFetchedOpioidView={hasFetchedOpioidView}
      isOpioidFetchErrored={isOpioidFetchErrored}
      isFetchingOpioidView={isFetchingOpioidView}
      hasFetched={hasFetched}
      casesCount={caseCount}
      location={location}
    />
  );
};

PatientProfileContainer.propTypes = {
  hasFetched: PropTypes.bool.isRequired,
  isFetchingOpioidView: PropTypes.bool.isRequired,
  hasFetchedOpioidView: PropTypes.bool.isRequired,
  isOpioidFetchErrored: PropTypes.bool.isRequired,
  patient: PropTypes.object,
  opioidIssueCount: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const opioidFetchActions = (id) => [
  fetchPatientOpioidView(id, id),
  fetchViewTemplates(false),
];

export default connect((state, props) => ({
  hasFetched: hasFetchedPatient(state, props.id),
  fetchPatientError: fetchPatientError(state),
  patient: getPatient(state, props.id),
  opioidIssues: getOpioidIssues(state, props.id),
  opioidIssueCount: getOpioidIssueCount(state, props.id),
  hasFetchedOpioidView: hasCompletedAll(state, ...opioidFetchActions(props.id)),
  isFetchingOpioidView: isRunningAny(state, ...opioidFetchActions(props.id)),
  isOpioidFetchErrored: isErrored(state, ...opioidFetchActions(props.id)),
  caseCount: getOutstandingCaseCount(state, props.id),
}))(PatientProfileContainer);
