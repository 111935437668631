import { Button } from '@material-ui/core';
import SaveButton from 'app/common/components/SaveButton/SaveButton';
import styles from './NoInteraction.css';

type NoInteractionProps = {
  isCompleting: boolean;
  isCanceling: boolean;
  isRunning: boolean;
  createInteraction: () => void;
  onSkip: () => void;
  onComplete: () => void;
  skipable: boolean;
  sessionHasInteraction: boolean;
};

const NoInteraction = ({
  isCanceling,
  isCompleting,
  isRunning,
  createInteraction,
  onSkip,
  onComplete,
  skipable,
  sessionHasInteraction,
}: NoInteractionProps) => {
  return (
    <>
      <div className={styles.interactionHeader}>
        <h3>What do you want to do?</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Button
          className={styles.interactionButton}
          variant="outlined"
          disabled={isCanceling || isCompleting || isRunning}
          onClick={() => createInteraction()}
        >
          Start Interaction
        </Button>

        <SaveButton
          variant="contained"
          color="primary"
          disabled={isCanceling || isCompleting || isRunning}
          isSaving={isCompleting}
          className={styles.interactionButton}
          onClick={sessionHasInteraction ? onComplete : onSkip}
        >
          {skipable ? (sessionHasInteraction ? 'Next Case' : 'Skip') : 'Close'}
        </SaveButton>
      </div>
    </>
  );
};

export default NoInteraction;
