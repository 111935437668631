import { Record, Map, Set } from 'immutable';
import { schema } from 'normalizr';

export const UserRecord = Record({
  id: null,
  name: null,
  email: null,
  pictureUrl: null,
  permissions: new Set(),
});

export const UsersStateRecord = Record({
  isFetching: false,
  error: null,
  activeUsers: new Set(),
  users: new Map(),
});

export const userSchema = new schema.Entity('users');
