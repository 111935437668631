import { ListItem } from '@material-ui/core';
import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import ChipInput from 'app/filters/components/ChipInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import { createAutoCompleteOptionsQueryHandler } from 'app/filters/definitions/utility';
import {
  IFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';

const handler = createAutoCompleteOptionsQueryHandler(
  'isNotEligibleForCarriers',
  'CARRIERS',
);
class IsNotEligibleForCarriersConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'isNotEligibleForCarriers',
    title: 'Is Patient Not Eligible for Carriers',
    targetType: IssueFilterType.Patient,
    options: (token: string) => handler(token),
    component: (props: any) => (
      <ChipInput
        placeholder="Enter Carriers"
        noOptionsFoundComponent={<ListItem>Invalid carrier...</ListItem>}
        {...props}
      />
    ),
  })
  implements
    IFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new IsNotEligibleForCarriersConfig();
