import { Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType, getTextOperatorConfig } from 'app/filters/constants';
import { textComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  TextComparisonBackendRecord,
  IClaimFilterConfiguration,
  TextComparisonRecord,
  TextComparisonOperator,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';
import TextComparison from '../../components/TextComparison';

class RejectMessagesConfig
  extends Record({
    ...textComparisonDefaults,
    name: 'rejectMessage',
    title: 'Reject Messages',
    targetType: IssueFilterType.Claim,
    component: (props: any) => (
      <TextComparison
        inputType="text"
        placeholder="Enter reject message"
        {...props}
      />
    ),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: TextComparisonBackendRecord,
    ) => {
      if (isNil(workItem.claim.rejectMessages)) return false;

      return getTextOperatorConfig(value.operator).filter(
        workItem.claim.rejectMessages.toArray(),
        value.text,
      );
    },
  })
  implements
    IClaimFilterConfiguration<
      TextComparisonRecord,
      Set<TextComparisonOperator>,
      TextComparisonBackendRecord,
      TextComparisonBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new RejectMessagesConfig();
