import { CaseRecord, BulkCreateCasesRecord } from 'app/cases/types';
import {
  CASES_FILTER_UPDATE,
  CASES_QUERY_CLEAR,
  CASES_QUERY_FETCH_FAIL,
  CASES_QUERY_FETCH_START,
  CASES_QUERY_FETCH_SUCCESS,
  CASES_SORT_APPLY,
  CASE_FETCH,
  CASE_FETCH_FAIL,
  CASE_FETCH_SUCCESS,
  CASE_UPSERT,
  CASE_UPSERT_FAIL,
  CASE_UPSERT_SUCCESS,
  BULK_CASE_UPSERT,
  BULK_CASE_UPSERT_FAIL,
  BULK_CASE_UPSERT_SUCCESS,
} from './constants';

export function fetchCase(id: string) {
  return {
    type: CASE_FETCH,
    payload: { id },
  };
}

export function fetchCaseSuccess(id: string, data: any) {
  return {
    type: CASE_FETCH_SUCCESS,
    payload: { id, data },
  };
}
export function fetchCaseFail(id: string, error: any) {
  return {
    type: CASE_FETCH_FAIL,
    payload: { id, error },
  };
}

export function fetchCasesQuery(
  filter: any,
  sortBy: string,
  sortDirection: string,
  continuationToken: string,
) {
  return {
    type: CASES_QUERY_FETCH_START,
    payload: { filter, sortBy, sortDirection, continuationToken },
  };
}

export function fetchCasesQuerySuccess(
  data: any,
  continuationToken: string,
  isContinuation: boolean,
) {
  return {
    type: CASES_QUERY_FETCH_SUCCESS,
    payload: { data, continuationToken, isContinuation },
  };
}

export function fetchCasesQueryFail(error: any) {
  return { type: CASES_QUERY_FETCH_FAIL, payload: { error } };
}

export function updateCaseFilter(filters: any) {
  return { type: CASES_FILTER_UPDATE, payload: { filters } };
}

export function applyCasesSort(sortBy: string, sortDirection: string) {
  return { type: CASES_SORT_APPLY, payload: { sortBy, sortDirection } };
}

export function clearCasesQuery() {
  return { type: CASES_QUERY_CLEAR, payload: {} };
}

export function upsertCase(optimisticId: string, caseRecord: CaseRecord) {
  return {
    type: CASE_UPSERT,
    payload: { optimisticId, case: caseRecord },
  };
}

export function upsertCaseSuccess(optimisticId: string, id: string, data: any) {
  return {
    type: CASE_UPSERT_SUCCESS,
    payload: { optimisticId, id, data },
  };
}

export function upsertCaseFail(
  optimisticId: string,
  source: string,
  timestamp: Date,
  error: any,
) {
  return {
    type: CASE_UPSERT_FAIL,
    payload: { optimisticId, source, timestamp, error },
  };
}

export function bulkCreateCases(
  optimisticId: string,
  bulkCreateCasesRecord: BulkCreateCasesRecord,
) {
  return {
    type: BULK_CASE_UPSERT,
    payload: { optimisticId, bulkCreateCasesRecord },
  };
}

export function bulkCreateCasesSuccess(optimisticId: string, data: any) {
  return {
    type: BULK_CASE_UPSERT_SUCCESS,
    payload: { optimisticId, data },
  };
}

export function bulkCreateCasesFail(
  optimisticId: string,
  source: string,
  timestamp: Date,
  error: any,
) {
  return {
    type: BULK_CASE_UPSERT_FAIL,
    payload: { optimisticId, source, timestamp, error },
  };
}
