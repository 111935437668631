import { prop, pipe, curry, defaultTo, isNil } from 'ramda';
import { createSelector } from 'reselect';
import { getClaims, getClaim } from 'app/claims/selectors';
import { getCommentsById } from 'app/comments/selectors';
import {
  ascDescSort,
  getSortValueSelector,
} from 'app/common/utilities/sorting';
import filterDefinitions from 'app/filters/definitions';
import { getAppliedFilters } from 'app/ui/selectors';
import { getUserId } from 'app/user/selectors';
import { getUsers, getUser } from 'app/users/selectors';
import columnDefinitions from 'app/workitems/containers/WorkItemsListView/columns';

const getWorkItemsState = prop('workItemsState');
const getOptimisticUpdates = pipe(getWorkItemsState, prop('optimistic'));
const getQueryResults = pipe(getWorkItemsState, prop('queryResults'));
const getSorting = pipe(getWorkItemsState, prop('sorting'));
const getOptimisticWorkItems = pipe(getOptimisticUpdates, prop('workItems'));
const getWorkItems = pipe(getWorkItemsState, prop('workItems'));
export const getWorkItemStatuses = pipe(
  getWorkItemsState,
  prop('workItemStatuses'),
);
const getWorkItemStatus = (state, id) =>
  !isNil(id) ? getWorkItemStatuses(state).get(id.toString()) : null;

const getAssignee = (id, user) => {
  switch (id) {
    case undefined:
      return undefined;
    case null:
      return null;
    default:
      return user;
  }
};

const denormalizeWorkItemHelper = (item, claim, assignee, comments, status) =>
  item.merge({
    claim,
    assignee,
    comments,
    status,
  });

const denormalizeWorkItem = (item, state) => {
  return denormalizeWorkItemHelper(
    item,
    getClaim(state, item.claim),
    getAssignee(item.assignee, getUser(state, item.assignee)),
    getCommentsById(state, item.comments),
    getWorkItemStatus(state, item.status),
  );
};

export const getWorkItem = curry((state, id) => {
  const optimisticItem = getOptimisticWorkItems(state).get(id);
  const item = getWorkItems(state).get(id);
  if (item) {
    return denormalizeWorkItem(item.mergeDeep(optimisticItem), state);
  }
  return null;
});

export const getExistingComments = (state, id) =>
  getWorkItems(state).get(id).comments;

export const getSortBy = pipe(
  getSorting,
  prop('sortBy'),
  defaultTo('AdjudicatedDate'),
);
export const getSortDirection = pipe(
  getSorting,
  prop('sortDirection'),
  defaultTo('DESC'),
);

export const getQueryResultsFactory = () =>
  createSelector(
    getOptimisticWorkItems,
    getWorkItems,
    getClaims,
    getUsers,
    getWorkItemStatuses,
    getQueryResults,
    getAppliedFilters,
    getUserId,
    createSelector(getSortBy, (sortBy) =>
      getSortValueSelector(sortBy, columnDefinitions),
    ),
    getSortDirection,
    (
      optimisticWorkItems,
      workItems,
      claims,
      users,
      workItemStatuses,
      results,
      appliedFilters,
      userId,
      sortValueSelector,
      sortDirection,
    ) => {
      return results
        .map((id) => {
          const item = workItems.get(id).merge(optimisticWorkItems.get(id));
          const statusId = !isNil(item.status) ? item.status.toString() : null;
          return denormalizeWorkItemHelper(
            item,
            claims.get(item.claim),
            getAssignee(item.assignee, users.get(item.assignee)),
            null,
            workItemStatuses.get(statusId),
          );
        })
        .filter((claim) => {
          return appliedFilters.every((filter, id) => {
            const config = filterDefinitions.get(id);
            const values = config.valueToQueryConverter(filter.value);

            return config.filterWorkItem(claim, values, userId);
          });
        })
        .sortBy(sortValueSelector, (a, b) => ascDescSort(a, b, sortDirection));
    },
  );
