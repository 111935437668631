import { createReducer } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { Map, Set } from 'immutable';
import { CASE_FETCH_SUCCESS } from 'app/cases/constants';
import { INTERACTION_SAVE_SUCCESS } from 'app/interactions/constants';
import { QUEUE_FETCH_NEXT_SESSION_SUCCESS } from 'app/queue/constants';
import { SESSION_UPSERT_SUCCESS } from 'app/session/constants';
import { SessionStateRecord, SessionRecord } from './types';

const sessionReducer = createReducer(new SessionStateRecord(), (builder) => {
  builder
    .addMatcher(
      (action) =>
        [
          QUEUE_FETCH_NEXT_SESSION_SUCCESS,
          SESSION_UPSERT_SUCCESS,
          INTERACTION_SAVE_SUCCESS,
          CASE_FETCH_SUCCESS,
        ].includes(action.type),
      (state: any, action: any) => {
        const session = Map(action.payload.data.entities.session);

        return state.merge({
          session: (state as unknown as SessionStateRecord).session.merge(
            session.map((p: any) => {
              const existingSession =
                state.session.get(p.id) || new SessionRecord();

              return existingSession.merge({
                ...p,
                interactions:
                  p.interactions === undefined
                    ? existingSession.interactions
                    : Set(p.interactions),
                secondaryCases:
                  p.secondaryCases === undefined
                    ? existingSession.secondaryCases
                    : Set(p.secondaryCases),
                sessionStart:
                  p.sessionStart === undefined
                    ? existingSession.sessionStart
                    : parseISO(p.sessionStart),
                sessionEnd:
                  p.sessionEnd === undefined
                    ? existingSession.sessionEnd
                    : parseISO(p.sessionEnd),
              });
            }),
          ) as Map<string, SessionRecord>,
        });
      },
    )
    .addDefaultCase((state) => state);
});

export default sessionReducer;
