import { createReducer, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { Map, OrderedMap } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import filterDefinitions from 'app/filters/definitions';
import { IFilterConfiguration } from 'app/filters/types';
import {
  workItemListFiltersClear,
  workItemlistFilterSetApplied,
  workItemListFilterSetEdited,
  workItemListUpdateFilters,
  workItemListFetchFilterOptionsStart,
  workItemListFetchFilterOptionsSuccess,
} from 'app/ui/actions';
import { applyViewTemplate } from 'app/viewtemplates/actions';
import { ViewTemplateRecord } from 'app/viewtemplates/types';
import { ComponentRecord, SetEditing } from '../types';
import {
  handleFilterFetchStart,
  handleFilterFetchSuccess,
  handleFilterUpdated,
  handleSetApplied,
  handleSetEditing,
} from './filterComponentHelpers';

const reducer = createReducer(
  OrderedMap<string, ComponentRecord>(),
  (builder) => {
    builder
      .addCase(workItemListUpdateFilters, (state, action) =>
        handleFilterUpdated(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(
        workItemListFilterSetEdited,
        (state, action: PayloadAction<SetEditing>) =>
          handleSetEditing(
            state as unknown as OrderedMap<string, ComponentRecord>,
            action,
          ),
      )
      .addCase(workItemlistFilterSetApplied, (state, action) =>
        handleSetApplied(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(workItemListFiltersClear, () =>
        OrderedMap<string, ComponentRecord>(),
      )
      .addCase(applyViewTemplate, (state, action: AnyAction) => {
        const { filters } = action.payload.viewTemplate;

        const componentRecords = filters
          .toArray()
          .map(
            (filter: {
              id: string;
              name: string;
              value: any;
              options: any;
            }) => {
              // TODO: Questionable typing fix typing workaround.
              const config = filterDefinitions
                .filter((x) => x.targetType === IssueFilterType.Claim)
                .find((x) => x.name === filter.name) as IFilterConfiguration<
                any,
                any,
                any,
                any
              >;

              if (isNil(config))
                throw new Error(`Cannot find config for ${filter.name}`);

              return [
                config.id,
                new ComponentRecord({
                  name: filter.name,
                  isEditing: false,
                  options: filter.options,
                  value: config.templateToFilterValueConverter(filter.value),
                }),
              ];
            },
          ) as [[string, ComponentRecord]];

        return OrderedMap<string, ComponentRecord>(componentRecords);
      })
      .addCase(workItemListFetchFilterOptionsStart, (state, action) =>
        handleFilterFetchStart(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(workItemListFetchFilterOptionsSuccess, (state, action) =>
        handleFilterFetchSuccess(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addDefaultCase((state) => state);
  },
);

export default reducer;
