import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getAccessToken } from '../user/selectors';
import { fetchUsersSuccess, fetchUsersFail } from './actions';
import { fetchUsers } from './api';
import { USERS_FETCH_START } from './constants';

export function* fetchUsersWorker() {
  try {
    const token = yield select(getAccessToken);
    const users = yield call(fetchUsers, token);
    yield put(fetchUsersSuccess(users));
  } catch (e) {
    yield put(fetchUsersFail(e));
  }
}

export function* fetchUsersWatcher() {
  yield takeLatest(USERS_FETCH_START, fetchUsersWorker);
}
