import { format, parse } from 'date-fns';
import { Map } from 'immutable';
import {
  BESTPRACTICES_CATEGORY_FETCH_SUCCESS,
  BESTPRACTICES_OVERVIEW_FETCH_SUCCESS,
} from '../constants';
import {
  BestPracticeRuleRecord,
  BestPracticeRuleResultsRecord,
  RiskModifierResultsRecord,
} from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case BESTPRACTICES_CATEGORY_FETCH_SUCCESS:
    case BESTPRACTICES_OVERVIEW_FETCH_SUCCESS: {
      const rules = new Map(action.payload.categories.entities.rules);
      return state.mergeDeep(
        rules.map((rule) => {
          const dailySummaries = new Map(
            rule.results.map((summary) => {
              const date = parse(summary.date, 'yyyy-MM-dd', new Date());

              const key = format(date, 'yyyyMMdd');

              const riskModifiers = new Map(
                summary.modifiers.map((modifier) => [
                  modifier.id,
                  new RiskModifierResultsRecord(modifier),
                ]),
              );

              return [
                key,
                new BestPracticeRuleResultsRecord({
                  ...summary,
                  date,
                  riskModifiers,
                }),
              ];
            }),
          );

          return new BestPracticeRuleRecord({
            ...rule,
            dailyResults: dailySummaries,
          });
        }),
      );
    }
    default:
      return state;
  }
};

export default reducer;
