import { EditorState, ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { CommentEditor } from '../../../../comments/components';
import {
  COMMENT_ADD_FAIL,
  COMMENT_EDIT_FAIL,
  COMMENT_DELETE_FAIL,
} from '../../../../comments/constants';
import { Comment } from '../../../../comments/containers/comment';
import ErrorBoundary from '../../../../common/components/ErrorBoundary';
import { mapToValuesArray } from '../../../../common/utilities/generic';
import styles from './WorkItemComments.css';

class WorkItemComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
    };
    this.onRenderSubject = new Subject();
    this.onRenderSubscription = this.onRenderSubject
      .pipe(auditTime(16)) // 60fps limit
      .subscribe(this.updateScrollBar);
  }

  componentDidMount = () => {
    if (this.scrollbar) {
      this.shouldScrollBottom = true;
      this.scrollbar.scrollToBottom();
    }
  };

  componentWillUpdate = () => {
    if (this.scrollbar) {
      this.shouldScrollBottom = this.getIsScrolledDown();
    }
  };

  componentDidUpdate = () => {
    if (this.shouldScrollBottom) {
      this.scrollbar.scrollToBottom();
    }
  };

  componentWillUnmount = () => this.onRenderSubscription.unsubscribe();

  // This function determines whether or not we should auto-scroll
  // The -20 gives us wiggle room if js falls behind animations
  getIsScrolledDown = () =>
    this.scrollbar.getScrollTop() +
      this.scrollbar.getValues().clientHeight -
      this.scrollbar.getValues().scrollHeight >
    -20;

  clearEditorState = () =>
    this.setState({ editorState: EditorState.createEmpty() });

  handleEditorChange = (editorState) => this.setState({ editorState });

  handlePrimaryClick = (editorState) => {
    this.clearEditorState();
    this.props.onAddComment(editorState.getCurrentContent());
    this.scrollbar.scrollToBottom();
  };

  updateScrollBar = () => {
    if (this.scrollbar) {
      if (this.shouldScrollBottom) {
        this.scrollbar.scrollToBottom();
      } else {
        // We forceUpdate() here to ensure that the scrollbar represents the
        // correct height once animation of the editor has finished
        this.scrollbar.forceUpdate();
      }
    }
  };

  handleEditorRender = () => this.onRenderSubject.next();

  handleSecondaryClick = () => this.clearEditorState();

  render() {
    const {
      userId,
      comments,
      erroredComments,
      onResubmitComment,
      disabled,
      onEditComment,
      onDeleteComment,
      addingComments,
      deletingComments,
      editingComments,
    } = this.props;
    const { editorState } = this.state;

    const upsertingComments = addingComments.union(editingComments);
    const commentErrors = new Map(
      erroredComments.map((x) => {
        let errorType = null;
        if (addingComments.includes(x)) errorType = COMMENT_ADD_FAIL;
        if (editingComments.includes(x)) errorType = COMMENT_EDIT_FAIL;
        if (deletingComments.includes(x)) errorType = COMMENT_DELETE_FAIL;
        return [x, errorType];
      }),
    );
    return (
      <div className={styles.container}>
        <div className={styles.header}>Comments</div>
        <Scrollbars
          className={styles.comments}
          renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
          renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
          ref={(el) => {
            this.scrollbar = el;
          }}
        >
          {comments &&
            mapToValuesArray(comments).map(
              (comment) =>
                (!deletingComments.includes(comment.id) ||
                  erroredComments.includes(comment.id)) && (
                  <div key={comment.id} className={styles.comment}>
                    <ErrorBoundary>
                      <Comment
                        id={comment.id}
                        author={comment.author}
                        message={comment.message}
                        dateCreated={comment.dateCreated}
                        error={commentErrors.get(comment.id)}
                        onErrorClick={onResubmitComment}
                        isOwner={comment.author?.id === userId}
                        isSaving={upsertingComments.includes(comment.id)}
                        onEdit={onEditComment}
                        onDelete={onDeleteComment}
                      />
                    </ErrorBoundary>
                  </div>
                ),
            )}
        </Scrollbars>
        <div className={styles.editor}>
          <CommentEditor
            editorState={editorState}
            onChange={this.handleEditorChange}
            primaryDisabled={!editorState.getCurrentContent().hasText()}
            onPrimaryClick={this.handlePrimaryClick}
            onSecondaryClick={this.handleSecondaryClick}
            showActions={
              editorState.getCurrentContent().hasText() ||
              editorState.getSelection().getHasFocus()
            }
            onRender={this.handleEditorRender}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

WorkItemComments.propTypes = {
  comments: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.recordOf({
      author: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      id: PropTypes.string.isRequired,
      message: PropTypes.instanceOf(ContentState).isRequired,
      dateCreated: PropTypes.instanceOf(Date).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  onAddComment: PropTypes.func.isRequired,
  onResubmitComment: PropTypes.func.isRequired,
  erroredComments: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
};

WorkItemComments.defaultProps = {
  disabled: false,
  comments: null,
};

export default WorkItemComments;
