import { Map } from 'immutable';
import {
  VIEWTEMPLATES_FETCH_SUCCESS,
  VIEWTEMPLATES_FETCH_COUNT_START,
  VIEWTEMPLATE_FETCH_COUNT_SUCCESS,
  VIEWTEMPLATE_FETCH_COUNT_FAIL,
  VIEWTEMPLATE_CREATE_SUCCESS,
  VIEWTEMPLATE_DELETE_SUCCESS,
} from '../../viewtemplates/constants';
import { ViewTemplateRecord } from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case VIEWTEMPLATES_FETCH_SUCCESS: {
      const update = new Map(action.payload.data.entities.viewTemplates).map(
        (viewTemplate) => new ViewTemplateRecord(),
      );
      return state.mergeDeep(update);
    }
    case VIEWTEMPLATE_CREATE_SUCCESS: {
      return state.merge({
        [action.payload.viewTemplate.id]: new ViewTemplateRecord(),
      });
    }
    case VIEWTEMPLATES_FETCH_COUNT_START: {
      const update = action.payload.ids.map((id) => {
        return {
          [id]: new ViewTemplateRecord({
            isFetching: true,
            error: null,
          }),
        };
      });
      return state.mergeDeep(...update);
    }
    case VIEWTEMPLATE_FETCH_COUNT_SUCCESS: {
      return state.merge({
        [action.payload.id]: new ViewTemplateRecord({
          isFetching: false,
          error: null,
        }),
      });
    }
    case VIEWTEMPLATE_FETCH_COUNT_FAIL: {
      return state.merge({
        [action.payload.id]: new ViewTemplateRecord({
          isFetching: false,
          error: action.payload.error,
        }),
      });
    }
    case VIEWTEMPLATE_DELETE_SUCCESS: {
      return state.delete(action.payload.id);
    }
    default:
      return state;
  }
};

export default reducer;
