/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import { startOfDay, addYears, subYears } from 'date-fns';
import { isNil } from 'ramda';
import React, { useState } from 'react';
import DateRangePicker from 'app/common/components/DateRangePicker/DateRangePicker';
import { AgeRangeFilterRecord } from 'app/filters/types';
import TextInput from '../../../common/components/TextInput';
import styles from './AgeRange.css';

type AgeRangeComponentProps = {
  value: AgeRangeFilterRecord;
  onChange: (value: AgeRangeFilterRecord) => void;
};

const AgeRange = ({ value, onChange }: AgeRangeComponentProps) => {
  const [errorText, setErrorText] = useState('');
  const [errorBool, setErrorBool] = useState(false);
  const handleMinChange = (numberValue: string) => {
    const minAge = isNil(numberValue) ? 0 : Number.parseInt(numberValue, 10);
    if (isNil(numberValue)) {
      setErrorBool(true);
      setErrorText('Min age is required.');
    } else if (value.maxAge && minAge >= value.maxAge) {
      setErrorBool(true);
      setErrorText('Min age must be less than max age.');
    } else {
      setErrorBool(false);
      setErrorText('');
    }
    onChange(
      value.merge({
        minAge,
      }),
    );
  };
  const handleMaxChange = (numberValue: string) => {
    const maxAge = isNil(numberValue) ? 0 : Number.parseInt(numberValue, 10);
    if (isNil(numberValue) || maxAge === 0) {
      setErrorBool(true);
      setErrorText('Max age is required.');
    } else if (value.minAge && maxAge <= value.minAge) {
      setErrorBool(true);
      setErrorText('Min age must be less than max age.');
    } else {
      setErrorBool(false);
      setErrorText('');
    }
    onChange(
      value.merge({
        maxAge,
      }),
    );
  };
  const handleDateChange = (fromDate: Date) => {
    const startDate = isNil(fromDate) ? null : startOfDay(fromDate);
    onChange(
      value.merge({
        fromDate: startDate,
      }),
    );
  };

  const dateValue = isNil(value.fromDate) ? null : new Date(value.fromDate);

  return (
    <div>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>Min Age</FormLabel>
        <TextInput
          type="number"
          onChange={(minAge: any) => handleMinChange(minAge)}
          value={isNil(value.minAge) ? value.minAge : value.minAge.toString()}
          placeholder="0"
          debounced
          min="0"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>Max Age</FormLabel>
        <TextInput
          type="number"
          onChange={(maxAge: any) => handleMaxChange(maxAge)}
          value={isNil(value.maxAge) ? value.maxAge : value.maxAge.toString()}
          placeholder="Max age"
          debounced
          min="0"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>
          As of Date (optional)
        </FormLabel>
        <DateRangePicker
          variant="outlined"
          maxSelectableDate={addYears(new Date(), 2)}
          minSelectableDate={subYears(new Date(), 2)}
          classes={{ root: styles.input }}
          value={dateValue}
          onChange={(date: any) => handleDateChange(date)}
        />
      </FormControl>
      {errorBool && <div className={styles.formErrorText}>{errorText}</div>}
    </div>
  );
};

export default AgeRange;
