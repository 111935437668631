import { isWithinInterval, parseISO, parse } from 'date-fns';
import { Map, OrderedSet, Set } from 'immutable';
import { isNil, omit } from 'ramda';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../cases/constants';
import { Interval } from '../common/types';
import { INTERACTION_SAVE_SUCCESS } from '../interactions/constants';
import {
  ISSUES_TARGET_SEARCH_FETCH_SUCCESS,
  ISSUES_QUERY_FETCH_SUCCESS,
  ISSUE_UPSERT_SUCCESS,
} from '../issues/constants';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../queue/constants';
import { SEARCH_SUCCESS } from '../search/constants';
import { WORKITEM_DETAILS_FETCH_SUCCESS } from '../workitems/constants';
import {
  PATIENT_FETCH_SUCCESS,
  DRUG_OVERVIEW_FETCH_SUCCESS,
  CLAIM_VIEWER_FETCH_SUCCESS,
  OPIOID_DETAIL_FETCH_SUCCESS,
  PATIENT_UPSERT_SUCCESS,
} from './constants';
import {
  PatientsStateRecord,
  PatientRecord,
  EligibilityRecord,
  PhoneNumberRecord,
  EligibilityForGroupRecord,
  EligibilitySpanRecord,
} from './types';

const findAndMerge = (state, patient) => {
  const existingRecord = state.patients.get(patient.id) || new PatientRecord();

  let { eligibility, opioidNaiveWindows, cancerDiagnoses, phoneNumbers } =
    existingRecord;

  const { cases, claims, dateOfBirth } = existingRecord;

  if (!isNil(patient.eligibility) && patient.eligibility.length > 0) {
    eligibility = new OrderedSet(
      patient.eligibility?.map(
        (x) =>
          new EligibilityRecord({
            eligibilityMatchingKey: x.eligibilityMatchingKey,
            patientNumber: x.patientNumber,
            startDate: isNil(x.startDate) ? null : parseISO(x.startDate),
            termDate: isNil(x.termDate) ? null : parseISO(x.termDate),
            eligibilityCarrier: isNil(x.eligibilityCarrier) ? 'Unknown Carrier' : x.eligibilityCarrier,
            eligibilityForGroups: isNil(x.eligibilityForGroups)
              ? null
              : new OrderedSet(
                  x.eligibilityForGroups?.map(
                    (y) =>
                      new EligibilityForGroupRecord({
                        group: isNil(y.group) ? 'Unknown Carrier' : y.group,
                        eligibilities: isNil(y.eligibilities)
                          ? null
                          : new OrderedSet(
                              y.eligibilities?.map(
                                (z) =>
                                  new EligibilitySpanRecord({
                                    eligibilityStartDate: isNil(
                                      z.eligibilityStartDate,
                                    )
                                      ? null
                                      : parseISO(z.eligibilityStartDate),
                                    eligibilityTermDate: isNil(
                                      z.eligibilityTermDate,
                                    )
                                      ? null
                                      : parseISO(z.eligibilityTermDate),
                                  }),
                              ),
                            ),
                      }),
                  ),
                ),
          }),
      ),
    );
  }

  if (
    !isNil(patient.opioidNaiveWindows) &&
    patient.opioidNaiveWindows.length > 0
  ) {
    opioidNaiveWindows = new OrderedSet(
      patient.opioidNaiveWindows?.map(
        (x) =>
          new Interval({
            start: parseISO(x.start),
            end: parseISO(x.end),
          }),
      ),
    );
  }

  if (!isNil(patient.cancerDiagnoses) && patient.cancerDiagnoses.length > 0) {
    cancerDiagnoses = new OrderedSet(
      patient.cancerDiagnoses?.map(
        (x) =>
          new Interval({
            start: parseISO(x.start),
            end: parseISO(x.end),
          }),
      ),
    );
  }

  if (!isNil(patient.phoneNumbers)) {
    phoneNumbers = Set(
      patient.phoneNumbers.map(
        (pn) =>
          new PhoneNumberRecord({
            phoneNumber: pn.phoneNumber,
            isPrimary: pn.isPrimary,
            source: pn.source,
            addedOn: parseISO(pn.addedOn),
          }),
      ),
    );
  }

  return existingRecord.merge({
    ...omit(['__typename'], patient),
    eligibility,
    opioidNaiveWindows,
    cancerDiagnoses,
    hasActiveCancerDiagnosis: cancerDiagnoses.some((cd) =>
      isWithinInterval(new Date(), cd),
    ),
    claims: claims.merge(patient.claims || []),
    phoneNumbers,
    dateOfBirth:
      patient.dateOfBirth !== undefined
        ? parse(patient.dateOfBirth, 'yyyy-MM-dd', new Date())
        : dateOfBirth,
    cases: cases.merge(patient.cases || []),
    followUpDate:
      patient.followUpDate !== undefined && patient.followUpDate !== null
        ? parseISO(patient.followUpDate)
        : patient.followUpDate,
  });
};

function reducer(state = new PatientsStateRecord(), action) {
  switch (action.type) {
    case INTERACTION_SAVE_SUCCESS:
    case QUEUE_SKIP_SESSION_SUCCESS:
    case WORKITEM_DETAILS_FETCH_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case PATIENT_FETCH_SUCCESS:
    case SEARCH_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS:
    case ISSUES_QUERY_FETCH_SUCCESS:
    case ISSUE_UPSERT_SUCCESS:
    case ISSUES_TARGET_SEARCH_FETCH_SUCCESS:
    case PATIENT_UPSERT_SUCCESS: {
      const patients = new Map(action.payload.data.entities.patients);

      return state.merge({
        patients: state.patients.merge(
          patients.map((x) => findAndMerge(state, x)),
        ),
      });
    }
    case DRUG_OVERVIEW_FETCH_SUCCESS:
    case CLAIM_VIEWER_FETCH_SUCCESS:
    case OPIOID_DETAIL_FETCH_SUCCESS: {
      const { id, data } = action.payload;
      const existingPatient =
        state.patients.get(id) || new PatientRecord({ id });
      return state.merge({
        patients: state.patients.merge([
          [
            id,
            existingPatient.merge({
              claims: existingPatient.claims.merge(data.result),
            }),
          ],
        ]),
      });
    }
    default:
      return state;
  }
}

export default reducer;
