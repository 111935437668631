import { navigate } from '@reach/router';
import { parseISO } from 'date-fns';
import { Map } from 'immutable';
import { normalize } from 'normalizr';
import {
  call,
  put,
  select,
  take,
  race,
  takeLatest,
  all,
  fork,
} from 'redux-saga/effects';
import { IssueFilterType } from 'app/filters/constants';
import {
  WORKITEMLIST_FILTERS_CLEAR,
  WORKITEMLIST_FILTERS_UPDATE,
  WORKITEMLIST_FILTER_SET_APPLIED,
} from 'app/ui/constants';
import {
  fetchPatientClaimsHistory,
  fetchPatientDrugHistory,
} from '../claims/actions';
import { mapResponseToComments } from '../comments/utility';
import { UNAUTHORIZED_URL } from '../common/constants';
import throttle from '../common/utilities/sagas';
import filterDefinitions from '../filters/definitions';
import {
  getAppliedFilters,
  getContinuationToken,
  hasNextPage,
  isFetchingQuery,
} from '../ui/selectors';
import { getAccessToken } from '../user/selectors';
import { VIEWTEMPLATE_APPLY } from '../viewtemplates/constants';
import {
  fetchWorkItemsQuery,
  fetchWorkItemsQuerySuccess,
  fetchWorkItemsQueryFail,
  fetchWorkItemDetailsSuccess,
  fetchWorkItemDetailsFail,
  upsertWorkItem,
  upsertWorkItemSuccess,
  upsertWorkItemFail,
  fetchWorkItemStatusesSuccess,
  fetchWorkItemStatusesFail,
  clearWorkItemsQuery,
} from './actions';
import {
  fetchWorkItemDetails,
  fetchFilteredClaims,
  upsertWorkItem as upsertWorkItemApi,
  fetchWorkItemStatuses,
} from './api';
import {
  WORKITEMS_QUERY_FETCH_NEXT,
  WORKITEMS_QUERY_FETCH_START,
  WORKITEM_DETAILS_FETCH_START,
  WORKITEM_SET_ASSIGNEE,
  WORKITEM_SET_STATUS,
  WORKITEM_UPSERT_START,
  WORKITEM_STATUSES_FETCH_START,
  WORKITEMS_QUERY_START,
  WORKITEMS_SORT_APPLY,
} from './constants';
import { getWorkItem, getSortBy, getSortDirection } from './selectors';
import {
  workitemQuerySchema,
  claim,
  workItem as workItemSchema,
} from './types';

export function* checkStatusAndRedirect(status) {
  if (status === 401 || status === 403) {
    yield call(navigate, UNAUTHORIZED_URL);
  }
}

export function* fetchClaimsWorker(action) {
  try {
    const startTime = performance.now();
    const {
      payload: { filter, continuationToken, sortBy, sortOrder },
    } = action;

    const token = yield select(getAccessToken);
    const response = yield call(
      fetchFilteredClaims,
      filter,
      sortBy,
      sortOrder,
      token,
      continuationToken,
    );
    const data = normalize(response, workitemQuerySchema);
    const elapsedTime = performance.now() - startTime;
    yield put(
      fetchWorkItemsQuerySuccess(data, continuationToken !== null, elapsedTime),
    );
  } catch (e) {
    yield put(fetchWorkItemsQueryFail(e));
    if (e.status) {
      yield call(checkStatusAndRedirect, e.status);
    }
  }
}

export function* fetchWorkItemsWatcher() {
  yield fork(throttle, 500, WORKITEMS_QUERY_FETCH_START, fetchClaimsWorker);
}

export function* fetchWorkItemDetailsWorker(action) {
  try {
    const startTime = performance.now();
    const token = yield select(getAccessToken);
    const response = yield call(fetchWorkItemDetails, action.payload.id, token);
    if (response.workItem.comments) {
      response.workItem.comments = mapResponseToComments(
        response.workItem.comments,
      );
    }
    const data = normalize(response, claim);

    const elapsedTime = performance.now() - startTime;
    yield put(fetchWorkItemDetailsSuccess(data, elapsedTime));
    if (response.patientId) {
      const filledDate = parseISO(response.filledDate);
      const tasks = [
        put(fetchPatientClaimsHistory(response.patientId, filledDate)),
      ];

      if (response.ndc || response.rxcui) {
        let drugFilter = {};
        if (response.rxcui) {
          drugFilter = {
            rxcui: [response.rxcui],
          };
        } else if (response.ndc) {
          drugFilter = {
            ndc: [response.ndc],
          };
        }

        tasks.push(
          put(
            fetchPatientDrugHistory(
              response.patientId,
              drugFilter,
              response.quantityLimitDays || 90,
              filledDate,
            ),
          ),
        );
      }
      yield all(tasks);
    }
  } catch (e) {
    yield put(fetchWorkItemDetailsFail(e));
    if (e.status) {
      yield call(checkStatusAndRedirect, e.status);
    }
  }
}

export function* fetchWorkItemDetailsWatcher() {
  yield takeLatest(WORKITEM_DETAILS_FETCH_START, fetchWorkItemDetailsWorker);
}

export function* fetchWorkItemStatusesWorker() {
  try {
    const token = yield select(getAccessToken);
    const response = yield call(fetchWorkItemStatuses, token);
    yield put(fetchWorkItemStatusesSuccess(response));
  } catch (e) {
    yield put(fetchWorkItemStatusesFail(e));
  }
}

export function* fetchWorkItemStatusesWatcher() {
  yield takeLatest(WORKITEM_STATUSES_FETCH_START, fetchWorkItemStatusesWorker);
}

export function* filterUpdateWatcher() {
  while (true) {
    const actions = yield race({
      updateFilters: take(WORKITEMLIST_FILTERS_UPDATE),
      clearFilters: take(WORKITEMLIST_FILTERS_CLEAR),
      appliedFilters: take(WORKITEMLIST_FILTER_SET_APPLIED),
      appliedViewTemplate: take(VIEWTEMPLATE_APPLY),
      workItemsNextPage: take(WORKITEMS_QUERY_FETCH_NEXT),
      workItemsStart: take(WORKITEMS_QUERY_START),
      sort: take(WORKITEMS_SORT_APPLY),
    });

    let filters = yield select(getAppliedFilters);
    const continuationToken = yield select(getContinuationToken);
    const sortBy = yield select(getSortBy);
    const sortOrder = yield select(getSortDirection);

    let shouldFetch = true;
    let shouldContinue = true;

    filters = filters.mapEntries((entry) => {
      return [
        entry[1].name,
        filterDefinitions.get(entry[0]).valueToQueryConverter(entry[1].value),
      ];
    });

    // Sorting is a special case, don't always need to fetch
    if (actions.sort) {
      const hasMoreResults = yield select(hasNextPage);
      const isFetching = yield select(isFetchingQuery);
      shouldFetch = hasMoreResults || isFetching;
      shouldContinue = false;
      if (shouldFetch) {
        yield put(clearWorkItemsQuery());
      }
    }

    if (shouldFetch) {
      yield put(
        fetchWorkItemsQuery(
          filters,
          sortBy,
          sortOrder,
          shouldContinue ? continuationToken : null,
        ),
      );
    }
  }
}

export function* upsertWorkItemSource() {
  while (true) {
    const results = yield race({
      setAssignee: take(WORKITEM_SET_ASSIGNEE),
      setStatus: take(WORKITEM_SET_STATUS),
    });
    const result = new Map({ ...results }).filter((r) => r).first();
    if (result) {
      const workItem = yield select(getWorkItem, result.payload.workItemId);
      yield put(upsertWorkItem(workItem, result));
    }
  }
}

export function* upsertWorkItemWorker(action) {
  const { workItem } = action.payload;
  try {
    const token = yield select(getAccessToken);
    const assignee = workItem.assignee
      ? { id: workItem.assignee.id, name: workItem.assignee.name }
      : null;
    const response = yield call(
      upsertWorkItemApi,
      {
        id: workItem.id,
        assignee,
        status: workItem.status.id,
      },
      token,
    );

    if (response.successful) {
      const data = normalize(response.workItemResponse, workItemSchema);
      yield put(upsertWorkItemSuccess(data));
    } else {
      yield put(
        upsertWorkItemFail(
          workItem.id,
          action.payload.source,
          new Date(),
          response.errors,
        ),
      );
    }
  } catch (e) {
    yield put(
      upsertWorkItemFail(
        workItem.id,
        action.payload.source,
        new Date(),
        `Failed to update work item ${workItem.id}`,
      ),
    );
  }
}

export function* upsertWorkItemWatcher() {
  yield takeLatest(WORKITEM_UPSERT_START, upsertWorkItemWorker);
}
