import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  isBestPracticesFetching,
  getBestPracticeErrors,
  getSelectedDate,
} from '../../../ui/selectors';
import {
  fetchBestPracticeCategory,
  updateBestPracticeCategoryDate,
} from '../../actions';
import BestPracticeCategory from '../../components/BestPracticeCategory';
import {
  getBestPracticeCategory,
  bestPracticeHistoricalRiskByRule,
} from '../../selectors';

export class BestPracticeCategoryContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    category: ImmutablePropTypes.record,
    dateRange: ImmutablePropTypes.record,
    riskScores: ImmutablePropTypes.map,
    isFetching: PropTypes.bool.isRequired,
    errorFetchingBestPractices: PropTypes.string,
  };

  componentDidMount = () => {
    const { dispatch, dateRange, categoryId } = this.props;
    dispatch(fetchBestPracticeCategory(categoryId, dateRange));
  };

  handleDateChange = (value) => {
    const { dispatch, categoryId } = this.props;
    dispatch(updateBestPracticeCategoryDate(categoryId, value));
  };

  render() {
    const {
      category,
      isFetching,
      dateRange,
      errorFetchingBestPractices,
      riskScores,
    } = this.props;
    return (
      <BestPracticeCategory
        riskScores={riskScores}
        categoryRiskScore={category.categoryRiskScore}
        category={category.category}
        isFetching={isFetching}
        handleDateChange={this.handleDateChange}
        dateRange={dateRange}
        errorFetchingBestPractices={errorFetchingBestPractices}
      />
    );
  }
}

export default connect((state, props) => ({
  category: getBestPracticeCategory(state, props.categoryId),
  isFetching: isBestPracticesFetching(state),
  riskScores: bestPracticeHistoricalRiskByRule(state, props.categoryId),
  dateRange: getSelectedDate(state, props.categoryId),
  errorFetchingBestPractices: getBestPracticeErrors(state),
}))(BestPracticeCategoryContainer);
