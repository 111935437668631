import { normalize } from 'normalizr';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from '../common/sagas';
import {
  fetchTargetsFail,
  fetchTargetsSuccess,
  fetchIssuesQueryFail,
  fetchIssuesQuerySuccess,
  upsertIssueSuccess,
  upsertIssueFail,
} from './actions';
import { fetchTargets, fetchIssues, upsertIssue } from './api';
import {
  ISSUES_TARGET_SEARCH_FETCH_START,
  ISSUES_QUERY_FETCH_START,
  ISSUE_UPSERT,
} from './constants';
import { contactSearchSchema, issueSchema, issuesQuerySchema } from './types';

export function* fetchIssuesQueryWorker(action) {
  const { filter, sortBy, sortDirection, continuationToken } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) =>
      call(
        fetchIssues,
        filter,
        sortBy,
        sortDirection,
        token,
        continuationToken,
      ),
    (response) =>
      put(
        fetchIssuesQuerySuccess(
          normalize(response.data.results, issuesQuerySchema),
          response.data.continuationToken,
          continuationToken !== null,
        ),
      ),
    (error) => fetchIssuesQueryFail(error),
  );
}

export function* fetchIssuesQueryWatcher() {
  yield takeLatest(ISSUES_QUERY_FETCH_START, fetchIssuesQueryWorker);
}

export function* fetchTargetsWorker(action) {
  const { value } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(fetchTargets, value, token),
    (response) =>
      put(
        fetchTargetsSuccess(
          normalize(response.data.autocomplete, contactSearchSchema),
        ),
      ),
    (error) => fetchTargetsFail(error),
  );
}

export function* fetchTargetsWatcher() {
  yield takeLatest(ISSUES_TARGET_SEARCH_FETCH_START, fetchTargetsWorker);
}

export function* upsertIssueWorker(action) {
  const { optimisticId, issue } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(upsertIssue, issue, token),
    (response) => {
      const data = normalize(response.data, issueSchema);
      return put(upsertIssueSuccess(optimisticId, data.result, data));
    },
    (error) => upsertIssueFail(optimisticId, error),
  );
}

export function* upsertIssueWatcher() {
  yield takeEvery(ISSUE_UPSERT, upsertIssueWorker);
}
