import { OrderedSet, Record } from 'immutable';
import React, { useState } from 'react';
import Table from '../../../common/components/Table/Table';
import styles from './ClaimViewer.css';
import columnDefinitions from './columns';

const ClaimViewer = ({ claims, hasFetched }) => {
  const [sortBy, setSortBy] = useState('FilledDate');
  const [sortDirection, setSortDirection] = useState('DESC');

  const sort = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  const sortedClaims = claims.sort((valueA, valueB) => {
    const column = columnDefinitions.find((def) => def.id == sortBy);
    const a = column.valueSelector(valueA);
    const b = column.valueSelector(valueB);

    if (a === undefined && b === undefined) {
      return 0;
    }

    if (a === undefined) {
      return sortDirection === 'ASC' ? 1 : -1;
    }

    if (b === undefined) {
      return sortDirection === 'ASC' ? -1 : 1;
    }

    if (sortDirection === 'ASC') {
      if (a > b) return 1;
      else if (a < b) return -1;
    } else if (sortDirection === 'DESC') {
      if (a > b) return -1;
      else if (a < b) return 1;
    }
    return 0;
  });

  return (
    <div className={styles.container}>
      <Table
        isSortEnabled
        data={hasFetched ? sortedClaims : new OrderedSet()}
        columnDefinitions={columnDefinitions}
        isFetching={!hasFetched}
        sortBy={sortBy}
        sortDirection={sortDirection}
        loadMoreRows={() => {}}
        hasNextPage={false}
        onSort={sort}
        onHeaderClick={() => {}}
      />
    </div>
  );
};

export default ClaimViewer;
