import { Avatar as MaterialUiAvatar, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from './Avatar.css';

const Avatar = ({ user, textOnly, classes }) => {
  return (
    <Tooltip
      arrow
      title={`${user.name} (${user.email})`}
      placement="left"
      trigger="mouseenter"
    >
      <div className={styles.container}>
        {!textOnly && !isNil(user.pictureUrl) && (
          <MaterialUiAvatar classes={classes} src={user.pictureUrl} />
        )}
        {!textOnly && isNil(user.pictureUrl) && !isNil(user.name) && (
          <MaterialUiAvatar
            classes={classes}
            style={{ backgroundColor: '#757575' }}
          >
            {/* Pick the first letter of first and last name */}
            <div className={styles.text}>
              {user.name
                .match(/\b(\w)/g)
                .join('')
                .toUpperCase()}
            </div>
          </MaterialUiAvatar>
        )}
        {!textOnly &&
          isNil(user.pictureUrl) &&
          isNil(user.name) &&
          !isNil(user.email) && (
            <MaterialUiAvatar
              classes={classes}
              style={{ backgroundColor: '#757575' }}
            >
              {/* Worst case scenario pick the first two letters from their email */}
              <div className={styles.text}>
                {user.email.substring(0, 2).toUpperCase()}
              </div>
            </MaterialUiAvatar>
          )}
        {textOnly && (
          <div className={styles.textOnly}>
            {!isNil(user.name) ? user.name : user.email}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

Avatar.propTypes = {
  user: ImmutablePropTypes.recordOf({
    pictureUrl: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  textOnly: PropTypes.bool,
  classes: PropTypes.object,
};

Avatar.defaultProps = {
  textOnly: false,
};

export default Avatar;
