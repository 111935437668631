import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { GraphqlTypes } from 'app/common/types';
import { IssueFilterType } from 'app/filters/constants';
import { enumDefaults } from 'app/filters/definitions/defaults';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class COBIndicatorConfig
  extends Record({
    ...enumDefaults(GraphqlTypes.cobIndicatorEnum),
    title: 'COB Indicator',
    name: 'coordinationOfBenefitsIndicators',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.coordinationOfBenefitsIndicator)) return false;

      return value
        .map((v) => v.toUpperCase())
        .contains(workItem.claim.coordinationOfBenefitsIndicator.toUpperCase());
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      Set<string>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new COBIndicatorConfig();
