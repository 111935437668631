import { Map, Record, Set } from 'immutable';
import { schema } from 'normalizr';
import { GraphqlTypes } from 'app/common/types';
import { pharmacySchema } from 'app/pharmacies/types';
import { patientSchema } from '../patient/types';
import { prescriberSchema } from '../prescribers/types';
import { claim as claimSchema } from '../workitems/types';

export class IssuesFilterStateRecord extends Record({
  campaigns: Set(),
  targets: Set(),
  hasCase: false,
  isCampaignSystemManaged: null,
  isEffective: true,
}) {}

export const targetSchema = new schema.Union(
  {
    patient: patientSchema,
    prescriber: prescriberSchema,
  },
  (value, parent, key) => {
    switch (value.__typename) {
      case GraphqlTypes.Patient:
        return 'patient';
      case GraphqlTypes.Prescriber:
        return 'prescriber';
      default:
        throw new Error(
          `Unsupported value type in targetSchema: ${value.__typename}`,
        );
    }
  },
);

export class IssueUpsertRecord extends Record({
  campaignEntityId: '',
  supportingDocument: '',
}) {}

export class IssueRecord extends Record({
  id: '',
  number: '',
  createdDate: new Date(),
  supportingDocuments: Set<string>(),
  campaign: '',
  target: '',
  contact: '',
  effectiveDates: Set<Interval>(),
}) {}

export class IssuesStateRecord extends Record({
  issues: Map(),
  filters: new IssuesFilterStateRecord(),
}) {}

export const supportingDocumentsSchema = new schema.Union(
  {
    claim: claimSchema,
    patient: patientSchema,
  },
  (value, parent, key) => {
    switch (value.__typename) {
      case GraphqlTypes.Patient:
        return 'patient';
      case GraphqlTypes.Claim:
        return 'claim';
      default:
        throw new Error(
          `Unsupported supporting document type: ${value.__typename}`,
        );
    }
  },
);

export const issueSchema = new schema.Entity('issues', {
  supportingDocuments: new schema.Array(supportingDocumentsSchema),
  target: targetSchema,
  contact: targetSchema,
});

export const continuationToken = new schema.Entity('continuationToken');

export const issuesQuerySchema = new schema.Array(issueSchema);

export const contactSearchSchema = new schema.Array(
  {
    patients: patientSchema,
    prescribers: prescriberSchema,
    pharmacies: pharmacySchema,
  },
  (value, parent, key) => {
    switch (value.__typename) {
      case GraphqlTypes.Patient:
        return 'patients';
      case GraphqlTypes.Prescriber:
        return 'prescribers';
      case GraphqlTypes.Pharmacy:
        return 'pharmacies';
      default:
        throw new Error(
          `Unsupported value type in contact search: ${value.__typename}`,
        );
    }
  },
);

export enum TargetType {
  Patient = 'PATIENT',
  Prescriber = 'PRESCRIBER',
  Pharmacy = 'PHARMACY',
}
