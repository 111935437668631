import { isNil } from 'ramda';

export default function (strings: readonly string[], ...values: any[]) {
  return strings
    .map((x, i) => {
      if (!isNil(values[i])) {
        return x.concat(values[i]);
      }
      return x;
    })
    .join('');
}
