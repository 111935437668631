import { createReducer } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { Map, Set } from 'immutable';
import { omit, isNil } from 'ramda';
import { CASE_FETCH_SUCCESS } from 'app/cases/constants';
import { PhoneNumberRecord } from 'app/common/types';
import { ISSUE_UPSERT_SUCCESS } from 'app/issues/constants';
import { QUEUE_FETCH_NEXT_SESSION_SUCCESS } from 'app/queue/constants';
import { SEARCH_SUCCESS } from 'app/search/constants';
import { PharmaciesStateRecord, PharmacyRecord } from './types';

const clean = (pharmacy: { __typename: string; id: string }) =>
  omit(['__typename'], pharmacy);

const pharmaciesReducer = createReducer(
  new PharmaciesStateRecord(),
  (builder) => {
    builder
      .addMatcher(
        (action) =>
          [
            QUEUE_FETCH_NEXT_SESSION_SUCCESS,
            CASE_FETCH_SUCCESS,
            SEARCH_SUCCESS,
            ISSUE_UPSERT_SUCCESS,
          ].includes(action.type),
        (state: any, action: any) => {
          const pharmacies = Map(action.payload.data.entities.pharmacies);

          return state.merge({
            pharmacies:
              // TODO investigate why immer/typescript seems to be messing up the nested Map typings
              (state as unknown as PharmaciesStateRecord).pharmacies.merge(
                pharmacies.map((p: any) => {
                  // const pharmacy = clean(p);
                  const existingPharmacy =
                    state.pharmacies.get(p.id) || new PharmacyRecord();
                  let { phoneNumbers } = existingPharmacy;
                  if (!isNil(p.phoneNumbers)) {
                    phoneNumbers = Set(
                      p.phoneNumbers.map(
                        (pn: any) =>
                          new PhoneNumberRecord({
                            phoneNumber: pn.phoneNumber,
                            isPrimary: pn.isPrimary,
                            source: pn.source,
                            addedOn: parseISO(pn.addedOn),
                          }),
                      ),
                    );
                  }
                  return existingPharmacy.merge({
                    ...omit(['__typename'], p),
                    phoneNumbers,
                  });
                }),
              ) as Map<string, PharmacyRecord>,
          });
        },
      )
      .addDefaultCase((state) => state);
  },
);

export default pharmaciesReducer;
