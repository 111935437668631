import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import NumericSelectCombo from 'app/filters/components/NumericSelectCombo';
import {
  numericOperatorConfig,
  IssueFilterType,
  NumericOperators,
} from 'app/filters/constants';
import { numericComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  InitialOpioidSupplyWindow,
  InitialOpioidSupplyFilterRecord,
  NumericComparison,
  NumericComparisonBackendRecord,
  InitialOpioidSupplyTemplateRecord,
  NumericSelectOptionsRecord,
  InitialOpioidWindowSelectValueRecord,
  NumericOperator,
  IClaimFilterConfiguration,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';
import { WorkItemRecord } from 'app/workitems/types';

const formatSelectText = (window: InitialOpioidSupplyWindow) => {
  switch (window) {
    case InitialOpioidSupplyWindow.Thirty:
      return '30';
    case InitialOpioidSupplyWindow.Sixty:
      return '62';
    default:
      throw new Error('Unrecognized window.');
  }
};

const summaryFormatter = (value: InitialOpioidSupplyFilterRecord) => {
  if (isNil(value.numericComparison.value))
    return `Any amount in first ${value.window.text} days`;
  return `${
    numericOperatorConfig(value.numericComparison.operator.value).summary
  } 
    ${value.numericComparison.value} in first ${value.window.text} days`;
};

const dependencyValueConverter =
  (converter: (value: NumericComparison) => NumericComparisonBackendRecord) =>
  (value: InitialOpioidSupplyFilterRecord) =>
    new InitialOpioidSupplyTemplateRecord({
      numericComparison: converter(value.numericComparison),
      window: value.window.value,
    });

class InitialOpioidSupplyConfig
  extends Record({
    enabled: true,
    targetType: IssueFilterType.Claim,
    workflowSpecific: false,
    name: 'initialOpioidSupply',
    title: 'Initial Opioid Supply',
    storedValueType: StoredFilterType.InitialOpioidSupply,
    summaryFormatter,
    options: (token: string) =>
      new NumericSelectOptionsRecord({
        numericComparison: numericComparisonDefaults.options(token),
        select: OrderedSet([
          new InitialOpioidWindowSelectValueRecord({
            text: formatSelectText(InitialOpioidSupplyWindow.Thirty),
            value: InitialOpioidSupplyWindow.Thirty,
          }),
          new InitialOpioidWindowSelectValueRecord({
            text: formatSelectText(InitialOpioidSupplyWindow.Sixty),
            value: InitialOpioidSupplyWindow.Sixty,
          }),
        ]),
      }),
    defaultValue: () =>
      new InitialOpioidSupplyFilterRecord({
        numericComparison: new NumericComparison({
          value: 15,
          operator: new NumericOperator({
            text: numericOperatorConfig(NumericOperators.Greater).text,
            value: NumericOperators.Greater,
          }),
        }),
        window: new InitialOpioidWindowSelectValueRecord({
          text: formatSelectText(InitialOpioidSupplyWindow.Thirty),
          value: InitialOpioidSupplyWindow.Thirty,
        }),
      }),
    component: (props: any) => <NumericSelectCombo {...props} />,
    storedValueConstructor: (value: {
      numericComparison: { number: number; operator: string };
      window: string;
    }) =>
      new InitialOpioidSupplyTemplateRecord({
        numericComparison: new NumericComparisonBackendRecord({
          number: value.numericComparison.number,
          operator: value.numericComparison.operator as NumericOperators,
        }),
        window: value.window as InitialOpioidSupplyWindow,
      }),
    valueToQueryConverter: dependencyValueConverter(
      numericComparisonDefaults.valueToQueryConverter,
    ),
    filterToTemplateValueConverter: dependencyValueConverter(
      numericComparisonDefaults.filterToTemplateValueConverter,
    ),
    templateToFilterValueConverter: (
      value: InitialOpioidSupplyTemplateRecord,
    ) =>
      new InitialOpioidSupplyFilterRecord({
        numericComparison:
          numericComparisonDefaults.templateToFilterValueConverter(
            value.numericComparison,
          ),
        window: new InitialOpioidWindowSelectValueRecord({
          text: formatSelectText(value.window),
          value: value.window,
        }),
      }),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: InitialOpioidSupplyTemplateRecord,
    ) => {
      if (isNil(value.numericComparison.number)) return false; // This will never get hit practically
      let riskSupply = null;
      switch (value.window) {
        case InitialOpioidSupplyWindow.Thirty:
          riskSupply = workItem.claim.initialOpioidSupplyThirtyDays;
          break;
        case InitialOpioidSupplyWindow.Sixty:
          riskSupply = workItem.claim.initialOpioidSupplySixtyDays;
          break;
        default:
          throw new Error(
            'Unkown value supplied for initial opioid supply window.',
          );
      }
      if (isNil(riskSupply)) return false;

      return numericOperatorConfig(value.numericComparison.operator).filter(
        riskSupply,
        value.numericComparison.number,
      );
    },
  })
  implements
    IClaimFilterConfiguration<
      InitialOpioidSupplyFilterRecord,
      NumericSelectOptionsRecord,
      InitialOpioidSupplyTemplateRecord,
      InitialOpioidSupplyTemplateRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new InitialOpioidSupplyConfig();
