import { Map } from 'immutable';
import { persistReducer } from '../../../hydrate/persist';
import {
  COMMENT_ADD,
  COMMENT_ADD_SUCCESS,
  COMMENT_EDIT,
  COMMENT_EDIT_SUCCESS,
} from '../../constants';
import { CommentRecord } from '../../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case COMMENT_ADD: {
      const { comment } = action.payload;
      return state.mergeDeep({
        [comment.id]: new CommentRecord(comment),
      });
    }
    case COMMENT_ADD_SUCCESS: {
      return state.delete(action.payload.optimisticId);
    }
    case COMMENT_EDIT: {
      const { id, message } = action.payload;
      return state.merge({
        [id]: new Map({ message }),
      });
    }
    case COMMENT_EDIT_SUCCESS: {
      return state.delete(action.payload.id);
    }
    default:
      return state;
  }
};

export default persistReducer('comments/optimistic', reducer);
