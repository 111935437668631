import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn, signInFailed } from '../../../auth/actions';
import {
  UNAUTHORIZED_URL,
  UNEXPECTED_ERROR_URL,
} from '../../../common/constants';

export class CallbackContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount = async () => {
    const { dispatch, location } = this.props;

    if (location.search) {
      var params = new URLSearchParams(location.search);
      var code = params.get('code');
      var error = params.get('error');
      var state = params.get('state');

      if (code) {
        dispatch(signIn(code, state));
        return;
      }

      if (error) {
        dispatch(signInFailed(error, state));
        await navigate(UNAUTHORIZED_URL);
        return;
      }
    }

    await navigate(UNEXPECTED_ERROR_URL);
  };

  render() {
    return <div />;
  }
}

export default connect()(CallbackContainer);
