import { OrderedSet } from 'immutable';
import {
  CASES_FILTER_UPDATE,
  CASES_QUERY_CLEAR,
  CASES_QUERY_FETCH_FAIL,
  CASES_QUERY_FETCH_SUCCESS,
  CASES_SORT_APPLY,
} from '../../cases/constants';
import { CaseListRecord } from '../types';

const caseListReducer = (state = CaseListRecord(), action) => {
  switch (action.type) {
    case CASES_QUERY_FETCH_SUCCESS:
      return state.mergeDeep({
        continuationToken: action.payload.continuationToken,
        error: null,
        queryResults: new OrderedSet(action.payload.data.result),
      });
    case CASES_QUERY_FETCH_FAIL:
      return state.merge({
        error: action.payload.error,
      });
    case CASES_QUERY_CLEAR:
    case CASES_FILTER_UPDATE: {
      return state.merge({
        queryResults: new OrderedSet(),
        continuationToken: null,
      });
    }
    case CASES_SORT_APPLY: {
      return state.mergeDeep({
        sorting: {
          sortBy: action.payload.sortBy,
          sortDirection: action.payload.sortDirection,
        },
      });
    }
    default:
      return state;
  }
};

export default caseListReducer;
