import { OrderedSet, Record } from 'immutable';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import { IssueFilterType } from 'app/filters/constants';
import { matchStringsDefaults } from 'app/filters/definitions/defaults';
import { drugListsQuery } from 'app/filters/queries';
import {
  IFilterConfiguration,
  MultiSelectMatchFilterRecord,
  SelectValueRecord,
  MatchStringsInputRecord,
  MultiSelectMatchStoredValue,
} from 'app/filters/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(drugListsQuery, {}, token)
    .then(throwIfErrors)
    .then((data) => data.data.drugLists)
    .then((drugLists: Array<{ id: string; name: string }>) =>
      OrderedSet(
        drugLists.map(
          (d) =>
            new SelectValueRecord({
              text: d.name,
              value: d.id,
            }),
        ),
      ),
    );

class ExcludeDrugHistoryConfig
  extends Record({
    ...matchStringsDefaults,
    name: 'excludeDrugHistory',
    title: 'Exclude Drug History',
    targetType: IssueFilterType.Patient,
    options: (token: string) => fetchOptions(token),
  })
  implements
    IFilterConfiguration<
      MultiSelectMatchFilterRecord,
      OrderedSet<SelectValueRecord>,
      MatchStringsInputRecord,
      MultiSelectMatchStoredValue
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExcludeDrugHistoryConfig();
