import {
  COMMENT_ADD,
  COMMENT_ADD_SUCCESS,
  COMMENT_ADD_FAIL,
  COMMENT_EDIT,
  COMMENT_EDIT_FAIL,
  COMMENT_EDIT_SUCCESS,
  COMMENT_DELETE,
  COMMENT_DELETE_FAIL,
  COMMENT_DELETE_SUCCESS,
} from './constants';

export function addComment(workItemId, comment) {
  return {
    type: COMMENT_ADD,
    payload: {
      workItemId,
      comment,
    },
  };
}

export function addCommentSuccess(optimisticId, data) {
  return { type: COMMENT_ADD_SUCCESS, payload: { optimisticId, data } };
}

export function addCommentFail(workItemId, optimisticId, error, timestamp) {
  return {
    type: COMMENT_ADD_FAIL,
    payload: { workItemId, optimisticId, error, timestamp },
  };
}

export function editComment(id, workItemId, message) {
  return {
    type: COMMENT_EDIT,
    payload: {
      id,
      workItemId,
      message,
    },
  };
}

export function editCommentSuccess(id, data) {
  return { type: COMMENT_EDIT_SUCCESS, payload: { id, data } };
}

export function editCommentFail(id, workItemId, error, timestamp) {
  return {
    type: COMMENT_EDIT_FAIL,
    payload: { id, workItemId, error, timestamp },
  };
}

export function deleteComment(id, workItemId) {
  return {
    type: COMMENT_DELETE,
    payload: {
      id,
      workItemId,
    },
  };
}

export function deleteCommentSuccess(id) {
  return { type: COMMENT_DELETE_SUCCESS, payload: { id } };
}

export function deleteCommentFail(id, workItemId, error, timestamp) {
  return {
    type: COMMENT_DELETE_FAIL,
    payload: { id, workItemId, error, timestamp },
  };
}
