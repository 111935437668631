import { combineReducers } from 'redux-immutable';
import { CommentStateRecord } from '../types';
import optimistic from './optimistic';
import comments from './reducer';

const reducer = combineReducers(
  {
    optimistic,
    comments,
  },
  CommentStateRecord,
);

export default reducer;
