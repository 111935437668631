import { convertToRaw, convertFromRaw } from 'draft-js';

export default [
  {
    key: 'comments/optimistic',
    selector: (state) => state.commentsState.optimistic.comments,
    preSave: (state) =>
      state.map((c) => c.merge({ message: convertToRaw(c.get('message')) })),
    postLoad: (state) =>
      state.map((c) =>
        c.merge({ message: convertFromRaw(c.get('message').toJS()) }),
      ),
  },
  {
    key: 'ui/comments',
    selector: (state) => state.uiState.workItemDetail.comments,
  },
  {
    key: 'workItems/optimistic',
    selector: (state) => state.workItemsState.optimistic.workItems,
  },
  {
    key: 'notifications',
    selector: (state) => state.notificationState,
  },
  {
    key: 'ui/workitemlist',
    selector: (state) => state.uiState.workItemList.filterSidebar,
    preSave: (state) => ({ isMinimized: state.isMinimized }),
  },
  {
    key: 'cases/optimistic',
    selector: (state) => state.casesState.optimistic,
  },
];
