import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { sortWith, filter, prop, ascend, descend, pipe } from 'ramda';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Tag from '../../../../common/components/Tag';
import { toArray } from '../../../../common/utilities/generic';
import styles from './WorkItemInfo.css';

const filterDeprecatedStatuses = (currentStatus) =>
  filter((status) => status.isDeprecated === false || currentStatus === status);
const typeNameSort = sortWith([descend(prop('type')), ascend(prop('name'))]);

const StatusSelector = ({
  currentStatus,
  statuses,
  onChangeStatus,
  isFetchingStatuses,
}) => {
  const filterAndSortStatuses = pipe(
    toArray,
    filterDeprecatedStatuses(currentStatus),
    typeNameSort,
  );

  return (
    <Select
      value={currentStatus.id}
      onChange={(event) => onChangeStatus(event.target.value)}
      disabled={isFetchingStatuses && statuses.isEmpty()}
      renderValue={(value) => {
        const status = statuses.find((s) => s.id === value);
        return (
          <div className={styles.selectedItemContainer}>
            <Tag
              className={classnames({
                [styles.tag]: true,
                [styles.blue]: status.type === 'OPEN',
                [styles.orange]: status.type === 'INPROGRESS',
                [styles.green]: status.type === 'DONE',
              })}
              text={status.name}
            />
          </div>
        );
      }}
    >
      {filterAndSortStatuses(statuses).map((status) => (
        <MenuItem key={status.id} value={status.id}>
          <div className={styles.optionContainer}>
            <div
              className={classnames({
                [styles.deprecated]: status.isDeprecated,
              })}
            >
              {status.name}
            </div>
            {status.isDeprecated && <Tag text="DISABLED" />}
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

StatusSelector.propTypes = {
  currentStatus: ImmutablePropTypes.record.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  statuses: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  isFetchingStatuses: PropTypes.bool.isRequired,
};

const AssigneeSelector = ({
  assignee,
  users,
  isFetchingUsers,
  onChangeAssignee,
}) => {
  let options = users;
  if (assignee && !users.has(assignee.id)) {
    options = options.set(assignee.id, assignee);
  }
  const menuItems = options
    .sort((a, b) => a.name.localeCompare(b.name))
    .toStack()
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
        {user.name}
      </MenuItem>
    ))
    .push(
      <MenuItem key="unassigned" value="unassigned">
        Unassigned
      </MenuItem>,
    );

  return (
    <Select
      value={assignee ? assignee.id : 'unassigned'}
      onChange={(event) => onChangeAssignee(options.get(event.target.value))}
      disabled={isFetchingUsers && users.isEmpty()}
    >
      {menuItems}
    </Select>
  );
};

AssigneeSelector.propTypes = {
  assignee: ImmutablePropTypes.recordOf({
    id: PropTypes.string.isRequired,
  }),
  users: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.string.isRequired,
  ).isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  onChangeAssignee: PropTypes.func.isRequired,
};

AssigneeSelector.defaultProps = {
  assignee: null,
};

const WorkItemInfo = ({
  workItem,
  isFetchingWorkItem,
  errorFetchingWorkItem,
  users,
  statuses,
  isFetchingUsers,
  onChangeAssignee,
  onChangeStatus,
  isFetchingStatuses,
  hasFetchedDetails,
}) => (
  <div className={styles.rightHeader}>
    <h5 className={styles.h5}>Info</h5>
    {workItem &&
      hasFetchedDetails &&
      !isFetchingWorkItem &&
      !errorFetchingWorkItem && (
        <div className={styles.panelRow}>
          <div className={styles.panelLeftColumn}>Status</div>
          <div className={styles.panelRightColumn}>
            <StatusSelector
              currentStatus={workItem.status}
              statuses={statuses}
              onChangeStatus={onChangeStatus}
              isFetchingStatuses={isFetchingStatuses}
            />
            {isFetchingStatuses && (
              <div className={styles.panelRightSpinner}>
                <CircularProgress size={18} />
              </div>
            )}
          </div>
        </div>
      )}
    {workItem &&
      hasFetchedDetails &&
      !isFetchingWorkItem &&
      !errorFetchingWorkItem && (
        <div className={styles.panelRow}>
          <div className={styles.panelLeftColumn}>Assignee</div>
          <div className={styles.panelRightColumn}>
            <AssigneeSelector
              assignee={workItem.assignee}
              users={users}
              isFetchingUsers={isFetchingUsers}
              onChangeAssignee={onChangeAssignee}
            />
            {isFetchingUsers && (
              <div className={styles.panelRightSpinner}>
                <CircularProgress size={18} />
              </div>
            )}
          </div>
        </div>
      )}
  </div>
);

WorkItemInfo.propTypes = {
  workItem: ImmutablePropTypes.recordOf({
    status: ImmutablePropTypes.record.isRequired,
    assignee: ImmutablePropTypes.recordOf({
      id: PropTypes.string.isRequired,
    }),
  }),
  isFetchingWorkItem: PropTypes.bool.isRequired,
  errorFetchingWorkItem: PropTypes.object,
  users: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.string.isRequired,
  ).isRequired,
  statuses: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  onChangeAssignee: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  isFetchingStatuses: PropTypes.bool.isRequired,
};

WorkItemInfo.defaultProps = {
  errorFetchingWorkItem: null,
  workItem: null,
};

export default WorkItemInfo;
