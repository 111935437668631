import {
  WORKFLOW_DEFINITION_QUERY_FETCH_FAIL,
  WORKFLOW_DEFINITION_QUERY_FETCH_SUCCESS,
} from './constants';
import {
  WorkflowDefinitionRecord,
  WorkflowDefinitionsStateRecord,
} from './types';

const mergeWorkflowDefinition = (
  newWorkflowDef,
  existingWorkflowDef = new WorkflowDefinitionRecord(),
) => {
  return existingWorkflowDef
    .merge(newWorkflowDef)
    .set('name', newWorkflowDef.name ?? 'Default');
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function reducer(state = new WorkflowDefinitionsStateRecord(), action) {
  switch (action.type) {
    case WORKFLOW_DEFINITION_QUERY_FETCH_SUCCESS: {
      const { workflowDefinitions } = action.payload;
      const update = workflowDefinitions.map((workflowDefinition) => {
        const existingDefinition = state.workflowDefinitions.get(
          workflowDefinition.id,
        );
        if (existingDefinition) {
          return {
            [workflowDefinition.id]: mergeWorkflowDefinition(
              workflowDefinition,
              existingDefinition,
            ),
          };
        }
        return {
          [workflowDefinition.id]: mergeWorkflowDefinition(workflowDefinition),
        };
      });

      return state.merge({
        workflowDefinitions: state.workflowDefinitions.merge(...update),
      });
    }
    case WORKFLOW_DEFINITION_QUERY_FETCH_FAIL:
      return state.merge({ error: action.payload.error });
    default:
      return state;
  }
}

export default reducer;
