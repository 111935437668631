import { createAction } from '@reduxjs/toolkit';
import { FilterFetchStart } from 'app/ui/types';
import {
  FILTERS_FETCH_START,
  FILTERS_FETCH_SUCCESS,
  FILTERS_FETCH_FAIL,
} from './constants';
import { FetchFiltersResponse } from './types';

export function fetchFilters(filterType) {
  return { type: FILTERS_FETCH_START, payload: { filterType } };
}

export const fetchFiltersSuccess = createAction(
  FILTERS_FETCH_SUCCESS,
  (filterSchema, filterType) => {
    return {
      payload: new FetchFiltersResponse({
        filters: filterSchema,
        filterType,
      }),
    };
  },
);

export function fetchFiltersFail(error) {
  return { type: FILTERS_FETCH_FAIL, payload: { error } };
}
