import {
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import {
  AssignmentInd,
  ZoomIn,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Link } from '@reach/router';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';
import PropTypes from 'prop-types';
import { dec, inc, isNil, values } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { GraphqlTypes } from 'app/common/types';
import { getCompletedInteractions } from 'app/interactions/utility';
import Rx from '../../../common/images/rx.svg';
import { formatCurrency } from '../../../common/utilities/generic';
import InteractionTimeline from '../../../interactions/components/Timeline/Timeline';
import { TargetType } from '../../../issues/types';
import BasicInfoItem from '../../../patient/components/BasicInfoItem/BasicInfoItem';
import PatientDetails from '../../../patient/components/PatientProfile/PatientDetails/PatientDetails';
import DrugOverviewChart from '../../../patient/containers/DrugOverviewChart/DrugOverviewChart';
import PrescriberDetails from '../../../prescribers/components/PrescriberDetails';
import CaseStatus from '../CaseStatus/CaseStatus';
import styles from './CaseDetails.css';

const dateFormat = 'MM/dd/yyyy';

const format = (value) => {
  if (isNil(value)) return '';
  return formatDate(value, dateFormat);
};

const PaperLoading = () => (
  <>
    <Skeleton width={80} variant="circle" className={styles.basicInfoIcon} />
    <Skeleton width={200} className={styles.basicPrimaryInfo} />
    {new Array(Math.floor(Math.random() * 7 + 1)).fill('').map((x, i) => (
      <Skeleton key={`loading-${i}`} width="100%" height={30} />
    ))}
  </>
);

const ClaimOverview = ({ activeClaim }) => (
  <>
    <Rx className={styles.basicInfoIconCustom} />
    <div className={styles.basicPrimaryInfo}>Prescription</div>
    {/* TODO:  Does it make sense to link to work items here if they have claim viewer
     * OR should we just link to the claims tab?
     */}
    <BasicInfoItem
      label="Claim Number"
      value={
        <Link to={`/workitems/${activeClaim.workItem.id}`}>
          {activeClaim.claimNumber}
        </Link>
      }
    />
    <BasicInfoItem label="Drug Name" value={activeClaim.drugName} />
    <BasicInfoItem label="Rx Number" value={activeClaim.rxNumber} />
    <BasicInfoItem label="Filled Date" value={format(activeClaim.filledDate)} />
    <BasicInfoItem label="Quantity" value={activeClaim.quantityDispensed} />
    <BasicInfoItem label="Days Supply" value={activeClaim.daysSupply} />
    <BasicInfoItem label="Copay" value={formatCurrency(activeClaim.copay)} />
  </>
);

const DrugOverviewDialog = ({
  onDrugOverviewClose,
  isDrugOverviewOpen,
  id,
}) => {
  return (
    <Dialog
      open={isDrugOverviewOpen}
      onClose={onDrugOverviewClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>Drug Overview</DialogTitle>
      <DialogContent>
        <div style={{ height: '80vh' }}>
          <DrugOverviewChart id={id} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDrugOverviewClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CaseDetails = ({
  caseItem,
  supportingDocuments,
  onSupportingDocumentStep,
  activeStep,
  isLoading,
  hasFetched,
}) => {
  const [isDrugOverviewOpen, setIsDrugOverviewOpen] = useState(false);

  const onDrugOverviewClose = () => setIsDrugOverviewOpen(false);

  const interactionTimelineScrollbar = useRef();
  useEffect(() => {
    interactionTimelineScrollbar.current.scrollToBottom();
  }, [caseItem]);

  const activeDocument =
    supportingDocuments.get(activeStep) ?? supportingDocuments.first();

  const handleNext = () => {
    onSupportingDocumentStep(inc);
  };

  const handleBack = () => {
    onSupportingDocumentStep(dec);
  };

  const renderSupportingDocument = () => {
    if (activeDocument.__typename === GraphqlTypes.Claim) {
      return <ClaimOverview activeClaim={activeDocument} />;
    }

    if (activeDocument.__typename === GraphqlTypes.Patient) {
      return <PatientDetails patient={activeDocument} />;
    }

    return <></>;
  };

  const completedInteractions = getCompletedInteractions(
    caseItem?.interactions,
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <Scrollbars>
          <div className={styles.overviewContainerWrapper}>
            <div className={styles.overviewContainer}>
              <div className={classnames(styles.row, styles.grow)}>
                <Paper classes={{ root: styles.basicInfo }}>
                  {isLoading && <PaperLoading />}
                  {!isLoading && hasFetched && (
                    <>
                      <AssignmentInd className={styles.basicInfoIcon} />
                      <div className={styles.basicPrimaryInfo}>Case</div>
                      <BasicInfoItem label="Number" value={caseItem.number} />
                      <BasicInfoItem
                        label="Campaign"
                        value={caseItem.campaign?.name}
                      />
                      <BasicInfoItem
                        label="Status"
                        value={<CaseStatus status={caseItem.status} />}
                      />
                      <BasicInfoItem
                        label="Number of Interactions"
                        value={completedInteractions.length}
                      />
                    </>
                  )}
                </Paper>
                <Paper classes={{ root: styles.basicInfo }}>
                  {isLoading && <PaperLoading />}
                  {!isLoading &&
                    hasFetched &&
                    caseItem.campaign.target === TargetType.Patient && (
                      <PatientDetails patient={caseItem.target} />
                    )}
                  {!isLoading &&
                    hasFetched &&
                    caseItem.campaign.target === TargetType.Prescriber && (
                      <PrescriberDetails prescriber={caseItem.target} />
                    )}
                </Paper>
                {(isLoading || !supportingDocuments.isEmpty()) && (
                  <Paper classes={{ root: styles.basicInfoNoPadding }}>
                    <div className={styles.detailsPane}>
                      {isLoading && <PaperLoading />}
                      {!isLoading && hasFetched && renderSupportingDocument()}
                    </div>
                    <div className={styles.footer}>
                      {!isLoading &&
                        hasFetched &&
                        supportingDocuments.size > 1 && (
                          <MobileStepper
                            variant="dots"
                            steps={supportingDocuments.size}
                            position="static"
                            activeStep={activeStep}
                            classes={{ root: styles.stepperBackground }}
                            nextButton={
                              <Button
                                size="small"
                                onClick={handleNext}
                                disabled={
                                  supportingDocuments.size - 1 === activeStep
                                }
                              >
                                Next
                                <KeyboardArrowRight />
                              </Button>
                            }
                            backButton={
                              <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                              >
                                <KeyboardArrowLeft />
                                Back
                              </Button>
                            }
                          />
                        )}
                    </div>
                  </Paper>
                )}
              </div>
              {!isLoading &&
                caseItem?.campaign?.target === TargetType.Patient && (
                  <div className={styles.row}>
                    <Paper classes={{ root: styles.claimsTrend }}>
                      <div className={styles.paperTitle}>
                        Drug Overview{'  '}
                        <ZoomIn
                          classes={{ root: styles.detailRowHelper }}
                          onClick={() => setIsDrugOverviewOpen(true)}
                        />
                      </div>
                      <DrugOverviewChart id={caseItem?.target?.id} />
                      <DrugOverviewDialog
                        isDrugOverviewOpen={isDrugOverviewOpen}
                        onDrugOverviewClose={onDrugOverviewClose}
                        id={caseItem?.target?.id}
                      />
                    </Paper>
                  </div>
                )}
            </div>
          </div>
        </Scrollbars>
      </div>
      <div className={styles.column}>
        <Paper classes={{ root: styles.rightContainer }}>
          <Scrollbars ref={interactionTimelineScrollbar}>
            <div className={styles.paper}>
              <InteractionTimeline
                isLoading={isLoading || !hasFetched}
                activeCaseId={caseItem?.id}
              />
            </div>
          </Scrollbars>
        </Paper>
      </div>
    </div>
  );
};

CaseDetails.propTypes = {
  caseItem: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    number: PropTypes.string,
    interactions: PropTypes.arrayOf(PropTypes.object),
    target: PropTypes.shape({
      id: PropTypes.string,
    }),
    campaign: PropTypes.shape({
      target: PropTypes.oneOf(values(TargetType)),
      name: PropTypes.string,
    }),
  }),
  supportingDocuments: ImmutablePropTypes.listOf(PropTypes.object).isRequired,
  onSupportingDocumentStep: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
};

CaseDetails.defaultProps = {
  caseItem: null,
};

export default CaseDetails;
