import FileSaver from 'file-saver';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { getAccessToken } from '../user/selectors';
import {
  exportCaseDownloadFetchFail,
  exportCaseDownloadFetchSuccess,
  exportDownloadComplete,
  exportDownloadFail,
} from './actions';
import { exportDownloadRequest, exportCaseDownloadRequest } from './api';
import {
  EXPORT_CASE_DOWNLOAD_FETCH_START,
  EXPORT_DOWNLOAD_REQUEST,
} from './constants';

export function* exportWorker(apiRequest, onSuccess, onFail, action) {
  try {
    const token = yield select(getAccessToken);
    const exportData = yield call(apiRequest, action.payload.query, token);

    yield call(FileSaver.saveAs, exportData.content, exportData.filename);
    yield put(onSuccess());
  } catch (e) {
    yield put(onFail(e));
  }
}

export function* exportDownloadWatcher() {
  yield takeLatest(
    EXPORT_DOWNLOAD_REQUEST,
    exportWorker,
    exportDownloadRequest,
    exportDownloadComplete,
    exportDownloadFail,
  );
}

export function* exportCaseDownloadWatcher() {
  yield takeLatest(
    EXPORT_CASE_DOWNLOAD_FETCH_START,
    exportWorker,
    exportCaseDownloadRequest,
    exportCaseDownloadFetchSuccess,
    exportCaseDownloadFetchFail,
  );
}
