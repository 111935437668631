import getConfiguration from '../configuration';

const tenantName = getConfiguration().TENANT_NAME;

export const permissions = {
  TENANT_ACCESS: `${tenantName}:access`,
  CAMPAIGN_ACCESS: `campaign:access`,
  CAMPAIGN_DELETE: `campaign:delete`,
  CAMPAIGN_EDIT: `campaign:edit`,
  CAMPAIGN_ADD: `campaign:add`,
  CAMPAIGN_LIST: `campaign:list`,
  CAMPAIGN_ASSIGNEE: 'campaign:assignee',
  CAMPAIGN_OWNER: 'campaign:owner',
  CAMPAIGN_ENDDATE_UPSERT: 'campaign:end-date:upsert',
  CAMPAIGN_STATUS_UPSERT: 'campaign:status:upsert',
  CAMPAIGN_ASSIGNEES_UPSERT: 'campaign:assignees:upsert',
  CAMPAIGN_STARTDATE_UPSERT: 'campaign:start-date:upsert',
  GRAPHIQL_ACCESS: 'graphiql:access',
};
