import { select, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { GraphqlTypes } from 'app/common/types';
import { fetchFilters } from 'app/filters/api';
import { FILTERS_FETCH_START, IssueFilterType } from 'app/filters/constants';
import filterDefinitions from 'app/filters/definitions';
import { mapToFilterSchema } from 'app/filters/utility';
import {
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_START,
  WORKITEMLIST_FILTER_FETCH_OPTIONS_START,
} from 'app/ui/constants';
import { getAccessToken } from 'app/user/selectors';
import { fetchFiltersSuccess, fetchFiltersFail } from './actions';

function graphqlFilterTypeToIssueTargetType(graphqlFilterType) {
  switch (graphqlFilterType) {
    case GraphqlTypes.claimsFilter:
      return IssueFilterType.Claim;
    case GraphqlTypes.patientFilter:
      return IssueFilterType.Patient;
    default:
      throw new Error('Unsupported graphql type');
  }
}

export function* fetchFiltersWorker(action) {
  try {
    const token = yield select(getAccessToken);
    const filterTypes = yield call(
      fetchFilters,
      action.payload.filterType,
      token,
    );

    const filteredDefinitions = filterDefinitions.filter(
      (x) =>
        x.targetType ===
        graphqlFilterTypeToIssueTargetType(action.payload.filterType),
    );
    const filters = mapToFilterSchema(filterTypes, filteredDefinitions);
    yield put(fetchFiltersSuccess(filters, action.payload.filterType));
  } catch (e) {
    yield put(fetchFiltersFail(e));
  }
}

export function* fetchFiltersWatcher() {
  yield takeLatest(FILTERS_FETCH_START, fetchFiltersWorker);
}

export function* fetchDataWorker(action) {
  const { id } = action.payload;
  const { resolve, success, fail } = action.meta;
  try {
    const results = yield resolve;

    yield put(success(id, results));
  } catch (e) {
    yield put(fail(id, e));
  }
}

export function* fetchFilterOptionsWatcher() {
  yield takeEvery(
    [
      WORKITEMLIST_FILTER_FETCH_OPTIONS_START,
      CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_START,
    ],
    fetchDataWorker,
  );
}
