import graphql from '../common/utilities/graphql';

export const patientFragmentFactory = (name) => graphql`
  fragment ${name} on PatientType {
    id
    entityId
    patientId
    name
    dateOfBirth
    gender
    followUpDate
    phoneNumbers {
      phoneNumber
      isPrimary
      source
      addedOn
    }
    eligibility {
      eligibilityMatchingKey
      startDate
      termDate
      eligibilityCarrier
      eligibilityForGroups {
        group
        eligibilities {
          eligibilityStartDate
          eligibilityTermDate
        }
      }
    }
    opioidNaiveWindows {
      start
      end
    }
    cancerDiagnoses {
      start
      end
    }
  }
`;

export const patientQuery = graphql`
  ${patientFragmentFactory('patientFragment')}
  query patient($id: ID!) {
    node(id: $id) {
      ... on PatientType {
        ...patientFragment
        cases {
          number
          dateOpened
          dateClosed
          followUpDate
          status
          id
          interactions {
            dateContacted
            id
          }
          campaign {
            id
            name
          }
          target {
            ... on PatientType {
              id
              patientId
              followUpDate
            }
            ... on PrescriberType {
              id
              npi
            }
            __typename
          }
        }
      }
    }
  }
`;

export const drugOverviewQuery = graphql`
  query ($patientId: String!, $filledAfter: DateTime!) {
    claims(
      filter: {
        patientId: $patientId
        filledDateRange: { start: $filledAfter }
        claimStatus: PAID
      }
      take: 1000
    ) {
      claims {
        id
        patientId
        filledDate
        prescriptionStart
        prescriptionEnd
        refillWindowStart
        gpi
        gpiDetails(gpiLevel: NameExtension) {
          gpi
          description
          level
        }
        rxNumber
        claimStatus
        workItem {
          id
        }
      }
    }
  }
`;

export const claimViewerQuery = graphql`
  query ($patientId: String!) {
    claims(filter: { patientId: $patientId }, take: 1000) {
      claims {
        id
        claimNumber
        patientId
        filledDate
        claimStatus
        rxNumber
        rejectCodes
        rejectMessages
        drugName
        workItem {
          id
          assignee {
            name
          }
          status {
            id
          }
        }
      }
    }
  }
`;

export const opioidViewQuery = graphql`
  fragment prescriberFields on PrescriberType {
    gender
    groupPracticeId
    name
    npi
    organizationName
    primarySpecialty
    id
  }

  fragment claimsFields on PagedClaimType {
    claims {
      id
      adjudicatedDate
      patientId
      filledDate
      prescriptionStart
      prescriptionEnd
      refillWindowStart
      gpi
      gpiDetails(gpiLevel: Strength) {
        gpi
        description
        level
      }
      rxNumber
      claimStatus
      drugContainsOpioid
      isDrugOpioidCombinator
      isPatientOpioidNaive
      isPatientInLongTermCare
      dailyMorphineMilligramEquivalent
      patientTotalMorphineMilligramEquivalent
      hasPriorAuth
      quantityDispensed
      daysSupply
      historicalOpioidPrescriberCount
      historicalOpioidPrescribers {
        ...prescriberFields
      }
      historicalOpioidPharmacyCount
      prescriber {
        ...prescriberFields
      }
      pharmacy {
        id
        name
        npi
        phoneNumbers {
          phoneNumber
          isPrimary
          source
          addedOn
        }
      }
      workItem {
        id
        assignee {
          name
        }
        status {
          id
          type
          name
        }
      }
      patientEndingTotalMorphineMilligramEquivalent
      patientTotalMorphineMilligramEquivalent
      continuedFrom
      claimNumber
      isSecondaryPayment
      hasOpioidCombinatorImpact
      initialOpioidSupplyThirtyDays
      initialOpioidSupplySixtyDays
      patientAge
    }
  }

  query ($patientId: String!, $filledAfter: DateTime!) {
    opioids: claims(
      filter: {
        patientId: $patientId
        filledDateRange: { start: $filledAfter }
        claimStatus: [PAID, REJECTED]
        drugContainsOpioid: true
        isPrimaryPayment: true
      }
      take: 1000
    ) {
      ...claimsFields
    }
    combinators: claims(
      filter: {
        patientId: $patientId
        filledDateRange: { start: $filledAfter }
        claimStatus: [PAID, REJECTED]
        isDrugOpioidCombinator: true
        isPrimaryPayment: true
      }
      take: 1000
    ) {
      ...claimsFields
    }
  }
`;

export const upsertPatientMutation = graphql`
  ${patientFragmentFactory('patientFragment')}
  mutation ($patient: PatientInputType!) {
    upsertPatient(patient: $patient) {
      ...patientFragment
    }
  }
`;
