import { Paper, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';
import Campaign from '../../../common/images/campaign.svg';
import styles from './CampaignEdit.css';

const Row = ({
  rowIndex,
  singleInput,
  doubleHeight,
  preceedingDoubles,
  buttons,
}) => {
  const singleRowHeight = 56;
  const rowHeight = doubleHeight ? singleRowHeight * 2 : singleRowHeight;
  const margin = 16;
  const y =
    rowIndex * singleRowHeight + margin * (rowIndex - preceedingDoubles);

  return (
    <>
      {singleInput && (
        <rect x="0" y={y} rx="8" ry="8" width="768" height={rowHeight} />
      )}
      {!singleInput && !buttons && (
        <>
          <rect x="0" y={y} rx="8" ry="8" width="376" height={rowHeight} />
          <rect x="394" y={y} rx="8" ry="8" width="376" height={rowHeight} />
        </>
      )}
      {buttons && (
        <rect x="542" y={y - 4} rx="8" ry="8" width="226" height={37} />
      )}
    </>
  );
};

Row.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  singleInput: PropTypes.bool,
  doubleHeight: PropTypes.bool,
  preceedingDoubles: PropTypes.number,
  buttons: PropTypes.bool,
};

Row.defaultProps = {
  preceedingDoubles: 0,
  singleInput: false,
  doubleHeight: false,
  buttons: false,
};

const CampaignEditFetching = () => (
  <div className={styles.container}>
    <Paper className={styles.formContainer}>
      <div className={styles.formRow}>
        <Paper elevation={2} className={styles.headerIcon}>
          <SvgIcon fontSize="large">
            <Campaign />
          </SvgIcon>
        </Paper>
        <div className={styles.headerText}>Create Campaign</div>
      </div>
      <ContentLoader
        height={666}
        width={768}
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        viewBox="0 0 768 666"
      >
        <Row rowIndex={0} />
        <Row rowIndex={1} singleInput doubleHeight />
        <Row rowIndex={3} preceedingDoubles={1} />
        <Row rowIndex={4} preceedingDoubles={1} />
        <Row rowIndex={5} preceedingDoubles={1} />
        <Row rowIndex={6} preceedingDoubles={1} />
        <Row rowIndex={7} singleInput preceedingDoubles={1} />
        <Row rowIndex={8} singleInput preceedingDoubles={1} />
        <Row rowIndex={9} buttons preceedingDoubles={1} />
      </ContentLoader>
    </Paper>
  </div>
);

export default CampaignEditFetching;
