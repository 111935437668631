import {
  EXPORT_DOWNLOAD_REQUEST,
  EXPORT_DOWNLOAD_COMPLETE,
  EXPORT_DOWNLOAD_FAIL,
} from './constants';
import { ExportStateRecord } from './types';

const initialState = new ExportStateRecord();

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_DOWNLOAD_REQUEST:
      return state.merge({ isFetching: true, error: null });
    case EXPORT_DOWNLOAD_FAIL:
      return state.merge({ isFetching: false, error: action.payload });
    case EXPORT_DOWNLOAD_COMPLETE:
      return state.merge({ isFetching: false, error: null });
    default:
      return state;
  }
};

export default reducer;
