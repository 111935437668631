import {
  __,
  complement,
  filter,
  includes,
  isEmpty,
  not,
  pathEq,
  pipe,
  prop,
  propEq,
  propSatisfies,
} from 'ramda';
import { InteractionStatus } from 'app/interactions/types';
import { activeStatuses } from 'app/session/types';

export const isSessionActive = propSatisfies(
  includes(__, activeStatuses),
  'status',
);

export const isSessionOwnedByUser = pathEq(__, ['user', 'id']);

interface SessionWorkHasBeenStartedParam {
  interactions: Array<{ status: InteractionStatus }>;
}

export const sessionWorkHasBeenStarted = pipe(
  (x: any) => prop('interactions', x),
  filter(complement(propEq(InteractionStatus.Cancelled, 'status'))),
  isEmpty,
  not,
);
