import { combineReducers } from 'redux-immutable';
import { OptimisticRecord } from '../../types';
import workItems from './workItems';

const reducer = combineReducers(
  {
    workItems,
  },
  OptimisticRecord,
);

export default reducer;
