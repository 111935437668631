import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import MultiSelect from 'app/filters/components/MultiSelect';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import { drugListsQuery } from 'app/filters/queries';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(drugListsQuery, {}, token)
    .then(throwIfErrors)
    .then((data) => data.data.drugLists)
    .then((drugLists: Array<{ id: string; name: string }>) =>
      OrderedSet(
        drugLists.map(
          (d) =>
            new SelectValueRecord({
              text: d.name,
              value: d.id,
            }),
        ),
      ),
    );

class ExcludeDrugListsConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'excludeDrugLists',
    title: 'Exclude Drug Lists',
    targetType: IssueFilterType.Claim,
    options: (token: string) => fetchOptions(token),
    component: (props: any) => <MultiSelect {...props} />,
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.drugLists)) return true;

      return value
        .intersect(workItem.claim.drugLists.map((x) => x.id))
        .isEmpty();
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExcludeDrugListsConfig();
