import graphql from '../../common/utilities/graphql';

export const logEventMutation = graphql`
  mutation ($logEvents: [LogEventInputType]!) {
    logEvent(events: $logEvents) {
      errors {
        fieldName
        messages
      }
      successful
    }
  }
`;
