import { fetchGraphqlQuery } from '../common/api';
import { fetchIssuesQuery, searchQuery, upsertIssueMutation } from './queries';

export async function fetchIssues(
  filter,
  sortBy,
  sortOrder,
  token,
  continuationToken = null,
) {
  const result = await fetchGraphqlQuery(
    fetchIssuesQuery,
    {
      filter,
      sortBy,
      sortOrder,
      continuationToken,
    },
    token,
  );

  return { errors: result.errors, data: result.data.issues };
}

export async function fetchTargets(value, token) {
  const result = await fetchGraphqlQuery(
    searchQuery,
    {
      search: value,
    },
    token,
  );

  return { errors: result.errors, data: result.data };
}

export async function upsertIssue(issue, token) {
  const result = await fetchGraphqlQuery(upsertIssueMutation, { issue }, token);

  return { errors: result.errors, data: result.data.upsertIssue };
}
