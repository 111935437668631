import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './BasicInfoItem.css';

const BasicInfoItem = ({ label, value, isLastItem }) => (
  <div
    className={classnames(styles.basicInfoItem, { [styles.last]: isLastItem })}
  >
    <div className={styles.basicInfoLabel}>{label}</div>
    <div className={styles.basicInfoValue}>{value}</div>
  </div>
);

BasicInfoItem.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node,
  isLastItem: PropTypes.bool,
};

BasicInfoItem.defaultProps = {
  value: '',
  isLastItem: false,
};

export default BasicInfoItem;
