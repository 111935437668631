import { call, put, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from '../common/sagas';
import {
  fetchWorkflowDefinitionsSuccess,
  fetchWorkflowDefinitionsFail,
} from './actions';
import { fetchCaseWorkflowDefinitions } from './api';
import { WORKFLOW_DEFINITION_QUERY_FETCH_START } from './constants';

export function* fetchWorkflowDefinitionsWorker() {
  yield call(
    genericGraphQlWorker,
    (token) => call(fetchCaseWorkflowDefinitions, token),
    (response) =>
      put(fetchWorkflowDefinitionsSuccess(response.workflowDefinitions)),
    (error) => fetchWorkflowDefinitionsFail(error),
  );
}

export function* fetchWorkflowDefinitionsWatcher() {
  yield takeLatest(
    WORKFLOW_DEFINITION_QUERY_FETCH_START,
    fetchWorkflowDefinitionsWorker,
  );
}
