import {
  differenceInDays,
  endOfDay,
  format,
  startOfDay,
  subDays,
} from 'date-fns';
import { OrderedSet, Set } from 'immutable';
import { isEmpty, isNil } from 'ramda';
import DateRange from 'app/common/components/DateRange';
import TextInput from 'app/common/components/TextInput';
import { DurationRecord } from 'app/common/duration';
import { GraphqlTypes, Interval } from 'app/common/types';
import MatchChipInput from 'app/filters/components/MatchChipInput';
import MultiSelect from 'app/filters/components/MultiSelect';
import RadialFilter from 'app/filters/components/RadialFilter';
import TextComparison from 'app/filters/components/TextComparison';
import {
  MatchStrings,
  numericOperatorConfig,
  NumericOperators,
  TextComparisonOperators,
  getTextOperatorConfig,
} from 'app/filters/constants';
import { createEnumOptionsQueryHandler } from 'app/filters/definitions/utility';
import {
  NumericComparison,
  DateRangeOffset,
  DateRangeSelectValue,
  KeyValueRecord,
  MatchStringsInputRecord,
  NumericComparisonBackendRecord,
  NumericOperator,
  SelectValueRecord,
  comparisonToRangeConverter,
  NumericRangeRecord,
  BoundaryRecord,
  BoundaryTypes,
  TextComparisonOperator,
  TextComparisonBackendRecord,
  TextComparisonRecord,
  MultiSelectMatchStoredValue,
  MultiSelectMatchFilterRecord,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';

const setFormatter = (value: Set<{ value: string }>) => {
  const valueSet = OrderedSet(value);
  if (valueSet.isEmpty()) {
    return 'All';
  }

  return valueSet
    .map((v) => v.value) // Set of text's
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
};

const selectValueSetFormatter = (value: Set<SelectValueRecord>) => {
  const set = value.map((v) => ({ value: v.text }));
  return setFormatter(set);
};

const matchStringsFormatter = (value: MultiSelectMatchFilterRecord) => {
  if (value.values.count() === 1) {
    return `${selectValueSetFormatter(value.values)}`;
  }

  if (value.values.count() > 1) {
    return `Has ${value.match} of the following: ${selectValueSetFormatter(
      value.values,
    )}`;
  }

  return 'All';
};

const selectValueToKeyValueConverter = (x: SelectValueRecord) =>
  new KeyValueRecord({ key: x.text, value: x.value });

const keyValueToSelectValueConverter = (x: KeyValueRecord) =>
  new SelectValueRecord({ text: x.key, value: x.value });

// Using a single generic param here might not hold up well, keep an eye on it as we migrate filters

export function commonDefaultsFactory<T>() {
  return {
    enabled: true,
    options: () => {}, // Is this common enough?
    valueToQueryConverter: (value: T) => value,
    templateToFilterValueConverter: (value: T) => value,
    filterToTemplateValueConverter: (value: T) => value,
    workflowSpecific: false,
  };
}

export function commonClassDefaultsFactory<T>(C: { new (): T }) {
  return {
    ...commonDefaultsFactory<T>(),
    defaultValue: () => new C(),
  };
}

export const booleanFilterDefaults = {
  ...commonDefaultsFactory<boolean>(),
  defaultValue: () => true,
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  component: (props: any) => <RadialFilter {...props} />,
  storedValueType: StoredFilterType.Boolean,
  summaryFormatter: (value: boolean) => (value === true ? 'Yes' : 'No'),
  storedValueConstructor: (value: boolean) => value,
  workflowSpecific: false,
};

export const stringFilterDefaults = {
  ...commonDefaultsFactory<string>(),
  defaultValue: () => '',
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  component: (props: any) => <TextInput debounced {...props} />,
  storedValueType: StoredFilterType.String,
  summaryFormatter: (value: string) =>
    isEmpty(value) || isNil(value) ? 'All' : value,
  storedValueConstructor: (value: string) => value,
  workflowSpecific: false,
};

export const enumDefaults = (
  graphQlType: GraphqlTypes,
  optionTextFormatter: (type: string) => string = (v) => v,
) => ({
  enabled: true,
  workflowSpecific: false,
  summaryFormatter: (value: Set<SelectValueRecord>) =>
    setFormatter(value.map((v) => ({ value: optionTextFormatter(v.value) }))),
  options: (token: string) => {
    const handler = createEnumOptionsQueryHandler(
      graphQlType,
      optionTextFormatter,
    );
    return handler(token);
  },
  defaultValue: () => OrderedSet<SelectValueRecord>(),
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  component: (props: any) => <MultiSelect {...props} />,
  storedValueType: StoredFilterType.MultiselectString,
  valueToQueryConverter: (value: OrderedSet<SelectValueRecord>) =>
    value.map((v) => v.value),
  templateToFilterValueConverter: (value: Set<string>) =>
    value
      .map(
        (v) =>
          new SelectValueRecord({ text: optionTextFormatter(v), value: v }),
      )
      .toOrderedSet(),
  filterToTemplateValueConverter: (value: OrderedSet<SelectValueRecord>) =>
    value.toSet().map((v) => v.value),
  storedValueConstructor: (value: string[]) => Set(value),
});

export const stringArrayDefaults = {
  enabled: true,
  summaryFormatter: selectValueSetFormatter,
  options: () => OrderedSet<SelectValueRecord>(),
  defaultValue: () => OrderedSet<SelectValueRecord>(),
  valueToQueryConverter: (value: Set<SelectValueRecord>) =>
    value.map((v) => v.value),
  filterToTemplateValueConverter: (value: Set<SelectValueRecord>) =>
    value.map(selectValueToKeyValueConverter).toOrderedSet(),
  templateToFilterValueConverter: (value: Set<KeyValueRecord>) =>
    value.map(keyValueToSelectValueConverter).toOrderedSet(),
  storedValueType: StoredFilterType.MultiselectKeyvalue,
  storedValueConstructor: (value: Array<{ key: string; value: string }>) =>
    OrderedSet(value.map((v) => new KeyValueRecord(v))),
  workflowSpecific: false,
};

export const numericComparisonDefaults = {
  enabled: true,
  workflowSpecific: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options: (_token: string) =>
    OrderedSet(
      Object.values(NumericOperators).map(
        (operator) =>
          new NumericOperator({
            text: numericOperatorConfig(operator).text,
            value: operator,
          }),
      ),
    ),
  defaultValue: () =>
    new NumericComparison({
      value: 0,
      operator: new NumericOperator({
        text: numericOperatorConfig(NumericOperators.Equal).text,
        value: NumericOperators.Equal,
      }),
    }),
  valueToQueryConverter: (filter: NumericComparison) =>
    new NumericComparisonBackendRecord({
      number: Number(filter.value),
      operator: filter.operator.value,
    }),
  filterToTemplateValueConverter: (filter: NumericComparison) =>
    new NumericComparisonBackendRecord({
      number: Number(filter.value),
      operator: filter.operator.value,
    }),
  templateToFilterValueConverter: (
    templateFilter: NumericComparisonBackendRecord,
  ) =>
    new NumericComparison({
      value: templateFilter.number,
      operator: new NumericOperator({
        text: numericOperatorConfig(templateFilter.operator).text,
        value: templateFilter.operator,
      }),
    }),
  storedValueConstructor: (value: { number: number; operator: string }) =>
    new NumericComparisonBackendRecord({
      number: value.number,
      operator: value.operator as NumericOperators,
    }),
};

export const matchStringsDefaults = {
  ...commonClassDefaultsFactory(MultiSelectMatchFilterRecord),
  summaryFormatter: matchStringsFormatter,
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  component: (props: any) => <MatchChipInput {...props} />,
  storedValueType: StoredFilterType.MultiselectMatch,
  valueToQueryConverter: (value: MultiSelectMatchFilterRecord) =>
    new MatchStringsInputRecord({
      match: value.match,
      values: value.values.map((v) => v.value),
    }),
  filterToTemplateValueConverter: (value: MultiSelectMatchFilterRecord) => {
    return new MultiSelectMatchStoredValue({
      match: value.match,
      values: value.values.map(
        (v) =>
          new KeyValueRecord({
            key: v.text,
            value: v.value,
          }),
      ),
    });
  },
  templateToFilterValueConverter: (value: MultiSelectMatchStoredValue) => {
    return new MultiSelectMatchFilterRecord({
      match: value.match,
      values: value.values.map(
        (v) =>
          new SelectValueRecord({
            text: v.key,
            value: v.value,
          }),
      ),
    });
  },
  storedValueConstructor: (value: {
    match: string;
    values: { key: string; value: string }[];
  }) =>
    new MultiSelectMatchStoredValue({
      match: value.match as MatchStrings,
      values: OrderedSet(
        value.values.map(
          (v) =>
            new KeyValueRecord({
              key: v.key,
              value: v.value,
            }),
        ),
      ),
    }),
};

export const dateRangeDefaults = {
  enabled: true,
  workflowSpecific: false,
  defaultValue: () =>
    new Interval({
      start: subDays(new Date(), 3),
      end: endOfDay(new Date()),
    }),
  storedValueType: StoredFilterType.DateRange,
  summaryFormatter: (value: Interval) =>
    `${format(value.start, 'MM/dd/yyyy')} - ${format(value.end, 'MM/dd/yyyy')}`,
  options: () =>
    OrderedSet([
      new DateRangeSelectValue({
        text: '1 day',
        value: new DurationRecord({ days: 1 }),
      }),
      new DateRangeSelectValue({
        text: '3 days',
        value: new DurationRecord({ days: 3 }),
      }),
      new DateRangeSelectValue({
        text: '5 days',
        value: new DurationRecord({ days: 5 }),
      }),
      new DateRangeSelectValue({
        text: '7 days',
        value: new DurationRecord({ days: 7 }),
      }),
    ]),
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  component: (props: any) => <DateRange {...props} />,
  templateToFilterValueConverter: (value: DateRangeOffset) =>
    new Interval({
      start: startOfDay(subDays(new Date(), value.start)),
      end: endOfDay(subDays(new Date(), value.end)),
    }),
  filterToTemplateValueConverter: (value: Interval) =>
    new DateRangeOffset({
      start: differenceInDays(endOfDay(new Date()), value.start),
      end: differenceInDays(endOfDay(new Date()), value.end),
    }),
  valueToQueryConverter: (value: Interval) =>
    value.merge({
      start: startOfDay(value.start),
      end: endOfDay(value.end),
    }),
  storedValueConstructor: (value: { start: number; end: number }) =>
    new DateRangeOffset({
      ...value,
    }),
};

// NOTE: Numeric range currently looks/acts like a numeric comparison filter,
// but converts to a numeric range value for query
export const numericRangeDefaults = {
  enabled: true,
  workflowSpecific: false,
  storedValueType: StoredFilterType.NumericRange,
  options: () =>
    OrderedSet(
      Object.values(NumericOperators).map(
        (operator) =>
          new NumericOperator({
            text: numericOperatorConfig(operator).text,
            value: operator,
          }),
      ),
    ),
  defaultValue: () => new NumericComparison(),
  valueToQueryConverter: comparisonToRangeConverter,
  filterToTemplateValueConverter: comparisonToRangeConverter,
  storedValueConstructor: (value: {
    minimum?: { value: number; type: string };
    maximum?: { value: number; type: string };
  }) =>
    new NumericRangeRecord({
      minimum: isNil(value.minimum)
        ? undefined
        : new BoundaryRecord({
            value: value.minimum.value,
            type: value.minimum.type as BoundaryTypes,
          }),
      maximum: isNil(value.maximum)
        ? undefined
        : new BoundaryRecord({
            value: value.maximum.value,
            type: value.maximum.type as BoundaryTypes,
          }),
    }),
  templateToFilterValueConverter: (value: NumericRangeRecord) => {
    if (isNil(value.minimum) && isNil(value.maximum)) {
      return new NumericComparison();
    }

    if (!isNil(value.minimum)) {
      if (
        value.minimum.type === BoundaryTypes.Exclusive &&
        isNil(value.maximum)
      ) {
        return new NumericComparison({
          value: value.minimum.value,
          operator: new NumericOperator({
            text: numericOperatorConfig(NumericOperators.Greater).text,
            value: NumericOperators.Greater,
          }),
        });
      }

      if (
        !isNil(value.maximum) &&
        value.minimum.type === BoundaryTypes.Inclusive &&
        value.maximum.type === BoundaryTypes.Inclusive &&
        value.minimum.value === value.maximum.value
      ) {
        return new NumericComparison({
          value: value.minimum.value,
          operator: new NumericOperator({
            text: numericOperatorConfig(NumericOperators.Equal).text,
            value: NumericOperators.Equal,
          }),
        });
      }
    }

    if (
      !isNil(value.maximum) &&
      isNil(value.minimum) &&
      value.maximum.type === BoundaryTypes.Exclusive
    ) {
      return new NumericComparison({
        value: value.maximum.value,
        operator: new NumericOperator({
          text: numericOperatorConfig(NumericOperators.Less).text,
          value: NumericOperators.Less,
        }),
      });
    }

    throw new Error('Unable to convert numeric range to numeric comparison');
  },
};

// Use with the textComparison fields
const convertTextComparisonToBackend = (filter: TextComparisonRecord) =>
  new TextComparisonBackendRecord({
    text: filter.text,
    operator: filter.operator.value,
  });

export const textComparisonDefaults = {
  enabled: true,
  workflowSpecific: false,
  storedValueType: StoredFilterType.TextComparison,
  summaryFormatter: (value: TextComparisonRecord) => {
    if (isEmpty(value.text)) return 'None';
    return `${getTextOperatorConfig(value.operator.value).summary} ${
      value.text
    }`;
  },
  defaultValue: () =>
    new TextComparisonRecord({
      text: undefined,
      operator: new TextComparisonOperator({
        text: getTextOperatorConfig(TextComparisonOperators.Contains).text,
        value: TextComparisonOperators.Contains,
      }),
    }),
  // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
  component: (props: any) => <TextComparison inputType="text" {...props} />,
  options: () =>
    Set(
      Object.values(TextComparisonOperators).map(
        (operator: TextComparisonOperators) =>
          new TextComparisonOperator({
            text: getTextOperatorConfig(operator).text,
            value: operator,
          }),
      ),
    ),
  valueToQueryConverter: convertTextComparisonToBackend,
  filterToTemplateValueConverter: convertTextComparisonToBackend,
  templateToFilterValueConverter: (value: TextComparisonBackendRecord) =>
    new TextComparisonRecord({
      text: value.text,
      operator: new TextComparisonOperator({
        text: getTextOperatorConfig(value.operator).text,
        value: value.operator,
      }),
    }),
  storedValueConstructor: (value: { text: string; operator: string }) =>
    new TextComparisonBackendRecord({
      text: value.text,
      operator: value.operator as TextComparisonOperators,
    }),
};
