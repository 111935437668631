import { List } from 'immutable';
import { isNil } from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CaseRecord } from 'app/cases/types';
import SaveButton from 'app/common/components/SaveButton';
import { graphqlSelector } from 'app/common/hooks/useQuery';
import { GraphqlTypes } from 'app/common/types';
import graphql from 'app/common/utilities/graphql';
import InteractionContact from 'app/interactions/components/InteractionContact/InteractionContact';
import { InteractionRecord, InteractionStatus } from 'app/interactions/types';
import { getSuggestedSearchResultsForAllContactsInCases } from 'app/interactions/utility';
import PatientOverview from 'app/patient/components/PatientProfile/PatientDetails/PatientDetails';
import { patientFragmentFactory } from 'app/patient/queries';
import { PatientRecord } from 'app/patient/types';
import PharmacyOverview from 'app/pharmacies/components/PharmacyDetails/PharmacyDetails';
import { pharmacyFragmentFactory } from 'app/pharmacies/queries';
import { PharmacyRecord } from 'app/pharmacies/types';
import PrescriberOverview from 'app/prescribers/components/PrescriberDetails/PrescriberDetails';
import { prescriberFragmentFactory } from 'app/prescribers/queries';
import { PrescriberRecord } from 'app/prescribers/types';
import { OutreachStatus } from 'app/queue/types';
import { SessionRecord } from 'app/session/types';
import { sessionWorkHasBeenStarted } from 'app/session/utility';
import styles from './CreateInteraction.css';

const query = graphql`
  ${patientFragmentFactory('patient')}
  ${prescriberFragmentFactory('prescriber')}
  ${pharmacyFragmentFactory('pharmacy')}
  query node($id: ID!) {
    node(id: $id) {
      ... on PatientType {
        ...patient
      }
      ... on PrescriberType {
        ...prescriber
      }
      ... on PharmacyType {
        ...pharmacy
      }
      __typename
    }
  }
`;

type CreateInteractionProps = {
  session: SessionRecord;
  isCreatingInteraction: boolean;
  interaction: InteractionRecord;
  isCanceling: boolean;
  isRunning: boolean;
  onCancel: () => void;
  cancelText: string;
  onInteractionChange: (interaction: InteractionRecord) => void;
  onInteractionCreateComplete: (interaction: InteractionRecord) => void;
  setStatus: (status: OutreachStatus) => void;
  isErrored: boolean;
  isCancelingErrored: boolean;
};

const CreateInteraction = ({
  session,
  onInteractionChange,
  onInteractionCreateComplete,
  isCreatingInteraction,
  interaction,
  onCancel,
  setStatus,
  cancelText,
  isCanceling,
  isRunning,
  isCancelingErrored,
  isErrored,
}: CreateInteractionProps) => {
  const [contactId, setContactId] = useState(
    (
      (session.primaryCase as CaseRecord).contact as
        | PatientRecord
        | PrescriberRecord
        | PharmacyRecord
    ).id,
  );

  const { node: contact }: any = useSelector((state) =>
    graphqlSelector(state, query, { id: contactId ?? '' }),
  );

  const suggestedContacts = getSuggestedSearchResultsForAllContactsInCases(
    interaction && interaction.cases ? interaction.cases : [],
  );

  const handleOnSearchClick = (result: { id: string }) => {
    setContactId(result.id);
    const updatedInteraction = {
      ...interaction,
      contact: result.id,
    };
    onInteractionChange(updatedInteraction);
  };

  const handleComplete = () => {
    if (isNil(contactId)) throw new Error('ContactId cannot be null');
    let casesArray: string[] = [];
    if (
      isNil(interaction.cases) ||
      (interaction.cases as CaseRecord[]).length === 0
    ) {
      casesArray = [(session.primaryCase as CaseRecord).id as string];
    } else {
      casesArray = (interaction.cases as CaseRecord[]).map(
        (x) => x.id,
      ) as string[];
    }

    const newInteraction = {
      ...interaction,
      caseSession: session.id,
      cases: casesArray,
      contact: contactId,
    };
    onInteractionCreateComplete(newInteraction);
  };

  let contactCard = <div />;

  if (
    (
      (session.primaryCase as CaseRecord).target as
        | PatientRecord
        | PrescriberRecord
        | PharmacyRecord
    ).id !== contactId
  ) {
    switch (contact?.__typename) {
      case GraphqlTypes.Patient: {
        contactCard = <PatientOverview patient={contact} readonly />;
        break;
      }
      case GraphqlTypes.Prescriber: {
        contactCard = <PrescriberOverview prescriber={contact} />;
        break;
      }
      case GraphqlTypes.Pharmacy: {
        contactCard = <PharmacyOverview pharmacy={contact} />;
        break;
      }
      default: {
        contactCard = <div />;
        break;
      }
    }
  }

  return (
    <>
      <div className={styles.interactionHeader}>
        <h3>Create Interaction</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <InteractionContact
          contact={contact}
          onContactSearchClick={handleOnSearchClick}
          suggestedContacts={List(suggestedContacts)}
          disableSwitching={isCanceling || isCreatingInteraction}
        />
        {contactCard}
      </div>
      <div className={styles.interactionFooter}>
        {isErrored ||
          (isCancelingErrored && (
            <div className={styles.interactionErrors}>
              Unexpected error encountered, please try again.
            </div>
          ))}
        <div className={styles.interactionButtons}>
          <SaveButton
            className={styles.interactionButton}
            variant="outlined"
            color="inherit"
            onClick={onCancel}
            disabled={isCreatingInteraction || isRunning}
            isSaving={isCanceling}
          >
            Cancel
          </SaveButton>
          <SaveButton
            className={styles.interactionButton}
            onClick={handleComplete}
            disabled={isNil(contactId) || isCanceling || isRunning}
            isSaving={isCreatingInteraction}
          >
            Start Interaction
          </SaveButton>
        </div>
      </div>
    </>
  );
};

export default CreateInteraction;
