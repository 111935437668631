import { Set } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  fetchDrugOverviewError,
  hasFetchedDrugOverview,
} from '../../../ui/selectors';
import DrugOverviewChart from '../../components/DrugOverviewChart/DrugOverviewChart';
import { getDrugOverviewClaims } from '../../selectors';

export const DrugOverviewChartContainer = ({ claims, hasFetched, error }) => {
  return (
    <DrugOverviewChart
      claims={claims.toArray()}
      hasFetched={hasFetched}
      error={error}
    />
  );
};

DrugOverviewChartContainer.propTypes = {
  hasFetched: PropTypes.bool.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  claims: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
};

DrugOverviewChartContainer.defaultProps = {
  claims: new Set(),
};

export default connect((state, props) => ({
  error: fetchDrugOverviewError(state),
  hasFetched: hasFetchedDrugOverview(state, props.id),
  claims: getDrugOverviewClaims(state, props.id),
}))(DrugOverviewChartContainer);
