import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';
import { sort, prop, descend, pipe } from 'ramda';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { toArray } from '../../../common/utilities/generic';
import { dismiss } from '../../actions';
import NotificationPanel from '../../components/NotificationPanel';
import { getNotifications } from '../../selectors';

export const NotificationPanelContainer = ({
  notifications,
  dispatch,
  onRequestClose,
  ...props
}) => (
  <NotificationPanel
    notifications={notifications}
    onDismiss={(id) => dispatch(dismiss(id))}
    onRequestClose={onRequestClose}
    {...props}
  />
);

NotificationPanelContainer.propTypes = {
  notifications: ImmutablePropTypes.orderedSet.isRequired,
  dispatch: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

const sortByTimestamp = pipe(
  toArray,
  sort(descend(prop('timestamp'))),
  OrderedSet,
);

export default connect((state) => ({
  notifications: sortByTimestamp(getNotifications(state)),
}))(NotificationPanelContainer);
