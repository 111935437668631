import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { OrderedMap } from 'immutable';
import { isNil } from 'ramda';
import {
  ComponentRecord,
  FilterFetchStart,
  SetApplied,
  SetEditing,
  SetValue,
} from '../types';

export const handleSetEditing = (
  state: OrderedMap<string, ComponentRecord>,
  action: PayloadAction<SetEditing>,
) => {
  // I don't like this, but if I don't do this then typescript thinks
  // state is a globalThis.Map<string, WritableDraft<ComponentRecord>>
  // have to convert to cast to unknown before casting to Map otherwise
  // typescript complains that there are 85 methods that aren't implemented.
  const { id, isEditing } = action.payload;
  const existingRecord = state.get(id);

  if (isNil(existingRecord)) {
    return state;
  }
  return state.merge({
    [id]: existingRecord.merge({
      isEditing,
    }),
  });
};

export const handleSetApplied = (
  state: OrderedMap<string, ComponentRecord>,
  action: PayloadAction<SetApplied>,
) => {
  const { id, defaultValue, options, isApplied, name } = action.payload;

  if (!isApplied) {
    return state.remove(id);
  }

  return state.merge({
    [id]: new ComponentRecord({
      name,
      value: defaultValue,
      defaultValue,
      options,
      isEditing: true,
    }),
  });
};

export const handleFilterUpdated = (
  state: OrderedMap<string, ComponentRecord>,
  action: PayloadAction<SetValue>,
) => {
  const { id, value } = action.payload;
  const existingRecord = state.get(id);

  if (isNil(existingRecord)) return state;
  return state.merge({
    [id]: existingRecord.merge({
      value,
    }),
  });
};

export const handleFilterFetchStart = (
  state: OrderedMap<string, ComponentRecord>,
  action: PayloadAction<FilterFetchStart>,
) => {
  const { id } = action.payload;
  const existingRecord = state.get(id);

  if (isNil(existingRecord)) return state;

  return state.merge({
    [id]: existingRecord.merge({
      isFetching: true,
    }),
  });
};

export const handleFilterFetchSuccess = (
  state: OrderedMap<string, ComponentRecord>,
  action: AnyAction,
) => {
  const { id, options } = action.payload;
  const existingRecord = state.get(id);

  if (isNil(existingRecord)) return state;

  return state.merge({
    [id]: existingRecord.merge({
      isFetching: false,
      options,
    }),
  });
};
