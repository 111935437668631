import { interactionResponseFragmentFactory } from 'app/interactions/queries';
import { targetFragment } from 'app/issues/queries';
import { claimFragmentFactory } from 'app/workitems/queries';
import graphql from '../common/utilities/graphql';
import { sessionFragmentFactory } from '../session/queries';

export const fetchNextSessionInQueueQuery = graphql`
  ${interactionResponseFragmentFactory('responses')}
  ${targetFragment}
  ${claimFragmentFactory('claimFragment')}
  fragment caseFragment on CaseType {
    id
    number
    status
    dateOpened
    dateClosed
    contact {
      ...targetFragment
    }
    caseSessions {
      id
      sessionStart
      user {
        id
        name
        email
        pictureUrl
      }
    }
    campaign {
      id
      description
      name
      contact
      target
      entityId
      caseWorkflowDefinitionId
      interactionFieldDefinitions: interactionFields {
        name
        type
        options
      }
    }
    target {
      ...targetFragment
    }
    issues {
      id
      createdDate
      supportingDocuments {
        ... on ClaimType {
          ...claimFragment
          workItem {
            id
          }
        }
        ... on PatientType {
          ...patientFragment
        }
        __typename
      }
    }
    interactions {
      id
      status
      dateContacted
      contact {
        ...targetFragment
      }
      responses {
        ...responses
      }
      user {
        id
        name
        email
        pictureUrl
      }
    }
  }
  query {
    nextCaseSession {
      id
      status
      interactions {
        id
        status
        dateContacted
        cases {
          ...caseFragment
        }
        contact {
          ...targetFragment
        }
        responses {
          ...responses
        }
        suggestedCases {
          ...caseFragment
        }
        user {
          id
          name
          email
          pictureUrl
        }
      }
      primaryCase {
        ...caseFragment
      }
    }
  }
`;

export const skipSessionMutation = graphql`
  ${sessionFragmentFactory('sessionFragment')}
  mutation ($id: String!) {
    skipCaseSession(caseSessionId: $id) {
      ...sessionFragment
    }
  }
`;

export const fetchQueueStatsQuery = graphql`
  query {
    interactionQueue {
      outstandingCount
      completedCount
      activeRepresentativesCount
    }
  }
`;

export const fetchContextForContactQuery = graphql`
  query ($id: String!) {
    contextForContact(contactEntityId: $id) {
      lockedBy {
        id
        name
        email
        pictureUrl
      }
    }
  }
`;
