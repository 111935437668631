import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from 'app/common/sagas';
import { autocompleteQuerySchema } from 'app/search/types';
import { searchSuccess, searchFail } from './actions';
import { autocomplete } from './api';
import { SEARCH_START } from './constants';

export function* searchWorker(action) {
  const { search, searchTypes } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(autocomplete, search, searchTypes, token),
    (response) =>
      put(
        searchSuccess(
          normalize(response.data.autocomplete, autocompleteQuerySchema),
        ),
      ),
    (error) => searchFail(error),
  );
}

export function* searchWatcher() {
  yield takeLatest(SEARCH_START, searchWorker);
}
