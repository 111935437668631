import graphql from '../common/utilities/graphql';
import { prescriberFragmentFactory } from '../prescribers/queries';

export const rejectCodeAutocompleteFragmentFactory = (name) =>
  graphql`
  fragment ${name} on RejectCodeType {
    code
    description
  }
`;

export const claimFragmentFactory = (name) => graphql`
  ${prescriberFragmentFactory('claimPrescriberFragment')}
  fragment ${name} on ClaimType {
    id
    carrier
    account
    group
    benefitContractId
    claimNumber
    claimStatus
    adjudicatedDate
    filledDate
    rxNumber
    drugName
    ndc
    rxcui
    gpi
    quantityDispensed
    daysSupply
    formularyRequiresPriorAuth
    formularyPriorAuthGroup
    formularyPriorAuthTypeCode
    copay
    rejectCodes
    rejectMessages
    isDrugOnFormulary
    isDrugProtectedClass
    isDrugCompound
    isDrugOverTheCounter
    coverageStatusSchedule
    licsLevel
    multiSourceCode
    drugContainsOpioid
    hasOpioidCombinatorImpact
    isPatientOpioidNaive
    dailyMorphineMilligramEquivalent
    patientTotalMorphineMilligramEquivalent
    patientPotentialTotalMorphineMilligramEquivalent
    isPatientInLongTermCare
    isPatientInPlanTransition
    isSecondaryPayment
    hasPriorAuth
    priorAuthNumbers
    pharmacy {
      id
      entityId
      name
      npi
      phoneNumbers {
        phoneNumber
        isPrimary
        source
        addedOn
      }
    }
    pharmacyId
    pharmacyName
    pharmacyAddress1
    pharmacyAddress2
    pharmacyCity
    pharmacyState
    pharmacyPhone
    pharmacyType
    prescriberNpi
    prescriber {
      ...claimPrescriberFragment
    }
    patient {
      id
      entityId
      name
      patientId
      gender
      dateOfBirth
      phoneNumbers {
        phoneNumber
        isPrimary
        source
        addedOn
      }
    }
    patientId
    patientName
    patientDob
    patientAge
    receivedTransitionFill
    eligibilityEffectiveDate
    eligibilityTerminationDate
    isPatientEligible
    formularyTier
    tier
    tierMismatch
    stepTherapyRequired
    stepTherapyType
    stepTherapyGroups {
      step
      description
    }
    quantityLimitType
    quantityLimitAmount
    quantityLimitDays
    quantityLimitPerDay
    quantityLimitPerDayExceeded
    quantityPreviouslyFilledInLimitWindow
    quantityPerDay
    daySupplyLimit
    daySupplyLimitExceeded
    lineOfBusiness
    historicalOpioidPharmacyCount
    historicalOpioidPrescriberCount
    patientHasCancer
    initialOpioidSupplyThirtyDays
    initialOpioidSupplySixtyDays
    seniorSavingsModel {
      exceedsCopay
      maxCopay
    }
    drugLists {
      id
      name
    }
    diagnosisCodes {
      code
      description
    }
    diseaseStates
    coordinationOfBenefitsIndicator
    isPatientEligible
    isDrugOpioidCombinator
  }
`;

const workItemFragment = graphql`
  fragment workItem on WorkItemDetailType {
    id
    status {
      id
      type
      name
    }
    dateCreated
    assignee {
      id
      name
    }
  }
`;

export const filterClaimsQuery = graphql`
  ${workItemFragment}

  query filterClaimsQuery(
    $filter: ClaimFilterType!
    $sortBy: SortByEnum = ADJUDICATEDDATE
    $sortOrder: SortOrderEnum = DESC
    $continuationToken: String
  ) {
    claims(
      filter: $filter
      sortBy: $sortBy
      sortOrder: $sortOrder
      take: 50
      continuationToken: $continuationToken
    ) {
      continuationToken
      claims {
        id
        adjudicatedDate
        carrier
        claimNumber
        claimStatus
        copay
        daysSupply
        drugContainsOpioid
        drugName
        filledDate
        benefitContractId
        formularyPriorAuthTypeCode
        formularyRequiresPriorAuth
        gpi
        group
        hasOpioidCombinatorImpact
        hasPriorAuth
        historicalOpioidPharmacyCount
        historicalOpioidPrescriberCount
        isDrugCompound
        isDrugOverTheCounter
        isDrugProtectedClass
        isDrugOnFormulary
        coverageStatusSchedule
        licsLevel
        multiSourceCode
        isPatientInLongTermCare
        isPatientInPlanTransition
        isPatientOpioidNaive
        lineOfBusiness
        matchedPaidClaims {
          adjudicatedDate
          claimId
          filledDate
          gpi
        }
        ndc
        patientAge
        patientId
        patientPotentialTotalMorphineMilligramEquivalent
        patientTotalMorphineMilligramEquivalent
        pharmacyId
        pharmacyType
        prescriberNpi
        quantityLimitType
        quantityLimitPerDay
        receivedTransitionFill
        rejectCodes
        rejectMessages
        rxNumber
        stepTherapyRequired
        tierMismatch
        tier
        isSecondaryPayment
        quantityLimitPerDayExceeded
        daySupplyLimit
        daySupplyLimitExceeded
        hasBeenRefilled
        refillWindowStart
        fillExpirationDate
        patientHasCancer
        initialOpioidSupplyThirtyDays
        initialOpioidSupplySixtyDays
        workItem {
          ...workItem
        }
        seniorSavingsModel {
          exceedsCopay
          maxCopay
        }
        drugLists {
          id
          name
        }
        coordinationOfBenefitsIndicator
        isPatientEligible
        isDrugOpioidCombinator
        diseaseStates
      }
    }
  }
`;

export const workItemDetailsQuery = graphql`
  ${workItemFragment}
  ${claimFragmentFactory('claimFragment')}

  query workItemDetails($id: ID!) {
    node(id: $id) {
      ... on WorkItemType {
        claim {
          workItem {
            ...workItem
            comments {
              id
              message
              dateCreated
              createdBy {
                id
                email
                name
                pictureUrl
              }
            }
          }
          ...claimFragment
        }
      }
    }
  }
`;

export const workItemMutation = graphql`
  ${workItemFragment}
  mutation ($workItem: WorkItemInputType!) {
    upsertWorkItem(workItem: $workItem) {
      errors {
        fieldName
        messages
      }
      successful
      workItemResponse {
        ...workItem
      }
    }
  }
`;

export const workItemStatusesQuery = graphql`
  query {
    workItemStatuses {
      id
      name
      type
      isDeprecated
    }
  }
`;
