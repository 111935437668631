import graphql from 'app/common/utilities/graphql';
import { targetFragment } from 'app/issues/queries';

export const caseOverviewQuery = graphql`
  ${targetFragment}
  query case($id: ID!) {
    case(id: $id) {
      id
      number
      campaign {
        name
        contact
        includeCasesInQueue
        prefix
        target
      }
      status
      dateOpened
      followUpDate
      caseSessions {
        id
        status
        sessionStart
        user {
          id
        }
        interactions {
          id
          status
          dateContacted
          cases {
            id
            number
            campaign {
              name
              contact
              target
            }
            status
            contact {
              ...targetFragment
            }
            target {
              ...targetFragment
            }
          }
          suggestedCases {
            id
            number
            campaign {
              id
              name
              contact
              target
            }
            status
            contact {
              ...targetFragment
            }
            target {
              ...targetFragment
            }
          }
        }
      }
      interactions {
        id
        dateContacted
        status
        user {
          id
          name
          email
          pictureUrl
        }
        contact {
          ...targetFragment
        }
      }
      contact {
        ...targetFragment
      }
      target {
        ...targetFragment
      }
      issues {
        id
        supportingDocuments {
          ... on ClaimType {
            workItem {
              id
            }
            adjudicatedDate
            claimNumber
            drugName
            rxNumber
            filledDate
            quantityDispensed
            daysSupply
            copay
            rejectCodes
            rejectMessages
            ndc
            claimStatus
            gpi
            rxcui
            isDrugProtectedClass
            isDrugCompound
            isDrugOverTheCounter
            multiSourceCode
            lineOfBusiness
            coordinationOfBenefitsIndicator
            carrier
            account
            group
            benefitContractId
            licsLevel
            isDrugOnFormulary
            coverageStatusSchedule
            tier
            formularyTier
            patient {
              id
              entityId
              patientId
              name
              __typename
            }
            patientId
            patientName
            patientAge
            patientDob
            eligibilityEffectiveDate
            eligibilityTerminationDate
            isPatientInPlanTransition
            isPatientInLongTermCare
            patientHasCancer
            pharmacyId
            pharmacyName
            pharmacyAddress1
            pharmacyCity
            pharmacyState
            pharmacyPhone
            pharmacyType
            prescriber {
              id
              entityId
              npi
              name
              __typename
            }
            pharmacy {
              id
              entityId
              npi
              name
              __typename
            }
            prescriberNpi
            quantityLimitType
            quantityLimitAmount
            quantityLimitDays
            quantityLimitPerDay
            quantityPreviouslyFilledInLimitWindow
            quantityLimitPerDayExceeded
            daySupplyLimit
            daySupplyLimitExceeded
            formularyRequiresPriorAuth
            formularyPriorAuthTypeCode
            formularyPriorAuthGroup
            hasPriorAuth
            priorAuthNumbers
            stepTherapyRequired
            stepTherapyType
            stepTherapyGroups {
              step
              description
            }
            drugContainsOpioid
            hasOpioidCombinatorImpact
            isPatientOpioidNaive
            dailyMorphineMilligramEquivalent
            patientTotalMorphineMilligramEquivalent
            patientPotentialTotalMorphineMilligramEquivalent
            historicalOpioidPrescriberCount
            historicalOpioidPharmacyCount
            diagnosisCodes {
              code
              description
            }
            seniorSavingsModel {
              exceedsCopay
              maxCopay
            }
            drugLists {
              name
            }
            isPatientEligible
            diseaseStates
            id
          }
          ... on PatientType {
            id
            patientId
            name
            gender
            dateOfBirth
            followUpDate
            phoneNumbers {
              phoneNumber
              isPrimary
              addedOn
              source
            }
            hasActiveCancerDiagnosis
            eligibility {
              patientNumber
              eligibilityMatchingKey
              startDate
              termDate
              eligibilityCarrier
              eligibilityForGroups {
                group
                eligibilities {
                  eligibilityStartDate
                  eligibilityTermDate
                }
              }
            }
            cancerDiagnoses {
              start
              end
            }
          }
          __typename
        }
      }
    }
  }
`;
