import Button from '@material-ui/core/Button';
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import PropTypes from 'prop-types';
import React, { useState, createRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import styles from './CommentEditor.css';

const stiffer = { tension: 325, friction: 26 };

const CommentEditor = ({
  primaryLabel,
  secondaryLabel,
  placeholder,
  editorState,
  showActions,
  disabled,
  primaryDisabled,
  onRender,
  onChange,
  onPrimaryClick,
  onSecondaryClick,
}) => {
  const scrollbar = createRef();
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(false);
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(false);
  const [containerRef, containerDimensions] = useMeasure();
  const [actionsRef, actionsDimensions] = useMeasure();
  const handleEditorChange = (value) => onChange(value);
  const containerStyles = useSpring({
    to: { height: containerDimensions.height },
    config: stiffer,
  });
  const actionsStyle = useSpring({
    to: { height: showActions ? actionsDimensions.height : 0 },
    config: stiffer,
  });
  const handlePrimaryClick = () => {
    onPrimaryClick(editorState);
  };

  const handleSecondaryClick = () => {
    onSecondaryClick(editorState);
  };

  const handleScrollbarUpdate = (values) => {
    const { clientHeight, scrollHeight, scrollTop } = values;
    let showTop = false;
    let showBottom = false;
    if (clientHeight < scrollHeight) {
      if (scrollTop > 20) {
        showTop = true;
      }
      if (scrollHeight - clientHeight - scrollTop > 20) {
        showBottom = true;
      }
    }
    if (
      showTop !== isTopShadowVisible ||
      showBottom !== isBottomShadowVisible
    ) {
      setIsTopShadowVisible(showTop);
      setIsBottomShadowVisible(showBottom);
    }
  };

  return (
    <div className={styles.container}>
      <animated.div
        style={{ ...containerStyles }}
        className={styles.collapse}
        springConfig={stiffer}
        isOpened
        onRender={onRender}
      >
        <div ref={containerRef}>
          {isTopShadowVisible && <div className={styles.shadowTop} />}
          {isBottomShadowVisible && <div className={styles.shadowBottom} />}
          <Scrollbars
            className={styles.scrollbar}
            autoHeight
            autoHeightMin={17}
            autoHeightMax={256}
            onUpdate={handleScrollbarUpdate}
            ref={scrollbar}
            renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
            renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
          >
            <Editor
              onChange={handleEditorChange}
              editorState={editorState}
              placeholder={placeholder}
              readOnly={disabled}
              stripPastedStyles
            />
          </Scrollbars>
        </div>
      </animated.div>
      <animated.div style={{ ...actionsStyle }}>
        <div ref={actionsRef} className={styles.actions}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrimaryClick}
            disabled={primaryDisabled}
          >
            {primaryLabel}
          </Button>
          <Button onClick={handleSecondaryClick}>{secondaryLabel}</Button>
        </div>
      </animated.div>
    </div>
  );
};

CommentEditor.propTypes = {
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  onChange: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
  primaryLabel: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  onPrimaryClick: PropTypes.func.isRequired,
  secondaryLabel: PropTypes.string,
  onSecondaryClick: PropTypes.func.isRequired,
  onRender: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

CommentEditor.defaultProps = {
  primaryLabel: 'ADD',
  secondaryLabel: 'Cancel',
  placeholder: 'Add Comment...',
  showActions: true,
  disabled: false,
  primaryDisabled: false,
  onRender: () => {},
};

export default CommentEditor;
