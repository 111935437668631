import { Map } from 'immutable';
import { map, pipe, curry, isNil } from 'ramda';
import { OPIOID_DETAIL_FETCH_SUCCESS } from '../../patient/constants';
import {
  WORKITEM_STATUSES_FETCH_SUCCESS,
  WORKITEM_DETAILS_FETCH_SUCCESS,
  WORKITEMS_QUERY_FETCH_SUCCESS,
} from '../constants';
import { WorkItemStatusRecord } from '../types';

const mergeInitializer = curry((initializer, state, status) => {
  const existingStatus = state.get(status.id.toString());
  if (existingStatus) {
    return existingStatus.merge(status);
  }

  return initializer(status);
});

const statusInitializer = mergeInitializer(
  (status) => new WorkItemStatusRecord(status),
);

const toKeyValuePairs = map((x) => [x.id.toString(), x]);

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case WORKITEM_STATUSES_FETCH_SUCCESS: {
      const transformer = pipe(
        map(statusInitializer(state)),
        toKeyValuePairs,
        Map,
      );
      return state.mergeDeep(transformer(action.payload.statuses));
    }
    case WORKITEM_DETAILS_FETCH_SUCCESS:
    case OPIOID_DETAIL_FETCH_SUCCESS:
    case WORKITEMS_QUERY_FETCH_SUCCESS: {
      const statuses = action.payload.data.entities.workItemStatuses;
      if (isNil(statuses)) return state;

      const transformer = pipe(map(statusInitializer(state)), Map);
      return state.mergeDeep(transformer(statuses));
    }
    default:
      return state;
  }
};

export default reducer;
