import { isEmpty, isNotNil, not } from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { v4 as uuid } from 'uuid';
import { graphqlSelector } from 'app/common/hooks/useQuery';
import {
  getError,
  getIdFromOptimisticId,
  hasCompleted as hasCompletedSelector,
  isErrored as isErroredSelector,
  isRunning as isRunningSelector,
} from 'app/ui/selectors';

// TODO: attempt to type this and leverage overloads, generics or something to reduce copy paste?

export function useOptimisticMutation(
  action: (optimisticId: string, ...data: any[]) => AnyAction,
) {
  const dispatch = useDispatch();
  const [optimisticId, setOptimisticId] = useState<string>(uuid());
  const statusAction = action(optimisticId, null);
  const isRunning = useSelector((state) =>
    isRunningSelector(state, statusAction),
  );
  const hasCompleted = useSelector((state) =>
    hasCompletedSelector(state, statusAction),
  );
  const errors = useSelector((state) => getError(state, statusAction));
  const isErrored = useSelector((state) =>
    isErroredSelector(state, statusAction),
  );
  const createdId = useSelector((state) =>
    getIdFromOptimisticId(state, optimisticId),
  );
  const mutation = (...input: any[]) => {
    const newId = uuid();
    setOptimisticId(newId);
    dispatch(action(newId, ...input));
  };

  return {
    mutation,
    optimisticId,
    createdId,
    isRunning,
    hasCompleted,
    isErrored,
    errors,
  };
}

export function useMutation(
  action: (id: string, ...values: any[]) => AnyAction,
) {
  const dispatch = useDispatch();
  const [trackedId, setTrackedId] = useState<string>('');
  const statusAction = action(trackedId);
  const isRunning = useSelector((state) =>
    isRunningSelector(state, statusAction),
  );
  const hasCompleted = useSelector((state) =>
    hasCompletedSelector(state, statusAction),
  );
  const errors = useSelector((state) => getError(state, statusAction));
  const mutation = (id: string, ...values: any[]) => {
    setTrackedId(id);
    dispatch(action(id, ...values));
  };

  return {
    mutation,
    isRunning,
    hasCompleted,
    isErrored: errors.length > 0,
    errors,
  };
}

export function useQuery(
  action: (...values: any[]) => AnyAction,
  graphqlQuery: string,
  graphqlVariables: any,
) {
  const dispatch = useDispatch();
  const statusAction = action();
  const isRunning = useSelector((state) =>
    isRunningSelector(state, statusAction),
  );
  const hasCompleted = useSelector((state) =>
    hasCompletedSelector(state, statusAction),
  );
  const errors = useSelector((state) => getError(state, statusAction));
  const query = (...values: any[]) => {
    dispatch(action(...values));
  };

  const result: any = useSelector((state) =>
    graphqlSelector(state, graphqlQuery, graphqlVariables),
  );

  return {
    query,
    result,
    isRunning,
    hasCompleted,
    isErrored: errors.length > 0,
    errors,
  };
}

export function useQueryById(
  action: (id: string) => AnyAction,
  graphqlQuery: string,
) {
  const dispatch = useDispatch();
  const [trackedId, setTrackedId] = useState<string>('');
  const statusAction = action(trackedId);
  useEffect(() => {
    if (isNotNil(trackedId) && not(isEmpty(trackedId))) {
      dispatch(action(trackedId));
    }
  }, [trackedId]);

  const isRunning = useSelector((state) =>
    isRunningSelector(state, statusAction),
  );
  const hasCompleted = useSelector((state) =>
    hasCompletedSelector(state, statusAction),
  );
  const errors = useSelector((state) => getError(state, statusAction));
  const query = (id: string) => {
    setTrackedId(id);
  };

  const result = useSelector((state) =>
    graphqlSelector(state, graphqlQuery, { id: trackedId }),
  ) as any;

  return {
    query,
    result,
    isRunning,
    hasCompleted,
    isErrored: errors.length > 0,
    errors,
  };
}
