import { LocalPharmacy } from '@material-ui/icons';
import PhoneNumber from '../../../common/components/PhoneNumber/PhoneNumber';
import BasicInfoItem from '../../../patient/components/BasicInfoItem/BasicInfoItem';
import styles from './PharmacyDetails.css';

type PharmacyOverviewProps = {
  pharmacy: {
    name: string;
    npi: string;
    phoneNumbers: {
      phoneNumber: string;
    }[];
  };
};

const PharmacyOverview = ({ pharmacy }: PharmacyOverviewProps) => {
  let phoneElement = <div />;
  const phoneNumber = pharmacy.phoneNumbers[0]?.phoneNumber ?? '';

  if (phoneNumber) {
    phoneElement = (
      <BasicInfoItem
        label="Phone Number"
        value={<PhoneNumber number={phoneNumber} />}
      />
    );
  }

  return (
    <div className={styles.container}>
      <LocalPharmacy className={styles.basicInfoIcon} />
      <div className={styles.basicPrimaryInfo}>Pharmacy</div>
      <BasicInfoItem label="Name" value={pharmacy.name} />
      <BasicInfoItem label="NPI" value={pharmacy.npi} />
      {phoneElement}
    </div>
  );
};

export default PharmacyOverview;
