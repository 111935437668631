import { Record, Map, Set, OrderedSet } from 'immutable';
import { schema } from 'normalizr';
import { GraphqlTypes } from 'app/common/types';

export const EligibilityRecord = Record({
  eligibilityMatchingKey: null,
  patientNumber: null,
  startDate: null,
  termDate: null,
  eligibilityCarrier: null,
  eligibilityForGroups: OrderedSet(),
});

export const EligibilityForGroupRecord = Record({
  group: null,
  eligibilities: OrderedSet(),
});

export const EligibilitySpanRecord = Record({
  eligibilityStartDate: null,
  eligibilityTermDate: null,
});

export enum PhoneNumberSource {
  Eligibility = 'Eligibility',
  User = 'User',
}

export class PhoneNumberRecord extends Record({
  phoneNumber: '',
  isPrimary: false,
  source: PhoneNumberSource.Eligibility,
  addedOn: undefined as undefined | Date,
  isDeleted: false,
}) {}

export enum GenderEnum {
  Male = 'Male',
  Female = 'Female',
  Unknown = 'Unknown',
}

export class PatientRecord extends Record({
  id: undefined as string | undefined,
  patientId: undefined as string | undefined,
  entityId: undefined as string | undefined,
  name: undefined as string | undefined,
  dateOfBirth: undefined as Date | undefined,
  gender: undefined as GenderEnum | undefined,
  eligibility: OrderedSet(),
  opioidNaiveWindows: OrderedSet(),
  claims: Set(),
  cancerDiagnoses: OrderedSet(),
  hasActiveCancerDiagnosis: undefined as boolean | undefined,
  phoneNumbers: Set<PhoneNumberRecord>(),
  cases: Set(),
  followUpDate: undefined as Date | undefined,
  __typename: GraphqlTypes.Patient,
}) {}

export const OpioidIssueRecord = Record({
  id: null,
  rule: null,
  text: null,
  primaryClaim: null,
  claims: Set(),
  prescribers: Set(),
});

export const PatientIssueRecord = Record({
  patient: null,
  claims: Set(),
});

export const PatientsStateRecord = Record({
  patients: Map(),
});

export const patientSchema = new schema.Entity('patients');
