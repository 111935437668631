import { fetchGraphqlQuery } from '../common/api';
import { fetchCaseWorkflowDefinitionsQuery } from './queries';

export async function fetchCaseWorkflowDefinitions(token) {
  const result = await fetchGraphqlQuery(
    fetchCaseWorkflowDefinitionsQuery,
    {},
    token,
  );

  return {
    errors: result.errors,
    workflowDefinitions: result.data.workflowDefinitions,
  };
}
