import { Redirect } from '@reach/router';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { permissions } from '../auth/permissions';
import { getUserPermissions } from './selectors';

const Authenticate =
  (WrappedComponent) =>
  ({ userPermissions, ...props }) => {
    if (userPermissions.has(permissions.TENANT_ACCESS)) {
      return <WrappedComponent {...props} />;
    }

    const returnUrl = props.location.pathname;
    return <Redirect to={`/login?returnUrl=${returnUrl}`} noThrow />;
  };

const mapStateToProps = (state, props) => ({
  userPermissions: getUserPermissions(state),
  ...props,
});

const composedAuthenticateWrapper = compose(
  connect(mapStateToProps, null),
  Authenticate,
);

export default composedAuthenticateWrapper;
