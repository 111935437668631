import { Record } from 'immutable';
import { isNil, isEmpty } from 'ramda';
import TextInput from 'app/common/components/TextInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringFilterDefaults } from 'app/filters/definitions/defaults';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class PatientIdConfig
  extends Record({
    ...stringFilterDefaults,
    name: 'patientId',
    title: 'Patient',
    targetType: IssueFilterType.Claim,
    component: (props: any) => (
      <TextInput debounced placeholder="Enter Patient ID" {...props} />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: string) => {
      if (isEmpty(value)) return true;
      if (isNil(workItem.claim.patientId)) return false;

      return workItem.claim.patientId === value;
    },
  })
  implements IClaimFilterConfiguration<string, undefined, string, string>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new PatientIdConfig();
