import { Record, Map, Set, OrderedSet } from 'immutable';
import { GraphqlTypes } from 'app/common/types';
import {
  ClaimStatus,
  LicsLevel,
  LineOfBusiness,
  MultiSourceCode,
  PharmacyType,
  QlEditType,
} from '../workitems/constants';
import { WorkItemRecord } from '../workitems/types';

export class MatchedPaidClaimRecord extends Record({
  claimId: undefined as string | undefined,
  gpi: undefined as string | undefined,
  adjudicatedDate: undefined as Date | undefined,
  filledDate: undefined as Date | undefined,
}) {}

export class StepTherapyGroup extends Record({
  step: undefined as string | undefined,
  description: undefined as string | undefined,
}) {}

export class SeniorSavingsModel extends Record({
  exceedsCopay: undefined as boolean | undefined,
  maxCopay: undefined as number | undefined,
}) {}

export class ClaimRecord extends Record({
  id: undefined as string | undefined,
  workItem: undefined as WorkItemRecord | undefined,
  claimNumber: undefined as string | undefined,
  claimStatus: undefined as ClaimStatus | undefined,
  adjudicatedDate: undefined as Date | undefined,
  filledDate: undefined as Date | undefined,
  account: undefined as string | undefined,
  benefitContractId: undefined as string | undefined,
  carrier: undefined as string | undefined,
  continuedFrom: undefined as string | undefined,
  coordinationOfBenefitsIndicator: undefined as string | undefined,
  copay: undefined as number | undefined,
  coverageStatusSchedule: undefined as string | undefined,
  dailyMorphineMilligramEquivalent: undefined as number | undefined,
  daysSupply: undefined as number | undefined,
  diagnosisCodes: Set<DiagnosisCodeRecord>(),
  diseaseStates: Set<string>(),
  drugContainsOpioid: undefined as boolean | undefined,
  drugLists: Set<DrugListRecord>(),
  drugName: undefined as string | undefined,
  eligibilityEffectiveDate: undefined as Date | undefined,
  eligibilityTerminationDate: undefined as Date | undefined,
  fillExpirationDate: undefined as Date | undefined,
  formularyPriorAuthGroup: undefined as string | undefined,
  formularyPriorAuthTypeCode: undefined as string | undefined,
  formularyRequiresPriorAuth: undefined as boolean | undefined,
  formularyTier: undefined as string | undefined,
  gpi: undefined as string | undefined,
  gpi10Description: undefined as string | undefined,
  gpi14Description: undefined as string | undefined,
  group: undefined as string | undefined,
  hasBeenRefilled: undefined as boolean | undefined,
  hasOpioidCombinatorImpact: undefined as boolean | undefined,
  hasPriorAuth: undefined as boolean | undefined,
  historicalOpioidPharmacyCount: undefined as number | undefined,
  historicalOpioidPrescriberCount: undefined as number | undefined,
  historicalOpioidPrescribers: Set<string>(),
  initialOpioidSupplySixtyDays: undefined as number | undefined,
  initialOpioidSupplyThirtyDays: undefined as number | undefined,
  isDrugCompound: undefined as boolean | undefined,
  isDrugOnFormulary: undefined as boolean | undefined,
  isDrugOpioidCombinator: undefined as boolean | undefined,
  isDrugOverTheCounter: undefined as boolean | undefined,
  isDrugProtectedClass: undefined as boolean | undefined,
  isPatientEligible: undefined as boolean | undefined,
  isPatientInLongTermCare: undefined as boolean | undefined,
  isPatientInPlanTransition: undefined as boolean | undefined,
  isPatientOpioidNaive: undefined as boolean | undefined,
  isSecondaryPayment: undefined as boolean | undefined,
  licsLevel: undefined as LicsLevel | undefined,
  lineOfBusiness: undefined as LineOfBusiness | undefined,
  matchedPaidClaims: Set<ClaimRecord>(),
  multiSourceCode: undefined as MultiSourceCode | undefined,
  ndc: undefined as string | undefined,
  patient: undefined as string | undefined,
  patientAge: undefined as number | undefined,
  patientDob: undefined as Date | undefined,
  patientEndingTotalMorphineMilligramEquivalent: undefined as
    | number
    | undefined,
  patientHasCancer: undefined as boolean | undefined,
  patientId: undefined as string | undefined,
  patientName: undefined as string | undefined,
  patientPotentialTotalMorphineMilligramEquivalent: undefined as
    | number
    | undefined,
  patientTotalMorphineMilligramEquivalent: undefined as number | undefined,
  pharmacy: undefined as string | undefined,
  pharmacyAddress1: undefined as string | undefined,
  pharmacyAddress2: undefined as string | undefined,
  pharmacyCity: undefined as string | undefined,
  pharmacyId: undefined as string | undefined,
  pharmacyName: undefined as string | undefined,
  pharmacyPhone: undefined as string | undefined,
  pharmacyState: undefined as string | undefined,
  pharmacyType: undefined as PharmacyType | undefined,
  prescriber: undefined as string | undefined,
  prescriberNpi: undefined as string | undefined,
  prescriptionEnd: undefined as Date | undefined,
  prescriptionStart: undefined as Date | undefined,
  priorAuthNumbers: Set<string>(),
  quantityDispensed: undefined as number | undefined,
  quantityLimitAmount: undefined as number | undefined,
  quantityLimitDays: undefined as number | undefined,
  quantityLimitPerDay: undefined as number | undefined,
  quantityLimitPerDayExceeded: undefined as boolean | undefined,
  quantityLimitType: undefined as QlEditType | undefined,
  quantityPerDay: undefined as number | undefined,
  quantityPreviouslyFilledInLimitWindow: undefined as number | undefined,
  daySupplyLimit: undefined as number | undefined,
  daySupplyLimitExceeded: undefined as boolean | undefined,
  receivedTransitionFill: undefined as boolean | undefined,
  refillWindowStart: undefined as Date | undefined,
  rejectCodes: Set<string>(),
  rejectMessages: Set<string>(),
  rxcui: undefined as string | undefined,
  rxNumber: undefined as string | undefined,
  seniorSavingsModel: undefined as SeniorSavingsModel | undefined,
  stepTherapyGroups: Set<StepTherapyGroup>(),
  stepTherapyRequired: undefined as boolean | undefined,
  stepTherapyType: undefined as string | undefined,
  tier: undefined as string | undefined,
  tierMismatch: undefined as boolean | undefined,
  __typename: GraphqlTypes.Claim,
}) {}

export class ClaimsStateRecord extends Record({
  claims: Map<string, ClaimRecord>(),
}) {}

export class PatientClaimsHistoryRecord extends Record({
  claims: OrderedSet<ClaimRecord>(),
  dateRange: '',
  hasAdditionalClaims: false,
}) {}

// eventually this will be moved to its own domain
// when we allow users to edit the lists on the Front End
export class DrugListRecord extends Record({
  id: '',
  name: '',
}) {}

export class DiagnosisCodeRecord extends Record({
  code: '',
  description: '',
}) {}
