import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';
import { contactContextSchema } from 'app/queue/types';
import { genericGraphQlWorker } from '../common/sagas';
import { sessionSchema } from '../session/types';
import {
  fetchNextSessionInQueueFail,
  fetchNextSessionInQueueSuccess,
  skipSessionInQueueFail,
  skipSessionInQueueSuccess,
  fetchQueueStatsSuccess,
  fetchQueueStatsFail,
  fetchContextForContactSuccess,
  fetchContextForContactFail,
} from './actions';
import {
  fetchNextSessionInQueue as fetchNextSessionInQueueApi,
  skipSessionInQueue,
  fetchQueueStats as fetchQueueStatsApi,
  fetchContextForContactAsync,
} from './api';
import {
  QUEUE_FETCH_NEXT_SESSION,
  QUEUE_SKIP_SESSION,
  QUEUE_FETCH_STATS,
  QUEUE_FETCH_CONTEXT_FOR_CONTACT,
} from './constants';

export function* fetchNextSessionInQueueWorker() {
  yield call(
    genericGraphQlWorker,
    (token) => call(fetchNextSessionInQueueApi, token),
    (response) =>
      put(
        fetchNextSessionInQueueSuccess(
          normalize(response.session ?? {}, sessionSchema),
        ),
      ),
    (error) => fetchNextSessionInQueueFail(error),
  );
}

export function* fetchNextSessionInQueueWatcher() {
  yield takeLatest(QUEUE_FETCH_NEXT_SESSION, fetchNextSessionInQueueWorker);
}

export function* skipSessionWorker(action) {
  const { id } = action.payload;
  yield call(
    genericGraphQlWorker,
    (token) => call(skipSessionInQueue, id, token),
    (response) =>
      put(
        skipSessionInQueueSuccess(
          id,
          normalize(response.session, sessionSchema),
        ),
      ),
    (error) => skipSessionInQueueFail(id, error),
  );
}

export function* skipSessionWatcher() {
  yield takeLatest(QUEUE_SKIP_SESSION, skipSessionWorker);
}

export function* fetchQueueStatsWorker() {
  yield call(
    genericGraphQlWorker,
    (token) => call(fetchQueueStatsApi, token),
    (response) => put(fetchQueueStatsSuccess(response.stats)),
    (error) => fetchQueueStatsFail(error),
  );
}

export function* fetchQueueStatsWatcher() {
  yield takeLatest(QUEUE_FETCH_STATS, fetchQueueStatsWorker);
}

export function* fetchContextForContactWorker(action) {
  const { id } = action.payload;
  yield call(
    genericGraphQlWorker,
    (token) => call(fetchContextForContactAsync, id, token),
    (response) =>
      put(
        fetchContextForContactSuccess(
          id,
          normalize(response.context, contactContextSchema),
        ),
      ),
    (error) => fetchContextForContactFail(error),
  );
}

export function* fetchContextForContactWatcher() {
  yield takeLatest(
    QUEUE_FETCH_CONTEXT_FOR_CONTACT,
    fetchContextForContactWorker,
  );
}
