import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, memo } from 'react';
import { SortDirection } from 'react-virtualized';
import styles from './HeaderCell.css';
import SortIndicator from './SortIndicator';

function HeaderCell({ dataKey, label, sortBy, sortDirection }) {
  const isCurrentlySorted = sortBy === dataKey;
  return (
    <Fragment>
      {isCurrentlySorted && (
        <SortIndicator
          key="sortDirection"
          sortDirection={sortDirection}
          className={styles.sort}
        />
      )}
      <span
        key="label"
        title={label}
        className={classnames({
          [styles.sorted]: isCurrentlySorted,
        })}
      >
        {label}
      </span>
    </Fragment>
  );
}

HeaderCell.propTypes = {
  dataKey: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf([SortDirection.ASC, SortDirection.DESC]),
};

export default memo(HeaderCell);
