import {
  COMMENT_ADD_FAIL,
  COMMENT_DELETE_FAIL,
  COMMENT_EDIT_FAIL,
} from '../../comments/constants';
import { WORKITEM_UPSERT_FAIL } from '../../workitems/constants';
import {
  NOTIFICATION_PANEL_OPEN,
  NOTIFICATION_PANEL_CLOSE,
} from '../constants';
import { NotificationsRecord } from '../types';

const reducer = (state = new NotificationsRecord(), action) => {
  switch (action.type) {
    case NOTIFICATION_PANEL_OPEN:
    case COMMENT_ADD_FAIL:
    case COMMENT_DELETE_FAIL:
    case COMMENT_EDIT_FAIL:
    case WORKITEM_UPSERT_FAIL: {
      return state.merge({ isPanelOpen: true });
    }
    case NOTIFICATION_PANEL_CLOSE: {
      return state.merge({ isPanelOpen: false });
    }
    default:
      return state;
  }
};

export default reducer;
