import { navigate } from '@reach/router';
import { normalize } from 'normalizr';
import { take, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { mapCampaignForCommit } from 'app/campaigns/utility';
import { deleteNode } from '../common/api';
import { genericGraphQlWorker } from '../common/sagas';
import {
  addCampaignSuccess,
  addCampaignFail,
  fetchCampaignsSuccess,
  fetchCampaignsFail,
  deleteCampaignSuccess,
  deleteCampaignFail,
  editCampaignSuccess,
  editCampaignFail,
  cloneCampaignSuccess,
  cloneCampaignFail,
  fetchCampaignDetailsSuccess,
  fetchCampaignDetailsFail,
} from './actions';
import {
  upsertCampaign,
  fetchCampaigns,
  fetchCampaignDetails,
  cloneCampaign,
} from './api';
import {
  CAMPAIGN_ADD,
  CAMPAIGN_EDIT,
  CAMPAIGN_CLONE_UPSERT,
  CAMPAIGN_DELETE_START,
  CAMPAIGN_DETAILS_FETCH_START,
  CAMPAIGN_QUERY_FETCH_START,
} from './constants';
import { campaignQuerySchema, campaignSchema } from './types';

export function* addCampaignWatcher() {
  while (true) {
    const action = yield take(CAMPAIGN_ADD);
    const { campaign } = action.payload;

    yield call(
      genericGraphQlWorker,
      (token) =>
        call(
          upsertCampaign,
          mapCampaignForCommit({ ...campaign, entityId: null }),
          token,
        ),
      function* onSuccess(response) {
        yield put(
          addCampaignSuccess(normalize(response.campaign, campaignSchema)),
        );

        yield call(navigate, '/campaigns');
      },
      (error) => addCampaignFail(error),
    );
  }
}

export function* editCampaignWatcher() {
  while (true) {
    const action = yield take(CAMPAIGN_EDIT);
    const { id, campaign } = action.payload;
    yield call(
      genericGraphQlWorker,
      (token) => call(upsertCampaign, mapCampaignForCommit(campaign), token),
      function* onSuccess(response) {
        yield put(
          editCampaignSuccess(id, normalize(response.campaign, campaignSchema)),
        );

        yield call(navigate, '/campaigns');
      },
      (error) => editCampaignFail(id, error),
    );
  }
}

export function* fetchCampaignDetailsWorker(action) {
  const { id } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(fetchCampaignDetails, id, token),
    (response) =>
      put(
        fetchCampaignDetailsSuccess(
          id,
          normalize(response.campaign, campaignSchema),
        ),
      ),
    (error) => fetchCampaignDetailsFail(id, error),
  );
}

export function* fetchCampaignDetailsWatcher() {
  yield takeLatest(CAMPAIGN_DETAILS_FETCH_START, fetchCampaignDetailsWorker);
}

export function* fetchCampaignsWorker() {
  yield call(
    genericGraphQlWorker,
    (token) => call(fetchCampaigns, token),
    (response) =>
      put(
        fetchCampaignsSuccess(
          normalize(response.campaigns, campaignQuerySchema),
        ),
      ),
    (error) => fetchCampaignsFail(error),
  );
}

export function* fetchCampaignsWatcher() {
  yield takeLatest(CAMPAIGN_QUERY_FETCH_START, fetchCampaignsWorker);
}

export function* deleteCampaignWatcher() {
  while (true) {
    const action = yield take(CAMPAIGN_DELETE_START);
    yield call(
      genericGraphQlWorker,
      (token) => call(deleteNode, action.payload.id, token),
      (response) => put(deleteCampaignSuccess(action.payload.id)),
      (error) => deleteCampaignFail(action.payload.id, error),
    );
  }
}

export function* cloneCampaignWorker(action) {
  const { optimisticId, cloneRecord } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(cloneCampaign, cloneRecord, token),
    function* onSuccess(response) {
      yield put(
        cloneCampaignSuccess(
          optimisticId,
          normalize(response.campaign, campaignSchema),
        ),
      );

      yield call(navigate, `/campaigns/${response.campaign.entityId}`);
    },
    (error) => cloneCampaignFail(optimisticId, action, new Date(), error),
  );
}

export function* cloneCampaignWatcher() {
  yield takeEvery(CAMPAIGN_CLONE_UPSERT, cloneCampaignWorker);
}
