import {
  CAMPAIGN_QUERY_FETCH_FAIL,
  CAMPAIGN_DELETE_START,
  CAMPAIGN_DELETE_FAIL,
  CAMPAIGN_DELETE_SUCCESS,
} from '../../campaigns/constants';
import { CampaignListRecord } from '../types';

const reducer = (state = new CampaignListRecord(), action) => {
  switch (action.type) {
    case CAMPAIGN_QUERY_FETCH_FAIL: {
      return state.merge({
        error: action.payload.error,
      });
    }
    case CAMPAIGN_DELETE_START: {
      return state.mergeDeep({
        campaignDelete: {
          isDeleting: true,
          error: null,
        },
      });
    }
    case CAMPAIGN_DELETE_FAIL: {
      return state.mergeDeep({
        campaignDelete: {
          isDeleting: false,
          error: action.payload.error,
        },
      });
    }
    case CAMPAIGN_DELETE_SUCCESS: {
      return state.mergeDeep({
        campaignDelete: {
          isDeleting: false,
          error: null,
        },
      });
    }
    default:
      return state;
  }
};

export default reducer;
