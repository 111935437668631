import { ListItem } from '@material-ui/core';
import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import MatchChipInput from 'app/filters/components/MatchChipInput';
import { IssueFilterType, MatchStrings } from 'app/filters/constants';
import { matchStringsDefaults } from 'app/filters/definitions/defaults';
import { filterTypesQuery } from 'app/filters/queries';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  MultiSelectMatchFilterRecord,
  MatchStringsInputRecord,
  MultiSelectMatchStoredValue,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(
    filterTypesQuery,
    { name: 'MatchDiseaseStateInputType' },
    token,
  )
    .then(throwIfErrors)
    .then(
      (response: {
        data: {
          fields: {
            inputFields: {
              name: string;
              type: {
                type: {
                  type: { enumValues: { name: string; description: string }[] };
                };
              };
            }[];
          };
        };
      }) =>
        response.data.fields.inputFields.find((x) => x.name === 'values')?.type
          .type.type.enumValues,
    )
    .then((values) => {
      if (isNil(values))
        throw new Error('No enum values found for disease state');
      return values;
    })
    .then((enumValues: { name: string; description: string }[]) =>
      OrderedSet(
        enumValues.map(
          (v) =>
            new SelectValueRecord({
              text: v.description,
              value: v.name,
            }),
        ),
      ),
    );

class DiseaseStatesConfig
  extends Record({
    ...matchStringsDefaults,
    name: 'diseaseStates',
    title: 'Disease State',
    targetType: IssueFilterType.Claim,
    options: (token: string) => fetchOptions(token),
    component: (props: any) => (
      <MatchChipInput
        placeholder="Enter disease state"
        noOptionsFoundComponent={<ListItem>Invalid disease state...</ListItem>}
        {...props}
      />
    ),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: MatchStringsInputRecord,
    ) => {
      if (value.values.isEmpty()) return true;
      if (isNil(workItem.claim.diseaseStates)) return false;

      if (value.match === MatchStrings.Any) {
        return value.values.some((v) =>
          workItem.claim.diseaseStates.contains(v),
        );
      }

      if (value.match === MatchStrings.All) {
        return value.values.every((v) =>
          workItem.claim.diseaseStates.contains(v),
        );
      }

      return false;
    },
  })
  implements
    IClaimFilterConfiguration<
      MultiSelectMatchFilterRecord,
      OrderedSet<SelectValueRecord>,
      MatchStringsInputRecord,
      MultiSelectMatchStoredValue
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new DiseaseStatesConfig();
