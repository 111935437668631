import { Record, Map } from 'immutable';
import { schema } from 'normalizr';

export const CommentRecord = Record({
  id: null,
  author: null,
  dateCreated: null,
  message: null,
});

export const OptimisticRecord = Record({
  comments: new Map(),
});

export const CommentStateRecord = Record({
  optimistic: new OptimisticRecord(),
  comments: new Map(),
});

const user = new schema.Entity('users');

export const comment = new schema.Entity('comments', {
  author: user,
});

export const comments = new schema.Array(comment);
