import { createAction } from '@reduxjs/toolkit';
import {
  CAMPAIGN_ADD,
  CAMPAIGN_ADD_SUCCESS,
  CAMPAIGN_ADD_FAIL,
  CAMPAIGN_EDIT,
  CAMPAIGN_EDIT_SUCCESS,
  CAMPAIGN_EDIT_FAIL,
  CAMPAIGN_DELETE_START,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_DELETE_FAIL,
  CAMPAIGN_DETAILS_FETCH_START,
  CAMPAIGN_DETAILS_FETCH_SUCCESS,
  CAMPAIGN_DETAILS_FETCH_FAIL,
  CAMPAIGN_QUERY_FETCH_START,
  CAMPAIGN_QUERY_FETCH_SUCCESS,
  CAMPAIGN_QUERY_FETCH_FAIL,
  CAMPAIGN_EDIT_FILTER_EDIT_OPEN,
  CAMPAIGN_CLONE_UPSERT,
  CAMPAIGN_CLONE_UPSERT_SUCCESS,
  CAMPAIGN_CLONE_UPSERT_FAIL,
} from './constants';
import { CloneCampaignRecord } from './types';

export function addCampaign(campaign) {
  return {
    type: CAMPAIGN_ADD,
    payload: {
      campaign,
    },
  };
}

export function addCampaignSuccess(data) {
  return {
    type: CAMPAIGN_ADD_SUCCESS,
    payload: { data },
  };
}

export function addCampaignFail(error) {
  return {
    type: CAMPAIGN_ADD_FAIL,
    payload: { error },
  };
}

export function editCampaign(entityId, campaign) {
  return {
    type: CAMPAIGN_EDIT,
    payload: {
      id: entityId,
      campaign,
    },
  };
}

export function editCampaignSuccess(entityId, data) {
  return {
    type: CAMPAIGN_EDIT_SUCCESS,
    payload: { id: entityId, data },
  };
}

export function editCampaignFail(entityId, error) {
  return {
    type: CAMPAIGN_EDIT_FAIL,
    payload: { id: entityId, error },
  };
}

export function fetchCampaignsStart() {
  return {
    type: CAMPAIGN_QUERY_FETCH_START,
    payload: {},
  };
}

export function fetchCampaignsSuccess(data) {
  return {
    type: CAMPAIGN_QUERY_FETCH_SUCCESS,
    payload: { data },
  };
}

export function fetchCampaignsFail(error) {
  return {
    type: CAMPAIGN_QUERY_FETCH_FAIL,
    payload: { error },
  };
}

export function cloneCampaign(optimisticId, cloneRecord) {
  return {
    type: CAMPAIGN_CLONE_UPSERT,
    payload: { optimisticId, cloneRecord },
  };
}

export function cloneCampaignSuccess(optimisticId, data) {
  return {
    type: CAMPAIGN_CLONE_UPSERT_SUCCESS,
    payload: { optimisticId, data },
  };
}

export function cloneCampaignFail(optimisticId, source, timestamp, error) {
  return {
    type: CAMPAIGN_CLONE_UPSERT_FAIL,
    payload: { optimisticId, source, timestamp, error },
  };
}

export function deleteCampaign(id) {
  return {
    type: CAMPAIGN_DELETE_START,
    payload: { id },
  };
}

export function deleteCampaignSuccess(id) {
  return {
    type: CAMPAIGN_DELETE_SUCCESS,
    payload: { id },
  };
}

export function deleteCampaignFail(id, error) {
  return {
    type: CAMPAIGN_DELETE_FAIL,
    payload: { id, error },
  };
}

export function fetchCampaignDetails(id) {
  return {
    type: CAMPAIGN_DETAILS_FETCH_START,
    payload: { id },
  };
}

export function fetchCampaignDetailsSuccess(id, data) {
  return {
    type: CAMPAIGN_DETAILS_FETCH_SUCCESS,
    payload: { id, data },
  };
}

export function fetchCampaignDetailsFail(id, error) {
  return {
    type: CAMPAIGN_DETAILS_FETCH_FAIL,
    payload: { id, error },
  };
}

export const campaignEditFilterOpen = createAction(
  CAMPAIGN_EDIT_FILTER_EDIT_OPEN,
  (filters) => {
    return {
      payload: {
        filters,
      },
    };
  },
);
