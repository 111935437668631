import { ListItem } from '@material-ui/core';
import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import ChipInput from 'app/filters/components/ChipInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import { createAutoCompleteOptionsQueryHandler } from 'app/filters/definitions/utility';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
  SchemaRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const handler = createAutoCompleteOptionsQueryHandler(
  'excludeBenefitContracts',
  'BENEFITCONTRACTS',
);
class ExcludeBenefitContractConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'excludeBenefitContractIds',
    title: 'Exclude Benefit Contract',
    targetType: IssueFilterType.Claim,
    options: (token: string) => handler(token),
    component: (props: any) => (
      <ChipInput
        placeholder="Enter contract IDs"
        optionsListOnly
        noOptionsFoundComponent={<ListItem>Invalid contract ID...</ListItem>}
        {...props}
      />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;

      const benefitContractId = workItem.claim.benefitContractId;
      if (isNil(benefitContractId)) return false;
      return !value.contains(benefitContractId);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExcludeBenefitContractConfig();
