import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { upsertIssue } from 'app/issues/actions';
import { SearchType } from 'app/search/types';
import { signOut, userIsInactive } from '../../../auth/actions';
import {
  addCampaign,
  editCampaign,
  deleteCampaign,
} from '../../../campaigns/actions';
import { upsertCase, bulkCreateCases } from '../../../cases/actions';
import { isFetching as isFetchingExport } from '../../../export/selectors';
import { addInteraction, saveInteraction } from '../../../interactions/actions';
import { getNotificationCount } from '../../../notifications/selectors';
import {
  closeNotificationPanel,
  closeSnackbar,
  expandSidebar,
  minimizeSidebar,
  openNotificationPanel,
} from '../../../ui/actions';
import {
  getSnackbarState,
  isNotificationPanelOpen as isNotificationPanelOpenSelector,
  isRunningAny,
  isSidebarMinimized as isSidebarMinimizedSelector,
  isTemplateQuickSaving,
  isTemplateSaving,
  isWorkItemDetailSaving,
} from '../../../ui/selectors';
import Authenticate from '../../../user/Authenticate';
import { getUserDisplayName, getCurrentUser } from '../../../user/selectors';
import AuthenticatedLayout from '../../components/AuthenticatedLayout';
import IdlePrompt from '../../components/IdlePrompt';
import UnsavedWorkPrompt from '../../components/UnsavedWorkPrompt';

const handleSearchClick = (result) => {
  switch (result.type) {
    case SearchType.Claims: {
      navigate(`/workitems/${result.entityId}`);
      break;
    }
    case SearchType.Patients: {
      navigate(`/patients/${result.id}`);
      break;
    }
    case SearchType.Cases: {
      navigate(`/cases/${result.id}`);
      break;
    }
    default: {
      throw new Error(`Unsupported search type received ${result.type}`);
    }
  }
};

export const AuthenticatedLayoutContainer = ({
  isSidebarMinimized,
  children,
  user,
  snackbar,
  notificationCount,
  isNotificationPanelOpen,
  hasOutstandingWork,
  dispatch,
}) => {
  const handleToggleMinimize = () => {
    if (isSidebarMinimized) {
      dispatch(expandSidebar());
    } else {
      dispatch(minimizeSidebar());
    }
  };

  const handleSnackBarClose = () => {
    dispatch(closeSnackbar());
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleIdle = (currentPath) => {
    dispatch(userIsInactive(currentPath));
  };

  const handleNotificationPanelRequestClose = () => {
    dispatch(closeNotificationPanel());
  };

  const handleNotificationPanelRequestOpen = () => {
    dispatch(openNotificationPanel());
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <AuthenticatedLayout
      isSidebarMinimized={isSidebarMinimized}
      onSidebarToggleMinimize={handleToggleMinimize}
      onSignOut={handleSignOut}
      user={user}
      message={snackbar.message}
      isSnackbarOpen={snackbar.isOpen}
      handleSnackbarClose={handleSnackBarClose}
      currentPath="/workitems"
      notificationCount={notificationCount}
      isNotificationPanelOpen={isNotificationPanelOpen}
      onNotificationPanelRequestClose={handleNotificationPanelRequestClose}
      onNotificationPanelRequestOpen={handleNotificationPanelRequestOpen}
      onSearchRequestOpen={() => setIsSearchOpen(true)}
      onSearchRequestClose={() => setIsSearchOpen(false)}
      isSearchOpen={isSearchOpen}
      onSearchClick={handleSearchClick}
    >
      <IdlePrompt onSignOut={handleSignOut} onIdle={handleIdle} />
      <UnsavedWorkPrompt shouldPrompt={hasOutstandingWork}>
        {children}
      </UnsavedWorkPrompt>
    </AuthenticatedLayout>
  );
};

const hasOutstandingWorkSelector = (state) =>
  isTemplateSaving(state) ||
  isTemplateQuickSaving(state) ||
  isWorkItemDetailSaving(state) ||
  isFetchingExport(state) ||
  isRunningAny(
    state,
    upsertCase(),
    bulkCreateCases(),
    upsertIssue(),
    addCampaign(),
    editCampaign(),
    saveInteraction(),
    addInteraction(),
    deleteCampaign(),
  );

AuthenticatedLayoutContainer.propTypes = {
  isSidebarMinimized: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  snackbar: PropTypes.object,
  notificationCount: PropTypes.number.isRequired,
  isNotificationPanelOpen: PropTypes.bool.isRequired,
  hasOutstandingWork: PropTypes.bool.isRequired,
};

const connectedCont = connect((state) => ({
  isSidebarMinimized: isSidebarMinimizedSelector(state),
  username: getUserDisplayName(state),
  user: getCurrentUser(state),
  snackbar: getSnackbarState(state),
  notificationCount: getNotificationCount(state),
  isNotificationPanelOpen: isNotificationPanelOpenSelector(state),
  hasOutstandingWork: hasOutstandingWorkSelector(state),
}))(AuthenticatedLayoutContainer);

export default Authenticate(connectedCont);
