export const ISSUES_QUERY_FETCH_START = 'ISSUE/ISSUES_QUERY_FETCH_START';
export const ISSUES_QUERY_FETCH_SUCCESS = 'ISSUE/ISSUES_QUERY_FETCH_SUCCESS';
export const ISSUES_QUERY_FETCH_FAIL = 'ISSUE/ISSUES_QUERY_FETCH_FAIL';

export const ISSUES_FILTER_UPDATE = 'ISSUE/ISSUES_FILTER_UPDATE';
export const ISSUES_SORT_APPLY = 'ISSUE/ISSUES_SORT_APPLY';
export const ISSUES_QUERY_CLEAR = 'ISSUE/ISSUES_QUERY_CLEAR';

export const ISSUE_UPSERT = 'ISSUE/ISSUE_UPSERT_START';
export const ISSUE_UPSERT_SUCCESS = 'ISSUE/ISSUE_UPSERT_SUCCESS';
export const ISSUE_UPSERT_FAIL = 'ISSUE/ISSUE_UPSERT_FAIL';

// TODO:  Will probably need to abstract his out.
export const ISSUES_TARGET_SEARCH_FETCH_START =
  'ISSUE/ISSUES_TARGET_SEARCH_FETCH_START';
export const ISSUES_TARGET_SEARCH_FETCH_SUCCESS =
  'ISSUE/ISSUES_TARGET_SEARCH_FETCH_SUCCESS';
export const ISSUES_TARGET_SEARCH_FETCH_FAIL =
  'ISSUE/ISSUES_TARGET_SEARCH_FETCH_FAIL';
