import {
  Dialog,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { CalendarToday, Close } from '@material-ui/icons';
import classnames from 'classnames';
import {
  format as formatDate,
  addYears,
  min,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { Record } from 'immutable';
import { isNil } from 'ramda';
import React, { useState, memo, forwardRef } from 'react';
import InfiniteCalendar, {
  Calendar,
  withRange,
  withDateSelection,
  EVENT_TYPE,
} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import { Interval } from '../../types';
import styles from './DateRangePicker.css';

const rangeCalendar = withRange(Calendar);
const dateSelectCalendar = withDateSelection(Calendar);

const defaultValidRange = new Interval({
  start: new Date(),
  end: new Date(),
});

const getInputComponent = (variant: string) => {
  switch (variant) {
    case 'outlined': {
      return OutlinedInput;
    }
    case 'filled': {
      return FilledInput;
    }
    default: {
      return Input;
    }
  }
};

type DateRangePickerProps = {
  autoFocus?: boolean;
  value?:
    | {
        start: Date | null;
        end: Date | null;
      }
    | Date
    | null;
  label?: string;
  onChange: (i: any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  range?: boolean;
  format?: string;
  view?: 'years' | 'days';
  key?: string;
  classes?: object;
  fullWidth?: boolean;
  minSelectableDate?: Date;
  maxSelectableDate?: Date;
  variant?: 'outlined' | 'filled';
  helperText?: string | null;
  error?: boolean;
  disabled?: boolean;
  clearable?: boolean;
};

const DateRangePicker = forwardRef(
  (
    {
      autoFocus = false,
      value = null,
      onChange,
      range = false,
      format = 'MM/dd/yyyy',
      variant = 'outlined',
      view = 'days',
      key = '',
      label = '',
      classes = {},
      onOpen = () => {},
      onClose = () => {},
      fullWidth = false,
      minSelectableDate = new Date(2017, 0, 1),
      maxSelectableDate = new Date(),
      helperText = null,
      error = false,
      disabled = false,
      clearable = true,
    }: DateRangePickerProps,
    ref,
  ) => {
    const [isControlled, setControlled] = useState(!isNil(value));
    if (value && isControlled) setControlled(false);
    const [selectedValue, setSelectedValue] = useState(
      range ? new Interval() : null,
    );
    const [validRange, setValidRange] = useState(
      new Interval({
        start: minSelectableDate,
        end: maxSelectableDate,
      }),
    );
    const [isOpen, setOpen] = useState(false);
    const [currentView, setCurrentView] = useState(view);
    const calendarComponent = range ? rangeCalendar : dateSelectCalendar;

    const handleChange = (v: any) => {
      let clean = v;
      if (
        isNil(v) ||
        (v instanceof Date === false && v instanceof Record === false)
      ) {
        clean = null;
      }
      setSelectedValue(clean);
      onChange(clean);
    };

    const handleClose = () => {
      setOpen(false);
      onClose();
    };

    const handleSelect = (date: any) => {
      switch (date?.eventType) {
        case EVENT_TYPE.START: {
          setValidRange(
            new Interval({
              start: addYears(date.start, -1),
              end: min([maxSelectableDate, addYears(date.start, 1)]),
            }),
          );
          break;
        }
        case EVENT_TYPE.END: {
          setValidRange(defaultValidRange);

          const selected = new Interval({
            start: startOfDay(date.start),
            end: endOfDay(date.end),
          });
          handleClose();
          handleChange(selected);
          break;
        }
        default: {
          if (range === false) {
            if (currentView === 'days') {
              handleChange(date);
              handleClose();
              setCurrentView(view);
            } else {
              setCurrentView('days');
            }
          }
          break;
        }
      }
    };

    const InputComponent = getInputComponent(variant);
    const inputId = `${key}-input-${variant}`;
    const activeValue = isControlled ? selectedValue : value;
    const displayValue = () => {
      if (range) {
        if (
          isNil((activeValue as Interval)?.start as Date) ||
          isNil((activeValue as Interval)?.end as Date)
        )
          return '';
        return `${formatDate(
          (activeValue as Interval)?.start as Date,
          format,
        )} - ${formatDate((activeValue as Interval)?.end as Date, format)}`;
      }
      if (isNil(activeValue)) return '';
      if (activeValue instanceof Date) {
        return formatDate(activeValue, format);
      }
      if (
        activeValue instanceof Record &&
        !isNil(activeValue.start) &&
        activeValue.start instanceof Date
      ) {
        return formatDate(activeValue?.start, format);
      }
      return formatDate((activeValue as Interval).start, format);
    };

    return (
      <FormControl
        variant={variant}
        classes={classes}
        focused={isOpen}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        <InputLabel htmlFor={inputId} error={error}>
          {label}
        </InputLabel>
        <InputComponent
          id={inputId}
          label={label}
          autoFocus={autoFocus}
          classes={{
            input: classnames(styles.input, { [styles.disabled]: disabled }),
          }}
          readOnly
          value={displayValue()}
          onClick={() => {
            if (!disabled) {
              setOpen(true);
              onOpen();
            }
          }}
          endAdornment={
            displayValue() === '' || disabled || !clearable ? (
              <CalendarToday className={styles.icon} />
            ) : (
              <IconButton
                onClick={(event) => {
                  handleSelect(null);
                  event.stopPropagation();
                  event.preventDefault();
                }}
                className={styles.closeIcon}
              >
                <Close />
              </IconButton>
            )
          }
          ref={ref}
          error={error}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
        <Dialog
          open={isOpen}
          onClose={handleClose}
          classes={{
            paper: styles.dialogContent,
          }}
        >
          <InfiniteCalendar
            Component={calendarComponent}
            locale={{
              headerFormat: 'MMM Do',
            }}
            onSelect={handleSelect}
            selected={activeValue}
            min={minSelectableDate}
            max={maxSelectableDate}
            minDate={validRange.start}
            maxDate={validRange.end}
            display={currentView}
          />
        </Dialog>
      </FormControl>
    );
  },
);

export default memo(DateRangePicker);
