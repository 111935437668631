export const getSortValueSelector = (sortBy, columnDefinitions) => {
  const sortingColumn = columnDefinitions.find((def) => def.id === sortBy);
  // If we didn't find a matching column or if the column has no valueSelector,
  // use a "constant" function to keep whatever order the API sent back
  return (sortingColumn && sortingColumn.valueSelector) || (() => 0);
};

export const ascDescSort = (a, b, sortDirection) => {
  if (a === undefined && b === undefined) {
    return 0;
  }

  if (a === undefined) {
    return sortDirection === 'ASC' ? 1 : -1;
  }

  if (b === undefined) {
    return sortDirection === 'ASC' ? -1 : 1;
  }

  if (sortDirection === 'ASC') {
    if (a > b) return 1;
    else if (a < b) return -1;
  } else if (sortDirection === 'DESC') {
    if (a > b) return -1;
    else if (a < b) return 1;
  }
  return 0;
};
