export const getViewTemplateSaveForm = (state) =>
  state.forms.forms.viewTemplateSave;

export const isSaving = (state, name) => {
  const form = state.forms[name];

  if (form) {
    return form.isSaving;
  }
  return null;
};

export const isSubmitted = (state, name) => {
  const form = state.forms[name];

  if (form) {
    return form.isSubmitted;
  }
  return null;
};
