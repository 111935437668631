import { combineReducers } from 'redux';
import { InteractionsStateRecord } from '../types';
import fieldDefinitions from './fieldDefinitions';
import interactions from './interactions';
import optimistic from './optimistic';

const reducer = combineReducers(
  {
    interactions,
    optimistic,
    interactionFieldDefinitions: fieldDefinitions,
  },
  InteractionsStateRecord,
);

export default reducer;
