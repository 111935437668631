import PropTypes from 'prop-types';
import React from 'react';
import WorkItemStatus from '../../../workitems/components/WorkItemStatus/WorkItemStatus';

const formatStatus = (value) => {
  switch (value) {
    case 'OPEN':
      return 'Open';
    case 'INPROGRESS':
      return 'In Progress';
    case 'DONE':
      return 'Done';
    default:
      return '';
  }
};

const CaseStatus = ({ status }) => (
  <WorkItemStatus
    status={{
      name: formatStatus(status),
      type: status,
    }}
  />
);

CaseStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CaseStatus;
