import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Person, Assignment } from '@material-ui/icons';
import React, { forwardRef, useState, cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { ClaimRecord } from 'app/claims/types';
import { graphqlSelector } from 'app/common/hooks/useQuery';
import { GraphqlTypes } from 'app/common/types';
import graphql from 'app/common/utilities/graphql';
import { PatientRecord } from 'app/patient/types';
import Search from 'app/search/containers/Search';
import { SearchResultRecord, SearchType } from 'app/search/types';
import styles from './SearchInput.css';

type TargetFieldPropTypes = {
  iconComponent: React.ReactElement;
  details: string;
};

const TargetField = ({ iconComponent, details }: TargetFieldPropTypes) => {
  return (
    <>
      {cloneElement(iconComponent, {
        color: 'action',
      })}
      <span className={styles.targetDetails}>{details}</span>
    </>
  );
};

const PatientTarget = forwardRef<HTMLInputElement, any>(function PatientTarget(
  { value },
  ref,
) {
  const query = graphql`
    query node($id: ID!) {
      node(id: $id) {
        ... on PatientType {
          id
          name
          patientId
        }
        ... on ClaimType {
          patient {
            name
            patientId
          }
          patientId
          patientName
          claimNumber
          drugName
        }
        __typename
      }
    }
  `;
  const { node }: any = useSelector((state: any) =>
    graphqlSelector(state, query, { id: value }),
  );

  const renderTarget = () => {
    switch (node.__typename) {
      case GraphqlTypes.Patient:
        return (
          <TargetField
            iconComponent={<Person />}
            details={`${node.name} - ${node.patientId}`}
          />
        );
      case GraphqlTypes.Claim:
        return (
          <TargetField
            iconComponent={<Assignment />}
            details={`${node.claimNumber} - ${node.drugName} for member ${node.patientId} - ${node.patientName}`}
          />
        );
      default:
        <></>;
    }
  };
  return <div className={styles.targetContainer}>{node && renderTarget()}</div>;
});

export type SearchInputPropTypes = {
  value: PatientRecord | ClaimRecord | undefined;
  error: boolean;
  disabled?: boolean;
  label: string;
  classes: { root: string };
  helperText: React.ReactNode;
  onChange: (entityId: string) => void;
};

const SearchInput = forwardRef(
  (
    {
      value,
      onChange,
      label,
      error,
      classes,
      disabled = false,
      helperText,
    }: SearchInputPropTypes,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const inputId = `${label}-input-outlined`;

    const handleSearchClick = (result: SearchResultRecord) => {
      switch (result.type) {
        case SearchType.Claims:
        case SearchType.Patients: {
          onChange(result.id);
          break;
        }
        default: {
          throw new Error('Unsupported result record');
        }
      }
    };
    return (
      <>
        <FormControl
          classes={classes}
          variant="outlined"
          focused={isOpen}
          disabled={disabled}
        >
          <InputLabel htmlFor={inputId} error={error}>
            {label}
          </InputLabel>
          <OutlinedInput
            id={inputId}
            ref={ref}
            label={label}
            readOnly
            value={value}
            classes={{ input: styles.input }}
            onClick={() => {
              if (!disabled) {
                setIsOpen(true);
              }
            }}
            error={error}
            inputComponent={PatientTarget}
          />
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </FormControl>
        <Search
          isOpen={isOpen}
          onClick={handleSearchClick}
          onClose={() => setIsOpen(false)}
          searchTypes={[SearchType.Patients, SearchType.Claims]}
        />
      </>
    );
  },
);

export default SearchInput;
