import { addDays, subDays, startOfDay, endOfDay } from 'date-fns';
import { Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import {
  MatchesClaimValuesRecord,
  MatchesClaimBackendRecord,
  GPILevelValueRecord,
  DayRangeValueRecord,
  MatchesClaimOptionsRecord,
  IClaimFilterConfiguration,
} from 'app/filters/types';
import { GpiLevel } from '../../../common/constants';
import { StoredFilterType } from '../../../viewtemplates/constants';
import { WorkItemRecord } from '../../../workitems/types';
import MatchesClaim from '../../components/MatchesClaim';

const DAY_RANGES = [7, 5, 3, 1, 0];

const formatDayRange = (dayRange: number) => {
  switch (dayRange) {
    case 0:
      return 'Same Day';
    case 1:
      return '1 Day';
    default:
      return `${dayRange} Days`;
  }
};

const formatGPILevel = (gpiLevel: string) => {
  switch (gpiLevel) {
    case GpiLevel.Six:
      return 6;
    case GpiLevel.Ten:
      return 10;
    case GpiLevel.Twelve:
      return 12;
    case GpiLevel.Fourteen:
      return 14;
    default:
      throw new Error('Unsupported gpi level passed to formatGpiLevel()');
  }
};

const filterToTemplateConverter = (value: MatchesClaimValuesRecord) =>
  new MatchesClaimBackendRecord({
    hasPaidClaim: value.isMatching,
    gpiLevel: value.gpiLevel.value,
    window: value.dayRange.value,
  });

class MatchesPaidClaimConfig
  extends Record({
    name: 'matchesPaidClaim',
    title: 'Matches Paid Claim',
    enabled: true,
    targetType: IssueFilterType.Claim,
    workflowSpecific: false,
    storedValueType: StoredFilterType.MatchesPaidClaim,
    summaryFormatter: (value: MatchesClaimValuesRecord) =>
      `${value.isMatching ? 'Yes' : 'No'}, using GPI ${formatGPILevel(
        value.gpiLevel.value,
      )} within ${formatDayRange(value.dayRange.value)}`,
    defaultValue: () =>
      new MatchesClaimValuesRecord({
        isMatching: true,
        gpiLevel: new GPILevelValueRecord({
          text: formatGPILevel(GpiLevel.Six).toString(),
          value: GpiLevel.Six,
        }),
        dayRange: new DayRangeValueRecord({
          text: formatDayRange(7),
          value: 7,
        }),
      }),
    component: (props: any) => <MatchesClaim {...props} />,
    options: () =>
      new MatchesClaimOptionsRecord({
        gpiLevels: Set(
          Object.values(GpiLevel).map(
            (gpiLevel) =>
              new GPILevelValueRecord({
                text: formatGPILevel(gpiLevel).toString(),
                value: gpiLevel,
              }),
          ),
        ),
        dayRanges: Set(
          DAY_RANGES.map(
            (dayRange) =>
              new DayRangeValueRecord({
                text: formatDayRange(dayRange),
                value: dayRange,
              }),
          ),
        ),
      }),
    storedValueConstructor: (value: {
      hasPaidClaim: boolean;
      gpiLevel: string;
      window: number;
    }) =>
      new MatchesClaimBackendRecord({
        hasPaidClaim: value.hasPaidClaim,
        gpiLevel: value.gpiLevel as GpiLevel,
        window: value.window,
      }),
    valueToQueryConverter: filterToTemplateConverter,
    filterToTemplateValueConverter: filterToTemplateConverter,
    templateToFilterValueConverter: (value: MatchesClaimBackendRecord) =>
      new MatchesClaimValuesRecord({
        isMatching: value.hasPaidClaim,
        gpiLevel: new GPILevelValueRecord({
          text: formatGPILevel(value.gpiLevel).toString(),
          value: value.gpiLevel,
        }),
        dayRange: new DayRangeValueRecord({
          text: formatDayRange(value.window),
          value: value.window,
        }),
      }),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: MatchesClaimBackendRecord,
    ) => {
      if (isNil(workItem.claim.matchedPaidClaims)) return false;
      // not bringing back matchedPaidClaims anywhere at the moment so we should
      // never be applying this client side

      if (
        isNil(workItem.claim.gpi) ||
        workItem.claim.matchedPaidClaims.isEmpty()
      )
        return !value.hasPaidClaim;

      const isMatching = workItem.claim.matchedPaidClaims.some((paidClaim) => {
        const dateOnClaim = isNil(paidClaim.filledDate)
          ? workItem.claim.adjudicatedDate
          : workItem.claim.filledDate;

        // TODO should we make adjudicated date non nullable?
        // How do we deal with not selecting it via graphql query?
        if (isNil(dateOnClaim))
          throw new Error('null adjudicated date on claim');

        const startDate = subDays(
          startOfDay(new Date(dateOnClaim)),
          value.window,
        );

        const endDate = addDays(endOfDay(new Date(dateOnClaim)), value.window);

        if (isNil(paidClaim.gpi)) throw new Error('null gpi found');
        if (isNil(workItem.claim.gpi)) throw new Error('null gpi found');

        const doesGpiLevelMatch = paidClaim.gpi.startsWith(
          workItem.claim.gpi.substring(0, formatGPILevel(value.gpiLevel)),
        );

        if (!doesGpiLevelMatch) return false;
        const paidClaimDate = !isNil(paidClaim.filledDate)
          ? paidClaim.filledDate
          : paidClaim.adjudicatedDate;

        if (isNil(paidClaimDate))
          throw new Error('Matching paid has null dates');

        const claimDate = new Date(paidClaimDate);
        const isDateWithinWindow =
          claimDate >= startDate && claimDate <= endDate;

        if (!isDateWithinWindow) return false;

        return true;
      });

      return value.hasPaidClaim ? isMatching : !isMatching;
    },
  })
  implements
    IClaimFilterConfiguration<
      MatchesClaimValuesRecord,
      MatchesClaimOptionsRecord,
      MatchesClaimBackendRecord,
      MatchesClaimBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new MatchesPaidClaimConfig();
