import { normalize } from 'normalizr';
import { complement, isNil } from 'ramda';
import { actions } from 'react-redux-form';
import { call, put, select, takeLatest, take, all } from 'redux-saga/effects';
import { deleteNode } from 'app/common/api';
import { getAppliedFilters } from 'app/ui/selectors';
import { getAccessToken, getActiveViewTemplateId } from 'app/user/selectors';
import {
  fetchViewTemplatesSuccess,
  fetchViewTemplatesFail,
  saveViewTemplateSuccess,
  saveViewTemplateFail,
  createViewTemplateSuccess,
  createViewTemplateFail,
  quickSaveViewTemplateSuccess,
  quickSaveViewTemplateFail,
  fetchViewTemplateCounts,
  fetchViewTemplateCountSuccess,
  fetchViewTemplateCountFail,
  deleteViewTemplateSuccess,
  deleteViewTemplateFail,
} from './actions';
import {
  fetchViewTemplatesAsync,
  saveViewTemplateAsync,
  fetchViewTemplateCountAsync,
} from './api';
import {
  VIEWTEMPLATES_FETCH_START,
  VIEWTEMPLATE_SAVE_START,
  VIEWTEMPLATE_CREATE_START,
  VIEWTEMPLATE_QUICKSAVE_START,
  VIEWTEMPLATE_APPLY,
  VIEWTEMPLATES_FETCH_COUNT_START,
  VIEWTEMPLATE_DELETE,
} from './constants';
import { templatesSchema } from './types';
import { mapResponseToViewTemplate, mapViewTemplateForCommit } from './utility';

export function* fetchViewTemplatesWorker(action) {
  try {
    const token = yield select(getAccessToken);
    const viewTemplates = yield call(fetchViewTemplatesAsync, token);
    const templates = viewTemplates
      .map((template) => mapResponseToViewTemplate(template))
      .filter(complement(isNil));

    yield put(fetchViewTemplatesSuccess(normalize(templates, templatesSchema)));
    if (action.payload.fetchCounts) {
      const templateIds = templates.map((template) => template.id);
      yield put(fetchViewTemplateCounts(templateIds));
    }
  } catch (e) {
    yield put(fetchViewTemplatesFail(e));
  }
}

export function* fetchViewTemplatesWatcher() {
  yield takeLatest(VIEWTEMPLATES_FETCH_START, fetchViewTemplatesWorker);
}

export function* saveTemplate(action) {
  const token = yield select(getAccessToken);
  const mappedViewTemplate = mapViewTemplateForCommit(
    action.payload.viewTemplate,
  );
  const response = yield call(saveViewTemplateAsync, token, mappedViewTemplate);
  // TODO update all these save paths to follor the normalizr pattern
  response.templateResponse.createdBy = response.templateResponse.createdBy.id;
  return response;
}

export function* handleViewTemplateCommit(onSuccess, onFailure, action) {
  try {
    yield put(actions.change('forms.viewTemplateSave.isSaving', true));
    const response = yield call(saveTemplate, action);
    if (response.successful) {
      const savedViewTemplate = mapResponseToViewTemplate(
        response.templateResponse,
      );
      yield put(onSuccess(savedViewTemplate));
      yield put(actions.setSubmitted('forms.viewTemplateSave', true));
      yield put(actions.change('forms.viewTemplateSave.isSubmitted', true));
    } else {
      yield put(onFailure(response.errors));
      yield all(
        response.errors.map((error) => {
          const message = error.messages.join(' ');
          let model = null;
          switch (error.fieldName) {
            case 'Name':
              model = 'forms.viewTemplateSave.name';
              break;
            default:
              model = 'forms.viewTemplateSave';
          }
          return put(actions.setErrors(model, message));
        }),
      );
    }
  } catch (e) {
    yield put(onFailure(e));
    yield put(
      actions.setErrors(
        'forms.viewTemplateSave',
        'There was an unexpected error saving the view template.',
      ),
    );
  } finally {
    yield put(actions.change('forms.viewTemplateSave.isSaving', false));
  }
}

export function* saveViewTemplateWorker(action) {
  yield call(
    handleViewTemplateCommit,
    saveViewTemplateSuccess,
    saveViewTemplateFail,
    action,
  );
}

export function* createViewTemplateWorker(action) {
  yield call(
    handleViewTemplateCommit,
    createViewTemplateSuccess,
    createViewTemplateFail,
    action,
  );
}

export function* quickSaveViewTemplateWorker(action) {
  try {
    const response = yield call(saveTemplate, action);
    const savedViewTemplate = mapResponseToViewTemplate(
      response.templateResponse,
    );
    yield put(quickSaveViewTemplateSuccess(savedViewTemplate));
  } catch (e) {
    yield put(quickSaveViewTemplateFail(e));
  }
}

export function* saveViewTemplateWatcher() {
  yield takeLatest(VIEWTEMPLATE_SAVE_START, saveViewTemplateWorker);
}

export function* createViewTemplateWatcher() {
  yield takeLatest(VIEWTEMPLATE_CREATE_START, createViewTemplateWorker);
}

export function* quickSaveViewTemplateWatcher() {
  yield takeLatest(VIEWTEMPLATE_QUICKSAVE_START, quickSaveViewTemplateWorker);
}

export function* fetchViewTemplateCountWorker(token, id) {
  try {
    const count = yield call(fetchViewTemplateCountAsync, token, id);
    yield put(fetchViewTemplateCountSuccess(id, count.count, count.isStale));
  } catch (e) {
    yield put(fetchViewTemplateCountFail(id, e));
  }
}

export function* fetchViewTemplateCountsWorker(action) {
  const { ids } = action.payload;
  const token = yield select(getAccessToken);
  yield all(ids.map((id) => call(fetchViewTemplateCountWorker, token, id)));
}

export function* fetchViewTemplateCountsWatcher() {
  yield takeLatest(
    VIEWTEMPLATES_FETCH_COUNT_START,
    fetchViewTemplateCountsWorker,
  );
}

export function* deleteTemplateWorker(action) {
  const { id } = action.payload;
  try {
    const token = yield select(getAccessToken);
    const activeTemplateId = yield select(getActiveViewTemplateId);
    const response = yield call(deleteNode, id, token);

    if (isNil(response.errors)) {
      yield put(deleteViewTemplateSuccess(id, activeTemplateId === id));
    } else {
      yield put(deleteViewTemplateFail(id, response.errors));
    }
  } catch (e) {
    yield put(deleteViewTemplateFail(id, e));
  }
}

export function* deleteTemplateWatcher() {
  while (true) {
    const action = yield take(VIEWTEMPLATE_DELETE);
    yield call(deleteTemplateWorker, action);
  }
}
