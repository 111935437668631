import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ColorChip from '../../../common/components/ColorChip';
import RiskScore from '../RiskScore/RiskScore';
import RuleModifier from '../RuleModifier';
import styles from './BestPracticeRuleCard.css';

const ModifiersSection = ({ modifiers }) => {
  const renderedModifiers = modifiers
    .filter(
      (modifier) =>
        modifier.inappropriate > 0 ||
        modifier.appropriate > 0 ||
        modifier.notReviewed > 0,
    )
    .toArray()
    .map((modifier) => (
      <RuleModifier
        key={modifier.id}
        id={modifier.id}
        inappropriate={modifier.inappropriate}
        appropriate={modifier.appropriate}
        notReviewed={modifier.notReviewed}
      />
    ));

  if (renderedModifiers.length > 0) {
    return (
      <div className={styles.section}>
        <div className={styles.flex}>{renderedModifiers}</div>
      </div>
    );
  }
  return <div />;
};
ModifiersSection.propTypes = {
  modifiers: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      id: PropTypes.string.isRequired,
      inappropriate: PropTypes.number.isRequired,
      appropriate: PropTypes.number.isRequired,
      notReviewed: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

const BestPracticeRuleCard = ({ rule, className }) => {
  const reviewedCount = rule.appropriate + rule.inappropriate;
  const totalCount = reviewedCount + rule.notReviewed;

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.summary}>
        <span className={styles.bold}>{totalCount}</span> {rule.description}
      </div>

      {(rule.notReviewed > 0 || reviewedCount > 0) && (
        <div className={styles.content}>
          <RiskScore className={styles.risk} score={rule.riskScore.score} />
          <ColorChip
            className={classnames({
              [styles.red]: rule.notReviewed > 0,
              [styles.grey]: rule.notReviewed === 0,
            })}
          />
          <div className={styles.details}>
            {/* is there a better way to align numbers? */}
            <div className={styles.table}>
              {rule.notReviewed > 0 && (
                <div className={classnames(styles.row, styles.highRisk)}>
                  <div className={classnames(styles.column, styles.number)}>
                    {rule.notReviewed}
                  </div>
                  <div className={styles.column}>
                    of these claims have not been reviewed
                  </div>
                </div>
              )}
              {reviewedCount > 0 && (
                <div className={styles.group}>
                  <div className={styles.row}>
                    <div className={classnames(styles.column, styles.number)}>
                      {reviewedCount}
                    </div>
                    <div className={styles.column}>
                      of these claims have been reviewed
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.column} />
                    <div className={styles.column}>
                      <div className={styles.table}>
                        <div className={styles.row}>
                          <div
                            className={classnames(styles.column, styles.number)}
                          >
                            {rule.appropriate}
                          </div>
                          <div className={styles.column}>were appropriate</div>
                        </div>
                        <div className={styles.row}>
                          <div
                            className={classnames(styles.column, styles.number)}
                          >
                            {rule.inappropriate}
                          </div>
                          <div className={styles.column}>
                            were inappropriate
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {totalCount > 0 &&
              rule.riskModifiers &&
              rule.riskModifiers.size > 0 && (
                <ModifiersSection modifiers={rule.riskModifiers} />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

BestPracticeRuleCard.propTypes = {
  rule: ImmutablePropTypes.recordOf({
    description: PropTypes.string.isRequired,
    appropriate: PropTypes.number.isRequired,
    inappropriate: PropTypes.number.isRequired,
    notReviewed: PropTypes.number.isRequired,
    riskModifiers: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  }).isRequired,
  className: PropTypes.string,
};

export default BestPracticeRuleCard;
