import { Record } from 'immutable';
import { schema } from 'normalizr';
import { caseSchema } from 'app/cases/types';
import { contactSchema } from 'app/interactions/types';
import { userSchema } from 'app/users/types';

export enum OutreachStatus {
  NoInteraction,
  CreateInteraction,
  StartInteraction,
  RecordInteraction,
}

export class QueueStatsRecord extends Record({
  outstandingCount: 0,
  completedCount: 0,
  activeRepresentativesCount: 0,
}) {}

export class ContactContextRecord extends Record({
  lockedBy: undefined as string | undefined,
}) {}

export class QueueStateRecord extends Record({
  lockedSession: null,
  contactContext: new ContactContextRecord(),
  stats: new QueueStatsRecord(),
}) {}

export const contactContextSchema = new schema.Object({
  lockedBy: userSchema,
  contact: contactSchema,
  cases: new schema.Array(caseSchema),
});
