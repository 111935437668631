import { Paper, Tabs, Tab, Tooltip } from '@material-ui/core';
import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { getActiveTabFromLocation } from 'app/common/utilities/generic';
import ClaimViewer from '../../containers/ClaimViewer';
import CaseList from './CaseList';
import Opioids from './Opioids/Opioids';
import Overview from './Overview';
import styles from './PatientProfile.css';

const PatientProfileLayout = ({ children, patient, navigate, location }) => {
  const tabs = ['./', 'claims', 'eligibility', 'opioids', 'cases'];
  const activeTab = getActiveTabFromLocation(tabs, location);

  return (
    <Scrollbars>
      <div className={styles.page}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>{patient?.patientId}</h1>
          <div className={styles.navigation}>
            <Paper elevation={3}>
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, v) => navigate(v)}
              >
                <Tab label="Overview" value="./" />
                <Tab label="Claims" value="claims" />
                <Tab label="Opioids" value="opioids" />
                <Tab label="Cases" value="cases" />
              </Tabs>
            </Paper>
          </div>
        </div>
        {children}
      </div>
    </Scrollbars>
  );
};

const PatientOverview = ({
  patient,
  opioidIssueCount,
  hasFetchedOpioidIssueCount,
  isOpioidFetchErrored,
  casesCount,
  hasFetchedPatient,
}) => (
  <div className={styles.overviewContainer}>
    <Overview
      patient={patient}
      opioidIssueCount={opioidIssueCount}
      hasFetchedOpioidIssueCount={hasFetchedOpioidIssueCount}
      isOpioidFetchErrored={isOpioidFetchErrored}
      casesCount={casesCount}
      hasFetchedPatient={hasFetchedPatient}
    />
  </div>
);

const PatientProfile = ({
  patient,
  opioidIssues,
  opioidIssueCount,
  casesCount,
  location,
  hasFetchedOpioidView,
  isFetchingOpioidView,
  isOpioidFetchErrored,
  hasFetched,
}) => (
  <Router primary={false}>
    <PatientProfileLayout path="/" patient={patient} location={location}>
      <PatientOverview
        default
        path="overview"
        patient={patient}
        opioidIssueCount={opioidIssueCount}
        hasFetchedOpioidIssueCount={hasFetchedOpioidView}
        isOpioidFetchErrored={isOpioidFetchErrored}
        casesCount={casesCount}
        hasFetchedPatient={hasFetched}
      />
      <ClaimViewer path="claims" id={patient.id} />
      <Opioids
        path="opioids"
        patient={patient}
        issues={opioidIssues}
        hasFetched={hasFetchedOpioidView}
        isFetching={isFetchingOpioidView}
        isErrored={isOpioidFetchErrored}
      />
      <CaseList path="cases" caseIds={patient.cases} />
    </PatientProfileLayout>
  </Router>
);

PatientProfile.propTypes = {
  patient: PropTypes.object,
  opioidIssueCount: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetchedOpioidView: PropTypes.bool.isRequired,
  isFetchingOpioidView: PropTypes.bool.isRequired,
};

export default PatientProfile;
