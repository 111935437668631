import { Link } from '@reach/router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ContentLoader from 'react-content-loader';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from './CategoryCard.css';

const CategoryCard = ({
  categoryId,
  categoryName,
  riskScore,
  isPlaceholder,
  isFetching,
}) => {
  const score = riskScore?.score;
  const minRiskScore = riskScore?.min;
  const maxRiskScore = riskScore?.max;
  const addressableRisk = score - minRiskScore;
  const addressedRisk = maxRiskScore - score;
  const percentRiskAddressed =
    1 - (score - minRiskScore) / (maxRiskScore - minRiskScore);
  return (
    <Link
      to={isFetching ? '' : `${categoryId}`}
      className={classnames({
        [styles.container]: true,
        [styles.placeholder]: isPlaceholder,
      })}
    >
      <svg
        viewBox="0 0 100 5"
        height="10px"
        width="100%"
        preserveAspectRatio="none"
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        <rect x="0" y="0" height="5" width="100" fill="#f72c2c" rx="0" />
        {!isFetching && (
          <rect
            x="0"
            y="0"
            height="5"
            width={addressableRisk === 0 ? 100 : percentRiskAddressed * 100}
            fill="#80c644"
            rx="0"
          />
        )}
        {isFetching && (
          <rect x="0" y="0" height="5" width="100" fill="lightgrey" rx="0" />
        )}
      </svg>
      <div className={styles.header}>
        {isFetching && (
          <ContentLoader
            speed={2}
            width={140}
            height={17}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            className={styles.headerLoader}
          >
            <rect x="0" y="0" rx="2" ry="2" width="140" height="17" />
          </ContentLoader>
        )}
        {!isFetching &&
          (isPlaceholder ? (
            categoryName
          ) : (
            <div className={styles.title}>{categoryName}</div>
          ))}
      </div>
      <div className={styles.content}>
        <div className={styles.scoreContainer}>
          <div className={styles.score}>
            <div className={styles.scoreNumber}>
              {isPlaceholder || isFetching ? '--' : Math.round(score)}
            </div>
            <div className={styles.scoreText}>Risk</div>
          </div>
          {isFetching && (
            <ContentLoader
              speed={2}
              width={180}
              height={60}
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              className={styles.scoreLoader}
            >
              <rect x="0" y="8" rx="2" ry="2" width="180" height="20" />
              <rect x="0" y="40" rx="2" ry="2" width="180" height="20" />
            </ContentLoader>
          )}
          {!isFetching && (
            <div className={styles.context}>
              <span className={styles.contextNumber}>{addressedRisk}</span>
              <span className={styles.contextText}> risk has been reduced</span>
            </div>
          )}
          {!isFetching && addressableRisk === 0 && (
            <div className={styles.context}>
              <span className={styles.contextNumber}>{addressableRisk}</span>
              <span className={styles.contextText}>
                {' '}
                further risk can be reduced
              </span>
            </div>
          )}
          {!isFetching && addressableRisk > 0 && (
            <div className={styles.context}>
              <span className={styles.contextNumber}>{addressableRisk}</span>
              <span className={styles.contextText}> risk can be reduced</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.navText}>View details</div>
      {isPlaceholder && <div className={styles.footer}>Coming Soon!</div>}
    </Link>
  );
};

CategoryCard.propTypes = {
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  riskScore: ImmutablePropTypes.recordOf({
    score: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  }),
  isPlaceholder: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
};

CategoryCard.defaultProps = {
  isPlaceholder: false,
};

export default CategoryCard;
