import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { toDays, toMonths, toWeeks, toYears } from 'duration-fns';
import { OrderedSet, Record, Set } from 'immutable';
import { isEmpty, not, prepend, range, without } from 'ramda';
import { ChangeEvent } from 'react';
import TextInput from 'app/common/components/TextInput';
import { DurationRecord } from '../../../common/duration';
import { SelectValueRecord, DosingScheduleFilterRecord } from '../../types';
import ChipInput from '../ChipInput';
import styles from './DosingSchedule.css';

type DosingScheduleComponentProps = {
  value: DosingScheduleFilterRecord;
  onChange: (value: DosingScheduleFilterRecord) => void;
};

enum DosingIntervalUnits {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

class DosingInterval extends Record({
  value: 0,
  units: DosingIntervalUnits.Days,
}) {}

const DosingSchedule = ({ value, onChange }: DosingScheduleComponentProps) => {
  const getDosingTiming = (duration: DurationRecord | null) => {
    if (duration === null) {
      if (value.dosingSchedule.maxDoses === 1) return null;

      throw new Error('Null duration only allowed when max doses is 1.');
    }

    const durationAsObject = duration.toObject();

    if (duration.days !== 0) {
      return new DosingInterval({
        value: toDays(durationAsObject),
        units: DosingIntervalUnits.Days,
      });
    }

    if (duration.weeks !== 0) {
      return new DosingInterval({
        value: toWeeks(durationAsObject),
        units: DosingIntervalUnits.Weeks,
      });
    }

    if (duration.months !== 0) {
      return new DosingInterval({
        value: toMonths(durationAsObject),
        units: DosingIntervalUnits.Months,
      });
    }

    if (duration.years !== 0) {
      return new DosingInterval({
        value: toYears(durationAsObject),
        units: DosingIntervalUnits.Years,
      });
    }

    throw new Error('Cannot convert period to dosing schedule.');
  };

  const dosingTiming = getDosingTiming(value.dosingSchedule.dosingInterval);
  const handleMaxDoses = (event: ChangeEvent<{ value: unknown }>) => {
    const maxDoses = isEmpty(event.target.value)
      ? null
      : (event.target.value as number);

    onChange(
      value.merge({
        dosingSchedule: value.dosingSchedule.merge({
          maxDoses,
          dosingInterval:
            maxDoses !== 1
              ? value.dosingSchedule.dosingInterval === null
                ? new DurationRecord({ days: 30 })
                : value.dosingSchedule.dosingInterval
              : null,
        }),
      }),
    );
  };

  const handleReceivedStatus = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(
      value.merge({
        exceedsSchedule: not(event.target.value === 'true'),
      }),
    );
  };

  const handleGpiChange = (gpis: OrderedSet<SelectValueRecord>) => {
    onChange(
      value.merge({
        dosingSchedule: value.dosingSchedule.merge({
          gpiPrefixes: gpis,
        }),
      }),
    );
  };

  const handleDosingIntervalNumericChange = (text: string) => {
    if (dosingTiming === null) return;
    const num = Number.parseInt(text);

    onChange(
      value.merge({
        dosingSchedule: value.dosingSchedule.merge({
          dosingInterval: new DurationRecord({
            years: dosingTiming.units === DosingIntervalUnits.Years ? num : 0,
            months: dosingTiming.units === DosingIntervalUnits.Months ? num : 0,
            weeks: dosingTiming.units === DosingIntervalUnits.Weeks ? num : 0,
            days: dosingTiming.units === DosingIntervalUnits.Days ? num : 0,
          }),
        }),
      }),
    );
  };

  const handleDosingIntervalUnitChange = (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    if (dosingTiming === null) return;

    const units = event.target.value as DosingIntervalUnits;

    onChange(
      value.merge({
        dosingSchedule: value.dosingSchedule.merge({
          dosingInterval: new DurationRecord({
            years: units === DosingIntervalUnits.Years ? dosingTiming.value : 0,
            months:
              units === DosingIntervalUnits.Months ? dosingTiming.value : 0,
            weeks: units === DosingIntervalUnits.Weeks ? dosingTiming.value : 0,
            days: units === DosingIntervalUnits.Days ? dosingTiming.value : 0,
          }),
        }),
      }),
    );
  };
  const hasReceived = not(value.exceedsSchedule).toString();
  return (
    <div>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>Received</FormLabel>
        <RadioGroup value={hasReceived} onChange={handleReceivedStatus}>
          <FormControlLabel
            value={'true'}
            label="Yes"
            control={
              <Radio classes={{ root: styles.slimRadio }} color="primary" />
            }
          />
          <FormControlLabel
            value={'false'}
            label="No"
            control={
              <Radio classes={{ root: styles.slimRadio }} color="primary" />
            }
          />
        </RadioGroup>
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>
          Number of Doses Required
        </FormLabel>
        <Select
          displayEmpty
          onChange={handleMaxDoses}
          value={value.dosingSchedule?.maxDoses ?? ''}
          defaultValue={''}
        >
          {without([1], prepend('' as string | number, range(1, 10))).map(
            (i) => {
              return (
                <MenuItem value={i}>
                  {i === '' ? <em>Recurring</em> : i}
                </MenuItem>
              );
            },
          )}
        </Select>
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>GPIs</FormLabel>
        <ChipInput
          onChange={handleGpiChange}
          options={Set()}
          value={value.dosingSchedule?.gpiPrefixes}
          optionsListOnly={false}
          placeholder="Enter GPI Prefixes"
        />
      </FormControl>
      {value.dosingSchedule?.maxDoses !== 1 && (
        <FormControl fullWidth margin="normal">
          <FormLabel classes={{ root: styles.labelHeader }}>
            Time between doses
          </FormLabel>
          <div className={styles.dosingTiming}>
            <TextInput
              type="number"
              onChange={handleDosingIntervalNumericChange}
              value={(dosingTiming as DosingInterval).value}
              classes={{ root: styles.halfWidth }}
              placeholder={`Enter number of ${dosingTiming?.units}`}
            />
            <div className={styles.halfWidth}>
              <Select
                fullWidth
                value={(dosingTiming as DosingInterval).units}
                onChange={handleDosingIntervalUnitChange}
              >
                <MenuItem value={DosingIntervalUnits.Days}>days</MenuItem>
                <MenuItem value={DosingIntervalUnits.Weeks}>weeks</MenuItem>
                <MenuItem value={DosingIntervalUnits.Months}>months</MenuItem>
                <MenuItem value={DosingIntervalUnits.Years}>years</MenuItem>
              </Select>
            </div>
          </div>
        </FormControl>
      )}
    </div>
  );
};

export default DosingSchedule;
