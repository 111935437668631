import {
  EXPORT_DOWNLOAD_REQUEST,
  EXPORT_DOWNLOAD_FAIL,
  EXPORT_DOWNLOAD_COMPLETE,
  EXPORT_CASE_DOWNLOAD_FETCH_SUCCESS,
  EXPORT_CASE_DOWNLOAD_FETCH_FAIL,
  EXPORT_CASE_DOWNLOAD_FETCH_START,
} from './constants';

export function exportDownloadRequest(query) {
  return {
    type: EXPORT_DOWNLOAD_REQUEST,
    payload: { query },
  };
}

export function exportDownloadFail(error) {
  return {
    type: EXPORT_DOWNLOAD_FAIL,
    payload: error,
  };
}

export function exportDownloadComplete() {
  return {
    type: EXPORT_DOWNLOAD_COMPLETE,
    payload: {},
  };
}

export function exportCaseDownloadRequest(query) {
  return {
    type: EXPORT_CASE_DOWNLOAD_FETCH_START,
    payload: { query },
  };
}

export function exportCaseDownloadFetchSuccess() {
  return {
    type: EXPORT_CASE_DOWNLOAD_FETCH_SUCCESS,
    payload: {},
  };
}

export function exportCaseDownloadFetchFail(error) {
  return {
    type: EXPORT_CASE_DOWNLOAD_FETCH_FAIL,
    payload: error,
  };
}
