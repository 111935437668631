import { Record, Map, Set } from 'immutable';
import { schema } from 'normalizr';

export const BestPracticeStateRecord = Record({
  categories: new Map(),
  bestPractices: new Map(),
  rules: new Map(),
});

export const BestPracticeCategoryPageRecord = Record({
  category: null,
  categoryRiskScore: NaN,
});

export const BestPracticeCategoryRecord = Record({
  id: null,
  name: null,
  bestPractices: new Set(),
});

export const BestPracticeRecord = Record({
  name: null,
  id: null,
  rules: new Set(),
});

export const BestPracticeRuleRecord = Record({
  description: null,
  id: null,
  dailyResults: new Map(),
});

export const BestPracticeRuleResultsRecord = Record({
  date: null,
  inappropriate: null,
  appropriate: null,
  notReviewed: null,
  riskModifiers: new Map(),
});

export const RiskModifierResultsRecord = Record({
  id: null,
  inappropriate: null,
  appropriate: null,
  notReviewed: null,
});

const ruleSchema = new schema.Entity('rules');

const bestPracticeSchema = new schema.Entity('bestPractices', {
  rules: new schema.Array(ruleSchema),
});

const categorySchema = new schema.Entity(
  'categories',
  {
    bestPractices: new schema.Array(bestPracticeSchema),
  },
  { idAttribute: 'id' },
);

export const bestPracticeStateSchema = new schema.Array(categorySchema);

export const BestPracticeRuleSummarizedRecord = Record({
  id: null,
  description: null,
  inappropriate: null,
  appropriate: null,
  notReviewed: null,
  riskModifiers: new Set(),
  riskScore: NaN,
});

export const SummaryRecord = Record({
  appropriate: 0,
  inappropriate: 0,
  notReviewed: 0,
});

export const RiskScoreRecord = Record({
  score: 0,
  max: 0,
  min: 0,
});

export const RiskRecord = Record({
  date: null,
  riskScore: new RiskScoreRecord(),
  period: null,
});

export const CategoryRiskRecord = Record({
  id: null,
  name: null,
  riskScore: new RiskScoreRecord(),
});
