import { isNil } from 'ramda';

const includeActions = ['_FETCH', '_UPSERT'];

export const getFetchKeyForAction = (action) => {
  let key = action.type.substring(
    0,
    includeActions
      .filter((x) => action.type.indexOf(x) >= 0)
      .map((x) => action.type.indexOf(x) + x.length)[0],
  );
  if (isNil(action.payload.optimisticId) === false) {
    key = `${key}:${action.payload.optimisticId}`;
  } else if (isNil(action.payload.id) === false) {
    key = `${key}:${action.payload.id}`;
  }
  return key;
};
