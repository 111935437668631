import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { isValidNumber } from '../../../common/utilities/generic';
import styles from './RiskScore.css';

const { round } = Math;

const RiskScore = ({ score, className }) => (
  <div className={classnames(styles.risk, className)}>
    <div>{isValidNumber(score) ? round(score) : '--'}</div>
    <div className={styles.label}>Risk</div>
  </div>
);

RiskScore.propTypes = {
  score: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default RiskScore;
