import {
  Checkbox,
  TextField,
  CircularProgress,
  Button,
  Fab,
  Tooltip,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
} from '@material-ui/core';
import { Add, Check, Error, FilterList } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { navigate, useLocation } from '@reach/router';
import { Record, Set } from 'immutable';
import PropTypes from 'prop-types';
import {
  isNil,
  isEmpty,
  pipe,
  uniq,
  map,
  path,
  nth,
  sort,
  descend,
  prop,
  sortWith,
  cond,
  equals,
  always,
} from 'ramda';
import { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch, useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { fetchCampaignsStart } from 'app/campaigns/actions';
import { getCampaigns } from 'app/campaigns/selectors';
import { upsertCase } from 'app/cases/actions';
import HeaderBar from 'app/common/components/HeaderBar/HeaderBar';
import MultiSelectDropDown from 'app/common/components/MutliSelectDropDown/MultiSelectDropDown';
import Table from 'app/common/components/Table/Table';
import UnexpectedError from 'app/common/components/UnexpectedError/UnexpectedError';
import { GraphqlTypes } from 'app/common/types';
import { toArray } from 'app/common/utilities/generic';
import { SelectValueRecord } from 'app/filters/types';
import { ContactType } from 'app/interactions/constants';
import {
  applyIssuesSort,
  clearIssuesQuery,
  fetchTargets,
  fetchIssuesQuery,
  updateIssueFilter,
} from 'app/issues/actions';
import {
  getTargets,
  getIssuesFilters,
  getIssuesList,
} from 'app/issues/selectors';
import { IssuesFilterStateRecord, TargetType } from 'app/issues/types';
import {
  getIdFromOptimisticId,
  getIssueListSortBy,
  getIssueListSortDirection,
  hasCompleted,
  isErrored,
  isRunning,
} from 'app/ui/selectors';
import { getUserId } from 'app/user/selectors';
import styles from './IssueList.css';
import columnDefinitions from './columns';

const campaignToSelectValue = (campaign) =>
  new SelectValueRecord({
    text: campaign.name,
    value: campaign.entityId,
  });

const targetToSelectValue = (target) =>
  new SelectValueRecord({
    text: target.name,
    value: target.entityId,
  });

const AutoCompleteDropDown = ({
  searchAction,
  filterKey,
  options,
  value,
  onChange,
  label,
  disabled,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => isRunning(state, searchAction()));
  const [searchText, setSearchText] = useState('');

  const onSearch = (val) => {
    if (!isNil(val) && !isEmpty(val)) {
      dispatch(searchAction(val));
    }
  };

  const errorSearching = useSelector((state) =>
    isErrored(state, searchAction(value)),
  );

  const searchSubject = new Subject();
  searchSubject.pipe(debounceTime(250)).subscribe(onSearch);

  const allOptions = value
    .filter((x) => !options.some((o) => o.value === x))
    .map((x) => new SelectValueRecord({ text: x, value: x }))
    .toOrderedSet()
    .union(options)
    .toArray();

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      id={filterKey}
      key={filterKey}
      classes={{ root: styles.formControl, tag: styles.tag }}
      options={allOptions}
      disableCloseOnSelect
      limitTags={2}
      getOptionLabel={(option) => option.text}
      loading={isLoading}
      loadingText="Loading"
      getOptionSelected={(option, val) => option.value === val.value}
      onChange={(event, val) => {
        setSearchText('');
        onChange(filterKey, new Set(val.map((x) => x.value)));
      }}
      inputValue={searchText}
      onInputChange={(event, val, reason) => {
        // possible bug here https://github.com/mui-org/material-ui/issues/20939
        if (reason !== 'reset') {
          setSearchText(val);
          searchSubject.next(val);
        }
      }}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox checked={selected} color="primary" />
          {option.text}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!errorSearching && isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {!isLoading && errorSearching ? (
                  <Tooltip title="Error while searching, more results may be available">
                    <Error style={{ fill: '#f72c2c' }} />
                  </Tooltip>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={value.map((x) => allOptions.find((y) => y.value === x)).toArray()}
    />
  );
};

AutoCompleteDropDown.propTypes = {
  searchAction: PropTypes.func.isRequired,
  filterKey: PropTypes.string.isRequired,
  options: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  value: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

AutoCompleteDropDown.defaultProps = {
  disabled: false,
  value: new Set(),
};

const IssueList = ({
  issues,
  isLoading,
  errorFetchingIssues,
  campaignOptions,
  targets,
  onChange,
  filters,
  onClearFilters,
  onSort,
  sortBy,
  sortDirection,
  selected,
  onSelectedChange,
  onCreateCase,
  onCreateIssue,
  isCreating,
  hasCreated,
}) => {
  const campaigns = campaignOptions.map(campaignToSelectValue);
  const targetOptions = targets.map(targetToSelectValue);
  const hasSelectedIssues = selected.isEmpty() === false;

  return (
    <div className={styles.container}>
      <HeaderBar>
        <div className={styles.headerContainer}>
          <h1>Issues</h1>
          <FilterList className={styles.headerIcon} />
          <MultiSelectDropDown
            label="Campaigns"
            id="campaigns"
            value={filters.campaigns}
            options={campaigns.sortBy((x) => x.text.toUpperCase())}
            onChange={onChange}
            disabled={hasSelectedIssues}
            formClassName={styles.formControl}
          />
          <AutoCompleteDropDown
            filterKey="targets"
            searchAction={fetchTargets}
            options={targetOptions}
            value={filters.targets}
            onChange={onChange}
            label="Targets"
            disabled={hasSelectedIssues}
          />
          <FormControl variant="outlined" className={styles.formControl}>
            <InputLabel shrink id="isEffective">
              Is Effective
            </InputLabel>
            <Select
              labelId="isEffective"
              variant="outlined"
              value={isNil(filters.isEffective) ? '' : filters.isEffective}
              input={<OutlinedInput notched label="Is Effective" />}
              displayEmpty
              onChange={(event) =>
                onChange(
                  'isEffective',
                  isEmpty(event.target.value) ? null : event.target.value,
                )
              }
            >
              <MenuItem value={true}>Effective</MenuItem>
              <MenuItem value={false}>Not Effective</MenuItem>
              <MenuItem value="">Either</MenuItem>
            </Select>
          </FormControl>
          <Button
            classes={{ root: styles.clearButton }}
            onClick={onClearFilters}
            disabled={
              (filters.campaigns.isEmpty() && filters.targets.isEmpty()) ||
              hasSelectedIssues
            }
          >
            Clear Filters
          </Button>
        </div>
      </HeaderBar>
      <div className={styles.table}>
        {!errorFetchingIssues && (
          <Table
            data={issues}
            isFetching={isLoading}
            columnDefinitions={columnDefinitions(onSelectedChange, selected)}
            isSortEnabled
            onSort={onSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
        )}
        {errorFetchingIssues && <UnexpectedError />}
      </div>
      <Fab
        disabled={isCreating || hasCreated}
        color="primary"
        classes={{ root: styles.create }}
        onClick={hasSelectedIssues ? onCreateCase : onCreateIssue}
        variant="extended"
      >
        <div className={styles.fabContainer}>
          {hasSelectedIssues && (
            <>
              <Check />
              <span>Create Case</span>
            </>
          )}
          {!hasSelectedIssues && (
            <>
              <Add />
              <span>Create Issue</span>
            </>
          )}
        </div>
      </Fab>
    </div>
  );
};

const SupportingDocumentContactRecord = new Record({
  patientEntity: '',
  prescriberEntity: '',
  date: new Date(0),
  claimNumber: '',
});

IssueList.propTypes = {
  issues: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  isLoading: PropTypes.bool.isRequired,
  errorFetchingIssues: PropTypes.bool,
  campaignOptions: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  targets: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  onChange: PropTypes.func,
  filters: ImmutablePropTypes.recordOf({
    campaigns: ImmutablePropTypes.setOf(PropTypes.string),
    targets: ImmutablePropTypes.setOf(PropTypes.string),
  }),
  onClearFilters: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['ASC', 'DESC']),
  selected: ImmutablePropTypes.setOf(PropTypes.string),
  onSelectedChange: PropTypes.func.isRequired,
  onCreateCase: PropTypes.func.isRequired,
  onCreateIssue: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  hasCreated: PropTypes.bool.isRequired,
};

IssueList.defaultProps = {
  issues: new Set(),
  errorFetchingIssues: false,
  campaignOptions: new Set(),
  targets: new Set(),
  onChange: () => {},
  filters: new IssuesFilterStateRecord(),
  sortBy: undefined,
  sortDirection: 'DESC',
  selected: new Set(),
};

const IssueListContainer = () => {
  const dispatch = useDispatch();

  const [optimisticId] = useState(uuid());

  // Issue Selectors
  // TODO:  Update to real selector...
  const issues = useSelector((state) => getIssuesList(state));
  const filters = useSelector((state) => getIssuesFilters(state));
  const userId = useSelector((state) => getUserId(state));
  const createdCaseId = useSelector((state) =>
    getIdFromOptimisticId(state, optimisticId),
  );

  const location = useLocation();
  const { newIssue } = location.state;

  // Fetching Selectors
  const isLoading = useSelector((state) =>
    isRunning(state, fetchIssuesQuery()),
  );
  const hasLoaded = useSelector((state) =>
    hasCompleted(state, fetchIssuesQuery()),
  );
  const errorFetchingIssues = useSelector((state) =>
    isErrored(state, fetchIssuesQuery()),
  );
  const isCreating = useSelector((state) =>
    isRunning(state, upsertCase(optimisticId)),
  );
  const hasCreated = useSelector((state) =>
    hasCompleted(state, upsertCase(optimisticId)),
  );

  const targets = useSelector((state) => getTargets(state));

  const campaigns = useSelector((state) => getCampaigns(state));

  const sortBy = useSelector((state) => getIssueListSortBy(state));
  const sortDirection = useSelector((state) =>
    getIssueListSortDirection(state),
  );

  const [selected, setSelected] = useState(new Set());

  useEffect(() => {
    if (!hasLoaded && !isLoading && !errorFetchingIssues) {
      dispatch(fetchCampaignsStart());
    }
  });

  const handleFilterChange = (name, value) => {
    dispatch(updateIssueFilter(filters.merge({ [name]: value })));
  };

  const handleClearFilters = () => {
    dispatch(
      updateIssueFilter(
        filters.merge({
          campaigns: new Set(),
          targets: new Set(),
        }),
      ),
    );
  };

  const handleSelectedChange = (id, value) => {
    if (value === true) {
      const issue = issues.find((x) => x.id === id);

      setSelected(selected.add(id));
      dispatch(
        updateIssueFilter(
          filters.mergeDeep({
            campaigns: [issue.campaign.entityId],
            targets: [issue.target.entityId],
          }),
        ),
      );
    } else {
      const newSelected = selected.remove(id);
      setSelected(newSelected);
      if (newSelected.isEmpty()) {
        dispatch(
          updateIssueFilter(
            filters.merge({
              campaigns: new Set(),
              targets: new Set(),
            }),
          ),
        );
      }
    }
  };

  const handleCreateCase = () => {
    const issuesInCase = issues.filter((x) => selected.has(x.id));

    const campaignEntityId = pipe(
      toArray,
      map(path(['campaign', 'entityId'])),
      uniq,
      nth(0),
    )(issuesInCase);

    const campaign = campaigns.find((x) => x.entityId === campaignEntityId);

    const getContactProp = pipe(
      prop('contact'),
      cond([
        [equals(ContactType.Prescriber), always('prescriberEntity')],
        [equals(ContactType.Patient), always('patientEntity')],
      ]),
    );

    const mapEntities = (doc) => {
      if (doc.__typename === GraphqlTypes.Claim) {
        return new SupportingDocumentContactRecord({
          patientEntity: doc.patient?.entityId,
          prescriberEntity: doc.prescriber?.entityId,
          date: doc.filledDate,
          claimNumber: doc.claimNumber,
        });
      }

      if (doc.__typename === GraphqlTypes.Patient) {
        return new SupportingDocumentContactRecord({
          patientEntity: doc.entityId,
        });
      }
    };
    const getContact = pipe(
      toArray,
      sort(descend(prop('createdDate'))),
      nth(0),
      prop('supportingDocuments'),
      map(mapEntities),
      sortWith([descend(prop('date')), descend(prop('claimNumber'))]),
      nth(0),
      prop(getContactProp(campaign)),
    );

    dispatch(
      upsertCase(optimisticId, {
        campaignEntityId,
        target: issuesInCase.first().target.entityId,
        contact: getContact(issuesInCase),
        assignee: userId,
        issues: selected,
      }),
    );
  };

  useEffect(() => {
    if (!isCreating && hasCreated && !isNil(createdCaseId)) {
      window.history.replaceState({}, '', location.path);
      navigate(`/cases/${createdCaseId}`);
    }
  }, [isCreating, hasCreated, createdCaseId]);

  const handleOnSort = ({ sortBy, sortDirection }) => {
    dispatch(applyIssuesSort(sortBy, sortDirection));
  };

  useEffect(() => {
    dispatch(fetchIssuesQuery(filters, null, null, null));
  }, [filters]);

  useEffect(() => {
    // TODO:  re-enable when we can add in
    // if (continuationToken != null || isLoading) {
    // Temp for now to remove the double load on initial render.
    if (hasLoaded) {
      dispatch(clearIssuesQuery());
      dispatch(fetchIssuesQuery(filters, sortBy, sortDirection, null));
      // }
    }
  }, [sortBy, sortDirection]);

  useEffect(
    () => () =>
      dispatch(
        updateIssueFilter(
          filters.merge({
            campaigns: new Set(),
            targets: new Set(),
          }),
        ),
      ),
    [],
  );

  useEffect(() => {
    if (!isNil(newIssue)) {
      dispatch(
        updateIssueFilter(
          new IssuesFilterStateRecord({
            campaigns: new Set([newIssue.campaign.entityId]),
            targets: new Set([newIssue.target.entityId]),
          }),
        ),
      );
      setSelected(selected.clear().add(newIssue.id));
    }
  }, [newIssue]);

  const handleCreateIssue = () => {
    navigate('/issues/create');
  };

  return (
    <IssueList
      issues={issues}
      isLoading={isLoading}
      errorFetchingIssues={errorFetchingIssues}
      campaignOptions={campaigns}
      onChange={handleFilterChange}
      filters={filters}
      targets={targets
        .toSet()
        .union(isNil(newIssue) ? new Set() : new Set([newIssue.target]))}
      onClearFilters={handleClearFilters}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onSort={handleOnSort}
      selected={selected}
      onSelectedChange={handleSelectedChange}
      onCreateCase={handleCreateCase}
      isCreating={isCreating}
      hasCreated={hasCreated}
      onCreateIssue={handleCreateIssue}
    />
  );
};

IssueListContainer.propTypes = {};
IssueListContainer.defaultProps = {};

export default IssueListContainer;
