import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import styles from './Message.css';

const Message = ({ className, icon, message }) => (
  <div className={classnames(styles.messageContainer, className)}>
    {cloneElement(icon, {
      className: classnames(icon.props.className, styles.messageIcon),
    })}
    <div className={styles.messageText}>{message}</div>
  </div>
);

Message.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
};

export default Message;
