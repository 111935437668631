import {
  CircularProgress,
  Popper,
  Grow,
  Paper,
  List,
  ListItem,
} from '@material-ui/core';
import classnames from 'classnames';
import Downshift from 'downshift';
import { OrderedSet } from 'immutable';
import MuiChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import {
  filter,
  useWith,
  prop,
  curry,
  isNil,
  isEmpty,
  anyPass,
  complement,
  pipe,
  trim,
} from 'ramda';
import React, { Component, createRef } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { levenshteinSort } from 'app/common/utilities/generic';
import { SelectValueRecord } from 'app/filters/types';
import styles from './ChipInput.css';

const sort = (a, b) => a.text.localeCompare(b.text);

const valueFuzzyComparer = curry((a, b) =>
  b.text.toUpperCase().includes(a.toUpperCase()),
);

const valueEqualityComparer = curry(
  (a, b) => a.toUpperCase() === b.text.toUpperCase(),
);

const getOptionForValue = curry(
  (options, currentlySelected, optionsListOnly, value) => {
    const unselectedOptions = options.subtract(currentlySelected);

    if (optionsListOnly) {
      const fuzzyMatches = filter(valueFuzzyComparer(value))(unselectedOptions);

      if (fuzzyMatches.size > 1) {
        const exactMatches = filter(valueEqualityComparer(value))(fuzzyMatches);

        if (exactMatches.size === 1) {
          return exactMatches.first();
        }
      } else if (fuzzyMatches.size === 1) {
        return fuzzyMatches.first();
      }
    } else {
      const exactMatches = filter(valueEqualityComparer(value))(
        unselectedOptions,
      );

      if (exactMatches.size === 1) {
        return exactMatches.first();
      } else {
        return new SelectValueRecord({ text: value, value });
      }
    }

    return null;
  },
);

class ChipInput extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    this.chipInputRef = createRef();
    this.menuRef = createRef();
  }

  handleFocus = () => {
    const { onFocus } = this.props;
    onFocus();
  };

  handleAdd = (valueToAdd) => {
    const { value, options, optionsListOnly } = this.props;
    const option = getOptionForValue(
      options,
      value,
      optionsListOnly,
      valueToAdd.text,
    );
    this.addOptionToValue(option);
  };

  handleBeforeAdd = (valueToAdd) => {
    const { value, options, optionsListOnly } = this.props;
    return pipe(
      getOptionForValue(options, value, optionsListOnly),
      complement(isNil),
    )(valueToAdd);
  };

  addOptionToValue = (option) => {
    const { onChange, value } = this.props;
    pipe(
      (o) => value.add(o),
      (v) => v.sort(sort),
      onChange,
    )(option);
  };

  handleDelete = (valueToDelete) => {
    const { onChange, value } = this.props;
    let normalizedValueToDelete = valueToDelete;

    if (!isNil(valueToDelete?.value)) {
      normalizedValueToDelete = valueToDelete.value;
    }

    onChange(
      value
        .remove(value.find((v) => v.value === normalizedValueToDelete))
        .sort(sort),
    );
  };

  render() {
    const {
      value,
      options,
      filter: suppliedFilter,
      placeholder,
      isFetching,
      optionsListOnly,
      noOptionsFoundComponent,
      showOptionsOnFocus,
      variant,
      label,
      classes,
      margin,
      disabled,
    } = this.props;
    return (
      <div
        className={classnames(styles.container, classes?.root)}
        ref={this.chipInputRef}
      >
        <Downshift
          onChange={this.addOptionToValue}
          itemToString={() => null}
          selectedItem={null}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            inputValue = '',
            highlightedIndex,
            setState,
          }) => {
            const input = !isNil(inputValue) ? inputValue : '';
            const filteredOptions = suppliedFilter(
              input,
              options.subtract(value),
            )
              .sort(
                useWith(levenshteinSort(input), [prop('text'), prop('text')]),
              )
              .take(5)
              .toArray();
            const { onClick } = getToggleButtonProps();
            const {
              onKeyDown,
              onChange,
              onBlur,
              value: blas,
              ...inputProps
            } = getInputProps({
              onFocus: (...args) => {
                // TODO this is a hack, usecombobox may provide a better api via openmenu()
                if (showOptionsOnFocus) onClick(...args);
              },
            });

            const menuStyle = {};
            if (!isNil(this.chipInputRef.current)) {
              menuStyle.width =
                this.chipInputRef.current.getBoundingClientRect().width;
            }

            const showAutocomplete =
              isOpen &&
              ((!optionsListOnly && filteredOptions.length > 0) ||
                optionsListOnly);

            return (
              <div style={{ width: '100%' }}>
                <MuiChipInput
                  clearInputValueOnChange
                  classes={{
                    chip: styles.chip,
                  }}
                  margin={margin}
                  disabled={disabled}
                  label={label}
                  placeholder={placeholder}
                  dataSourceConfig={{ text: 'text', value: 'value' }}
                  dataSource={filteredOptions}
                  onClick={this.handleFocus}
                  onUpdateInput={onChange}
                  onBeforeAdd={this.handleBeforeAdd}
                  onAdd={(v) => {
                    setState({ inputValue: null });
                    this.handleAdd(v);
                  }}
                  onDelete={this.handleDelete}
                  value={value.toArray()}
                  fullWidth
                  InputProps={inputProps}
                  inputRef={(r) => {
                    this.inputRef.current = r;
                  }} // just this.inputRef does not work, muichip wants a function
                  onKeyDown={onKeyDown}
                  onBlur={onBlur}
                  variant={variant}
                />
                {isFetching && (
                  <CircularProgress size={16} className={styles.spinner} />
                )}
                <Popper
                  open={showAutocomplete}
                  // This zindex hack prevents material-ui labels from rendering ontop of our autocomplete
                  style={{ zIndex: 99999 }}
                  anchorEl={this.inputRef.current}
                  transition
                  placement="bottom-start"
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: '0 0 0' }}
                      className={styles.autocompleteContainer}
                    >
                      <Paper>
                        <List
                          style={menuStyle}
                          {...getMenuProps(
                            {},
                            {
                              // It appears ref() is being called by list AFTER downshift calls validateGetMenuPropsCalledCorrectly() due to the popper
                              // TODO figure out a way to guarantee render of <List /> on first pass
                              suppressRefError: true,
                            },
                          )}
                        >
                          {filteredOptions.length === 0 &&
                            optionsListOnly &&
                            noOptionsFoundComponent}
                          {filteredOptions.map((x, index) => (
                            <ListItem
                              {...getItemProps({ item: x })}
                              selected={index === highlightedIndex}
                              key={x.value}
                            >
                              {x.text}
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }
}

ChipInput.propTypes = {
  value: ImmutablePropTypes.orderedSetOf(ImmutablePropTypes.record),
  options: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ),
  optionsListOnly: PropTypes.bool, // If true, only allow the selection of options in the list. If false, allow any input
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  filter: PropTypes.func,
  showOptionsOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.oneOf(['dense', 'normal', 'none']),
};

ChipInput.defaultProps = {
  value: new OrderedSet(),
  optionsListOnly: false,
  disabled: false,
  margin: 'normal',
  placeholder: 'Search',
  isFetching: false,
  options: OrderedSet(),
  onFocus: () => {},
  filter: (filter, options) => {
    const validate = pipe(trim, complement(anyPass([isNil, isEmpty])));

    if (validate(filter)) {
      return options.filter((value) =>
        value.text.toUpperCase().includes(filter.toUpperCase()),
      );
    }
    return options;
  },
  noOptionsFoundComponent: <ListItem>Nothing Found...</ListItem>,
};

export default ChipInput;
