import { getUser } from '../users/selectors';

const getCommentsState = (state) => state.commentsState;
const getComments = (state) => getCommentsState(state).comments;
const getOptimisticState = (state) => getCommentsState(state).optimistic;
const getOptimisticComments = (state) => getOptimisticState(state).comments;

const getDenormalizedComment = (state, comment) => {
  const user = getUser(state, comment.author);
  return comment.merge({
    author: user,
    dateCreated: new Date(comment.dateCreated),
    message: comment.message,
  });
};

const getDenormalizedOptimisticComments = (state, ids) =>
  getOptimisticComments(state)
    .merge(getComments(state))
    .filter((comment) => ids.includes(comment.id))
    .map((comment) =>
      getDenormalizedComment(
        state,
        comment.merge(getOptimisticComments(state).get(comment.id)),
      ),
    );

export const getCommentsById = (state, ids) => {
  const optimisticComments = getDenormalizedOptimisticComments(state, ids);

  return optimisticComments.sort((a, b) => {
    if (a.dateCreated < b.dateCreated) {
      return -1;
    }
    if (a.dateCreated > b.dateCreated) {
      return 1;
    }
    return 0;
  });
};
