import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import { numericOperatorConfig, IssueFilterType } from 'app/filters/constants';
import { numericRangeDefaults } from 'app/filters/definitions/defaults';
import {
  handleNumericComparisonOnChange,
  numericToTextComparisonConverter,
} from 'app/filters/definitions/utility';
import { WorkItemRecord } from '../../../workitems/types';
import TextComparison from '../../components/TextComparison';
import {
  IFilterConfiguration,
  NumericComparison,
  NumericRangeRecord,
  NumericOperator,
  BoundaryTypes,
} from '../../types';

const summaryFormatter = (value: NumericComparison) => {
  if (isNil(value.value)) return 'All';
  return `${numericOperatorConfig(value.operator.value).summary} 
    ${value.value} days`;
};

class DaysSupplyConfig
  extends Record({
    ...numericRangeDefaults,
    name: 'daysSupply',
    title: 'Days Supply',
    targetType: IssueFilterType.Claim,
    summaryFormatter,
    component: (props: any) => (
      <TextComparison
        {...props}
        inputType="number"
        placeholder="Enter days"
        onChange={handleNumericComparisonOnChange(props)}
        value={numericToTextComparisonConverter(props.value)}
      />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: NumericRangeRecord) => {
      if (isNil(workItem.claim.daysSupply)) return false;
      if (isNil(value.minimum) && isNil(value.maximum)) return true;

      const { daysSupply } = workItem.claim;

      let included = true;
      if (!isNil(value.minimum)) {
        if (value.minimum.type === BoundaryTypes.Inclusive) {
          included = daysSupply >= value.minimum.value;
        } else if (value.minimum.type === BoundaryTypes.Exclusive) {
          included = daysSupply > value.minimum.value;
        }
      }

      if (!included) return false;

      if (!isNil(value.maximum)) {
        if (value.maximum.type === BoundaryTypes.Inclusive) {
          included = daysSupply <= value.maximum.value;
        } else if (value.maximum.type === BoundaryTypes.Exclusive) {
          included = daysSupply < value.maximum.value;
        }
      }

      return included;
    },
  })
  implements
    IFilterConfiguration<
      NumericComparison,
      OrderedSet<NumericOperator>,
      NumericRangeRecord,
      NumericRangeRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new DaysSupplyConfig();
