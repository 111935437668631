import { OrderedSet, Record } from 'immutable';
import { fetchTherapiesQuery } from 'app/campaigns/queries';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import { IssueFilterType } from 'app/filters/constants';
import { matchStringsDefaults } from 'app/filters/definitions/defaults';
import {
  MultiSelectMatchFilterRecord,
  IFilterConfiguration,
  SelectValueRecord,
  MatchStringsInputRecord,
  MultiSelectMatchStoredValue,
} from 'app/filters/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(fetchTherapiesQuery, {}, token)
    .then(throwIfErrors)
    .then((data) => data.data.therapies)
    .then((therapies: Array<{ entityId: string; name: string }>) =>
      OrderedSet(
        therapies.map(
          (d) =>
            new SelectValueRecord({
              text: d.name,
              value: d.entityId,
            }),
        ),
      ),
    );

class NewToTherapiesConfig
  extends Record({
    ...matchStringsDefaults,
    name: 'newToTherapies',
    title: 'New to Therapies',
    targetType: IssueFilterType.Patient,
    options: (token: string) => fetchOptions(token),
    summaryFormatter: (value: MultiSelectMatchFilterRecord) => {
      if (value.values.isEmpty() === false) {
        return `Has ${value.match} of the following: ${value.values
          .map((x) => x.text)
          .join(', ')}`;
      }

      return 'All';
    },
  })
  implements
    IFilterConfiguration<
      MultiSelectMatchFilterRecord,
      OrderedSet<SelectValueRecord>,
      MatchStringsInputRecord,
      MultiSelectMatchStoredValue
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new NewToTherapiesConfig();
