import { combineReducers } from 'redux-immutable';
import { WorkItemsStateRecord } from '../types';
import optimistic from './optimistic';
import queryResults from './queryResults';
import sorting from './sorting';
import workItemStatuses from './workItemStatuses';
import workItems from './workItems';

const reducer = combineReducers(
  {
    optimistic,
    workItems,
    queryResults,
    sorting,
    workItemStatuses,
  },
  WorkItemsStateRecord,
);

export default reducer;
