export const caresourceAdherence = {
  id: 'adherence-case-workflow',
  key: 'case',
  context: {
    attempts: 0,
  },
  type: 'compound',
  initial: 'OPEN',
  history: false,
  states: {
    OPEN: {
      id: 'case.OPEN',
      key: 'OPEN',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        REVEAL_INTERACTION_STARTED: [
          {
            target: ['#case.INPROGRESS'],
            event: 'REVEAL_INTERACTION_STARTED',
            actions: [],
            source: '#case.OPEN',
            internal: false,
            eventType: 'REVEAL_INTERACTION_STARTED',
          },
        ],
      },
      transitions: [
        {
          target: ['#case.INPROGRESS'],
          event: 'REVEAL_INTERACTION_STARTED',
          actions: [],
          source: '#case.OPEN',
          internal: false,
          eventType: 'REVEAL_INTERACTION_STARTED',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 1,
      invoke: [],
    },
    INPROGRESS: {
      id: 'case.INPROGRESS',
      key: 'INPROGRESS',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        INTERACTION_COMPLETED_FIELD_BARRIER_ALLERGICREACTION: [
          {
            target: ['#case.DONE'],
            event: 'INTERACTION_COMPLETED_FIELD_BARRIER_ALLERGICREACTION',
            actions: [],
            source: '#case.INPROGRESS',
            internal: false,
            eventType: 'INTERACTION_COMPLETED_FIELD_BARRIER_ALLERGICREACTION',
          },
        ],
        INTERACTION_COMPLETED_FIELD_BARRIER_ADVERSEREACTION: [
          {
            target: ['#case.DONE'],
            event: 'INTERACTION_COMPLETED_FIELD_BARRIER_ADVERSEREACTION',
            actions: [],
            source: '#case.INPROGRESS',
            internal: false,
            eventType: 'INTERACTION_COMPLETED_FIELD_BARRIER_ADVERSEREACTION',
          },
        ],
        INTERACTION_COMPLETED_FIELD_ATTEMPTRESULT_CONFIRMEDMEDPICKUP: [
          {
            target: ['#case.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_ATTEMPTRESULT_CONFIRMEDMEDPICKUP',
            actions: [],
            source: '#case.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_ATTEMPTRESULT_CONFIRMEDMEDPICKUP',
          },
        ],
        INTERACTION_COMPLETED_FIELD_BARRIER_NOLONGERTAKING: [
          {
            target: ['#case.DONE'],
            event: 'INTERACTION_COMPLETED_FIELD_BARRIER_NOLONGERTAKING',
            actions: [],
            source: '#case.INPROGRESS',
            internal: false,
            eventType: 'INTERACTION_COMPLETED_FIELD_BARRIER_NOLONGERTAKING',
          },
        ],
      },
      transitions: [
        {
          target: ['#case.DONE'],
          event: 'INTERACTION_COMPLETED_FIELD_BARRIER_ALLERGICREACTION',
          actions: [],
          source: '#case.INPROGRESS',
          internal: false,
          eventType: 'INTERACTION_COMPLETED_FIELD_BARRIER_ALLERGICREACTION',
        },
        {
          target: ['#case.DONE'],
          event: 'INTERACTION_COMPLETED_FIELD_BARRIER_ADVERSEREACTION',
          actions: [],
          source: '#case.INPROGRESS',
          internal: false,
          eventType: 'INTERACTION_COMPLETED_FIELD_BARRIER_ADVERSEREACTION',
        },
        {
          target: ['#case.DONE'],
          event: 'INTERACTION_COMPLETED_FIELD_ATTEMPTRESULT_CONFIRMEDMEDPICKUP',
          actions: [],
          source: '#case.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_ATTEMPTRESULT_CONFIRMEDMEDPICKUP',
        },
        {
          target: ['#case.DONE'],
          event: 'INTERACTION_COMPLETED_FIELD_BARRIER_NOLONGERTAKING',
          actions: [],
          source: '#case.INPROGRESS',
          internal: false,
          eventType: 'INTERACTION_COMPLETED_FIELD_BARRIER_NOLONGERTAKING',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 2,
      invoke: [],
    },
    DONE: {
      id: 'case.DONE',
      key: 'DONE',
      type: 'final',
      history: false,
      states: {},
      on: {},
      transitions: [],
      entry: [],
      exit: [],
      activities: [],
      order: 3,
      invoke: [],
    },
  },
  on: {
    INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED: [
      {
        target: ['#case.DONE'],
        event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
        actions: [],
        source: '#case',
        internal: false,
        eventType:
          'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      },
    ],
  },
  transitions: [
    {
      target: ['#case.DONE'],
      event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      actions: [],
      source: '#case',
      internal: false,
      eventType: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
    },
  ],
  entry: [],
  exit: [],
  activities: [],
  order: -1,
  invoke: [],
};

export const manualCaseStatus = {
  id: 'manual-case-status-workflow',
  key: 'manual-case-status-workflow',
  context: {},
  type: 'compound',
  initial: 'OPEN',
  history: false,
  states: {
    OPEN: {
      id: 'manual-case-status-workflow.OPEN',
      key: 'OPEN',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        REVEAL_INTERACTION_STARTED: [
          {
            target: ['#manual-case-status-workflow.INPROGRESS'],
            event: 'REVEAL_INTERACTION_STARTED',
            actions: [],
            source: '#manual-case-status-workflow.OPEN',
            internal: false,
            eventType: 'REVEAL_INTERACTION_STARTED',
          },
        ],
      },
      transitions: [
        {
          target: ['#manual-case-status-workflow.INPROGRESS'],
          event: 'REVEAL_INTERACTION_STARTED',
          actions: [],
          source: '#manual-case-status-workflow.OPEN',
          internal: false,
          eventType: 'REVEAL_INTERACTION_STARTED',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 1,
      invoke: [],
    },
    INPROGRESS: {
      id: 'manual-case-status-workflow.INPROGRESS',
      key: 'INPROGRESS',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        INTERACTION_COMPLETED_FIELD_CLOSECASE_TRUE: [
          {
            target: ['#manual-case-status-workflow.DONE'],
            event: 'INTERACTION_COMPLETED_FIELD_CLOSECASE_TRUE',
            actions: [],
            source: '#manual-case-status-workflow.INPROGRESS',
            internal: false,
            eventType: 'INTERACTION_COMPLETED_FIELD_CLOSECASE_TRUE',
          },
        ],
      },
      transitions: [
        {
          target: ['#manual-case-status-workflow.DONE'],
          event: 'INTERACTION_COMPLETED_FIELD_CLOSECASE_TRUE',
          actions: [],
          source: '#manual-case-status-workflow.INPROGRESS',
          internal: false,
          eventType: 'INTERACTION_COMPLETED_FIELD_CLOSECASE_TRUE',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 2,
      invoke: [],
    },
    DONE: {
      id: 'manual-case-status-workflow.DONE',
      key: 'DONE',
      type: 'final',
      history: false,
      states: {},
      on: {},
      transitions: [],
      entry: [],
      exit: [],
      activities: [],
      order: 3,
      invoke: [],
    },
  },
  on: {},
  transitions: [],
  entry: [],
  exit: [],
  activities: [],
  order: -1,
  invoke: [],
};

export const caresourceOtc = {
  id: 'case-otc',
  key: 'case-otc',
  context: {},
  type: 'compound',
  initial: 'OPEN',
  history: false,
  states: {
    OPEN: {
      id: 'case-otc.OPEN',
      key: 'OPEN',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        REVEAL_INTERACTION_STARTED: [
          {
            target: ['#case-otc.INPROGRESS'],
            event: 'REVEAL_INTERACTION_STARTED',
            actions: [],
            source: '#case-otc.OPEN',
            internal: false,
            eventType: 'REVEAL_INTERACTION_STARTED',
          },
        ],
      },
      transitions: [
        {
          target: ['#case-otc.INPROGRESS'],
          event: 'REVEAL_INTERACTION_STARTED',
          actions: [],
          source: '#case-otc.OPEN',
          internal: false,
          eventType: 'REVEAL_INTERACTION_STARTED',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 1,
      invoke: [],
    },
    INPROGRESS: {
      id: 'case-otc.INPROGRESS',
      key: 'INPROGRESS',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY: [
          {
            target: ['#case-otc.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
            actions: [],
            source: '#case-otc.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER: [
          {
            target: ['#case-otc.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
            actions: [],
            source: '#case-otc.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE: [
          {
            target: ['#case-otc.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
            actions: [],
            source: '#case-otc.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION:
          [
            {
              target: ['#case-otc.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
              actions: [],
              source: '#case-otc.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
            },
          ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM: [
          {
            target: ['#case-otc.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
            actions: [],
            source: '#case-otc.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES:
          [
            {
              target: ['#case-otc.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
              actions: [],
              source: '#case-otc.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
            },
          ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH: [
          {
            target: ['#case-otc.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
            actions: [],
            source: '#case-otc.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOUNITEDMEDCO:
          [
            {
              target: ['#case-otc.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOUNITEDMEDCO',
              actions: [],
              source: '#case-otc.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOUNITEDMEDCO',
            },
          ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_RSCPLACEDORDER: [
          {
            target: ['#case-otc.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_RSCPLACEDORDER',
            actions: [],
            source: '#case-otc.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_RSCPLACEDORDER',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_PROVIDEDMEMBEREDUCATION:
          [
            {
              target: ['#case-otc.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_PROVIDEDMEMBEREDUCATION',
              actions: [],
              source: '#case-otc.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_PROVIDEDMEMBEREDUCATION',
            },
          ],
      },
      transitions: [
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOUNITEDMEDCO',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOUNITEDMEDCO',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_RSCPLACEDORDER',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_RSCPLACEDORDER',
        },
        {
          target: ['#case-otc.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_PROVIDEDMEMBEREDUCATION',
          actions: [],
          source: '#case-otc.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_PROVIDEDMEMBEREDUCATION',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 2,
      invoke: [],
    },
    DONE: {
      id: 'case-otc.DONE',
      key: 'DONE',
      type: 'final',
      history: false,
      states: {},
      on: {},
      transitions: [],
      entry: [],
      exit: [],
      activities: [],
      order: 3,
      invoke: [],
    },
  },
  on: {
    INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED: [
      {
        target: ['#case-otc.DONE'],
        event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
        actions: [],
        source: '#case-otc',
        internal: false,
        eventType:
          'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      },
    ],
  },
  transitions: [
    {
      target: ['#case-otc.DONE'],
      event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      actions: [],
      source: '#case-otc',
      internal: false,
      eventType: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
    },
  ],
  entry: [],
  exit: [],
  activities: [],
  order: -1,
  invoke: [],
};

export const caresource40rejects = {
  id: 'case-40-rejects',
  key: 'case-40-rejects',
  context: {},
  type: 'compound',
  initial: 'OPEN',
  history: false,
  states: {
    OPEN: {
      id: 'case-40-rejects.OPEN',
      key: 'OPEN',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        REVEAL_INTERACTION_STARTED: [
          {
            target: ['#case-40-rejects.INPROGRESS'],
            event: 'REVEAL_INTERACTION_STARTED',
            actions: [],
            source: '#case-40-rejects.OPEN',
            internal: false,
            eventType: 'REVEAL_INTERACTION_STARTED',
          },
        ],
      },
      transitions: [
        {
          target: ['#case-40-rejects.INPROGRESS'],
          event: 'REVEAL_INTERACTION_STARTED',
          actions: [],
          source: '#case-40-rejects.OPEN',
          internal: false,
          eventType: 'REVEAL_INTERACTION_STARTED',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 1,
      invoke: [],
    },
    INPROGRESS: {
      id: 'case-40-rejects.INPROGRESS',
      key: 'INPROGRESS',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY: [
          {
            target: ['#case-40-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
            actions: [],
            source: '#case-40-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER: [
          {
            target: ['#case-40-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
            actions: [],
            source: '#case-40-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE: [
          {
            target: ['#case-40-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
            actions: [],
            source: '#case-40-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION:
          [
            {
              target: ['#case-40-rejects.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
              actions: [],
              source: '#case-40-rejects.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
            },
          ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM: [
          {
            target: ['#case-40-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
            actions: [],
            source: '#case-40-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES:
          [
            {
              target: ['#case-40-rejects.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
              actions: [],
              source: '#case-40-rejects.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
            },
          ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH: [
          {
            target: ['#case-40-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
            actions: [],
            source: '#case-40-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_INITIATEDPRESCRIPTIONTRANSFER:
          [
            {
              target: ['#case-40-rejects.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_INITIATEDPRESCRIPTIONTRANSFER',
              actions: [],
              source: '#case-40-rejects.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_INITIATEDPRESCRIPTIONTRANSFER',
            },
          ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_COMPLETEDRECOMMENDATION:
          [
            {
              target: ['#case-40-rejects.DONE'],
              event:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_COMPLETEDRECOMMENDATION',
              actions: [],
              source: '#case-40-rejects.INPROGRESS',
              internal: false,
              eventType:
                'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_COMPLETEDRECOMMENDATION',
            },
          ],
      },
      transitions: [
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPHARMACY',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONTACTEDPRESCRIBER',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_FILEDAGRIEVANCE',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_MEMBERTOTAKEOWNACTION',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_REFERREDTOCM',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTOMEMBERSERVICES',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_TRANSFERREDTORPH',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_INITIATEDPRESCRIPTIONTRANSFER',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_INITIATEDPRESCRIPTIONTRANSFER',
        },
        {
          target: ['#case-40-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_COMPLETEDRECOMMENDATION',
          actions: [],
          source: '#case-40-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_COMPLETEDRECOMMENDATION',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 2,
      invoke: [],
    },
    DONE: {
      id: 'case-40-rejects.DONE',
      key: 'DONE',
      type: 'final',
      history: false,
      states: {},
      on: {},
      transitions: [],
      entry: [],
      exit: [],
      activities: [],
      order: 3,
      invoke: [],
    },
  },
  on: {
    INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED: [
      {
        target: ['#case-40-rejects.DONE'],
        event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
        actions: [],
        source: '#case-40-rejects',
        internal: false,
        eventType:
          'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      },
    ],
  },
  transitions: [
    {
      target: ['#case-40-rejects.DONE'],
      event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      actions: [],
      source: '#case-40-rejects',
      internal: false,
      eventType: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
    },
  ],
  entry: [],
  exit: [],
  activities: [],
  order: -1,
  invoke: [],
};

export const caresource891rejects = {
  id: 'case-891-rejects',
  key: 'case-891-rejects',
  context: {},
  type: 'compound',
  initial: 'OPEN',
  history: false,
  states: {
    OPEN: {
      id: 'case-891-rejects.OPEN',
      key: 'OPEN',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        REVEAL_INTERACTION_STARTED: [
          {
            target: ['#case-891-rejects.INPROGRESS'],
            event: 'REVEAL_INTERACTION_STARTED',
            actions: [],
            source: '#case-891-rejects.OPEN',
            internal: false,
            eventType: 'REVEAL_INTERACTION_STARTED',
          },
        ],
      },
      transitions: [
        {
          target: ['#case-891-rejects.INPROGRESS'],
          event: 'REVEAL_INTERACTION_STARTED',
          actions: [],
          source: '#case-891-rejects.OPEN',
          internal: false,
          eventType: 'REVEAL_INTERACTION_STARTED',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 1,
      invoke: [],
    },
    INPROGRESS: {
      id: 'case-891-rejects.INPROGRESS',
      key: 'INPROGRESS',
      type: 'atomic',
      history: false,
      states: {},
      on: {
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_OVERRIDDENFOR30DAYS: [
          {
            target: ['#case-891-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_OVERRIDDENFOR30DAYS',
            actions: [],
            source: '#case-891-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_OVERRIDDENFOR30DAYS',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONVERTEDTO90DAYS: [
          {
            target: ['#case-891-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONVERTEDTO90DAYS',
            actions: [],
            source: '#case-891-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONVERTEDTO90DAYS',
          },
        ],
        INTERACTION_COMPLETED_FIELD_RECOMMENDATION_ALREADY_FILLED: [
          {
            target: ['#case-891-rejects.DONE'],
            event: 'INTERACTION_COMPLETED_FIELD_RECOMMENDATION_ALREADY_FILLED',
            actions: [],
            source: '#case-891-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_RECOMMENDATION_ALREADY_FILLED',
          },
        ],
        INTERACTION_COMPLETED_FIELD_CONSULTATIONRESULT_NOOUTREACHNEEDED: [
          {
            target: ['#case-891-rejects.DONE'],
            event:
              'INTERACTION_COMPLETED_FIELD_CONSULTATIONRESULT_NOOUTREACHNEEDED',
            actions: [],
            source: '#case-891-rejects.INPROGRESS',
            internal: false,
            eventType:
              'INTERACTION_COMPLETED_FIELD_CONSULTATIONRESULT_NOOUTREACHNEEDED',
          },
        ],
      },
      transitions: [
        {
          target: ['#case-891-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_OVERRIDDENFOR30DAYS',
          actions: [],
          source: '#case-891-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_OVERRIDDENFOR30DAYS',
        },
        {
          target: ['#case-891-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONVERTEDTO90DAYS',
          actions: [],
          source: '#case-891-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATIONACTION_CONVERTEDTO90DAYS',
        },
        {
          target: ['#case-891-rejects.DONE'],
          event: 'INTERACTION_COMPLETED_FIELD_RECOMMENDATION_ALREADY_FILLED',
          actions: [],
          source: '#case-891-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_RECOMMENDATION_ALREADY_FILLED',
        },
        {
          target: ['#case-891-rejects.DONE'],
          event:
            'INTERACTION_COMPLETED_FIELD_CONSULTATIONRESULT_NOOUTREACHNEEDED',
          actions: [],
          source: '#case-891-rejects.INPROGRESS',
          internal: false,
          eventType:
            'INTERACTION_COMPLETED_FIELD_CONSULTATIONRESULT_NOOUTREACHNEEDED',
        },
      ],
      entry: [],
      exit: [],
      activities: [],
      order: 2,
      invoke: [],
    },
    DONE: {
      id: 'case-891-rejects.DONE',
      key: 'DONE',
      type: 'final',
      history: false,
      states: {},
      on: {},
      transitions: [],
      entry: [],
      exit: [],
      activities: [],
      order: 3,
      invoke: [],
    },
  },
  on: {
    INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED: [
      {
        target: ['#case-891-rejects.DONE'],
        event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
        actions: [],
        source: '#case-891-rejects',
        internal: false,
        eventType:
          'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      },
    ],
  },
  transitions: [
    {
      target: ['#case-891-rejects.DONE'],
      event: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
      actions: [],
      source: '#case-891-rejects',
      internal: false,
      eventType: 'INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED',
    },
  ],
  entry: [],
  exit: [],
  activities: [],
  order: -1,
  invoke: [],
};
