import graphql from '../common/utilities/graphql';

export const upsertCommentMutation = graphql`
  mutation (
    $workItemId: String = null
    $commentId: String = null
    $message: String!
  ) {
    upsertComment(
      workItemId: $workItemId
      commentId: $commentId
      message: $message
    ) {
      id
      workItemId
      message
      dateCreated
      createdBy {
        id
        email
        name
        pictureUrl
      }
    }
  }
`;
