import { Set } from 'immutable';

export const initialState = {
  id: null,
  name: '',
  isPublic: false,
  isSubmitted: false,
  isSaving: false,
  isNew: true,
  tags: new Set(),
};
