import { Set } from 'immutable';
import { IFilterConfiguration } from 'app/filters/types';
import age from './age';
import ageRange from './ageRange';
import diseaseStates from './diseaseStates';
import drugLists from './drugLists';
import excludeDiseaseStates from './excludeDiseaseStates';
import excludeDrugHistory from './excludeDrugHistory';
import greaterThanFillsFromDate from './greaterThanFillsFromDate';
import hasCancer from './hasCancer';
import isPatientEligible from './isEligible';
import isEligibleForCarriers from './isEligibleForCarriers';
import isNotEligibleForCarriers from './isNotEligibleForCarriers';
import lateToFill from './lateToFill';
import lessThanFillsFromDate from './lessThanFillsFromDate';
import newToTherapies from './newToTherapies';
import patientId from './patientId';
import vaccineDosingSchedule from './vaccineDosingSchedule';

export default Set<IFilterConfiguration<any, any, any, any>>([
  age,
  diseaseStates,
  drugLists,
  excludeDiseaseStates,
  excludeDrugHistory,
  hasCancer,
  isPatientEligible,
  isEligibleForCarriers,
  isNotEligibleForCarriers,
  lateToFill,
  newToTherapies,
  vaccineDosingSchedule,
  patientId,
  greaterThanFillsFromDate,
  lessThanFillsFromDate,
  ageRange,
]);
