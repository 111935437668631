import multimatch from 'multimatch';
import { isNil, toPairs } from 'ramda';
import { KeyValueRecord } from 'app/filters/types';
import {
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAIL,
  USER_SIGN_OUT,
} from '../../auth/constants';
import getConfig from '../../configuration';
import {
  blacklistActions,
  getLogProperties,
  printLogProperties,
} from './config';
import { LogEventRecord } from './types';

const config = getConfig();

function handleLoginEvents(name, properties) {
  if (name === USER_SIGN_IN_SUCCESS) {
    const userName = properties.email;
    if (config.LOG_TO_CONSOLE)
      console.log('Setting Authenticated User Context: ' + userName);
  } else if (name === USER_SIGN_OUT || name === USER_SIGN_IN_FAIL) {
    if (config.LOG_TO_CONSOLE)
      console.log('Clearing Authenticated User Context');
  }
}

export const loggingMiddleware = (channel) => (store) => (next) => (action) => {
  const result = next(action);
  const currentState = store.getState();

  if (multimatch([action.type], blacklistActions).length < 1) {
    const properties = getLogProperties(currentState, action);
    log(action.type, properties, channel);
  }
  return result;
};

export const log = (name, properties, channel) => {
  handleLoginEvents(name, properties);

  if (config.LOG_TO_CONSOLE) console.log({ name, properties });

  const logMessage = new LogEventRecord({
    eventName: name,
    timeStamp: new Date().toISOString(),
    contextId: config.CONTEXT_ID,
    properties: toPairs(properties).map(
      (p) =>
        new KeyValueRecord({
          key: p[0],
          value: JSON.stringify(p[1]),
        }),
    ),
  });

  if (!isNil(channel)) {
    channel.put(logMessage);
  }
};

export const setupPrintLogging = (store) => {
  window.onafterprint = (event) => {
    const state = store.getState();

    const properties = printLogProperties(
      state,
      event.currentTarget.location.pathname,
    );
    log('PRINT REQUEST', properties);
  };
};
