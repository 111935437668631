export const VIEWTEMPLATES_FETCH_START =
  'VIEWTEMPLATES/VIEWTEMPLATES_FETCH_START';
export const VIEWTEMPLATES_FETCH_SUCCESS =
  'VIEWTEMPLATES/VIEWTEMPLATES_FETCH_SUCCESS';
export const VIEWTEMPLATES_FETCH_FAIL =
  'VIEWTEMPLATES/VIEWTEMPLATES_FETCH_FAIL';

export const VIEWTEMPLATE_APPLY = 'VIEWTEMPLATES/VIEWTEMPLATE_APPLY';

export const VIEWTEMPLATE_SAVE_START = 'VIEWTEMPLATES/VIEWTEMPLATE_SAVE_START';
export const VIEWTEMPLATE_SAVE_SUCCESS =
  'VIEWTEMPLATES/VIEWTEMPLATE_SAVE_SUCCESS';
export const VIEWTEMPLATE_SAVE_FAIL = 'VIEWTEMPLATES/VIEWTEMPLATE_SAVE_FAIL';

export const VIEWTEMPLATE_CREATE_START =
  'VIEWTEMPLATES/VIEWTEMPLATE_CREATE_START';
export const VIEWTEMPLATE_CREATE_SUCCESS =
  'VIEWTEMPLATES/VIEWTEMPLATE_CREATE_SUCCESS';
export const VIEWTEMPLATE_CREATE_FAIL =
  'VIEWTEMPLATES/VIEWTEMPLATE_CREATE_FAIL';

export const VIEWTEMPLATE_QUICKSAVE_START =
  'VIEWTEMPLATES/VIEWTEMPLATE_QUICKSAVE_START';
export const VIEWTEMPLATE_QUICKSAVE_SUCCESS =
  'VIEWTEMPLATES/VIEWTEMPLATE_QUICKSAVE_SUCCESS';
export const VIEWTEMPLATE_QUICKSAVE_FAIL =
  'VIEWTEMPLATES/VIEWTEMPLATE_QUICKSAVE_FAIL';

export const VIEWTEMPLATES_FETCH_COUNT_START =
  'VIEWTEMPLATES/VIEWTEMPLATES_COUNT_FETCH_START';
export const VIEWTEMPLATE_FETCH_COUNT_SUCCESS =
  'VIEWTEMPLATES/VIEWTEMPLATE_COUNT_FETCH_SUCCESS';
export const VIEWTEMPLATE_FETCH_COUNT_FAIL =
  'VIEWTEMPLATES/VIEWTEMPLATE_COUNT_FETCH_FAIL';

export const VIEWTEMPLATE_DELETE = 'VIEWTEMPLATES/VIEWTEMPLATE_DELETE';
export const VIEWTEMPLATE_DELETE_SUCCESS =
  'VIEWTEMPLATES/VIEWTEMPLATE_DELETE_SUCCESS';
export const VIEWTEMPLATE_DELETE_FAIL =
  'VIEWTEMPLATES/VIEWTEMPLATE_DELETE_FAIL';
export const VIEWTEMPLATE_DELETE_CANCEL =
  'VIEWTEMPLATES/VIEWTEMPLATE_DELETE_CANCEL';

export enum StoredFilterType {
  DateRange = 'DATE_RANGE',
  MultiselectString = 'MULTISELECT_STRING',
  Boolean = 'BOOLEAN',
  MultiselectKeyvalue = 'MULTISELECT_KEYVALUE',
  MultiselectMatch = 'MULTISELECT_MATCH',
  MatchesPaidClaim = 'MATCHES_PAID_CLAIM',
  NumericComparison = 'NUMERIC_COMPARISON',
  NumericRange = 'NUMERIC_RANGE',
  TextComparison = 'TEXT_COMPARISON',
  String = 'STRING',
  InitialOpioidSupply = 'INITIAL_OPIOID_SUPPLY',
  LateToFill = `LATE_TO_FILL`,
  DosingSchedule = 'DOSING_SCHEDULE',
  GreaterThanFillsFromDate = 'GREATER_FILLS_FROM_DATE',
  LessThanFillsFromDate = 'LESS_FILLS_FROM_DATE',
  AgeRange = 'AGE_RANGE',
}
