import { isDate, parseISO } from 'date-fns';
import { Map } from 'immutable';
import { WORKITEM_DETAILS_FETCH_SUCCESS } from '../../workitems/constants';
import {
  COMMENT_ADD_SUCCESS,
  COMMENT_EDIT_SUCCESS,
  COMMENT_DELETE_SUCCESS,
} from '../constants';
import { CommentRecord } from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case WORKITEM_DETAILS_FETCH_SUCCESS:
    case COMMENT_ADD_SUCCESS:
    case COMMENT_EDIT_SUCCESS: {
      const comments = new Map(action.payload.data.entities.comments);

      return state.merge(
        comments.map((comment) => {
          const existingComment =
            state.get(comment.id)?.merge(comment) ?? new CommentRecord(comment);

          return existingComment.merge({
            dateCreated:
              comment.dateCreated !== undefined
                ? parseISO(comment.dateCreated)
                : existingComment.dateCreated,
          });
        }),
      );
    }
    case COMMENT_DELETE_SUCCESS: {
      return state.delete(action.payload.id);
    }
    default:
      return state;
  }
};

export default reducer;
