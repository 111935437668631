import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from './MultiSelectDropDown.css';

const MultiSelectDropDown = ({
  label,
  id,
  options,
  value,
  onChange,
  optionRenderer,
  disabled,
  variant,
  fullWidth,
  formClassName,
}) => (
  <FormControl
    variant={variant}
    fullWidth={fullWidth}
    className={formClassName}
  >
    <InputLabel id={id}>{label}</InputLabel>
    <Select
      multiple
      disabled={disabled}
      labelId={id}
      label={label}
      value={value.toArray()}
      onChange={(event) => onChange(id, new Set(event.target.value))}
      renderValue={(v) => {
        return options
          .filter((x) => v.includes(x.value))
          .map((x) => x.text)
          .join(', ');
      }}
      MenuProps={{ variant: 'menu' }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          classes={{ gutters: styles.dropdownGutter }}
        >
          <Checkbox checked={value.includes(option.value)} color="primary" />
          {optionRenderer(option)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

MultiSelectDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  value: ImmutablePropTypes.setOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  optionRenderer: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  fullWidth: PropTypes.bool,
  formClassName: PropTypes.string,
};

MultiSelectDropDown.defaultProps = {
  value: new Set(),
  optionRenderer: ({ text }) => <ListItemText primary={text} />,
  disabled: false,
  variant: 'outlined',
  fullWidth: false,
  formClassName: '',
  options: Set(),
};

export default MultiSelectDropDown;
