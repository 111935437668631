import { Record, Map } from 'immutable';
import { schema } from 'normalizr';
import { GraphqlTypes } from 'app/common/types';

export class PrescriberRecord extends Record({
  id: null as string | null,
  entityId: undefined as string | undefined,
  gender: null as string | null,
  groupPracticeId: null as string | null,
  name: null as string | null,
  npi: null as string | null,
  organizationName: null as string | null,
  primarySpecialty: null as string | null,
  phoneNumber: null as string | null,
  __typename: GraphqlTypes.Prescriber,
}) {}

export const PrescribersStateRecord = Record({
  prescribers: Map(),
});

export const prescriberSchema = new schema.Entity('prescribers');
