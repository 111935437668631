import { Record, Map, OrderedSet, Set } from 'immutable';
import { schema } from 'normalizr';
import { pharmacySchema } from 'app/pharmacies/types';
import { ClaimRecord } from '../claims/types';
import { comment as commentSchema } from '../comments/types';
import { SortingRecord } from '../common/types';
import { patientSchema } from '../patient/types';
import { prescriberSchema } from '../prescribers/types';

export class WorkItemStatusRecord extends Record({
  id: '',
  name: '',
  type: '',
  isDeprecated: false,
}) {}

export class WorkItemRecord extends Record({
  id: '',
  claim: new ClaimRecord(),
  comments: Set(),
  status: undefined as WorkItemStatusRecord | undefined,
  dateCreated: undefined as Date | undefined,
  assignee: null as UserTypeRecord | null,
}) {}

export class UserTypeRecord extends Record({
  id: '',
  email: '',
  name: '',
  pictureUrl: undefined as string | undefined,
}) {}

export const OptimisticRecord = Record({
  workItems: Map(),
});

export const WorkItemsStateRecord = Record({
  optimistic: new OptimisticRecord(),
  queryResults: OrderedSet(),
  sorting: new SortingRecord(),
  workItems: Map(),
  workItemStatuses: Map(),
});

const user = new schema.Entity('users');
const workItemStatus = new schema.Entity('workItemStatuses');

export const workItem = new schema.Entity('workItems', {
  assignee: user,
  comments: new schema.Array(commentSchema),
  status: workItemStatus,
});

const nestedWorkItem = new schema.Entity(
  'workItems',
  {
    assignee: user,
    comments: new schema.Array(commentSchema),
    status: workItemStatus,
  },
  {
    processStrategy: (value: any, parent: any) => ({
      ...value,
      claim: parent.id,
    }),
  },
);

export const claim = new schema.Entity('claims', {
  workItem: nestedWorkItem,
  prescriber: prescriberSchema,
  pharmacy: pharmacySchema,
  historicalOpioidPrescribers: new schema.Array(prescriberSchema),
  patient: patientSchema,
});

export const continuationToken = new schema.Entity('continuationToken');

export const workitemQuerySchema = {
  claims: new schema.Array(claim),
  continuationToken,
};
