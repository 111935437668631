import { parseISO } from 'date-fns';
import { Map, Set } from 'immutable';
import { SESSION_UPSERT_SUCCESS } from 'app/session/constants';
import { INTERACTION_SAVE_SUCCESS } from '../../interactions/constants';
import { PATIENT_FETCH_SUCCESS } from '../../patient/constants';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../../queue/constants';
import { SEARCH_SUCCESS } from '../../search/constants';
import { CASES_QUERY_FETCH_SUCCESS, CASE_FETCH_SUCCESS } from '../constants';
import { CaseRecord } from '../types';

function findAndMerge(state, c) {
  const initialRecord = state.get(c.id) || new CaseRecord();

  return initialRecord.merge({
    ...c,
    issues: c.issues !== undefined ? new Set(c.issues) : initialRecord.issues,
    interactions:
      c.interactions !== undefined
        ? new Set(c.interactions)
        : initialRecord.interactions,
    caseSessions:
      c.caseSessions === undefined
        ? initialRecord.caseSessions
        : new Set(c.caseSessions),
    target: c.target?.id !== undefined ? c.target.id : initialRecord.target,
    contact: c.contact?.id !== undefined ? c.contact.id : initialRecord.contact,
    dateOpened:
      c.dateOpened !== undefined
        ? parseISO(c.dateOpened)
        : initialRecord.dateOpened,
    dateClosed:
      c.dateClosed !== undefined
        ? parseISO(c.dateClosed)
        : initialRecord.dateClosed,
    followUpDate:
      c.followUpDate !== undefined && c.followUpDate !== null
        ? parseISO(c.followUpDate)
        : c.followUpDate,
  });
}

function reducer(state = new Map(), action) {
  switch (action.type) {
    case INTERACTION_SAVE_SUCCESS:
    case QUEUE_SKIP_SESSION_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case PATIENT_FETCH_SUCCESS:
    case SEARCH_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case SESSION_UPSERT_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS: {
      const cases = new Map(action.payload.data.entities.cases);

      return state.merge(cases.map((x) => findAndMerge(state, x)));
    }
    default:
      return state;
  }
}

export default reducer;
