import { Router, Redirect } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import UploadCases from 'app/cases/components/UploadCases';
import CreateIssue from 'app/issues/components/CreateIssue';
import Callback from '../../../auth/components/Callback';
import Login from '../../../auth/components/Login';
import BestPracticeCategory from '../../../bestpractices/containers/BestPracticeCategory';
import BestPracticeOverview from '../../../bestpractices/containers/BestPracticeOverview';
import BusinessProcessViewer from '../../../campaigns/components/BusinessProcessViewer';
import CampaignDashboard from '../../../campaigns/containers/CampaignDashboard/CampaignDashboard';
import CampaignEdit from '../../../campaigns/containers/CampaignEdit';
import CaseList from '../../../cases/components/CaseList';
import CaseOverview from '../../../cases/components/CaseOverview/CaseOverview';
import IssueList from '../../../issues/components/IssueList';
import PatientProfile from '../../../patient/containers/PatientProfile';
import Outreach from '../../../queue/containers/Outreach/Outreach';
import WorkItemDetailView from '../../../workitems/containers/WorkItemDetailView';
import WorkItemsListView from '../../../workitems/containers/WorkItemsListView';
import Inactive from '../../components/Inactive';
import NotFound from '../../components/NotFound';
import Unauthorized from '../../components/Unauthorized';
import UnexpectedError from '../../components/UnexpectedError';
import AuthenticatedLayout from '../AuthenticatedLayout';
import Global from '../Global';
import GraphiQL from '../GraphiQL';

const App = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Global default>
        <Login path="login" />
        <Callback path="callback" />
        <Unauthorized path="unauthorized" />
        <UnexpectedError path="unexpected-error" />
        <Inactive path="inactive" />
        <AuthenticatedLayout default>
          <Redirect from="/" to="workitems" noThrow />
          <WorkItemsListView path="workitems" />
          <WorkItemDetailView path="workitems/:id/*" />
          <PatientProfile path="patients/:id/*" />
          <BestPracticeOverview path="best-practices" />
          <BestPracticeCategory path="best-practices/:categoryId" />
          <CampaignDashboard path="campaigns" />
          <BusinessProcessViewer path="business" />
          <CampaignEdit path="campaigns/:id" />
          <GraphiQL path="graphiql" />
          <Outreach path="queue/*" />
          <NotFound default />
          <UploadCases path="uploadcases" />
          <CaseOverview path="cases/:id/*" />
          <CaseList path="cases" />
          <IssueList path="issues" />
          <CreateIssue path="issues/create" />
        </AuthenticatedLayout>
      </Global>
    </Router>
  </Provider>
);

App.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

export default App;
