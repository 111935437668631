import { SortDirection } from '@material-ui/core';
import { IssuesFilterStateRecord, IssueUpsertRecord } from 'app/issues/types';
import {
  ISSUES_TARGET_SEARCH_FETCH_FAIL,
  ISSUES_TARGET_SEARCH_FETCH_START,
  ISSUES_TARGET_SEARCH_FETCH_SUCCESS,
  ISSUES_FILTER_UPDATE,
  ISSUES_QUERY_CLEAR,
  ISSUES_QUERY_FETCH_FAIL,
  ISSUES_QUERY_FETCH_START,
  ISSUES_QUERY_FETCH_SUCCESS,
  ISSUES_SORT_APPLY,
  ISSUE_UPSERT,
  ISSUE_UPSERT_SUCCESS,
  ISSUE_UPSERT_FAIL,
} from './constants';

export function fetchIssuesQuery(
  filter: IssuesFilterStateRecord,
  sortBy: string,
  sortDirection: SortDirection,
  continuationToken: string,
) {
  return {
    type: ISSUES_QUERY_FETCH_START,
    payload: { filter, sortBy, sortDirection, continuationToken },
  };
}

export function fetchIssuesQuerySuccess(
  data: any,
  continuationToken: string,
  isContinuation: boolean,
) {
  return {
    type: ISSUES_QUERY_FETCH_SUCCESS,
    payload: { data, continuationToken, isContinuation },
  };
}

export function fetchIssuesQueryFail(error: any) {
  return { type: ISSUES_QUERY_FETCH_FAIL, payload: { error } };
}

export function updateIssueFilter(filters: IssuesFilterStateRecord) {
  return { type: ISSUES_FILTER_UPDATE, payload: { filters } };
}

export function applyIssuesSort(sortBy: string, sortDirection: SortDirection) {
  return { type: ISSUES_SORT_APPLY, payload: { sortBy, sortDirection } };
}

export function clearIssuesQuery() {
  return { type: ISSUES_QUERY_CLEAR, payload: {} };
}

export function fetchTargets(value: string) {
  return { type: ISSUES_TARGET_SEARCH_FETCH_START, payload: { value } };
}

export function fetchTargetsSuccess(data: any) {
  return { type: ISSUES_TARGET_SEARCH_FETCH_SUCCESS, payload: { data } };
}

export function fetchTargetsFail(error: any) {
  return { type: ISSUES_TARGET_SEARCH_FETCH_FAIL, payload: { error } };
}

export function upsertIssue(optimisticId: string, issue: IssueUpsertRecord) {
  return {
    type: ISSUE_UPSERT,
    payload: { optimisticId, issue },
  };
}

export function upsertIssueSuccess(
  optimisticId: string,
  id: string,
  data: any,
) {
  return {
    type: ISSUE_UPSERT_SUCCESS,
    payload: { optimisticId, id, data },
  };
}

export function upsertIssueFail(optimisticId: string, error: any) {
  return {
    type: ISSUE_UPSERT_FAIL,
    payload: { optimisticId, error },
  };
}
