import getConfig from '../configuration';

const { API_BASE_URL } = getConfig();
export const UNAUTHORIZED_URL = '/unauthorized';
export const UNEXPECTED_ERROR_URL = '/unexpected-error';
export const AUTH_REDIRECT_CALLBACK_URL = '/callback';
export const AUTH_REDIRECT_CALLBACK_SILENT_URL = '/callback-silent';
export const INACTIVE_URL = '/inactive';
export const GRAPHQL_URL = `${API_BASE_URL}/graphql`;
export const EXPORT_URL = `${API_BASE_URL}/export`;

export const MAX_INT_32 = 2_147_483_647;

export const USER_INACTIVE_TIMEOUT = 20 * 60 * 1000;
export const INACTIVE_PROMPT_TIME = 90 * 1000;

export enum GpiLevel {
  Six = 'SubClass',
  Ten = 'NameExtension',
  Twelve = 'Dosage',
  Fourteen = 'Strength',
}

export enum HistoricalSourceEnum {
  Claim = 'Claim',
  User = 'User',
  Eligibility = 'Eligibility',
  ReferenceData = 'ReferenceData',
}

export const UNHANDLED_ERROR = 'COMMON/UNHANDLED_ERROR';
