import graphql from '../common/utilities/graphql';

const campaignFragment = graphql`
  fragment user on UserType {
    id
    name
    email
    pictureUrl
    permissions
  }

  fragment campaignFields on CampaignType {
    id
    entityId
    etag
    name
    prefix
    description
    startDate
    endDate
    duration
    owner {
      ...user
    }
    contact
    version
    target
    contactMethod
    requiredSpecialists
    assignees {
      ...user
    }
    status
    runsIndefinitely
    interactionFieldDefinitions: interactionFields {
      name
      type
      options
    }
    systemManagedCases
    includeCasesInQueue
    caseWorkflowDefinitionId
    issueFilter {
      issueFilterType
      filters {
        name
        valueType
        ... on BooleanFilterType {
          value
        }
        ... on MultiSelectStringFilterType {
          value
        }
        ... on DateRangeFilterType {
          value {
            start
            end
          }
        }
        ... on MultiSelectKeyValueType {
          value {
            key
            value
          }
        }
        ... on MatchesPaidClaimFilterType {
          value {
            hasPaidClaim
            gpiLevel
            window
          }
        }
        ... on MultiSelectMatchFilterType {
          value {
            match
            values {
              key
              value
            }
          }
        }
        ... on NumericComparisonFilterType {
          value {
            number
            operator
          }
        }
        ... on TextComparisonFilterType {
          value {
            text
            operator
          }
        }
        ... on SingleTextValueFilterType {
          value
        }
        ... on InitialOpioidSupplyFilterType {
          value {
            numericComparison {
              number
              operator
            }
            window
          }
        }
        ... on NumericRangeFilterType {
          value {
            minimum {
              value
              type
            }
            maximum {
              value
              type
            }
          }
        }
        ... on LateToFillFilterType {
          value {
            gpis {
              key
              value
            }
            therapies {
              key
              value
            }
            matchType
            window {
              start
              end
            }
            excludeIssuesBeforeCampaignStartDate
          }
        }
        ... on DosingScheduleFilterType {
          value {
            dosingSchedule {
              dosingInterval
              gpiPrefixes {
                key
                value
              }
              maxDoses
            }
            exceedsSchedule
          }
        }
        ... on GreaterThanFillsFromDateFilterType {
          value {
            fromDate
            therapies {
              match
              values {
                key
                value
              }
            }
            numberOfFills {
              number
              operator
            }
          }
        }
        ... on LessThanFillsFromDateFilterType {
          value {
            fromDate
            therapies {
              match
              values {
                key
                value
              }
            }
            numberOfFills {
              number
              operator
            }
          }
        }
        ... on AgeRangeFilterType {
          value {
            fromDate
            minAge
            maxAge
          }
        }
      }
    }
  }
`;

export const upsertCampaignMutation = graphql`
  ${campaignFragment}
  mutation ($campaign: CampaignInputType!) {
    upsertCampaign(campaign: $campaign) {
      ...campaignFields
    }
  }
`;

export const cloneCampaignMutation = graphql`
  ${campaignFragment}
  mutation ($cloneCampaign: CloneCampaignInputType!) {
    cloneCampaign(cloneCampaign: $cloneCampaign) {
      ...campaignFields
    }
  }
`;

export const fetchCampaignsQuery = graphql`
  ${campaignFragment}
  query {
    campaigns {
      ...campaignFields
    }
  }
`;

export const fetchCampaignDetailsQuery = graphql`
  ${campaignFragment}
  query campaign($id: ID!) {
    node(id: $id) {
      ... on CampaignType {
        ...campaignFields
      }
    }
  }
`;

export const fetchTherapiesQuery = graphql`
  query therapies {
    therapies {
      entityId
      name
    }
  }
`;
