import { normalize } from 'normalizr';
import { filter, map, pipe } from 'ramda';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from '../common/sagas';
import throttle from '../common/utilities/sagas';
import {
  fetchInteractionFieldDefinitionsFail,
  fetchInteractionFieldDefinitionsSuccess,
  saveInteractionFail,
  saveInteractionSuccess,
} from './actions';
import {
  fetchInteractionFieldDefinitionsAsync,
  saveInteractionAsync,
} from './api';
import {
  INTERACTION_LEVEL_RESPONSE,
  INTERACTION_SAVE,
  CASE_LEVEL_RESPONSE,
  INTERACTION_FIELD_DEFINTION_FETCH_START,
} from './constants';
import { interactionSchema } from './types';

export function* saveInteractionWorker(action) {
  const { optimisticId, interaction } = action.payload;
  const interactionLevelResponses = pipe(
    filter((x) => x.level === INTERACTION_LEVEL_RESPONSE),
    map((x) => ({
      name: x.name,
      value: x.value,
    })),
  )(interaction.responses);
  const caseLevelResponses = pipe(
    filter((x) => x.level === CASE_LEVEL_RESPONSE),
    map((x) => ({
      name: x.name,
      value: x.value,
      caseId: x.caseId,
    })),
  )(interaction.responses);

  yield call(
    genericGraphQlWorker,
    (token) =>
      call(
        saveInteractionAsync,
        {
          id: interaction.id,
          cases: interaction.cases,
          caseSession: interaction.caseSession,
          // TODO this is iffy, we are assuming the shape will be the same
          // between all contact types here, can we just send up the id via the action
          contact: interaction.contact,
          responses: caseLevelResponses,
          interactionLevelResponses,
          dateContacted: interaction.dateContacted,
          status: interaction.status,
        },
        token,
      ),
    (response) =>
      put(
        saveInteractionSuccess(
          optimisticId,
          response.interaction.id,
          normalize(response.interaction, interactionSchema),
        ),
      ),
    (error) => saveInteractionFail(optimisticId, interaction.id, error),
  );
}

export function* saveInteractionWatcher() {
  yield fork(throttle, 1000, INTERACTION_SAVE, saveInteractionWorker);
}

export function* fetchInteractionFieldDefinitionsWorker(action) {
  const { contact, activeCase } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) =>
      call(fetchInteractionFieldDefinitionsAsync, contact, activeCase, token),
    (response) =>
      put(
        fetchInteractionFieldDefinitionsSuccess(
          contact,
          response.fieldDefinitions,
        ),
      ),
    (error) => fetchInteractionFieldDefinitionsFail(error),
  );
}

export function* fetchInteractionFieldDefinitionsWatcher() {
  yield takeLatest(
    INTERACTION_FIELD_DEFINTION_FETCH_START,
    fetchInteractionFieldDefinitionsWorker,
  );
}
