import { put, call, race, take } from 'redux-saga/effects';
import { USER_SIGN_IN_SUCCESS } from '../auth/constants';
import { hydrate as hydrateAction, hydrateFail } from './actions';
import { HYDRATING_AUTH_DONE } from './constants';
import { loadPersistedStateAsync, purgeAsync } from './persist';

export function* hydrate() {
  const { signedIn } = yield race({
    signedIn: take(USER_SIGN_IN_SUCCESS),
    authHydrateDone: take(HYDRATING_AUTH_DONE),
  });

  if (signedIn) {
    const prefix = signedIn.payload.userId;

    try {
      const state = yield call(loadPersistedStateAsync, prefix);
      yield put(hydrateAction(state));
    } catch (e) {
      yield put(hydrateFail(e));
      yield call(purgeAsync, prefix);
    }
  } else {
    yield put(hydrateAction());
  }
}
