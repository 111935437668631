import { normalize, schema } from 'normalizr';
import { isNil } from 'ramda';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from 'app/common/sagas';
import { upsertPatientMutation } from 'app/patient/queries';
import { getAccessToken } from '../user/selectors';
import { claim } from '../workitems/types';
import {
  fetchPatientSuccess,
  fetchPatientFail,
  fetchPatientDrugOverviewSuccess,
  fetchPatientDrugOverviewFail,
  fetchPatientClaimViewSuccess,
  fetchPatientClaimViewFail,
  fetchPatientOpioidViewSuccess,
  fetchPatientOpioidViewFail,
  upsertPatientSuccess,
  upsertPatientFail,
} from './actions';
import {
  fetchPatient,
  fetchPatientDrugOverview,
  fetchPatientClaimView,
  fetchPatientOpioidView,
  upsertPatient,
} from './api';
import {
  PATIENT_FETCH_START,
  DRUG_OVERVIEW_FETCH_START,
  CLAIM_VIEWER_FETCH_START,
  OPIOID_DETAIL_FETCH_START,
  PATIENT_UPSERT_START,
} from './constants';
import { patientSchema } from './types';

export function* genericFetchWorker(action, fetcher, onSuccess, onFail) {
  try {
    const token = yield select(getAccessToken);
    const response = yield fetcher(action, token);

    if (isNil(response.errors)) {
      yield put(onSuccess(action, response));
    } else {
      yield put(onFail(action, response.errors, new Date()));
    }
  } catch (e) {
    yield put(onFail(action, e.toString(), new Date()));
  }
}

export function* genericFetchWatcher(trigger, fetcher, onSuccess, onFail) {
  yield takeLatest(trigger, (action) =>
    genericFetchWorker(action, fetcher, onSuccess, onFail),
  );
}

export function* patientFetchWatcher() {
  yield call(
    genericFetchWatcher,
    PATIENT_FETCH_START,
    (action, token) => call(fetchPatient, action.payload.id, token),
    (action, response) =>
      fetchPatientSuccess(
        response.data.node.id,
        normalize(response.data.node, patientSchema),
      ),
    (action, error, timestamp) => fetchPatientFail(action.payload.id, error),
  );
}

export function* drugOverviewFetchWatcher() {
  yield call(
    genericFetchWatcher,
    DRUG_OVERVIEW_FETCH_START,
    (action, token) =>
      call(fetchPatientDrugOverview, action.payload.patientId, token),
    (action, response) =>
      fetchPatientDrugOverviewSuccess(
        action.payload.id,
        normalize(response.data.claims.claims, new schema.Array(claim)),
      ),
    fetchPatientDrugOverviewFail,
  );
}

export function* patientClaimViewerFetchWatcher() {
  yield call(
    genericFetchWatcher,
    CLAIM_VIEWER_FETCH_START,
    (action, token) =>
      call(fetchPatientClaimView, action.payload.patientId, token),
    (action, response) =>
      fetchPatientClaimViewSuccess(
        action.payload.id,
        normalize(response.data.claims.claims, new schema.Array(claim)),
      ),
    fetchPatientClaimViewFail,
  );
}

export function* opioidClaimFetchWatcher() {
  yield call(
    genericFetchWatcher,
    OPIOID_DETAIL_FETCH_START,
    (action, token) =>
      call(fetchPatientOpioidView, action.payload.patientId, token),
    (action, response) =>
      fetchPatientOpioidViewSuccess(
        action.payload.id,
        normalize(
          response.data.opioids.claims.concat(response.data.combinators.claims),
          new schema.Array(claim),
        ),
      ),
    (action, error, timestamp) =>
      fetchPatientOpioidViewFail(action.payload.id, error),
  );
}

export function* savePatientWorker(action) {
  const { patient } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(upsertPatient, patient, token),
    (response) =>
      put(
        upsertPatientSuccess(
          patient.id,
          normalize(response.patient, patientSchema),
        ),
      ),
    (error) => upsertPatientFail(patient.id, error),
  );
}

export function* savePatientWatcher() {
  yield takeLatest(PATIENT_UPSERT_START, savePatientWorker);
}
