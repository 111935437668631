import { combineReducers } from 'redux-immutable';
import { UiStateRecord } from '../types';
import bestPracticeCategory from './bestPracticeCategory';
import bestPracticesOverview from './bestPracticesOverview';
import campaignEdit from './campaignEdit';
import campaignList from './campaignList';
import caseList from './caseList';
import fetch from './fetch';
import issueList from './issueList';
import notifications from './notifications';
import optimistic from './optimistic';
import patientProfile from './patientProfile';
import sidebar from './sidebar';
import snackbar from './snackbar';
import viewTemplates from './viewTemplates';
import workItemDetail from './workItemDetail';
import workItemList from './workItemList';

const reducer = combineReducers(
  {
    fetch,
    bestPracticeCategory,
    bestPracticesOverview,
    sidebar,
    snackbar,
    viewTemplates,
    workItemDetail,
    workItemList,
    notifications,
    patientProfile,
    campaignList,
    caseList,
    issueList,
    optimistic,
    campaignEdit,
  },
  UiStateRecord,
);

export default reducer;
