import { Record, Map, Set, OrderedSet, List } from 'immutable';
import { schema } from 'normalizr';
import { SelectValueRecord } from 'app/filters/types';
import { ContactType } from 'app/interactions/constants';
import { PatientRecord } from 'app/patient/types';
import { PharmacyRecord } from 'app/pharmacies/types';
import { PrescriberRecord } from 'app/prescribers/types';
import { FilterRecord } from 'app/viewtemplates/types';

export const MethodsOfCommunication = Set([
  new SelectValueRecord({
    text: 'Phone',
    value: 'PHONE',
  }),
  // new SelectValueRecord({
  //   text: 'Email',
  //   value: 'EMAIL',
  // }),
  // new SelectValueRecord({
  //   text: 'Fax',
  //   value: 'FAX',
  // }),
  // new SelectValueRecord({
  //   text: 'Mail',
  //   value: 'MAIL',
  // }),
  // new SelectValueRecord({
  //   text: 'Text',
  //   value: 'TEXT',
  // }),
]);

export const RequiredSkills = OrderedSet([
  new SelectValueRecord({
    text: 'Pharmacist',
    value: 'PHARMACIST',
  }),
]);

export const Contacts = OrderedSet([
  new SelectValueRecord({
    text: 'Patient',
    value: ContactType.Patient,
  }),
  new SelectValueRecord({
    text: 'Prescriber',
    value: ContactType.Prescriber,
  }),
  // new SelectValueRecord({
  //   text: 'Pharmacy',
  //   value: ContactType.Pharmacy,
  // }),
]);

export const Targets = OrderedSet([
  new SelectValueRecord({
    text: 'Patient',
    value: 'PATIENT',
  }),
  new SelectValueRecord({
    text: 'Prescriber',
    value: 'PRESCRIBER',
  }),
  // new SelectValueRecord({
  //   text: 'Pharmacy',
  //   value: 'PHARMACY',
  // }),
]);

export const DefaultCampaignStatus = 'REVIEW';
export const CampaignStatus = OrderedSet([
  new SelectValueRecord({
    text: 'Review',
    value: 'REVIEW',
  }),
  new SelectValueRecord({
    text: 'In Progress',
    value: 'INPROGRESS',
  }),
  new SelectValueRecord({
    text: 'Completed',
    value: 'COMPLETED',
  }),
]);

export enum InteractionFieldType {
  Text = 'TEXT',
  Select = 'SELECT',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
}

export class InteractionFieldDefinitionRecord extends Record({
  name: undefined as string | undefined,
  type: InteractionFieldType.Select,
  options: List<string>() as List<string> | string[] | undefined,
  level: undefined as string | undefined,
}) {}

export class CampaignIssuesFilterRecord extends Record({
  issueFilterType: null,
  filters: Set<FilterRecord>(),
}) {}

export class CampaignRecord extends Record({
  id: undefined as string | undefined,
  entityId: undefined as string | undefined,
  version: undefined as number | undefined,
  name: undefined as string | undefined,
  prefix: undefined as string | undefined,
  etag: undefined as string | undefined,
  description: undefined as string | undefined,
  startDate: undefined as Date | undefined,
  endDate: undefined as Date | undefined,
  contact: undefined as
    | PatientRecord
    | PrescriberRecord
    | PharmacyRecord
    | undefined,
  target: undefined as
    | PatientRecord
    | PrescriberRecord
    | PharmacyRecord
    | undefined,
  owner: undefined as string | undefined,
  status: undefined as string | undefined,
  assignees: Set<string>() as Set<string> | string[] | undefined,
  duration: null,
  requiredSpecialists: Set<string>() as Set<string> | string[] | undefined,
  contactMethod: undefined as string | undefined,
  runsIndefinitely: undefined as boolean | undefined,
  interactionFieldDefinitions: Set<InteractionFieldDefinitionRecord>() as
    | Set<InteractionFieldDefinitionRecord>
    | InteractionFieldDefinitionRecord[]
    | undefined,
  systemManagedCases: undefined as boolean | undefined,
  includeCasesInQueue: undefined as boolean | undefined,
  issueFilter: new CampaignIssuesFilterRecord(),
  caseWorkflowDefinitionId: undefined as string | undefined,
}) {}

export class CloneCampaignRecord extends Record({
  id: undefined as string | undefined,
  name: undefined as string | undefined,
  prefix: undefined as string | undefined,
}) {}

export class CampaignStateRecord extends Record({
  campaigns: Map(),
}) {}

const user = new schema.Entity('users');

export const campaignSchema = new schema.Entity('campaigns', {
  assignees: new schema.Array(user),
  createdBy: user,
  owner: user,
});

export const campaignQuerySchema = new schema.Array(campaignSchema);
