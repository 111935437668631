import { fetchGraphqlQuery } from 'app/common/api';
import { upsertSessionMutation } from 'app/session/queries';
import { SessionInputRecord } from 'app/session/types';

export async function upsertSessionAsync(
  session: SessionInputRecord,
  token: string,
) {
  const data = await fetchGraphqlQuery(
    upsertSessionMutation,
    { session },
    token,
  );
  return { errors: data.errors, session: data.data.upsertCaseSession };
}
