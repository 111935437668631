import { CircularProgress } from '@material-ui/core';
import { Flag, ErrorOutline } from '@material-ui/icons';
import { navigate } from '@reach/router';
import {
  differenceInCalendarDays,
  format,
  min as minDate,
  max as maxDate,
  subMonths,
  addMonths,
  subDays,
  isAfter,
} from 'date-fns';
import PropTypes from 'prop-types';
import {
  sortWith,
  pipe,
  groupBy,
  map,
  sum,
  prop,
  reduce,
  filter,
  minBy,
  descend,
  uniq,
  uniqBy,
  flatten,
  max,
  isNil,
  min,
} from 'ramda';
import React, { useEffect, createRef, useState, forwardRef } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  LineSegment,
  VictoryLine,
  VictoryScatter,
  VictoryLabel,
  VictoryAxis,
  VictoryTooltip,
  Rect,
  Bar,
  VictoryCursorContainer,
} from 'victory';
import Message from '../../../common/components/Message';

const Flyout = ({ x, y, ...props }) => {
  const labelWidth = 40;
  const labelHeight = 20;
  return (
    <Rect
      {...props}
      x={x - 8 - labelWidth / 2}
      y={y - labelHeight - props.height / 2}
      rx={2}
      height={labelHeight}
      width={labelWidth}
      style={{ fill: 'black' }}
    />
  );
};

const Tooltip = (props) => {
  return (
    <VictoryTooltip
      {...props}
      active={props.focusedRow === props.datum.x}
      dx={-8}
      style={{ fill: 'white' }}
      orientation="top"
      flyoutComponent={<Flyout />}
    />
  );
};

const DiagonalHatchBar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern
          id="diagonalHatch"
          width="5"
          height="5"
          patternTransform="rotate(45 0 0)"
          patternUnits="userSpaceOnUse"
        >
          <line
            x1="0"
            y1="0"
            x2="0"
            y2="5"
            style={{ stroke: 'white', strokeWidth: '6' }}
          />
        </pattern>
      </defs>
      <Bar {...props} style={{ fill: 'url(#diagonalHatch)' }} />
    </svg>
  );
};

const RxLabel = ({ style, ...props }) => {
  const y = props.scale.y(props.datum.y);
  const y0 = props.scale.y(props.datum.y0);
  const display = y0 - y > 80 ? 'inherit' : 'none';
  return (
    <VictoryLabel
      {...props}
      style={{
        ...style,
        fill: '#000',
        fontFamily: 'roboto',
        fontSize: '12px',
        fontWeight: '500',
        display,
      }}
    />
  );
};

const DrugChart = ({ claims, height, width }) => {
  const [focusedRow, setFocusedRow] = useState(null);

  const firstStart = minDate(claims.map((x) => x.prescriptionStart));
  const lastEnd = maxDate(claims.map((x) => x.prescriptionEnd));

  const dateDomain = [
    maxDate([subMonths(new Date(), 12), subDays(firstStart, 14)]),
    maxDate([lastEnd, addMonths(new Date(), 1)]),
  ];

  const daysSuppliedByGpi10 = pipe(
    map((x) => x.merge({ gpi: x.gpi.substring(0, 10) })),
    groupBy(prop('gpi')),
    map(
      pipe(
        map((x) =>
          differenceInCalendarDays(x.prescriptionEnd, x.prescriptionStart),
        ),
        sum,
      ),
    ),
  )(claims);

  const sortedClaims = sortWith([
    descend((x) => daysSuppliedByGpi10[x.gpi.substring(0, 10)]),
    descend((x) => x.gpi.substring(0, 10)),
  ])(claims);

  const refillData = sortedClaims
    .filter((x) => !isNil(x.refillWindowStart))
    .map((x) => ({
      x: x.gpi.substring(0, 10),
      y: maxDate([dateDomain[0], new Date(x.refillWindowStart)]),
      y0: minDate([dateDomain[1], new Date(x.prescriptionEnd)]),
    }));

  const gpiDescriptionMap = pipe(
    filter((x) => !isNil(x.gpi10Description)),
    groupBy((x) => x.gpi.substring(0, 10)),
    map((x) => x[0]?.gpi10Description),
  )(sortedClaims);

  const data = sortedClaims.map((x) => ({
    x: x.gpi.substring(0, 10),
    y: maxDate([dateDomain[0], new Date(x.prescriptionStart)]),
    y0: minDate([dateDomain[1], new Date(x.prescriptionEnd)]),
    id: x.id,
    rxNumber: x.rxNumber,
    gpiDescription: x.gpi10Description,
    workItemId: x.workItem,
  }));

  const startEndPoints = pipe(
    map((x) => [
      { x: x.gpi.substring(0, 10), y: new Date(x.prescriptionStart) },
      { x: x.gpi.substring(0, 10), y: new Date(x.prescriptionEnd) },
    ]),
    flatten,
    uniq,
    filter((x) => isAfter(x.y, dateDomain[0])),
  )(claims);
  const gpiRows = uniqBy(prop('x'))(data);

  const ShortAxisLine = (props) => {
    let gpi = uniqBy(prop('x'))(data)[props.index].x;
    let x2 = pipe(
      filter((x) => x.x === gpi),
      reduce(
        minBy((x) => x.y),
        { y: dateDomain[1] },
      ),
      prop('y'),
      props.scale.y,
    )(data);
    return (
      <LineSegment
        {...props}
        x2={x2}
        style={{ ...props.style, strokeDasharray: '5,5' }}
      />
    );
  };

  const handleClickClaim = (id) => navigate(`/workitems/${id}`);

  const domainPadding = 20 * max(1, 10 - gpiRows.length);
  // max bar height of 50, min bar height of 10. This should handle up to 25 rows and allows 5px spacing between each
  const barWidth = min(
    50,
    max(
      10,
      (height - domainPadding - 50 - 5 * (gpiRows.length + 1)) / gpiRows.length,
    ),
  );

  return (
    <VictoryChart
      horizontal
      scale={{ y: 'time' }}
      domain={{ y: dateDomain }}
      domainPadding={{ x: domainPadding }}
      padding={{ top: 32, bottom: 32, left: 150, right: 32 }}
      height={height}
      theme={VictoryTheme.material}
      width={width}
      containerComponent={
        <VictoryCursorContainer
          cursorComponent={<div />}
          onCursorChange={(value, props) => {
            const row = gpiRows[Math.round(value?.x - 1)]?.x;
            if (row !== focusedRow) {
              setFocusedRow(row);
            }
          }}
        />
      }
    >
      <VictoryAxis
        style={{
          axis: { stroke: '#756f6a' },
          grid: { stroke: '#d0d0d0' },
        }}
        gridComponent={<ShortAxisLine />}
        tickFormat={(d) => {
          const maxLength = 21;
          const description = gpiDescriptionMap[d];
          if (isNil(description)) return d;
          if (description.length > maxLength)
            return description.substring(0, maxLength).concat('...');
          return description;
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: '#756f6a' },
          grid: { stroke: '#d0d0d0' },
        }}
        gridComponent={<LineSegment />}
      />
      <VictoryBar
        name="Base-Bars"
        barWidth={barWidth}
        style={{
          data: { fill: '#f4ac00', overflow: 'hidden' },
        }}
        data={data}
      />
      <VictoryBar
        name="Refill-Windows"
        barWidth={barWidth}
        data={refillData}
        style={{ data: { fill: '#fff' } }}
        dataComponent={<DiagonalHatchBar />}
      />
      <VictoryBar
        name="Interactable-Bars"
        barWidth={barWidth}
        style={{
          data: {
            fill: '#ff000000',
            stroke: '#000',
            strokeWidth: '1px',
            cursor: 'pointer',
          },
          labels: {
            pointerEvents: 'none',
          },
        }}
        labels={({ datum }) => datum.rxNumber}
        labelComponent={
          <RxLabel
            events={{
              onClick: () => [
                {
                  target: 'data',
                  mutation: (e) => handleClickClaim(e.datum.workItemId),
                },
              ],
            }}
          />
        }
        data={data}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: () => [
                {
                  target: 'data',
                  mutation: (e) => handleClickClaim(e.datum.workItemId),
                },
              ],
            },
          },
        ]}
      />
      <VictoryScatter
        name="Start-End-Tooltips"
        data={startEndPoints}
        labels={({ datum }) => format(datum.y, 'MM/dd')}
        style={{
          data: { fill: '#ff000000' },
        }}
        labelComponent={<Tooltip focusedRow={focusedRow} />}
      />
      <VictoryScatter
        name="Filled-Date-Points"
        style={{
          data: { fill: '#000' },
        }}
        data={claims
          .map((x) => ({
            x: x.gpi.substring(0, 10),
            y: new Date(x.filledDate),
          }))
          .filter((x) => isAfter(x.y, dateDomain[0]))}
      />
      <VictoryLine
        name="Today-Line"
        standalone={false}
        labels={['Today']}
        labelComponent={
          <VictoryLabel renderInPortal dx={-25} dy={(height - 48) * -1} />
        }
        y={() => new Date()}
      />
    </VictoryChart>
  );
};

const DrugOverviewChart = forwardRef(
  ({ claims, hasFetched, error, height, width }, ref) => (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      {hasFetched && claims.length > 0 && (
        <DrugChart
          claims={claims}
          height={max(0, height - 32)}
          width={max(0, width - 32)}
        />
      )}
      {!hasFetched && !error && (
        <Message
          icon={<CircularProgress style={{ marginBottom: '.5rem' }} />}
          message="Getting patient history..."
        />
      )}
      {claims.length === 0 && hasFetched && !error && (
        <Message
          icon={<Flag />}
          message="We could not find any paid claims in the last 6 months for this
            patient."
        />
      )}
      {error && (
        <Message
          icon={<ErrorOutline />}
          message="Something went wrong while getting patient history."
        />
      )}
    </div>
  ),
);

DrugOverviewChart.propTypes = {
  claims: PropTypes.array.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  error: PropTypes.object,
};

export default withResizeDetector(DrugOverviewChart);
