import { Record, Set, Map } from 'immutable';
import { schema } from 'normalizr';
import { GraphqlTypes, PhoneNumberRecord } from 'app/common/types';

export class PharmacyRecord extends Record({
  id: undefined as string | undefined,
  entityId: undefined as string | undefined,
  name: undefined as string | undefined,
  npi: undefined as string | undefined,
  phoneNumbers: Set<PhoneNumberRecord>(),
  __typename: GraphqlTypes.Pharmacy,
}) {}

export class PharmaciesStateRecord extends Record({
  pharmacies: Map<string, PharmacyRecord>(),
}) {}

export const pharmacySchema = new schema.Entity('pharmacies');
