import { Map } from 'immutable';
import { isNil, not } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { mapFiltersForCommit } from 'app/filters/utility';
import { WorkItemRecord } from 'app/workitems/types';
import { ViewTemplateRecord } from './types';

export function mapResponseToViewTemplate(input: any) {
  if (!input || !input.configuration) {
    return null;
  }
  return {
    id: input.id,
    name: input.name,
    isPublic: input.isPublic,
    tags: input.tags,
    createdBy: input.createdBy,
    filters: input.configuration.filter.map((filter: any) => {
      return {
        name: filter.name,
        value: filter.value,
        valueType: filter.valueType,
      };
    }),
    sortBy: input.configuration.sortBy,
    sortDirection: input.configuration.sortDirection,
  };
}

export function mapViewTemplateForCommit(template: ViewTemplateRecord) {
  if (isNil(template)) {
    throw new Error('template has not been supplied');
  }

  return {
    id: template.id,
    isPublic: template.isPublic,
    name: template.name,
    configuration: {
      filters: mapFiltersForCommit(template.filters),
      sortBy: template.sortBy,
      sortDirection: template.sortDirection,
    },
    tags: template.tags.toArray(),
  };
}
/**
 * Creates a function which can be used to test whether or not a work item
 * matches to a template.
 *
 * WARNING: This function currently handles the special case of workflow specific
 * and filterless templates
 * @param  {object} template The template to test matches against
 * @param  {object} filterConfig The filter configuration object
 * @returns {function} The filter function
 */
export function getFilterFunctionForTemplate(
  template: ViewTemplateRecord,
  filterConfig: Map<string, IClaimFilterConfiguration<any, any, any, any>>,
) {
  return function filterFunction(claim: WorkItemRecord) {
    const filters = template.filters.filter((filter) => {
      const def = filterConfig
        .filter((x) => x.targetType === IssueFilterType.Claim)
        .find((x) => x.name === filter.name);
      if (isNil(def))
        throw new Error('Unrecognized filter present in template');
      return (
        not(def.workflowSpecific) && def.targetType === IssueFilterType.Claim
      );
    });
    if (filters.isEmpty()) return false;
    return filters.every((filter) => {
      const config = filterConfig
        .filter((x) => x.targetType === IssueFilterType.Claim)
        .find((x) => x.name === filter.name);
      if (isNil(config))
        throw new Error('Unrecognized filter present in template');
      return config.filterWorkItem(
        claim,
        config.valueToQueryConverter(
          config.templateToFilterValueConverter(filter.value),
        ),
        // eslint-disable-next-line unicorn/no-useless-undefined
        undefined,
      );
    });
  };
}
