import { createSelector } from 'reselect';

const getUsersState = (state) => state.usersState;
const getActiveUserIds = (state) => getUsersState(state).activeUsers;

export const getUsers = (state) => getUsersState(state).users;
export const getUser = (state, id) => getUsers(state).get(id);
export const isFetchingUsers = (state) => getUsersState(state).isFetching;

export const getActiveUsers = createSelector(
  getUsers,
  getActiveUserIds,
  (users, activeIds) => users.filter((user) => activeIds.includes(user.id)),
);

export const getUsersWithPermission = (state, permission) =>
  getActiveUsers(state)
    .filter((user) => user.permissions.has(permission))
    .toSet();
