import {
  Button,
  ButtonBase,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import classnames from 'classnames';
import { format } from 'date-fns';
import { Editor, ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';
import {
  COMMENT_DELETE_FAIL,
  COMMENT_EDIT_FAIL,
  COMMENT_ADD_FAIL,
} from '../../constants';
import styles from './Comment.css';

const formatDate = (date) => format(date, `MM/dd/yyyy 'at' h:mm a`);

const Comment = ({
  author,
  message,
  dateCreated,
  isEditing,
  isOwner,
  isSaving,
  onEditRequest,
  onEdit,
  onEditCancel,
  onSaveClick,
  isDeleteDialogOpen,
  onDeleteRequest,
  onDeleteConfirm,
  onDeleteCancel,
  error,
  onErrorClick,
}) => {
  let errorText;
  let userCorrectableError = true;

  switch (error) {
    case COMMENT_ADD_FAIL:
      errorText = 'Failed to add comment, please click here to try again...';
      break;
    case COMMENT_EDIT_FAIL:
      errorText = 'Failed to edit comment, please click here to try again...';
      break;
    case COMMENT_DELETE_FAIL:
      errorText = 'Failed to delete comment, please click here to try again';
      break;
    default:
      errorText = 'Something unexpected went wrong, please contact us...';
      userCorrectableError = false;
      break;
  }

  const handleErrorClick = () => {
    if (userCorrectableError) {
      onErrorClick(error);
    }
  };

  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.disabled]: error,
      })}
    >
      <div className={styles.header}>
        <div className={styles.author}>{author.name}</div>
        <div className={styles.dateCreated}>{formatDate(dateCreated)}</div>
      </div>
      <div
        className={classnames({
          [styles.message]: true,
          [styles.disabled]: error,
        })}
      >
        <Editor editorState={message} onChange={onEdit} readOnly={!isEditing} />
      </div>
      {error && (
        <div className={styles.error} onClick={handleErrorClick}>
          {errorText}
        </div>
      )}
      {!error && isSaving && <div className={styles.footer}>Saving...</div>}
      {!error && isOwner && !isEditing && !isSaving && (
        <div className={styles.footer}>
          <ButtonBase onClick={onEditRequest}>Edit</ButtonBase>
          <div className={styles.divider}>•</div>
          <ButtonBase onClick={onDeleteRequest}>Delete</ButtonBase>
          <Dialog open={isDeleteDialogOpen}>
            <DialogContent>
              Are you sure you want to delete this comment?
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onDeleteConfirm}
                variant="contained"
                color="primary"
                autoFocus
              >
                Delete
              </Button>
              <Button onClick={onDeleteCancel} variant="text">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {isEditing && <Divider style={{ margin: '0 0 1rem 0' }} />}
      {!error && isEditing && (
        <div className={styles.footer}>
          <Button variant="contained" color="primary" onClick={onSaveClick}>
            Save
          </Button>
          <div className={styles.divider} />
          <Button variant="text" onClick={onEditCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

Comment.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.instanceOf(ContentState).isRequired,
  dateCreated: PropTypes.instanceOf(Date).isRequired,
  error: PropTypes.bool,
};

Comment.defaultProps = {
  error: false,
  disabled: false,
};

export default Comment;
