import { Map, Set } from 'immutable';
import { deletePropsFromOptimistic } from 'app/common/utilities/reducers';
import { COMMENT_ADD, COMMENT_ADD_SUCCESS } from '../../../comments/constants';
import { persistReducer } from '../../../hydrate/persist';
import {
  WORKITEM_SET_ASSIGNEE,
  WORKITEM_SET_STATUS,
  WORKITEM_UPSERT_SUCCESS,
  WORKITEM_UPSERT_FAIL,
} from '../../constants';

const reducer = (state = Map(), action) => {
  switch (action.type) {
    case WORKITEM_SET_ASSIGNEE: {
      const { workItemId, userId } = action.payload;
      return state.mergeDeep({ [workItemId]: Map({ assignee: userId }) });
    }
    case WORKITEM_SET_STATUS: {
      const { workItemId, status } = action.payload;
      return state.mergeDeep({ [workItemId]: Map({ status }) });
    }
    case WORKITEM_UPSERT_SUCCESS: {
      const { data } = action.payload;
      const workItemId = data.result;
      return deletePropsFromOptimistic(state, workItemId, [
        'assignee',
        'status',
      ]);
    }
    case WORKITEM_UPSERT_FAIL: {
      return deletePropsFromOptimistic(state, action.payload.id, [
        'assignee',
        'status',
      ]);
    }
    case COMMENT_ADD: {
      const { workItemId, comment } = action.payload;

      return state.mergeDeep({
        [workItemId]: new Map({
          comments: new Set([comment.id]),
        }),
      });
    }
    case COMMENT_ADD_SUCCESS: {
      const { data, optimisticId } = action.payload;
      const workItemId = data.result;
      const workItem = state.get(workItemId);
      const comments = workItem.get('comments');
      return state.merge({
        [workItemId]: workItem.merge({
          comments: comments.delete(optimisticId),
        }),
      });
    }
    default:
      return state;
  }
};

export default persistReducer('workItems/optimistic', reducer);
