import base64 from 'crypto-js/enc-base64';
import utf8 from 'crypto-js/enc-utf8';
import levenshtein from 'fast-levenshtein';
import localforage from 'localforage';
import {
  compose,
  curry,
  useWith,
  includes,
  toUpper,
  both,
  is,
  complement,
  equals,
  isNil,
  map,
  addIndex,
  concat,
  join,
  slice,
  head,
  last,
  pipe,
  prop,
  split,
  defaultTo,
  unless,
  __,
} from 'ramda';
import { MAX_INT_32 } from '../constants';

export function delay(time) {
  let delayTime = time;
  if (delayTime > MAX_INT_32) delayTime = MAX_INT_32;
  return new Promise((resolve) => {
    setTimeout(resolve, delayTime);
  });
}

export function b64Encode(string) {
  return base64.stringify(utf8.parse(string));
}

export function urlEncodeBase64(string) {
  // Remove padding equal characters
  // Replace characters according to base64url specifications
  return string.replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_');
}

export const b64UrlEncode = compose(urlEncodeBase64, b64Encode);

export function b64Decode(string) {
  return base64.parse(string).toString(utf8);
}

export function store(key, value) {
  if (isNil(value)) throw new Error('Invalid Argument.');
  return localforage.setItem(key, value);
}

export function remove(key) {
  return localforage.removeItem(key);
}

export function get(key) {
  return localforage.getItem(key);
}

// http://stackoverflow.com/a/196991
export function toTitleCase(string) {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const levenshteinSort = curry(
  (input, a, b) => levenshtein.get(a, input) - levenshtein.get(b, input),
);

export const containsCaseInsensitive = useWith(includes, [toUpper, toUpper]);

export function* retryAsync(func, onRetry, retries) {
  if (isNil(func)) throw new TypeError('func is not a function.');
  if (isNil(retries)) throw new TypeError('retries is required.');

  let count = 0;
  while (true) {
    try {
      return yield func();
    } catch (e) {
      if (count >= retries) {
        throw e;
      }
      count += 1;
      if (!isNil(onRetry)) {
        yield onRetry(e, count);
      }
    }
  }
}

export const mapToValuesArray = (x) => Array.from(x.values());
export const toArray = (x) => x.toArray();

export const isValidNumber = both(is(Number), complement(equals(NaN)));

export const pluralize = (value, count) => {
  if (count > 1) return `${value}s`;
  return value;
};

// Use this to help debug pipelines
export const debugLog = (x) => {
  console.log(x);
  return x;
};

const currencyFormatter = new Intl.NumberFormat('US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (value) => {
  if (isNil(value)) {
    return '';
  }

  return currencyFormatter.format(value);
};

export const mapIndexed = addIndex(map);

export function oxfordJoin(list, delimiter = ',', endWith = 'and') {
  switch (list.length) {
    case 1:
      return head(list);
    case 2:
      return `${head(list)} ${endWith} ${last(list)}`;
    default:
      return concat(
        join(`${delimiter} `, slice(0, -1, list)),
        ` ${endWith} ${last(list)}`,
      );
  }
}

export const getActiveTabFromLocation = curry((tabs, location) =>
  pipe(
    prop('pathname'),
    split('/'),
    last,
    unless(includes(__, tabs), () => './'),
  )(location),
);
