import { EXPORT_URL } from '../common/constants';
import {
  post,
  addcontentTypeJsonToHeader,
  addAuthorizationToHeader,
} from '../common/utilities/api';
import { getFilenameFromResponse } from './utility';

export async function exportDownloadRequest(filters, token) {
  const headers = addcontentTypeJsonToHeader(addAuthorizationToHeader(token));
  const response = await post(EXPORT_URL, headers, filters);
  const content = await response.blob();

  return { filename: getFilenameFromResponse(response), content };
}

export async function exportCaseDownloadRequest(filters, token) {
  const headers = addcontentTypeJsonToHeader(addAuthorizationToHeader(token));
  const response = await post(`${EXPORT_URL}/cases`, headers, filters);
  const content = await response.blob();

  return { filename: getFilenameFromResponse(response), content };
};