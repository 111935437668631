import { omit } from 'ramda';
import { fetchGraphqlQuery } from '../common/api';
import {
  upsertCampaignMutation,
  cloneCampaignMutation,
  fetchCampaignsQuery,
  fetchCampaignDetailsQuery,
} from './queries';

const mapCampaignToInputType = (c) =>
  omit(['interactionFieldDefinitions'])({
    ...c,
    interactionFields: c.interactionFieldDefinitions,
  });

export async function upsertCampaign(campaign, token) {
  const result = await fetchGraphqlQuery(
    upsertCampaignMutation,
    { campaign: mapCampaignToInputType(campaign) },
    token,
  );

  return { errors: result.errors, campaign: result.data.upsertCampaign };
}

export async function cloneCampaign(cloneRecord, token) {
  const result = await fetchGraphqlQuery(
    cloneCampaignMutation,
    { cloneCampaign: cloneRecord },
    token,
  );

  return { errors: result.errors, campaign: result.data.cloneCampaign };
}

export async function fetchCampaigns(token) {
  const result = await fetchGraphqlQuery(fetchCampaignsQuery, {}, token);

  return { errors: result.errors, campaigns: result.data.campaigns };
}

export async function fetchCampaignDetails(id, token) {
  const result = await fetchGraphqlQuery(
    fetchCampaignDetailsQuery,
    { id },
    token,
  );

  return { errors: result.errors, campaign: result.data.node };
}
