import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, FileCopy } from '@material-ui/icons';
import { Link } from '@reach/router';
import { format } from 'date-fns';
import { isNil } from 'ramda';
import Authorize from '../../../auth/containers/Authorize';
import { permissions } from '../../../auth/permissions';

const columnDefinitions = (onDelete, onClone) => [
  {
    id: 'Name',
    name: 'Name',
    width: 2,
    render: (c) => <Link to={c.entityId}>{c.name}</Link>,
  },
  {
    id: 'owner',
    name: 'Owner',
    width: 1,
    valueSelector: (c) => c,
    render: (c) => c.owner?.name,
  },
  {
    id: 'status',
    name: 'Status',
    width: 1,
    valueSelector: (c) => c,
    render: (c) => c.status,
  },
  {
    id: 'contact',
    name: 'Contact',
    width: 1,
    valueSelector: (c) => c,
    render: (c) => c.contact,
  },
  {
    id: 'duration',
    name: 'Duration',
    width: 2,
    valueSelector: (c) => c,
    render: (c) => {
      if (isNil(c.startDate)) return null;
      let timeRange = `${format(c.startDate, 'MM/dd/yyyy')}`;
      if (!isNil(c.endDate))
        timeRange += `- ${format(c.endDate, 'MM/dd/yyyy')}`;
      return timeRange;
    },
  },
  {
    id: 'assignees',
    name: 'Assignees',
    width: 1,
    valueSelector: (c) => c,
    render: (c) => c.assignees.count(),
  },
  {
    id: 'action',
    name: 'Action',
    width: 1,
    valueSelector: (c) => c,
    render: (c) => (
      <>
        <Authorize permissions={permissions.CAMPAIGN_DELETE}>
          <Tooltip title="Delete">
            <IconButton onClick={() => onDelete(c.entityId)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Authorize>
        <Authorize permissions={permissions.CAMPAIGN_ADD}>
          <Tooltip title="Copy">
            <IconButton onClick={() => onClone(c.entityId)}>
              <FileCopy />
            </IconButton>
          </Tooltip>
        </Authorize>
      </>
    ),
  },
];

export default columnDefinitions;
