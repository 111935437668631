import graphql from '../common/utilities/graphql';

const claimsFragment = graphql`
  fragment claimsFields on PagedClaimType {
    claims {
      id
      adjudicatedDate
      filledDate
      claimNumber
      claimStatus
      drugName
      pharmacyName
      patientId
      drugContainsOpioid
      workItem {
        id
      }
    }
  }
`;

export const patientClaimsHistoryQuery = graphql`
  ${claimsFragment}

  query patientClaimsHistory(
    $patientId: String!
    $priorDateRange: DateRangeInputGraphType!
    $futureDateRange: DateRangeInputGraphType!
  ) {
    prior: claims(
      filter: { filledDateRange: $priorDateRange, patientId: $patientId }
      sortOrder: DESC
      take: 200
    ) {
      ...claimsFields
    }
    future: claims(
      filter: { filledDateRange: $futureDateRange, patientId: $patientId }
      sortOrder: ASC
      take: 200
    ) {
      ...claimsFields
    }
  }
`;

export const patientDrugHistoryQuery = graphql`
  query patientDrugHistory($filters: ClaimFilterType!) {
    claims(filter: $filters, take: 200) {
      claims {
        id
        adjudicatedDate
        filledDate
        claimNumber
        claimStatus
        rxcui
        ndc
        patientId
        quantityDispensed
        daysSupply
        rxNumber
        workItem {
          id
        }
      }
    }
  }
`;
