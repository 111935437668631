import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Table from '../../../../common/components/Table';
import { QlEditType } from '../../../constants';
import {
  formatQuantityLimit,
  formatQuantityLimitOverTime,
  formatQlEditType,
} from '../../../utility';
import styles from './DrugHistory.css';
import columnDefinitions from './columns';

const DetailRow = ({ label, value }) => (
  <div className={styles.detailRow}>
    <div className={styles.detailLabel}>{label}:</div>
    <div className={styles.detailValue}>{value}</div>
  </div>
);

DetailRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

function isClaimValid(claim) {
  return claim.patientId && (claim.rxcui || claim.ndc);
}

const DrugHistory = ({
  isFetchingPatientDrugHistory,
  history,
  dateRange,
  hasAdditionalClaims,
  scrollToIndex,
  claim,
  errorFetching,
}) => (
  <div className={styles.container}>
    {!errorFetching && !isFetchingPatientDrugHistory && !claim.patientId && (
      <div className={styles.listMessage}>
        <span>There is no patient associated with this claim.</span>
      </div>
    )}
    {!errorFetching &&
      !isFetchingPatientDrugHistory &&
      claim.patientId &&
      !(claim.rxcui || claim.ndc) && (
        <div className={styles.listMessage}>
          <span>There is no drug associated with this claim.</span>
        </div>
      )}
    {isClaimValid(claim) && (
      <div className={styles.formularyRule}>
        <div className={styles.header}>
          Showing history for{' '}
          {claim.drugName && (
            <span className={styles.drugName}>{claim.drugName}</span>
          )}
          {claim.rxcui && (
            <span className={styles.drugNumber}>(RXCUI: {claim.rxcui})</span>
          )}
          {!claim.rxcui && claim.ndc && (
            <span className={styles.drugNumber}>(NDC: {claim.ndc})</span>
          )}
        </div>
        {claim.quantityLimitType !== QlEditType.none && (
          <div className={styles.formularyInfo}>
            <DetailRow
              label="QL Type"
              value={formatQlEditType(claim.quantityLimitType)}
            />
            {claim.quantityLimitType === QlEditType.qtyOverTime ? (
              <DetailRow
                label="Limit"
                value={formatQuantityLimitOverTime(
                  claim.quantityLimitAmount,
                  claim.quantityLimitDays,
                )}
              />
            ) : (
              <DetailRow
                label="Daily Limit"
                value={formatQuantityLimit(
                  claim.quantityLimitAmount,
                  claim.quantityLimitDays,
                  claim.quantityLimitPerDay,
                )}
              />
            )}
          </div>
        )}
      </div>
    )}
    {!errorFetching && isClaimValid(claim) && (
      <div className={styles.table}>
        <Table
          data={!isFetchingPatientDrugHistory ? history : null}
          columnDefinitions={columnDefinitions}
          loadMoreRows={() => {}}
          hasNextPage={false}
          isFetching={isFetchingPatientDrugHistory}
          scrollToIndex={scrollToIndex}
          isSortEnabled={false}
        />
      </div>
    )}
    {!errorFetching && isClaimValid(claim) && (
      <div className={styles.footer}>
        Showing drug history between {format(dateRange.start, 'MM/dd/yyyy')}{' '}
        &ndash; {format(dateRange.end, 'MM/dd/yyyy')}.{' '}
        {hasAdditionalClaims && (
          <span>There may be additional results not currently displayed.</span>
        )}
      </div>
    )}
    {errorFetching && isClaimValid(claim) && (
      <div className={styles.listLoadError}>
        There was an unexpected error loading the claims.
      </div>
    )}
  </div>
);

DrugHistory.propTypes = {
  claim: PropTypes.shape({
    patientId: PropTypes.string,
    rxcui: PropTypes.string,
    ndc: PropTypes.string,
  }).isRequired,
  history: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      claim: ImmutablePropTypes.record,
    }).isRequired,
  ),
  dateRange: ImmutablePropTypes.recordOf({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }).isRequired,
  hasAdditionalClaims: PropTypes.bool.isRequired,
  scrollToIndex: PropTypes.number,
  errorFetching: PropTypes.object,
};

export default DrugHistory;
