import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useTransition, config, animated } from 'react-spring';
import Notification from '../Notification';
import styles from './NotificationPanel.css';

const NotificationPanel = ({
  className,
  notifications,
  onDismiss,
  onRequestClose,
}) => {
  const transitions = useTransition(notifications.toArray(), {
    from: { opacity: 0, transform: `translate3d(-100%,0,0)` },
    enter: { opacity: 1, transform: `translate3d(0,0,0)` },
    leave: { opacity: 0, transform: `translate3d(100%,0,0)` },
    keys: notifications.map((x) => x.id).toArray(),
    config: config.stiff,
  });

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.title}>Notifications</div>
        <IconButton onClick={onRequestClose} className={styles.close}>
          <Close />
        </IconButton>
      </div>
      <Scrollbars
        className={styles.notifications}
        renderTrackHorizontal={() => <div />}
        renderThumbHorizontal={() => <div />}
        renderThumbVertical={({ style, ...props }) => (
          <div style={style} {...props} className={styles.scrollThumb} />
        )}
      >
        {notifications.isEmpty() && (
          <div className={styles.nothing}>No Notifications...</div>
        )}
        {transitions((transitionStyles, notification) => {
          return (
            <animated.div
              style={{
                ...transitionStyles,
              }}
            >
              <Notification
                key={notification.id}
                className={styles.notification}
                notification={notification}
                onDismiss={onDismiss}
              />
            </animated.div>
          );
        })}
      </Scrollbars>
    </div>
  );
};

NotificationPanel.propTypes = {
  className: PropTypes.string,
  notifications: ImmutablePropTypes.orderedSet.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

NotificationPanel.defaultProps = {
  className: '',
};

export default NotificationPanel;
