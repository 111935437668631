import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { useOptimisticMutation } from 'app/common/utilities/hooks';
import Authorize from '../../../auth/containers/Authorize';
import { permissions } from '../../../auth/permissions';
import Unauthorized from '../../../common/components/Unauthorized/Unauthorized';
import {
  isRunning as running,
  isErrored as Errored,
  isDeletingCampaign,
  getErrorOnCampaignDelete,
  hasCompleted as completed,
} from '../../../ui/selectors';
import { userHasPermissions } from '../../../user/selectors';
import {
  fetchCampaignsStart,
  deleteCampaign,
  cloneCampaign,
} from '../../actions';
import CampaignList from '../../components/CampaignList/CampaignList';
import { getLatestCampaigns } from '../../selectors';

const CampaignListContainer = ({
  campaigns,
  dispatch,
  isFetching,
  hasErrorFetchingCampaigns,
  isDeleting,
  deleteError,
  hasFetched,
  userCanAddCampaigns,
}) => {
  const [isDeleteDialogOpen, setDeleteDialog] = useState(false);
  const [idToDelete, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(fetchCampaignsStart());
  }, []);

  useEffect(() => {
    if (!isDeleting && isNil(deleteError) && isDeleteDialogOpen) {
      setDeleteDialog(false);
      setDeleteId(null);
    }
  }, [isDeleting, deleteError]);

  const handleDeleteRequest = (id) => {
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteDialog(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteCampaign(idToDelete));
  };

  const {
    mutation,
    optimisticId,
    createdId: newId,
    isRunning,
    hasCompleted,
    isErrored,
    errors,
  } = useOptimisticMutation(cloneCampaign);

  const handleCloneConfirm = (data) => {
    mutation(data);
  };

  return (
    <Authorize
      permissions={permissions.CAMPAIGN_LIST}
      renderOnUnauthorize={<Unauthorized />}
    >
      <CampaignList
        canAddCampaign={userCanAddCampaigns}
        campaigns={hasFetched ? campaigns : new Set()}
        isFetching={isFetching}
        onDeleteRequest={handleDeleteRequest}
        onDeleteConfirm={handleDeleteConfirm}
        onDeleteCancel={handleDeleteCancel}
        isDeleteDialogOpen={isDeleteDialogOpen}
        onCloneConfirm={handleCloneConfirm}
        isCloning={isRunning}
        isCloningError={isErrored}
        isCloneComplete={hasCompleted}
        cloneError={errors}
        hasFetchingError={hasErrorFetchingCampaigns}
        isDeleting={isDeleting}
        deleteError={deleteError}
      />
    </Authorize>
  );
};

CampaignListContainer.propTypes = {
  campaigns: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  hasErrorFetchingCampaigns: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  deleteError: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  userCanAddCampaigns: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isFetching: running(state, fetchCampaignsStart()),
  hasFetched: completed(state, fetchCampaignsStart()),
  campaigns: getLatestCampaigns(state),
  hasErrorFetchingCampaigns: Errored(state, fetchCampaignsStart()),
  isDeleting: isDeletingCampaign(state),
  deleteError: getErrorOnCampaignDelete(state),
  userCanAddCampaigns: userHasPermissions(state, permissions.CAMPAIGN_ADD),
}))(CampaignListContainer);
