import graphql from '../common/utilities/graphql';

export const sessionFragmentFactory = (name: string) => graphql`
  fragment ${name} on CaseSessionType {
    id
    status
    sessionStart
    primaryCase {
        id
        caseSessions {
          id
        }
    }
    interactions {
      id
      status
      dateContacted
      suggestedCases {
        id
      }
    }
    user {
      id
      name
      email
      pictureUrl
    }
  }
`;

export const upsertSessionMutation = graphql`
  ${sessionFragmentFactory('sessionFragment')}
  mutation ($session: CaseSessionInputType!) {
    upsertCaseSession(caseSession: $session) {
      ...sessionFragment
    }
  }
`;
