import PropTypes from 'prop-types';
import { values } from 'ramda';
import React, { Fragment, forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { VictoryPie, VictoryLabel } from 'victory';
import UnexpectedError from '../../../common/components/UnexpectedError';
import { AGGREGATION_PERIODS } from '../../constants';
import RiskChart from '../RiskChart';
import RiskLine from '../RiskLine/RiskLine';
import styles from './BestPracticeOverview.css';
import CategoryCard from './CategoryCard';

const riskRanges = [
  {
    name: 'Excellent',
    start: 60,
    end: 100,
    color: '#80c644',
  },
  {
    name: 'Good',
    start: 15,
    end: 60,
    color: '#f4ac00',
  },
  {
    name: 'Bad',
    start: 0,
    end: 15,
    color: '#f72c2c',
  },
];

const RiskGauge = forwardRef(
  ({ isFetching, currentRisk, maxRisk, minRisk }, ref) => {
    const eliminatedRisk = 1 - (currentRisk - minRisk) / (maxRisk - minRisk);
    const getData = () => {
      const fullSections = riskRanges
        .filter(
          (x) => x.end > eliminatedRisk * 100 && x.start > eliminatedRisk * 100,
        )
        .map((x) => ({ x: 1, y: x.end - x.start }));

      const partialSections = riskRanges
        .filter(
          (x) =>
            x.start <= eliminatedRisk * 100 && x.end >= eliminatedRisk * 100,
        )
        .map((x) => ({ x: 2, y: x.end - eliminatedRisk * 100 }));

      return fullSections.concat(partialSections).concat(
        eliminatedRisk !== 0
          ? [{ x: 3, y: eliminatedRisk * 100, hide: true }]
          : [], // A 0 value slice shifts everything a bit so prevent it
      );
    };

    const data = getData();
    const activeRange = riskRanges.filter(
      (x) => x.start <= eliminatedRisk * 100 && x.end >= eliminatedRisk * 100,
    )[0];

    return (
      <div className={styles.currentScore} ref={ref}>
        <svg viewBox="0 0 300 300" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            width={300}
            height={375}
            radius={180}
            innerRadius={160}
            cornerRadius={15}
            startAngle={-120}
            padAngle={1}
            endAngle={120}
            data={riskRanges.map((x) => ({ x: 1, y: x.end - x.start }))}
            labels={() => null}
            style={{
              data: {
                fill: () => {
                  return 'lightgrey';
                },
              },
            }}
          />
          {!isFetching && (
            <VictoryPie
              standalone={false}
              width={300}
              height={375}
              radius={180}
              innerRadius={160}
              cornerRadius={15}
              startAngle={-120}
              endAngle={120}
              padAngle={1}
              data={data}
              labels={() => null}
              style={{
                data: {
                  fill: ({ datum }) => {
                    return !datum.hide ? activeRange?.color : 'transparent';
                  },
                },
              }}
            />
          )}
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={150}
            y={150}
            text={isFetching ? '--' : currentRisk}
            style={{ fontSize: 72 }}
          />
          {!isFetching && (
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="middle"
              x={150}
              y={210}
              text={activeRange?.name}
              style={{ fontSize: 36 }}
            />
          )}
        </svg>
      </div>
    );
  },
);

RiskGauge.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  currentRisk: PropTypes.number.isRequired,
  maxRisk: PropTypes.number.isRequired,
  minRisk: PropTypes.number.isRequired,
};

const BestPracticeOverview = ({
  categories,
  currentRisk,
  historicalRisk,
  errorFetching,
  isFetching,
}) => {
  return (
    <Scrollbars>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {errorFetching && <UnexpectedError />}
          {!errorFetching && (
            <Fragment>
              <header className={styles.header}>
                <h1 className={styles.title}>CMS Best Practices</h1>
                <h2 className={styles.category}>
                  Formulary and Benefit Administration Health Check
                </h2>
              </header>
              <RiskGauge
                currentRisk={currentRisk.score}
                maxRisk={currentRisk.max}
                minRisk={currentRisk.min}
                isFetching={isFetching}
              />
              <div className={styles.history}>
                <RiskChart isFetching={isFetching}>
                  <RiskLine
                    displayRiskRange
                    style={{
                      data: {
                        strokeWidth: () => 3,
                      },
                    }}
                    key="historical-risk"
                    historicalRisk={historicalRisk}
                    labelComponent={
                      <VictoryLabel style={{ fontSize: '18px' }} />
                    }
                  />
                </RiskChart>
              </div>
              <div className={styles.categories}>
                {isFetching && (
                  <Fragment>
                    <CategoryCard isFetching />
                    <CategoryCard isFetching />
                    <CategoryCard isFetching />
                    <CategoryCard isFetching />
                  </Fragment>
                )}
                {!isFetching &&
                  categories.map((x) => (
                    <CategoryCard
                      key={x.id}
                      categoryId={x.id}
                      categoryName={x.name}
                      riskScore={x.riskScore}
                      isPlaceholder={false}
                      isFetching={isFetching}
                    />
                  ))}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Scrollbars>
  );
};

BestPracticeOverview.propTypes = {
  currentRisk: ImmutablePropTypes.recordOf({
    score: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  }),
  errorFetching: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  historicalRisk: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      date: PropTypes.instanceOf(Date),
      period: PropTypes.oneOf(values(AGGREGATION_PERIODS)),
      riskScore: ImmutablePropTypes.recordOf({
        score: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
      }),
    }),
  ),
  categories: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      id: PropTypes.string,
      name: PropTypes.string,
      riskScore: ImmutablePropTypes.recordOf({
        score: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
      }),
    }),
  ),
};

export default BestPracticeOverview;
