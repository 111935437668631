import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  getPatientClaimsHistory,
  getPatientDrugHistory,
} from '../../../claims/selectors';
import {
  addComment,
  editComment,
  deleteComment,
} from '../../../comments/actions';
import {
  COMMENT_ADD_FAIL,
  COMMENT_DELETE_FAIL,
  COMMENT_EDIT_FAIL,
} from '../../../comments/constants';
import NotFound from '../../../common/components/NotFound';
import {
  isWorkItemDetailsFetching,
  getWorkItemDetailError,
  isWorkItemStatusesFetching,
  isPatientClaimsHistoryFetching,
  getPatientClaimsHistoryError,
  isPatientDrugHistoryFetching,
  getPatientDrugHistoryError,
  getErroredCommentIds,
  getAddingCommentIds,
  getDeletingCommentIds,
  getEditingCommentIds,
} from '../../../ui/selectors';
import { getUserId, getActiveViewTemplateId } from '../../../user/selectors';
import { fetchUsers } from '../../../users/actions';
import {
  getActiveUsers,
  isFetchingUsers as isFetchingUsersSelector,
} from '../../../users/selectors';
import { fetchViewTemplates } from '../../../viewtemplates/actions';
import { getViewTemplateMatches } from '../../../viewtemplates/selectors';
import {
  fetchWorkItemDetails,
  setAssignee,
  setStatus,
  fetchWorkItemStatuses,
} from '../../actions';
import WorkItemDetailView from '../../components/WorkItemDetailView';
import { getWorkItem, getWorkItemStatuses } from '../../selectors';
import columnDefinitions from './columns';

const drugHistoryDefaultDaysBack = 90;

export class WorkItemDetailViewContainer extends Component {
  static propTypes = {
    workItem: ImmutablePropTypes.recordOf({
      id: PropTypes.string.isRequired,
      status: ImmutablePropTypes.recordOf({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isDeprecated: PropTypes.bool.isRequired,
      }),
      assignee: ImmutablePropTypes.recordOf({
        id: PropTypes.string.isRequired,
      }),
      claim: ImmutablePropTypes.record.isRequired,
    }),
    isFetchingWorkItem: PropTypes.bool.isRequired,
    errorFetchingWorkItem: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    isFetchingUsers: PropTypes.bool.isRequired,
    users: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.contains({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      PropTypes.string.isRequired,
    ).isRequired,
    workItemStatuses: ImmutablePropTypes.mapOf(ImmutablePropTypes.record)
      .isRequired,
    isFetchingStatuses: PropTypes.bool.isRequired,
    patientClaimsHistory: ImmutablePropTypes.record.isRequired,
    isFetchingPatientClaimsHistory: PropTypes.bool.isRequired,
    errorFetchingPatientClaimsHistory: PropTypes.object,
    isFetchingDrugHistory: PropTypes.bool.isRequired,
    drugHistory: ImmutablePropTypes.record.isRequired,
    errorFetchingPatientDrugHistory: PropTypes.object,
    templateMatches: PropTypes.array.isRequired,
    activeTemplateId: PropTypes.string,
  };

  static defaultProps = {
    workItem: null,
    activeTemplateId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasFetchedDetails: false,
      hasFetchedPatientClaimsHistory: false,
    };
  }

  componentDidMount = () => {
    const { dispatch, id } = this.props;
    dispatch(fetchWorkItemDetails(id));
    dispatch(fetchUsers());
    dispatch(fetchWorkItemStatuses());
    dispatch(fetchViewTemplates(false));
  };

  componentWillReceiveProps = (nextProps) => {
    const { dispatch, isFetchingWorkItem, isFetchingPatientClaimsHistory, id } =
      this.props;

    if (isFetchingWorkItem && !nextProps.isFetchingWorkItem) {
      this.setState({ hasFetchedDetails: true });
    }

    if (
      isFetchingPatientClaimsHistory &&
      !nextProps.isFetchingPatientClaimsHistory
    ) {
      this.setState({ hasFetchedPatientClaimsHistory: true });
    }

    if (id != nextProps.id) {
      this.setState({ hasFetchedDetails: false });
      dispatch(fetchWorkItemDetails(nextProps.id));
      dispatch(fetchUsers());
      dispatch(fetchWorkItemStatuses());
      dispatch(fetchViewTemplates(false));
    }
  };

  handleChangeStatus = (status) => {
    const {
      dispatch,
      workItem: { id },
      workItemStatuses,
    } = this.props;

    dispatch(setStatus(id, status, workItemStatuses.get(String(status)).name));
  };

  handleChangeAssignee = (assignee) => {
    const {
      dispatch,
      workItem: { id },
    } = this.props;

    if (assignee) {
      dispatch(setAssignee(id, assignee.id, assignee.name));
    } else {
      // Unassigned
      dispatch(setAssignee(id, null, 'Unassigned'));
    }
  };

  handleResubmitComment = (error, comment) => {
    const {
      dispatch,
      workItem: { id },
    } = this.props;

    switch (error) {
      case COMMENT_ADD_FAIL:
        dispatch(addComment(id, comment));
        break;
      case COMMENT_EDIT_FAIL:
        dispatch(editComment(comment.id, id, comment.message));
        break;
      case COMMENT_DELETE_FAIL:
        dispatch(deleteComment(comment.id, id));
        break;
      default:
        break;
    }
  };

  handleAddComment = (message) => {
    const {
      dispatch,
      userId,
      workItem: { id },
    } = this.props;
    dispatch(
      addComment(id, {
        id: uuid(),
        author: userId,
        dateCreated: new Date().toISOString(),
        message,
      }),
    );
  };

  handleEditComment = (id, message) => {
    const { workItem, dispatch } = this.props;
    dispatch(editComment(id, workItem.id, message));
  };

  handleDeleteComment = (id) => {
    const { workItem, dispatch } = this.props;
    dispatch(deleteComment(id, workItem.id));
  };

  render() {
    const {
      workItem,
      isFetchingWorkItem,
      isFetchingUsers,
      users,
      errorFetchingWorkItem,
      workItemStatuses,
      isFetchingStatuses,
      erroredComments,
      editingComments,
      deletingComments,
      addingComments,
      patientClaimsHistory,
      isFetchingPatientClaimsHistory,
      errorFetchingPatientClaimsHistory,
      isFetchingDrugHistory,
      drugHistory,
      errorFetchingPatientDrugHistory,
      userId,
      templateMatches,
      activeTemplateId,
      location,
    } = this.props;

    const { hasFetchedDetails, hasFetchedPatientClaimsHistory } = this.state;

    const findIndex = (claims) => {
      const { workItem } = this.props;

      if (claims.count() > 0 && workItem && workItem.claim) {
        return claims
          .toList()
          .findIndex((claims) => claims.id == workItem.claim.id);
      }
      return null;
    };

    return errorFetchingWorkItem && errorFetchingWorkItem.status === 404 ? (
      <NotFound />
    ) : (
      <WorkItemDetailView
        userId={userId}
        workItem={workItem}
        isFetchingWorkItem={isFetchingWorkItem}
        errorFetchingWorkItem={errorFetchingWorkItem}
        users={users}
        statuses={workItemStatuses.toSet()}
        isFetchingUsers={isFetchingUsers}
        onChangeAssignee={this.handleChangeAssignee}
        onChangeStatus={this.handleChangeStatus}
        isFetchingStatuses={isFetchingStatuses}
        // TODO Consider moving comment stuff into a container around workitem comments specifically
        onAddComment={this.handleAddComment}
        onResubmitComment={this.handleResubmitComment}
        onEditComment={this.handleEditComment}
        onDeleteComment={this.handleDeleteComment}
        erroredComments={erroredComments}
        editingComments={editingComments}
        deletingComments={deletingComments}
        addingComments={addingComments}
        columnDefinitions={columnDefinitions}
        patientClaimsHistory={patientClaimsHistory}
        isFetchingPatientClaimsHistory={isFetchingPatientClaimsHistory}
        errorFetchingPatientClaimsHistory={errorFetchingPatientClaimsHistory}
        scrollToIndexPatientClaimsHistory={findIndex(
          patientClaimsHistory.claims,
        )}
        hasFetchedDetails={hasFetchedDetails}
        hasFetchedPatientClaimsHistory={hasFetchedPatientClaimsHistory}
        isFetchingPatientDrugHistory={isFetchingDrugHistory}
        drugHistory={drugHistory}
        scrollToIndexDrugHistory={findIndex(drugHistory.claims)}
        errorFetchingPatientDrugHistory={errorFetchingPatientDrugHistory}
        templateMatches={templateMatches}
        activeTemplateId={activeTemplateId}
        location={location}
      />
    );
  }
}

export default connect((state, props) => ({
  id: props.id,
  workItem: getWorkItem(state, props.id),
  errorFetchingWorkItem: getWorkItemDetailError(state),
  erroredComments: getErroredCommentIds(state),
  editingComments: getEditingCommentIds(state),
  deletingComments: getDeletingCommentIds(state),
  addingComments: getAddingCommentIds(state),
  userId: getUserId(state),
  users: getActiveUsers(state),
  workItemStatuses: getWorkItemStatuses(state),
  patientClaimsHistory: getPatientClaimsHistory(state, props.id, 200),
  isFetchingWorkItem: isWorkItemDetailsFetching(state),
  isFetchingUsers: isFetchingUsersSelector(state),
  isFetchingStatuses: isWorkItemStatusesFetching(state),
  isFetchingPatientClaimsHistory: isPatientClaimsHistoryFetching(state),
  isFetchingDrugHistory: isPatientDrugHistoryFetching(state),
  errorFetchingPatientClaimsHistory: getPatientClaimsHistoryError(state),
  drugHistory: getPatientDrugHistory(
    state,
    props.id,
    drugHistoryDefaultDaysBack,
    200,
  ),
  errorFetchingPatientDrugHistory: getPatientDrugHistoryError(state),
  templateMatches: getViewTemplateMatches(state, props.id),
  activeTemplateId: getActiveViewTemplateId(state),
}))(WorkItemDetailViewContainer);
