import { isNil } from 'ramda';
import { createSelector } from 'reselect';
import { mapToValuesArray } from 'app/common/utilities/generic';
import { IssueFilterType } from 'app/filters/constants';
import filterDefinitions from 'app/filters/definitions';
import { getAppliedFilters } from 'app/ui/selectors';
import { getActiveViewTemplateId, getUserId } from 'app/user/selectors';
import { getUser } from 'app/users/selectors';
import { ViewTemplateRecord, FilterRecord } from 'app/viewtemplates/types';
import { getFilterFunctionForTemplate } from 'app/viewtemplates/utility';
import {
  getSortBy,
  getSortDirection,
  getWorkItem,
} from 'app/workitems/selectors';

const denormalizeViewTemplate = (state, viewTemplate) => {
  return viewTemplate?.merge({
    createdBy: getUser(state, viewTemplate.createdBy),
  });
};

const getViewTemplateSelector = (state, id) =>
  denormalizeViewTemplate(
    state,
    state.viewTemplatesState.viewTemplates.get(id),
  );

export const getIsFetchingViewTemplates = (state) =>
  state.viewTemplatesState.isFetching;

export const getViewTemplates = (state) =>
  state.viewTemplatesState.viewTemplates.map((t) =>
    denormalizeViewTemplate(state, t),
  );

export const getActiveViewTemplate = (state) => {
  const id = getActiveViewTemplateId(state);
  return getViewTemplateSelector(state, id);
};

/** * Gets a new view template record consisting of the active template's properties and the applied filters.
 * @param  {object} state The application state
 * @param  {object} valueTypeMap The object that maps filter types to filter's value types
 * @return {ViewTemplateRecord} A new view template record that replaces the active template's filters with the applied ones.
 */
export const getCurrentViewTemplate = createSelector(
  getActiveViewTemplate,
  getAppliedFilters,
  getSortBy,
  getSortDirection,
  (s, c) => c,
  (s, c, m) => m,
  (viewTemplate, filters, sortBy, sortDirection) => {
    const viewTemplateFilters = filters
      .map((filter, id) => {
        const filterConfig = filterDefinitions.get(id);

        return new FilterRecord({
          name: filterConfig.name,
          value: filterConfig.filterToTemplateValueConverter(filter.value),
          valueType: filterConfig.storedValueType,
        });
      })
      .toSet();
    return new ViewTemplateRecord(viewTemplate).merge({
      filters: viewTemplateFilters,
      sortBy,
      sortDirection,
    });
  },
);

export const isUserCurrentViewTemplateOwner = createSelector(
  getActiveViewTemplate,
  getUserId,
  (activeTemplate, userId) => {
    if (!activeTemplate) {
      return false;
    }
    return activeTemplate.createdBy.id === userId;
  },
);

export const isCurrentViewTemplateDirty = createSelector(
  getActiveViewTemplate,
  (s, c, m) => getCurrentViewTemplate(s, c, m),
  getAppliedFilters,
  (activeTemplate, currentTemplate, filters) => {
    if (!activeTemplate) {
      return !filters.isEmpty();
    }
    // We do not want to compare the options on filters
    return !activeTemplate.equals(currentTemplate);
  },
);

export const getViewTemplateMatches = (state, workItemId) => {
  const workItem = getWorkItem(state, workItemId);
  if (isNil(workItem)) return [];
  const templates = getViewTemplates(state);
  const matches = templates.filter((template) =>
    getFilterFunctionForTemplate(template, filterDefinitions)(workItem),
  );

  return mapToValuesArray(matches);
};
