import {
  PATIENT_CLAIMS_HISTORY_FETCH_START,
  PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS,
  PATIENT_CLAIMS_HISTORY_FETCH_FAIL,
  PATIENT_DRUG_HISTORY_FETCH_START,
  PATIENT_DRUG_HISTORY_FETCH_SUCCESS,
  PATIENT_DRUG_HISTORY_FETCH_FAIL,
} from './constants';

export function fetchPatientClaimsHistory(patientId, filledDate) {
  return {
    type: PATIENT_CLAIMS_HISTORY_FETCH_START,
    payload: { patientId, filledDate },
  };
}

export function fetchPatientClaimsHistorySuccess(data) {
  return { type: PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS, payload: { data } };
}

export function fetchPatientClaimsHistoryFail(error) {
  return { type: PATIENT_CLAIMS_HISTORY_FETCH_FAIL, payload: { error } };
}

export function fetchPatientDrugHistory(patientId, drug, daysBack, filledDate) {
  return {
    type: PATIENT_DRUG_HISTORY_FETCH_START,
    payload: { patientId, drug, daysBack, filledDate },
  };
}

export function fetchPatientDrugHistorySuccess(data) {
  return { type: PATIENT_DRUG_HISTORY_FETCH_SUCCESS, payload: { data } };
}

export function fetchPatientDrugHistoryFail(error) {
  return { type: PATIENT_DRUG_HISTORY_FETCH_FAIL, payload: { error } };
}
