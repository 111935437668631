import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  Warning,
  AssignmentInd,
  ZoomIn,
} from '@material-ui/icons';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Message from '../../../../common/components/Message/Message';
import DrugOverviewChart from '../../../containers/DrugOverviewChart';
import { PatientRecord } from '../../../types';
import PatientDetails from '../PatientDetails';
import styles from './Overview.css';

const DrugOverviewDialog = ({
  onDrugOverviewClose,
  isDrugOverviewOpen,
  id,
}) => {
  return (
    <Dialog
      open={isDrugOverviewOpen}
      onClose={onDrugOverviewClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>Drug Overview</DialogTitle>
      <DialogContent>
        <div style={{ height: '80vh' }}>
          <DrugOverviewChart id={id} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDrugOverviewClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Overview = ({
  patient,
  displaySummaryCards,
  opioidIssueCount,
  hasFetchedOpioidIssueCount,
  casesCount,
  hasFetchedPatient,
}) => {
  const [isDrugOverviewOpen, setIsDrugOverviewOpen] = useState(false);

  const onDrugOverviewClose = () => setIsDrugOverviewOpen(false);

  return (
    <>
      <div className={styles.row}>
        <Paper classes={{ root: styles.basicInfo }}>
          <PatientDetails patient={patient.toJS()} />
        </Paper>
        <Paper classes={{ root: styles.claimsTrend }}>
          <div className={styles.paperTitle}>
            Drug Overview{'  '}
            <ZoomIn
              classes={{ root: styles.detailRowHelper }}
              onClick={() => setIsDrugOverviewOpen(true)}
            />
          </div>
          <DrugOverviewChart id={patient.id} />
          <DrugOverviewDialog
            isDrugOverviewOpen={isDrugOverviewOpen}
            onDrugOverviewClose={onDrugOverviewClose}
            id={patient.id}
          />
        </Paper>
      </div>
      {displaySummaryCards && (
        <div className={styles.row}>
          <Paper classes={{ root: styles.basicInfo }}>
            <div className="">Patient Issues</div>
            <div className={styles.issuesContainer}>
              {!hasFetchedOpioidIssueCount && (
                <Message
                  icon={<CircularProgress />}
                  message="Loading issues..."
                />
              )}
              {hasFetchedOpioidIssueCount && opioidIssueCount > 0 && (
                <List>
                  <Link to="opioids" className={styles.link}>
                    <ListItem button>
                      <ListItemIcon>
                        <Warning className={styles.warnIcon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${opioidIssueCount} Opioid Issues`}
                      />
                    </ListItem>
                  </Link>
                </List>
              )}
              {hasFetchedOpioidIssueCount && opioidIssueCount === 0 && (
                <div className={styles.issue}>
                  <CheckCircleOutline className={styles.noIssuesIcon} />
                  <div>No Issues</div>
                </div>
              )}
            </div>
          </Paper>
          <Paper classes={{ root: styles.basicInfo }}>
            <div className="">Cases</div>
            <div className={styles.issuesContainer}>
              {!hasFetchedPatient && (
                <Message
                  icon={<CircularProgress />}
                  message="Loading cases..."
                />
              )}
              {hasFetchedPatient && casesCount > 0 && (
                <List>
                  <Link to="cases" className={styles.link}>
                    <ListItem button>
                      <ListItemIcon>
                        <AssignmentInd />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${casesCount} Outstanding Cases`}
                      />
                    </ListItem>
                  </Link>
                </List>
              )}
              {hasFetchedPatient && casesCount === 0 && (
                <div className={styles.issue}>
                  <CheckCircleOutline className={styles.noIssuesIcon} />
                  <div>No Outstanding Cases</div>
                </div>
              )}
            </div>
          </Paper>
        </div>
      )}
    </>
  );
};

Overview.propTypes = {
  patient: ImmutablePropTypes.record,
  displaySummaryCards: PropTypes.bool,
  opioidIssueCount: PropTypes.number,
  hasFetchedOpioidIssueCount: PropTypes.number.isRequired,
  casesCount: PropTypes.number,
  hasFetchedPatient: PropTypes.bool.isRequired,
};

Overview.defaultProps = {
  displaySummaryCards: true,
  patient: new PatientRecord(),
  opioidIssueCount: null,
  casesCount: null,
};

export default Overview;
