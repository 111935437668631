import { Record } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import { booleanFilterDefaults } from 'app/filters/definitions/defaults';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class IsDrugOpioidCombinatorConfig
  extends Record({
    ...booleanFilterDefaults,
    name: 'isDrugOpioidCombinator',
    title: 'Opioid Combinator Drug',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: boolean) => {
      if (isNil(workItem.claim.isDrugOpioidCombinator)) return false;
      return workItem.claim.isDrugOpioidCombinator === value;
    },
  })
  implements IClaimFilterConfiguration<boolean, undefined, boolean, boolean>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new IsDrugOpioidCombinatorConfig();
