import { intersection, isEmpty, map, not, pipe, prop, toLower } from 'ramda';
import { createSelector } from 'reselect';
import { toArray } from '../common/utilities/generic';
import { getUser } from '../users/selectors';

const getUserState = (state) => state.userState;
export const getUserPictureUrl = (state) => getUserState(state).picture;
export const getUserId = (state) => getUserState(state).userId;
export const getCurrentUser = (state) => getUser(state, getUserId(state));

export const isUserAuthenticated = createSelector(
  (state) => getUserState(state).token,
  (token) => token != null && token.idToken != null,
);

export const getUserPermissions = pipe(getUserState, prop('permissions'));

export const userHasPermissions = (state, ...permissions) =>
  pipe(
    getUserPermissions,
    toArray,
    intersection(permissions),
    isEmpty,
    not,
  )(state);

export const getAccessToken = (state) => getUserState(state).token?.accessToken;

export const getIdToken = (state) => getUserState(state).token?.idToken;

export const getUserDisplayName = (state) => {
  const userState = getUserState(state);
  if (userState.name) {
    return userState.name;
  }
  if (userState.email) {
    return userState.email;
  }
  return null;
};

export const getActiveViewTemplateId = (state) =>
  getUserState(state).activeViewTemplateId;
