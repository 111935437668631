import {
  HYDRATING_AUTH,
  HYDRATING_AUTH_DONE,
  HYDRATE,
  HYDRATE_FAIL,
} from './constants';
import { HydrateStateRecord } from './types';

const reducer = (state = new HydrateStateRecord(), action) => {
  switch (action.type) {
    case HYDRATING_AUTH_DONE:
      return state.delete(HYDRATING_AUTH);
    case HYDRATE:
    case HYDRATE_FAIL:
      return state.delete(HYDRATE);
    default:
      return state;
  }
};

export default reducer;
