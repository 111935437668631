import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './RadialFilter.css';

class RadialFilter extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleOnChange = (event) => {
    const { onChange } = this.props;
    onChange(event.target.value === 'true');
  };

  render() {
    const { value } = this.props;
    return (
      <FormControl fullWidth>
        <RadioGroup name="Radial" value={value} onChange={this.handleOnChange}>
          <FormControlLabel
            value
            label="Yes"
            control={
              <Radio classes={{ root: styles.slimRadio }} color="primary" />
            }
          />
          <FormControlLabel
            value={false}
            label="No"
            control={
              <Radio classes={{ root: styles.slimRadio }} color="primary" />
            }
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

export default RadialFilter;
