import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import {
  workItemDetailsQuery,
  filterClaimsQuery,
  workItemMutation,
  workItemStatusesQuery,
} from './queries';

export function fetchWorkItemDetails(id, token) {
  return fetchGraphqlQuery(
    workItemDetailsQuery,
    {
      id,
    },
    token,
  )
    .then(throwIfErrors)
    .then((response) => response.data.node.claim);
}

export function fetchFilteredClaims(
  filter,
  sortBy,
  sortOrder,
  token,
  continuationToken = null,
) {
  return fetchGraphqlQuery(
    filterClaimsQuery,
    { filter, sortBy, sortOrder, continuationToken },
    token,
  )
    .then(throwIfErrors)
    .then((response) => response.data.claims);
}

export function upsertWorkItem(workItem, token) {
  return fetchGraphqlQuery(workItemMutation, { workItem }, token)
    .then(throwIfErrors)
    .then((response) => response.data.upsertWorkItem);
}

export function fetchWorkItemStatuses(token) {
  return fetchGraphqlQuery(workItemStatusesQuery, null, token)
    .then(throwIfErrors)
    .then((response) => response.data.workItemStatuses);
}
