import { Map } from 'immutable';
import getConfiguration from 'app/configuration';
import { IFilterConfiguration } from 'app/filters/types';
import claimFilters from './claim';
import patientFilters from './patient';

const disabledFilters = getConfiguration().DISABLED_FILTERS as string[];

// TODO figure out the typings here
const filterDefinitions = Map(
  claimFilters
    .merge(patientFilters)
    .map(
      (x: any) =>
        x.merge({
          enabled: !disabledFilters.includes(x.name),
        }) as IFilterConfiguration<any, any, any, any>,
    )
    .filter((x) => x.enabled)
    .map((x) => [x.id, x]),
);

export default filterDefinitions;
