import {
  subDays,
  addDays,
  eachDayOfInterval,
  isWeekend,
  format,
  min,
  max,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { Map, OrderedSet } from 'immutable';
import { mapAccum, isNil, sum } from 'ramda';

export const campaigns = [
  {
    id: '1',
    name: '200 MME',
    started: startOfDay(subDays(new Date(), 120)),
    ended: startOfDay(addDays(new Date(), 120)),
    averageVolumeWeekday: 150,
    weekdayVariance: 30,
    averageVolumeWeekend: 60,
    weekendVariance: 10,
  },
  {
    id: '2',
    name: 'Risk of Continued Opioid Abuse',
    started: startOfDay(subDays(new Date(), 90)),
    ended: startOfDay(addDays(new Date(), 30)),
    averageVolumeWeekday: 180,
    weekdayVariance: 40,
    averageVolumeWeekend: 40,
    weekendVariance: 10,
  },
  {
    id: '3',
    name: 'High Dosage Opioid Naive',
    started: startOfDay(subDays(new Date(), 15)),
    ended: startOfDay(addDays(new Date(), 120)),
    averageVolumeWeekday: 120,
    weekdayVariance: 10,
    averageVolumeWeekend: 30,
    weekendVariance: 5,
  },
];

export const capacities = [
  {
    start: startOfDay(subDays(new Date(), 120)),
    end: startOfDay(subDays(new Date(), 81)),
    capacity: 200,
  },
  {
    start: startOfDay(subDays(new Date(), 80)),
    end: startOfDay(addDays(new Date(), 120)),
    capacity: 380,
  },
];

const generateNumber = (average, variance) =>
  Math.floor(Math.random() * variance) + (average - variance);

const campaignDataGenerator = (acc, data) => {
  const date = data.date;
  const campaign = data.campaign;
  const dateIsWeekend = isWeekend(date);

  // calculate opened cases
  const averageVolume = dateIsWeekend
    ? campaign.averageVolumeWeekend
    : campaign.averageVolumeWeekday;
  const variance = dateIsWeekend
    ? campaign.weekendVariance
    : campaign.weekdayVariance;

  const opened = generateNumber(averageVolume, variance);

  // find capacity per campaign
  const campaingsActive = campaigns.filter((x) =>
    isWithinInterval(date, { start: x.started, end: x.ended }),
  ).length;
  const campaignCapacity =
    capacities.find((x) =>
      isWithinInterval(date, { start: x.start, end: x.end }),
    )?.capacity / campaingsActive;

  const capacity = Math.floor(
    generateNumber(campaignCapacity, Math.floor(campaignCapacity * 0.1)),
  );

  // get closed
  const closed = dateIsWeekend
    ? 0
    : acc + opened < capacity
    ? acc + opened
    : capacity;
  const outstanding = acc + opened - closed;

  return [
    outstanding,
    {
      date: date,
      casesOpened: opened,
      casesClosed: closed,
      casesOutstanding: outstanding,
    },
  ];
};

const generateRandomCampaignData = (campaign) =>
  mapAccum(
    campaignDataGenerator,
    0,
    eachDayOfInterval({ start: campaign.started, end: campaign.ended }).map(
      (x) => ({
        campaign: campaign,
        date: x,
      }),
    ),
  );

export const campaignData = new Map(
  campaigns.map((x) => [
    x.id,
    new Map(
      generateRandomCampaignData(x)[1].map((y) => {
        return [format(y.date, 'yyyyMMdd'), y];
      }),
    ),
  ]),
);

export const dataSelector = (campaignIds) => {
  if (isNil(campaignIds) || campaignIds.length === 0) {
    campaignIds = campaigns.map((x) => x.id);
  }

  const campaignDailyData = campaignIds.map((x) => campaignData.get(x));

  const range = {
    start: min(campaigns.map((x) => x.started)),
    end: max(campaigns.map((x) => x.ended)),
  };

  return eachDayOfInterval(range).map((x) => {
    const dayData = campaignDailyData
      .map((d) => d.get(format(x, 'yyyyMMdd')))
      .filter((y) => !isNil(y));

    return {
      date: x,
      casesOpened: sum(dayData.map((y) => y.casesOpened)),
      casesClosed: sum(dayData.map((y) => y.casesClosed)),
      casesOutstanding: sum(dayData.map((y) => y.casesOutstanding)),
    };
  });
};
