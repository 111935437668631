import { combineReducers } from 'redux-immutable';
import { OptimisticRecord } from '../../types';
import comments from './comments';

const reducer = combineReducers(
  {
    comments,
  },
  OptimisticRecord,
);

export default reducer;
