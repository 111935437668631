import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#f4ac00',
      dark: '#f4ac00', // We don't like the darkened version, not reveal™ enough
    },
    secondary: { main: '#2196F3' },
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        paddingBottom: '0',
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: '1.25rem',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'inherit',
        marginRight: '1rem',
      },
    },
  },
});

export default muiTheme;
