import { useDispatch, useSelector } from 'react-redux';
import { getCasesFilters } from 'app/cases/selectors';
import { exportCaseDownloadRequest } from 'app/export/actions';
import { isRunning } from 'app/ui/selectors';
import Export from '../../components/ExportButton';

const CaseExportButton = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getCasesFilters(state));

  const handleClick = () => {
    dispatch(exportCaseDownloadRequest(filters));
  };

  const fetching = useSelector((state) =>
    isRunning(state, exportCaseDownloadRequest()),
  );

  return (
    <Export handleClick={handleClick} disabled={false} isFetching={fetching} />
  );
};

export default CaseExportButton;
