import { List, ListItem, Checkbox, CircularProgress } from '@material-ui/core';
import { OrderedSet, Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from './MultiSelect.css';

class MultiSelect extends Component {
  static propTypes = {
    value: ImmutablePropTypes.setOf(PropTypes.string),
    options: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ),
    isFetching: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    optionRenderer: PropTypes.func,
  };

  static defaultProps = {
    value: new OrderedSet(),
    optionRenderer: (value) => value.text,
    options: Set(),
  };

  handleOnChange = (name, selected) => {
    const { onChange, value } = this.props;
    if (selected) {
      onChange(value.add(name));
    } else {
      onChange(value.delete(name));
    }
  };

  render() {
    const { value, options, isFetching, optionRenderer } = this.props;
    const items = options.map((v) => ({
      value: v,
      selected: value.includes(v),
    }));

    return (
      <Fragment>
        {isFetching && (
          <div className={styles.spinner}>
            <CircularProgress />
          </div>
        )}
        {!isFetching && (
          <List>
            {items.map((item) => (
              <ListItem
                disableGutters
                key={item.value.value}
                button
                onClick={() => this.handleOnChange(item.value, !item.selected)}
                className={styles.listItem}
              >
                <Checkbox
                  color="primary"
                  checked={item.selected}
                  className={styles.checkbox}
                />
                {optionRenderer(item.value)}
              </ListItem>
            ))}
          </List>
        )}
      </Fragment>
    );
  }
}

export default MultiSelect;
