import {
  USER_SIGN_OUT,
  TOKEN_FETCH_START,
  TOKEN_FETCH_SUCCESS,
  TOKEN_FETCH_FAIL,
  TOKEN_REFRESH_RETRY,
  TOKEN_REFRESH_FAIL,
  USER_SIGN_IN,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAIL,
  USER_IS_NOT_AUTHENTICATED,
  USER_INACTIVE,
  USER_INACTIVE_SIGN_OUT,
} from './constants';

export function signIn(code, state) {
  return { type: USER_SIGN_IN, payload: { code, state } };
}

export function signInSuccess(
  idToken,
  accessToken,
  email,
  name,
  picture,
  userId,
  permissions,
) {
  return {
    type: USER_SIGN_IN_SUCCESS,
    payload: {
      idToken,
      accessToken,
      email,
      name,
      picture,
      userId,
      permissions,
    },
  };
}

export function signInFailed(error) {
  return { type: USER_SIGN_IN_FAIL, payload: { error } };
}

export function signOut() {
  return { type: USER_SIGN_OUT, payload: {} };
}

export function userIsInactive(redirect) {
  return { type: USER_INACTIVE, payload: { redirect } };
}

export function userInactiveSignOut() {
  return { type: USER_INACTIVE_SIGN_OUT, payload: {} };
}

export function fetchToken() {
  return { type: TOKEN_FETCH_START, payload: {} };
}

export function fetchTokenSuccess(
  idToken,
  accessToken,
  email,
  name,
  picture,
  userId,
  permissions,
) {
  return {
    type: TOKEN_FETCH_SUCCESS,
    payload: {
      idToken,
      accessToken,
      email,
      name,
      picture,
      userId,
      permissions,
    },
  };
}

export function fetchTokenFail(error) {
  return { type: TOKEN_FETCH_FAIL, payload: { error } };
}

export function refreshTokenRetry(retryCount, error) {
  return { type: TOKEN_REFRESH_RETRY, payload: { retryCount, error } };
}

export function refreshTokenFail(error) {
  return { type: TOKEN_REFRESH_FAIL, payload: { error } };
}

export function userIsNotAuthenticated(redirect) {
  return { type: USER_IS_NOT_AUTHENTICATED, payload: { redirect } };
}
