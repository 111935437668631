import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Call, Check, Delete, Edit } from '@material-ui/icons';
import { format } from 'date-fns';
import { Set, OrderedSet } from 'immutable';
import { any, concat, forEach, isEmpty, isNil, sort } from 'ramda';
import React, { useEffect, useState, forwardRef } from 'react';
import {
  useForm,
  useFieldArray,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import SaveButton from 'app/common/components/SaveButton';
import { PhoneNumberRecord, PhoneNumberSource } from 'app/patient/types';
import styles from './PhoneNumber.css';

const unmaskPhone = (value: string) =>
  value.toString().replace(/\D+/g, '').replace(/\s+/, '');

type PhoneNumberProps = {
  number: string;
};

const PhoneNumber = ({ number }: PhoneNumberProps) => {
  if (isNil(number)) {
    return <span />;
  }
  // Strip it down to just numbers
  let phoneNumber = unmaskPhone(number);

  let formattedNumber = number;

  // Remove a leading 1 if it's there
  if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
    phoneNumber = phoneNumber.slice(1);
  }

  // If it's now a 10-digit number, format it like a phone number
  if (phoneNumber.length === 10) {
    formattedNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3',
    );
  }
  // If it wasn't a known phone format, formattedNumber is still just the plain number
  return <a href={`tel:${number}`}>{formattedNumber}</a>;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const MaskedPhoneNumber = forwardRef<HTMLInputElement, CustomProps>(
  function MaskedPhoneNumber(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /\d/,
        }}
        inputRef={ref}
        onAccept={(value, inputMask) =>
          onChange({
            target: { name: props.name, value: inputMask.unmaskedValue },
          })
        }
        overwrite
      />
    );
  },
);
interface PhoneNumberDialogBaseProps {
  readOnly?: boolean;
  phoneNumbers: PhoneNumberType[];
  isModalOpen: boolean;
  closeModal: () => void;
}
interface ReadOnlyPhoneNumberProps extends PhoneNumberDialogBaseProps {
  onSubmit?: never;
  isSaving?: never;
  error?: never;
}

interface EditablePhoneNumberProps extends PhoneNumberDialogBaseProps {
  onSubmit: (phoneNumbers: Set<PhoneNumberRecord>) => void;
  isSaving: boolean;
  error: Array<{ path: string[]; message: string }>;
}

type PhoneNumberDialogProps =
  | ReadOnlyPhoneNumberProps
  | EditablePhoneNumberProps;

type PhoneNumberType = {
  phoneNumber: string;
  isPrimary: boolean;
  isDeleted: boolean;
  source: PhoneNumberSource;
  addedOn: Date | undefined;
};

type FormValues = {
  phoneNumbers: PhoneNumberType[];
};

const compareByAddedOnDesc = (a: PhoneNumberType, b: PhoneNumberType) => {
  if (a.addedOn === undefined) return 1;
  if (b.addedOn === undefined) return -1;
  return b.addedOn.getTime() - a.addedOn.getTime();
};

const PhoneNumberDialog = ({
  phoneNumbers,
  readOnly = false,
  isModalOpen,
  closeModal,
  onSubmit,
  isSaving,
  error,
}: PhoneNumberDialogProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<FormValues>();

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'phoneNumbers',
  });

  useEffect(() => {
    setValue('phoneNumbers', phoneNumbers);
  }, [phoneNumbers]);

  // TODO refactor error handling, take a look at campaignedit and GraphQlErrorRecord
  let formError: undefined | string;
  if (Array.isArray(error)) {
    forEach((x: any) => {
      if (x.path?.length > 1) {
        const erroredField = OrderedSet<string>(x.path)
          .remove('upsertPatient')
          .remove('patient')
          .remove('phoneNumbers');

        const index = Number.parseInt(erroredField.first(), 10) || null;

        if (isNil(index)) {
          formError = x.message;
        } else {
          const field = erroredField.skip(1).first() as keyof PhoneNumberType;

          setError(`phoneNumbers.${index as number}.${field}`, {
            type: 'custom',
            message: x.message,
          });
        }
      } else {
        formError = x.message;
      }
    }, error);
  } else {
    formError = error;
  }

  useEffect(() => {
    if (isEditing && !isSaving && isEmpty(errors) && isNil(formError)) {
      setIsEditing(false);
    }
  }, [isSaving]);

  const addEmptyRow = () => {
    const data = getValues().phoneNumbers;

    const recordsToRemove = data
      .map((x: PhoneNumberType, i) => {
        if (!isEmpty(x.phoneNumber)) return null;

        return i;
      })
      .filter((x) => !isNil(x)) as number[];

    remove(recordsToRemove);

    append(
      {
        phoneNumber: '',
        isPrimary: false,
        isDeleted: false,
        source: PhoneNumberSource.User,
        addedOn: undefined,
      },
      { shouldFocus: false },
    );
  };

  const handleDelete = (index: number) => {
    const data = getValues().phoneNumbers;

    const { phoneNumber } = data[index];

    if (isNil(phoneNumbers.find((x) => x.phoneNumber === phoneNumber))) {
      remove(index);
    } else {
      update(index, {
        ...data[index],
        isDeleted: true,
      });
    }
  };

  const handlePrimaryChange = (index: number) => {
    getValues().phoneNumbers.forEach((x, i) =>
      update(i, {
        ...x,
        isPrimary: i === index,
      }),
    );
  };

  const handleOnSubmit: SubmitHandler<FormValues> = (data) => {
    if (isNil(onSubmit)) return;

    const toDelete: PhoneNumberType[] = phoneNumbers
      .filter(
        (x) =>
          x.isDeleted !== true &&
          !any(
            (y: PhoneNumberType) => y.phoneNumber === x.phoneNumber,
            data.phoneNumbers,
          ),
      )
      .map((x) => ({
        ...x,
        isDeleted: true,
      }));
    const currentNumbers = data.phoneNumbers.filter(
      (x) => !isEmpty(x.phoneNumber),
    );

    onSubmit(
      Set(concat(currentNumbers, toDelete)).map(
        (x) =>
          new PhoneNumberRecord({
            ...x,
          }),
      ),
    );
  };

  const handleEditClick = () => {
    setIsEditing(true);
    addEmptyRow();
  };

  const handleCancel = () => {
    setValue('phoneNumbers', phoneNumbers);
    setIsEditing(false);
  };

  const handleValidatePhoneNumber = (value: string, index: number) => {
    if (isEmpty(value)) return true;

    if (value.length !== 10) return 'Invalid phone number';

    const isRepeated = getValues().phoneNumbers.some((item, i) => {
      return (
        i !== index && item.phoneNumber === value && item.isDeleted !== true
      );
    });

    if (isRepeated) return 'Phone number must be unique';
    return true;
  };

  const isInEditMode = isEditing && !readOnly;

  return (
    <Dialog open={isModalOpen} onClose={() => closeModal}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>
          <div className={styles.dialogTitle}>
            <div className={styles.title}>Phone Numbers</div>
            {!readOnly && (
              <div className={styles.quickActions}>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<Edit />}
                  disabled={isEditing}
                  onClick={() => handleEditClick()}
                >
                  Update
                </Button>
              </div>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableCell>Primary</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Added Date</TableCell>
              {isInEditMode && <TableCell />}
            </TableHead>
            <TableBody>
              {sort(
                compareByAddedOnDesc,
                fields.map((x, index) => ({
                  ...x,
                  fieldIndex: index,
                })),
              ).map(
                (n) =>
                  !n.isDeleted && (
                    <TableRow>
                      <TableCell>
                        {isInEditMode && (
                          <Radio
                            checked={n.isPrimary}
                            name="primaryNumber"
                            disabled={isEmpty(n.phoneNumber)}
                            onChange={() => handlePrimaryChange(n.fieldIndex)}
                          />
                        )}
                        {!isEditing && n.isPrimary && <Check />}
                      </TableCell>
                      <TableCell>
                        {isInEditMode &&
                          n.source === PhoneNumberSource.User && (
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    inputComponent: MaskedPhoneNumber as any,
                                  }}
                                  classes={{
                                    root: styles.input,
                                  }}
                                  className={styles.phoneNumberInput}
                                  variant="outlined"
                                  error={
                                    !isNil(errors.phoneNumbers?.[n.fieldIndex])
                                  }
                                  helperText={
                                    errors.phoneNumbers?.[n.fieldIndex]
                                      ?.phoneNumber?.message ||
                                    errors.phoneNumbers?.[n.fieldIndex]?.message
                                  }
                                  onChange={(e) => {
                                    field.onChange(e);
                                    addEmptyRow();
                                  }}
                                />
                              )}
                              name={`phoneNumbers.${n.fieldIndex}.phoneNumber`}
                              rules={{
                                validate: (value) =>
                                  handleValidatePhoneNumber(
                                    value,
                                    n.fieldIndex,
                                  ),
                              }}
                              control={control}
                              defaultValue={n?.phoneNumber}
                            />
                          )}
                        {(!isEditing ||
                          n.source === PhoneNumberSource.Eligibility) && (
                          <PhoneNumber number={n.phoneNumber} />
                        )}
                      </TableCell>
                      <TableCell>{n.source}</TableCell>
                      <TableCell>
                        {isNil(n.addedOn)
                          ? ''
                          : format(n.addedOn, 'MM/dd/yyyy')}
                      </TableCell>
                      {isInEditMode && (
                        <TableCell>
                          {n.source === PhoneNumberSource.User && (
                            <IconButton
                              onClick={() => handleDelete(n.fieldIndex)}
                              disabled={isEmpty(n.phoneNumber)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
          {isInEditMode && formError && (
            <div className={styles.formErrorText}>{formError}</div>
          )}
        </DialogContent>
        <DialogActions>
          {isInEditMode && !isNil(isSaving) && (
            <>
              <Button disabled={isSaving} onClick={() => handleCancel()}>
                Cancel
              </Button>
              <SaveButton isSaving={isSaving} type="submit">
                Save
              </SaveButton>
            </>
          )}
          {!isEditing && <Button onClick={() => closeModal()}>Close</Button>}
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface MultiPhoneNumberBaseProps {
  value: PhoneNumberType[];
  readOnly?: boolean;
}

interface ReadOnlyProps extends MultiPhoneNumberBaseProps {
  onSubmit?: never;
  isSaving?: never;
  errors?: never;
}

interface EditableProps extends MultiPhoneNumberBaseProps {
  onSubmit: (phoneNumbers: Set<PhoneNumberRecord>) => void;
  isSaving: boolean;
  errors: Array<{ path: string[]; message: string }>;
}

type MultiPhoneNumberComponentProps = ReadOnlyProps | EditableProps;

export const MultiPhoneNumber = ({
  value,
  readOnly = false,
  onSubmit = () => {},
  isSaving = false,
  errors = [],
}: MultiPhoneNumberComponentProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const primaryPhone = value?.find((n) => n.isPrimary);

  return (
    <>
      <Input
        value={primaryPhone?.phoneNumber}
        inputComponent={MaskedPhoneNumber as any}
        classes={{
          root: styles.input,
          input: styles.pointer,
        }}
        onClick={(e: any) => {
          if (e.target.value !== undefined) {
            setModalOpen(true);
          }
        }}
        readOnly
        endAdornment={
          <InputAdornment position="end">
            {primaryPhone?.phoneNumber && (
              <a href={`tel:${primaryPhone?.phoneNumber}`}>
                <Call fontSize="small" color="action" />
              </a>
            )}
            {!primaryPhone?.phoneNumber && (
              <Call fontSize="small" color="disabled" />
            )}
          </InputAdornment>
        }
      />
      <PhoneNumberDialog
        readOnly={readOnly}
        phoneNumbers={value}
        isModalOpen={isModalOpen}
        closeModal={() => setModalOpen(false)}
        onSubmit={onSubmit}
        isSaving={isSaving}
        error={errors}
      />
    </>
  );
};

export default PhoneNumber;
