import { pharmacyFragmentFactory } from 'app/pharmacies/queries';
import graphql from '../common/utilities/graphql';
import { patientFragmentFactory } from '../patient/queries';
import { prescriberFragmentFactory } from '../prescribers/queries';
import { claimFragmentFactory } from '../workitems/queries';

export const targetFragment = graphql`
  ${patientFragmentFactory('patientFragment')}
  ${prescriberFragmentFactory('prescriberTargetFragment')}
  ${pharmacyFragmentFactory('pharmacyFragment')}
  fragment targetFragment on CaseContact {
    ...patientFragment
    ...prescriberTargetFragment
    ...pharmacyFragment
    __typename
  }
`;

export const issueFragment = graphql`
  fragment issueFragment on IssueType {
    id
    number
    createdDate
    campaign {
      id
      description
      name
      target
      entityId
      version
    }
    supportingDocuments {
      ... on ClaimType {
        ...claimFragment
        workItem {
          id
        }
      }
      ... on PatientType {
        ...patientFragment
      }
      __typename
    }
    target {
      ...targetFragment
    }
    contact {
      ...targetFragment
    }
    effectiveDates {
      start
      end
    }
  }
`;

export const fetchIssuesQuery = graphql`
  ${issueFragment}
  ${targetFragment}
  ${claimFragmentFactory('claimFragment')}

  query filterIssuesQuery(
    $filter: IssueFilterInputType!
    $sortBy: IssueSortOrderEnum = CreatedDate
    $sortOrder: SortOrderEnum = ASC
    $continuationToken: String
  ) {
    issues(
      filter: $filter
      continuationToken: $continuationToken
      take: 50
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      continuationToken
      results {
        ...issueFragment
      }
    }
  }
`;

// TODO:  combine into one query
export const searchQuery = graphql`
  query autocomplete($search: String) {
    autocomplete(search: $search, autocompleteTypes: [PATIENTS, PRESCRIBERS]) {
      __typename
      ... on PatientType {
        id
        entityId
        name
        patientId
        dateOfBirth
      }
      ... on PrescriberType {
        id
        entityId
        name
        npi
      }
    }
  }
`;

export const upsertIssueMutation = graphql`
  ${issueFragment}
  ${targetFragment}
  ${claimFragmentFactory('claimFragment')}
  mutation ($issue: IssueInputType) {
    upsertIssue(issue: $issue) {
      ...issueFragment
    }
  }
`;
