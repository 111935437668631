import { Map, Record, Set } from 'immutable';
import { schema } from 'normalizr';
import { InteractionRecord } from 'app/interactions/types';
import { PharmacyRecord } from 'app/pharmacies/types';
import { PrescriberRecord } from 'app/prescribers/types';
import { SessionRecord } from 'app/session/types';
import { CampaignRecord, campaignSchema } from '../campaigns/types';
import { issueSchema, targetSchema } from '../issues/types';
import { PatientRecord, patientSchema } from '../patient/types';
import { userSchema } from '../users/types';

export class CaseRecord extends Record({
  id: undefined as string | undefined,
  number: undefined as string | undefined,
  campaign: undefined as CampaignRecord | string | undefined,
  issues: Set<string>() as Set<string> | IssueRecord[] | undefined,
  status: undefined as string | undefined,
  interactions: Set<string>() as
    | Set<string>
    | InteractionRecord[]
    | string[]
    | undefined,
  caseSessions: Set<string>() as
    | Set<string>
    | SessionRecord[]
    | string[]
    | undefined,
  target: undefined as
    | PatientRecord
    | PrescriberRecord
    | PharmacyRecord
    | undefined,
  contact: undefined as
    | PatientRecord
    | PrescriberRecord
    | PharmacyRecord
    | undefined,
  etag: undefined as string | undefined,
  dateOpened: undefined as Date | undefined,
  dateClosed: undefined as Date | undefined,
  assignee: undefined as string | undefined,
  followUpDate: undefined as Date | undefined,
}) {}

export class BulkCreateCasesRecord extends Record({
  campaignEntityId: undefined as CampaignRecord | string | undefined,
  patients: undefined as string[] | undefined,
}) {}

export class SupportingDocumentRecord extends Record({
  id: undefined as string | undefined,
  filledDate: undefined as Date | undefined,
  __typename: undefined as string | undefined,
}) {}

export class IssueRecord extends Record({
  supportingDocuments: undefined as SupportingDocumentRecord[] | undefined,
}) {}

export class CasesFilterStateRecord extends Record({
  assignees: Set<string>(),
  campaigns: Set<string>(),
  statuses: Set<string>(),
}) {}

export class CasesStateRecord extends Record({
  cases: Map(),
  // TODO: temporary, simple fix, might need to abstract out later.
  filters: new CasesFilterStateRecord({
    assignees: Set<string>(),
    statuses: Set<string>(['OPEN', 'INPROGRESS']),
  }),
  optimistic: Map(),
}) {}

export const caseSchema = new schema.Entity('cases', {
  issues: new schema.Array(issueSchema),
  target: targetSchema,
  campaign: campaignSchema,
  assignee: userSchema,
  contact: targetSchema,
});

export const bulkCreateCasesSchema = new schema.Entity('bulkCreateCases', {
  cases: new schema.Array(new schema.Entity('cases')),
});

issueSchema?.define({
  campaign: campaignSchema,
});

export const continuationToken = new schema.Entity('continuationToken');

export const casesQuerySchema = new schema.Array(caseSchema);

patientSchema?.define({
  cases: new schema.Array(caseSchema),
});
