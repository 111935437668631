import { Set } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useSelector } from 'react-redux';
import Table from '../../../../common/components/Table';
import { graphqlSelector } from '../../../../common/hooks/useQuery';
import graphql from '../../../../common/utilities/graphql';
import { isRunning } from '../../../../ui/selectors';
import { fetchPatient } from '../../../actions';
import styles from './CaseList.css';
import columnDefinitions from './columns';

const CaseList = ({ cases, isFetching }) => (
  <div className={styles.container}>
    <Table
      isSortEnabled={false}
      columnDefinitions={columnDefinitions}
      data={cases}
      isFetching={isFetching}
    />
  </div>
);

CaseList.propTypes = {
  cases: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  isFetching: PropTypes.bool.isRequired,
};

CaseList.defaultProps = {
  cases: new Set(),
};

const CaseListContainer = ({ caseIds, id }) => {
  const caseQuery = graphql`
    query cases($ids: [ID]!) {
      cases(ids: $ids) {
        id
        number
        dateOpened
        followUpDate
        campaign {
          name
        }
        status
        interactions {
          id
          dateContacted
        }
        target {
          ... on PatientType {
            id
            patientId
            followUpDate
          }
          ... on PrescriberType {
            id
            npi
          }
          __typename
        }
      }
    }
  `;

  const { cases } = useSelector((state) =>
    graphqlSelector(state, caseQuery, { ids: caseIds }),
  );
  const isFetching = useSelector((state) => isRunning(state, fetchPatient(id)));

  return <CaseList cases={new Set(cases)} isFetching={isFetching} />;
};

CaseListContainer.propTypes = {
  id: PropTypes.string.isRequired,
  caseIds: ImmutablePropTypes.setOf(PropTypes.string),
};

CaseListContainer.defaultProps = {
  caseIds: new Set(),
};

export default CaseListContainer;
