import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Table from '../../../../common/components/Table';
import styles from './PatientClaimsHistory.css';

const PatientClaimsHistory = ({
  columnDefinitions,
  errorFetching,
  isFetchingClaims,
  claims,
  dateRange,
  hasAdditionalClaims,
  scrollToIndex,
  hasFetchedDetails,
  hasFetchedClaims,
  patientId,
}) => (
  <div className={styles.container}>
    {!errorFetching && !isFetchingClaims && !hasFetchedClaims && (
      <div className={styles.listMessage}>
        {hasFetchedDetails && !patientId && (
          <span>There is no patient associated with this claim.</span>
        )}
      </div>
    )}
    {!errorFetching && (isFetchingClaims || hasFetchedClaims) && (
      <div className={styles.table}>
        <Table
          data={!isFetchingClaims ? claims : null}
          scrollToIndex={scrollToIndex}
          columnDefinitions={columnDefinitions}
          loadMoreRows={() => {}}
          hasNextPage={false}
          isFetching={isFetchingClaims}
          isSortEnabled={false}
        />
      </div>
    )}
    {!errorFetching && (isFetchingClaims || hasFetchedClaims) && (
      <div className={styles.footer}>
        Showing patient history between {format(dateRange.start, 'MM/dd/yyyy')}{' '}
        &ndash; {format(dateRange.end, 'MM/dd/yyyy')}.{' '}
        {hasAdditionalClaims && (
          <span>There may be additional results not currently displayed.</span>
        )}
      </div>
    )}
    {errorFetching && (
      <div className={styles.listLoadError}>
        There was an unexpected error loading the claims.
      </div>
    )}
  </div>
);

PatientClaimsHistory.propTypes = {
  claims: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      claim: ImmutablePropTypes.record,
    }).isRequired,
  ),
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  errorFetching: PropTypes.object,
  isFetchingClaims: PropTypes.bool.isRequired,
  dateRange: ImmutablePropTypes.recordOf({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }).isRequired,
  hasAdditionalClaims: PropTypes.bool.isRequired,
  scrollToIndex: PropTypes.number,
  hasFetchedDetails: PropTypes.bool.isRequired,
  hasFetchedClaims: PropTypes.bool.isRequired,
  patientId: PropTypes.string,
};

export default PatientClaimsHistory;
