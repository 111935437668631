import PropTypes from 'prop-types';
import React from 'react';
import styles from './FilterSummary.css';

const FilterSummary = ({ summary }) => (
  <div className={styles.value}>{summary}</div>
);

FilterSummary.propTypes = {
  summary: PropTypes.string.isRequired,
};

export default FilterSummary;
