import { List, Map, Record } from 'immutable';
import { HistoricalSourceEnum } from 'app/common/constants';

export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export class ErrorRecord extends Record({
  message: '',
  name: '',
  stack: '',
  code: '',
}) {}

export class GraphQlErrorRecord extends Record({
  message: '',
  path: List<string>(),
  locations: List<Map<string, number>>(),
  extensions: Map<string, string>(),
}) {}

export const NetworkErrorRecord = Record({
  status: null,
});

export class Interval extends Record({
  start: new Date(),
  end: new Date(),
}) {}

export const SortingRecord = Record({
  sortBy: null,
  sortDirection: SortDirection.Descending,
});

export class PhoneNumberRecord extends Record({
  phoneNumber: undefined as string | undefined,
  isPrimary: false,
  source: undefined as HistoricalSourceEnum | undefined,
  addedOn: undefined as Date | undefined,
}) {}

export class OptimisticUpdateRecord extends Record({
  optimisticId: null,
  updates: Map<string, any>(),
}) {}

export enum GraphqlTypes {
  Patient = 'PatientType',
  Pharmacy = 'PharmacyType',
  Prescriber = 'PrescriberType',
  Case = 'CaseType',
  Claim = 'ClaimType',
  nonNull = 'NON_NULL',
  list = 'LIST',
  dateRange = 'DateRangeInputGraphType',
  boolean = 'Boolean',
  claimsFilter = 'ClaimFilterType',
  patientFilter = 'PatientFilterInputType',
  claimStatusEnum = 'ClaimStatusEnum[]',
  diseaseStatusEnum = 'DiseaseStatusEnum[]',
  workitemStatusEnum = 'WorkItemStatusTypeEnum[]',
  quantityLimitEditTypeEnum = 'QuantityLimitEditTypeEnum[]',
  multiSourceCodeEnum = 'MultiSourceCodeEnum[]',
  licsLevelEnum = 'LICSLevelTypeEnum[]',
  pharmacyTypeEnum = 'PharmacyTypeEnum[]',
  stringArray = 'String[]',
  matchesPaidClaim = 'MatchesPaidClaimInputType',
  matchStrings = 'MatchStringsInputType',
  lineOfBusinessEnum = 'LineOfBusinessEnum[]',
  numericComparison = 'NumericComparisonInputType',
  numericRange = 'NumericRangeInputType',
  textComparison = 'TextComparisonInputType',
  initialOpioidSupply = 'InitialOpioidSupplyInputType',
  string = 'String',
  cobIndicatorEnum = 'CoordinationOfBenefitsIndicatorEnum[]',
  dosingSchedule = 'DosingScheduleFilterInputType',
  lateToFill = 'LateToFillFilterInputType',
}
