import React from 'react';
import PhoneNumber from '../../../common/components/PhoneNumber/PhoneNumber';
import Prescriber from '../../../common/images/prescriber.svg';
import BasicInfoItem from '../../../patient/components/BasicInfoItem/BasicInfoItem';
import styles from './PrescriberDetails.css';

const PrescriberOverview = ({ prescriber }) => (
  <div className={styles.container}>
    <Prescriber className={styles.basicInfoIcon} />
    <div className={styles.basicPrimaryInfo}>Prescriber</div>
    <BasicInfoItem label="Name" value={prescriber.name} />
    <BasicInfoItem label="NPI" value={prescriber.npi} />
    <BasicInfoItem label="Organization" value={prescriber.organizationName} />
    <BasicInfoItem
      label="Primary Specialty"
      value={prescriber.primarySpecialty}
    />
    <BasicInfoItem
      label="Phone Number"
      value={<PhoneNumber number={prescriber.phoneNumber} />}
    ></BasicInfoItem>
  </div>
);

// TODO:  Prop types
PrescriberOverview.propTypes = {};
PrescriberOverview.defaultProps = {};

export default PrescriberOverview;
