import { Set, Map } from 'immutable';
import { IssueFilterType } from 'app/filters/constants';
import getFilterConfiguration from 'app/filters/definitions';
import {
  VIEWTEMPLATES_FETCH_START,
  VIEWTEMPLATES_FETCH_SUCCESS,
  VIEWTEMPLATES_FETCH_FAIL,
  VIEWTEMPLATE_SAVE_SUCCESS,
  VIEWTEMPLATE_QUICKSAVE_SUCCESS,
  VIEWTEMPLATE_CREATE_SUCCESS,
  VIEWTEMPLATE_FETCH_COUNT_SUCCESS,
  VIEWTEMPLATE_DELETE_SUCCESS,
} from './constants';
import {
  ViewTemplatesStateRecord,
  ViewTemplateRecord,
  FilterRecord,
} from './types';

const config = getFilterConfiguration;

const reducer = (state = new ViewTemplatesStateRecord(), action) => {
  switch (action.type) {
    case VIEWTEMPLATES_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case VIEWTEMPLATES_FETCH_SUCCESS: {
      const update = new Map(action.payload.data.entities.viewTemplates).map(
        (viewTemplate) => {
          const initialRecord =
            state.viewTemplates.get(viewTemplate.id) ||
            new ViewTemplateRecord();
          return initialRecord.merge({
            ...viewTemplate,
            tags: new Set(viewTemplate.tags),
            filters: new Set(
              viewTemplate.filters.map(
                (filter) =>
                  new FilterRecord({
                    name: filter.name,
                    value: config
                      .filter((x) => x.targetType === IssueFilterType.Claim)
                      .find((x) => x.name === filter.name)
                      .storedValueConstructor(filter.value),
                    options: filter.options,
                    valueType: filter.valueType,
                  }),
              ),
            ),
          });
        },
      );

      return state.merge({
        viewTemplates: update,
        isFetching: false,
      });
    }
    case VIEWTEMPLATES_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    case VIEWTEMPLATE_QUICKSAVE_SUCCESS:
    case VIEWTEMPLATE_SAVE_SUCCESS:
    case VIEWTEMPLATE_CREATE_SUCCESS: {
      const initialRecord =
        state.viewTemplates.get(action.payload.viewTemplate.id) ||
        new ViewTemplateRecord();

      const filters = new Set(
        action.payload.viewTemplate.filters.map(
          (filter) =>
            new FilterRecord({
              name: filter.name,
              value: config
                .filter((x) => x.targetType === IssueFilterType.Claim)
                .find((x) => x.name === filter.name)
                .storedValueConstructor(filter.value),
              valueType: filter.valueType,
            }),
        ),
      );

      const resetCount = !initialRecord.filters.equals(filters);
      return state.merge({
        viewTemplates: state.viewTemplates.merge({
          [action.payload.viewTemplate.id]: initialRecord.merge({
            ...action.payload.viewTemplate,
            tags:
              action.payload.viewTemplate.tags !== undefined
                ? new Set(action.payload.viewTemplate.tags)
                : initialRecord.tags,
            filters,
            count: resetCount ? null : initialRecord.count,
          }),
        }),
      });
    }
    case VIEWTEMPLATE_FETCH_COUNT_SUCCESS:
      return state.merge({
        viewTemplates: state.viewTemplates.merge({
          [action.payload.id]: state.viewTemplates
            .get(action.payload.id)
            .merge({
              ...action.payload,
            }),
        }),
      });
    case VIEWTEMPLATE_DELETE_SUCCESS: {
      return state.merge({
        viewTemplates: state.viewTemplates.delete(action.payload.id),
      });
    }
    default:
      return state;
  }
};

export default reducer;
