import graphql from 'app/common/utilities/graphql';
import { targetFragment } from 'app/issues/queries';

const caseFragmanent = (name: string) => graphql`
${targetFragment}
fragment ${name} on CaseType {
  id
  number
  status
  dateOpened
  followUpDate
  contact {
    ...targetFragment
  }
  target {
    __typename
    ... on PatientType{
      id
      name
      patientId
      gender
      dateOfBirth
      followUpDate
      phoneNumbers {
        phoneNumber
        isPrimary
        addedOn
        source
      }
      hasActiveCancerDiagnosis
      cancerDiagnoses {
        start
        end
      }
      eligibility {
        eligibilityMatchingKey
        startDate
        termDate
        eligibilityCarrier
        eligibilityForGroups {
          group
          eligibilities {
            eligibilityStartDate
            eligibilityTermDate
          }
        }
      }
    }
    ... on PrescriberType{
      id
      name
      phoneNumber
      npi
      organizationName
      primarySpecialty
    }
  }
  campaign {
    id
    name
    prefix
    contact
    target
    caseWorkflowDefinitionId
    interactionFieldDefinitions {
      name
      type
      options
      level
    }
  }
  interactions {
    id
    dateContacted
    status
    user {
      id
      name
      email
      pictureUrl
    }
    contact {
      ...targetFragment
    }
  }
  issues {
    id
    supportingDocuments {
      ... on ClaimType{
        workItem {
          id
        }
        claimStatus
        adjudicatedDate
        claimNumber
        drugName
        rxNumber
        filledDate
        quantityDispensed
        daysSupply
        copay
        rejectCodes
        rejectMessages
        ndc
        gpi
        rxcui
        isDrugProtectedClass
        isDrugCompound
        isDrugOverTheCounter
        multiSourceCode
        lineOfBusiness
        coordinationOfBenefitsIndicator
        carrier
        account
        group
        benefitContractId
        licsLevel
        isDrugOnFormulary
        coverageStatusSchedule
        tier
        formularyTier
        patient {
          id
          name
          patientId
          gender
          dateOfBirth
          phoneNumbers {
            phoneNumber
            isPrimary
            addedOn
            source
          }
          __typename
        }
        patientId
        patientName
        patientAge
        patientDob
        eligibilityEffectiveDate
        eligibilityTerminationDate
        isPatientInPlanTransition
        isPatientInLongTermCare
        patientHasCancer
        pharmacyId
        pharmacyName
        pharmacyAddress1
        pharmacyCity
        pharmacyState
        pharmacyPhone
        pharmacyType
        prescriberNpi
        pharmacy{
          id
          name
          entityId
          npi
          __typename
        }
        quantityLimitType
        quantityLimitAmount
        quantityLimitDays
        quantityLimitPerDay
        quantityPreviouslyFilledInLimitWindow
        quantityLimitPerDayExceeded
        daySupplyLimit
        daySupplyLimitExceeded
        formularyRequiresPriorAuth
        formularyPriorAuthTypeCode
        formularyPriorAuthGroup
        hasPriorAuth
        priorAuthNumbers
        stepTherapyRequired
        stepTherapyType
        prescriber {
          id
          entityId
          name
          npi
          __typename
        }
        stepTherapyGroups {
          step
          description
        }
        drugContainsOpioid
        hasOpioidCombinatorImpact
        isPatientOpioidNaive
        dailyMorphineMilligramEquivalent
        patientTotalMorphineMilligramEquivalent
        patientPotentialTotalMorphineMilligramEquivalent
        historicalOpioidPrescriberCount
        historicalOpioidPharmacyCount
        diagnosisCodes {
          code
          description
        }
        seniorSavingsModel {
          exceedsCopay
          maxCopay
        }
        drugLists {
          name
        }
        isPatientEligible
        diseaseStates
        id
      }
      ... on PatientType{
        id
        patientId
        name
        gender
        dateOfBirth
        phoneNumbers {
          phoneNumber
          isPrimary
          addedOn
          source
        }
        hasActiveCancerDiagnosis
        eligibility {
          patientNumber
          eligibilityMatchingKey
          startDate
          termDate
          eligibilityCarrier
          eligibilityForGroups {
            group
            eligibilities {
              eligibilityStartDate
              eligibilityTermDate
            }
          }
        }
        cancerDiagnoses {
          start
          end
        }
      }
      __typename
    }
  }
}
`;

export const outreachQuery = graphql`
  ${caseFragmanent('caseFragment')}
  query session($id: ID) {
    session(id: $id) {
      id
      status
      sessionStart
      interactions {
        id
        status
        dateContacted
        contact {
          ...targetFragment
          __typename
        }
        cases {
          ...caseFragment
        }
        suggestedCases {
          ...caseFragment
        }
        responses {
          name
          value
          caseId
          level
        }
      }
      primaryCase {
        ...caseFragment
      }
      secondaryCases {
        ...caseFragment
      }
    }
  }
`;
