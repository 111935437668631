import { Record } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import { booleanFilterDefaults } from 'app/filters/definitions/defaults';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class IsDrugOverTheCounterConfig
  extends Record({
    ...booleanFilterDefaults,
    name: 'isDrugOverTheCounter',
    title: 'OTC Drug',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: boolean) => {
      if (isNil(workItem.claim.isDrugOverTheCounter)) return false;
      return workItem.claim.isDrugOverTheCounter === value;
    },
  })
  implements IClaimFilterConfiguration<boolean, undefined, boolean, boolean>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new IsDrugOverTheCounterConfig();
