import { Button, Paper, Tab, Tabs, Tooltip } from '@material-ui/core';
import {
  Check,
  LocalCafe,
  Phone,
  Error as ErrorIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { RouteComponentProps, Router, useLocation } from '@reach/router';
import { List } from 'immutable';
import {
  defaultTo,
  find,
  flatten,
  isNil,
  map,
  nth,
  pipe,
  prop,
  filter,
  props,
  without,
  isNotNil,
} from 'ramda';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CaseRecord } from 'app/cases/types';
import { GraphqlTypes } from 'app/common/types';
import { getActiveTabFromLocation } from 'app/common/utilities/generic';
import { InteractionRecord, InteractionStatus } from 'app/interactions/types';
import OutreachSession from 'app/session/components/OutreachSession/OutreachSession';
import { SessionRecord } from 'app/session/types';
import CaseDetails from '../../../cases/components/CaseDetails';
import Message from '../../../common/components/Message';
import UnexpectedError from '../../../common/components/UnexpectedError/UnexpectedError';
import SupportAgent from '../../../common/images/supportAgent.svg';
import { fetchPatientDrugOverview } from '../../../patient/actions';
import PatientOverview from '../../../patient/components/PatientProfile/Overview';
import { PatientRecord } from '../../../patient/types';
import WorkItemDetails from '../../../workitems/components/WorkItemDetailView/WorkItemDetails/WorkItemDetails';
import { WorkItemRecord } from '../../../workitems/types';
import styles from './Outreach.css';

type EndOfQueueProps = {
  onRefresh: () => void;
  isFetching: boolean;
};

const EndOfQueue = ({ onRefresh, isFetching }: EndOfQueueProps) => {
  return (
    <Message
      icon={<LocalCafe />}
      message={
        <>
          <p>
            Looks like there are no more interactions for you to make. Nice job!
          </p>
          <p>
            If this seems in error, double check that you are properly assigned
            to campaigns or click the button below to refresh.
          </p>
          <Button variant="contained" onClick={onRefresh} disabled={isFetching}>
            Refresh
          </Button>
        </>
      }
    />
  );
};

interface PatientSupportingDocumentProps extends RouteComponentProps {
  patient: PatientRecord;
  hasFetchedPatient: boolean;
}

const PatientSupportingDocument = ({
  patient,
  hasFetchedPatient,
}: PatientSupportingDocumentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNotNil(patient?.id) && isNotNil(patient?.patientId)) {
      dispatch(fetchPatientDrugOverview(patient.id, patient.patientId));
    }
  }, [patient.id]);

  return (
    <div className={styles.patientOverviewContainer}>
      <PatientOverview
        className={styles.patientOverviewContainer}
        hasFetchedPatient={hasFetchedPatient}
        patient={patient}
        displaySummaryCards={false}
      />
    </div>
  );
};

type QuickStatProps = {
  isFetching: boolean;
  value: string;
  icon: ReactNode;
  tooltipText: string;
  error: boolean;
};

const QuickStat = ({
  isFetching,
  value,
  icon,
  tooltipText,
  error,
}: QuickStatProps) => (
  <Tooltip arrow title={tooltipText} placement="bottom">
    <Paper classes={{ root: styles.headerStat }}>
      {icon}
      <div>
        {isFetching && <Skeleton variant="rect" width="1rem" height="20" />}
        {!isFetching && !error && value}
        {!isFetching && error && (
          <Tooltip title="Unexpected error while obtaining stats">
            <ErrorIcon style={{ fill: '#f72c2c', opacity: 0.8 }} />
          </Tooltip>
        )}
      </div>
    </Paper>
  </Tooltip>
);

interface LayoutProps extends RouteComponentProps {
  children: ReactNode;
  session: SessionRecord;
  onComplete: () => void;
  activeCase: string;
  onChangeActiveCase: (caseItem: CaseRecord) => void;
  onSkip: () => void;
  isCompleting: boolean;
  isSkipping: boolean;
  isLoading: boolean;
  hasFetched: boolean;
  queueStats: any;
  areStatsLoading: boolean;
  errorOnSkip: boolean;
  errorOnComplete: boolean;
  errorOnQueueStats: boolean;
  disableDetailsTab: boolean;
}

const Layout = ({
  children,
  session,
  navigate,
  onComplete,
  activeCase,
  onChangeActiveCase,
  onSkip,
  isCompleting,
  isSkipping,
  isLoading,
  hasFetched,
  queueStats,
  areStatsLoading,
  errorOnSkip,
  errorOnComplete,
  errorOnQueueStats,
  disableDetailsTab,
}: LayoutProps) => {
  const location = useLocation();
  if (isNil(navigate)) throw new Error('Prop navigate is null');
  const tabs = ['./', 'details'];
  const activeTab = getActiveTabFromLocation(tabs, location);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <QuickStat
                isFetching={isLoading || areStatsLoading}
                icon={<Phone />}
                value={queueStats.outstandingCount}
                tooltipText="Outstanding Interactions"
                error={errorOnQueueStats}
              />
              <QuickStat
                isFetching={isLoading || areStatsLoading}
                icon={<Check />}
                value={queueStats.completedCount}
                tooltipText="Completed Interactions"
                error={errorOnQueueStats}
              />
              <QuickStat
                isFetching={isLoading || areStatsLoading}
                icon={<SupportAgent />}
                value={queueStats.activeRepresentativesCount}
                tooltipText="Active Representatives"
                error={errorOnQueueStats}
              />
            </div>
            <div className={styles.navigation}>
              <Paper elevation={3}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={activeTab}
                  onChange={(e, v) => navigate(v)}
                >
                  <Tab
                    classes={{ root: styles.tabRoot }}
                    label="Overview"
                    value="./"
                  />
                  <Tab
                    classes={{ root: styles.tabRoot }}
                    label="Details"
                    value="details"
                    disabled={disableDetailsTab}
                  />
                </Tabs>
              </Paper>
            </div>
          </div>
        </div>
        {children}
      </div>
      <div className={styles.rightContainer}>
        <Paper classes={{ root: styles.interactionPanel }}>
          <div className={styles.interactionContainer}>
            <OutreachSession
              session={session}
              activeCase={activeCase}
              onCancel={onSkip}
              isCanceling={isSkipping}
              cancelText="Skip"
              skipable={true}
              onChangeActiveCase={onChangeActiveCase}
              isCompleting={isCompleting}
              isLoading={isLoading}
              hasFetched={hasFetched}
              onComplete={onComplete}
              isSavingErrored={errorOnComplete || errorOnSkip}
              savingErrors={[]}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

type OutreachProps = {
  activeCase: string;
  onNext: () => void;
  onSkip: () => void;
  onChangeActiveCase: (caseItem: CaseRecord) => void;
  session: SessionRecord;
  isSkipping: boolean;
  isCompleting: boolean;
  isLoading: boolean;
  hasFetched: boolean;
  queueStats: any;
  areStatsLoading: boolean;
  errorOnSkip: boolean;
  errorOnComplete: boolean;
  errorFetchingNextInteraction: boolean;
  errorOnQueueStats: boolean;
  onComplete: () => void;
};

const Outreach = ({
  activeCase,
  onNext,
  onSkip,
  onChangeActiveCase,
  session,
  isSkipping,
  isCompleting,
  isLoading,
  hasFetched,
  queueStats,
  areStatsLoading,
  errorOnSkip,
  errorOnComplete,
  errorFetchingNextInteraction,
  errorOnQueueStats,
  onComplete,
}: OutreachProps) => {
  // Leaving this search in
  // This is duped

  const filterInProgressInteractions = filter<InteractionRecord>(
    (interaction) =>
      interaction.status === InteractionStatus.InProgress ||
      interaction.status === InteractionStatus.Created,
  );

  const interaction: InteractionRecord = pipe(
    defaultTo([]) as any,
    filterInProgressInteractions as any,
    nth(0) as any,
  )(session?.interactions as InteractionRecord[]) as InteractionRecord;

  const findActiveCase = (): CaseRecord | null => {
    if (
      interaction &&
      interaction.suggestedCases &&
      (interaction.suggestedCases as CaseRecord[]).length > 0
    ) {
      const caseIndex = (interaction.suggestedCases as CaseRecord[]).findIndex(
        (x) => x.id === activeCase,
      );
      if (caseIndex > -1) {
        return (interaction.suggestedCases as CaseRecord[])[caseIndex];
      }
    }
    if (
      interaction &&
      interaction.cases &&
      (interaction.cases as CaseRecord[]).length > 0
    ) {
      const caseIndex = (interaction.cases as CaseRecord[]).findIndex(
        (x) => x.id === activeCase,
      );
      if (caseIndex > -1) {
        return (interaction.cases as CaseRecord[])[caseIndex];
      }
    }
    if (session && session.primaryCase) {
      return session.primaryCase as CaseRecord;
    }
    return null;
  };

  const caseRecord: CaseRecord | null =
    !isLoading && hasFetched ? findActiveCase() : null;

  const supportingDocuments: any = List(
    pipe(
      defaultTo({}),
      prop<string[]>('issues'),
      defaultTo([]),
      map(prop('supportingDocuments')),
      flatten,
      filter((x: any) => x.id !== caseRecord?.target?.id),
    )(caseRecord) as any[],
  );

  const [activeDocument, setActiveDocument] = useState(0);

  useEffect(() => {
    setActiveDocument(0);
  }, [activeCase]);

  // TODO be wary of digging for the claims here
  const supportingDocument =
    supportingDocuments.get(activeDocument) ?? nth(0)(supportingDocuments);

  if (!isLoading && hasFetched && isNil(session)) {
    return <EndOfQueue onRefresh={onNext} isFetching={isLoading} />;
  }

  return (
    <>
      {errorFetchingNextInteraction && <UnexpectedError />}
      {!errorFetchingNextInteraction && (
        <Router primary={false}>
          <Layout
            path="/"
            session={session}
            activeCase={activeCase}
            onComplete={onComplete}
            onChangeActiveCase={onChangeActiveCase}
            onSkip={onSkip}
            isSkipping={isSkipping}
            isCompleting={isCompleting}
            isLoading={isLoading}
            hasFetched={hasFetched}
            queueStats={queueStats}
            areStatsLoading={areStatsLoading}
            errorOnSkip={errorOnSkip}
            errorOnComplete={errorOnComplete}
            errorOnQueueStats={errorOnQueueStats}
            disableDetailsTab={supportingDocuments.isEmpty()}
          >
            {/* 
          Right now this is coordinating the claim on the card and the claim in the tab
          In order to do that, I need to pass the claims, the current step and how to 
          navigate the steps. Look into if there is a cleaner way to pass this
          information along.  If we don't coordinate the tab and the overview page
          we can move the majority of that code over to the Overview Component.
        */}
            <CaseDetails
              default
              path="overview"
              caseItem={caseRecord}
              onSupportingDocumentStep={setActiveDocument}
              supportingDocuments={supportingDocuments}
              activeStep={activeDocument}
              isLoading={isLoading}
              hasFetched={hasFetched}
            />
            {/* TODO update this to take into account patient */}
            {supportingDocument?.__typename === GraphqlTypes.Claim && (
              <WorkItemDetails
                path="details"
                isFetchingWorkItem={isLoading}
                hasFetchedDetails={hasFetched}
                workItem={
                  new WorkItemRecord({
                    claim: supportingDocument,
                  })
                }
              />
            )}
            {supportingDocument?.__typename === GraphqlTypes.Patient && (
              <PatientSupportingDocument
                path="details"
                hasFetchedPatient={hasFetched}
                patient={new PatientRecord(supportingDocument)}
              />
            )}
          </Layout>
        </Router>
      )}
    </>
  );
};

export default Outreach;
