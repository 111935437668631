import { is } from 'immutable';
import { isEmpty } from 'ramda';
import { take, select, race, call, flush, put } from 'redux-saga/effects';
import { delay } from 'app/common/utilities/generic';
import getConfig from 'app/configuration';
import { upsertlogEvent } from 'app/middleware/logging/api';
import { APPLICATION, LOCATION_CHANGE } from 'app/middleware/logging/constants';
import { log } from 'app/middleware/logging/logging';
import { getUserId, getAccessToken } from 'app/user/selectors';
import {
  WORKITEM_DETAILS_FETCH_START,
  WORKITEM_DETAILS_FETCH_SUCCESS,
} from 'app/workitems/constants';
import { getWorkItem } from 'app/workitems/selectors';

const workItemPropertiesToCompare = [
  {
    getValue: (workItem) => workItem.claim.claimNumber,
  },
  {
    getValue: (workItem) => workItem.claim.adjudicatedDate,
  },
  {
    getValue: (workItem) => workItem.claim.claimStatus,
  },
  {
    getValue: (workItem) => workItem.claim.rejectCodes,
  },
  {
    getValue: (workItem) => workItem.claim.rejectMessages,
  },
  {
    getValue: (workItem) => workItem.claim.drugName,
  },
  {
    getValue: (workItem) => workItem.assignee,
  },
  {
    getValue: (workItem) => workItem.status,
  },
];

function staleCheck(oldWorkitem, newWorkitem) {
  if (!oldWorkitem || !newWorkitem) return false;
  return workItemPropertiesToCompare.some(
    (property) =>
      !is(property.getValue(oldWorkitem), property.getValue(newWorkitem)),
  );
}

export function* logMiddlewareHandler(logChannel) {
  while (true) {
    const payloads = yield flush(logChannel);
    if (!isEmpty(payloads)) {
      const token = yield select(getAccessToken);

      try {
        yield call(upsertlogEvent, payloads, token);
      } catch (error) {
        console.log(error);
        yield payloads.map((e) => put(logChannel, e));
      }
    }

    yield call(delay, 1000);
  }
}

export function* userNavigatesFromWorkItemListToDetails() {
  while (true) {
    const action = yield take(LOCATION_CHANGE);

    if (action.payload.pathname === '/') {
      const startAction = yield take(WORKITEM_DETAILS_FETCH_START);
      const oldDetails = yield select(getWorkItem, startAction.payload.id);

      const result = yield race({
        locationChange: take(LOCATION_CHANGE),
        fetchSuccess: take(WORKITEM_DETAILS_FETCH_SUCCESS),
      });

      if (result.fetchSuccess) {
        const newDetails = yield select(getWorkItem, startAction.payload.id);

        const properties = {
          application: APPLICATION,
          tenantName: getConfig().TENANT_NAME,
          userId: yield select(getUserId),
          containsStaleData: staleCheck(oldDetails, newDetails),
        };

        yield call(log, 'Workitem Details Stale Check', properties);
      }
    }
  }
}
