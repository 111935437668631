import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import getConfig from '../../../configuration';
import { exportDownloadRequest } from '../../actions';
import Export from '../../components/ExportButton';
import { isFetching, getFilterQuery, canExport } from '../../selectors';

export class ExportButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    fetching: PropTypes.bool.isRequired,
    query: ImmutablePropTypes.map.isRequired,
  };

  handleClick = () => {
    const { dispatch, query } = this.props;
    dispatch(exportDownloadRequest(query));
  };

  render() {
    const { enabled, fetching } = this.props;
    return (
      getConfig().EXCEL_EXPORT_ENABLED && (
        <Export
          handleClick={this.handleClick}
          disabled={!enabled}
          isFetching={fetching}
        />
      )
    );
  }
}

export default connect((state) => ({
  enabled: canExport(state),
  fetching: isFetching(state),
  query: getFilterQuery(state),
}))(ExportButton);
