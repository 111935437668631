import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import 'normalize.css';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import { isHydrating } from '../../../hydrate/selectors';
import '../../styles/fonts.css';
import muiTheme from '../../styles/muiTheme';
import styles from './Global.css';

export const Global = ({ isHydrating, children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <StylesProvider injectFirst>
      {!isHydrating ? (
        <div className={styles.container}>{children}</div>
      ) : (
        <div />
      )}
    </StylesProvider>
  </MuiThemeProvider>
);

Global.propTypes = {
  children: PropTypes.object.isRequired,
  isHydrating: PropTypes.bool.isRequired,
};

export default connect((state) => ({ isHydrating: isHydrating(state) }))(
  Global,
);
