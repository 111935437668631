import Immutable, { Map } from 'immutable';
import { isNil } from 'ramda';
import Serialize from 'remotedev-serialize';
import { from } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { CommentRecord } from '../../comments/types';
import {
  store as storeItem,
  get,
  remove,
} from '../../common/utilities/generic';
import { NotificationRecord } from '../../notifications/types';
import { CommentsRecord, SideBarRecord } from '../../ui/types';
import { HYDRATE } from '../constants';
import { isHydrating } from '../selectors';
import config from './config';

const { stringify, parse } = Serialize.immutable(Immutable, [
  NotificationRecord,
  CommentRecord,
  CommentsRecord,
  SideBarRecord,
]);

const createKey = (prefix, configKey) => `${prefix}:${configKey}`;

export async function loadPersistedStateAsync(prefix = '') {
  const keyStatePairs = await Promise.all(
    config.map(async (c) => {
      const state = await parse(await get(createKey(prefix, c.key)));
      const postLoad = c.postLoad && !isNil(state) ? c.postLoad : (x) => x;
      return [c.key, postLoad(state)];
    }),
  );
  return new Map(keyStatePairs);
}

export function persistReducer(key, baseReducer) {
  return (state, action) => {
    switch (action.type) {
      case HYDRATE: {
        try {
          if (action.payload.state.has(key)) {
            return state.merge(action.payload.state.get(key));
          }
        } catch (e) {} // If it goes boom drop out and return default state
        return state;
      }
      default:
        return baseReducer(state, action);
    }
  };
}

export function persistStore(store, prefix = () => '') {
  from(store)
    .pipe(auditTime(1000))
    .subscribe(() => {
      const state = store.getState();
      if (isHydrating(state)) return;
      config.map((c) => {
        const preSave = c.preSave ? c.preSave : (x) => x;
        // TODO If we want to be legit do some observable joining to make this promise block
        storeItem(
          createKey(prefix(state), c.key),
          stringify(preSave(c.selector(state))),
        );
      });
    });
}

export async function purgeAsync(prefix = '') {
  await Promise.all(config.map((c) => remove(createKey(c.key, prefix))));
}
