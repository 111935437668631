import PropTypes from 'prop-types';
import React from 'react';
import styles from './HeaderBar.css';

const HeaderBar = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

HeaderBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default HeaderBar;
