import { Record } from 'immutable';
import { isNil } from 'ramda';
import { IssueFilterType } from 'app/filters/constants';
import { booleanFilterDefaults } from 'app/filters/definitions/defaults';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class ExceedsSeniorSavingsModelConfig
  extends Record({
    ...booleanFilterDefaults,
    name: 'exceedsSeniorSavingsModel',
    title: 'Exceeds Senior Savings',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: boolean) => {
      const { seniorSavingsModel } = workItem.claim;
      if (isNil(seniorSavingsModel) || isNil(seniorSavingsModel.exceedsCopay))
        return false;

      return seniorSavingsModel.exceedsCopay === value;
    },
  })
  implements IClaimFilterConfiguration<boolean, undefined, boolean, boolean>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExceedsSeniorSavingsModelConfig();
