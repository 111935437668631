import { Paper, Tab, Tabs, Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { RouteComponentProps, Router, useLocation } from '@reach/router';
import { List } from 'immutable';
import {
  flatten,
  isNil,
  map,
  pipe,
  filter,
  find,
  descend,
  prop,
  allPass,
  isNotNil,
  nth,
  defaultTo,
  sort,
  pathEq,
  complement,
  not,
} from 'ramda';
import {
  useState,
  useEffect,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { caseOverviewQuery } from 'app/cases/components/CaseOverview/query';
import { CaseRecord } from 'app/cases/types';
import { ClaimRecord } from 'app/claims/types';
import { GraphqlTypes } from 'app/common/types';
import { getActiveTabFromLocation } from 'app/common/utilities/generic';
import {
  useOptimisticMutation,
  useQueryById,
} from 'app/common/utilities/hooks';
import { InteractionRecord, InteractionStatus } from 'app/interactions/types';
import { isClaimRecord, isPatientRecord } from 'app/interactions/utility';
import { TargetType } from 'app/issues/types';
import { outreachQuery } from 'app/queue/containers/Outreach/queries';
import { upsertSession } from 'app/session/actions';
import OutreachSession from 'app/session/components/OutreachSession/OutreachSession';
import {
  SessionInputRecord,
  SessionRecord,
  SessionStatus,
} from 'app/session/types';
import { isSessionActive, isSessionOwnedByUser } from 'app/session/utility';
import { getUserId } from 'app/user/selectors';
import UnexpectedError from '../../../common/components/UnexpectedError/UnexpectedError';
import { graphqlSelector } from '../../../common/hooks/useQuery';
import { fetchPatientDrugOverview } from '../../../patient/actions';
import PatientOverview from '../../../patient/components/PatientProfile/Overview';
import { PatientRecord } from '../../../patient/types';
import WorkItemDetails from '../../../workitems/components/WorkItemDetailView/WorkItemDetails/WorkItemDetails';
import { WorkItemRecord } from '../../../workitems/types';
import { fetchCase } from '../../actions';
import CaseDetails from '../CaseDetails';
import styles from './CaseOverview.css';

interface PatientSupportingDocumentProps extends RouteComponentProps {
  patient: PatientRecord;
  hasFetchedPatient: boolean;
}

const PatientSupportingDocument = ({
  patient,
  hasFetchedPatient,
}: PatientSupportingDocumentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNotNil(patient?.id) && isNotNil(patient?.patientId)) {
      dispatch(fetchPatientDrugOverview(patient.id, patient.patientId));
    }
  }, [patient.id]);

  return (
    <PatientOverview
      hasFetchedPatient={hasFetchedPatient}
      patient={patient}
      displaySummaryCards={false}
    />
  );
};

const getCaseArrayFromSession = (session: SessionRecord) => {
  let caseArray: CaseRecord[] = [session.primaryCase as CaseRecord];
  if (
    session.interactions &&
    (session.interactions as InteractionRecord[]).length > 0
  ) {
    const inProgressInteraction = (
      session.interactions as InteractionRecord[]
    ).find((x: InteractionRecord) => x.status === InteractionStatus.InProgress);
    if (inProgressInteraction) {
      if (
        inProgressInteraction.cases &&
        (inProgressInteraction.cases as CaseRecord[]).length > 0
      ) {
        caseArray = caseArray.concat(
          inProgressInteraction.cases as CaseRecord[],
        );
      }
      if (
        inProgressInteraction.suggestedCases &&
        (inProgressInteraction.suggestedCases as CaseRecord[]).length > 0
      ) {
        caseArray = caseArray.concat(
          inProgressInteraction.suggestedCases as CaseRecord[],
        );
      }
    }
  }

  return caseArray;
};

interface LayoutProps extends RouteComponentProps {
  children: ReactNode;
  isInteractionOpen: boolean;
  isCompleting: boolean;
  onAddInteraction: () => void;
  session: SessionRecord;
  isSavingSessionErrored: boolean;
  savingSessionErrors: string[];
  caseItem: CaseRecord;
  errorFetchingCase: boolean;
  disableDetailsTab: boolean;
  onComplete: () => void;
  onCancel: () => void;
  isCanceling: boolean;
  isCreating: boolean;
  isLoading: boolean;
  hasFetched: boolean;
  handleActiveCaseChange: (caseItem: CaseRecord) => void;
  activeCase: string;
}

const Layout = ({
  children,
  navigate,
  isInteractionOpen,
  isCompleting,
  onAddInteraction,
  session,
  isSavingSessionErrored,
  savingSessionErrors,
  caseItem,
  errorFetchingCase,
  disableDetailsTab,
  onComplete,
  onCancel,
  isCanceling,
  isCreating,
  isLoading,
  hasFetched,
  handleActiveCaseChange,
  activeCase,
}: LayoutProps) => {
  if (isNil(navigate)) throw new Error('Prop navigate is null');

  const location = useLocation();
  const tabs = ['./', 'details'];
  const activeTab = getActiveTabFromLocation(tabs, location);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.navigation}>
          <Paper>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={activeTab}
              onChange={(e, v) => navigate(v)}
            >
              <Tab label="Overview" value="./" />
              {/* What to name this tab?  Support?  Evidence?  Details? */}
              <Tab
                label="Details"
                value="details"
                disabled={disableDetailsTab}
              />
            </Tabs>
          </Paper>
        </div>
        {errorFetchingCase && <UnexpectedError />}
        {!errorFetchingCase && <div className={styles.main}>{children}</div>}
      </div>
      {!isInteractionOpen && (
        <Fab
          color="primary"
          className={styles.add}
          disabled={!hasFetched || isLoading || isCreating}
          onClick={onAddInteraction}
        >
          <Add />
        </Fab>
      )}
      {isInteractionOpen && (
        <div className={styles.rightContainer}>
          <Paper classes={{ root: styles.interactionPanel }}>
            <OutreachSession
              session={session}
              activeCase={activeCase as string}
              onCancel={onCancel}
              isCanceling={isCanceling}
              isCompleting={isCompleting}
              cancelText="Cancel"
              skipable={false}
              onComplete={onComplete}
              isSavingErrored={isSavingSessionErrored}
              savingErrors={savingSessionErrors}
              isLoading={isCreating}
              hasFetched={!isNil(session)}
              onChangeActiveCase={handleActiveCaseChange}
            />
          </Paper>
        </div>
      )}
    </div>
  );
};

type SupportingDocumentProp = {
  id: string;
  filledDate: Date;
  __typename: GraphqlTypes;
};

type IssueProp = {
  supportingDocuments: Array<SupportingDocumentProp>;
};

type CaseOverviewProps = {
  caseItem?: CaseRecord;
  session: SessionRecord;
  onComplete: () => void;
  onCancel: () => void;
  isCanceling: boolean;
  isCompleting: boolean;
  isCreating: boolean;
  isLoading: boolean;
  hasFetched: boolean;
  supportingDocumentStep: number;
  setSupportingDocument: Dispatch<SetStateAction<number>>;
  isInteractionOpen: boolean;
  onAddInteraction: () => void;
  errorFetchingCase: boolean;
  isSavingSessionErrored: boolean;
  savingSessionErrors: string[];
  handleActiveCaseChange: (caseItem: CaseRecord) => void;
  activeCase: string;
};

const CaseOverview = ({
  caseItem,
  session,
  onComplete,
  onCancel,
  isCanceling,
  isCompleting,
  isCreating,
  isLoading,
  hasFetched,
  supportingDocumentStep,
  setSupportingDocument,
  isInteractionOpen,
  onAddInteraction,
  errorFetchingCase,
  isSavingSessionErrored,
  savingSessionErrors,
  handleActiveCaseChange,
  activeCase,
}: CaseOverviewProps) => {
  const supportingDocuments = pipe(
    (x: Array<IssueProp> | undefined) => defaultTo(new Array<IssueProp>())(x),
    map(prop('supportingDocuments')),
    flatten,
    filter(complement(pathEq(caseItem?.target?.id, ['id']))),
    sort(descend(prop('filledDate'))),
    List,
  )(caseItem?.issues as any);

  const supportingDocument =
    supportingDocuments.get(supportingDocumentStep) ??
    supportingDocuments.first();

  return (
    <Router primary={false}>
      <Layout
        path="/"
        session={session}
        onComplete={onComplete}
        onCancel={onCancel}
        isLoading={isLoading}
        hasFetched={hasFetched}
        isCanceling={isCanceling}
        isCompleting={isCompleting}
        isCreating={isCreating}
        isInteractionOpen={isInteractionOpen}
        onAddInteraction={onAddInteraction}
        caseItem={caseItem as CaseRecord}
        errorFetchingCase={errorFetchingCase}
        isSavingSessionErrored={isSavingSessionErrored}
        savingSessionErrors={savingSessionErrors}
        disableDetailsTab={supportingDocuments.isEmpty()}
        handleActiveCaseChange={handleActiveCaseChange}
        activeCase={activeCase}
      >
        <CaseDetails
          default
          path="overview"
          caseItem={caseItem}
          supportingDocuments={supportingDocuments}
          isLoading={isLoading}
          hasFetched={hasFetched}
          activeStep={supportingDocumentStep}
          onSupportingDocumentStep={setSupportingDocument}
        />
        {isClaimRecord(supportingDocument) && (
          <WorkItemDetails
            path="details"
            isFetchingWorkItem={isLoading}
            hasFetchedDetails={hasFetched}
            workItem={
              new WorkItemRecord({
                claim: new ClaimRecord(supportingDocument),
              })
            }
          />
        )}
        {isPatientRecord(supportingDocument) && (
          <PatientSupportingDocument
            path="details"
            hasFetchedPatient={hasFetched}
            patient={new PatientRecord(supportingDocument)}
          />
        )}
      </Layout>
    </Router>
  );
};
type CaseOverviewContainerTypes = {
  id: string;
};

const CaseOverviewContainer = ({ id }: CaseOverviewContainerTypes) => {
  const [activeCase, setActiveCase] = useState<string>();
  const [displayCase, setDisplayCase] = useState<CaseRecord | null>(null);
  const [supportingDocumentStep, setSupportingDocument] = useState(0);
  const [isInteractionOpen, setIsInteractionOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const {
    query: fetchCaseQuery,
    result: { case: caseItem },
    isRunning: isLoading,
    hasCompleted: hasLoaded,
    isErrored: errorFetchingCase,
  } = useQueryById(fetchCase, caseOverviewQuery);

  const existingSession: any = pipe(
    prop('caseSessions') as any,
    filter(allPass([isSessionActive, isSessionOwnedByUser(userId) as any])),
    nth(0) as any,
  )(caseItem);

  useEffect(() => {
    if (hasLoaded && not(isLoading) && isNotNil(existingSession?.id)) {
      setIsInteractionOpen(true);
    }
  }, [existingSession?.id, hasLoaded, isLoading]);

  const {
    mutation: upsertMutation,
    createdId,
    isErrored: isUpsertErrored,
    errors: upsertErrors,
    hasCompleted: hasUpsertCompleted,
  } = useOptimisticMutation(upsertSession);

  const { session }: any = useSelector((state) =>
    graphqlSelector(state, outreachQuery, {
      id: existingSession?.id ?? createdId,
    }),
  );

  useEffect(() => {
    fetchCaseQuery(id);
    setSupportingDocument(0);
  }, [id]);

  const filterInProgressInteractions = filter<InteractionRecord>(
    (interaction) =>
      interaction.status === InteractionStatus.InProgress ||
      interaction.status === InteractionStatus.Created,
  );

  const interaction: InteractionRecord = pipe(
    defaultTo([]) as any,
    filterInProgressInteractions as any,
    nth(0) as any,
  )(session?.interactions as InteractionRecord[]) as InteractionRecord;

  const findActiveCase = (): CaseRecord => {
    if (
      interaction &&
      interaction.suggestedCases &&
      (interaction.suggestedCases as CaseRecord[]).length > 0
    ) {
      const caseIndex = (interaction.suggestedCases as CaseRecord[]).findIndex(
        (x) => x.id === activeCase,
      );
      if (caseIndex > -1) {
        return (interaction.suggestedCases as CaseRecord[])[caseIndex];
      }
    }
    if (
      interaction &&
      interaction.cases &&
      (interaction.cases as CaseRecord[]).length > 0
    ) {
      const caseIndex = (interaction.cases as CaseRecord[]).findIndex(
        (x) => x.id === activeCase,
      );
      if (caseIndex > -1) {
        return (interaction.cases as CaseRecord[])[caseIndex];
      }
    }
    if (session && session.primaryCase) {
      return session.primaryCase as CaseRecord;
    }
    return caseItem;
  };

  useEffect(() => {
    if (isNotNil(activeCase) && activeCase !== caseItem?.id) {
      setDisplayCase(findActiveCase());
    } else if (hasLoaded) {
      setDisplayCase(caseItem as CaseRecord);
    }
  }, [caseItem?.id, activeCase]);

  useEffect(() => {
    if (isUpsertErrored) {
      setIsCompleting(false);
      setIsCanceling(false);
      setIsCreating(false);
    }
    if (hasUpsertCompleted && !isNil(session)) {
      switch (session.status) {
        case SessionStatus.Completed: {
          setIsInteractionOpen(false);
          setIsCompleting(false);
          break;
        }
        case SessionStatus.Abandoned: {
          setIsCanceling(false);
          setIsInteractionOpen(false);
          break;
        }
        case SessionStatus.Skipped: {
          setIsCanceling(false);
          setIsInteractionOpen(false);
          break;
        }
        case SessionStatus.TimedOut: {
          setIsCanceling(false);
          setIsInteractionOpen(false);
          break;
        }
        case SessionStatus.Claimed: {
          setIsCreating(false);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [session?.status, hasUpsertCompleted, isUpsertErrored]);

  useEffect(() => {
    const dispCase = isNotNil(displayCase) ? displayCase : caseItem;
    if (
      isNotNil(dispCase) &&
      isNotNil(dispCase?.target?.id) &&
      dispCase.campaign?.target === TargetType.Patient
    ) {
      dispatch(
        fetchPatientDrugOverview(dispCase.target.id, dispCase.target.patientId),
      );
    }
  }, [caseItem?.target?.id, displayCase?.target?.id]);

  const handleAddSession = () => {
    upsertMutation(
      null,
      new SessionInputRecord({
        primaryCase: caseItem.id,
        status: SessionStatus.Claimed,
      }),
    );
    setIsCreating(true);
    setIsInteractionOpen(true);
  };

  const handleOnComplete = () => {
    upsertMutation(
      session.id,
      new SessionInputRecord({
        id: session.id,
        primaryCase: caseItem.id,
        status: SessionStatus.Completed,
      }),
    );
    setIsCompleting(true);
  };

  const handleOnCancel = () => {
    if (isNotNil(session)) {
      upsertMutation(
        session.id,
        new SessionInputRecord({
          id: session.id,
          primaryCase: caseItem.id,
          status: SessionStatus.Abandoned,
        }),
      );
      setIsCanceling(true);
    } else {
      setIsInteractionOpen(false);
    }
  };

  useEffect(() => {
    if (!isNil(session)) {
      setActiveCase((session?.primaryCase as CaseRecord)?.id);
    }
  }, [session?.id]);

  const handleOnChangeActiveCase = (newCaseItem: CaseRecord) => {
    setActiveCase(newCaseItem.id);
  };

  return (
    <CaseOverview
      caseItem={isNotNil(displayCase) ? displayCase : caseItem}
      session={session}
      onComplete={handleOnComplete}
      onCancel={handleOnCancel}
      isCanceling={isCanceling}
      isCompleting={isCompleting}
      isCreating={isCreating}
      isLoading={isLoading}
      hasFetched={hasLoaded}
      supportingDocumentStep={supportingDocumentStep}
      setSupportingDocument={setSupportingDocument}
      isInteractionOpen={isInteractionOpen}
      onAddInteraction={handleAddSession}
      errorFetchingCase={errorFetchingCase}
      isSavingSessionErrored={isUpsertErrored}
      savingSessionErrors={upsertErrors}
      handleActiveCaseChange={handleOnChangeActiveCase}
      activeCase={activeCase ? (activeCase as string) : caseItem.id}
    />
  );
};

export default CaseOverviewContainer;
