import { AddCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import ColorChip from '../../../common/components/ColorChip';
import FilterTitle from '../FilterTitle';
import styles from './AppliedFilter.css';
import FilterSummary from './FilterSummary';

function getClassName(isEditing) {
  let classNames = styles.filter;
  if (isEditing) {
    classNames += ` ${styles.editingFilter}`;
  }
  return classNames;
}

const AppliedFilter = ({
  onRemove,
  isEditing,
  onSetEditing,
  title,
  component,
  summary,
}) => (
  <div className={styles.container}>
    <div className={styles.remove} onClick={onRemove}>
      <AddCircle />
    </div>
    <div
      className={getClassName(isEditing)}
      onClick={() => !isEditing && onSetEditing(true)}
    >
      {!isEditing && <ColorChip />}
      <div className={styles.content}>
        <FilterTitle
          title={title}
          onSetEditing={onSetEditing}
          isEditing={isEditing}
        />
        <div className={styles.body}>
          {isEditing && component()}
          {!isEditing && <FilterSummary summary={summary} />}
        </div>
      </div>
    </div>
  </div>
);

AppliedFilter.propTypes = {
  component: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

export default AppliedFilter;
