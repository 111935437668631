import { Record, Map } from 'immutable';

export const NotificationsStateRecord = Map;

export const NotificationRecord = new Record({
  id: null,
  status: 'Error',
  type: null,
  timestamp: new Date(),
  data: null,
});
