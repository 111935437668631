import { fetchGraphqlQuery } from '../common/api';
import {
  fetchNextSessionInQueueQuery,
  skipSessionMutation,
  fetchQueueStatsQuery,
  fetchContextForContactQuery,
} from './queries';

export async function fetchNextSessionInQueue(token) {
  const result = await fetchGraphqlQuery(
    fetchNextSessionInQueueQuery,
    {},
    token,
  );

  return { errors: result.errors, session: result.data.nextCaseSession };
}

export async function skipSessionInQueue(id, token) {
  const result = await fetchGraphqlQuery(skipSessionMutation, { id }, token);

  return { errors: result.errors, session: result.data.skipCaseSession };
}

export async function fetchQueueStats(token) {
  const result = await fetchGraphqlQuery(fetchQueueStatsQuery, {}, token);

  return { errors: result.errors, stats: result.data.interactionQueue };
}

export async function fetchContextForContactAsync(id, token) {
  const result = await fetchGraphqlQuery(
    fetchContextForContactQuery,
    { id },
    token,
  );

  return { errors: result.errors, context: result.data.contextForContact };
}
