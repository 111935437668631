import graphql from '../common/utilities/graphql';

const templateDetailsFragment = graphql`
  fragment templateDetails on TemplateType {
    id
    name
    isPublic
    createdBy {
      id
      email
      name
      pictureUrl
    }
    tags
    configuration {
      filter {
        name
        valueType
        ... on BooleanFilterType {
          value
        }
        ... on MultiSelectStringFilterType {
          value
        }
        ... on DateRangeFilterType {
          value {
            start
            end
          }
        }
        ... on MultiSelectKeyValueType {
          value {
            key
            value
          }
        }
        ... on MatchesPaidClaimFilterType {
          value {
            hasPaidClaim
            gpiLevel
            window
          }
        }
        ... on MultiSelectMatchFilterType {
          value {
            match
            values {
              key
              value
            }
          }
        }
        ... on NumericComparisonFilterType {
          value {
            number
            operator
          }
        }
        ... on TextComparisonFilterType {
          value {
            text
            operator
          }
        }
        ... on SingleTextValueFilterType {
          value
        }
        ... on InitialOpioidSupplyFilterType {
          value {
            numericComparison {
              number
              operator
            }
            window
          }
        }
        ... on NumericRangeFilterType {
          value {
            minimum {
              value
              type
            }
            maximum {
              value
              type
            }
          }
        }
      }
      sortBy
      sortDirection
    }
  }
`;

const errorDetailsFragment = graphql`
  fragment errorDetails on ErrorType {
    fieldName
    messages
  }
`;

export const viewTemplatesQuery = graphql`
  ${templateDetailsFragment}

  query {
    templates {
      ...templateDetails
    }
  }
`;

export const viewTemplateUpsert = graphql`
  ${templateDetailsFragment}
  ${errorDetailsFragment}

  mutation ($template: TemplateInputType!) {
    upsertTemplate(template: $template) {
      successful
      errors {
        ...errorDetails
      }
      templateResponse {
        ...templateDetails
      }
    }
  }
`;

export const viewTemplateCountQuery = graphql`
  query ($id: ID!) {
    node(id: $id) {
      ... on TemplateType {
        countSummary {
          count
          isStale
        }
      }
    }
  }
`;
