import { NetworkErrorRecord } from '../types';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new NetworkErrorRecord({ status: response.status });

  throw error;
}

export function addAuthorizationToHeader(token, headers = new Headers()) {
  headers.append('authorization', `Bearer ${token}`);
  return headers;
}

export function addcontentTypeJsonToHeader(headers = new Headers()) {
  headers.append('Content-Type', 'application/json');
  return headers;
}

export function parseJson(response) {
  return response.json();
}

function fetchHelper(url, method, headers, body = {}) {
  const params = { method };
  params.headers = headers;

  if (Object.keys(body).length > 0) {
    params.body = JSON.stringify(body);
  }

  return fetch(url, params).then(checkStatus);
}

export function get(url, headers) {
  return fetchHelper(url, 'get', headers);
}

export function post(url, headers, body) {
  return fetchHelper(url, 'post', headers, body);
}
