import { patientFragmentFactory } from 'app/patient/queries';
import { pharmacyFragmentFactory } from 'app/pharmacies/queries';
import { prescriberFragmentFactory } from 'app/prescribers/queries';
import graphql from '../common/utilities/graphql';

export const interactionResponseFragmentFactory = (name) => graphql`
  fragment ${name} on FieldResponse {
    ... on InteractionFieldResponseType {
      level
      name
      value
    }
    ... on CaseFieldResponseType {
      level
      name
      value
      caseId
    }
  }
`;
const interactionFragment = graphql`
  ${interactionResponseFragmentFactory('responses')}
  ${patientFragmentFactory('patientFragment')}
  ${prescriberFragmentFactory('prescriberFragment')}
  ${pharmacyFragmentFactory('pharmacyFragment')}
  ${patientFragmentFactory('patientInteractionFragment')}
  ${prescriberFragmentFactory('prescriberInteractionFragment')}
  ${pharmacyFragmentFactory('pharmacyInteractionFragment')}

  fragment interactionFragment on InteractionType {
    id
    dateContacted
    status
    cases {
      id
      status
      followUpDate
      interactions {
        id
        dateContacted
        status
        responses {
          ...responses
        }
        user {
          id
          name
          email
          pictureUrl
        }
        contact {
          ... on PatientType {
            ...patientInteractionFragment
          }
          ... on PrescriberType {
            ...prescriberInteractionFragment
          }
          ... on PharmacyType {
            ...pharmacyInteractionFragment
          }
          __typename
        }
      }
    }
    responses {
      ...responses
    }
    contact {
      ... on PatientType {
        ...patientFragment
      }
      ... on PrescriberType {
        ...prescriberFragment
      }
      ... on PharmacyType {
        ...pharmacyFragment
      }
      __typename
    }
  }
`;

export const upsertInteractionMutation = graphql`
  ${interactionFragment}
  ${patientFragmentFactory('patientContactFragment')}
  ${prescriberFragmentFactory('prescriberContactFragment')}
  ${pharmacyFragmentFactory('pharmacyContactFragment')}
  ${patientFragmentFactory('patientTargetFragment')}
  ${prescriberFragmentFactory('prescriberTargetFragment')}
  ${pharmacyFragmentFactory('pharmacyTargetFragment')}
  mutation ($interaction: InteractionInputType!) {
    saveInteraction(interaction: $interaction) {
      ...interactionFragment
      caseSession {
        id
        interactions {
          id
          status
        }
      }
      suggestedCases {
        id
        number
        dateOpened
        dateClosed
        campaign {
          id
          name
          contact
          target
          interactionFieldDefinitions: interactionFields {
            name
            type
            options
          }
        }
        status
        contact {
          ...patientContactFragment
          ...prescriberContactFragment
          ...pharmacyContactFragment
          __typename
        }
        target {
          ...patientTargetFragment
          ...prescriberTargetFragment
          ...pharmacyTargetFragment
          __typename
        }
        interactions {
          id
          dateContacted
          responses {
            ...responses
          }
          user {
            id
            name
            email
            pictureUrl
          }
        }
      }
    }
  }
`;

export const fetchInteractionLevelFieldDefinitions = graphql`
  query ($contact: ContactType!, $activeCase: String) {
    interactionLevelFields(
      contact: $contact
      contactMethod: PHONE
      activeCase: $activeCase
    ) {
      name
      type
      options
    }
  }
`;
