import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './Tag.css';

export type TagPropTypes = {
  text: string;
  className?: string;
  bold?: boolean;
  color?: 'red' | 'orange' | 'blue' | 'green' | 'grey' | 'yellow';
};

const Tag = forwardRef<HTMLDivElement, TagPropTypes>(
  (
    {
      text,
      className = '',
      color = 'grey',
      bold = false,
      ...props
    }: TagPropTypes,
    ref,
  ) => (
    <div
      // This is required for tooltip support with material-ui
      // https://material-ui.com/guides/composition/#caveat-with-refs
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      className={classNames(className, styles.tag, styles[color], {
        [styles.bold]: bold,
      })}
    >
      {text}
    </div>
  ),
);

export default Tag;
