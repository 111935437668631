import { addMonths, addDays } from 'date-fns';
import { OrderedSet, Set } from 'immutable';
import { ascend, descend, compose, prop, sortWith, isNil, curry } from 'ramda';
import { Interval } from '../common/types';
import { mapToValuesArray } from '../common/utilities/generic';
import { getPrescriber } from '../prescribers/selectors';
import { getWorkItem } from '../workitems/selectors';
import { PatientClaimsHistoryRecord } from './types';

const getClaimsState = (state) => state.claimsState;
export const getClaims = (state) => getClaimsState(state).claims;
export const getClaim = curry((state, id) => {
  let claim = getClaims(state).get(id);
  if (isNil(claim?.prescriber) === false) {
    claim = claim.merge({
      prescriber: getPrescriber(state, claim.prescriber),
    });
  }
  if (isNil(claim?.historicalOpioidPrescribers) === false) {
    claim = claim.merge({
      historicalOpioidPrescribers: new Set(
        claim.historicalOpioidPrescribers.map((x) => getPrescriber(state, x)),
      ),
    });
  }
  return claim;
});

export const getClaimsById = (state, ids) =>
  ids.map((id) => getClaim(state, id));

export const getPatientClaimsHistory = (state, workItemId, size) => {
  const monthOffset = 6;
  const workItem = getWorkItem(state, workItemId);
  if (!(workItem && workItem.claim && workItem.claim.patientId)) {
    return new PatientClaimsHistoryRecord({
      dateRange: new Interval(),
    });
  }

  const filledDate = new Date(workItem.claim.filledDate);

  const patientClaims = getClaims(state).filter((claim) => {
    const claimFilledDate = new Date(claim.filledDate);
    return (
      claim.patientId === workItem.claim.patientId &&
      claimFilledDate >= addMonths(filledDate, -monthOffset) &&
      claimFilledDate <= addMonths(filledDate, monthOffset)
    );
  });

  const convertToDate = (dateString) => new Date(dateString);

  const timeBetweenDates = (date) => Math.abs(date - filledDate);

  const sortClosestToFilledDate = sortWith([
    ascend(compose(timeBetweenDates, convertToDate, prop('filledDate'))),
    descend(prop('claimNumber')),
  ]);

  const sortedClaims = new OrderedSet(
    sortClosestToFilledDate(mapToValuesArray(patientClaims)),
  );

  const subsetClaims = sortedClaims.take(size).toArray();

  const sort = sortWith([
    descend(prop('filledDate')),
    descend(prop('claimNumber')),
  ]);

  const sortedSubsetClaims = new OrderedSet(sort(subsetClaims));

  const hasAdditionalClaims = patientClaims.count() > size;
  let startDate = new Date(filledDate);
  let endDate = new Date(filledDate);

  if (hasAdditionalClaims) {
    startDate = sortedSubsetClaims.minBy(
      (claims) => new Date(claims.filledDate),
    ).filledDate;
    endDate = sortedSubsetClaims.maxBy(
      (claims) => new Date(claims.filledDate),
    ).filledDate;
  } else {
    startDate = addMonths(startDate, -monthOffset);
    endDate = addMonths(endDate, monthOffset);
    if (endDate > new Date()) {
      endDate = new Date();
    }
  }

  return new PatientClaimsHistoryRecord({
    claims: sortedSubsetClaims,
    dateRange: new Interval({ start: startDate, end: endDate }),
    hasAdditionalClaims,
  });
};

export const getPatientDrugHistory = (
  state,
  workItemId,
  defaultDaysBack,
  size,
) => {
  const workItem = getWorkItem(state, workItemId);

  if (!(workItem && workItem.claim && workItem.claim.patientId)) {
    return new PatientClaimsHistoryRecord({
      dateRange: new Interval(),
    });
  }

  const filledDate = new Date(workItem.claim.filledDate);

  const daysBack = workItem.claim.quantityLimitDays || defaultDaysBack;
  const quantityLimitStartDate = addDays(filledDate, -daysBack);

  const patientClaims = getClaims(state).filter((claim) => {
    const claimFilledDate = new Date(claim.filledDate);

    const withinDateRange =
      claim.patientId === workItem.claim.patientId &&
      claimFilledDate <= filledDate &&
      claimFilledDate >= quantityLimitStartDate;

    if (withinDateRange) {
      if (workItem.claim.rxcui) {
        return claim.rxcui === workItem.claim.rxcui;
      }
      return claim.ndc === workItem.claim.ndc;
    }

    return false;
  });

  const sort = sortWith([
    descend(prop('filledDate')),
    descend(prop('claimNumber')),
  ]);
  const sortedSubsetClaims = new OrderedSet(
    sort(Array.from(patientClaims.values())),
  ).take(size);

  const hasAdditionalClaims = patientClaims.count() > size;
  let startDate = quantityLimitStartDate;

  if (hasAdditionalClaims) {
    startDate = sortedSubsetClaims.minBy(
      (claim) => new Date(claim.filledDate),
    ).filledDate;
  }

  return new PatientClaimsHistoryRecord({
    claims: sortedSubsetClaims,
    dateRange: new Interval({
      start: startDate,
      end: filledDate,
    }),
    hasAdditionalClaims,
  });
};
