import { Record, OrderedSet } from 'immutable';
import { schema } from 'normalizr';
import { caseSchema } from 'app/cases/types';
import { GraphqlTypes } from 'app/common/types';
import { patientSchema } from 'app/patient/types';
import { pharmacySchema } from 'app/pharmacies/types';
import { prescriberSchema } from 'app/prescribers/types';
import { claim as claimSchema } from '../workitems/types';

export enum SearchType {
  Claims = 'CLAIMS',
  Patients = 'PATIENTS',
  Cases = 'CASES',
  Prescribers = 'PRESCRIBERS',
  Pharmacies = 'PHARMACIES',
}

export class SearchStateRecord extends Record({
  claims: OrderedSet<string>(),
  patients: OrderedSet<string>(),
  cases: OrderedSet<string>(),
  prescribers: OrderedSet<string>(),
  pharmacies: OrderedSet<string>(),
}) {}

export class SearchResultRecord extends Record({
  type: SearchType.Cases,
  id: '',
  entityId: undefined as string | undefined, // Not all types have entityIds
  primaryText: '',
  secondaryText: '',
}) {}

const autocompleteResultUnion = new schema.Union(
  {
    claim: claimSchema,
    patient: patientSchema,
    prescriber: prescriberSchema,
    case: caseSchema,
    pharmacy: pharmacySchema,
  },
  (value, parent, key) => {
    switch (value.__typename) {
      case GraphqlTypes.Patient:
        return 'patient';
      case GraphqlTypes.Claim:
        return 'claim';
      case GraphqlTypes.Case:
        return 'case';
      case GraphqlTypes.Prescriber:
        return 'prescriber';
      case GraphqlTypes.Pharmacy:
        return 'pharmacy';
      default:
        throw new Error(
          `Unsupported autocomplete document type: ${value.__typename}`,
        );
    }
  },
);

export const autocompleteQuerySchema = new schema.Array(
  autocompleteResultUnion,
);
