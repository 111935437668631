import { Button, CircularProgress } from '@material-ui/core';
import { ReactNode } from 'react';

type Color = 'inherit' | 'primary' | 'secondary' | 'default';

interface SaveButtonBaseProps {
  className?: string;
  variant?: 'contained' | 'outlined';
  color?: Color;
  isSaving: boolean;
  children: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

interface FormSaveButtonProps extends SaveButtonBaseProps {
  type: 'submit' | 'reset';
  onClick?: never;
}

interface NormalSaveButtonProps extends SaveButtonBaseProps {
  type?: 'button';
  onClick: () => void;
}

type SaveButtonProps = FormSaveButtonProps | NormalSaveButtonProps;

const SaveButton = ({
  variant = 'contained',
  color = 'primary',
  type = 'button',
  fullWidth = false,
  disabled = false,
  isSaving,
  children,
  onClick,
  className = '',
}: SaveButtonProps) => (
  <Button
    className={className}
    variant={variant}
    fullWidth={fullWidth}
    color={color}
    onClick={onClick}
    disabled={isSaving || disabled}
    type={type}
  >
    {!isSaving && children}
    {isSaving && <CircularProgress size={22} />}
  </Button>
);

export default SaveButton;
