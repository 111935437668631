import { Record, Set } from 'immutable';

export const TokenMetaRecord = Record({
  idToken: null,
  accessToken: null,
  isFetching: false,
  error: null,
});

// With the addition of the users domain, this should probably be trimmed down
// to just the user id and the other data not stored in /users
export const UserStateRecord = Record({
  error: null,
  token: new TokenMetaRecord(),
  email: null,
  name: null,
  picture: null,
  userId: null,
  activeViewTemplateId: null,
  permissions: new Set(),
});
