import { OrderedSet, Record } from 'immutable';
import { Interval } from 'app/common/types';
import { IssueFilterType } from 'app/filters/constants';
import { dateRangeDefaults } from 'app/filters/definitions/defaults';
import {
  DateRangeOffset,
  DateRangeSelectValue,
  IClaimFilterConfiguration,
} from 'app/filters/types';

class FilledDateRangeConfig
  extends Record({
    ...dateRangeDefaults,
    name: 'filledDateRange',
    title: 'Filled Date',
    targetType: IssueFilterType.Claim,
    // We removed this in 2018, likely due to client timezone not being synced
    // between client/services
    filterWorkItem: () => true,
    workflowSpecific: true,
  })
  implements
    IClaimFilterConfiguration<
      Interval,
      OrderedSet<DateRangeSelectValue>,
      Interval,
      DateRangeOffset
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new FilledDateRangeConfig();
