import { combineForms } from 'react-redux-form';
import { initialState as viewTemplateSaveInitialState } from './viewtemplates/components/ViewTemplateSave/form';

const formsReducer = combineForms(
  {
    viewTemplateSave: viewTemplateSaveInitialState,
  },
  'forms',
);

export default formsReducer;
