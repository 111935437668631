import { CircularProgress } from '@material-ui/core';
import { Flag, ErrorOutline } from '@material-ui/icons';
import {
  differenceInCalendarDays,
  format,
  eachDayOfInterval,
  subMonths,
  subDays,
  addMonths,
  isAfter,
  isBefore,
  min as minDate,
  max as maxDate,
  isEqual,
  isWithinInterval,
  areIntervalsOverlapping,
} from 'date-fns';
import { OrderedMap, Record, OrderedSet, Map } from 'immutable';
import PropTypes from 'prop-types';
import {
  sortWith,
  pipe,
  groupBy,
  map,
  sum,
  prop,
  reduce,
  filter,
  minBy,
  descend,
  uniq,
  uniqBy,
  flatten,
  max,
  isNil,
  min,
  equals,
  dissocPath,
  ascend,
  mean,
  values,
  mapObjIndexed,
  curry,
  mergeRight,
  concat,
} from 'ramda';
import React, { useState, forwardRef } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import Tour from 'reactour';
import { v4 as uuid } from 'uuid';
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  LineSegment,
  VictoryLine,
  VictoryScatter,
  VictoryLabel,
  VictoryAxis,
  VictoryTooltip,
  VictoryGroup,
  VictoryLegend,
  Rect,
  Bar,
  Curve,
  VictoryCursorContainer,
  Path,
} from 'victory';
import Message from '../../../common/components/Message';
import { mapToValuesArray } from '../../../common/utilities/generic';
import styles from './OpioidOverviewChart.css';

const CustomBar = (props) => (
  <Bar
    {...props}
    pathComponent={
      <Path tour-stop={!props.datum.outsideDomain ? 'fill' : 'none'} />
    }
  />
);

const Flyout = ({ x, y, ...props }) => {
  const labelWidth = 40;
  const labelHeight = 20;
  return (
    <Rect
      {...props}
      x={x - 8 - labelWidth / 2}
      y={y - labelHeight - props.height / 2}
      rx={2}
      height={labelHeight}
      width={labelWidth}
      style={{ fill: 'black' }}
    />
  );
};

const Tooltip = (props) => {
  return (
    <VictoryTooltip
      {...props}
      active={props.focusedRow === props.datum.x}
      dx={-8}
      style={{ fill: 'white' }}
      orientation="top"
      flyoutComponent={<Flyout />}
    />
  );
};

const DiagonalHatchBar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern
          id="diagonalHatch"
          width="5"
          height="5"
          patternTransform="rotate(45 0 0)"
          patternUnits="userSpaceOnUse"
        >
          <line
            x1="0"
            y1="0"
            x2="0"
            y2="5"
            style={{ stroke: 'white', strokeWidth: '6' }}
          />
        </pattern>
      </defs>
      <Bar
        {...props}
        barWidth={props.barWidthMap.get(props.datum.gpi)}
        style={{ fill: 'url(#diagonalHatch)' }}
      />
    </svg>
  );
};

const RxLabel = ({ style, ...props }) => {
  const y = props.scale.y(props.datum.y);
  const y0 = props.scale.y(props.datum.y0);

  const display = y0 - y > 80 ? 'inherit' : 'none';

  return (
    <VictoryLabel
      {...props}
      style={{
        ...style,
        fill: '#000',
        fontFamily: 'roboto',
        fontSize: '12px',
        fontWeight: '500',
        display,
      }}
    />
  );
};

// TODO:  Better way, just comparing the prevProps and nextProps returns false, once you stringify it all out, everything works fine.
const propsAreEqual = (prevProps, nextProps) => {
  const omitYDomain = (props) => dissocPath(['domain', 'y'], props);

  const areEqual = equals(
    JSON.stringify(omitYDomain(prevProps)),
    JSON.stringify(omitYDomain(nextProps)),
  );

  return areEqual;
};

const OpioidRecord = new Record({
  date: null,
  mme: null,
});

const createMme = (date, mme) =>
  new OpioidRecord({
    date,
    mme,
  });

const DailyMmeGraph = ({ mme, ...props }) => {
  // get the position of the first and last element, add a padding of 3 px per bar, and find the width.
  const strokeWidth =
    (props.scale.y(props.domain.y[1]) -
      props.scale.y(props.domain.y[0]) -
      3 * differenceInCalendarDays(props.domain.y[1], props.domain.y[0])) /
    differenceInCalendarDays(props.domain.y[1], props.domain.y[0]);

  const determineColor = (value) => {
    if (value.mme > 200) {
      return '#C72D3F';
    }

    if (value.mme > 90) {
      return '#F6A823';
    }

    return '#4DC47E';
  };

  const normalizeData = (date) => [
    { x: date, y: props.domain.x[0] },
    { x: date, y: props.domain.x[1] },
  ];

  const dailyMme = new OrderedMap(
    eachDayOfInterval({
      start: props.domain.y[0],
      end: subDays(props.domain.y[1], 1),
    }).map((c) => [c, createMme(c, 0)]),
  )
    .merge(mme)
    .filter((x) =>
      isWithinInterval(x.date, {
        start: props.domain.y[0],
        end: props.domain.y[1],
      }),
    );

  const isTourStop = (current, index, arr) => {
    if (index === 0 || current.mme === 0) return false;

    return (
      arr[index - 1].mme > 0 &&
      index != arr.length - 1 &&
      arr[index + 1].mme > 0
    );
  };

  return (
    <VictoryGroup {...props}>
      {mapToValuesArray(dailyMme).map((x, i, arr) => (
        <VictoryLine
          {...props}
          name={`MME-Line-${x.date}`}
          style={{
            data: {
              stroke: determineColor(x),
              strokeWidth: strokeWidth,
              opacity: 0.4,
            },
          }}
          dataComponent={<Curve transform={`translate(${strokeWidth / 2})`} />}
          groupComponent={
            <g tour-stop={isTourStop(x, i, arr) ? 'mme' : 'none'} />
          }
          data={normalizeData(x.date)}
          y="x"
          x="y"
        />
      ))}
    </VictoryGroup>
  );
};

const DailyMmeChart = React.memo(DailyMmeGraph, propsAreEqual);

const OpioidNaiveGraph = ({ naiveWindows, ...props }) => {
  const getStartingPoint = (window) => {
    const beginningDate = isBefore(new Date(window.start), props.domain.y[0])
      ? props.domain.y[0]
      : new Date(window.start);
    return props.scale.y(beginningDate);
  };

  const getWidth = (window) => {
    const endingDate = isAfter(new Date(window.end), props.domain.y[1])
      ? props.domain.y[1]
      : new Date(window.end);
    const endPoint = props.scale.y(endingDate);

    return endPoint - getStartingPoint(window);
  };

  const topOfChartPoint = props.scale.x(props.domain.x[1]);
  const bottomOfChartPoint = props.scale.x(props.domain.x[0]);
  const height = bottomOfChartPoint - topOfChartPoint;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={max(0, props.width)}
      height={max(0, props.height)}
      x={0}
      y={0}
    >
      <defs>
        <pattern
          id="diagonalHatch1"
          width="10"
          height="5"
          patternTransform="rotate(45 0 0)"
          patternUnits="userSpaceOnUse"
        >
          <rect width={100} height={100} style={{ fill: 'white' }} />
          <line
            x1="0"
            y1="0"
            x2="0"
            y2="14"
            style={{ stroke: '#C5C5C5', strokeWidth: '11px' }}
          />
        </pattern>
      </defs>

      {naiveWindows.map((n) => {
        const width = getWidth(n);
        const x = getStartingPoint(n);

        return (
          <rect
            data-tut={'naive'}
            x={x}
            y={topOfChartPoint}
            width={width}
            height={height}
            style={{ fill: 'url(#diagonalHatch1)' }}
          />
        );
      })}
    </svg>
  );
};

const OpioidNaiveChart = React.memo(OpioidNaiveGraph, propsAreEqual);

const GpiChainRecord = new Record({
  claims: new Map(),
  chains: new Map(),
});

const DrugChart = ({
  claims,
  issues,
  selectedIssueId,
  naiveWindows,
  height,
  width,
  isTourOpen,
  onTourClose,
}) => {
  const [focusedRow, setFocusedRow] = useState(null);

  // Data Manipulation
  const paidClaims = claims.filter((x) => x.claimStatus == 'Paid');

  const dailyMme = pipe(
    filter((claim) => claim.drugContainsOpioid),
    reduce((acc, claim) => {
      const mergeRecord = (opioidRecord) => {
        const date = acc.get(opioidRecord.date);

        if (isNil(date) || opioidRecord.mme > date.mme) {
          return acc.merge({
            [opioidRecord.date]: opioidRecord,
          });
        }

        return acc;
      };

      acc = mergeRecord(
        createMme(
          claim.prescriptionStart,
          claim.patientTotalMorphineMilligramEquivalent,
        ),
      );

      acc = mergeRecord(
        createMme(
          claim.prescriptionEnd,
          claim.patientEndingTotalMorphineMilligramEquivalent,
        ),
      );

      return acc;
    }, new Map()),
    curry((dates) =>
      dates
        .sortBy((x) => x.date)
        .toIndexedSeq()
        .reduce((acc, curr, index, list) => {
          const createMmePerDay = (start, end, mme) => {
            const interval = eachDayOfInterval({ start, end });
            return interval.map((x) => [x, createMme(x, mme)]);
          };

          // first row, nothing has changed.
          if (index === 0) {
            return acc;
          }
          const previousMme = list.get(index - 1);
          return acc.concat(
            createMmePerDay(
              previousMme.date,
              subDays(curr.date, 1),
              previousMme.mme,
            ),
          );
        }, new OrderedMap()),
    ),
  )(paidClaims);

  const toNullOrDate = (date) => (isNil(date) ? null : new Date(date));

  const claimsBySplitGpi = pipe(
    groupBy((x) => x.gpi),
    values,
    reduce((acc, curr) => {
      const sortedClaims = sortWith([ascend((y) => y.prescriptionStart)])(curr);

      const chainRecord = reduce(
        (chain, claim) => {
          const hasPreviousChain =
            !isNil(claim.continuedFrom) &&
            chain.claims.has(claim.continuedFrom);
          const chainId = !hasPreviousChain
            ? uuid()
            : chain.claims.get(claim.continuedFrom);
          const currentChain = chain.chains.get(chainId) || new OrderedSet();

          return chain.mergeDeep({
            claims: {
              [claim.id]: chainId,
            },
            chains: {
              [chainId]: currentChain.add(claim),
            },
          });
        },
        new GpiChainRecord(),
        sortedClaims,
      );

      return acc.merge(
        chainRecord.chains
          .sortBy((x) => x.first().prescriptionStart)
          .sortBy((x) => x.first().claimNumber)
          .reduce((acc, curr) => {
            const start = curr.first().prescriptionStart;
            const end = curr.last().prescriptionEnd;

            const gpi = sortedClaims[0].gpi;
            let key = gpi;

            let gpiChain = acc.get(key);

            let totalSplits = 0;
            while (
              gpiChain &&
              !gpiChain.every(
                (x) =>
                  isBefore(x.prescriptionEnd, start) ||
                  isEqual(x.prescriptionEnd, start) ||
                  isAfter(x.prescriptionStart, end) ||
                  isEqual(x.prescriptionStart, end),
              )
            ) {
              key = `${gpi}-${++totalSplits}`;
              gpiChain = acc.get(key);
            }

            return acc.merge({
              [key]: isNil(gpiChain) ? curr : gpiChain.concat(curr),
            });
          }, new Map()),
      );
    }, new Map()),
    curry((value) => value.toObject()),
    mapObjIndexed((value, key) =>
      value
        .map((c) => ({
          id: c.id,
          workItemId: c.workItem,
          rxNumber: c.rxNumber,
          filledDate: toNullOrDate(c.filledDate),
          prescriptionStart: toNullOrDate(c.prescriptionStart),
          prescriptionEnd: toNullOrDate(c.prescriptionEnd),
          refillWindowStart: toNullOrDate(c.refillWindowStart),
          gpi: c.gpi,
          splitGpi: key,
          gpi14Description: c.gpi14Description,
        }))
        .toArray(),
    ),
    values,
    flatten,
  )(paidClaims);

  const daysSuppliedByGpi = pipe(
    groupBy((x) => x.gpi),
    map(
      pipe(
        map((x) =>
          differenceInCalendarDays(x.prescriptionEnd, x.prescriptionStart),
        ),
        sum,
      ),
    ),
  )(claimsBySplitGpi);

  const sortedClaims = sortWith([
    descend((x) => daysSuppliedByGpi[x.gpi]),
    descend((x) => x.gpi),
  ])(claimsBySplitGpi);

  const uniqSplitGpis = uniq(
    concat(
      claims.map((x) => x.gpi),
      sortedClaims.map((x) => x.splitGpi),
    ),
  );

  const gpis = sortWith([
    descend((x) => daysSuppliedByGpi[x]),
    descend((x) => x),
  ])(uniq(claims.map((x) => x.gpi)));

  const gpiDescriptionMap = pipe(
    filter((x) => !isNil(x.gpi14Description)),
    groupBy((x) => x.gpi),
    map((x) => x[0]?.gpi14Description),
  )(claims);

  // Chart Domain Functions
  const normalizedTickValues = new Map(
    uniqSplitGpis.map((x) => {
      const index = gpis.indexOf(x.substring(0, 14)) + 1;
      const overlapIndex = x.includes('-') ? x.split('-')[1] : 1;

      // .085 works for ramon and sophie
      return [
        x,
        x.includes('-') ? index - 0.085 * overlapIndex * gpis.length : index,
      ];
    }),
  );

  const getXValue = (gpi) => {
    const ticksForGpi = normalizedTickValues
      .keySeq()
      .filter((x) => x.includes(gpi));

    return mean(ticksForGpi.map((x) => normalizedTickValues.get(x)).toArray());
  };

  const tickMap = new Map(
    gpis.map((x) => {
      const maxLength = 21;
      let description = gpiDescriptionMap[x];

      if (isNil(description)) {
        description = x;
      } else if (description.length > maxLength) {
        description = description.substring(0, maxLength).concat('...');
      }

      return [getXValue(x), description];
    }),
  );

  const firstStart = minDate(
    claims.map((x) =>
      x.claimStatus === 'Paid'
        ? new Date(x.prescriptionStart)
        : new Date(x.filledDate),
    ),
  );
  const lastEnd = maxDate(
    claims.map((x) =>
      x.claimStatus === 'Paid'
        ? new Date(x.prescriptionEnd)
        : new Date(x.filledDate),
    ),
  );

  const dateDomain = [
    maxDate([subMonths(new Date(), 12), subMonths(firstStart, 1)]),
    maxDate([lastEnd, addMonths(new Date(), 1)]),
  ];

  // Chart Bar Calculations
  // max bar height of 50, min bar height of 10. This should handle up to 25 rows and allows 5px spacing between each
  const defaultBarWidth = min(
    50,
    max(10, (height - 50 - 5 * (gpis.length + 1)) / gpis.length),
  );

  const barWidthMap = new Map(
    uniqSplitGpis.map((x) => {
      const numberOfOverlaps =
        uniqSplitGpis.filter((y) => y.includes(x.substring(0, 14))).length - 1;

      if (numberOfOverlaps === 0) return [x, defaultBarWidth];

      return [x, max(8, defaultBarWidth / 2 - 5)];
    }),
  );

  // normalized chart data
  const data = sortedClaims.map((x) =>
    mergeRight(x, {
      x: normalizedTickValues.get(x.splitGpi),
      y: maxDate([dateDomain[0], x.prescriptionStart]),
      y0: minDate([dateDomain[1], x.prescriptionEnd]),
      outsideDomain:
        !isWithinInterval(x.prescriptionStart, {
          start: dateDomain[0],
          end: dateDomain[1],
        }) ||
        !isWithinInterval(x.prescriptionEnd, {
          start: dateDomain[0],
          end: dateDomain[1],
        }),
    }),
  );

  const refillData = data
    .filter((x) => !isNil(x.refillWindowStart))
    .map((x) => ({
      x: x.x,
      y: maxDate([dateDomain[0], x.refillWindowStart]),
      y0: minDate([dateDomain[1], x.prescriptionEnd]),
      gpi: x.splitGpi,
    }));

  const rejectedData = pipe(
    filter((x) => x.claimStatus === 'Rejected'),
    map((x) => ({
      x: getXValue(x.gpi),
      y: new Date(x.filledDate),
      totalRejections: claims.filter(
        (c) =>
          c.claimStatus === 'Rejected' &&
          c.filledDate === x.filledDate &&
          c.gpi === x.gpi,
      ).length,
    })),
    uniq,
  )(claims);

  const filledDateData = data
    .map((x) => ({
      x: x.x,
      y: x.filledDate,
    }))
    .filter((x) => isAfter(x.y, dateDomain[0]));

  const startEndPoints = pipe(
    map((x) => [
      {
        x: x.x,
        y: x.prescriptionStart,
      },
      {
        x: x.x,
        y: x.prescriptionEnd,
      },
    ]),
    flatten,
    uniq,
    filter((x) => isAfter(x.y, dateDomain[0])),
  )(data);

  const gpiRows = uniqBy(prop('x'))(data);

  const naiveWindowsInDomain = naiveWindows.filter((x) =>
    areIntervalsOverlapping(
      { start: x.start, end: x.end },
      { start: dateDomain[0], end: dateDomain[1] },
    ),
  );

  const ShortAxisLine = (props) => {
    let gpi = uniqBy(prop('x'))(data)[props.index]?.x;
    let x2 = pipe(
      filter((x) => x.x === gpi),
      reduce(
        minBy((x) => x.y),
        { y: dateDomain[1] },
      ),
      prop('y'),
      props.scale.y,
    )(data);

    return (
      <LineSegment
        {...props}
        x2={x2}
        style={{ ...props.style, strokeDasharray: '5,5' }}
      />
    );
  };

  //  Walkthrough Information
  const TourStopDetails = ({ children, title }) => (
    <div className={styles.tourContainer}>
      <h4 className={styles.tourStopTitle}>{title}</h4>
      {children}
    </div>
  );

  const steps = [
    {
      selector: '.opioidChart',
      content: () => (
        <TourStopDetails title={'Welcome to the Opioid Overview Chart!'}>
          This chart can be used to explore opioid and opioid combinator fills
          for the patient.
          <div className={styles.details}>
            You will find details relating to:
            <ul>
              <li>Prescription Fill Information</li>
              <li>Refill Periods</li>
              <li>Rejected Claims</li>
              <li>Daily MME Levels</li>
              <li>Opioid Naive Windows</li>
            </ul>
          </div>
        </TourStopDetails>
      ),
      enabled: true,
    },
    {
      selector: '[tour-stop="fill"]',
      content: () => (
        <TourStopDetails title={'Prescription Fills'}>
          The claims are grouped at the GPI 14 level. Each fill has the
          following details:
          <ul>
            <li>Prescription Fill Date</li>
            <li>Rx Number on Fill</li>
            <li>Prescription Start and End Dates</li>
            <li>Refill Periods</li>
          </ul>
        </TourStopDetails>
      ),
      enabled: data.length > 0,
    },
    {
      selector: '[data-tut="rejects"] > path',
      content: () => (
        <TourStopDetails title="Rejections">
          Each red dot on the screen represents a day on which a rejected claim
          for that GPI occurred.
          <div>
            The size of the dot increases as the number of rejections for the
            day increases.
          </div>
        </TourStopDetails>
      ),
      enabled: rejectedData.length > 0,
    },
    {
      selector: '[data-tut="naive"]',
      content: () => (
        <TourStopDetails title="Opioid Naive Windows">
          Each shaded area of the chart represents when the patient would be
          considered opioid naive.
        </TourStopDetails>
      ),
      enabled: !naiveWindowsInDomain.isEmpty(),
    },
    {
      selector: '[tour-stop="mme"]',
      content: () => (
        <TourStopDetails title="Daily MME">
          Each colored bar represents the amount of MME that patient is
          prescribed each day
          <VictoryLegend
            style={{
              data: {
                opacity: 0.4,
              },
              labels: {
                fontFamily: 'roboto',
                fontSize: 12,
              },
            }}
            width={250}
            height={75}
            data={[
              { name: 'less than 90', symbol: { fill: '#4DC47E' } },
              { name: '90 - 200', symbol: { fill: '#F6A823' } },
              { name: 'greater than 200', symbol: { fill: '#C72D3F' } },
            ]}
          />
        </TourStopDetails>
      ),
      enabled: dailyMme.count((x) => x.mme > 0) > 0,
    },
  ];

  return (
    <>
      <Tour
        isOpen={isTourOpen}
        rounded={5}
        steps={steps.filter((x) => x.enabled)}
        accentColor={'#f4ac00'}
        onRequestClose={onTourClose}
        startAt={0}
      />
      <VictoryChart
        scale={{ y: 'time' }}
        horizontal
        domain={{ y: dateDomain }}
        padding={{ top: 32, bottom: 32, left: 150, right: 32 }}
        maxDomain={Math.ceil(reduce(max, 0, tickMap.keySeq().toArray())) + 1}
        height={height}
        theme={VictoryTheme.material}
        width={width}
        containerComponent={
          <VictoryCursorContainer
            className="opioidChart"
            cursorComponent={<div />}
            onCursorChange={(value, props) => {
              const closestValue = reduce(
                minBy((x) => Math.abs(value?.x - x)),
                Infinity,
                gpiRows.map((x) => x.x),
              );

              const row = gpiRows.find((x) => x.x === closestValue)?.x;
              if (row !== focusedRow) {
                setFocusedRow(row);
              }
            }}
          />
        }
      >
        <VictoryLegend
          title="Total MME "
          style={{
            data: {
              opacity: 0.4,
            },
          }}
          orientation="horizontal"
          y={5}
          width={432}
          x={width / 2 - 432 / 2}
          gutter={15}
          titleOrientation="left"
          data={[
            { name: 'less than 90', symbol: { fill: '#4DC47E' } },
            { name: '90 - 200', symbol: { fill: '#F6A823' } },
            { name: 'greater than 200', symbol: { fill: '#C72D3F' } },
          ]}
        />

        <DailyMmeChart mme={dailyMme} />
        {!naiveWindowsInDomain.isEmpty() && (
          <OpioidNaiveChart naiveWindows={naiveWindowsInDomain} />
        )}
        <VictoryAxis
          style={{
            axis: { stroke: '#756f6a' },
            grid: { stroke: '#d0d0d0' },
          }}
          tickValues={tickMap.keySeq().toArray()}
          gridComponent={<ShortAxisLine />}
          tickFormat={(d) => tickMap.get(d)}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: '#756f6a' },
            grid: { stroke: '#d0d0d0' },
          }}
          gridComponent={<LineSegment />}
        />
        <VictoryBar
          name="Base-Bars"
          style={{
            data: {
              fill: ({ datum }) => {
                const issue = issues?.find((x) => x.id === selectedIssueId);
                if (datum.workItemId === issue?.primaryClaim?.id)
                  return '#2196f3';
                if (issue?.claims?.find((x) => x.id === datum.workItemId))
                  return '#f4ac00';
                return '#FAD68F';
              },
              width: ({ datum }) => barWidthMap.get(datum.gpi),
              overflow: 'hidden',
            },
          }}
          data={data}
          dataComponent={<CustomBar />}
          groupComponent={<g data-tut="fills" />}
        />
        <VictoryBar
          name="Refill-Windows"
          data={refillData}
          style={{
            data: {
              fill: '#fff',
            },
          }}
          dataComponent={<DiagonalHatchBar barWidthMap={barWidthMap} />}
        />

        <VictoryBar
          name="Interactable-Bars"
          style={{
            data: {
              fill: '#ff000000',
              stroke: '#000',
              strokeWidth: '1px',
              width: ({ datum }) => barWidthMap.get(datum.gpi),
            },
            labels: {
              pointerEvents: 'none',
            },
          }}
          labels={({ datum }) => datum.rxNumber}
          labelComponent={<RxLabel />}
          data={data}
        />
        <VictoryScatter
          name="Rejected-Claims"
          style={{ data: { fill: '#F72C2C' } }}
          data={rejectedData}
          maxBubbleSize={8}
          minBubbleSize={5}
          groupComponent={<g data-tut="rejects" />}
          bubbleProperty="totalRejections"
        />
        <VictoryScatter
          name="Start-End-Tooltips"
          data={startEndPoints}
          labels={({ datum }) => format(datum.y, 'MM/dd')}
          style={{
            data: { fill: '#ff000000' },
          }}
          labelComponent={<Tooltip focusedRow={focusedRow} />}
        />
        <VictoryScatter
          name="Filled-Date-Points"
          style={{
            data: { fill: '#000' },
          }}
          data={filledDateData}
        />

        <VictoryLine
          name="Today-Line"
          labels={['Today']}
          standalone={false}
          labelComponent={
            <VictoryLabel renderInPortal dx={-25} dy={(height - 48) * -1} />
          }
          y={() => new Date()}
        />
      </VictoryChart>
    </>
  );
};

const OpioidOverviewChart = ({
  claims,
  issues,
  selectedIssueId,
  naiveWindows,
  hasFetched,
  error,
  height,
  width,
  onTourClose,
  isTourOpen,
  style,
}) => (
  <div style={{ height: '100%', width: '100%', ...style }}>
    {/* I don't like doing this check, but it does not appear
        the VictoryGroup respects the visibility of the div, without this
        the graph still renders in the upper left corner
      */}
    {hasFetched && claims.length > 0 && !isNil(width) && !isNil(height) && (
      <DrugChart
        claims={claims}
        issues={issues}
        selectedIssueId={selectedIssueId}
        naiveWindows={naiveWindows}
        height={max(0, height - 32)}
        width={max(0, width - 32)}
        isTourOpen={isTourOpen}
        onTourClose={onTourClose}
      />
    )}
    {(!hasFetched || isNil(width) || isNil(height)) && !error && (
      <Message
        icon={<CircularProgress style={{ marginBottom: '.5rem' }} />}
        message="Getting patient history..."
      />
    )}
    {claims.length === 0 && hasFetched && !error && (
      <Message
        icon={<Flag />}
        message="We could not find any opioid claims in the last 6 months for this
            patient."
      />
    )}
    {error && (
      <Message
        icon={<ErrorOutline />}
        message="Something went wrong while getting patient history."
      />
    )}
  </div>
);

OpioidOverviewChart.propTypes = {
  claims: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  error: PropTypes.object,
};

export default withResizeDetector(OpioidOverviewChart);
