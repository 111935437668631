import { ArrowDownward } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isNil, isEmpty, anyPass } from 'ramda';
import React from 'react';
import { SortDirection } from 'react-virtualized';
import styles from './SortIndicator.css';

const isNullOrEmpty = anyPass([isNil, isEmpty]);

export default function SortIndicator({ sortDirection, className }) {
  return (
    <ArrowDownward
      className={classnames({
        [styles.icon]: true,
        [styles.ascending]: sortDirection === SortDirection.ASC,
        [className]: !isNullOrEmpty(className),
      })}
    />
  );
}

SortIndicator.propTypes = {
  sortDirection: PropTypes.oneOf([SortDirection.ASC, SortDirection.DESC])
    .isRequired,
  className: PropTypes.string,
};

SortIndicator.defaultProps = {
  className: null,
};
