import { OrderedSet } from 'immutable';
import { prop, pipe, sort, isNil } from 'ramda';
import { createSelector } from 'reselect';
import { graphqlSelector } from '../common/hooks/useQuery';
import graphql from '../common/utilities/graphql';
import { getSortValueSelector } from '../common/utilities/sorting';
import { getPatients } from '../patient/selectors';
import { getPrescribers } from '../prescribers/selectors';
import {
  getIssueListSortBy,
  getIssueListSortDirection,
  getIssueQueryResults,
} from '../ui/selectors';
import columnDefinitions from './components/IssueList/columns';

const getIssuesState = prop('issuesState');
const getIssues = pipe(getIssuesState, prop('issues'));
export const getIssuesFilters = pipe(getIssuesState, prop('filters'));

export const issueFragmentFactory = (name) => graphql`
  fragment ${name} on Issue {
    id
    number
    createdDate
    campaign {
      id
      name
      target
      entityId
    }
    effectiveDates {
      start
      end
    }
    target {
      ... on PatientType{
        id
        entityId
        name
        patientId
      }
      ... on PrescriberType{
        id
        entityId
        name
        npi
      }
    }
    supportingDocuments {
      ... on ClaimType{
        patientId
        patient {
          entityId
        }
        prescriber {
          entityId
        }
        filledDate
        claimNumber
      }
      ... on PatientType{
        patientId
        entityId
      }
      __typename
    }   
  }
`;

export const getIssue = (state, id) => getIssues(state).get(id);

const getIssueFromQueryResults = (state) => {
  const queryResults = getIssueQueryResults(state).toIndexedSeq();

  const query = graphql`
    ${issueFragmentFactory('issueFragment')}
    query issues($ids: [ID]!) {
      issues(ids: $ids) {
        ...issueFragment
      }
    }
  `;

  const { issues } = graphqlSelector(state, query, { ids: queryResults });

  return new OrderedSet(
    sort(
      (a, b) =>
        queryResults.findIndex((c) => c === a.id) -
        queryResults.findIndex((c) => c === b.id),
      issues,
    ),
  );
};

export const getTargets = (state) => {
  const patients = getPatients(state);
  const prescribers = getPrescribers(state);
  return patients
    .map((x) => ({
      entityId: x.entityId,
      name: x.name,
    }))
    .concat(
      prescribers.map((x) => ({
        entityId: x.entityId,
        name: x.name,
      })),
    );
};

export const getIssuesList = createSelector(
  getIssuesFilters,
  getIssueListSortDirection,
  createSelector(getIssueListSortBy, (sortBy) =>
    getSortValueSelector(sortBy, columnDefinitions()),
  ),
  getIssueFromQueryResults,
  (filters, sortDirection, getSortValueSelector, results) =>
    results.filter((i) => {
      const targetId = i.target.entityId;

      if (!filters.campaigns.isEmpty()) {
        if (!filters.campaigns.includes(i.campaign?.entityId)) {
          return false;
        }
      }

      if (!filters.targets.isEmpty()) {
        if (!filters.targets.includes(targetId)) {
          return false;
        }
      }
      // TODO:  differences between server and local time are causing newly created issues from showing up...
      // const date = new Date();
      // if (!isNil(filters.isEffective)) {
      //   if (
      //     i.effectiveDates.some(
      //       (effective) =>
      //         (isNil(effective.start) || effective.start <= date) &&
      //         (isNil(effective.end) || effective.end > date),
      //     ) !== filters.isEffective
      //   ) {
      //     console.log('filtered');
      //     debugger;
      //     return false;
      //   }
      // }

      return true;
      // TODO:  re-enable this line when cases have query tags on them
      // Trust the queryResults selector for now.
      //.sortBy(sortValueSelector, (a, b) => ascDescSort(a, b, sortDirection)),
    }),
);
