import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import ChipInput from 'app/filters/components/ChipInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class RXCUIConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'rxcui',
    title: 'RXCUI',
    targetType: IssueFilterType.Claim,
    component: (props: any) => (
      <ChipInput optionsListOnly={false} placeholder="Enter RXCUI" {...props} />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.rxcui)) return false;
      const { rxcui } = workItem.claim;

      return value.some((x) => rxcui.toUpperCase().startsWith(x.toUpperCase()));
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new RXCUIConfig();
