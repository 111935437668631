import {
  Fab,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Paper,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { toUpper, isNil, is } from 'ramda';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SaveButton from 'app/common/components/SaveButton';
import { ErrorRecord, GraphQlErrorRecord } from 'app/common/types';
import Table from '../../../common/components/Table/Table';
import UnexpectedError from '../../../common/components/UnexpectedError/UnexpectedError';
import Campaign from '../../../common/images/campaign.svg';
import styles from './CampaignList.css';
import columnDefinitions from './columns';

const CampaignList = ({
  campaigns,
  isFetching,
  isDeleteDialogOpen,
  onDeleteConfirm,
  onDeleteCancel,
  onDeleteRequest,
  onCloneConfirm,
  isCloning,
  isCloningError,
  isCloneComplete,
  cloneError,
  hasFetchingError,
  isDeleting,
  deleteError,
  canAddCampaign,
}) => {
  const { handleSubmit, control, formState } = useForm();

  const { errors: fieldErrors } = formState;

  const [cloneId, setCloneId] = useState('');
  const [open, setOpen] = useState(false);

  const openCloneDialog = (id) => {
    setCloneId(id);
    setOpen(true);
  };

  const onCloneCancel = () => {
    setCloneId('');
    setOpen(false);
  };

  const onSubmit = (data) => {
    if (cloneId) {
      const update = {
        id: cloneId,
        name: data.name,
        prefix: toUpper(data.prefix),
      };
      onCloneConfirm(update);
    }
  };

  let formError = false;
  cloneError.forEach((error) => {
    if (is(GraphQlErrorRecord, error)) {
      if (error.path.count() > 1) {
        const field = error.path.get(2);
        if (!isNil(field) && isNil(fieldErrors[field])) {
          fieldErrors[field] = { type: 'server', message: error.message };
        }
      } else {
        formError = true;
      }
    }
    if (is(ErrorRecord, error)) {
      formError = true;
    }
  });

  return (
    <Paper className={styles.container}>
      {!hasFetchingError ? (
        <Table
          isFetching={isFetching}
          data={campaigns}
          columnDefinitions={columnDefinitions(
            onDeleteRequest,
            openCloneDialog,
          )}
        />
      ) : (
        hasFetchingError && (
          <UnexpectedError
            description={
              <>
                <span>
                  There was an error loading the page. Please refresh your
                  browser. If the issue persists, please contact your company
                  administrator for assistance.
                </span>
              </>
            }
          />
        )
      )}
      <Link to="create">
        <Fab
          color="primary"
          disabled={!canAddCampaign}
          classes={{ root: styles.create }}
        >
          <Add />
        </Fab>
      </Link>

      <Dialog open={isDeleteDialogOpen}>
        <DialogContent>
          Are you sure you want to delete this campaign?
          {deleteError && (
            <div className={styles.error}>
              Error encountered while deleting the campaign.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onDeleteConfirm}
            variant="contained"
            color="primary"
            autoFocus
            disabled={isDeleting}
          >
            {!isDeleting ? 'Delete' : <CircularProgress size={20} />}
          </Button>
          <Button onClick={onDeleteCancel} variant="text" disabled={isDeleting}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open}>
        <DialogContent>
          <div className={styles.dialogFormContainer}>
            <Paper className={styles.formContainer}>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formRow}>
                  <Paper elevation={2} className={styles.headerIcon}>
                    <SvgIcon fontSize="large">
                      <Campaign />
                    </SvgIcon>
                  </Paper>
                  <div className={styles.headerText}>Copy Campaign</div>
                </div>
                <div className={styles.formRow}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        classes={{ root: styles.input }}
                        label="New Campaign Name"
                        helperText={
                          fieldErrors.name ? (
                            <>
                              {fieldErrors.name.type === 'required' && (
                                <div>Name is required</div>
                              )}
                              {fieldErrors.name.type === 'server' && (
                                <div>{fieldErrors.name.message}</div>
                              )}
                            </>
                          ) : null
                        }
                        error={fieldErrors.name}
                      />
                    )}
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </div>
                <div className={styles.formRow}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        classes={{ root: styles.input }}
                        label="New Campaign Prefix"
                        placeholder="A 3 character shorthand name. ex: ABC"
                        helperText={
                          fieldErrors.prefix ? (
                            <>
                              {fieldErrors.prefix.type === 'required' && (
                                <div>Prefix is required</div>
                              )}
                              {(fieldErrors.prefix.type === 'minLength' ||
                                fieldErrors.prefix.type === 'maxLength') && (
                                <div>Prefix must be 3 characters long</div>
                              )}
                              {fieldErrors.prefix.type === 'server' && (
                                <div>{fieldErrors.prefix.message}</div>
                              )}
                            </>
                          ) : null
                        }
                        error={fieldErrors.prefix}
                      />
                    )}
                    name="prefix"
                    control={control}
                    rules={{
                      required: true,
                      minLength: 3,
                      maxLength: 3,
                    }}
                  />
                </div>
                <div className={styles.submitRow}>
                  <Button
                    onClick={onCloneCancel}
                    variant="text"
                    disabled={isCloning}
                  >
                    Cancel
                  </Button>
                  <SaveButton
                    type="submit"
                    isSaving={isCloning}
                    color="primary"
                    variant="contained"
                    disabled={isCloning}
                  >
                    {!isCloning ? 'Create' : <CircularProgress size={20} />}
                  </SaveButton>
                </div>
              </form>
            </Paper>
          </div>
          {formError && (
            <div className={styles.error}>
              Something went wrong, please try again or contact us.
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

CampaignList.propTypes = {
  campaigns: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired,
  onDeleteCancel: PropTypes.func.isRequired,
  onDeleteRequest: PropTypes.func.isRequired,
  onCloneConfirm: PropTypes.func.isRequired,
  isCloning: PropTypes.bool,
  isCloningError: PropTypes.bool,
  isCloneComplete: PropTypes.bool,
  cloneError: PropTypes.array,
  hasFetchingError: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  deleteError: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  canAddCampaign: PropTypes.bool.isRequired,
};

CampaignList.defaultProps = {
  deleteError: null,
  isDeleting: false,
  cloneError: [],
  isCloning: false,
};

export default CampaignList;
