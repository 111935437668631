import { ListItem } from '@material-ui/core';
import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import MatchChipInput from 'app/filters/components/MatchChipInput';
import { IssueFilterType, MatchStrings } from 'app/filters/constants';
import { matchStringsDefaults } from 'app/filters/definitions/defaults';
import { createRejectCodesOptionsQueryHandler } from 'app/filters/definitions/utility';
import {
  IClaimFilterConfiguration,
  MatchStringsInputRecord,
  MultiSelectMatchFilterRecord,
  MultiSelectMatchStoredValue,
  SelectValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const handler = createRejectCodesOptionsQueryHandler();
class ExcludeRejectCodesConfig
  extends Record({
    ...matchStringsDefaults,
    name: 'excludeRejectCodes',
    title: 'Exclude Reject Codes',
    targetType: IssueFilterType.Claim,
    options: (token: string) => handler(token),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => (
      <MatchChipInput
        placeholder="Enter reject codes"
        noOptionsFoundComponent={<ListItem>Invalid reject code...</ListItem>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: MatchStringsInputRecord,
    ) => {
      if (value.values.isEmpty()) return true;

      if (
        isNil(workItem.claim.rejectCodes) ||
        workItem.claim.rejectCodes.isEmpty()
      )
        return false;

      if (value.match === MatchStrings.Any) {
        return !value.values.some((v) =>
          workItem.claim.rejectCodes.contains(v),
        );
      }

      if (value.match === MatchStrings.All) {
        return !value.values.every((v) =>
          workItem.claim.rejectCodes.contains(v),
        );
      }

      return false;
    },
  })
  implements
    IClaimFilterConfiguration<
      MultiSelectMatchFilterRecord,
      OrderedSet<SelectValueRecord>,
      MatchStringsInputRecord,
      MultiSelectMatchStoredValue
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExcludeRejectCodesConfig();
