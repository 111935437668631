import { FindInPage } from '@material-ui/icons';
import React from 'react';
import styles from './NotFound.css';

const NotFound = () => (
  <div className={styles.container}>
    <div className={styles.contentWrap}>
      <FindInPage className={styles.icon} />
      <h1 className={styles.header}>Page Not Found</h1>
      <div className={styles.description}>
        The page you are looking for doesn't exist or has been moved.
      </div>
    </div>
  </div>
);

export default NotFound;
