import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  MatchesClaimValuesRecord,
  DayRangeValueRecord,
  GPILevelValueRecord,
} from '../../types';
import RadialFilter from '../RadialFilter';

const GPILevelSelector = ({ currentGPILevel, gpiLevels, onChangeGPILevel }) => {
  const menuItems = gpiLevels.map((gpiLevel) => (
    <MenuItem key={gpiLevel.value} value={gpiLevel.value}>
      {gpiLevel.text}
    </MenuItem>
  ));
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel>Using GPI</InputLabel>
      <Select
        value={currentGPILevel.value}
        onChange={(event) => onChangeGPILevel(event.target.value)}
        input={<Input name="age" />}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

GPILevelSelector.propTypes = {
  currentGPILevel: ImmutablePropTypes.recordOf({
    text: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onChangeGPILevel: PropTypes.func.isRequired,
  gpiLevels: ImmutablePropTypes.listOf(
    ImmutablePropTypes.recordOf({
      text: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

const DayRangeSelector = ({ currentDayRange, dayRanges, onChangeDayRange }) => {
  const menuItems = dayRanges.map((dayRange) => (
    <MenuItem
      key={dayRange.value}
      value={dayRange.value}
      primaryText={dayRange.text}
    >
      {dayRange.text}
    </MenuItem>
  ));
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel>Within</InputLabel>
      <Select
        value={currentDayRange.value}
        onChange={(event) => onChangeDayRange(event.target.value)}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
};

DayRangeSelector.propTypes = {
  currentDayRange: ImmutablePropTypes.recordOf({
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  onChangeDayRange: PropTypes.func.isRequired,
  dayRanges: ImmutablePropTypes.listOf(
    ImmutablePropTypes.recordOf({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
};

class MatchesClaim extends Component {
  static propTypes = {
    /** The initial values selected */
    value: ImmutablePropTypes.recordOf({
      isMatching: PropTypes.bool.isRequired,
      gpiLevel: ImmutablePropTypes.recordOf({
        text: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
      dayRange: ImmutablePropTypes.recordOf({
        text: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    /** The options for gpi and day range */
    options: ImmutablePropTypes.recordOf({
      gpiLevels: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
          text: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      dayRanges: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
          text: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    /** Gets called when the user changes a value */
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const isMatching = props.value ? props.value.isMatching : true;
    const gpiLevel = props.value
      ? props.value.gpiLevel
      : new GPILevelValueRecord();
    const dayRange = props.value
      ? props.value.dayRange
      : new DayRangeValueRecord();

    this.state = {
      isMatching,
      gpiLevel,
      dayRange,
    };
  }

  handleOnChange = () => {
    const { onChange } = this.props;
    const { isMatching, gpiLevel, dayRange } = this.state;

    onChange(
      new MatchesClaimValuesRecord({
        isMatching,
        gpiLevel,
        dayRange,
      }),
    );
  };

  handleOnChangeIsMatching = (value) => {
    this.setState({ isMatching: value }, () => this.handleOnChange());
  };

  handleOnChangeGPILevel = (value) => {
    const {
      options: { gpiLevels },
    } = this.props;
    this.setState({ gpiLevel: gpiLevels.find((v) => v.value === value) }, () =>
      this.handleOnChange(),
    );
  };

  handleOnChangeDayRange = (value) => {
    const {
      options: { dayRanges },
    } = this.props;
    this.setState({ dayRange: dayRanges.find((v) => v.value === value) }, () =>
      this.handleOnChange(),
    );
  };

  render() {
    const { value, options } = this.props;
    return (
      <Fragment>
        <RadialFilter
          value={value.isMatching}
          onChange={this.handleOnChangeIsMatching}
        />
        <GPILevelSelector
          currentGPILevel={value.gpiLevel}
          gpiLevels={options.gpiLevels}
          onChangeGPILevel={this.handleOnChangeGPILevel}
        />
        <DayRangeSelector
          currentDayRange={value.dayRange}
          dayRanges={options.dayRanges}
          onChangeDayRange={this.handleOnChangeDayRange}
        />
      </Fragment>
    );
  }
}

export default MatchesClaim;
