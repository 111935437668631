import { prop, pipe, map } from 'ramda';
import { createSelector } from 'reselect';

const getNotificationState = prop('notificationState');

export const getNotifications = pipe(
  getNotificationState,
  map((x) => x.merge({ timestamp: new Date(x.timestamp) })),
  (x) => x.toSet(),
);

export const getNotificationCount = createSelector(
  getNotifications,
  (notifications) => notifications.count(),
);
