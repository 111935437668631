import { ListItem } from '@material-ui/core';
import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import ChipInput from 'app/filters/components/ChipInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import {
  autocompleteQueryFactory,
  userAutoCompleteFragmentFactory,
} from 'app/filters/queries';
import {
  IClaimFilterConfiguration,
  KeyValueRecord,
  SelectValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const fragmentName = 'users';
const query = autocompleteQueryFactory(
  userAutoCompleteFragmentFactory(fragmentName),
  fragmentName,
);

const currentUser = new SelectValueRecord({
  text: 'Current User',
  value: 'CurrentUser',
});

const unassigned = new SelectValueRecord({
  text: 'Unassigned',
  value: 'Unassigned',
});

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(query, { search: null, autocompleteType: 'USERS' }, token)
    .then(throwIfErrors)
    .then((response) => response.data.autocomplete)
    .then((users: Array<{ id: string; name: string }>) =>
      OrderedSet(
        users.map(
          (u) =>
            new SelectValueRecord({
              text: u.name,
              value: u.id,
            }),
        ),
      )
        .add(unassigned)
        .add(currentUser),
    );

class AssigneesConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'assignees',
    title: 'Assignee',
    targetType: IssueFilterType.Claim,
    workflowSpecific: true,
    options: (token: string) => fetchOptions(token),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => (
      <ChipInput
        optionsListOnly
        placeholder="Enter Assignees"
        noOptionsFoundComponent={<ListItem>User not found...</ListItem>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
    filterWorkItem: (
      workItem: WorkItemRecord,
      value: Set<string>,
      userId: string | undefined,
    ) => {
      if (value.isEmpty()) return true;

      if (isNil(workItem.assignee)) {
        return value.contains(unassigned.value);
      }

      if (
        value.contains(currentUser.value) &&
        workItem.assignee.id === userId
      ) {
        return true;
      }

      return value.contains(workItem.assignee.id);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new AssigneesConfig();
