import { isNil } from 'ramda';
import { GraphqlTypes } from 'app/common/types';

function traverse(filterType, filter) {
  const field = {
    id: filter.id,
    name: filter.name,
    type: filter.type,
    required: filter.required,
  };

  // Check to see if nesting is too deep
  if (filterType.type === undefined) {
    throw new Error('Cannot determine type');
  }

  // have we reached the lowest type level
  if (!filterType.type) {
    field.type = filterType.name + field.type;

    return field;
  }

  // any list present, add array to the type
  if (filterType.kind === GraphqlTypes.list) {
    field.type += '[]';
  }

  // dig deeper into the type and build up the filter type
  return traverse(filterType.type, field);
}

function buildSchema(inputField) {
  let filter = {
    id: inputField.id,
    name: inputField.name,
    type: '',
    required: inputField.type.kind === GraphqlTypes.nonNull,
  };

  filter = traverse(inputField.type, filter);

  return filter;
}

export function mapToFilterSchema(fieldDefinitions, componentConfig) {
  return fieldDefinitions
    .filter((def) => {
      const config = componentConfig.find((x) => x.name === def.name);
      return !isNil(config) && config.enabled;
    })
    .map((def) => ({
      ...def,
      id: componentConfig.find((x) => x.name === def.name).id,
    }))
    .map(buildSchema);
}

export function mapFiltersForCommit(filters) {
  return filters.toArray().map((filter) => {
    return {
      name: filter.name,
      value: JSON.stringify(filter.value),
      valueType: filter.valueType,
    };
  });
}
