import { Divider, Tabs, Tab } from '@material-ui/core';
import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { getActiveTabFromLocation } from 'app/common/utilities/generic';
import DrugHistory from './DrugHistory';
import PatientClaimsHistory from './PatientClaimsHistory';
import WorkItemComments from './WorkItemComments';
import styles from './WorkItemDetailView.css';
import WorkItemDetails from './WorkItemDetails';
import WorkItemHeader from './WorkItemHeader';
import WorkItemInfo from './WorkItemInfo';

const Layout = ({
  children,
  userId,
  workItem,
  users,
  statuses,
  isFetchingUsers,
  onChangeAssignee,
  onChangeStatus,
  isFetchingWorkItem,
  errorFetchingWorkItem,
  isFetchingStatuses,
  hasFetchedDetails,
  onAddComment,
  erroredComments,
  editingComments,
  deletingComments,
  addingComments,
  onResubmitComment,
  onEditComment,
  onDeleteComment,
  navigate,
  location,
}) => {
  const tabs = ['./', 'patient-history', 'drug-history'];
  const activeTab = getActiveTabFromLocation(tabs, location);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <WorkItemHeader workItem={workItem} />
        <Tabs
          classes={{
            root: styles.tabs,
          }}
          value={activeTab}
          onChange={(e, v) => navigate(v)}
          indicatorColor="primary"
        >
          <Tab
            classes={{ root: styles.tabLabel, selected: styles.tabActive }}
            label="Overview"
            value="./"
          />
          <Tab
            classes={{ root: styles.tabLabel, selected: styles.tabActive }}
            label="Patient History"
            value="patient-history"
          />
          <Tab
            classes={{ root: styles.tabLabel, selected: styles.tabActive }}
            label="Drug History"
            value="drug-history"
          />
        </Tabs>
        {children}
      </div>
      <div className={styles.rightContainer}>
        <WorkItemInfo
          workItem={workItem}
          users={users}
          statuses={statuses}
          isFetchingUsers={isFetchingUsers}
          onChangeAssignee={onChangeAssignee}
          onChangeStatus={onChangeStatus}
          isFetchingWorkItem={isFetchingWorkItem}
          errorFetchingWorkItem={errorFetchingWorkItem}
          isFetchingStatuses={isFetchingStatuses}
          hasFetchedDetails={hasFetchedDetails}
        />
        <Divider />
        <WorkItemComments
          userId={userId}
          onAddComment={onAddComment}
          comments={workItem && workItem.comments}
          erroredComments={erroredComments}
          editingComments={editingComments}
          deletingComments={deletingComments}
          addingComments={addingComments}
          isFetchingWorkItem={isFetchingWorkItem}
          errorFetchingWorkItem={errorFetchingWorkItem}
          onResubmitComment={onResubmitComment}
          onEditComment={onEditComment}
          onDeleteComment={onDeleteComment}
        />
      </div>
    </div>
  );
};

const WorkItemDetailView = ({
  userId,
  workItem,
  isFetchingWorkItem,
  errorFetchingWorkItem,
  users,
  statuses,
  isFetchingUsers,
  onChangeAssignee,
  onChangeStatus,
  isFetchingStatuses,
  onAddComment,
  onResubmitComment,
  onEditComment,
  onDeleteComment,
  erroredComments,
  editingComments,
  deletingComments,
  addingComments,
  columnDefinitions,
  patientClaimsHistory,
  isFetchingPatientClaimsHistory,
  errorFetchingPatientClaimsHistory,
  scrollToIndexPatientClaimsHistory,
  hasFetchedDetails,
  hasFetchedPatientClaimsHistory,
  fetchDrugHistory,
  isFetchingPatientDrugHistory,
  drugHistory,
  scrollToIndexDrugHistory,
  errorFetchingPatientDrugHistory,
  onChangeTab,
  templateMatches,
  activeTemplateId,
  location,
}) => (
  <Router primary={false}>
    <Layout
      path="/"
      workItem={workItem}
      userId={userId}
      users={users}
      statuses={statuses}
      isFetchingUsers={isFetchingUsers}
      onChangeAssignee={onChangeAssignee}
      onChangeStatus={onChangeStatus}
      isFetchingWorkItem={isFetchingWorkItem}
      errorFetchingWorkItem={errorFetchingWorkItem}
      isFetchingStatuses={isFetchingStatuses}
      onAddComment={onAddComment}
      comments={workItem && workItem.comments}
      erroredComments={erroredComments}
      editingComments={editingComments}
      deletingComments={deletingComments}
      addingComments={addingComments}
      onResubmitComment={onResubmitComment}
      onEditComment={onEditComment}
      onDeleteComment={onDeleteComment}
      onChangeTab={onChangeTab}
      hasFetchedDetails={hasFetchedDetails}
      location={location}
    >
      <WorkItemDetails
        default
        path="overview"
        workItem={workItem}
        isFetchingWorkItem={isFetchingWorkItem}
        errorFetchingWorkItem={errorFetchingWorkItem}
        hasFetchedDetails={hasFetchedDetails}
        templateMatches={templateMatches}
        activeTemplateId={activeTemplateId}
      />

      <PatientClaimsHistory
        path="patient-history"
        scrollToIndex={scrollToIndexPatientClaimsHistory}
        columnDefinitions={columnDefinitions}
        claims={patientClaimsHistory.claims}
        dateRange={patientClaimsHistory.dateRange}
        hasAdditionalClaims={patientClaimsHistory.hasAdditionalClaims}
        isFetchingClaims={isFetchingPatientClaimsHistory}
        errorFetchingClaims={errorFetchingPatientClaimsHistory}
        hasFetchedDetails={hasFetchedDetails}
        hasFetchedClaims={hasFetchedPatientClaimsHistory}
        patientId={workItem && workItem.claim ? workItem.claim.patientId : null}
      />
      {workItem && (
        <DrugHistory
          path="drug-history"
          claim={workItem.claim}
          isFetchingPatientDrugHistory={isFetchingPatientDrugHistory}
          history={drugHistory.claims}
          dateRange={drugHistory.dateRange}
          hasAdditionalClaims={drugHistory.hasAdditionalClaims}
          scrollToIndex={scrollToIndexDrugHistory}
          errorFetching={errorFetchingPatientDrugHistory}
        />
      )}
    </Layout>
  </Router>
);

WorkItemDetailView.propTypes = {
  workItem: ImmutablePropTypes.recordOf({
    id: PropTypes.string.isRequired,
    status: ImmutablePropTypes.record.isRequired,
    assignee: ImmutablePropTypes.recordOf({
      id: PropTypes.string.isRequired,
    }),
    claim: ImmutablePropTypes.record.isRequired,
  }),
  patientClaimsHistory: ImmutablePropTypes.recordOf({
    claims: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
    dateRange: ImmutablePropTypes.recordOf({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }).isRequired,
    hasAdditionalClaims: PropTypes.bool.isRequired,
  }).isRequired,
  drugHistory: ImmutablePropTypes.recordOf({
    claims: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
    dateRange: ImmutablePropTypes.recordOf({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    }).isRequired,
    hasAdditionalClaims: PropTypes.bool.isRequired,
  }).isRequired,
  isFetchingWorkItem: PropTypes.bool.isRequired,
  errorFetchingWorkItem: PropTypes.object,
  users: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.string.isRequired,
  ).isRequired,
  statuses: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  onChangeAssignee: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  isFetchingStatuses: PropTypes.bool.isRequired,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isFetchingPatientClaimsHistory: PropTypes.bool.isRequired,
  errorFetchingPatientClaimsHistory: PropTypes.object,
  scrollToIndexPatientClaimsHistory: PropTypes.number,
  hasFetchedDetails: PropTypes.bool.isRequired,
  hasFetchedPatientClaimsHistory: PropTypes.bool.isRequired,
  scrollToIndexDrugHistory: PropTypes.number,
  errorFetchingPatientDrugHistory: PropTypes.object,
  onChangeTab: PropTypes.func,
  selectedTab: PropTypes.string,
  onAddComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  templateMatches: PropTypes.array.isRequired,
  activeTemplateId: PropTypes.string,
};

WorkItemDetailView.defaultProps = {
  workItem: null,
  onChangeTab: () => {},
  selectedTab: null,
  activeTemplateId: null,
};

export default WorkItemDetailView;
