import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { SearchType } from 'app/search/types';
import Search from '../../../search/containers/Search';
import ErrorBoundary from '../ErrorBoundary';
import SideBar from '../SideBar';
import styles from './AuthenticatedLayout.css';

const AuthenticatedLayout = ({
  children,
  isSidebarMinimized,
  onSidebarToggleMinimize,
  onSignOut,
  user,
  isSnackbarOpen,
  message,
  handleSnackbarClose,
  currentPath,
  notificationCount,
  isNotificationPanelOpen,
  onNotificationPanelRequestClose,
  onNotificationPanelRequestOpen,
  onSearchRequestOpen,
  onSearchRequestClose,
  isSearchOpen,
  onSearchClick,
}) => (
  <div
    className={styles.pageContainer}
    onKeyDown={(e) => {
      if (e.ctrlKey && e.shiftKey && e.which === 70) {
        onSearchRequestOpen();
      }
    }}
  >
    <SideBar
      isMinimized={isSidebarMinimized}
      onToggleMinimize={onSidebarToggleMinimize}
      onSignOut={onSignOut}
      user={user}
      currentPath={currentPath}
      notificationCount={notificationCount}
      isNotificationPanelOpen={isNotificationPanelOpen}
      onNotificationPanelRequestClose={onNotificationPanelRequestClose}
      onNotificationPanelRequestOpen={onNotificationPanelRequestOpen}
      onSearchClick={onSearchRequestOpen}
    />
    <ErrorBoundary>
      <div className={styles.mainContainer}>
        <Search
          isOpen={isSearchOpen}
          onClose={onSearchRequestClose}
          onClick={onSearchClick}
          searchTypes={[
            SearchType.Claims,
            SearchType.Patients,
            SearchType.Cases,
          ]}
        />
        <div className={styles.contentContainer}>{children}</div>
        <Snackbar
          open={isSnackbarOpen}
          message={message}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        />
      </div>
    </ErrorBoundary>
  </div>
);

AuthenticatedLayout.propTypes = {
  children: PropTypes.array.isRequired,
  isSidebarMinimized: PropTypes.bool.isRequired,
  onSidebarToggleMinimize: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isSnackbarOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleSnackbarClose: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  isNotificationPanelOpen: PropTypes.bool.isRequired,
  onNotificationPanelRequestClose: PropTypes.func.isRequired,
  onNotificationPanelRequestOpen: PropTypes.func.isRequired,
};

export default AuthenticatedLayout;
