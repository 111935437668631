export const CASE_FETCH = 'CASE/CASE_FETCH_START';
export const CASE_FETCH_SUCCESS = 'CASE/CASE_FETCH_SUCCESS';
export const CASE_FETCH_FAIL = 'CASE/CASE_FETCH_FAIL';

export const CASE_UPSERT = 'CASE/CASE_UPSERT_START';
export const CASE_UPSERT_SUCCESS = 'CASE/CASE_UPSERT_SUCCESS';
export const CASE_UPSERT_FAIL = 'CASE/CASE_UPSERT_FAIL';

export const BULK_CASE_UPSERT = 'CASE/BULK_CASE_UPSERT_START';
export const BULK_CASE_UPSERT_SUCCESS = 'CASE/BULK_CASE_UPSERT_SUCCESS';
export const BULK_CASE_UPSERT_FAIL = 'CASE/BULK_CASE_UPSERT_FAIL';

export const CASES_QUERY_FETCH_START = 'CASE/CASES_QUERY_FETCH_START';
export const CASES_QUERY_FETCH_SUCCESS = 'CASE/CASES_QUERY_FETCH_SUCCESS';
export const CASES_QUERY_FETCH_FAIL = 'CASE/CASES_QUERY_FETCH_FAIL';

export const CASES_FILTER_UPDATE = 'CASE/CASES_FILTER_UPDATE';
export const CASES_SORT_APPLY = 'CASE/CASES_SORT_APPLY';
export const CASES_QUERY_CLEAR = 'CASE/CASES_QUERY_CLEAR';
