import { IconButton, CircularProgress, Button } from '@material-ui/core';
import { ErrorOutline, Close } from '@material-ui/icons';
import { Link } from '@reach/router';
import classnames from 'classnames';
import { format, isToday } from 'date-fns';
import PropTypes from 'prop-types';
import { defaultTo, isNil, nth, pipe, prop } from 'ramda';
import React, { useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  CAMPAIGN_EDIT_FAIL,
  CAMPAIGN_CLONE_UPSERT_FAIL,
} from '../../../campaigns/constants';
import {
  CASE_UPSERT_FAIL,
  BULK_CASE_UPSERT_FAIL,
} from '../../../cases/constants';
import {
  COMMENT_ADD_FAIL,
  COMMENT_DELETE_FAIL,
  COMMENT_EDIT_FAIL,
} from '../../../comments/constants';
import { graphqlSelector } from '../../../common/hooks/useQuery';
import { isRunning } from '../../../ui/selectors';
import {
  WORKITEM_SET_ASSIGNEE,
  WORKITEM_SET_STATUS,
} from '../../../workitems/constants';
import styles from './Notification.css';

const formatDate = (date) => {
  if (isToday(date)) {
    return format(date, 'h:mm a');
  }
  return format(date, 'MM/dd/yy');
};

const renderTitle = (notification) => {
  switch (notification.type) {
    case COMMENT_EDIT_FAIL:
      return 'Error editing comment';
    case COMMENT_ADD_FAIL:
      return 'Error saving comment';
    case COMMENT_DELETE_FAIL:
      return 'Error deleting comment';
    case WORKITEM_SET_STATUS:
      return 'Error setting status';
    case WORKITEM_SET_ASSIGNEE:
      return 'Error setting assignee';
    case CASE_UPSERT_FAIL:
      return 'Error saving case';
    case BULK_CASE_UPSERT_FAIL:
      return 'Error creating cases';
    case CAMPAIGN_EDIT_FAIL:
      return 'Error saving campaign';
    case CAMPAIGN_CLONE_UPSERT_FAIL:
      return 'Error copying campaign';
    default:
      return '';
  }
};

function CaseUpsertFailNotification({ notification }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    isRunning(state, notification.data.get('source')),
  );

  const { data } = notification;
  const source = data.get('source');

  const query = `
    query issues($ids: [ID]!) {
      issues(ids: $ids) {
        id
        target {
          ... on PatientType{
            name
          }
          ... on PrescriberType{
            name
          }
        }
      }
    }`;

  const { issues } = useSelector((state) =>
    graphqlSelector(state, query, { ids: source.payload.case.issues }),
  );

  const target = pipe(
    defaultTo([]),
    nth(0),
    prop('target'),
    prop('name'),
  )(issues);

  const formatIssues = () => (issues.length === 1 ? 'issue' : 'issues');

  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <div className={styles.caseNotificationContainer}>
          <div className={styles.caseNotificationDescription}>
            Failed to save the case with {issues.length} {formatIssues()}
            {!isNil(target) && <> for target {target}</>}.
          </div>
          <div className={styles.caseNotificationActionContainer}>
            <Button
              variant="text"
              color="secondary"
              className={styles.caseNotificationAction}
              onClick={() => dispatch(notification.data.get('source'))}
            >
              Retry
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

CaseUpsertFailNotification.propTypes = {
  notification: ImmutablePropTypes.recordOf({
    data: ImmutablePropTypes.mapOf({
      source: ImmutablePropTypes.record,
    }),
  }).isRequired,
};

const renderDescription = (notification) => {
  switch (notification.type) {
    case WORKITEM_SET_ASSIGNEE:
      return (
        <>
          Failed to set the assignee to {notification.data.get('name')},{' '}
          <Link to={`/workitems/${notification.data.get('workItemId')}`}>
            click here to view
          </Link>
          .
        </>
      );
    case WORKITEM_SET_STATUS:
      return (
        <>
          Failed to set the status to {notification.data.get('name')},{' '}
          <Link to={`/workitems/${notification.data.get('workItemId')}`}>
            click here to view
          </Link>
          .
        </>
      );
    case COMMENT_EDIT_FAIL:
    case COMMENT_DELETE_FAIL:
    case COMMENT_ADD_FAIL:
      return (
        <>
          <Link to={`/workitems/${notification.data.get('workItemId')}`}>
            Click here to view
          </Link>
          .
        </>
      );
    case CASE_UPSERT_FAIL:
      return <CaseUpsertFailNotification notification={notification} />;
    case BULK_CASE_UPSERT_FAIL:
      return <>Failed to create cases. </>;
    case CAMPAIGN_EDIT_FAIL:
      return (
        <>
          Failed to save campaign.{' '}
          <Link to={`/campaigns/${notification.data.get('id')}`}>
            click here to view
          </Link>
          .
        </>
      );
    case CAMPAIGN_CLONE_UPSERT_FAIL:
      return (
        <>
          Failed to copy campaign.{' '}
          <Link to={`/campaigns/${notification.data.get('id')}`}>
            click here to view
          </Link>
          .
        </>
      );
    default:
      return <></>;
  }
};

function Notification({ className, notification, onDismiss, style }) {
  const [isHovering, setHovering] = useState(false);

  return (
    <div
      style={style}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={classnames(styles.container, className)}
    >
      <div className={styles.icon}>
        <ErrorOutline style={{ height: 32, width: 32 }} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.header}>
          <div className={styles.title}>{renderTitle(notification)}</div>
          {!isHovering && (
            <div
              className={styles.date}
              title={format(notification.timestamp, 'MM/dd/yyyy [at] h:mm a')}
            >
              {formatDate(notification.timestamp)}
            </div>
          )}{' '}
          {isHovering && (
            <div className={styles.dismiss}>
              <IconButton onClick={() => onDismiss(notification.id)}>
                <Close />
              </IconButton>
            </div>
          )}
        </div>
        <div className={styles.description}>
          {renderDescription(notification)}
        </div>
      </div>
    </div>
  );
}

Notification.propTypes = {
  className: PropTypes.string,
  notification: ImmutablePropTypes.recordOf({
    timestamp: PropTypes.instanceOf(Date),
    id: PropTypes.string,
  }).isRequired,
  onDismiss: PropTypes.func.isRequired,
  style: PropTypes.object,
};

Notification.defaultProps = {
  className: '',
  style: {},
};

export default Notification;
