import { Map } from 'immutable';
import { persistReducer } from '../../hydrate/persist';
import {
  CASE_UPSERT,
  CASE_UPSERT_SUCCESS,
  BULK_CASE_UPSERT,
  BULK_CASE_UPSERT_SUCCESS,
  BULK_CASE_UPSERT_FAIL,
} from '../constants';
import { CaseRecord, BulkCreateCasesRecord } from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case CASE_UPSERT: {
      return state.merge({
        [action.payload.optimisticId]: new CaseRecord(action.payload.case),
      });
    }
    case CASE_UPSERT_SUCCESS: {
      return state.delete(action.payload.optimisticId);
    }
    case BULK_CASE_UPSERT: {
      return state.merge({
        [action.payload.optimisticId]: new BulkCreateCasesRecord(
          action.payload.bulkCreateCasesRecord,
        ),
      });
    }
    case BULK_CASE_UPSERT_SUCCESS: {
      return state.delete(action.payload.optimisticId);
    }
    default:
      return state;
  }
};

export default persistReducer('cases/optimistic', reducer);
