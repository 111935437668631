import {
  CircularProgress,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isNil, max, min } from 'ramda';
import ImmutablePropTypes from 'react-immutable-proptypes';
import HeaderBar from 'app/common/components/HeaderBar';
import SplitButton from 'app/common/components/SplitButton';
import Table from 'app/common/components/Table';
import UnexpectedError from 'app/common/components/UnexpectedError';
import ExportButton from 'app/export/containers/ExportButton';
import { IssueFilterType } from 'app/filters/constants';
import FilterSideBar from 'app/filters/containers/FilterSideBar';
import {
  workItemListFetchFilterOptionsStart,
  workItemlistFilterSetApplied,
  workItemListFilterSetEdited,
  workItemListUpdateFilters,
} from 'app/ui/actions';
import ViewTemplateSave from 'app/viewtemplates/components/ViewTemplateSave';
import ViewTemplateSelect from 'app/viewtemplates/containers/ViewTemplateSelect';
import styles from './WorkItemsListView.css';

const TemplateDeleteConfirm = ({
  id,
  isOpen,
  isDeleting,
  error,
  onConfirm,
  onCancel,
}) => (
  <Dialog open={isOpen}>
    <DialogContent className={styles.confirmText}>
      Are you sure you want to delete this template?
    </DialogContent>
    {error && (
      <DialogContent className={styles.error}>
        Error encountered while deleting template.
      </DialogContent>
    )}
    <DialogActions>
      <Button
        onClick={() => onConfirm(id)}
        variant="contained"
        color="primary"
        autoFocus
        disabled={isDeleting || !isOpen}
      >
        {!isDeleting && isOpen ? 'Delete' : <CircularProgress size={20} />}
      </Button>
      <Button onClick={onCancel} variant="text">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

TemplateDeleteConfirm.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

TemplateDeleteConfirm.defaultProps = {
  error: null,
};

const Header = ({
  isCurrentViewTemplateDirty,
  isQuickSaving,
  isUserCurrentViewTemplateOwner,
  isFilterSidebarMinimized,
  onDiscardChanges,
  onQuickSave,
  onTemplateSave,
  onTemplateSaveAs,
  onFilterSidebarToggle,
  onTemplateDeleteRequest,
  onViewTemplatesOpen,
  viewTemplate,
}) => {
  const isTemplateSelected = viewTemplate.id !== null;
  const isSystemTemplate =
    viewTemplate.tags && viewTemplate.tags.includes('system');
  const isTemplateEditable =
    isTemplateSelected &&
    (!isSystemTemplate || (isSystemTemplate && isUserCurrentViewTemplateOwner));
  const defaultLabel =
    isTemplateEditable && isCurrentViewTemplateDirty ? 'Save' : 'Save as';
  const defaultAction =
    isTemplateEditable && isCurrentViewTemplateDirty
      ? onQuickSave
      : onTemplateSaveAs;
  return (
    <HeaderBar>
      <div className={styles.headerLeft}>
        <div className={styles.headerTitle}>
          <Tooltip title="Toggle Filter Panel" placement="bottom">
            <IconButton onClick={onFilterSidebarToggle}>
              <FilterList
                className={classnames({
                  [styles.headerFilterIcon]: isFilterSidebarMinimized,
                  [styles.headerFilterIconActive]: !isFilterSidebarMinimized,
                })}
              />
            </IconButton>
          </Tooltip>
        </div>
        <div className={styles.headerTemplateSwitch}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onViewTemplatesOpen}
          >
            Templates
          </Button>
        </div>
        <div className={styles.headerTitle}>
          {isTemplateSelected ? viewTemplate.name : 'All Work Items'}
        </div>
        <div className={styles.headerDirtyStatus}>
          {isCurrentViewTemplateDirty && (
            <div className={styles.headerDirtyStatusText}>— Edited</div>
          )}
        </div>
        <div className={styles.headerSaveFilters}>
          <SplitButton
            defaultLabel={
              isQuickSaving ? <CircularProgress size={20} /> : defaultLabel
            }
            onDefaultClick={defaultAction}
            disabled={isQuickSaving}
          >
            {isTemplateSelected &&
              isTemplateEditable &&
              isCurrentViewTemplateDirty && (
                <MenuItem onClick={onTemplateSaveAs}>Save as</MenuItem>
              )}
            {isTemplateEditable && (
              <MenuItem onClick={onTemplateSave}>Edit</MenuItem>
            )}
            {isUserCurrentViewTemplateOwner && (
              <MenuItem onClick={onTemplateDeleteRequest}>Delete</MenuItem>
            )}
            {isCurrentViewTemplateDirty && (
              <MenuItem onClick={onDiscardChanges}>Discard changes</MenuItem>
            )}
          </SplitButton>
        </div>
      </div>
      <div className={styles.headerRight}>
        <ExportButton />
      </div>
    </HeaderBar>
  );
};

Header.propTypes = {
  isCurrentViewTemplateDirty: PropTypes.bool.isRequired,
  isQuickSaving: PropTypes.bool.isRequired,
  isUserCurrentViewTemplateOwner: PropTypes.bool.isRequired,
  isFilterSidebarMinimized: PropTypes.bool.isRequired,
  onDiscardChanges: PropTypes.func.isRequired,
  onQuickSave: PropTypes.func.isRequired,
  onTemplateSave: PropTypes.func.isRequired,
  onTemplateSaveAs: PropTypes.func.isRequired,
  onFilterSidebarToggle: PropTypes.func.isRequired,
  onViewTemplatesOpen: PropTypes.func.isRequired,
  viewTemplate: ImmutablePropTypes.recordOf({
    name: PropTypes.string,
  }).isRequired,
  onTemplateDeleteRequest: PropTypes.func.isRequired,
};

const WorkItemsListView = ({
  columnDefinitions,
  errorFetchingData,
  isCurrentViewTemplateDirty,
  isFetchingData,
  isFilterSidebarMinimized,
  isQuickSaving,
  isSaveModalOpen,
  isUserCurrentViewTemplateOwner,
  isSortEnabled,
  sortBy,
  sortDirection,
  onSort,
  onDiscardChanges,
  onFilterSidebarToggle,
  onHeaderClick,
  onQuickSave,
  onSaveSubmit,
  onSelectTemplate,
  onTemplateSave,
  onTemplateSaveAs,
  onTemplateSaveClose,
  onTemplateEdit,
  requestViewTemplateClose,
  requestViewTemplateOpen,
  templateModalIsOpen,
  viewTemplate,
  workItems,
  fetchNextPage,
  hasNextPage,
  onWorkItemListScroll,
  workItemListScrollTop,
  modifiedViewTemplate,
  onTemplateDeleteRequest,
  onTemplateDeleteConfirm,
  onTemplateDeleteCancel,
  isTemplateDeleting,
  isTemplateDeleteModalOpen,
  templateDeleteError,
  appliedFilters,
}) => (
  <div className={styles.container}>
    <Header
      isFetchingData={isFetchingData}
      workItems={workItems}
      onTemplateSave={onTemplateSave}
      onTemplateSaveAs={onTemplateSaveAs}
      viewTemplate={viewTemplate}
      isCurrentViewTemplateDirty={isCurrentViewTemplateDirty}
      isUserCurrentViewTemplateOwner={isUserCurrentViewTemplateOwner}
      onDiscardChanges={onDiscardChanges}
      onQuickSave={onQuickSave}
      isQuickSaving={isQuickSaving}
      isFilterSidebarMinimized={isFilterSidebarMinimized}
      onFilterSidebarToggle={onFilterSidebarToggle}
      onViewTemplatesOpen={requestViewTemplateOpen}
      onTemplateDeleteRequest={onTemplateDeleteRequest}
    />
    <div className={styles.contentContainer}>
      <div
        className={classnames({
          [styles.contentLeft]: true,
          [styles.contentLeftMinimized]: isFilterSidebarMinimized,
        })}
      >
        <div className={styles.innerContentLeft}>
          <FilterSideBar
            showWorkflowSpecificFilters
            filterType={IssueFilterType.Claim}
            appliedFilters={appliedFilters}
            onApplyAction={workItemlistFilterSetApplied}
            onEditAction={workItemListFilterSetEdited}
            onFilterUpdate={workItemListUpdateFilters}
            onFetchOptionsAction={workItemListFetchFilterOptionsStart}
          />
        </div>
      </div>
      <div className={styles.listContainer}>
        <div className={styles.table}>
          {!errorFetchingData && (
            <Table
              data={workItems}
              columnDefinitions={columnDefinitions}
              isSortEnabled={isSortEnabled}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSort={onSort}
              loadMoreRows={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetching={isFetchingData}
              isFetchingEstimatedRows={
                !isNil(viewTemplate.count) && !isCurrentViewTemplateDirty
                  ? min(max(1, viewTemplate.count), 50)
                  : 50
              }
              onScroll={onWorkItemListScroll}
              scrollTop={workItemListScrollTop}
              onHeaderClick={onHeaderClick}
            />
          )}
          {errorFetchingData && <UnexpectedError />}
        </div>
      </div>
    </div>
    <ViewTemplateSelect
      onSelectTemplate={onSelectTemplate}
      isOpen={templateModalIsOpen}
      onRequestClose={requestViewTemplateClose}
      onTemplateEdit={onTemplateEdit}
    />
    <ViewTemplateSave
      viewTemplate={modifiedViewTemplate}
      onRequestClose={onTemplateSaveClose}
      isOpen={isSaveModalOpen}
      onSubmit={onSaveSubmit}
    />
    <TemplateDeleteConfirm
      id={viewTemplate.id}
      onConfirm={onTemplateDeleteConfirm}
      onCancel={onTemplateDeleteCancel}
      isOpen={isTemplateDeleteModalOpen}
      isDeleting={isTemplateDeleting}
      error={templateDeleteError}
    />
  </div>
);

WorkItemsListView.propTypes = {
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  errorFetchingData: PropTypes.object,
  isCurrentViewTemplateDirty: PropTypes.bool.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  isFilterSidebarMinimized: PropTypes.bool.isRequired,
  isQuickSaving: PropTypes.bool.isRequired,
  isSaveModalOpen: PropTypes.bool.isRequired,
  isUserCurrentViewTemplateOwner: PropTypes.bool.isRequired,
  isSortEnabled: PropTypes.bool.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  onDiscardChanges: PropTypes.func.isRequired,
  onFilterSidebarToggle: PropTypes.func.isRequired,
  onQuickSave: PropTypes.func.isRequired,
  onSaveSubmit: PropTypes.func.isRequired,
  onSelectTemplate: PropTypes.func.isRequired,
  onTemplateSave: PropTypes.func.isRequired,
  onTemplateSaveAs: PropTypes.func.isRequired,
  onTemplateSaveClose: PropTypes.func.isRequired,
  requestViewTemplateClose: PropTypes.func.isRequired,
  requestViewTemplateOpen: PropTypes.func.isRequired,
  templateModalIsOpen: PropTypes.bool.isRequired,
  viewTemplate: ImmutablePropTypes.recordOf({
    filters: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        valueType: PropTypes.string.isRequired,
      }),
      PropTypes.string.isRequired,
    ).isRequired,
    id: PropTypes.string,
    isPublic: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  modifiedViewTemplate: ImmutablePropTypes.recordOf({
    filters: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        valueType: PropTypes.string.isRequired,
      }),
      PropTypes.string.isRequired,
    ).isRequired,
    id: PropTypes.string,
    isPublic: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  workItems: PropTypes.object.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  onWorkItemListScroll: PropTypes.func.isRequired,
  onTemplateEdit: PropTypes.func.isRequired,
  workItemListScrollTop: PropTypes.number,
  onHeaderClick: PropTypes.func,
  onTemplateDeleteRequest: PropTypes.func.isRequired,
  onTemplateDeleteConfirm: PropTypes.func.isRequired,
  onTemplateDeleteCancel: PropTypes.func.isRequired,
  isTemplateDeleting: PropTypes.bool.isRequired,
  isTemplateDeleteModalOpen: PropTypes.bool.isRequired,
  templateDeleteError: PropTypes.string,
};

WorkItemsListView.defaultProps = {
  workItemListScrollTop: 0,
  templateDeleteError: null,
};

export default WorkItemsListView;
