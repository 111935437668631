import React, { Component } from 'react';
import { connect } from 'react-redux';
import { unhandledError } from '../../actions';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(unhandledError(error, info));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>Something went wrong.</div>;
    }
    return this.props.children;
  }
}

export default connect()(ErrorBoundary);
