import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import {
  viewTemplatesQuery,
  viewTemplateUpsert,
  viewTemplateCountQuery,
} from './queries';

export function fetchViewTemplatesAsync(token) {
  return fetchGraphqlQuery(viewTemplatesQuery, {}, token)
    .then(throwIfErrors)
    .then((response) => response.data.templates);
}

export function saveViewTemplateAsync(token, template) {
  return fetchGraphqlQuery(viewTemplateUpsert, { template }, token)
    .then(throwIfErrors)
    .then((response) => response.data.upsertTemplate);
}

export function fetchViewTemplateCountAsync(token, id) {
  return fetchGraphqlQuery(
    viewTemplateCountQuery,
    {
      id,
    },
    token,
  )
    .then(throwIfErrors)
    .then((response) => response.data.node.countSummary);
}
