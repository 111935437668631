import { Button } from '@material-ui/core';
import { InsertChartOutlinedRounded } from '@material-ui/icons';
import { isNotNil } from 'ramda';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { CaseRecord } from 'app/cases/types';
import SaveButton from 'app/common/components/SaveButton';
import { saveInteraction } from 'app/interactions/actions';
import InteractionForm from 'app/interactions/components/InteractionForm/InteractionForm';
import { InteractionRecord } from 'app/interactions/types';
import { getContactTypeFromInteraction } from 'app/interactions/utility';
import { OutreachStatus } from 'app/queue/types';
import styles from './RecordInteraction.css';

type RecordInteractionProps = {
  interaction: InteractionRecord;
  activeCase: string;
  onChange: (i: any) => void;
  onChangeActiveCase: (c: CaseRecord) => void;
  isErrored: boolean;
  onComplete: () => void;
  isCompleting: boolean;
  isCanceling: boolean;
  isRunning: boolean;
  setStatus: Dispatch<SetStateAction<OutreachStatus | undefined>>;
};

const RecordInteraction = ({
  interaction,
  activeCase,
  onChange,
  onChangeActiveCase,
  isErrored,
  onComplete,
  isCompleting,
  isCanceling,
  isRunning,
  setStatus,
}: RecordInteractionProps) => {
  return (
    <>
      <div className={styles.interactionHeader}>
        <h3>Record Interaction</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {interaction &&
          interaction.cases &&
          (interaction.cases as CaseRecord[]).length > 0 &&
          isNotNil(activeCase) && (
            <InteractionForm
              isLoading={false}
              interaction={interaction}
              activeCase={activeCase}
              onChange={onChange}
              onCaseChange={onChangeActiveCase}
              contactType={getContactTypeFromInteraction(interaction)}
              caseLevelOnly
              interactionLevelOnly={false}
              readOnly={isCanceling || isCompleting}
              showCampaignTitle
            />
          )}
      </div>
      <div className={styles.interactionFooter}>
        {isErrored && (
          <div className={styles.interactionErrors}>
            Unexpected error encountered, please try again.
          </div>
        )}
        <div className={styles.interactionButtons}>
          <SaveButton
            variant="outlined"
            color="default"
            className={styles.interactionButton}
            disabled={isCompleting || isRunning}
            onClick={() => setStatus(OutreachStatus.StartInteraction)}
            isSaving={false}
          >
            Back
          </SaveButton>
          <SaveButton
            className={styles.interactionButton}
            onClick={onComplete}
            isSaving={isCompleting}
            disabled={isCanceling || isRunning}
          >
            Complete
          </SaveButton>
        </div>
      </div>
    </>
  );
};

export default RecordInteraction;
