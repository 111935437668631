import LockOutline from '@material-ui/icons/LockOutlined';
import { Link } from '@reach/router';
import React from 'react';
import styles from './Unauthorized.css';

const Unauthorized = () => (
  <div className={styles.container}>
    <div className={styles.contentWrap}>
      <LockOutline className={styles.icon} />
      <h1 className={styles.header}>Access Denied</h1>
      <div className={styles.description}>
        You do not have permission to access this application. Please contact
        your company administrator for assistance or{' '}
        <Link to={'/'}>click here</Link> to return to the home page.
      </div>
    </div>
  </div>
);

export default Unauthorized;
