import graphql from '../common/utilities/graphql';

export const searchQuery = graphql`
  query autocomplete(
    $search: String
    $autocompleteTypes: [AutocompleteTypeEnum]!
  ) {
    autocomplete(search: $search, autocompleteTypes: $autocompleteTypes) {
      __typename
      ... on PatientType {
        id
        entityId
        name
        patientId
        dateOfBirth
      }

      ... on ClaimType {
        id
        claimNumber
        patientName
        patientId
        drugName
        workItem {
          id
        }
      }

      ... on PrescriberType {
        id
        entityId
        name
        npi
        phoneNumber
      }

      ... on PharmacyType {
        id
        entityId
        name
        npi
        phoneNumbers {
          phoneNumber
          isPrimary
          source
          addedOn
        }
      }

      ... on CaseType {
        id
        number
        dateOpened
        campaign {
          id
          name
          contact
        }
        target {
          ... on PatientType {
            id
            patientId
          }
          ... on PrescriberType {
            id
            npi
          }
          __typename
        }
      }
    }
  }
`;
