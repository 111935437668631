import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userIsNotAuthenticated } from '../../actions';

export class LoginContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  componentDidMount = () => {
    const { dispatch, location } = this.props;
    let returnUrl;
    if (location.search) {
      returnUrl = new URLSearchParams(location.search).get('returnUrl');
    }

    dispatch(userIsNotAuthenticated(returnUrl));
  };

  render() {
    return <div>Redirecting to login...</div>;
  }
}

export default connect()(LoginContainer);
