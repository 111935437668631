import { formatDuration, formatISODuration } from 'date-fns';
import { isNegative } from 'duration-fns';
import { OrderedSet, Record } from 'immutable';
import pluralize from 'pluralize';
import { isNil } from 'ramda';
import { fetchTherapiesQuery } from 'app/campaigns/queries';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import {
  absoluteDuration,
  DurationRecord,
  parseISODuration,
} from 'app/common/duration';
import LateToFillFilter from 'app/filters/components/LateToFillFilter';
import { IssueFilterType, LateToFillMatch } from 'app/filters/constants';
import { commonClassDefaultsFactory } from 'app/filters/definitions/defaults';
import {
  LateToFillFilterRecord,
  WindowRecord,
  IFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
  LateToFillFilterBackendRecord,
  WindowBackendRecord,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(fetchTherapiesQuery, {}, token)
    .then(throwIfErrors)
    .then((data) => data.data.therapies)
    .then((therapies: Array<{ entityId: string; name: string }>) =>
      OrderedSet(
        therapies.map(
          (d) =>
            new SelectValueRecord({
              text: d.name,
              value: d.entityId,
            }),
        ),
      ),
    );

function getFormattedMatches(value: LateToFillFilterRecord) {
  switch (value.matchType) {
    case LateToFillMatch.Therapy:
      return `${pluralize(
        'therapy',
        value.therapies.count(),
      )}: ${value.therapies
        .map((x) => x.text)
        .filter((v) => !isNil(v))
        .join(', ')}`;
    case LateToFillMatch.Gpi:
      return `${pluralize('GPI', value.gpis.count(), true)}: ${value.gpis
        .map((x) => x.text)
        .join(', ')}`;
    default:
      throw new Error('Unrecognized late to fill match type provided.');
  }
}

class LateToFillConfig
  extends Record({
    ...commonClassDefaultsFactory(LateToFillFilterRecord),
    name: 'lateToFill',
    title: 'Late to Fill',
    targetType: IssueFilterType.Patient,
    options: (token: string) => fetchOptions(token),
    component: (props: any) => <LateToFillFilter {...props} />,
    storedValueType: StoredFilterType.LateToFill,
    summaryFormatter: (value: LateToFillFilterRecord) => {
      if (isNil(value.window.start))
        throw new Error('Late to fill does not support open start dates');

      // TODO fork duration-fns?
      const firstSuffix = isNegative(value.window.start.toJS() as Duration)
        ? 'prior'
        : 'after';
      const startWindowSummary = `${formatDuration(
        absoluteDuration(value.window.start),
      )} ${firstSuffix}`;

      let endWindowSummary = '';
      if (value.window.end !== null) {
        const secondSuffix = isNegative(value.window.end.toJS() as Duration)
          ? 'prior'
          : 'after';

        endWindowSummary = ` through ${formatDuration(
          absoluteDuration(value.window.end),
        )} ${secondSuffix} prescription end`;
      }
      return `${startWindowSummary} ${endWindowSummary} for ${getFormattedMatches(
        value,
      )}`;
    },
    defaultValue: () =>
      new LateToFillFilterRecord({
        window: new WindowRecord({
          start: new DurationRecord({ days: -3 }),
          end: new DurationRecord({ days: 7 }),
        }),
      }),
    templateToFilterValueConverter: (value: LateToFillFilterBackendRecord) =>
      new LateToFillFilterRecord({
        gpis: value.gpis.map(
          (x) =>
            new SelectValueRecord({
              text: x.key,
              value: x.value,
            }),
        ),
        therapies: value.therapies.map(
          (x) =>
            new SelectValueRecord({
              text: x.key,
              value: x.value,
            }),
        ),
        matchType: value.matchType,
        window: new WindowRecord({
          start: isNil(value.window.start)
            ? null
            : parseISODuration(value.window.start),
          end: isNil(value.window.end)
            ? null
            : parseISODuration(value.window.end),
        }),
        excludeIssuesBeforeCampaignStartDate:
          value.excludeIssuesBeforeCampaignStartDate,
      }),
    filterToTemplateValueConverter: (value: LateToFillFilterRecord) => {
      if (isNil(value.window.start)) throw new Error('Window start is nil');
      return new LateToFillFilterBackendRecord({
        gpis: value.gpis.map(
          (x) =>
            new KeyValueRecord({
              key: x.text,
              value: x.value,
            }),
        ),
        therapies: value.therapies.map(
          (x) =>
            new KeyValueRecord({
              key: x.text,
              value: x.value,
            }),
        ),
        matchType: value.matchType,
        window: new WindowBackendRecord({
          start: formatISODuration(value.window.start),
          end: isNil(value.window.end)
            ? null
            : formatISODuration(value.window.end),
        }),
        excludeIssuesBeforeCampaignStartDate:
          value.excludeIssuesBeforeCampaignStartDate,
      });
    },

    storedValueConstructor: (value: {
      matchType: string;
      gpis: { key: string; value: string }[];
      therapies: { key: string; value: string }[];
      window: { start: string; end: string };
      excludeIssuesBeforeCampaignStartDate: boolean;
    }) =>
      new LateToFillFilterBackendRecord({
        matchType: value.matchType as LateToFillMatch,
        gpis: OrderedSet(value.gpis.map((x) => new KeyValueRecord(x))),
        therapies: OrderedSet(
          value.therapies.map((x) => new KeyValueRecord(x)),
        ),
        window: new WindowBackendRecord({
          start: value.window.start,
          end: value.window.end,
        }),
        excludeIssuesBeforeCampaignStartDate:
          value.excludeIssuesBeforeCampaignStartDate,
      }),
  })
  implements
    IFilterConfiguration<
      LateToFillFilterRecord,
      OrderedSet<SelectValueRecord>,
      LateToFillFilterRecord,
      LateToFillFilterBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new LateToFillConfig();
