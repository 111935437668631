import { Record } from 'immutable';
import { IssueFilterType } from 'app/filters/constants';
import { booleanFilterDefaults } from 'app/filters/definitions/defaults';
import { IFilterConfiguration } from 'app/filters/types';

class IsEligibleConfig
  extends Record({
    ...booleanFilterDefaults,
    name: 'isPatientEligible',
    title: 'Is Eligible',
    targetType: IssueFilterType.Patient,
    filterWorkItem: () => false,
  })
  implements IFilterConfiguration<boolean, undefined, boolean, boolean>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new IsEligibleConfig();
