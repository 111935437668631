import { parseISO } from 'date-fns';
import { Map, Set } from 'immutable';
import { isNil, omit, lensProp, view, over } from 'ramda';
import { GpiLevel } from 'app/common/constants';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../cases/constants';
import {
  ISSUES_QUERY_FETCH_SUCCESS,
  ISSUE_UPSERT_SUCCESS,
} from '../issues/constants';
import {
  DRUG_OVERVIEW_FETCH_SUCCESS,
  CLAIM_VIEWER_FETCH_SUCCESS,
  OPIOID_DETAIL_FETCH_SUCCESS,
} from '../patient/constants';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../queue/constants';
import { SEARCH_SUCCESS } from '../search/constants';
import {
  WORKITEMS_QUERY_FETCH_SUCCESS,
  WORKITEM_DETAILS_FETCH_SUCCESS,
} from '../workitems/constants';
import {
  PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS,
  PATIENT_DRUG_HISTORY_FETCH_SUCCESS,
} from './constants';
import {
  ClaimsStateRecord,
  ClaimRecord,
  StepTherapyGroup,
  MatchedPaidClaimRecord,
  SeniorSavingsModel,
  DrugListRecord,
  DiagnosisCodeRecord,
} from './types';

const sanitizeClaim = (claim) => {
  let sanitizedClaim = omit(['gpiDetails', '__typename'], claim);

  if (claim.stepTherapyGroups !== undefined) {
    if (claim.stepTherapyGroups == null) {
      sanitizedClaim.stepTherapyGroups = new Set();
    } else {
      sanitizedClaim.stepTherapyGroups = new Set(
        claim.stepTherapyGroups.map((stg) => new StepTherapyGroup(stg)),
      );
    }
  }

  if (claim.rejectMessages !== undefined) {
    sanitizedClaim.rejectMessages = new Set(claim.rejectMessages);
  }

  if (claim.rejectCodes !== undefined) {
    sanitizedClaim.rejectCodes = new Set(claim.rejectCodes);
  }

  if (claim.diseaseStates !== undefined) {
    sanitizedClaim.diseaseStates = new Set(claim.diseaseStates);
  }

  if (claim.matchedPaidClaims !== undefined) {
    sanitizedClaim.matchedPaidClaims = new Set(
      claim.matchedPaidClaims.map((mpc) => new MatchedPaidClaimRecord(mpc)),
    );
  }

  if (claim.seniorSavingsModel !== undefined) {
    sanitizedClaim.seniorSavingsModel = new SeniorSavingsModel(
      claim.seniorSavingsModel,
    );
  }

  if (!isNil(claim.gpiDetails)) {
    const description = claim.gpiDetails.description;
    switch (claim.gpiDetails.level) {
      case GpiLevel.Ten:
        sanitizedClaim.gpi10Description = description;
        break;
      case GpiLevel.Fourteen:
        sanitizedClaim.gpi14Description = description;
        break;
    }
  }

  if (!isNil(claim.drugLists)) {
    sanitizedClaim.drugLists = new Set(
      claim.drugLists.map(
        (x) =>
          new DrugListRecord({
            id: x.id,
            name: x.name,
          }),
      ),
    );
  }

  if (!isNil(claim.diagnosisCodes)) {
    sanitizedClaim.diagnosisCodes = new Set(
      claim.diagnosisCodes.map(
        (x) =>
          new DiagnosisCodeRecord({
            code: x.code,
            description: x.description,
          }),
      ),
    );
  }

  const setDate = (property) => {
    const lens = lensProp(property);
    const valueOnClaim = view(lens, claim);

    if (valueOnClaim !== undefined && valueOnClaim !== null) {
      sanitizedClaim = over(lens, parseISO, sanitizedClaim);
    }
  };

  const dateProperties = [
    'adjudicatedDate',
    'filledDate',
    'refillWindowStart',
    'prescriptionStart',
    'prescriptionEnd',
    'eligibilityEffectiveDate',
    'eligibilityTerminationDate',
    'fillExpirationDate',
    'patientDob',
  ];

  dateProperties.forEach((x) => setDate(x));

  return sanitizedClaim;
};

function reducer(state = new ClaimsStateRecord(), action) {
  switch (action.type) {
    case SEARCH_SUCCESS:
    case WORKITEM_DETAILS_FETCH_SUCCESS:
    case WORKITEMS_QUERY_FETCH_SUCCESS:
    case PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS:
    case DRUG_OVERVIEW_FETCH_SUCCESS:
    case PATIENT_DRUG_HISTORY_FETCH_SUCCESS:
    case CLAIM_VIEWER_FETCH_SUCCESS:
    case OPIOID_DETAIL_FETCH_SUCCESS:
    case QUEUE_SKIP_SESSION_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS:
    case ISSUES_QUERY_FETCH_SUCCESS:
    case ISSUE_UPSERT_SUCCESS: {
      const claims = new Map(action.payload.data.entities.claims);
      return state.merge({
        claims: state.claims.merge(
          claims.map((claim) => {
            const existingClaim = state.claims.get(claim.id);
            if (existingClaim) {
              return existingClaim.merge(sanitizeClaim(claim));
            }
            return new ClaimRecord(sanitizeClaim(claim));
          }),
        ),
      });
    }
    default:
      return state;
  }
}

export default reducer;
