import { all, fork } from 'redux-saga/effects';
import { upsertSessionWatcher } from 'app/session/sagas';
import {
  refreshLoop,
  hydrateAuth,
  logout,
  userIsNotAuthenticatedSaga,
  authenticateUserWatcher,
  inactive,
} from './auth/sagas';
import {
  fetchBestPracticeCategoryWatcher,
  updateBestPracticeCategoryDateWatcher,
  fetchBestPracticesOverviewWatcher,
} from './bestpractices/sagas';
import {
  addCampaignWatcher,
  fetchCampaignsWatcher,
  deleteCampaignWatcher,
  editCampaignWatcher,
  cloneCampaignWatcher,
  fetchCampaignDetailsWatcher,
} from './campaigns/sagas';
import {
  fetchCasesQueryWatcher,
  fetchCaseWatcher,
  upsertCaseWatcher,
  bulkCreateCasesWatcher,
} from './cases/sagas';
import {
  fetchPatientClaimsHistoryWatcher,
  fetchPatientDrugHistoryWatcher,
} from './claims/sagas';
import {
  addCommentSource,
  editCommentSource,
  deleteCommentSource,
} from './comments/sagas';
import {
  exportDownloadWatcher,
  exportCaseDownloadWatcher,
} from './export/sagas';
import {
  fetchFiltersWatcher,
  fetchFilterOptionsWatcher,
} from './filters/sagas';
import { hydrate } from './hydrate/sagas';
import {
  saveInteractionWatcher,
  fetchInteractionFieldDefinitionsWatcher,
} from './interactions/sagas';
import {
  fetchIssuesQueryWatcher,
  fetchTargetsWatcher,
  upsertIssueWatcher,
} from './issues/sagas';
import {
  userNavigatesFromWorkItemListToDetails,
  logMiddlewareHandler,
} from './middleware/logging/sagas';
import {
  patientFetchWatcher,
  drugOverviewFetchWatcher,
  patientClaimViewerFetchWatcher,
  opioidClaimFetchWatcher,
  savePatientWatcher,
} from './patient/sagas';
import {
  fetchNextSessionInQueueWatcher,
  skipSessionWatcher,
  fetchQueueStatsWatcher,
  fetchContextForContactWatcher,
} from './queue/sagas';
import { searchWatcher } from './search/sagas';
import { fetchUsersWatcher } from './users/sagas';
import {
  fetchViewTemplatesWatcher,
  saveViewTemplateWatcher,
  createViewTemplateWatcher,
  quickSaveViewTemplateWatcher,
  fetchViewTemplateCountsWatcher,
  deleteTemplateWatcher,
} from './viewtemplates/sagas';
import { fetchWorkflowDefinitionsWatcher } from './workflow/sagas';
import {
  fetchWorkItemsWatcher,
  fetchWorkItemDetailsWatcher,
  filterUpdateWatcher,
  upsertWorkItemSource,
  upsertWorkItemWatcher,
  fetchWorkItemStatusesWatcher,
} from './workitems/sagas';

export default function* root(channel) {
  yield all([
    fork(refreshLoop),
    fork(hydrateAuth),
    fork(logout),
    fork(inactive),
    fork(userIsNotAuthenticatedSaga),
    fork(authenticateUserWatcher),
    // TODO figure out how to run this on component mount
    fork(searchWatcher),
    fork(patientFetchWatcher),
    fork(patientClaimViewerFetchWatcher),
    fork(opioidClaimFetchWatcher),
    fork(drugOverviewFetchWatcher),
    fork(fetchWorkItemsWatcher),
    fork(fetchWorkItemDetailsWatcher),
    fork(fetchWorkItemStatusesWatcher),
    fork(fetchFiltersWatcher),
    fork(filterUpdateWatcher),
    fork(fetchViewTemplatesWatcher),
    fork(fetchViewTemplateCountsWatcher),
    fork(fetchUsersWatcher),
    fork(upsertWorkItemSource),
    fork(addCommentSource),
    fork(editCommentSource),
    fork(deleteCommentSource),
    fork(exportDownloadWatcher),
    fork(createViewTemplateWatcher),
    fork(deleteTemplateWatcher),
    fork(saveViewTemplateWatcher),
    fork(quickSaveViewTemplateWatcher),
    fork(upsertWorkItemWatcher),
    fork(fetchPatientClaimsHistoryWatcher),
    fork(fetchFilterOptionsWatcher),
    fork(fetchPatientDrugHistoryWatcher),
    fork(userNavigatesFromWorkItemListToDetails),
    fork(fetchBestPracticeCategoryWatcher),
    fork(updateBestPracticeCategoryDateWatcher),
    fork(fetchBestPracticesOverviewWatcher),
    fork(hydrate),
    fork(addCampaignWatcher),
    fork(upsertSessionWatcher),
    fork(fetchCampaignsWatcher),
    fork(deleteCampaignWatcher),
    fork(editCampaignWatcher),
    fork(cloneCampaignWatcher),
    fork(fetchCampaignDetailsWatcher),
    fork(logMiddlewareHandler, channel),
    fork(fetchNextSessionInQueueWatcher),
    fork(saveInteractionWatcher),
    fork(skipSessionWatcher),
    fork(fetchQueueStatsWatcher),
    fork(fetchCaseWatcher),
    fork(fetchCasesQueryWatcher),
    fork(fetchIssuesQueryWatcher),
    fork(fetchTargetsWatcher),
    fork(upsertCaseWatcher),
    fork(bulkCreateCasesWatcher),
    fork(fetchInteractionFieldDefinitionsWatcher),
    fork(savePatientWatcher),
    fork(fetchWorkflowDefinitionsWatcher),
    fork(fetchContextForContactWatcher),
    fork(exportCaseDownloadWatcher),
    fork(upsertIssueWatcher),
  ]);
}
