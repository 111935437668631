import { Button, List, ListItem, ListSubheader } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import Scrollbars from 'react-custom-scrollbars';
import { CampaignRecord } from 'app/campaigns/types';
import CaseStatus from 'app/cases/components/CaseStatus/CaseStatus';
import { CaseRecord } from 'app/cases/types';
import { InteractionRecord } from 'app/interactions/types';
import { getCompletedInteractions } from 'app/interactions/utility';
import { PatientRecord } from 'app/patient/types';
import { PharmacyRecord } from 'app/pharmacies/types';
import { PrescriberRecord } from 'app/prescribers/types';
import { SessionRecord } from 'app/session/types';
import styles from './CaseSelector.css';

enum CaseSelectorType {
  Primary,
  Secondary,
  Suggested,
}

type CaseListItemProps = {
  caseItem: CaseRecord;
  selected: boolean;
  onClickItem: (caseItem: CaseRecord) => void;
  caseType: CaseSelectorType;
  isRunning: boolean;
  addCase: (caseId: CaseRecord) => void;
  removeCase: (caseId: CaseRecord) => void;
};

const CaseListItem = ({
  caseItem,
  selected,
  onClickItem,
  caseType,
  isRunning,
  addCase,
  removeCase,
}: CaseListItemProps) => {
  const completedInteractions = getCompletedInteractions(
    caseItem?.interactions,
  ) as InteractionRecord[];

  let actionButton = null;
  if (
    caseType === CaseSelectorType.Secondary ||
    caseType === CaseSelectorType.Suggested
  ) {
    actionButton = (
      <Button
        className={
          caseType === CaseSelectorType.Secondary
            ? styles.removeButton
            : styles.workButton
        }
        onClick={
          caseType === CaseSelectorType.Secondary
            ? () => removeCase(caseItem)
            : () => addCase(caseItem)
        }
        title={
          caseType === CaseSelectorType.Secondary ? 'Remove Case' : 'Work Case'
        }
        disabled={isRunning}
      >
        {caseType === CaseSelectorType.Secondary ? (
          <Remove className={styles.iconSizing} />
        ) : (
          <Add className={styles.iconSizing} />
        )}
      </Button>
    );
  }
  return (
    <ListItem
      button
      divider
      selected={selected}
      onClick={() => onClickItem(caseItem)}
    >
      <div className={styles.caseItemContainer}>
        <div className={styles.listItemHeader}>
          <div>{caseItem.number}</div>
          {actionButton}
        </div>
        <div className={styles.targetText}>
          {
            (
              caseItem.target as
                | PatientRecord
                | PrescriberRecord
                | PharmacyRecord
            ).name
          }
        </div>
        <div className={styles.campaignText}>
          {(caseItem.campaign as CampaignRecord).name}
        </div>
        <div className={styles.interactionsText}>
          Interactions: {completedInteractions.length}
        </div>
        <CaseStatus status={caseItem.status} />
      </div>
    </ListItem>
  );
};

type CaseSelectorProps = {
  session: SessionRecord;
  secondaryCases: CaseRecord[];
  suggestedCases: CaseRecord[];
  activeCase: string;
  onChange: (caseItem: CaseRecord) => void;
  addCase: (caseId: CaseRecord) => void;
  removeCase: (caseId: CaseRecord) => void;
  isRunning: boolean;
};

const CaseSelector = ({
  session,
  secondaryCases,
  suggestedCases,
  activeCase,
  onChange,
  addCase,
  removeCase,
  isRunning,
}: CaseSelectorProps) => {
  return (
    <div className={styles.container}>
      <Scrollbars>
        <List>
          <ListSubheader>Primary Case</ListSubheader>
          <CaseListItem
            key={(session.primaryCase as CaseRecord).id}
            caseItem={session.primaryCase as CaseRecord}
            onClickItem={onChange}
            selected={(session.primaryCase as CaseRecord).id === activeCase}
            addCase={addCase}
            removeCase={removeCase}
            caseType={CaseSelectorType.Primary}
            isRunning={isRunning}
          />
          {secondaryCases.length > 0 && (
            <>
              <ListSubheader>Working Cases</ListSubheader>
              {secondaryCases.map((c) => (
                <CaseListItem
                  key={(c as CaseRecord).id}
                  caseItem={c as CaseRecord}
                  onClickItem={onChange}
                  selected={(c as CaseRecord).id === activeCase}
                  addCase={addCase}
                  removeCase={removeCase}
                  caseType={CaseSelectorType.Secondary}
                  isRunning={isRunning}
                />
              ))}
            </>
          )}
          {suggestedCases.length > 0 && (
            <>
              <ListSubheader>Suggested Cases</ListSubheader>
              {suggestedCases.map((c) => (
                <CaseListItem
                  key={(c as CaseRecord).id}
                  caseItem={c as CaseRecord}
                  onClickItem={onChange}
                  selected={(c as CaseRecord).id === activeCase}
                  addCase={addCase}
                  removeCase={removeCase}
                  caseType={CaseSelectorType.Suggested}
                  isRunning={isRunning}
                />
              ))}
            </>
          )}
        </List>
      </Scrollbars>
    </div>
  );
};

CaseSelector.defaultProps = {};

export default CaseSelector;
