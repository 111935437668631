import PropTypes from 'prop-types';
import { values } from 'ramda';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  getBestPracticesOverviewErrors,
  isBestPracticesOverviewFetching,
} from '../../../ui/selectors';
import { fetchBestPracticesOverview } from '../../actions';
import BestPracticeOverview from '../../components/BestPracticeOverview';
import { AGGREGATION_PERIODS } from '../../constants';
import {
  getCurrentRisk,
  getBestPracticesHistoricalRisk,
  getCurrentRiskByCategory,
} from '../../selectors';
import { bestPracticeHistoricDateRange } from '../../utilities';

export class BestPracticeOverviewContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorFetching: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    currentRisk: ImmutablePropTypes.recordOf({
      score: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
    }),
    historicalRisk: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        date: PropTypes.instanceOf(Date),
        period: PropTypes.oneOf(values(AGGREGATION_PERIODS)),
        riskScore: ImmutablePropTypes.recordOf({
          score: PropTypes.number.isRequired,
          max: PropTypes.number.isRequired,
          min: PropTypes.number.isRequired,
        }),
      }),
    ),
    categories: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        id: PropTypes.string,
        name: PropTypes.string,
        riskScore: ImmutablePropTypes.recordOf({
          score: PropTypes.number.isRequired,
          max: PropTypes.number.isRequired,
          min: PropTypes.number.isRequired,
        }),
      }),
    ),
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(fetchBestPracticesOverview(bestPracticeHistoricDateRange()));
  };

  render() {
    const {
      currentRisk,
      categories,
      historicalRisk,
      errorFetching,
      isFetching,
    } = this.props;
    return (
      <BestPracticeOverview
        currentRisk={currentRisk}
        historicalRisk={historicalRisk}
        errorFetching={errorFetching}
        isFetching={isFetching}
        categories={categories}
      />
    );
  }
}

export default connect((state) => ({
  currentRisk: getCurrentRisk(state),
  historicalRisk: getBestPracticesHistoricalRisk(state),
  errorFetching: getBestPracticesOverviewErrors(state),
  isFetching: isBestPracticesOverviewFetching(state),
  categories: getCurrentRiskByCategory(state),
}))(BestPracticeOverviewContainer);
