import { Person } from '@material-ui/icons';
import { Link } from '@reach/router';
import { format as formatDate, isAfter } from 'date-fns';
import { isNil, pipe, sortWith, descend, prop, map, flatten } from 'ramda';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { upsertPatientStart } from 'app/patient/actions';
import { getErrors, isRunning } from 'app/ui/selectors';
import { MultiPhoneNumber } from '../../../../common/components/PhoneNumber/PhoneNumber';
import BasicInfoItem from '../../BasicInfoItem';
import styles from './PatientDetails.css';

const dateFormat = 'MM/dd/yyyy';

const format = (value) => {
  if (isNil(value)) return '';
  return formatDate(value, dateFormat);
};

const formatHighlightedBool = (value) => {
  if (isNil(value)) {
    return '';
  }
  if (value) {
    return <span className={styles.detailHighlighted}>Yes</span>;
  }
  return 'No';
};

const EligibilitySection = ({ eligibility }) => (
  <div className={styles.basicInfoSection}>
    <Scrollbars>
      <div className={styles.basicInfoLabel}>Total Eligibility Wiindow:</div>
      <div className={styles.multiLineItemContainer}>
        {eligibility.map((e) => (
          <div className={styles.multiLineItem} key={e.eligibilityMatchingKey}>
            <div className={styles.multiLineItemSecondary}>{`${format(
              e.startDate,
            )} - ${format(e.termDate)}`}</div>
          </div>
        ))}
      </div>
      <div className={styles.basicInfoLabel}>Eligibility Detail:</div>

      <div className={styles.multiLineItemContainer}>
        {eligibility.map((e) => (
          <div>
            {pipe(
              map((x) =>
                pipe(
                  map((y) => [
                    {
                      group: x.group,
                      start: y.eligibilityStartDate,
                      end: y.eligibilityTermDate,
                    },
                  ]),
                  flatten,
                )(x.eligibilities),
              ),
              flatten,
              sortWith([
                descend(prop('start')),
                descend(prop('end')),
                descend(prop('group')),
              ]),
              map((x) => (
                <div className={styles.multiLineItem}>
                  <div>{`${x.group} - ${format(x.start)} - ${format(
                    x.end,
                  )}`}</div>
                </div>
              )),
            )(e.eligibilityForGroups)}
          </div>
        ))}
      </div>
    </Scrollbars>
  </div>
);

const CancerDiagnosis = ({ patient }) => {
  const formatEndDate = (endDate) => {
    if (isNil(endDate) || isAfter(endDate, new Date())) {
      return '';
    }

    return formatDate(endDate, dateFormat);
  };

  return (
    <div className={styles.basicInfoSection}>
      <div>
        <div className={styles.basicInfoLabel}>Cancer Diagnoses:</div>
        <div className={styles.multiLineItemContainer}>
          {patient.cancerDiagnoses.map((e) => (
            <div className={styles.multiLineItem}>{`${format(
              e.start,
            )} - ${formatEndDate(e.end)}`}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PatientOverview = ({ patient, readonly = false }) => {
  const dispatch = useDispatch();

  const isSaving = useSelector((state) =>
    isRunning(state, upsertPatientStart(patient.id, patient)),
  );

  const errors = useSelector(
    (state) => getErrors(state, upsertPatientStart(patient.id, patient))[0],
  );

  const onPhoneNumberSubmit = (phoneNumber) => {
    dispatch(
      upsertPatientStart(patient.id, {
        ...patient,
        phoneNumbers: phoneNumber,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <Person className={styles.basicInfoIcon} />
      <div className={styles.basicPrimaryInfo}>{patient.name}</div>
      <BasicInfoItem
        label="Patient Number"
        value={<Link to={`/patients/${patient.id}`}>{patient.patientId}</Link>}
      />
      <BasicInfoItem label="Gender" value={patient.gender} />
      <BasicInfoItem
        label="Date of Birth"
        value={format(patient.dateOfBirth)}
      />
      <BasicInfoItem
        label="Phone Number"
        value={
          <MultiPhoneNumber
            isSaving={isSaving}
            errors={errors}
            readOnly={readonly}
            value={patient.phoneNumbers}
            onSubmit={onPhoneNumberSubmit}
          />
        }
      />
      <BasicInfoItem
        label="Active Cancer Diagnosis"
        value={formatHighlightedBool(patient.hasActiveCancerDiagnosis)}
      />
      {patient.cancerDiagnoses.length > 0 && (
        <CancerDiagnosis patient={patient} />
      )}
      <EligibilitySection eligibility={patient.eligibility} />
    </div>
  );
};

// TODO:  Prop types
PatientOverview.propTypes = {};
PatientOverview.defaultProps = {};

export default PatientOverview;
