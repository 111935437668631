export const PATIENT_CLAIMS_HISTORY_FETCH_START =
  'CLAIMS/PATIENT_CLAIMS_HISTORY_FETCH_START';

export const PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS =
  'CLAIMS/PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS';

export const PATIENT_CLAIMS_HISTORY_FETCH_FAIL =
  'CLAIMS/PATIENT_CLAIMS_HISTORY_FETCH_FAIL';

export const PATIENT_DRUG_HISTORY_FETCH_START =
  'CLAIMS/PATIENT_DRUG_HISTORY_FETCH_START';

export const PATIENT_DRUG_HISTORY_FETCH_SUCCESS =
  'CLAIMS/PATIENT_DRUG_HISTORY_FETCH_SUCCESS';

export const PATIENT_DRUG_HISTORY_FETCH_FAIL =
  'CLAIMS/PATIENT_DRUG_HISTORY_FETCH_FAIL';
