/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormLabel, ListItem } from '@material-ui/core';
import { startOfDay, addYears, subYears } from 'date-fns';
import Immutable, { OrderedSet } from 'immutable';
import { isNil } from 'ramda';
import DateRangePicker from 'app/common/components/DateRangePicker/DateRangePicker';
import MatchChipInput from 'app/filters/components/MatchChipInput/MatchChipInput';
import TextComparison from 'app/filters/components/TextComparison/TextComparison';
import { NumericOperators } from 'app/filters/constants';
import { numericComparisonDefaults } from 'app/filters/definitions/defaults';
import { numericToTextComparisonConverter } from 'app/filters/definitions/utility';
import {
  GreaterThanFillsFromDateFilterRecord,
  MultiSelectMatchFilterRecord,
  SelectValueRecord,
  TextComparisonRecord,
} from 'app/filters/types';
import styles from './GreaterThanFillsFromDate.css';

type GreaterThanFillsFromDateComponentProps = {
  value: GreaterThanFillsFromDateFilterRecord;
  onChange: (value: GreaterThanFillsFromDateFilterRecord) => void;
  options: OrderedSet<SelectValueRecord>;
};

const GreaterThanFillsFromDate = ({
  value,
  onChange,
  options,
}: GreaterThanFillsFromDateComponentProps) => {
  const handleTherapiesChange = (
    selectedTherapies: MultiSelectMatchFilterRecord,
  ) => {
    onChange(
      value.merge({
        therapies: value.therapies.merge({
          values: selectedTherapies.values.map(
            (v) =>
              new SelectValueRecord({
                text: v.text,
                value: v.value,
              }),
          ),
          match: selectedTherapies.match,
        }),
      }),
    );
  };
  const handleNumericChange = (numberValue: TextComparisonRecord) => {
    const selectedNumberOfFills = isNil(numberValue.text)
      ? 0
      : Number.parseInt(numberValue.text, 10);
    onChange(
      value.merge({
        numberOfFills: value.numberOfFills.merge({
          value: selectedNumberOfFills,
          operator: value.numberOfFills.operator.merge({
            text: numberValue.operator.text,
            value: numberValue.operator.value as unknown as NumericOperators,
          }),
        }),
      }),
    );
  };
  const handleDateChange = (fromDate: Date) => {
    const startDate = isNil(fromDate) ? null : startOfDay(fromDate);
    onChange(
      value.merge({
        fromDate: startDate,
      }),
    );
  };

  const dateValue = isNil(value.fromDate) ? null : new Date(value.fromDate);

  return (
    <div>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>
          Number of Fills
        </FormLabel>
        <TextComparison
          inputType="number"
          placeholder="0"
          onChange={(selectedNumberOptions: any) =>
            handleNumericChange(selectedNumberOptions)
          }
          options={numericComparisonDefaults.options('Greater')}
          value={numericToTextComparisonConverter(value.numberOfFills)}
          hideComparisonOptions={true}
          min="0"
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>Therapies</FormLabel>
        <MatchChipInput
          value={
            new MultiSelectMatchFilterRecord({
              match: value.therapies.match,
              values: Immutable.OrderedSet(value.therapies.values),
            })
          }
          options={options}
          isFetching={false}
          onChange={(selectedTherapies: any) =>
            handleTherapiesChange(selectedTherapies)
          }
          optionRenderer={(option: any) => option.text}
          noOptionsFoundComponent={<ListItem>Invalid therapy...</ListItem>}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>From Date</FormLabel>
        <DateRangePicker
          variant="outlined"
          maxSelectableDate={addYears(new Date(), 2)}
          minSelectableDate={subYears(new Date(), 2)}
          classes={{ root: styles.input }}
          value={dateValue}
          onChange={(date: any) => handleDateChange(date)}
          clearable={false}
        />
      </FormControl>
    </div>
  );
};

export default GreaterThanFillsFromDate;
