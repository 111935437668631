import classnames from 'classnames';
import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import Tag from 'app/common/components/Tag';
import { GraphqlTypes } from 'app/common/types';
import MultiSelect from 'app/filters/components/MultiSelect';
import { IssueFilterType } from 'app/filters/constants';
import { enumDefaults } from 'app/filters/definitions/defaults';
import { createEnumOptionsQueryHandler } from 'app/filters/definitions/utility';
import {
  IClaimFilterConfiguration,
  SchemaRecord,
  SelectValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';
import { formatWorkItemStatus } from 'app/workitems/utility';
import styles from './workItemStatus.css';

export const optionRenderer = (option: SelectValueRecord) => (
  <Tag
    className={classnames({
      [styles.option]: true,
      [styles.blue]: option.value === 'OPEN',
      [styles.orange]: option.value === 'INPROGRESS',
      [styles.green]: option.value === 'DONE',
    })}
    text={option.text}
  />
);

const optionTextFormatter = (value: string) => formatWorkItemStatus(value);

class WorkItemStatusConfig
  extends Record({
    ...enumDefaults(GraphqlTypes.workitemStatusEnum, optionTextFormatter),
    name: 'workItemStatus',
    title: 'Work Item Status',
    targetType: IssueFilterType.Claim,
    workflowSpecific: true,
    component: (props: any) => (
      <MultiSelect optionRenderer={optionRenderer} {...props} />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.status)) return false;

      return value.contains(workItem.status.type);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      Set<string>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new WorkItemStatusConfig();
