import { isWithinInterval } from 'date-fns';
import { normalize } from 'normalizr';
import { takeLatest, select, call, put, all } from 'redux-saga/effects';
import { getAccessToken } from '../user/selectors';
import {
  fetchBestPracticeCategorySuccess,
  fetchBestPracticeCategoryFail,
  fetchBestPracticeCategory,
  fetchBestPracticesOverviewSuccess,
  fetchBestPracticesOverviewFail,
} from './actions';
import { fetchBestPractices } from './api';
import {
  BESTPRACTICES_CATEGORY_FETCH_START,
  BESTPRACTICES_CATEGORY_UPDATE_DATE,
  BESTPRACTICES_OVERVIEW_FETCH_START,
} from './constants';
import { bestPracticeStateSchema } from './types';
import { bestPracticeHistoricDateRange } from './utilities';

export function* fetchBestPracticeCategoryWorker(action) {
  const {
    payload: { category, dateRange },
  } = action;

  const historicDateRange = bestPracticeHistoricDateRange();

  const token = yield select(getAccessToken);

  const tasks = {};
  tasks.historical = call(
    fetchBestPractices,
    historicDateRange,
    token,
    category,
  );

  // Only fetch the selected date range if it falls outside the historic range
  if (
    !isWithinInterval(dateRange.start, historicDateRange) ||
    !isWithinInterval(dateRange.end, historicDateRange)
  ) {
    tasks.selected = call(fetchBestPractices, dateRange, token, category);
  }
  try {
    const { historical, selected } = yield all(tasks);
    const data = normalize(
      historical.concat(selected || []),
      bestPracticeStateSchema,
    );
    yield put(fetchBestPracticeCategorySuccess(data));
  } catch (e) {
    yield put(fetchBestPracticeCategoryFail(e));
  }
}

export function* fetchBestPracticeCategoryWatcher() {
  yield takeLatest(
    BESTPRACTICES_CATEGORY_FETCH_START,
    fetchBestPracticeCategoryWorker,
  );
}

export function* dispatchFetchBestPracticeCategory(action) {
  yield put(
    fetchBestPracticeCategory(
      action.payload.category,
      action.payload.dateRange,
    ),
  );
}

export function* updateBestPracticeCategoryDateWatcher() {
  yield takeLatest(
    BESTPRACTICES_CATEGORY_UPDATE_DATE,
    dispatchFetchBestPracticeCategory,
  );
}

export function* fetchBestPracticesOverviewWorker(action) {
  const {
    payload: { dateRange },
  } = action;

  const token = yield select(getAccessToken);
  try {
    const response = yield call(fetchBestPractices, dateRange, token);
    const data = normalize(response, bestPracticeStateSchema);
    yield put(fetchBestPracticesOverviewSuccess(data));
  } catch (e) {
    yield put(fetchBestPracticesOverviewFail(e));
  }
}

export function* fetchBestPracticesOverviewWatcher() {
  yield takeLatest(
    BESTPRACTICES_OVERVIEW_FETCH_START,
    fetchBestPracticesOverviewWorker,
  );
}
