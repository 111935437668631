import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Tooltip,
  ValueLabelProps,
  Switch,
} from '@material-ui/core';
import { toDays } from 'duration-fns';
import { OrderedSet, Set, Map, OrderedMap } from 'immutable';
import { is, isNil, range, concat, map } from 'ramda';
import { ChangeEvent, useState } from 'react';
import MultiSelectDropDown from '../../../common/components/MutliSelectDropDown';
import { DurationRecord } from '../../../common/duration';
import { LateToFillMatch } from '../../constants';
import {
  SelectValueRecord,
  LateToFillFilterRecord,
  WindowRecord,
} from '../../types';
import ChipInput from '../ChipInput';
import styles from './LateToFillFilter.css';

const ValueLabelComponent = ({ children, open, value }: ValueLabelProps) => (
  <Tooltip
    classes={{ popper: styles.popper }}
    open={open}
    enterTouchDelay={0}
    placement="top"
    title={value}
    PopperProps={{
      disablePortal: true,
      popperOptions: {
        modifiers: {
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: 'scrollParent',
          },
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

const sliderValueToFilterValueMap = OrderedMap<number, number | null>(
  concat(
    map((x) => [x, x], range(-10, 11)),
    [
      [11, 14],
      [12, 30],
      [13, 60],
      [14, 90],
      [15, 365],
      [16, null],
    ],
  ) as any,
);

type LateToFillFilterComponentProps = {
  value: LateToFillFilterRecord;
  onChange: (value: LateToFillFilterRecord) => void;
  options: OrderedSet<SelectValueRecord>;
};

const LateToFillFilterComponent = ({
  value,
  onChange,
  options,
}: LateToFillFilterComponentProps) => {
  const valueToSliderValue = (x: DurationRecord | null) => {
    const sliderValue = sliderValueToFilterValueMap.findKey((v, _) => {
      const compareValue = x === null ? null : toDays(x.toObject());
      return v === compareValue;
    });

    if (sliderValue === undefined) throw new Error('Unrecognized value');
    return sliderValue;
  };

  const getWindowValue = (window: WindowRecord | null) => {
    if (isNil(window)) return [];

    return [valueToSliderValue(window.start), valueToSliderValue(window.end)];
  };

  const [prescriptionWindow, setPrescriptionWindow] = useState<number[]>(
    getWindowValue(value.window),
  );

  const onMatchChange = (
    _: ChangeEvent<HTMLInputElement>,
    matchValue: string,
  ) => {
    onChange(
      value.merge({
        gpis: OrderedSet<SelectValueRecord>(),
        therapies: OrderedSet<SelectValueRecord>(),
        matchType: matchValue as LateToFillMatch,
      }),
    );
  };

  const onWindowChange = (_: ChangeEvent<{}>, days: Array<number>) => {
    if (!Array.isArray(days)) throw new Error('Should only be an array');

    if (days[0] === days[1]) return;
    setPrescriptionWindow(days as Array<number>);
  };

  const sliderToValueConverter = (x: number) => {
    const filterValue = sliderValueToFilterValueMap.get(x);

    if (filterValue === undefined) throw new Error('Unrecognized value');

    return filterValue;
  };

  const onWindowChangeCommit = (
    _: ChangeEvent<{}>,
    days: number | Array<number>,
  ) => {
    if (is(Number)(days)) {
      throw new Error('The prescription window should always contain a range.');
    }

    const startDay = sliderToValueConverter(days[0]);
    const endDay = sliderToValueConverter(days[1]);

    onChange(
      value.merge({
        window: new WindowRecord({
          start: isNil(startDay)
            ? null
            : new DurationRecord({
              days: startDay as number,
            }),
          end: isNil(endDay)
            ? null
            : new DurationRecord({
              days: endDay as number,
            }),
        }),
      }),
    );
  };

  const onGpiChange = (gpis: OrderedSet<SelectValueRecord>) => {
    onChange(
      value.merge({
        gpis,
        therapies: OrderedSet<SelectValueRecord>(),
      }),
    );
  };

  const onTherapyChange = (_: string, therapies: OrderedSet<string>) => {
    onChange(
      value.merge({
        gpis: OrderedSet<SelectValueRecord>(),
        therapies: therapies.map((x) => {
          const option = options.find((y) => y.value === x);
          if (isNil(option))
            throw new Error('Unable to find option for selected therapy');
          return new SelectValueRecord({ text: option.text, value: x });
        }),
      }),
    );
  };

  const handleExcludeIssuesBeforeCampaignStartDateChange = (
    _: ChangeEvent<HTMLInputElement>,
    excludeValue: boolean,
  ) => {
    onChange(
      value.merge({
        excludeIssuesBeforeCampaignStartDate: excludeValue,
      }),
    );
  };

  const labelRenderer = (x: number) => {
    const filterValue = sliderToValueConverter(x);

    const days =
      filterValue === null ? '\u221E' : Math.abs(filterValue).toString();

    return `${days} days`;
  };

  return (
    <div>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>Match Type</FormLabel>
        <RadioGroup value={value.matchType} onChange={onMatchChange}>
          <FormControlLabel
            value={LateToFillMatch.Gpi}
            label="GPI"
            control={
              <Radio classes={{ root: styles.slimRadio }} color="primary" />
            }
          />
          <FormControlLabel
            value={LateToFillMatch.Therapy}
            label="Therapies"
            control={
              <Radio classes={{ root: styles.slimRadio }} color="primary" />
            }
          />
        </RadioGroup>
      </FormControl>
      {value.matchType === LateToFillMatch.Gpi && (
        <>
          <FormLabel classes={{ root: styles.labelHeader }}>GPIs:</FormLabel>
          <ChipInput
            options={Set()}
            onChange={onGpiChange}
            value={value.gpis}
            optionsListOnly={false}
            placeholder="Enter GPI Prefixes"
          />
        </>
      )}
      {value.matchType === LateToFillMatch.Therapy && (
        <div className={styles.fullWidth}>
          <MultiSelectDropDown
            label="Therapies"
            fullWidth
            id="therapies"
            variant="standard"
            options={options}
            value={value.therapies.map((x) => x.value)}
            onChange={onTherapyChange}
          />
        </div>
      )}
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>Window</FormLabel>
        <Slider
          classes={{ root: styles.slider }}
          min={sliderValueToFilterValueMap.minBy((v, k) => k) as number}
          // for some reason maxBy is returning null;
          max={sliderValueToFilterValueMap.map((v, k) => k).max() as number}
          valueLabelDisplay="on"
          value={prescriptionWindow}
          aria-label="Default"
          valueLabelFormat={labelRenderer}
          ValueLabelComponent={ValueLabelComponent}
          onChange={
            onWindowChange as (
              event: React.ChangeEvent<{}>,
              value: number | number[],
            ) => void
          }
          onChangeCommitted={onWindowChangeCommit}
          marks={[
            {
              value: 0,
              label: (
                <Tooltip
                  classes={{
                    tooltip: styles.tooltip,
                    popper: styles.popper,
                  }}
                  PopperProps={{
                    placement: 'bottom',
                    disablePortal: true,
                    // TODO:  These will change when we update to the latest material ui
                    popperOptions: {
                      modifiers: {
                        flip: {
                          enabled: false,
                        },
                        preventOverflow: {
                          enabled: false,
                          boundariesElement: 'scrollParent',
                        },
                      },
                    },
                  }}
                  title="Prescription End"
                  open
                  arrow
                  placement="bottom"
                >
                  <span />
                </Tooltip>
              ),
            },
          ]}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel classes={{ root: styles.labelHeader }}>
          Exclude Late To Fill Issues starting before the Campaign start date
        </FormLabel>
        <FormControlLabel
          label="Exclude"
          control={
            <Switch
              checked={value.excludeIssuesBeforeCampaignStartDate}
              color="primary"
              onChange={handleExcludeIssuesBeforeCampaignStartDateChange}
            />
          }
        />
      </FormControl>
    </div>
  );
};

export default LateToFillFilterComponent;
