import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { USER_INACTIVE_TIMEOUT, INACTIVE_PROMPT_TIME } from '../../constants';

const IdlePrompt = ({ onSignOut }) => {
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(INACTIVE_PROMPT_TIME);

  const onPrompt = () => {
    setIsPromptOpen(true);
    setRemainingTime(INACTIVE_PROMPT_TIME);
  };

  const onIdle = () => {
    setIsPromptOpen(false);
    onSignOut();
  };

  const onActive = (event) => {
    setIsPromptOpen(false);
  };

  const { activate, getRemainingTime } = useIdleTimer({
    timeout: USER_INACTIVE_TIMEOUT,
    promptTimeout: INACTIVE_PROMPT_TIME,
    onIdle,
    onActive,
    onPrompt,
    crossTab: true,
    syncTimers: 3000,
  });

  useEffect(() => {
    if (isPromptOpen) {
      const interval = setInterval(
        () => setRemainingTime(getRemainingTime()),
        1000,
      );
      return () => clearInterval(interval);
    }
  }, [isPromptOpen]);

  return (
    <Dialog open={isPromptOpen} disableEscapeKeyDown>
      <DialogTitle>Your Session is About to End</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Due to inactivity, we will automatically log you out in{' '}
          {Math.round(remainingTime / 1000)} seconds. Please click continue to
          keep working or log out to end your session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={activate}>
          Continue
        </Button>
        <Button onClick={onSignOut}>Log Out</Button>
      </DialogActions>
    </Dialog>
  );
};

IdlePrompt.propTypes = {
  onSignOut: PropTypes.func.isRequired,
};

export default IdlePrompt;
