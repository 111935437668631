import { Button, Menu, RootRef } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  Component,
  Children,
  Fragment,
  createRef,
  cloneElement,
} from 'react';
import styles from './SplitButton.css';

class SplitButton extends Component {
  static propTypes = {
    defaultLabel: PropTypes.string,
    onDefaultClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.anchorRef = createRef();
  }

  handleRequestClose = () => {
    this.setState({ isOpen: false });
  };

  handleExpandOnClick = (event) => {
    this.setState({
      isOpen: !this.state.isOpen,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const { defaultLabel, onDefaultClick, children, disabled } = this.props;
    const { isOpen, anchorEl } = this.state;
    const hasChildren =
      Children.toArray(children)
        .map((child) => React.isValidElement(child))
        .filter((x) => x === true).length !== 0;
    return (
      <div
        className={classnames({
          [styles.containerDisabled]: hasChildren && disabled,
          [styles.container]: hasChildren && !disabled,
        })}
      >
        <Button onClick={onDefaultClick} disabled={disabled}>
          {defaultLabel}
        </Button>
        {hasChildren && !disabled && (
          <Fragment>
            <RootRef rootRef={this.anchorRef}>
              <Button
                className={styles.expander}
                onClick={this.handleExpandOnClick}
                disabled={disabled}
              >
                {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
              </Button>
            </RootRef>
            <Menu
              open={isOpen}
              anchorEl={this.anchorRef.current}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              getContentAnchorEl={null}
              onClose={this.handleRequestClose}
            >
              {Children.toArray(children).map((x) =>
                cloneElement(x, {
                  onClick: () => {
                    this.handleRequestClose();
                    x.props.onClick();
                  },
                }),
              )}
            </Menu>
          </Fragment>
        )}
      </div>
    );
  }
}

export default SplitButton;
