import { parseISO } from 'date-fns';
import { List, Map, Set } from 'immutable';
import { isNil, has, prop } from 'ramda';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../cases/constants';
import { parseISODuration } from '../common/duration';
import filterDefinitions from '../filters/definitions';
import {
  CASE_LEVEL_RESPONSE,
  INTERACTION_SAVE_SUCCESS,
} from '../interactions/constants';
import {
  ISSUES_QUERY_FETCH_SUCCESS,
  ISSUE_UPSERT_SUCCESS,
} from '../issues/constants';
import { PATIENT_FETCH_SUCCESS } from '../patient/constants';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../queue/constants';
import { SEARCH_SUCCESS } from '../search/constants';
import { FilterRecord } from '../viewtemplates/types';
import {
  CAMPAIGN_ADD_SUCCESS,
  CAMPAIGN_QUERY_FETCH_SUCCESS,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_EDIT_SUCCESS,
  CAMPAIGN_CLONE_UPSERT_SUCCESS,
  CAMPAIGN_DETAILS_FETCH_SUCCESS,
} from './constants';
import {
  CampaignStateRecord,
  CampaignRecord,
  InteractionFieldDefinitionRecord,
  CampaignIssuesFilterRecord,
} from './types';

const findAndMerge = (state, campaign) => {
  const initialRecord =
    state.campaigns.get(campaign.id) || new CampaignRecord();

  const getValue = (field, valueSelector) => {
    if (!has(field, campaign)) {
      return prop(field, initialRecord);
    }

    return valueSelector(prop(field, campaign));
  };

  return initialRecord.merge({
    ...campaign,
    startDate: getValue('startDate', (startDate) =>
      isNil(startDate) ? null : parseISO(startDate),
    ),
    endDate: getValue('endDate', (endDate) =>
      isNil(endDate) ? null : parseISO(endDate),
    ),
    assignees: getValue('assignees', (assignees) => new Set(assignees)),
    requiredSpecialists: getValue(
      'requiredSpecialists',
      (requiredSpecialists) => new Set(requiredSpecialists),
    ),
    duration: getValue('duration', (duration) =>
      isNil(duration) ? null : parseISODuration(duration),
    ),
    issueFilter: getValue('issueFilter', (issueFilter) => {
      return new CampaignIssuesFilterRecord({
        issueFilterType: issueFilter?.issueFilterType,
        filters: new Set(
          issueFilter?.filters?.map(
            (filter) =>
              new FilterRecord({
                name: filter.name,
                value: filterDefinitions
                  .filter(
                    (def) => def.targetType === issueFilter.issueFilterType,
                  )
                  .find((x) => x.name === filter.name)
                  .storedValueConstructor(filter.value),
                valueType: filter.valueType,
              }),
          ),
        ),
      });
    }),
    interactionFieldDefinitions: getValue(
      'interactionFieldDefinitions',
      (interactionFieldDefinitions) =>
        new List(
          interactionFieldDefinitions?.map(
            (field) =>
              new InteractionFieldDefinitionRecord({
                ...field,
                options: new List(field.options),
                level: CASE_LEVEL_RESPONSE,
              }),
          ),
        ),
    ),
  });
};

// TODO:  Do we need a optimistic state here?
// if we want to redirect the user to try again with the form alread filled out
// or if we want to add a notifacation with action.
function reducer(state = new CampaignStateRecord(), action) {
  switch (action.type) {
    case CAMPAIGN_QUERY_FETCH_SUCCESS: {
      const campaigns = new Map(action.payload.data.entities.campaigns);
      const campaignEntityIds = campaigns.map((y) => y.entityId).toSet();
      return state.merge({
        campaigns: state.campaigns
          .filter((x) => campaignEntityIds.has(x.entityId))
          .merge(campaigns.map((x) => findAndMerge(state, x))),
      });
    }
    case INTERACTION_SAVE_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case QUEUE_SKIP_SESSION_SUCCESS:
    case CAMPAIGN_DETAILS_FETCH_SUCCESS:
    case CAMPAIGN_ADD_SUCCESS:
    case CAMPAIGN_EDIT_SUCCESS:
    case CAMPAIGN_CLONE_UPSERT_SUCCESS:
    case PATIENT_FETCH_SUCCESS:
    case SEARCH_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS:
    case ISSUES_QUERY_FETCH_SUCCESS:
    case ISSUE_UPSERT_SUCCESS: {
      const campaigns = new Map(action.payload.data.entities.campaigns);

      return state.merge({
        campaigns: state.campaigns.merge(
          campaigns.map((x) => findAndMerge(state, x)),
        ),
      });
    }
    case CAMPAIGN_DELETE_SUCCESS: {
      const { id } = action.payload;
      return state.merge({
        campaigns: state.campaigns.filterNot((x) => x.entityId === id),
      });
    }
    default:
      return state;
  }
}

export default reducer;
