import { SortingRecord } from '../../common/types';
import { VIEWTEMPLATE_APPLY } from '../../viewtemplates/constants';
import { WORKITEMS_SORT_APPLY } from '../constants';

const reducer = (state = new SortingRecord(), action) => {
  switch (action.type) {
    case WORKITEMS_SORT_APPLY:
      return state.merge({
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      });
    case VIEWTEMPLATE_APPLY:
      return state.merge({
        sortBy: action.payload.viewTemplate.sortBy,
        sortDirection: action.payload.viewTemplate.sortDirection,
      });
    default:
      return state;
  }
};

export default reducer;
