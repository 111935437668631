/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable indent */
import { format } from 'date-fns';
import { OrderedSet, Record } from 'immutable';
import pluralize from 'pluralize';
import { equals, lt, gt } from 'ramda';
import { fetchTherapiesQuery } from 'app/campaigns/queries';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import LessThanFillsFromDate from 'app/filters/components/LessThanFillsFromDate';
import {
  IssueFilterType,
  MatchStrings,
  numericOperatorConfig,
  NumericOperators,
} from 'app/filters/constants';
import { commonClassDefaultsFactory } from 'app/filters/definitions/defaults';
import {
  IFilterConfiguration,
  NumericComparison,
  NumericOperator,
  MultiSelectMatchFilterRecord,
  LessThanFillsFromDateFilterRecord,
  LessThanFillsFromDateFilterBackendRecord,
  SelectValueRecord,
  NumericComparisonBackendRecord,
  MultiSelectMatchStoredValue,
  KeyValueRecord,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';

const numericOperatorToTextConfig = (operator: NumericOperators) => {
  switch (operator) {
    case NumericOperators.Equal: {
      return {
        summary: 'Exactly',
        text: 'Equal to',
        filter: (a: number, b: number) => equals(a, b),
      };
    }
    case NumericOperators.Less: {
      return {
        summary: 'Less than or equal to',
        text: 'Less than',
        filter: (a: number, b: number) => lt(a, b),
      };
    }
    case NumericOperators.Greater: {
      return {
        summary: 'Greater than or equal to',
        text: 'Greater than',
        filter: (a: number, b: number) => gt(a, b),
      };
    }
    default: {
      throw new Error('Unknown operator type provided.');
    }
  }
};

const matchOperatorToTextConfig = (operator: MatchStrings) => {
  switch (operator) {
    case MatchStrings.All: {
      return {
        text: 'All',
      };
    }
    case MatchStrings.Any: {
      return {
        text: 'Any',
      };
    }
    default: {
      throw new Error('Unknown operator type provided.');
    }
  }
};

const summaryFormatter = (value: LessThanFillsFromDateFilterRecord) => {
  return `Patient has 
    ${numericOperatorToTextConfig(value.numberOfFills.operator.value).summary} 
    ${value.numberOfFills.value} ${pluralize('fill', value.numberOfFills.value)}
    after
    ${
      value.fromDate !== null && value.fromDate !== undefined
        ? format(new Date(value.fromDate), 'MM/dd/yyyy')
        : null
    } for
    ${
      matchOperatorToTextConfig(value.therapies.match).text
    } of the following therapies: 
    ${value.therapies.values.map((x) => x.text).join(', ')}
    `;
};

const fetchTherapieOptions = (token: string) =>
  fetchGraphqlQuery(fetchTherapiesQuery, {}, token)
    .then(throwIfErrors)
    .then((data) => data.data.therapies)
    .then((therapies: Array<{ entityId: string; name: string }>) =>
      OrderedSet(
        therapies.map(
          (d) =>
            new SelectValueRecord({
              text: d.name,
              value: d.entityId,
            }),
        ),
      ),
    );

class LessThanFillsFromDateConfig
  extends Record({
    ...commonClassDefaultsFactory(LessThanFillsFromDateFilterRecord),
    name: 'lessThanFillsFromDate',
    title: 'Number of Fills Less than or Equal to',
    targetType: IssueFilterType.Patient,
    summaryFormatter,
    component: (props: any) => <LessThanFillsFromDate {...props} />,
    options: (token: string) => fetchTherapieOptions(token),
    storedValueType: StoredFilterType.LessThanFillsFromDate,
    storedValueConstructor: (value: {
      therapies: MultiSelectMatchStoredValue;
      fromDate: Date;
      numberOfFills: NumericComparisonBackendRecord;
    }) => {
      return new LessThanFillsFromDateFilterBackendRecord({
        therapies: new MultiSelectMatchStoredValue({
          values: value.therapies.values.map(
            (v) =>
              new KeyValueRecord({
                key: v.key,
                value: v.value,
              }),
          ),
          match: value.therapies.match,
        }),
        fromDate: value.fromDate,
        numberOfFills: new NumericComparisonBackendRecord({
          number: value.numberOfFills.number,
          operator: value.numberOfFills.operator,
        }),
      });
    },
    templateToFilterValueConverter: (
      value: LessThanFillsFromDateFilterBackendRecord,
    ) =>
      new LessThanFillsFromDateFilterRecord({
        numberOfFills: new NumericComparison({
          value: value.numberOfFills.number,
          operator: new NumericOperator({
            text: numericOperatorConfig(NumericOperators.Less).text,
            value: NumericOperators.Less,
          }),
        }),
        fromDate: value.fromDate,
        therapies: new MultiSelectMatchFilterRecord({
          values: value.therapies.values.map(
            (v) =>
              new SelectValueRecord({
                text: v.key,
                value: v.value,
              }),
          ),
          match: value.therapies.match,
        }),
      }),
    filterToTemplateValueConverter: (
      value: LessThanFillsFromDateFilterRecord,
    ) =>
      new LessThanFillsFromDateFilterBackendRecord({
        numberOfFills: new NumericComparisonBackendRecord({
          number: value.numberOfFills.value,
          operator: value.numberOfFills.operator.value,
        }),
        fromDate: value.fromDate,
        therapies: new MultiSelectMatchStoredValue({
          values: value.therapies.values.map(
            (v) =>
              new KeyValueRecord({
                key: v.text,
                value: v.value,
              }),
          ),
          match: value.therapies.match,
        }),
      }),
    defaultValue: () =>
      new LessThanFillsFromDateFilterRecord({
        therapies: new MultiSelectMatchFilterRecord(),
        numberOfFills: new NumericComparison({
          value: 1,
          operator: new NumericOperator({
            text: numericOperatorConfig(NumericOperators.Less).text,
            value: NumericOperators.Less,
          }),
        }),
        fromDate: new Date(),
      }),
  })
  implements
    IFilterConfiguration<
      LessThanFillsFromDateFilterRecord,
      OrderedSet<SelectValueRecord>,
      LessThanFillsFromDateFilterRecord,
      LessThanFillsFromDateFilterBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new LessThanFillsFromDateConfig();
