import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styles from './WorkItemHeader.css';

const WorkItemHeader = ({ workItem }) => (
  <header className={styles.workItemHeader}>
    <h1 className={styles.h1}>
      {(!workItem || !workItem.id) && <span>Loading Work Item...</span>}
      {workItem && workItem.id && (
        <Fragment>
          <span>Work Item</span>
          <span> {workItem.claim.claimNumber}</span>
        </Fragment>
      )}
    </h1>
  </header>
);

WorkItemHeader.propTypes = {
  workItem: ImmutablePropTypes.recordOf({
    id: PropTypes.string.isRequired,
  }),
};

export default WorkItemHeader;
