import {
  BESTPRACTICES_CATEGORY_FETCH_START,
  BESTPRACTICES_CATEGORY_FETCH_SUCCESS,
  BESTPRACTICES_CATEGORY_FETCH_FAIL,
  BESTPRACTICES_CATEGORY_UPDATE_DATE,
  BESTPRACTICES_OVERVIEW_FETCH_START,
  BESTPRACTICES_OVERVIEW_FETCH_SUCCESS,
  BESTPRACTICES_OVERVIEW_FETCH_FAIL,
} from './constants';

export function fetchBestPracticesOverview(dateRange) {
  return {
    type: BESTPRACTICES_OVERVIEW_FETCH_START,
    payload: { dateRange },
  };
}

export function fetchBestPracticesOverviewSuccess(data) {
  return {
    type: BESTPRACTICES_OVERVIEW_FETCH_SUCCESS,
    payload: { categories: data },
  };
}

export function fetchBestPracticesOverviewFail(error) {
  return {
    type: BESTPRACTICES_OVERVIEW_FETCH_FAIL,
    payload: { error },
  };
}

export function fetchBestPracticeCategory(category, dateRange) {
  return {
    type: BESTPRACTICES_CATEGORY_FETCH_START,
    payload: { category, dateRange },
  };
}

export function fetchBestPracticeCategorySuccess(categories) {
  return {
    type: BESTPRACTICES_CATEGORY_FETCH_SUCCESS,
    payload: { categories },
  };
}

export function fetchBestPracticeCategoryFail(error) {
  return {
    type: BESTPRACTICES_CATEGORY_FETCH_FAIL,
    payload: { error },
  };
}

export function updateBestPracticeCategoryDate(category, dateRange) {
  return {
    type: BESTPRACTICES_CATEGORY_UPDATE_DATE,
    payload: { category, dateRange },
  };
}
