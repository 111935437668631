import { normalize } from 'normalizr';
import { call } from 'ramda';
import { put, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from 'app/common/sagas';
import { upsertSessionFail, upsertSessionSuccess } from 'app/session/actions';
import { upsertSessionAsync } from 'app/session/api';
import { SESSION_UPSERT } from 'app/session/constants';
import { SessionInputRecord, sessionSchema } from 'app/session/types';

export function* upsertSessionWorker(action: {
  type: string;
  payload: { optimisticId: string; id: string; session: SessionInputRecord };
}) {
  const { optimisticId, id, session } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token: string) => call(upsertSessionAsync, session, token),
    (response) =>
      put(
        upsertSessionSuccess(
          optimisticId,
          response.session.id,
          normalize(response.session, sessionSchema),
        ),
      ),
    (error) => upsertSessionFail(optimisticId, id, error),
  );
}

export function* upsertSessionWatcher() {
  yield takeLatest(SESSION_UPSERT, upsertSessionWorker);
}
