import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  fetchClaimViewerError,
  hasFetchedClaimViewer,
} from '../../../ui/selectors';
import ClaimViewer from '../../components/ClaimViewer/ClaimViewer';
import { getPatientClaims } from '../../selectors';

export const ClaimViewerContainer = ({ claims, error, hasFetched }) => {
  return <ClaimViewer claims={claims} hasFetched={hasFetched} error={error} />;
};

ClaimViewerContainer.propTypes = {
  hasFetched: PropTypes.bool.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  claims: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
};

ClaimViewerContainer.defaultProps = {
  claims: new Set(),
};

export default connect((state, props) => ({
  hasFetched: hasFetchedClaimViewer(state, props.id),
  error: fetchClaimViewerError(state),
  claims: getPatientClaims(state, props.id),
}))(ClaimViewerContainer);
