import { combineReducers } from 'redux-immutable';
import { persistReducer } from '../../hydrate/persist';
import {
  VIEWTEMPLATE_SAVE_START,
  VIEWTEMPLATE_SAVE_SUCCESS,
  VIEWTEMPLATE_SAVE_FAIL,
  VIEWTEMPLATE_QUICKSAVE_START,
  VIEWTEMPLATE_QUICKSAVE_SUCCESS,
  VIEWTEMPLATE_QUICKSAVE_FAIL,
  VIEWTEMPLATE_APPLY,
  VIEWTEMPLATE_DELETE,
  VIEWTEMPLATE_DELETE_SUCCESS,
  VIEWTEMPLATE_DELETE_FAIL,
  VIEWTEMPLATE_DELETE_CANCEL,
} from '../../viewtemplates/constants';
import {
  WORKITEMS_QUERY_FETCH_START,
  WORKITEMS_QUERY_FETCH_SUCCESS,
  WORKITEMS_QUERY_FETCH_FAIL,
  WORKITEMS_QUERY_CLEAR,
} from '../../workitems/constants';
import {
  FILTER_SIDEBAR_EXPAND,
  FILTER_SIDEBAR_MINIMIZE,
  WORKITEMLIST_FILTERS_CLEAR,
  WORKITEMLIST_FILTERS_UPDATE,
  WORKITEMLIST_FILTER_SET_APPLIED,
  WORKITEM_LIST_SCROLL_TOP_CHANGE,
} from '../constants';
import {
  WorkItemListRecord,
  QueryRecord,
  QuickSaveRecord,
  TemplatesRecord,
  TemplateDeleteRecord,
} from '../types';
import filterReducer from './workItemListFilters';

const queryReducer = (state = new QueryRecord(), action) => {
  switch (action.type) {
    case WORKITEMS_QUERY_FETCH_START: {
      return state.merge({
        isFetching: true,
        error: null,
      });
    }
    case WORKITEMS_QUERY_FETCH_SUCCESS:
      return state.merge({
        isFetching: false,
        continuationToken: action.payload.data.result.continuationToken || null,
        error: null,
      });
    case WORKITEMS_QUERY_FETCH_FAIL:
      return state.merge({
        isFetching: false,
        error: action.payload.error,
      });
    case WORKITEMLIST_FILTERS_UPDATE:
    case WORKITEMLIST_FILTER_SET_APPLIED:
    case WORKITEMLIST_FILTERS_CLEAR:
    case WORKITEMS_QUERY_CLEAR:
    case VIEWTEMPLATE_APPLY:
      return state.merge({
        continuationToken: null,
      });
    default:
      return state;
  }
};

const quickSaveReducer = (state = new QuickSaveRecord(), action) => {
  switch (action.type) {
    case VIEWTEMPLATE_QUICKSAVE_START:
      return state.merge({
        isSaving: true,
        error: null,
      });
    case VIEWTEMPLATE_QUICKSAVE_SUCCESS:
      return state.merge({
        isSaving: false,
        error: null,
      });
    case VIEWTEMPLATE_QUICKSAVE_FAIL:
      return state.merge({
        isSaving: false,
        error: 'Unexpected error while saving the template',
      });
    default:
      return state;
  }
};

const templateDeleteReducer = (state = new TemplateDeleteRecord(), action) => {
  switch (action.type) {
    case VIEWTEMPLATE_DELETE:
      return state.merge({
        isDeleting: true,
        error: null,
      });
    case VIEWTEMPLATE_DELETE_SUCCESS:
    case VIEWTEMPLATE_DELETE_CANCEL:
      return state.merge({
        isDeleting: false,
        error: null,
      });
    case VIEWTEMPLATE_DELETE_FAIL:
      return state.merge({
        isDeleting: false,
        error: action.payload.error,
      });
    default:
      return state;
  }
};
const isMinimizedReducer = (state = false, action) => {
  switch (action.type) {
    case FILTER_SIDEBAR_EXPAND:
      return false;
    case FILTER_SIDEBAR_MINIMIZE:
      return true;
    default:
      return state;
  }
};
const filterSidebarReducer = combineReducers({
  isMinimized: isMinimizedReducer,
  filters: filterReducer,
});

const scrollTopReducer = (state = 0, action) => {
  switch (action.type) {
    case WORKITEM_LIST_SCROLL_TOP_CHANGE:
      return action.payload.scrollTop;
    default:
      return state;
  }
};

const templatesReducer = (state = new TemplatesRecord(), action) => {
  switch (action.type) {
    case VIEWTEMPLATE_SAVE_START:
      return state.merge({ isSaving: true });
    case VIEWTEMPLATE_SAVE_SUCCESS:
      return state.merge({ isSaving: false });
    case VIEWTEMPLATE_SAVE_FAIL:
      return state.merge({ isSaving: false });
    default:
      return state;
  }
};

export default combineReducers(
  {
    templates: templatesReducer,
    query: queryReducer,
    quickSave: quickSaveReducer,
    filterSidebar: persistReducer('ui/workitemlist', filterSidebarReducer),
    scrollTop: scrollTopReducer,
    templateDelete: templateDeleteReducer,
  },
  new WorkItemListRecord(),
);
