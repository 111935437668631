import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { Delete, DragHandle as DragHandleIcon } from '@material-ui/icons';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import {
  InteractionFieldDefinitionRecord,
  InteractionFieldType,
} from '../../types';
import styles from './InteractionInputs.css';

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const SortableItem = memo(
  SortableElement(({ key, value, onChange, indexValue, onDelete }) => (
    <ListItem key={key} classes={{ root: styles.listItem }}>
      <ListItemIcon classes={{ root: styles.dragHandle }}>
        <DragHandle />
      </ListItemIcon>
      <TextField
        variant="outlined"
        label="Name"
        classes={{ root: styles.fieldInputName }}
        value={value.name}
        onChange={(e) => {
          onChange(indexValue, value.merge({ name: e.target.value }));
        }}
      />
      <FormControl variant="outlined" classes={{ root: styles.fieldInput }}>
        <InputLabel id={`${key}-type-select`}>Type</InputLabel>
        <Select
          labelId={`${key}-type-select`}
          label="Type"
          value={value.type}
          defaultValue="SELECT"
          variant="outlined"
          onChange={(e) => {
            onChange(indexValue, value.merge({ type: e.target.value }));
          }}
        >
          <MenuItem key="1" value={InteractionFieldType.Text}>
            Text
          </MenuItem>
          <MenuItem key="2" value={InteractionFieldType.Select}>
            Drop Down
          </MenuItem>
          <MenuItem key="3" value={InteractionFieldType.Boolean}>
            Check box
          </MenuItem>
        </Select>
      </FormControl>
      {value.type === InteractionFieldType.Select && (
        <ChipInput
          variant="outlined"
          label="Options"
          value={value.options.toArray()}
          onAdd={(v) => {
            onChange(
              indexValue,
              value.merge({ options: value.options.push(v) }),
            );
          }}
          onDelete={(v, i) => {
            onChange(
              indexValue,
              value.merge({ options: value.options.delete(i) }),
            );
          }}
        />
      )}
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => onDelete(indexValue)}
      >
        <Delete />
      </IconButton>
    </ListItem>
  )),
);

const SortableList = memo(
  SortableContainer(({ items, onChange, onDelete }) => {
    return (
      <List classes={{ root: styles.list }} disablePadding>
        {items.map((value, index) => (
          <SortableItem
            key={index}
            index={index}
            indexValue={index}
            value={value}
            onChange={onChange}
            onDelete={onDelete}
          />
        ))}
      </List>
    );
  }),
);

const InteractionInputs = ({
  value,
  onChange,
  label,
  error,
  disabled,
  helperText,
  key,
  variant = 'outlined',
  classes,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [mutatedState, setMutatedState] = useState(value);

  const handleSort = ({ oldIndex, newIndex }) => {
    const srcItem = mutatedState.get(oldIndex);
    setMutatedState(
      mutatedState.splice(oldIndex, 1).splice(newIndex, 0, srcItem),
    );
  };

  const handleSave = () => {
    onChange(mutatedState);
    setOpen(false);
  };

  const handleCancel = () => {
    setMutatedState(value);
    setOpen(false);
  };

  const handleChange = (index, v) => {
    setMutatedState(mutatedState.set(index, v));
  };

  const handleAddField = () => {
    setMutatedState(mutatedState.push(new InteractionFieldDefinitionRecord()));
  };

  const handleRemoveField = (index) => {
    setMutatedState(mutatedState.delete(index));
  };

  const inputId = `${key}-input-outlined`;

  return (
    <>
      <FormControl
        classes={classes}
        variant={variant}
        focused={isOpen}
        disabled={disabled}
      >
        <InputLabel htmlFor={inputId} error={error}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={inputId}
          label={label}
          readOnly
          value={value.map((x) => x.name).join(', ')}
          classes={{ input: styles.input }}
          onClick={() => {
            if (!disabled) {
              setOpen(true);
            }
          }}
          error={error}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
      <Dialog maxWidth={false} open={isOpen}>
        <Paper classes={{ root: styles.container }}>
          <div className={styles.header}>
            <div className={styles.headerText}>Interaction Form Fields</div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddField}
            >
              Add Field
            </Button>
          </div>
          <Scrollbars
            renderThumbVertical={({ style, ...props }) => (
              <div style={style} {...props} className={styles.thumbVertical} />
            )}
          >
            <SortableList
              helperClass={styles.dragging}
              useDragHandle
              lockAxis="y"
              items={mutatedState}
              onChange={handleChange}
              onSortEnd={handleSort}
              onDelete={handleRemoveField}
            />
          </Scrollbars>
          <div className={styles.submitRow}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Done
            </Button>
          </div>
        </Paper>
      </Dialog>
    </>
  );
};

InteractionInputs.propTypes = {
  value: ImmutablePropTypes.listOf(ImmutablePropTypes.record).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  key: PropTypes.string,
  variant: PropTypes.oneOf(['outlined']),
  classes: PropTypes.string,
};

InteractionInputs.defaultProps = {
  variant: 'outlined',
};

export default InteractionInputs;
