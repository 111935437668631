import { ErrorOutline } from '@material-ui/icons';
import { Link } from '@reach/router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styles from './UnexpectedError.css';

const UnexpectedError = ({ header, description, className }) => (
  <div className={classnames(styles.container, className)}>
    <div className={styles.contentWrap}>
      <ErrorOutline className={styles.icon} />
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.description}>{description}</div>
    </div>
  </div>
);

UnexpectedError.propTypes = {
  header: PropTypes.string,
  description: PropTypes.node,
  className: PropTypes.string,
};

UnexpectedError.defaultProps = {
  header: 'Oops, something went wrong...',
  className: '',
  description: (
    <Fragment>
      <Link to={'/'}>Click here</Link>
      <span>
        {' '}
        to return to the home page. If the issue persists, please contact your
        company administrator for assistance.
      </span>
    </Fragment>
  ),
};

export default UnexpectedError;
