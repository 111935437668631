import { prop, pipe, curry } from 'ramda';
import { PharmaciesStateRecord } from 'app/pharmacies/types';

const getPharmaciesState = (state: any): PharmaciesStateRecord =>
  prop('pharmaciesState')(state);

export const getPharmacies = pipe(getPharmaciesState, prop('pharmacies'));

export const getPharmacy = curry((state: any, id: string) => {
  return getPharmacies(state).get(id);
});
