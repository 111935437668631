import { format, endOfToday } from 'date-fns';
import PropTypes from 'prop-types';
import { flatten, map, min, max, pipe, reduce, is, filter } from 'ramda';
import React, { cloneElement, forwardRef } from 'react';
import ContentLoader from 'react-content-loader';
import { withResizeDetector } from 'react-resize-detector';
import {
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  LineSegment,
  VictoryLabel,
  VictoryCursorContainer,
} from 'victory';
import styles from './RiskChart.css';

const { round } = Math;
const getDomainFromChild = (x) => x.type.getDomain(x.props, 'x');
const getYDomainFromChild = (x) => x.type.getDomain(x.props, 'y');

const notInfinity = (x) => x !== Infinity && x !== -Infinity;
const calculateDomain = (x) => [
  reduce(min, Infinity, x),
  reduce(max, -Infinity, x),
];

const RiskChart = ({
  children,
  style,
  onCursorChange,
  onMouseOut,
  isFetching,
  height,
  width,
}) => {
  const today = endOfToday();
  const childArray = is(Array, children) ? children : [children];
  const domain = pipe(
    map(getDomainFromChild),
    flatten,
    calculateDomain,
    filter(notInfinity),
  )(childArray);

  const domainY = pipe(
    map(getYDomainFromChild),
    flatten,
    calculateDomain,
    filter(notInfinity),
  )(childArray);

  let container;
  const labels = ({ datum }) => round(datum.riskScore.score);

  if (childArray.length > 1) {
    container = (
      <VictoryCursorContainer
        cursorComponent={<div />}
        onCursorChange={onCursorChange}
        events={{
          onMouseOut,
        }}
      />
    );
  }

  return (
    <div style={{ height: '100%', width: '100%', ...style }}>
      {isFetching && (
        <ContentLoader
          speed={2}
          width={height}
          height={width}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className={styles.loader}
        >
          <rect x="11.5%" y="0" rx="2" ry="2" width="77%" height="68%" />
          <rect x="22%" y="180" rx="2" ry="2" width="20" height="15" />
          <rect x="47.5%" y="180" rx="2" ry="2" width="20" height="15" />
          <rect x="73%" y="180" rx="2" ry="2" width="20" height="15" />
        </ContentLoader>
      )}
      {!isFetching && domain.length === 2 && (
        <VictoryChart
          height={height}
          width={width}
          scale={{ x: 'time', y: 'linear' }}
          theme={VictoryTheme.material}
          domainPadding={5}
          containerComponent={container}
        >
          <VictoryAxis
            domain={domain}
            tickComponent={
              <LineSegment
                y2={0}
                style={{ stroke: '#d0d0d0', strokeWidth: 2 }}
              />
            }
            axisComponent={<div />}
            tickLabelComponent={
              <VictoryLabel dx={90} style={{ fontSize: '16px' }} />
            }
            tickFormat={(x) => (x < today ? format(x, 'MMM') : null)}
          />
          <VictoryAxis
            height={height}
            width={width}
            style={{
              axis: { stroke: 'none' },
              ticks: { stroke: 'none' },
              tickLabels: { fill: 'none' },
            }}
            dependentAxis
            domain={domainY}
          />
          {childArray.map((x) => cloneElement(x, { labels }))}
        </VictoryChart>
      )}
    </div>
  );
};

RiskChart.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  style: PropTypes.object,
  onCursorChange: PropTypes.func,
  onMouseOut: PropTypes.func,
  isFetching: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

const ResponsiveRiskChart = withResizeDetector(RiskChart);

export default ResponsiveRiskChart;
