import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import { isSubmitted as isSubmittedSelector } from '../../../forms/selectors';
import {
  expandFilterSidebar,
  minimizeFilterSidebar,
  workItemListFiltersClear,
  workItemListScrollTopChange,
} from '../../../ui/actions';
import {
  getAppliedFilters,
  getIsTemplateDeleting,
  getQueryError,
  getScrollTop,
  getTemplateDeleteError,
  hasNextPage as hasNextPageSelector,
  isFetchingQuery,
  isFilterSidebarMinimized as isFilterSidebarMinimizedSelector,
  isSnackbarOpen as isSnackbarOpenSelector,
  isTemplateQuickSaving,
} from '../../../ui/selectors';
import { getAccessToken } from '../../../user/selectors';
import {
  saveViewTemplate,
  applyViewTemplate,
  quickSaveViewTemplate,
  createViewTemplate,
  fetchViewTemplates,
  deleteViewTemplate,
  deleteViewTemplateCancel,
} from '../../../viewtemplates/actions';
import { initialState as viewTemplateSaveInitialState } from '../../../viewtemplates/components/ViewTemplateSave/form';
import {
  getViewTemplates,
  getCurrentViewTemplate,
  isCurrentViewTemplateDirty as isCurrentViewTemplateDirtySelector,
  isUserCurrentViewTemplateOwner as isUserCurrentViewTemplateOwnerSelector,
} from '../../../viewtemplates/selectors';
import { ViewTemplateRecord } from '../../../viewtemplates/types';
import {
  fetchWorkItemsNextPage,
  fetchWorkItems,
  applyWorkItemSort,
  notifySortDisabled,
} from '../../actions';
import WorkItemsListView from '../../components/WorkItemsListView';
import {
  getQueryResultsFactory,
  getSortBy,
  getSortDirection,
} from '../../selectors';
import columnDefinitions from './columns';

export class WorkItemsListViewContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    workItems: PropTypes.object.isRequired,
    isFetchingWorkItems: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool,
    errorFetchingWorkItems: PropTypes.object,
    isCurrentViewTemplateDirty: PropTypes.bool.isRequired,
    currentViewTemplate: ImmutablePropTypes.recordOf({
      id: PropTypes.string,
      name: PropTypes.string,
      isPublic: PropTypes.bool,
      filters: ImmutablePropTypes.setOf(
        ImmutablePropTypes.recordOf({
          name: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
          valueType: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    viewTemplates: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.recordOf({
        id: PropTypes.string,
        name: PropTypes.string,
        isPublic: PropTypes.bool,
        filters: ImmutablePropTypes.setOf(
          ImmutablePropTypes.recordOf({
            name: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
            valueType: PropTypes.string.isRequired,
          }),
        ),
      }),
      PropTypes.string.isRequired,
    ),
    isQuickSaving: PropTypes.bool.isRequired,
    isSnackbarOpen: PropTypes.bool.isRequired,
    isFilterSidebarMinimized: PropTypes.bool.isRequired,
    scrollTop: PropTypes.number,
    isUserCurrentViewTemplateOwner: PropTypes.bool.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    isSortEnabled: PropTypes.bool.isRequired,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    isTemplateDeleting: PropTypes.bool.isRequired,
    templateDeleteError: PropTypes.string,
  };

  static defaultProps = {
    isSubmitted: false,
    scrollTop: 0,
    templateDeleteError: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isTemplateModalOpen: false,
      isTemplateDeleteModalOpen: false,
      isSaveModalOpen: false,
      scrollTop: 0,
      modifiedViewTemplate: new ViewTemplateRecord(),
    };
  }

  componentDidMount = () => {
    const { dispatch, workItems, scrollTop } = this.props;

    this.setState({ scrollTop });
    if (workItems.size === 0) {
      dispatch(fetchWorkItems());
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { isSubmitted, templateDeleteError, isTemplateDeleting } = nextProps;
    const { isSaveModalOpen, isTemplateDeleteModalOpen } = this.state;

    if (isSaveModalOpen && isSubmitted) {
      this.setState({ isSaveModalOpen: false });
      this.resetForm();
    }

    if (
      isTemplateDeleteModalOpen &&
      isNil(templateDeleteError) &&
      isTemplateDeleting === false
    ) {
      this.setState({ isTemplateDeleteModalOpen: false });
    }
  };

  componentWillUnmount = () => {
    const { scrollTop } = this.state;
    const { dispatch } = this.props;
    dispatch(workItemListScrollTopChange(scrollTop));
  };

  handleDiscardChanges = () => {
    const { dispatch, currentViewTemplate, viewTemplates } = this.props;
    if (currentViewTemplate.id) {
      const viewTemplate = viewTemplates.get(currentViewTemplate.id);
      dispatch(applyViewTemplate(viewTemplate, dispatch));
    } else {
      dispatch(workItemListFiltersClear());
    }
  };

  handleViewTemplateOpen = () => {
    const { dispatch } = this.props;
    dispatch(fetchViewTemplates());
    this.setState({ isTemplateModalOpen: true });
  };

  handleViewTemplateClose = () => this.setState({ isTemplateModalOpen: false });

  handleEditOnClick = (value) => {
    const { dispatch, viewTemplates, currentViewTemplate } = this.props;

    let modifiedViewTemplate = null;

    if (value === currentViewTemplate.id) {
      modifiedViewTemplate = currentViewTemplate;
    } else {
      modifiedViewTemplate = viewTemplates.get(value);
    }

    dispatch(
      actions.change('forms.viewTemplateSave', {
        id: modifiedViewTemplate.id,
        isPublic: modifiedViewTemplate.isPublic,
        name: modifiedViewTemplate.name,
        tags: modifiedViewTemplate.tags,
        isSubmitted: false,
      }),
    );
    this.setState({ isSaveModalOpen: true, modifiedViewTemplate });
  };

  handleSaveOnClick = () => {
    const { dispatch, currentViewTemplate } = this.props;
    dispatch(
      actions.change('forms.viewTemplateSave', {
        id: currentViewTemplate.id,
        isPublic: currentViewTemplate.isPublic,
        name: currentViewTemplate.name,
        tags: currentViewTemplate.tags,
        isSubmitted: false,
        isNew: false,
      }),
    );
    this.setState({
      isSaveModalOpen: true,
      modifiedViewTemplate: currentViewTemplate,
    });
  };

  handleSaveSubmit = (viewTemplate, isNew) => {
    const { dispatch } = this.props;
    if (isNew) {
      dispatch(createViewTemplate(viewTemplate));
    } else {
      dispatch(saveViewTemplate(viewTemplate));
    }
  };

  handleQuickSaveOnClick = () => {
    const { currentViewTemplate, dispatch } = this.props;
    dispatch(quickSaveViewTemplate(currentViewTemplate));
  };

  handleSaveAsOnClick = () => {
    const { dispatch, currentViewTemplate } = this.props;
    dispatch(
      actions.change('forms.viewTemplateSave', viewTemplateSaveInitialState),
    );
    this.setState({
      isSaveModalOpen: true,
      modifiedViewTemplate: currentViewTemplate,
    });
  };

  handleSaveCloseOnClick = () => {
    this.setState({ isSaveModalOpen: false });
    this.resetForm();
  };

  handleFilterSidebarToggle = () => {
    const { dispatch, isFilterSidebarMinimized } = this.props;
    dispatch(
      isFilterSidebarMinimized
        ? expandFilterSidebar()
        : minimizeFilterSidebar(),
    );
  };

  handleOnSort = ({ sortBy, sortDirection }) => {
    const { dispatch } = this.props;
    dispatch(applyWorkItemSort(sortBy, sortDirection));
  };

  handleOnHeaderClick = ({ columnData }) => {
    const { dispatch, isSortEnabled } = this.props;
    if (!isSortEnabled) {
      dispatch(notifySortDisabled(columnData));
    }
  };

  handleFetchNextPage = () => {
    const { dispatch } = this.props;
    dispatch(fetchWorkItemsNextPage());
  };

  handleOnWorkItemListScroll = ({ scrollTop }) => {
    this.setState({ scrollTop });
  };

  resetForm = () => {
    const { dispatch } = this.props;
    dispatch(actions.reset('forms.viewTemplateSave'));
  };

  handleTemplateDeleteRequest = () => {
    this.setState({ isTemplateDeleteModalOpen: true });
  };

  handleTemplateDeleteConfirm = (id) => {
    const { dispatch } = this.props;
    dispatch(deleteViewTemplate(id));
  };

  handleTemplateDeleteCancel = (id) => {
    const { dispatch } = this.props;
    this.setState({ isTemplateDeleteModalOpen: false });
    dispatch(deleteViewTemplateCancel(id));
  };

  render() {
    const {
      workItems,
      isFetchingWorkItems,
      errorFetchingWorkItems,
      currentViewTemplate,
      isCurrentViewTemplateDirty,
      isUserCurrentViewTemplateOwner,
      isQuickSaving,
      isSnackbarOpen,
      isFilterSidebarMinimized,
      isSortEnabled,
      sortBy,
      sortDirection,
      hasNextPage,
      isTemplateDeleting,
      templateDeleteError,
      appliedFilters,
    } = this.props;
    const {
      isSaveModalOpen,
      isTemplateModalOpen,
      isTemplateDeleteModalOpen,
      modifiedViewTemplate,
      scrollTop,
    } = this.state;

    return (
      <WorkItemsListView
        workItems={workItems}
        isFetchingData={isFetchingWorkItems}
        errorFetchingData={errorFetchingWorkItems}
        columnDefinitions={columnDefinitions}
        isSortEnabled={isSortEnabled}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={this.handleOnSort}
        viewTemplate={currentViewTemplate}
        onTemplateSave={this.handleSaveOnClick}
        onTemplateSaveAs={this.handleSaveAsOnClick}
        onTemplateSaveClose={this.handleSaveCloseOnClick}
        onTemplateEdit={this.handleEditOnClick}
        onDiscardChanges={this.handleDiscardChanges}
        isSaveModalOpen={isSaveModalOpen}
        isUserCurrentViewTemplateOwner={isUserCurrentViewTemplateOwner}
        onSaveSubmit={this.handleSaveSubmit}
        onQuickSave={this.handleQuickSaveOnClick}
        requestViewTemplateOpen={this.handleViewTemplateOpen}
        requestViewTemplateClose={this.handleViewTemplateClose}
        onSelectTemplate={this.handleViewTemplateClose}
        templateModalIsOpen={isTemplateModalOpen}
        isCurrentViewTemplateDirty={isCurrentViewTemplateDirty}
        isQuickSaveSnackbarOpen={isSnackbarOpen}
        isQuickSaving={isQuickSaving}
        isFilterSidebarMinimized={isFilterSidebarMinimized}
        onFilterSidebarToggle={this.handleFilterSidebarToggle}
        fetchNextPage={this.handleFetchNextPage}
        hasNextPage={hasNextPage}
        onWorkItemListScroll={this.handleOnWorkItemListScroll}
        workItemListScrollTop={scrollTop}
        modifiedViewTemplate={modifiedViewTemplate}
        onHeaderClick={this.handleOnHeaderClick}
        isTemplateDeleteModalOpen={isTemplateDeleteModalOpen}
        isTemplateDeleting={isTemplateDeleting}
        templateDeleteError={templateDeleteError}
        onTemplateDeleteRequest={this.handleTemplateDeleteRequest}
        onTemplateDeleteConfirm={this.handleTemplateDeleteConfirm}
        onTemplateDeleteCancel={this.handleTemplateDeleteCancel}
        appliedFilters={appliedFilters}
      />
    );
  }
}

const getQueryResults = getQueryResultsFactory();

export default connect((state) => ({
  token: getAccessToken(state),
  isFetchingWorkItems: isFetchingQuery(state),
  errorFetchingWorkItems: getQueryError(state),
  isQuickSaving: isTemplateQuickSaving(state),
  isSubmitted: isSubmittedSelector(state, 'viewTemplateSave'),
  workItems: getQueryResults(state),
  viewTemplates: getViewTemplates(state),
  currentViewTemplate: getCurrentViewTemplate(state),
  isUserCurrentViewTemplateOwner: isUserCurrentViewTemplateOwnerSelector(state),
  isCurrentViewTemplateDirty: isCurrentViewTemplateDirtySelector(state),
  isSnackbarOpen: isSnackbarOpenSelector(state),
  isFilterSidebarMinimized: isFilterSidebarMinimizedSelector(state),
  hasNextPage: hasNextPageSelector(state),
  scrollTop: getScrollTop(state),
  isSortEnabled: true,
  sortBy: getSortBy(state),
  sortDirection: getSortDirection(state),
  isTemplateDeleting: getIsTemplateDeleting(state),
  templateDeleteError: getTemplateDeleteError(state),
  appliedFilters: getAppliedFilters(state),
}))(WorkItemsListViewContainer);
