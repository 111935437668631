import { OrderedSet } from 'immutable';
import { pipe, prop, curry, sort } from 'ramda';
import { createSelector } from 'reselect';
import { graphqlSelector } from '../common/hooks/useQuery';
import graphql from '../common/utilities/graphql';
import { getSortValueSelector } from '../common/utilities/sorting';
import {
  getCaseListSortBy,
  getCaseListSortDirection,
  getCaseQueryResults,
} from '../ui/selectors';
import { getUserId } from '../user/selectors';
import columnDefinitions from './components/CaseList/columns';
import { CaseRecord } from './types';

const getCasesState = prop('casesState');
const getCases = pipe(getCasesState, prop('cases'));

export const getCasesFilters = pipe(getCasesState, prop('filters'));

// TODO remove hack
export const getCase = curry(
  (state, id) => getCases(state).get(id) ?? new CaseRecord(),
);

export const getCasesByIds = (state, ids) => ids.map((x) => getCase(state, x));

const getCasesFromQueryResults = (state) => {
  const queryResults = getCaseQueryResults(state).toIndexedSeq();

  const query = graphql`
    query cases($ids: [ID]!) {
      cases(ids: $ids) {
        id
        number
        status
        dateOpened
        followUpDate
        assignee {
          id
          name
        }
        campaign {
          id
          name
          entityId
        }
        interactions {
          id
          dateContacted
          status
          responses {
            level
            name
            value
            caseId
          }
        }
        target {
          ... on PatientType {
            id
            patientId
            followUpDate
          }
          ... on PrescriberType {
            id
            npi
          }
          __typename
        }
      }
    }
  `;

  const { cases } = graphqlSelector(state, query, { ids: queryResults });

  return new OrderedSet(
    sort(
      (a, b) =>
        queryResults.findIndex((c) => c === a.id) -
        queryResults.findIndex((c) => c === b.id),
      cases,
    ),
  );
};

export const getCaseList = createSelector(
  getCasesFilters,
  getCaseListSortDirection,
  createSelector(getCaseListSortBy, (sortBy) =>
    getSortValueSelector(sortBy, columnDefinitions),
  ),
  getUserId,
  getCasesFromQueryResults,
  (filters, sortDirection, sortValueSelector, userId, results) =>
    results.filter((c) => {
      let { assignees } = filters;

      if (assignees.contains('CurrentUser')) {
        assignees = assignees.delete('CurrentUser').add(userId);
      }

      return (
        (!assignees.isEmpty() ? assignees.includes(c.assignee?.id) : true) &&
        (!filters.campaigns.isEmpty()
          ? filters.campaigns.includes(c.campaign?.entityId)
          : true)
      );
    }),
  // TODO:  re-enable this line when cases have query tags on them
  // Trust the queryResults selector for now.
  //.sortBy(sortValueSelector, (a, b) => ascDescSort(a, b, sortDirection)),
);
