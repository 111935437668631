import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
} from '@material-ui/core';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Form, Control, actions } from 'react-redux-form';
import TextInput from '../../../common/components/TextInput';
import {
  getViewTemplateSaveForm,
  isSaving as isSavingSelector,
} from '../../../forms/selectors';
import styles from './ViewTemplateSave.css';

export class ViewTemplateSave extends Component {
  static propTypes = {
    form: PropTypes.shape({
      id: PropTypes.shape({
        value: PropTypes.string,
      }),
      name: PropTypes.shape({
        value: PropTypes.string,
      }),
      isPublic: PropTypes.shape({
        value: PropTypes.bool,
      }),
    }).isRequired,
    viewTemplate: ImmutablePropTypes.recordOf({
      id: PropTypes.string,
      name: PropTypes.string,
      isPublic: PropTypes.bool,
      filters: ImmutablePropTypes.setOf(
        ImmutablePropTypes.recordOf({
          name: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
          valueType: PropTypes.string.isRequired,
        }),
      ),
      tags: ImmutablePropTypes.set,
    }).isRequired,
    onRequestClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isSaving: null,
  };

  handleSubmit = (form) => {
    const { onSubmit, viewTemplate } = this.props;

    onSubmit(
      viewTemplate.merge({
        id: form.id,
        name: form.name,
        isPublic: form.isPublic,
        tags: form.isNew ? new Set() : form.tags,
      }),
      form.isNew,
    );
  };

  handleChange = (props) => (value) => {
    const { form } = this.props;
    if (!form.$form.valid) {
      this.resetError(form.$form.model);
    }
    props.onChange(value);
  };

  resetError(model) {
    const { dispatch } = this.props;
    dispatch(actions.resetValidity(model));
  }

  renderCheckbox = (props) => (
    <FormControlLabel
      control={<Checkbox color="primary" />}
      {...props}
      onChange={(event) => props.onChange(event.target.checked)}
      label={
        <span>
          Make this template <b>public</b>. Public templates can be seen by
          anyone.
        </span>
      }
    />
  );

  renderText = (props) => (
    <TextInput label="Name" id="templateName" margin="normal" {...props} />
  );

  render() {
    const { isOpen, onRequestClose, form, isSaving } = this.props;

    return (
      <Dialog
        classes={{ paper: styles.dialog }}
        open={isOpen}
        onClose={onRequestClose}
      >
        <h1>Save Template</h1>
        <Form model="forms.viewTemplateSave" onSubmit={this.handleSubmit}>
          <Control
            model="forms.viewTemplateSave.name"
            component={this.renderText}
            mapProps={{
              onChange: this.handleChange,
            }}
            controlProps={{
              error: !form.name.valid,
              helperText:
                !form.name.valid && typeof form.name.errors === 'string'
                  ? form.name.errors
                  : '',
            }}
          />
          <div className={styles.row}>
            <Control
              model="forms.viewTemplateSave.isPublic"
              component={this.renderCheckbox}
              mapProps={{
                onChange: this.handleChange,
                checked: (props) => props.fieldValue.value,
              }}
            />
          </div>

          {form.$form &&
            !form.$form.valid &&
            form.$form.errors &&
            typeof form.$form.errors === 'string' && (
              <div className={styles.saveError}>
                <span>{form.$form.errors}</span>
              </div>
            )}
          <div className={styles.footer}>
            <Button onClick={onRequestClose}>Cancel</Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                isSaving ||
                !(form.name.value && form.name.value.length > 0) ||
                !form.$form.valid
              }
            >
              {!isSaving ? 'Save' : <CircularProgress size={20} />}
            </Button>
          </div>
        </Form>
      </Dialog>
    );
  }
}

export default connect((state) => ({
  form: getViewTemplateSaveForm(state),
  isSaving: isSavingSelector(state, 'viewTemplateSave'),
}))(ViewTemplateSave);
