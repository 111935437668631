import { Divider } from '@material-ui/core';
import { PlaylistAdd } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import AppliedFilter from '../AppliedFilter';
import FilterFinder from '../FilterFinder';
import styles from './FilterSideBar.css';

const FilterSideBar = ({ filters, onApply, appliedFilters, isFetching }) => {
  const firstLoad = isFetching && filters && filters.length === 0;
  const nothingApplied = appliedFilters.length === 0;
  const displayFilters = !isFetching || (filters && filters.length > 0);
  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>Applied Filters</div>
      <Scrollbars className={styles.filtersContainer}>
        {(nothingApplied || firstLoad) && (
          <div className={styles.messageContainer}>
            <PlaylistAdd className={styles.messageIcon} />
            <div className={styles.messageText}>
              Try applying a template or adding some filters.
            </div>
          </div>
        )}
        {displayFilters &&
          appliedFilters.map((filter) => {
            return (
              <div className={styles.filter} key={filter.key}>
                <AppliedFilter {...filter.props} />
              </div>
            );
          })}
      </Scrollbars>
      <div className={styles.divider}>
        <Divider />
      </div>
      <div className={styles.sectionTitle}>Filters</div>
      <div className={styles.finderContainer}>
        <FilterFinder
          isFetching={isFetching && firstLoad}
          filters={filters}
          onApply={onApply}
        />
      </div>
    </div>
  );
};

FilterSideBar.propTypes = {
  filters: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
};

export default FilterSideBar;
