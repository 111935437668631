import { Record } from 'immutable';
import { combineReducers } from 'redux';
import pharmaciesReducer from 'app/pharmacies/reducer';
import { PharmaciesStateRecord } from 'app/pharmacies/types';
import bestPracticeReducer from './bestpractices/reducers';
import { BestPracticeStateRecord } from './bestpractices/types';
import campaignsReducer from './campaigns/reducer';
import { CampaignStateRecord } from './campaigns/types';
import casesReducer from './cases/reducers';
import { CasesStateRecord } from './cases/types';
import claimsReducer from './claims/reducer';
import { ClaimsStateRecord } from './claims/types';
import commentsReducer from './comments/reducers';
import { CommentStateRecord } from './comments/types';
import exportReducer from './export/reducer';
import { ExportStateRecord } from './export/types';
import filterReducer from './filters/reducer';
import { FiltersStateRecord } from './filters/types';
import formsReducer from './forms';
import hydrateReducer from './hydrate/reducer';
import { HydrateStateRecord } from './hydrate/types';
import interactionReducer from './interactions/reducers';
import { InteractionsStateRecord } from './interactions/types';
import issuesReducer from './issues/reducers';
import { IssuesStateRecord } from './issues/types';
import notificationsReducer from './notifications/reducer';
import { NotificationsStateRecord } from './notifications/types';
import patientsReducer from './patient/reducer';
import { PatientsStateRecord } from './patient/types';
import prescribersReducer from './prescribers/reducer';
import { PrescribersStateRecord } from './prescribers/types';
import queueReducer from './queue/reducer';
import { QueueStateRecord } from './queue/types';
import searchReducer from './search/reducer';
import { SearchStateRecord } from './search/types';
import SessionReducer from './session/reducer';
import { SessionStateRecord } from './session/types';
import uiReducer from './ui/reducers';
import { UiStateRecord } from './ui/types';
import userReducer from './user/reducer';
import { UserStateRecord } from './user/types';
import usersReducer from './users/reducer';
import { UsersStateRecord } from './users/types';
import viewTemplatesReducer from './viewtemplates/reducer';
import { ViewTemplatesStateRecord } from './viewtemplates/types';
import workflowReducer from './workflow/reducer';
import { WorkflowDefinitionsStateRecord } from './workflow/types';
import workItemsReducer from './workitems/reducers';
import { WorkItemsStateRecord } from './workitems/types';

export const StateRecord = Record({
  bestPracticeState: new BestPracticeStateRecord(),
  campaignsState: new CampaignStateRecord(),
  claimsState: new ClaimsStateRecord(),
  casesState: new CasesStateRecord(),
  commentsState: new CommentStateRecord(),
  exportState: new ExportStateRecord(),
  filtersState: new FiltersStateRecord(),
  hydrateState: new HydrateStateRecord(),
  notifications: new NotificationsStateRecord(),
  patientsState: new PatientsStateRecord(),
  prescribersState: new PrescribersStateRecord(),
  queueState: new QueueStateRecord(),
  searchState: new SearchStateRecord(),
  sessionState: new SessionStateRecord(),
  uiState: new UiStateRecord(),
  userState: new UserStateRecord(),
  usersState: new UsersStateRecord(),
  viewTemplatesState: new ViewTemplatesStateRecord(),
  workItemsState: new WorkItemsStateRecord(),
  workflowState: new WorkflowDefinitionsStateRecord(),
  interactionsState: new InteractionsStateRecord(),
  issuesState: new IssuesStateRecord(),
  forms: {}, // TODO react-redux-form has immutable support, investigate
  pharmaciesState: new PharmaciesStateRecord(),
});

const rootReducer = combineReducers({
  bestPracticeState: bestPracticeReducer,
  campaignsState: campaignsReducer,
  casesState: casesReducer,
  claimsState: claimsReducer,
  commentsState: commentsReducer,
  exportState: exportReducer,
  filtersState: filterReducer,
  hydrateState: hydrateReducer,
  interactionsState: interactionReducer,
  issuesState: issuesReducer,
  notificationState: notificationsReducer,
  patientsState: patientsReducer,
  prescribersState: prescribersReducer,
  queueState: queueReducer,
  searchState: searchReducer,
  sessionState: SessionReducer,
  uiState: uiReducer,
  userState: userReducer,
  usersState: usersReducer,
  viewTemplatesState: viewTemplatesReducer,
  workItemsState: workItemsReducer,
  forms: formsReducer,
  workflowState: workflowReducer,
  pharmaciesState: pharmaciesReducer,
});

export default rootReducer;
