import { createAction } from '@reduxjs/toolkit';
import {
  VIEWTEMPLATES_FETCH_START,
  VIEWTEMPLATES_FETCH_SUCCESS,
  VIEWTEMPLATES_FETCH_FAIL,
  VIEWTEMPLATE_APPLY,
  VIEWTEMPLATE_SAVE_START,
  VIEWTEMPLATE_SAVE_SUCCESS,
  VIEWTEMPLATE_SAVE_FAIL,
  VIEWTEMPLATE_CREATE_START,
  VIEWTEMPLATE_CREATE_SUCCESS,
  VIEWTEMPLATE_CREATE_FAIL,
  VIEWTEMPLATE_QUICKSAVE_START,
  VIEWTEMPLATE_QUICKSAVE_SUCCESS,
  VIEWTEMPLATE_QUICKSAVE_FAIL,
  VIEWTEMPLATES_FETCH_COUNT_START,
  VIEWTEMPLATE_FETCH_COUNT_SUCCESS,
  VIEWTEMPLATE_FETCH_COUNT_FAIL,
  VIEWTEMPLATE_DELETE,
  VIEWTEMPLATE_DELETE_FAIL,
  VIEWTEMPLATE_DELETE_SUCCESS,
  VIEWTEMPLATE_DELETE_CANCEL,
} from './constants';

export function fetchViewTemplates(fetchCounts = true) {
  return {
    type: VIEWTEMPLATES_FETCH_START,
    payload: { fetchCounts },
  };
}

export function fetchViewTemplatesSuccess(data) {
  return {
    type: VIEWTEMPLATES_FETCH_SUCCESS,
    payload: { data },
  };
}

export function fetchViewTemplatesFail(error) {
  return { type: VIEWTEMPLATES_FETCH_FAIL, payload: { error } };
}

export const applyViewTemplate = createAction(
  VIEWTEMPLATE_APPLY,
  (viewTemplate, dispatch) => {
    return {
      payload: {
        viewTemplate,
      },
      meta: dispatch,
    };
  },
);

export function saveViewTemplate(viewTemplate) {
  return { type: VIEWTEMPLATE_SAVE_START, payload: { viewTemplate } };
}

export function saveViewTemplateSuccess(viewTemplate) {
  return { type: VIEWTEMPLATE_SAVE_SUCCESS, payload: { viewTemplate } };
}

export function saveViewTemplateFail(error) {
  return { type: VIEWTEMPLATE_SAVE_FAIL, payload: { error } };
}

export function createViewTemplate(viewTemplate) {
  return { type: VIEWTEMPLATE_CREATE_START, payload: { viewTemplate } };
}

export function createViewTemplateSuccess(viewTemplate) {
  return { type: VIEWTEMPLATE_CREATE_SUCCESS, payload: { viewTemplate } };
}

export function createViewTemplateFail(error) {
  return { type: VIEWTEMPLATE_CREATE_FAIL, payload: { error } };
}

export function quickSaveViewTemplate(viewTemplate) {
  return { type: VIEWTEMPLATE_QUICKSAVE_START, payload: { viewTemplate } };
}

export function quickSaveViewTemplateSuccess(viewTemplate, message) {
  return {
    type: VIEWTEMPLATE_QUICKSAVE_SUCCESS,
    payload: { viewTemplate, message },
  };
}

export function quickSaveViewTemplateFail(error) {
  return { type: VIEWTEMPLATE_QUICKSAVE_FAIL, payload: { error } };
}

export function fetchViewTemplateCounts(ids) {
  return { type: VIEWTEMPLATES_FETCH_COUNT_START, payload: { ids } };
}

export function fetchViewTemplateCountSuccess(id, count, isCountStale) {
  return {
    type: VIEWTEMPLATE_FETCH_COUNT_SUCCESS,
    payload: { id, count, isCountStale },
  };
}

export function fetchViewTemplateCountFail(id, error) {
  return {
    type: VIEWTEMPLATE_FETCH_COUNT_FAIL,
    payload: { id, error },
  };
}

export function deleteViewTemplate(id) {
  return {
    type: VIEWTEMPLATE_DELETE,
    payload: {
      id,
    },
  };
}

export function deleteViewTemplateSuccess(id, isActiveTemplate) {
  return {
    type: VIEWTEMPLATE_DELETE_SUCCESS,
    payload: { id, isActiveTemplate },
  };
}

export function deleteViewTemplateFail(id, error) {
  return {
    type: VIEWTEMPLATE_DELETE_FAIL,
    payload: { id, error },
  };
}

export function deleteViewTemplateCancel() {
  return { type: VIEWTEMPLATE_DELETE_CANCEL, payload: {} };
}
