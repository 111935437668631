export const WORKITEMS_QUERY_START = 'WORKITEMS/WORKITEMS_QUERY_START';
export const WORKITEMS_QUERY_FETCH_NEXT =
  'WORKITEMS/WORKITEMS_QUERY_FETCH_NEXT';
export const WORKITEMS_QUERY_FETCH_START =
  'WORKITEMS/WORKITEMS_QUERY_FETCH_START';
export const WORKITEMS_QUERY_FETCH_SUCCESS =
  'WORKITEMS/WORKITEMS_QUERY_FETCH_SUCCESS';
export const WORKITEMS_QUERY_FETCH_FAIL =
  'WORKITEMS/WORKITEMS_QUERY_FETCH_FAIL';
export const WORKITEMS_SORT_APPLY = 'WORKITEMS/WORKITEMS_SORT_APPLY';
export const WORKITEMS_SORT_DISABLED = 'WORKITEMS/WORKITEMS_SORT_DISABLED';
export const WORKITEMS_QUERY_CLEAR = 'WORKITEMS/WORKITEMS_QUERY_CLEAR';

export const WORKITEM_DETAILS_FETCH_START =
  'WORKITEM/WORKITEM_DETAILS_FETCH_START';
export const WORKITEM_DETAILS_FETCH_SUCCESS =
  'WORKITEM/WORKITEM_DETAILS_FETCH_SUCCESS';
export const WORKITEM_DETAILS_FETCH_FAIL =
  'WORKITEM/WORKITEM_DETAILS_FETCH_FAIL';
export const WORKITEM_STATUSES_FETCH_START =
  'WORKITEM/WORKITEM_STATUSES_FETCH_START';
export const WORKITEM_STATUSES_FETCH_SUCCESS =
  'WORKITEM/WORKITEM_STATUSES_FETCH_SUCCESS';
export const WORKITEM_STATUSES_FETCH_FAIL =
  'WORKITEM/WORKITEM_STATUSES_FETCH_FAIL';
export const WORKITEM_SET_ASSIGNEE = 'WORKITEM/WORKITEM_SET_ASSIGNEE';
export const WORKITEM_SET_STATUS = 'WORKITEM/WORKITEM_SET_STATUS';

export const WORKITEM_UPSERT_START = 'WORKITEM/WORKITEM_UPSERT_START';
export const WORKITEM_UPSERT_SUCCESS = 'WORKITEM/WORKITEM_UPSERT_SUCCESS';
export const WORKITEM_UPSERT_FAIL = 'WORKITEM/WORKITEM_UPSERT_FAIL';

export const WORKITEM_STATUS_TYPE = 'WorkItemStatusEnum';
export const WORKITEM_LINE_OF_BUSINESS = 'LineOfBusinessEnum';

export enum WorkItemStatus {
  Open = 'OPEN',
  InProgress = 'INPROGRESS',
  Done = 'DONE',
}

export enum LineOfBusiness {
  MedicarePartD = 'MedicarePartD',
  Medicaid = 'Medicaid',
}

export enum QlEditType {
  None = 'None',
  MaxDailyDose = 'MaxDailyDose',
  QtyOverTime = 'QtyOverTime',
  Other = 'Other',
}

export enum MultiSourceCode {
  SingleSource = 'SingleSource',
  SingleSourceCoLicense = 'SingleSourceCoLicensed',
  MultiSourceOriginator = 'MultiSourceOriginator',
  MultiSource = 'MultiSource',
}

export enum PharmacyType {
  Unknown = 'Unknown',
  Mail = 'Mail',
  Retail = 'Retail',
  LongTermCare = 'LongTermCare',
  Specialty = 'Specialty',
  Other = 'Other',
}

export enum LicsLevel {
  None = 'None',
  One = 'Level1',
  Two = 'Level2',
  Three = 'Level3',
  Four = 'Level4',
}

export enum ClaimStatus {
  Rejected = 'Rejected',
  Paid = 'Paid',
  Reversed = 'Reversed',
}
