import graphql from '../common/utilities/graphql';

export const fetchCaseWorkflowDefinitionsQuery = graphql`
  query workflowDefinitions {
    workflowDefinitions {
      documentVersion
      entityId
      id
      name
      workflowType
    }
  }
`;
