import LockOutline from '@material-ui/icons/LockOutlined';
import { Link } from '@reach/router';
import React from 'react';
import { USER_INACTIVE_TIMEOUT } from '../../constants';
import styles from './Inactive.css';

const Inactive = (props) => (
  <div className={styles.container}>
    <div className={styles.contentWrap}>
      <LockOutline className={styles.icon} />
      <h1 className={styles.header}>Logged Out</h1>
      <div className={styles.description}>
        For your security, you have automatically been logged out after{' '}
        {USER_INACTIVE_TIMEOUT / 60} minutes of inactivity. Please click{' '}
        <Link to={props?.location?.state?.previousLocation || '/'} replace>
          here
        </Link>{' '}
        to login and return to your previous page.
      </div>
    </div>
  </div>
);

export default Inactive;
