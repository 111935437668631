import { pipe, prop } from 'ramda';

const getQueueState = prop('queueState');
export const getInteraciontLevelFields = (state) =>
  pipe(getQueueState, prop('patientInteractionFieldDefinitions'))(state);
export const getLockedSessionId = (state) =>
  pipe(getQueueState, prop('lockedSession'))(state);
export const getQueueStats = (state) =>
  pipe(getQueueState, prop('stats'))(state);
export const getContextForContact = (state) =>
  pipe(getQueueState, prop('contactContext'))(state);
