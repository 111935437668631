import { Record } from 'immutable';
import { IssueFilterType } from 'app/filters/constants';
import { booleanFilterDefaults } from 'app/filters/definitions/defaults';
import { IFilterConfiguration } from 'app/filters/types';

class HasCancerConfig
  extends Record({
    ...booleanFilterDefaults,
    name: 'hasCancer',
    title: 'Has Cancer',
    targetType: IssueFilterType.Patient,
  })
  implements IFilterConfiguration<boolean, undefined, boolean, boolean>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new HasCancerConfig();
