import { Map } from 'immutable';
import {
  CAMPAIGN_EDIT_FAIL,
  CAMPAIGN_CLONE_UPSERT_FAIL,
} from '../campaigns/constants';
import {
  CASE_UPSERT_FAIL,
  CASE_UPSERT_SUCCESS,
  BULK_CASE_UPSERT_FAIL,
  BULK_CASE_UPSERT_SUCCESS,
} from '../cases/constants';
import {
  COMMENT_ADD_FAIL,
  COMMENT_EDIT_FAIL,
  COMMENT_DELETE_FAIL,
} from '../comments/constants';
import { persistReducer } from '../hydrate/persist';
import { WORKITEM_UPSERT_FAIL } from '../workitems/constants';
import { NOTIFICATION_DISMISS } from './constants';
import { NotificationsStateRecord, NotificationRecord } from './types';

const reducer = (state = new NotificationsStateRecord(), action) => {
  switch (action.type) {
    case NOTIFICATION_DISMISS: {
      return state.delete(action.payload.id);
    }
    case COMMENT_DELETE_FAIL:
    case COMMENT_EDIT_FAIL:
    case COMMENT_ADD_FAIL: {
      const { optimisticId, id, workItemId, timestamp } = action.payload;
      const commentId = id || optimisticId;
      const notificationId = `${action.type}:${commentId}`;
      return state.set(
        notificationId,
        new NotificationRecord({
          id: notificationId,
          type: action.type,
          timestamp,
          data: new Map({ workItemId }),
        }),
      );
    }
    case WORKITEM_UPSERT_FAIL: {
      const { id: workItemId, timestamp, source } = action.payload;
      const id = `${source.type}:${workItemId}`;

      return state.set(
        id,
        new NotificationRecord({
          id,
          type: action.payload.source.type,
          timestamp,
          data: new Map({ workItemId, name: source.payload.name }),
        }),
      );
    }
    case CASE_UPSERT_FAIL: {
      const { optimisticId, source, timestamp } = action.payload;
      const notificationId = `${action.type}:${optimisticId}`;

      return state.set(
        notificationId,
        new NotificationRecord({
          id: notificationId,
          type: action.type,
          timestamp,
          data: new Map({ optimisticId, source }),
        }),
      );
    }
    case CASE_UPSERT_SUCCESS: {
      return state.delete(`${CASE_UPSERT_FAIL}:${action.payload.optimisticId}`);
    }
    case BULK_CASE_UPSERT_FAIL: {
      const { optimisticId, source, timestamp } = action.payload;
      const notificationId = `${action.type}:${optimisticId}`;

      return state.set(
        notificationId,
        new NotificationRecord({
          id: notificationId,
          type: action.type,
          timestamp,
          data: new Map({ optimisticId, source }),
        }),
      );
    }
    case BULK_CASE_UPSERT_SUCCESS: {
      return state.delete(
        `${BULK_CASE_UPSERT_FAIL}:${action.payload.optimisticId}`,
      );
    }
    case CAMPAIGN_EDIT_FAIL: {
      const { id } = action.payload;
      const notificationId = `${action.type}:${id}`;

      return state.set(
        notificationId,
        new NotificationRecord({
          id: notificationId,
          type: action.type,
          timestamp: new Date(),
          data: new Map({ id }),
        }),
      );
    }
    case CAMPAIGN_CLONE_UPSERT_FAIL: {
      const { id } = action.payload;
      const notificationId = `${action.type}:${id}`;

      return state.set(
        notificationId,
        new NotificationRecord({
          id: notificationId,
          type: action.type,
          timestamp: new Date(),
          data: new Map({ id }),
        }),
      );
    }
    default:
      return state;
  }
};

export default persistReducer('notifications', reducer);
