import { Set } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { fetchDrugOverviewError } from '../../../ui/selectors';
import OpioidOverviewChart from '../../components/OpioidOverviewChart/OpioidOverviewChart';
import {
  getOpioidOverviewClaims,
  getOpioidNaiveWindows,
} from '../../selectors';

export const OpioidOverviewChartContainer = ({
  claims,
  onClaimClick,
  issues,
  selectedIssueId,
  naiveWindows,
  hasFetched,
  error,
  isTourOpen,
  onTourClose,
}) => {
  return (
    <OpioidOverviewChart
      claims={claims.toArray()}
      naiveWindows={naiveWindows}
      onClaimClick={onClaimClick}
      issues={issues}
      selectedIssueId={selectedIssueId}
      hasFetched={hasFetched}
      error={error}
      isTourOpen={isTourOpen}
      onTourClose={onTourClose}
    />
  );
};

OpioidOverviewChartContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  claims: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
};

OpioidOverviewChartContainer.defaultProps = {
  claims: new Set(),
};

export default connect((state, props) => ({
  error: fetchDrugOverviewError(state),
  claims: getOpioidOverviewClaims(state, props.id),
  naiveWindows: getOpioidNaiveWindows(state, props.id),
}))(OpioidOverviewChartContainer);
