import { startOfMonth, subMonths } from 'date-fns';
import { Record } from 'immutable';
import { Interval } from '../common/types';
import { SummaryRecord } from './types';

export const aggregateSummaries = (summaries) =>
  summaries.reduce(
    (agg, cur) =>
      new SummaryRecord({
        appropriate: agg.appropriate + cur.appropriate,
        inappropriate: agg.inappropriate + cur.inappropriate,
        notReviewed: agg.notReviewed + cur.notReviewed,
      }),
    new SummaryRecord(),
  );

export const bestPracticeHistoricDateRange = () =>
  new Interval({
    start: startOfMonth(subMonths(new Date(), 3)),
    end: new Date(),
  });
