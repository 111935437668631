import { Record } from 'immutable';
import { both, is, complement, equals, isNil } from 'ramda';

export class DurationRecord extends Record({
  years: 0,
  months: 0,
  weeks: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
}) {}

export const absoluteDuration = (duration: DurationRecord) => {
  return duration.merge({
    years: Math.abs(duration.years),
    months: Math.abs(duration.months),
    weeks: Math.abs(duration.weeks),
    days: Math.abs(duration.days),
    hours: Math.abs(duration.hours),
    minutes: Math.abs(duration.minutes),
    seconds: Math.abs(duration.seconds),
  });
};

const isValidNumber: (b: any) => boolean = both(
  is(Number),
  complement(equals(Number.NaN)),
);

function parseIso(inp: string, sign: number) {
  const result = inp && Number.parseFloat(inp.replace(',', '.'));

  return !isValidNumber(result) ? 0 : (result as number) * sign;
}

// stolen from momentjs
// https://github.com/moment/moment/blob/develop/src/lib/duration/create.js
export const parseISODuration = (duration: string) => {
  const isoRegex =
    /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
  const match = isoRegex.exec(duration);
  if (isNil(match)) throw new Error('Unable to parse duration.');
  const sign = match[1] === '-' ? -1 : 1;

  return new DurationRecord({
    years: parseIso(match[2], sign),
    months: parseIso(match[3], sign),
    weeks: parseIso(match[4], sign),
    days: parseIso(match[5], sign),
    hours: parseIso(match[6], sign),
    minutes: parseIso(match[7], sign),
    seconds: parseIso(match[8], sign),
  });
};
