import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
  QUEUE_FETCH_STATS_SUCCESS,
  QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS,
  QUEUE_SESSION_DISMOUNT,
} from './constants';
import { ContactContextRecord, QueueStateRecord } from './types';

const reducer = (state = new QueueStateRecord(), action) => {
  switch (action.type) {
    case QUEUE_SKIP_SESSION_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS: {
      return state.merge({
        lockedSession: action.payload.data.result,
      });
    }
    case QUEUE_FETCH_STATS_SUCCESS: {
      return state.mergeDeep({
        stats: { ...action.payload.stats },
      });
    }
    case QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS: {
      return state.merge({
        contactContext: new ContactContextRecord({
          lockedBy: action.payload.data.result.lockedBy,
        }),
      });
    }
    case QUEUE_SESSION_DISMOUNT: {
      return state.merge({
        lockedSession: null,
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
