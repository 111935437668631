import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import { numericOperatorConfig, IssueFilterType } from 'app/filters/constants';
import { numericComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  handleNumericComparisonOnChange,
  numericToTextComparisonConverter,
} from 'app/filters/definitions/utility';
import { ClaimStatus } from 'app/workitems/constants';
import { StoredFilterType } from '../../../viewtemplates/constants';
import { WorkItemRecord } from '../../../workitems/types';
import TextComparison from '../../components/TextComparison';
import {
  IClaimFilterConfiguration,
  NumericComparison,
  NumericComparisonBackendRecord,
  NumericOperator,
} from '../../types';

const summaryFormatter = (value: NumericComparison) => {
  if (isNil(value.value)) return 'All';
  return `${numericOperatorConfig(value.operator.value).summary} ${
    value.value
  } MME/day`;
};

class PatientTotalMorphineMilligramEquivalentConfig
  extends Record({
    ...numericComparisonDefaults,
    name: 'patientTotalMorphineMilligramEquivalent',
    title: 'Patient Total MME',
    targetType: IssueFilterType.Claim,
    summaryFormatter,
    component: (props: any) => (
      <TextComparison
        {...props}
        inputType="number"
        placeholder="Enter MME"
        onChange={handleNumericComparisonOnChange(props)}
        value={numericToTextComparisonConverter(props.value)}
      />
    ),
    storedValueType: StoredFilterType.NumericComparison,
    filterWorkItem: (
      workItem: WorkItemRecord,
      record: NumericComparisonBackendRecord,
    ) => {
      if (isNil(record.number)) return true;
      if (isNil(workItem.claim.claimStatus)) return false;

      const totalMme =
        workItem.claim.claimStatus !== ClaimStatus.Rejected
          ? workItem.claim.patientTotalMorphineMilligramEquivalent
          : workItem.claim.patientPotentialTotalMorphineMilligramEquivalent;

      if (isNil(totalMme)) {
        return false;
      }

      return numericOperatorConfig(record.operator).filter(
        totalMme,
        record.number,
      );
    },
  })
  implements
    IClaimFilterConfiguration<
      NumericComparison,
      OrderedSet<NumericOperator>,
      NumericComparisonBackendRecord,
      NumericComparisonBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new PatientTotalMorphineMilligramEquivalentConfig();
