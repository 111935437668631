import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MyComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    shouldPrompt: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.onUnload = this.onUnload.bind(this);
  }

  onUnload(event) {
    if (this.props.shouldPrompt) {
      event.returnValue = '';
      return '';
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  render() {
    return this.props.children;
  }
}

export default MyComponent;
