import graphql from '../common/utilities/graphql';

export const pharmacyFragmentFactory = (name: string) => graphql`
  fragment ${name} on PharmacyType {
    id
    entityId
    name
    npi
    phoneNumbers {
      phoneNumber
      isPrimary
      source
      addedOn
    }
  }
`;
