import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Button,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import classnames from 'classnames';
import { format as formatDate, addYears, addDays } from 'date-fns';
import { convertFromRaw, convertToRaw, Editor, EditorState } from 'draft-js';
import { List, Record } from 'immutable';
import {
  append,
  ascend,
  defaultTo,
  find,
  identity,
  isNil,
  isNotNil,
  pipe,
  prop,
  sort,
  without,
} from 'ramda';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { NullEvent } from 'xstate';
import { CaseRecord } from 'app/cases/types';
import DateRangePicker from 'app/common/components/DateRangePicker/DateRangePicker';
import { PatientRecord } from 'app/patient/types';
import {
  CampaignRecord,
  InteractionFieldDefinitionRecord,
  InteractionFieldType,
} from '../../../campaigns/types';
import UnexpectedError from '../../../common/components/UnexpectedError/UnexpectedError';
import { isErrored, isRunning } from '../../../ui/selectors';
import { fetchInteractionFieldDefinitions } from '../../actions';
import {
  CASE_LEVEL_RESPONSE,
  ContactType,
  INTERACTION_LEVEL_RESPONSE,
} from '../../constants';
import { getPhoneInteractionFieldDefinitions } from '../../selectors';
import {
  CaseInteractionFieldResponse,
  InteractionFieldResponse,
  InteractionRecord,
  InteractionResponses,
} from '../../types';
import styles from './InteractionForm.css';

function format(value: any) {
  if (isNil(value)) {
    return '';
  }
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  if (value instanceof Date) {
    return formatDate(value, 'MM/dd/yyyy');
  }

  return String(value);
}

const sortAlphabetically = pipe(sort(ascend(identity as any) as any));

type InteractionSelectProps = {
  name: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
  readOnly: boolean;
};

const InteractionSelect = ({
  name,
  value = '',
  options,
  onChange,
  readOnly,
}: InteractionSelectProps) => (
  <FormControl className={styles.formControl}>
    <InputLabel id={`select-${name}`}>{name}</InputLabel>
    <Select
      readOnly={readOnly}
      disabled={readOnly}
      value={value}
      onChange={(e) => onChange(e.target.value as string)}
    >
      {options.map((o) => (
        <MenuItem key={o} value={o}>
          {o}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

type InteractionDateProps = {
  name: string;
  value: string;
  onChange: (value: string | null) => void;
  readOnly: boolean;
};

const InteractionDate = ({
  name,
  value,
  onChange,
  readOnly,
}: InteractionDateProps) => {
  const dateValue = isNil(value) ? null : new Date(value);
  return (
    <FormControl className={classnames(styles.formControl, styles.Date)}>
      <DateRangePicker
        label={name}
        variant="outlined"
        maxSelectableDate={addYears(new Date(), 2)}
        minSelectableDate={new Date()}
        classes={{ root: styles.input }}
        disabled={readOnly}
        value={dateValue}
        onChange={(date: any) =>
          onChange(isNil(date) ? null : format(new Date(date.toISOString())))
        }
      />
    </FormControl>
  );
};

type InteractionTextProps = {
  caseId: string;
  name: string;
  value: string | null;
  onChange: (value: string) => void;
  readOnly: boolean;
};

const InteractionText = ({
  caseId,
  name,
  value = null,
  onChange,
  readOnly,
}: InteractionTextProps) => {
  const [localState, setLocalState] = useState(EditorState.createEmpty());
  useEffect(() => {
    const state = isNil(value)
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
    setLocalState(state);
  }, [caseId]);

  const handleBlur = () => {
    onChange(JSON.stringify(convertToRaw(localState.getCurrentContent())));
  };

  return (
    <FormControl>
      <FormLabel>{name}</FormLabel>
      <div className={styles.caseNotes}>
        <Scrollbars
          renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
          renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
        >
          <div className={styles.editor}>
            <Editor
              readOnly={readOnly}
              editorState={localState}
              onChange={setLocalState}
              onBlur={handleBlur}
            />
          </div>
        </Scrollbars>
      </div>
    </FormControl>
  );
};

type InteractionBooleanProps = {
  name: string;
  value: string;
  onChange: (value: string) => void;
  readOnly: boolean;
};

const InteractionBoolean = ({
  name,
  value = 'false',
  onChange,
  readOnly,
}: InteractionBooleanProps) => {
  return (
    <FormControl className={classnames(styles.formControl, styles.checkbox)}>
      <FormControlLabel
        value="top"
        control={
          <Checkbox
            disabled={readOnly}
            color="primary"
            checked={value === 'true'}
            onChange={(e) => onChange(e.target.checked.toString())}
          />
        }
        label={name}
        labelPlacement="start"
      />
    </FormControl>
  );
};

const InteractionLoading = () => (
  <>
    <Skeleton
      variant="rect"
      width="100%"
      height={70}
      classes={{ root: styles.loadingContact }}
    />
    {new Array(Math.floor(Math.random() * 3 + 5)).fill('').map((x, i) => (
      <Skeleton
        key={`interaction-loading-${i}`}
        variant="rect"
        width="100%"
        height={30}
        classes={{ root: styles.loadingContact }}
      />
    ))}
    <Skeleton
      variant="rect"
      width="100%"
      height={80}
      classes={{ root: styles.loadingContact }}
    />
  </>
);

type InteractionFormProps = {
  interaction: InteractionRecord;
  contactType: string;
  activeCase: string;
  onCaseChange: (caseItem: CaseRecord) => void;
  onChange: (i: InteractionRecord) => void;
  isLoading: boolean;
  readOnly: boolean;
  interactionLevelOnly: boolean;
  caseLevelOnly: boolean;
  showCampaignTitle: boolean;
};

const InteractionForm = ({
  interaction,
  contactType = ContactType.Patient,
  activeCase,
  onCaseChange,
  onChange,
  isLoading,
  readOnly = false,
  interactionLevelOnly = false,
  caseLevelOnly = false,
  showCampaignTitle = false,
}: InteractionFormProps) => {
  const caseItem: CaseRecord | null = pipe(
    prop('cases') as any,
    defaultTo([]) as any,
    find((x: any) => x.id === activeCase),
  )(interaction) as CaseRecord | null;

  const areFieldsFetching = useSelector(
    (state) =>
      isRunning(
        state,
        fetchInteractionFieldDefinitions(contactType, activeCase),
      ) as boolean,
  );

  const interactionLevelFields = useSelector(
    (state) =>
      getPhoneInteractionFieldDefinitions(
        contactType,
        state,
      ) as List<InteractionFieldDefinitionRecord>,
  );

  const interactionLevelFieldErrors = useSelector((state) =>
    isErrored(state, fetchInteractionFieldDefinitions(contactType, activeCase)),
  );

  const getResponse = (
    field: InteractionFieldDefinitionRecord,
    responses: InteractionFieldResponse[] | CaseInteractionFieldResponse[],
  ) => {
    switch (field.level) {
      case CASE_LEVEL_RESPONSE: {
        return find(
          (f: CaseInteractionFieldResponse) =>
            f.name === field.name &&
            f.caseId === caseItem?.id &&
            f.level === CASE_LEVEL_RESPONSE,
        )(responses as CaseInteractionFieldResponse[]);
      }
      case INTERACTION_LEVEL_RESPONSE: {
        return find(
          (f: InteractionFieldResponse) =>
            f.name === field.name && f.level === INTERACTION_LEVEL_RESPONSE,
        )(responses as InteractionFieldResponse[]);
      }
      default: {
        return null;
      }
    }
  };

  const createResponse = (
    field: InteractionFieldDefinitionRecord,
    value: string | undefined,
  ) => {
    switch (field.level) {
      case CASE_LEVEL_RESPONSE: {
        return new CaseInteractionFieldResponse({
          caseId: (caseItem as CaseRecord).id as string,
          name: field.name,
          value,
        });
      }
      case INTERACTION_LEVEL_RESPONSE: {
        return new InteractionFieldResponse({
          name: field.name,
          value,
        });
      }
      default: {
        throw new Error(`Unknown level of {field.level}`);
      }
    }
  };

  const handleFieldChange =
    (field: InteractionFieldDefinitionRecord) => (value: string | null) => {
      const newInteraction: InteractionRecord = {
        ...interaction,
        responses: pipe(
          without([
            getResponse(
              field,
              interaction.responses as InteractionFieldResponse[],
            ),
          ]) as any,
          append(
            createResponse(
              field,
              isNotNil(value) ? (value as string) : undefined,
            ),
          ),
        )(interaction.responses as InteractionResponses[]),
      };

      onChange(newInteraction);
    };

  const renderField = (field: InteractionFieldDefinitionRecord) => {
    const value = getResponse(
      field,
      interaction.responses as InteractionFieldResponse[],
    )?.value;

    switch (field.type) {
      case InteractionFieldType.Select: {
        return (
          <InteractionSelect
            name={field.name as string}
            value={value as string}
            options={sortAlphabetically(field.options as string[]) as string[]}
            onChange={handleFieldChange(field)}
            key={field.name}
            readOnly={readOnly}
          />
        );
      }
      case InteractionFieldType.Text: {
        return (
          <InteractionText
            name={field.name as string}
            caseId={(caseItem as CaseRecord).id as string}
            value={value as string}
            onChange={handleFieldChange(field)}
            key={field.name}
            readOnly={readOnly}
          />
        );
      }
      case InteractionFieldType.Boolean: {
        return (
          <InteractionBoolean
            name={field.name as string}
            value={value as string}
            onChange={handleFieldChange(field)}
            key={field.name}
            readOnly={readOnly}
          />
        );
      }
      case InteractionFieldType.Date: {
        return (
          <InteractionDate
            name={field.name as string}
            value={value as string}
            onChange={handleFieldChange(field)}
            key={field.name}
            readOnly={readOnly}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderFollowUpDateField = (
    field: InteractionFieldDefinitionRecord,
    defaultValue: Date | undefined,
  ) => {
    const interactionResponseValue = getResponse(
      field,
      interaction.responses as InteractionFieldResponse[],
    )?.value;

    // interactionResponseValue will be undefined on initial form load,
    // it will change to null if the field is cleared,
    // on initial form load: default to previously set follow up date
    // if the field is cleared: use null

    const value =
      interactionResponseValue === undefined
        ? defaultValue
        : interactionResponseValue;

    return (
      <InteractionDate
        name={field.name as string}
        value={value as string}
        onChange={handleFieldChange(field)}
        key={field.name}
        readOnly={readOnly}
      />
    );
  };

  const activeCaseIndex: number =
    interaction.cases && (interaction.cases as CaseRecord[]).length > 0
      ? (interaction.cases as CaseRecord[]).findIndex(
          (element) => element.id === activeCase,
        )
      : -1;

  const workingCasesLength: number = interaction.cases
    ? (interaction.cases as CaseRecord[]).length
    : 0;

  const previousCase = (): void => {
    if (activeCaseIndex > 0) {
      onCaseChange((interaction.cases as CaseRecord[])[activeCaseIndex - 1]);
    }
  };

  const nextCase = (): void => {
    if (activeCaseIndex < workingCasesLength - 1 && activeCaseIndex > -1) {
      onCaseChange((interaction.cases as CaseRecord[])[activeCaseIndex + 1]);
    }
  };

  return (
    <div className={styles.interactionContent}>
      {!interactionLevelFieldErrors && (areFieldsFetching || isLoading) && (
        <InteractionLoading />
      )}
      {!interactionLevelFieldErrors &&
        !areFieldsFetching &&
        !isLoading &&
        !isNil(interaction) &&
        !isNil(activeCase) &&
        !isNil(caseItem) && (
          <Scrollbars>
            {showCampaignTitle && workingCasesLength > 1 && (
              <div>
                <h4 className={styles.caseHeader}>
                  {(caseItem.campaign as CampaignRecord).name}
                </h4>
                <div className={styles.caseNavigatorButtonWrapper}>
                  <Button
                    className={styles.caseNavigatorButtons}
                    size="small"
                    disabled={activeCaseIndex <= 0}
                    onClick={previousCase}
                  >
                    <ChevronLeft />
                  </Button>
                  <Button
                    className={styles.caseNavigatorButtons}
                    size="small"
                    disabled={activeCaseIndex >= workingCasesLength - 1}
                    onClick={nextCase}
                  >
                    <ChevronRight />
                  </Button>
                </div>
              </div>
            )}
            <div className={styles.mainContent}>
              {!caseLevelOnly &&
                interactionLevelFields?.toJS().map((field: any) => {
                  if (field.name === 'Contact Follow Up Date') {
                    if (contactType === ContactType.Patient) {
                      return renderFollowUpDateField(
                        field,
                        (caseItem.target as PatientRecord)?.followUpDate,
                      );
                    }
                  } else {
                    return renderField(field);
                  }
                })}
              {!interactionLevelOnly &&
                (
                  (caseItem.campaign as CampaignRecord)
                    ?.interactionFieldDefinitions as InteractionFieldDefinitionRecord[]
                ).map((field) => {
                  if (field.name === 'Case Follow Up Date') {
                    return renderFollowUpDateField(
                      field,
                      caseItem?.followUpDate,
                    );
                  } else {
                    return renderField(field);
                  }
                })}
            </div>
          </Scrollbars>
        )}
      {interactionLevelFieldErrors && (
        <UnexpectedError
          header="Error obtaining interaction fields"
          description="Please try refreshing your page"
        />
      )}
    </div>
  );
};

export default InteractionForm;
