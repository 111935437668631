import { isNil, pipe, prop, defaultTo } from 'ramda';
import { getFetchKeyForAction } from './utility';

const getUiState = (state) => state.uiState;

// Notifications
const getNotificationsState = (state) => getUiState(state).notifications;
export const isNotificationPanelOpen = (state) =>
  getNotificationsState(state).isPanelOpen;

// Best Practices Category
const getBestPracticeCategoryState = (state) =>
  getUiState(state).bestPracticeCategory;
export const isBestPracticesFetching = (state) =>
  getBestPracticeCategoryState(state).isFetching;
export const getBestPracticeErrors = (state) =>
  getBestPracticeCategoryState(state).error;
export const getSelectedDate = (state) =>
  getBestPracticeCategoryState(state).selectedDate;

// Best Practices Overview
const getBestPracticesOverviewState = (state) =>
  getUiState(state).bestPracticesOverview;
export const isBestPracticesOverviewFetching = (state) =>
  getBestPracticesOverviewState(state).isFetching;
export const getBestPracticesOverviewErrors = (state) =>
  getBestPracticesOverviewState(state).error;

// Snackbar
export const getSnackbarState = (state) => getUiState(state).snackbar;
export const isSnackbarOpen = (state) => getSnackbarState(state).isOpen;

// Sidebar
const getSidebarState = (state) => getUiState(state).sidebar;
export const isSidebarMinimized = (state) => getSidebarState(state).isMinimized;

// WorkItemList
const getWorkItemListState = (state) => getUiState(state).workItemList;
const getQueryState = (state) => getWorkItemListState(state).query;
const getQuickSaveState = (state) => getWorkItemListState(state).quickSave;
const getFilterSidebarState = (state) =>
  getWorkItemListState(state).filterSidebar;
const getTemplatesState = (state) => getWorkItemListState(state).templates;
const getTemplateDeleteState = (state) =>
  getWorkItemListState(state).templateDelete;

export const getAppliedFilters = (state) =>
  getFilterSidebarState(state).filters;
export const isFilterSidebarMinimized = (state) =>
  getFilterSidebarState(state).isMinimized;
export const isFetchingQuery = (state) => getQueryState(state).isFetching;
export const getQueryError = (state) => getQueryState(state).error;
export const isTemplateQuickSaving = (state) =>
  getQuickSaveState(state).isSaving;
export const isTemplateSaving = (state) => getTemplatesState(state).isSaving;
export const getTemplateQuickSaveError = (state) =>
  getQuickSaveState(state).error;
export const getContinuationToken = (state) =>
  getQueryState(state).continuationToken;
export const hasNextPage = (state) => getContinuationToken(state) !== null;
export const getScrollTop = (state) => getWorkItemListState(state).scrollTop;
export const getIsTemplateDeleting = (state) =>
  getTemplateDeleteState(state).isDeleting;
export const getTemplateDeleteError = (state) =>
  getTemplateDeleteState(state).error;

// WorkItemDetail
const getWorkItemDetailState = (state) => getUiState(state).workItemDetail;
const getDetailState = (state) => getWorkItemDetailState(state).detail;
const getStatusesState = (state) => getWorkItemDetailState(state).statuses;
const getCommentsState = (state) => getWorkItemDetailState(state).comments;
const getPatientClaimsHistoryState = (state) =>
  getWorkItemDetailState(state).patientClaimsHistory;
const getDrugHistoryState = (state) =>
  getWorkItemDetailState(state).patientDrugHistory;
export const isWorkItemDetailsFetching = (state) =>
  getDetailState(state).isFetching;
export const getWorkItemDetailError = (state) => getDetailState(state).error;
export const isWorkItemStatusesFetching = (state) =>
  getStatusesState(state).isFetching;
export const getWorkItemStatusesError = (state) =>
  getStatusesState(state).error;
export const isPatientClaimsHistoryFetching = (state) =>
  getPatientClaimsHistoryState(state).isFetching;
export const getPatientClaimsHistoryError = (state) =>
  getPatientClaimsHistoryState(state).error;
export const isPatientDrugHistoryFetching = (state) =>
  getDrugHistoryState(state).isFetching;
export const getPatientDrugHistoryError = (state) =>
  getDrugHistoryState(state).error;
export const getErroredCommentIds = (state) =>
  getCommentsState(state).erroredComments;
export const getAddingCommentIds = (state) =>
  getCommentsState(state).addingComments;
export const getDeletingCommentIds = (state) =>
  getCommentsState(state).deletingComments;
export const getEditingCommentIds = (state) =>
  getCommentsState(state).editingComments;
export const isWorkItemDetailSaving = (state) =>
  !getAddingCommentIds(state).subtract(getErroredCommentIds(state)).isEmpty() ||
  !getEditingCommentIds(state)
    .subtract(getErroredCommentIds(state))
    .isEmpty() ||
  !getDeletingCommentIds(state)
    .subtract(getErroredCommentIds(state))
    .isEmpty() ||
  getDetailState(state).isSaving;

// ViewTemplates
const getViewTemplatesState = (state) => getUiState(state).viewTemplates;
const getViewTemplateState = (state, id) =>
  getViewTemplatesState(state).get(id);
export const isViewTemplateCountFetching = (state, id) =>
  getViewTemplateState(state, id).isFetching;
export const getViewTemplateCountError = (state, id) =>
  getViewTemplateState(state, id).error;

// PatientProfile
const getPatientProfileState = (state) => getUiState(state).patientProfile;
export const isFetchingPatient = (state) =>
  getPatientProfileState(state).isFetchingPatient;
export const isFetchingClaimViewer = (state) =>
  getPatientProfileState(state).isFetchingClaimViewer;
export const isFetchingDrugOverview = (state) =>
  getPatientProfileState(state).isFetchingDrugOverview;
export const fetchPatientError = (state) =>
  getPatientProfileState(state).fetchPatientError;
export const fetchDrugOverviewError = (state) =>
  getPatientProfileState(state).fetchDrugOverviewError;
export const fetchClaimViewerError = (state) =>
  getPatientProfileState(state).fetchClaimViewerError;

// Campaign Edit
const getCampaignEditState = (state) => getUiState(state).campaignEdit;
export const getCampaignEditAppliedFilters = (state) =>
  getCampaignEditState(state).filters;

export const hasFetchedDrugOverview = (state, id) => {
  const fetchedId = getPatientProfileState(state).hasFetchedDrugOverview;
  if (isNil(fetchedId)) return false;
  return fetchedId === id;
};

export const hasFetchedPatient = (state, id) => {
  const fetchedId = getPatientProfileState(state).hasFetchedPatient;
  if (isNil(fetchedId)) return false;
  return fetchedId === id;
};

export const hasFetchedClaimViewer = (state, id) => {
  const fetchedId = getPatientProfileState(state).hasFetchedClaimViewer;
  if (isNil(fetchedId)) return false;
  return fetchedId === id;
};

// campaignList
const getCampaignListState = (state) => getUiState(state).campaignList;
const getDeleteCampaignState = (state) =>
  getCampaignListState(state).campaignDelete;

export const getErrorOnCampaignDelete = pipe(
  getDeleteCampaignState,
  prop('error'),
);

export const isDeletingCampaign = pipe(
  getDeleteCampaignState,
  prop('isDeleting'),
);

const getCloneCampaignState = (state) =>
  getCampaignListState(state).campaignClone;

export const getErrorOnCampaignClone = pipe(
  getCloneCampaignState,
  prop('error'),
);

export const isCloningCampaign = pipe(getCloneCampaignState, prop('isCloning'));

// CaseList
const getCaseList = (state) => getUiState(state).caseList;
const getCaseListSorting = pipe(getCaseList, prop('sorting'));

export const getCaseListContinuation = pipe(
  getCaseList,
  prop('continuationToken'),
);

export const getCaseQueryResults = pipe(getCaseList, prop('queryResults'));
export const getCaseListSortBy = pipe(
  getCaseListSorting,
  prop('sortBy'),
  defaultTo('DateOpened'),
);

export const getCaseListSortDirection = pipe(
  getCaseListSorting,
  prop('sortDirection'),
  defaultTo('DESC'),
);

// Issues List
const getIssueList = (state) => getUiState(state).issueList;
const getIssueListSorting = pipe(getIssueList, prop('sorting'));

export const getIssueListContinuation = pipe(
  getIssueList,
  prop('continuationToken'),
);

export const getIssueQueryResults = pipe(getIssueList, prop('queryResults'));
export const getIssueListSortBy = pipe(
  getIssueListSorting,
  prop('sortBy'),
  defaultTo('CreatedDate'),
);

export const getIssueListSortDirection = pipe(
  getIssueListSorting,
  prop('sortDirection'),
  defaultTo('ASC'),
);

// So fetch!
export const isRunning = (state, action) => {
  const actionKey = getFetchKeyForAction(action);
  return state.uiState.fetch.isRunning.some((_, key) =>
    key.startsWith(actionKey),
  );
};

export const isRunningAny = (state, ...actions) => {
  return (
    actions.map((action) => isRunning(state, action)).filter((x) => x).length >
    0
  );
};

export const hasCompleted = (state, action) => {
  const key = getFetchKeyForAction(action);
  return isNil(state.uiState.fetch.hasCompleted.get(key)) === false;
};

export const hasCompletedAll = (state, ...actions) => {
  return (
    actions.map((action) => hasCompleted(state, action)).filter((x) => x)
      .length === actions.length
  );
};

export const isErrored = (state, ...actions) => {
  return actions
    .map((action) => getFetchKeyForAction(action))
    .some((key) => !isNil(state.uiState.fetch.error.get(key)));
};

export const getError = (state, action) =>
  state.uiState.fetch.error.get(getFetchKeyForAction(action)) ?? [];

export const getErrors = (state, ...actions) => {
  return actions
    .flatMap((action) =>
      state.uiState.fetch.error.get(getFetchKeyForAction(action)),
    )
    .filter((x) => !isNil(x));
};

const getOptimistic = (state) => getUiState(state).optimistic;
export const getIdFromOptimisticId = (state, optimisticId) =>
  pipe(getOptimistic, (x) => x.get(optimisticId))(state);
