import { parseISO } from 'date-fns';
import { List, Map, Set } from 'immutable';
import { isNil, omit } from 'ramda';
import { SESSION_UPSERT_SUCCESS } from 'app/session/constants';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../../cases/constants';
import { PATIENT_FETCH_SUCCESS } from '../../patient/constants';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../../queue/constants';
import {
  CASE_LEVEL_RESPONSE,
  INTERACTION_LEVEL_RESPONSE,
  INTERACTION_SAVE_SUCCESS,
} from '../constants';
import {
  CaseInteractionFieldResponse,
  InteractionFieldResponse,
  InteractionRecord,
} from '../types';

const mapResponse = (response) => {
  switch (response.level) {
    case CASE_LEVEL_RESPONSE: {
      return new CaseInteractionFieldResponse(response);
    }
    case INTERACTION_LEVEL_RESPONSE: {
      return new InteractionFieldResponse(response);
    }
    default: {
      return null;
    }
  }
};

const findAndMerge = (state, interaction) => {
  const initialRecord = state.get(interaction.id) || new InteractionRecord();

  return initialRecord.merge({
    ...omit(['interactionLevelFields'], interaction),
    responses: !isNil(interaction.responses)
      ? new Set(interaction.responses?.map((f) => mapResponse(f)))
      : initialRecord.responses,
    cases: !isNil(interaction.cases)
      ? new List(interaction.cases)
      : initialRecord.responses,
    contact:
      interaction.contact?.id !== undefined
        ? interaction.contact.id
        : initialRecord.contact,
    dateContacted: !isNil(interaction.dateContacted)
      ? parseISO(interaction.dateContacted)
      : initialRecord.dateContacted,
  });
};

function reducer(state = new Map(), action) {
  switch (action.type) {
    case INTERACTION_SAVE_SUCCESS:
    case QUEUE_SKIP_SESSION_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case PATIENT_FETCH_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case SESSION_UPSERT_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS: {
      const interactions = new Map(action.payload.data.entities.interactions);

      return state.merge(interactions.map((x) => findAndMerge(state, x)));
    }
    default: {
      return state;
    }
  }
}

export default reducer;
