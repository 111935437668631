import { List, Map } from 'immutable';
import { isNil } from 'ramda';
import { put, select } from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import { ErrorRecord, GraphQlErrorRecord } from 'app/common/types';
import { getAccessToken } from '../user/selectors';

function createGraphQlErrorRecord(error: any) {
  return new GraphQlErrorRecord({
    message: error.message,
    path: List(error.path),
    locations: List(error.locations?.map(Map)),
    extensions: Map(error.extensions),
  });
}

function createUnexpectedErrorRecord(error: unknown) {
  return new ErrorRecord(serializeError(error));
}

// TODO type this
export function* genericGraphQlWorker(
  graphQlOperation: (token: string) => Promise<any>,
  onSuccess: (response: any) => any,
  onFail: (errors: any) => any,
): any {
  try {
    const token = yield select(getAccessToken);

    const response = yield graphQlOperation(token);

    if (isNil(response.errors)) {
      yield onSuccess(response);
    } else {
      yield put(onFail(response.errors.map(createGraphQlErrorRecord)));
    }
  } catch (error) {
    yield put(onFail([createUnexpectedErrorRecord(error)]));
  }
}
