import { isNil } from 'ramda';
import { DiagnosisCodeRecord } from '../claims/types';
import { pluralize } from '../common/utilities/generic';
import {
  WorkItemStatus,
  LineOfBusiness,
  LicsLevel,
  MultiSourceCode,
  PharmacyType,
  QlEditType,
} from './constants';

export const formatQuantityLimit = (
  amount: string | undefined,
  days: string | undefined,
  dailyAmount: number | undefined,
) => {
  if (!isNil(amount) && !isNil(days)) {
    if (!isNil(dailyAmount)) {
      return `${amount} / ${days} ${pluralize(
        'day',
        days,
      )} (${Number.parseFloat(dailyAmount.toFixed(3))} per day)`;
    }
    return `${amount} / ${days} ${pluralize('day', days)}`;
  }
  return '';
};

export const formatQuantityLimitOverTime = (
  amount: string | undefined,
  days: string | undefined,
) => {
  if (!isNil(amount) && !isNil(days)) {
    return `${amount} over ${days} ${pluralize('day', days)}`;
  }
  return '';
};

export const formatLineOfBusiness = (lob: string | LineOfBusiness) => {
  switch (lob) {
    case LineOfBusiness.MedicarePartD:
      return 'Medicare Part D';
    case LineOfBusiness.Medicaid:
      return 'Medicaid';
    default:
      return '';
  }
};

export const formatWorkItemStatus = (status: string | WorkItemStatus) => {
  switch (status) {
    case WorkItemStatus.Open:
      return 'Open';
    case WorkItemStatus.InProgress:
      return 'In Progress';
    case WorkItemStatus.Done:
      return 'Done';
    default:
      return 'Unknown';
  }
};

export const formatMultiSourceCode = (type: string | MultiSourceCode) => {
  switch (type) {
    case MultiSourceCode.SingleSource:
      return 'Single Source (N)';
    case MultiSourceCode.SingleSourceCoLicense:
      return 'Co-Licensed (M)';
    case MultiSourceCode.MultiSourceOriginator:
      return 'Multi-Source Originator (O)';
    case MultiSourceCode.MultiSource:
      return 'Multi-Source (Y)';
    default:
      return 'None';
  }
};

export const formatLICSLevel = (type: string | LicsLevel) => {
  switch (type) {
    case LicsLevel.None:
      return 'None';
    case LicsLevel.One:
      return '1';
    case LicsLevel.Two:
      return '2';
    case LicsLevel.Three:
      return '3';
    case LicsLevel.Four:
      return '4';
    default:
      return '';
  }
};

export const formatPharmacyType = (type: string | PharmacyType) => {
  switch (type) {
    case PharmacyType.Mail:
      return 'Mail';
    case PharmacyType.Retail:
      return 'Retail';
    case PharmacyType.LongTermCare:
      return 'Long-Term Care';
    case PharmacyType.Specialty:
      return 'Specialty';
    case PharmacyType.Other:
      return 'Other';
    default:
      return 'Unknown';
  }
};

export const formatQlEditType = (type: string | QlEditType) => {
  switch (type) {
    case QlEditType.None:
      return 'None';
    case QlEditType.MaxDailyDose:
      return '1 (Max Daily Dose)';
    case QlEditType.QtyOverTime:
      return '2 (Qty Over Time)';
    case QlEditType.Other:
      return 'Other';
    default:
      return 'Unknown';
  }
};

export const formatDiagnosisCode = (diagnosisCode: DiagnosisCodeRecord) => {
  if (!isNil(diagnosisCode.description)) {
    return `${diagnosisCode.description} (${diagnosisCode.code})`;
  }
  return diagnosisCode.code;
};
