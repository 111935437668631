import { TextField, InputAdornment } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { Component } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import styles from './TextInput.css';

class TextInput extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    leftIcon: PropTypes.element,
    containerClassName: PropTypes.string,
    placeholder: PropTypes.string,
    disableUnderline: PropTypes.bool,
    debounced: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.shape({
      root: PropTypes.string,
      input: PropTypes.string,
    }),
    autoFocus: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    onKeyDown: PropTypes.func,
    valueTransformer: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
  };

  static defaultProps = {
    defaultValue: '',
    disableUnderline: false,
    debounced: false,
    type: 'text',
    valueTransformer: (v) => v,
    classes: {},
    label: null,
    error: false,
    errorText: '',
    variant: 'standard',
    autoFocus: false,
    onKeyDown: () => {},
    placeholder: '',
    min: '',
    max: '',
  };

  constructor(props) {
    super(props);
    const { value, debounced } = this.props;
    this.onChangeSubject = new Subject();
    this.onChangeSubject
      .pipe(debounceTime(debounced ? 250 : 0))
      .subscribe(this.processValueChange);

    this.state = {
      value,
    };
  }

  componentWillUnmount = () => this.onChangeSubject.unsubscribe();

  processValueChange = (newValue) => {
    const { onChange } = this.props;
    onChange(newValue);
  };

  handleOnBlur = (event) => {
    const { value } = this.state;
    const { type } = this.props;
    const changeOnBlur = type === 'number';
    if (changeOnBlur) {
      this.onChangeSubject.next(
        Number.isNaN(Number.parseInt(value)) ? 0 : value,
      );
    }
  };

  handleValueChange = (event) => {
    const { valueTransformer, type } = this.props;
    const { value } = event.target;

    const transformedValue = valueTransformer(value);
    this.setState({ value: transformedValue }, () => {
      if (
        type !== 'number' ||
        (type === 'number' && Number.isNaN(Number.parseInt(value)) === false)
      ) {
        this.onChangeSubject.next(value);
      }
    });
  };

  render() {
    const {
      leftIcon,
      rightIcon,
      placeholder,
      disableUnderline,
      classes,
      type,
      margin,
      label,
      error,
      helperText,
      variant,
      onKeyDown,
      autoFocus,
      min,
      max,
    } = this.props;

    const { value } = this.state;
    const inputProps = {};

    if (variant === 'standard') {
      inputProps.disableUnderline = disableUnderline;
    }

    if (type === 'number' && (min !== '' || max !== '')) {
      inputProps.inputProps = {
        min,
        max,
      };
    }

    if (!isNil(leftIcon)) {
      inputProps.startAdornment = (
        <InputAdornment position="start">{leftIcon}</InputAdornment>
      );
    }

    if (!isNil(rightIcon)) {
      inputProps.endAdornment = (
        <InputAdornment position="end">{rightIcon}</InputAdornment>
      );
    }

    return (
      <div
        className={classnames({
          [styles.container]: true,
          [classes.root]: !isNil(classes.root),
        })}
      >
        <TextField
          value={value}
          classes={{
            root: classnames({
              [styles.input]: true,
              [classes.input]: !isNil(classes.input),
            }),
          }}
          placeholder={placeholder}
          onChange={this.handleValueChange}
          onBlur={this.handleOnBlur}
          InputProps={inputProps}
          fullWidth
          type={type}
          margin={margin}
          label={label}
          error={error}
          helperText={helperText}
          variant={variant}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
        />
      </div>
    );
  }
}

export default TextInput;
