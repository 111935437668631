import PropTypes from 'prop-types';
import { is } from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GraphqlTypes } from 'app/common/types';
import { IssueFilterType } from 'app/filters/constants';
import filterDefinitions from 'app/filters/definitions';
import { getFilters } from 'app/filters/selectors';
import { mapToValuesArray } from '../../../common/utilities/generic';
import { getAccessToken } from '../../../user/selectors';
import { fetchFilters } from '../../actions';
import FilterSideBar from '../../components/FilterSideBar';

const issueTypeToFilterType = (issueType) => {
  switch (issueType) {
    case IssueFilterType.Claim:
      return GraphqlTypes.claimsFilter;
    case IssueFilterType.Patient:
      return GraphqlTypes.patientFilter;
    default:
      throw new Error('Unrecognized issue filter type.');
  }
};

const FilterSideBarContainer = ({
  appliedFilters,
  filterType,
  onFilterUpdate,
  onEditAction,
  onFetchOptionsAction,
  onApplyAction,
  showWorkflowSpecificFilters = false,
}) => {
  const token = useSelector(getAccessToken);
  const filters = useSelector(getFilters);
  const isFetching = useSelector((state) => state.filtersState.isFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilters(issueTypeToFilterType(filterType)));
  }, [filterType]);

  const handleOnChange = (id, value) => {
    dispatch(onFilterUpdate(id, value));
  };

  const handleSetEditing = (id, value) => {
    dispatch(onEditAction(id, value));
  };

  const handleSetApplied = (id, value) => {
    const config = filterDefinitions.get(id);
    const options = config.options(token);
    const needToFetchOptions = is(Promise, options);

    dispatch(
      onApplyAction(
        config.id,
        config.name,
        config.defaultValue(),
        !needToFetchOptions ? options : undefined,
        value,
      ),
    );
    if (needToFetchOptions && value === true) {
      dispatch(onFetchOptionsAction(id, options));
    }
  };

  const appFilters = mapToValuesArray(
    appliedFilters.map((filter, id) => {
      const {
        value,
        options,
        isEditing,
        isFetching: isFetchingFilter,
      } = filter;

      const config = filterDefinitions.get(id);

      const props = {
        isEditing,
        isFetching,
        title: config.title,
        summary: config.summaryFormatter(value),
        onRemove: () => handleSetApplied(id, false),
        onSetEditing: (editing) => handleSetEditing(id, editing),
        component: () =>
          config.component({
            dispatch,
            token,
            value,
            options,
            isFetching: isFetchingFilter,
            onChange: (val) => handleOnChange(id, val),
          }),
      };
      return {
        isApplied: true,
        props,
        key: id,
      };
    }),
  );

  const allFilters = mapToValuesArray(filters)
    .filter(
      (filter) => filterDefinitions.get(filter.id).targetType === filterType,
    )
    .filter((filter) =>
      showWorkflowSpecificFilters
        ? true
        : filterDefinitions.get(filter.id).workflowSpecific ===
          showWorkflowSpecificFilters,
    )
    .filter((filter) => !appliedFilters.has(filter.id))
    .map((filter) => {
      const config = filterDefinitions.get(filter.id);
      return {
        title: config.title,
        key: filter.id,
        schema: filter,
      };
    });

  return (
    <FilterSideBar
      isFetching={isFetching}
      onApply={handleSetApplied}
      filters={allFilters}
      appliedFilters={appFilters}
    />
  );
};

FilterSideBarContainer.propTypes = {
  filters: PropTypes.object.isRequired,
  componentConfig: PropTypes.object.isRequired,
};

export default FilterSideBarContainer;
