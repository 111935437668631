import { Map, Set } from 'immutable';
import { isNil } from 'ramda';
import { OptimisticUpdateRecord } from 'app/common/types';
import { deletePropsFromOptimisticUpdate } from 'app/common/utilities/reducers';
import {
  INTERACTION_ADD,
  INTERACTION_SAVE,
  INTERACTION_SAVE_FAIL,
  INTERACTION_SAVE_SUCCESS,
} from '../constants';

function cleanseInteraction(interaction) {
  return new Map({
    responses: new Set(interaction.responses),
    status: interaction.status,
    user: interaction.user,
  });
}

function reducer(state = new Map(), action) {
  switch (action.type) {
    case INTERACTION_ADD: {
      const { optimisticId, caseIds, user } = action.payload;
      const interaction = new Map({
        cases: new Set(caseIds),
        user,
      });
      return state.merge({
        [optimisticId]: new OptimisticUpdateRecord({
          optimisticId,
          updates: interaction,
        }),
      });
    }
    case INTERACTION_SAVE: {
      const { optimisticId, interaction } = action.payload;
      const id = interaction.id ?? optimisticId;
      const optimisticInteraction = state.get(id)?.updates ?? new Map();

      return state.merge({
        [id]: new OptimisticUpdateRecord({
          optimisticId,
          updates: optimisticInteraction.merge(cleanseInteraction(interaction)),
        }),
      });
    }
    case INTERACTION_SAVE_SUCCESS: {
      const {
        optimisticId,
        data: { result },
      } = action.payload;

      let id = null;
      if (state.has(result)) {
        id = result;
      } else if (state.has(optimisticId)) {
        id = optimisticId;
      }

      const existingData = state.get(id);

      if (!isNil(existingData) && existingData.optimisticId === optimisticId) {
        return state.delete(id);
      }
      return state;
    }
    case INTERACTION_SAVE_FAIL: {
      return deletePropsFromOptimisticUpdate(state, action.payload.id, [
        'status',
      ]);
    }
    default:
      return state;
  }
}

export default reducer;
