import classnames from 'classnames';
import React from 'react';
import styles from './ButtonIcon.css';

const ButtonIcon = ({ children, dense }) => (
  <div
    className={classnames({
      [styles.button]: true,
      [styles.dense]: dense,
    })}
  >
    {children}
  </div>
);

ButtonIcon.defaultProps = {
  dense: false,
};

export default ButtonIcon;
