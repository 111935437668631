import { Record, Set, Map, OrderedSet, OrderedMap } from 'immutable';
import { defaultDateRange } from '../bestpractices/constants';
import { SortingRecord } from '../common/types';

export const TemplatesRecord = Record({ isSaving: false });

export const QueryRecord = Record({
  isFetching: false,
  continuationToken: null,
  error: null,
});

export const QuickSaveRecord = Record({ isSaving: false, error: null });

export const DetailRecord = Record({
  isFetching: false,
  error: null,
  isSaving: false,
});

export const StatusesRecord = Record({ isFetching: false, error: null });

export const CommentsRecord = Record({
  erroredComments: Set(),
  addingComments: Set(),
  deletingComments: Set(),
  editingComments: Set(),
});

export const PatientClaimsHistoryRecord = Record({
  isFetching: false,
  error: null,
});

export const PatientDrugHistoryRecord = Record({
  isFetching: false,
  error: null,
});

export const TemplateDeleteRecord = Record({
  isDeleting: false,
  error: null,
});

export const SideBarRecord = Record({
  isMinimized: true,
  filters: OrderedMap(),
});

export const WorkItemListRecord = Record({
  quickSave: new QuickSaveRecord(),
  query: new QueryRecord(),
  filterSidebar: new SideBarRecord(),
  templates: new TemplatesRecord(),
  templateDelete: new TemplateDeleteRecord(),
  scrollTop: 0,
});

export const WorkItemDetailRecord = Record({
  detail: new DetailRecord(),
  statuses: new StatusesRecord(),
  comments: new CommentsRecord(),
  patientClaimsHistory: new PatientClaimsHistoryRecord(),
  patientDrugHistory: new PatientDrugHistoryRecord(),
});

export const SnackbarRecord = Record({ isOpen: false, message: '' });

export const ViewTemplateRecord = Record({ isFetching: true, error: null });

export const BestPracticeCategoryUiRecord = Record({
  isFetching: false,
  error: null,
  selectedDate: defaultDateRange,
});

export const BestPracticeOverviewUiRecord = Record({
  isFetching: false,
  error: null,
});

export const NotificationsRecord = Record({ isPanelOpen: false });

export const PatientProfileRecord = Record({
  hasFetchedDrugOverview: null,
  hasFetchedClaimViewer: null,
  hasFetchedPatient: null,
  isFetchingDrugOverview: false,
  isFetchingClaimViewer: false,
  isFetchingPatient: false,
  fetchDrugOverviewError: null,
  fetchPatientError: null,
  fetchClaimViewerError: null,
});

export const FetchRecord = Record({
  isRunning: Set(),
  hasCompleted: Set(),
  error: Map(),
});

export const DeleteCampaignRecord = Record({
  isDeleting: null,
  error: null,
});

export const CampaignListRecord = Record({
  error: null,
  campaignDelete: new DeleteCampaignRecord(),
});

export const CaseListRecord = Record({
  continuationToken: null,
  error: null,
  queryResults: OrderedSet(),
  sorting: new SortingRecord(),
});

export const IssueListRecord = Record({
  continuationToken: null,
  error: null,
  queryResults: OrderedSet(),
  sorting: new SortingRecord(),
});

export class CampaignEditRecord extends Record({
  filters: Map(),
}) {}

export const UiStateRecord = Record({
  fetch: new FetchRecord(),
  bestPracticeCategory: new BestPracticeCategoryUiRecord(),
  bestPracticesOverview: new BestPracticeOverviewUiRecord(),
  campaignList: new CampaignListRecord(),
  campaignEdit: new CampaignEditRecord(),
  notifications: new NotificationsRecord(),
  patientProfile: new PatientProfileRecord(),
  sidebar: new SideBarRecord(),
  snackbar: new SnackbarRecord(),
  workItemDetail: new WorkItemDetailRecord(),
  workItemList: new WorkItemListRecord(),
  viewTemplates: Map(),
  caseList: new CaseListRecord(),
  issueList: new IssueListRecord(),
  optimistic: Map(),
});

export class SetEditing extends Record({
  id: '',
  isEditing: false,
}) {}

export class SetApplied extends Record({
  name: '',
  id: '',
  defaultValue: undefined as any,
  options: undefined as any,
  isApplied: true,
}) {}

export class SetValue extends Record({
  id: '',
  value: '' as any,
}) {}

export class ComponentRecord extends Record({
  name: '',
  value: null as any,
  defaultValue: null as any,
  isEditing: false,
  options: undefined as any,
  isFetching: false,
}) {}

export class FilterFetchStart extends Record({
  id: '',
}) {}
