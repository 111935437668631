import { Link } from '@reach/router';
import { format } from 'date-fns';
import React from 'react';

const formatDate = (date) => format(date, 'MM/dd/yyyy');

const columnDefinitions = [
  {
    id: 'ClaimNumber',
    name: 'Claim Number',
    render: (claim) => (
      <Link to={`/workitems/${claim.workItem}`}>{claim.claimNumber}</Link>
    ),
    width: 1,
  },
  {
    id: 'ClaimStatus',
    name: 'Status',
    render: (claim) => claim.claimStatus,
    width: 1,
  },
  {
    id: 'NDC',
    name: 'NDC',
    render: (claim) => claim.ndc,
    width: 1,
  },
  {
    id: 'RxNumber',
    name: 'RX Number',
    render: (claim) => claim.rxNumber,
    width: 1,
  },
  {
    id: 'PreviousQuantity',
    name: 'Previous Quantity',
    render: (claim) => claim.quantityPreviouslyFilledInLimitWindow,
    width: 1,
  },
  {
    id: 'Quantity',
    name: 'Quantity',
    render: (claim) => claim.quantityDispensed,
    width: 1,
  },
  {
    id: 'Days Supply',
    name: 'Days Supply',
    render: (claim) => claim.daysSupply,
    width: 1,
  },
  {
    id: 'FilledDate',
    name: 'Filled Date',
    render: (claim) => formatDate(claim.filledDate),
    width: 1,
  },
  {
    id: 'AdjudicatedDate',
    name: 'Adjudication Date',
    render: (claim) => formatDate(claim.adjudicatedDate),
    width: 1,
  },
];

export default columnDefinitions;
