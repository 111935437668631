import {
  INTERACTION_ADD,
  INTERACTION_FIELD_DEFINTION_FETCH_FAIL,
  INTERACTION_FIELD_DEFINTION_FETCH_START,
  INTERACTION_FIELD_DEFINTION_FETCH_SUCCESS,
  INTERACTION_SAVE,
  INTERACTION_SAVE_FAIL,
  INTERACTION_SAVE_SUCCESS,
} from './constants';

export function saveInteraction(optimisticId, id, interaction) {
  return {
    type: INTERACTION_SAVE,
    payload: { optimisticId, id, interaction },
  };
}

export function saveInteractionSuccess(optimisticId, id, data) {
  return {
    type: INTERACTION_SAVE_SUCCESS,
    payload: { optimisticId, id, data },
  };
}

export function saveInteractionFail(optimisticId, id, error) {
  return { type: INTERACTION_SAVE_FAIL, payload: { optimisticId, id, error } };
}

export function addInteraction(optimisticId, caseIds, userId) {
  return {
    type: INTERACTION_ADD,
    payload: { optimisticId, caseIds, user: userId },
  };
}

export function fetchInteractionFieldDefinitions(contact, activeCase) {
  return {
    type: INTERACTION_FIELD_DEFINTION_FETCH_START,
    payload: { contact, activeCase },
  };
}

export function fetchInteractionFieldDefinitionsSuccess(
  contact,
  fieldDefinitions,
) {
  return {
    type: INTERACTION_FIELD_DEFINTION_FETCH_SUCCESS,
    payload: { contact, fieldDefinitions },
  };
}

export function fetchInteractionFieldDefinitionsFail(error) {
  return { type: INTERACTION_FIELD_DEFINTION_FETCH_FAIL, payload: { error } };
}
