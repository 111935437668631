import { targetFragment } from 'app/issues/queries';
import graphql from '../common/utilities/graphql';
import { interactionResponseFragmentFactory } from '../interactions/queries';
import { claimFragmentFactory } from '../workitems/queries';

const caseFragment = graphql`
  ${interactionResponseFragmentFactory('responses')}
  ${targetFragment}
  ${claimFragmentFactory('claimFragment')}

  fragment caseFields on CaseType {
    id
    number
    status
    dateOpened
    dateClosed
    followUpDate
    campaign {
      id
      entityId
      description
      name
      contact
      target
      caseWorkflowDefinitionId
      interactionFieldDefinitions: interactionFields {
        name
        type
        options
      }
      includeCasesInQueue
      systemManagedCases
    }
    assignee {
      id
      name
      email
      pictureUrl
    }
    contact {
      ...targetFragment
    }
    target {
      ...targetFragment
    }
    issues {
      id
      createdDate
      supportingDocuments {
        ... on ClaimType {
          ...claimFragment
          workItem {
            id
          }
        }
        ... on PatientType {
          ...patientFragment
        }
        __typename
      }
    }
    caseSessions {
      id
      primaryCase {
        id
      }
      status
      sessionStart
      user {
        id
      }
      interactions {
        id
        status
        dateContacted
        cases {
          id
          number
          dateOpened
          followUpDate
          campaign {
            id
            entityId
            name
            contact
            target
            caseWorkflowDefinitionId
            interactionFieldDefinitions: interactionFields {
              name
              type
              options
            }
          }
          status
          contact {
            ...targetFragment
          }
          target {
            ...targetFragment
          }
        }
        suggestedCases {
          id
          number
          dateOpened
          campaign {
            id
            entityId
            name
            contact
            target
            caseWorkflowDefinitionId
            interactionFieldDefinitions: interactionFields {
              name
              type
              options
            }
          }
          status
          contact {
            ...targetFragment
          }
          target {
            ...targetFragment
          }
        }
        responses {
          ...responses
        }
      }
    }
    interactions {
      id
      dateContacted
      status
      user {
        id
        name
        email
        pictureUrl
      }
      contact {
        ...targetFragment
      }
      responses {
        ...responses
      }
    }
  }
`;

export const fetchCaseDetailsQuery = graphql`
  ${caseFragment}
  query case($id: ID!) {
    node(id: $id) {
      ... on CaseType {
        ...caseFields
      }
    }
  }
`;

export const upsertCaseMutation = graphql`
  ${caseFragment}
  mutation ($case: CaseInputType) {
    upsertCase(case: $case) {
      ...caseFields
    }
  }
`;

export const bulkCreateCasesMutation = graphql`
  mutation ($bulkCreateCasesInput: BulkCreateCasesInputType) {
    bulkCreateCases(bulkCreateCasesInput: $bulkCreateCasesInput) {
      cases
    }
  }
`;

export const fetchCasesQuery = graphql`
  query filterCasesQuery(
    $filter: CaseFilterInputType!
    $sortBy: CaseSortByEnum = DateOpened
    $sortOrder: SortOrderEnum = ASC
    $continuationToken: String
  ) {
    cases(
      filter: $filter
      continuationToken: $continuationToken
      take: 50
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      continuationToken
      results {
        id
        number
        status
        dateOpened
        dateClosed
        followUpDate
        assignee {
          id
          name
          email
          pictureUrl
        }
        campaign {
          id
          entityId
          description
          name
        }
        issues {
          id
        }
        interactions {
          id
          dateContacted
          status
          responses {
            ... on InteractionFieldResponseType {
              level
              name
              value
            }
            ... on CaseFieldResponseType {
              level
              name
              value
              caseId
            }
          }
        }
        target {
          __typename
          ... on PatientType {
            id
            patientId
            followUpDate
          }
          ... on PrescriberType {
            id
            npi
          }
          ... on PharmacyType {
            id
            npi
          }
        }
      }
    }
  }
`;
