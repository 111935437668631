import {
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  SvgIcon,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  formatISODuration,
} from 'date-fns';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { curry, isNil, keys, pipe, __, has, toUpper, is } from 'ramda';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SaveButton from 'app/common/components/SaveButton';
import { ErrorRecord, GraphQlErrorRecord } from 'app/common/types';
import { IssueFilterType } from 'app/filters/constants';
import { ContactType } from 'app/interactions/constants';
import { permissions } from '../../../auth/permissions';
import DateRangePicker from '../../../common/components/DateRangePicker';
import { DurationRecord, parseISODuration } from '../../../common/duration';
import Campaign from '../../../common/images/campaign.svg';
import ChipInput from '../../../filters/components/ChipInput/ChipInput';
import { SelectValueRecord } from '../../../filters/types';
import {
  CampaignRecord,
  CampaignStatus,
  Contacts,
  Targets,
  DefaultCampaignStatus,
  MethodsOfCommunication,
  RequiredSkills,
} from '../../types';
import styles from './CampaignEdit.css';
import FilterEditor from './FilterEditor';
import InteractionInputs from './InteractionInputs';
import WorkFlowEditor from './WorkflowEditor';

const addDuration = (date, isoDuration) => {
  const duration = parseISODuration(isoDuration);
  return pipe(
    curry(addYears)(__, duration.years || 0),
    curry(addMonths)(__, duration.months || 0),
    curry(addWeeks)(__, duration.weeks || 0),
    curry(addDays)(__, duration.days || 0),
    curry(addHours)(__, duration.hours || 0),
    curry(addMinutes)(__, duration.minutes || 0),
    curry(addSeconds)(__, duration.seconds || 0),
  )(date);
};

const durationOptions = [
  { text: '1 Week', value: new DurationRecord({ weeks: 1 }) },
  { text: '1 Month', value: new DurationRecord({ months: 1 }) },
  { text: '3 Months', value: new DurationRecord({ months: 3 }) },
  { text: '6 Months', value: new DurationRecord({ months: 6 }) },
  { text: '1 Year', value: new DurationRecord({ years: 1 }) },
  { text: 'Indefinite', value: 'Indefinite' },
];

const userToSelectValue = (user) =>
  new SelectValueRecord({
    text: user.name || user.email,
    value: user.id,
  });

const parseIndefinite = (campaign) => {
  switch (campaign.duration) {
    case 'Indefinite':
      return true;
    case null:
      return null;
    default:
      return false;
  }
};

let hasFilterError = false;

const CampaignDetails = ({
  campaign,
  campaignOwners,
  campaignAssignees,
  userPermissions,
  workflowDefinitions,
  isSaving,
  isEditing,
  errors,
  onSave,
  onCancel,
}) => {
  const { handleSubmit, control, watch, setValue, formState } = useForm();

  const { errors: fieldErrors } = formState;

  useEffect(() => {
    return () => {
      hasFilterError = false;
    };
  }, []);

  // Read the formState before render to subscribe the form state through Proxy
  // This is needed so first edits trigger dirty flags in callbacks
  const { dirtyFields } = formState;

  const onSubmit = (data) => {
    if (data.issueFilter.filters.size > 0) {
      hasFilterError = false;

      const update = {
        entityId: campaign.entityId,
        etag: campaign.etag,
      };

      const formattedData = {
        ...data,
        issueFilter: {
          filters: data.issueFilter.filters,
          filterType: data.issueFilter.issueFilterType,
        },
        prefix: toUpper(data.prefix), // Would be nice to do this formatting at the field display level
        requiredSpecialists: data.requiredSpecialists.map((x) => x.value),
        assignees: data.assignees.map((x) => x.value),
        duration:
          isNil(data.duration) || data.duration === 'Indefinite'
            ? null
            : data.duration,
        runsIndefinitely: parseIndefinite(data),
      };

      const fieldsToSubmit = keys(formState.dirtyFields);

      // If we are creating a new campaign, we want to make sure the
      // default interaction fields get passed along despite the user not touching the field
      if (!isEditing) {
        fieldsToSubmit.push('interactionFieldDefinitions');
      }

      fieldsToSubmit.forEach((x) => {
        update[x] = formattedData[x];
      });

      // can we add an hidden input type instead for this?
      if (has('duration')(update)) {
        update['runsIndefinitely'] = formattedData.runsIndefinitely;
      }

      onSave(update);
    } else {
      hasFilterError = true;
    }
  };

  const [startDate, duration, endDate, issueFilter] = watch([
    'startDate',
    'duration',
    'endDate',
    'issueFilter',
  ]);

  const systemManagedCases = watch('systemManagedCases', false);

  if (
    !isNil(issueFilter) &&
    issueFilter.issueFilterType === IssueFilterType.Patient &&
    systemManagedCases !== true
  ) {
    setValue('includeCasesInQueue', false, {
      shouldDirty: true,
    });
  }

  if (
    !isNil(startDate) &&
    !isNil(duration) &&
    duration !== 'Indefinite' &&
    isNil(endDate) &&
    userPermissions.includes(permissions.CAMPAIGN_ENDDATE_UPSERT)
  ) {
    setValue('endDate', addDuration(startDate, duration), {
      shouldDirty: true,
    });
  }

  // TODO we will want to continue to refine how we distribute server side field errors
  let formError = false;
  errors.forEach((error) => {
    if (is(GraphQlErrorRecord, error)) {
      if (error.path.count() > 1) {
        const field = error.path.get(2);

        // TODO the server side errors do not get cleared out on the form when user changes value
        // Checking for dirty flag however does not clear after a failed attempt
        if (!isNil(field) && isNil(fieldErrors[field])) {
          fieldErrors[field] = { type: 'server', message: error.message };
        }
      } else {
        formError = true;
      }
    }
    if (is(ErrorRecord, error)) {
      formError = true;
    }
  });

  const isAddToQueueDisabled =
    systemManagedCases !== true &&
    !isNil(issueFilter) &&
    issueFilter.issueFilterType === IssueFilterType.Patient;

  return (
    <div className={styles.container}>
      <Paper className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formRow}>
            <Paper elevation={2} className={styles.headerIcon}>
              <SvgIcon fontSize="large">
                <Campaign />
              </SvgIcon>
            </Paper>
            <div className={styles.headerText}>Create Campaign</div>
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  classes={{ root: styles.input }}
                  label="Name"
                  helperText={
                    fieldErrors.name ? (
                      <>
                        {fieldErrors.name.type === 'required' && (
                          <div>Name is required</div>
                        )}
                        {fieldErrors.name.type === 'server' && (
                          <div>{fieldErrors.name.message}</div>
                        )}
                      </>
                    ) : null
                  }
                  error={fieldErrors.name}
                />
              )}
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue={campaign.name}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Status"
                  disabled={
                    !userPermissions.includes(
                      permissions.CAMPAIGN_STATUS_UPSERT,
                    )
                  }
                  variant="outlined"
                  classes={{ root: styles.input }}
                  select
                >
                  {CampaignStatus.map((x) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="status"
              control={control}
              defaultValue={campaign.status || DefaultCampaignStatus}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  label="Description"
                  variant="outlined"
                  rows={4}
                  classes={{ root: styles.input }}
                />
              )}
              name="description"
              control={control}
              defaultValue={campaign.description}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  classes={{ root: styles.input }}
                  label="Prefix"
                  placeholder="A 3 character shorthand name. ex: ABC"
                  helperText={
                    fieldErrors.prefix ? (
                      <>
                        {fieldErrors.prefix.type === 'required' && (
                          <div>Prefix is required</div>
                        )}
                        {(fieldErrors.prefix.type === 'minLength' ||
                          fieldErrors.prefix.type === 'maxLength') && (
                          <div>Prefix must be 3 characters long</div>
                        )}
                        {fieldErrors.prefix.type === 'server' && (
                          <div>{fieldErrors.prefix.message}</div>
                        )}
                      </>
                    ) : null
                  }
                  error={fieldErrors.prefix}
                />
              )}
              name="prefix"
              control={control}
              rules={{
                required: true,
                minLength: 3,
                maxLength: 3,
              }}
              defaultValue={campaign.prefix}
            />
          </div>
          <div className={styles.formRow}>
            <FormControlLabel
              control={
                <Controller
                  render={({ field }) => (
                    <Switch {...field} checked={field.value} color="primary" />
                  )}
                  name="systemManagedCases"
                  control={control}
                  defaultValue={campaign.systemManagedCases}
                />
              }
              label="Automatically Create Cases"
              className={styles.outlined}
            />
            <FormControlLabel
              control={
                <Controller
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      color="primary"
                      disabled={isAddToQueueDisabled}
                    />
                  )}
                  name="includeCasesInQueue"
                  control={control}
                  defaultValue={campaign.includeCasesInQueue}
                />
              }
              label="Include Cases in Queue"
              className={styles.outlined}
              disabled={isAddToQueueDisabled}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Owner"
                  variant="outlined"
                  classes={{ root: styles.input }}
                >
                  {campaignOwners.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="owner"
              defaultValue={campaign.owner?.id}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Contact"
                  variant="outlined"
                  helperText={
                    fieldErrors.contact
                      ? fieldErrors.contact.type === 'required' && (
                          <div>Contact is required</div>
                        )
                      : null
                  }
                  error={fieldErrors.contact}
                  classes={{ root: styles.input }}
                >
                  {Contacts.map((x) => (
                    <MenuItem
                      key={x.value}
                      value={x.value}
                      disabled={
                        x.value !== ContactType.Patient &&
                        campaign.issueFilter.issueFilterType ===
                          IssueFilterType.Patient
                      }
                    >
                      {x.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="contact"
              control={control}
              defaultValue={campaign.contact}
              rules={{
                required: true,
              }}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Target"
                  variant="outlined"
                  disabled={!isNil(campaign.target)}
                  helperText={
                    fieldErrors.target
                      ? fieldErrors.target.type === 'required' && (
                          <div>Target is required</div>
                        )
                      : null
                  }
                  error={fieldErrors.target}
                  classes={{ root: styles.input }}
                >
                  {Targets.map((x) => (
                    <MenuItem
                      key={x.value}
                      value={x.value}
                      disabled={
                        x.value !== 'PATIENT' &&
                        campaign.issueFilter.issueFilterType ===
                          IssueFilterType.Patient
                      }
                    >
                      {x.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="target"
              control={control}
              defaultValue={campaign.target}
              rules={{
                required: true,
              }}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Duration"
                  variant="outlined"
                  classes={{ root: styles.input }}
                >
                  {durationOptions.map((x) => (
                    <MenuItem
                      key={x.text}
                      value={
                        x.value !== 'Indefinite' && !isNil(x.value)
                          ? formatISODuration(x.value)
                          : x.value
                      }
                    >
                      {x.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="duration"
              defaultValue={
                campaign.runsIndefinitely === true
                  ? 'Indefinite'
                  : isNil(campaign.Duration)
                  ? null
                  : formatISODuration(campaign.duration)
              }
              control={control}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Method of Communication"
                  variant="outlined"
                  helperText={
                    fieldErrors.contactMethod
                      ? fieldErrors.contactMethod.type === 'required' && (
                          <div>Method of Communication is required</div>
                        )
                      : null
                  }
                  error={fieldErrors.contactMethod}
                  classes={{ root: styles.input }}
                >
                  {MethodsOfCommunication.map((x) => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="contactMethod"
              control={control}
              defaultValue={campaign.contactMethod}
              rules={{
                required: true,
              }}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              key="startDate"
              render={({ field }) => (
                <DateRangePicker
                  {...field}
                  label="Start Date"
                  variant="outlined"
                  disabled={
                    !userPermissions.includes(
                      permissions.CAMPAIGN_STARTDATE_UPSERT,
                    )
                  }
                  maxSelectableDate={addYears(new Date(), 2)}
                  classes={{ root: styles.input }}
                  helperText={
                    fieldErrors.startDate
                      ? fieldErrors.startDate.type === 'required' && (
                          <div>Start Date is required</div>
                        )
                      : null
                  }
                  error={fieldErrors.startDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              )}
              defaultValue={campaign.startDate}
              name="startDate"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <DateRangePicker
                  {...field}
                  label="End Date"
                  variant="outlined"
                  disabled={
                    !userPermissions.includes(
                      permissions.CAMPAIGN_ENDDATE_UPSERT,
                    )
                  }
                  minSelectableDate={
                    !isNil(startDate) ? startDate : new Date(2017, 0, 1)
                  }
                  maxSelectableDate={addYears(new Date(), 2)}
                  classes={{ root: styles.input }}
                  error={fieldErrors.endDate}
                  helperText={fieldErrors.endDate?.message}
                />
              )}
              defaultValue={campaign.endDate}
              name="endDate"
              control={control}
              rules={{
                validate: (val) => {
                  if (startDate && val && startDate > val)
                    return 'End Date must occur after start date';
                  return true;
                },
              }}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <ChipInput
                  {...field}
                  showOptionsOnFocus
                  margin="none"
                  optionsListOnly
                  label="Requires Skills"
                  disabled
                  variant="outlined"
                  classes={{ root: styles.input }}
                  options={RequiredSkills}
                />
              )}
              name="requiredSpecialists"
              defaultValue={RequiredSkills.filter((x) =>
                campaign.requiredSpecialists.includes(x.value),
              )}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <ChipInput
                  {...field}
                  optionsListOnly
                  margin="none"
                  disabled={
                    !userPermissions.includes(
                      permissions.CAMPAIGN_ASSIGNEES_UPSERT,
                    )
                  }
                  label="Assignees"
                  variant="outlined"
                  classes={{ root: styles.input }}
                  options={campaignAssignees.map(userToSelectValue)}
                />
              )}
              name="assignees"
              defaultValue={campaign.assignees.map(userToSelectValue)}
              control={control}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              name="interactionFieldDefinitions"
              control={control}
              render={({ field }) => (
                <InteractionInputs
                  {...field}
                  value={campaign.interactionFieldDefinitions.filter(
                    (x) =>
                      x.name !== 'Close Case' &&
                      x.name !== 'Case Follow Up Date',
                  )}
                  label="Interaction Fields"
                  classes={{ root: styles.input }}
                  isEditing={isEditing}
                />
              )}
              defaultValue={campaign.interactionFieldDefinitions}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              name="caseWorkflowDefinitionId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Workflow"
                  variant="outlined"
                  helperText={
                    fieldErrors.caseWorkflowDefinitionId
                      ? fieldErrors.caseWorkflowDefinitionId.type ===
                          'required' && <div>Workflow is required</div>
                      : null
                  }
                  error={fieldErrors.caseWorkflowDefinitionId}
                  classes={{ root: styles.input }}
                >
                  {workflowDefinitions.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              defaultValue={campaign.caseWorkflowDefinitionId}
              rules={{
                required: true,
              }}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              name="issueFilter"
              control={control}
              render={({ field }) => (
                <FilterEditor
                  {...field}
                  label="Filters"
                  classes={{ root: styles.input }}
                  isEditing={isEditing}
                />
              )}
              defaultValue={campaign.issueFilter}
            />
          </div>
          {formError && (
            <div className={styles.formErrorText}>
              Something went wrong, please try again or contact us.
            </div>
          )}
          {hasFilterError && (
            <div className={styles.formErrorText}>
              A campaign requires filters. Please add at least 1 filter and
              submit again.
            </div>
          )}
          <div className={styles.submitRow}>
            <Button disabled={isSaving} onClick={onCancel}>
              Cancel
            </Button>
            <SaveButton type="submit" isSaving={isSaving}>
              {!isEditing && 'Create'}
              {isEditing && 'Save Changes'}
            </SaveButton>
          </div>
        </form>
      </Paper>
    </div>
  );
};

CampaignDetails.propTypes = {
  campaignOwners: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  campaignAssignees: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  workflowDefinitions: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  campaign: ImmutablePropTypes.record,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  userPermissions: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
};

CampaignDetails.defaultProps = {
  errors: [],
  campaign: new CampaignRecord(),
  campaignOwners: new Set(),
  campaignAssignees: new Set(),
  workflowDefinitions: new Set(),
};

export default CampaignDetails;
