import { CircularProgress } from '@material-ui/core';
import { ContactsOutlined } from '@material-ui/icons';
import classnames from 'classnames';
import {
  format,
  subDays,
  startOfDay,
  closestTo,
  isEqual,
  addDays,
  isWithinInterval,
} from 'date-fns';
import {
  reduce,
  mean,
  pipe,
  map,
  props,
  flatten,
  min,
  max,
  range,
  isNil,
} from 'ramda';
import React, { forwardRef, useEffect, useState } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import {
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLine,
  VictoryLegend,
  Style,
  VictoryArea,
  VictoryScatter,
  VictoryLabel,
  createContainer,
  VictoryTooltip,
  LineSegment,
} from 'victory';
import Message from '../../../../common/components/Message/Message';
import Campaign from '../../../../common/images/campaign.svg';
import styles from './Workload.css';
import { dataSelector, campaigns } from './realisticData';

const ZoomCursorContainer = createContainer('zoom', 'cursor');
const Workload = ({ style, width, height, selectedCampaigns }) => {
  const today = startOfDay(new Date());
  const data = dataSelector(selectedCampaigns);

  const getYRange = (properties) => [
    pipe(
      map(props(properties)),
      flatten,
      reduce(min, Infinity),
      (x) => Math.floor(x / 100) * 100,
    )(data),
    pipe(
      map(props(properties)),
      flatten,
      reduce(max, -Infinity),
      (x) => Math.ceil(x / 100) * 100,
    )(data),
  ];

  const domain = {
    x: [subDays(today, 30), addDays(today, 30)],
    y: getYRange(['casesOpened', 'casesClosed']),
  };
  const casesOutstandingRange = getYRange(['casesOutstanding']);

  const [zoomedDomain, setDomain] = useState(domain);
  const [drawCursor, setDrawCursor] = useState(true);

  useEffect(() => {
    const yDomain = getYRange(['casesOpened', 'casesClosed']);
    setDomain({ x: zoomedDomain.x, y: yDomain });
  }, [selectedCampaigns]);

  const dateRange = data.map((x) => x.date);

  const colorPalette = ['#2196f3', '#80c644', '#f4ac00'];

  const priorDataFilter = (x) => x.date <= today;
  const futureDataFilter = (x) => x.date >= today;

  const normalizeBetweenTwoRanges = (val, currentRange, newRange) => {
    return (
      newRange[0] +
      ((val - currentRange[0]) * (newRange[1] - newRange[0])) /
        (currentRange[1] - currentRange[0])
    );
  };

  const scaledData = data.map((x, i, array) => {
    const endIndex = i + 1;
    const lastWeek = range(i < 7 ? 0 : endIndex - 7, endIndex);
    return {
      date: x.date,
      casesOpened: mean(lastWeek.map((i) => array[i].casesOpened)),
      casesClosed: mean(lastWeek.map((i) => array[i].casesClosed)),
      casesOutstanding: normalizeBetweenTwoRanges(
        mean(lastWeek.map((i) => array[i].casesOutstanding)),
        casesOutstandingRange,
        zoomedDomain.y,
      ),
    };
  });

  const events = flatten(
    campaigns
      .filter(
        (x) =>
          selectedCampaigns.length === 0 || selectedCampaigns.includes(x.id),
      )
      .map((x) => [
        {
          x: x.started,
          y: zoomedDomain.y[0],
          status: 'ADDED',
          name: x.name,
        },
        {
          x: x.ended,
          y: zoomedDomain.y[0],
          status: 'REMOVED',
          name: x.name,
        },
      ]),
  );

  const handleZoomChange = (newDomain) => {
    setDomain({ x: newDomain.x, y: zoomedDomain.y });
  };

  const Point = ({ x, y, events, datum }) => {
    return (
      <svg
        {...events}
        x={x}
        y={y - 25}
        className={classnames({
          [styles.campaignAdded]: datum.status === 'ADDED',
          [styles.campaignRemoved]: datum.status === 'REMOVED',
        })}
      >
        <Campaign />
      </svg>
    );
  };

  // I hate that I have to do this, but with the zoom, the cursor would extend past the
  // domain of the graph.  I am not checking if the x value of the cursor is outside
  // the bounds of the domain and if it is, setting the draw cursor variable so
  // we can use that in our custom components to render/not render the cursor line and tooltip.
  const onCursorChange = (value) => {
    if (isNil(value)) {
      return;
    }

    const shouldDrawCursor = isWithinInterval(value, {
      start: zoomedDomain.x[0],
      end: zoomedDomain.x[1],
    });

    if (shouldDrawCursor != drawCursor) {
      setDrawCursor(shouldDrawCursor);
    }
  };

  const CustomLabel = (props) => {
    const nearestDate = closestTo(props.datum.x, dateRange);
    const values = data.find((x) => isEqual(x.date, nearestDate));

    return drawCursor ? (
      <VictoryTooltip
        {...props}
        constrainToVisibleArea
        flyoutStyle={{ fill: 'white' }}
        pointerLength={0}
        y={150}
        x={props.x}
        text={`${format(values.date, 'MM/dd/yyyy')}

            Outstanding: ${values.casesOutstanding}
            Opened: ${values.casesOpened}
            Closed: ${values.casesClosed}`}
      />
    ) : (
      <svg />
    );
  };

  const Cursor = (props) => {
    return drawCursor ? (
      <LineSegment
        {...props}
        style={{
          stroke: '#878787',
          strokeWidth: 2,
          strokeDasharray: '4',
        }}
      />
    ) : (
      <svg />
    );
  };
  const isLoading = isNil(width) || isNil(height);
  return (
    <div style={{ height: '100%', width: '100%', ...style }}>
      {isLoading && (
        <Message
          icon={<CircularProgress style={{ marginBottom: '.5rem' }} />}
          message="Getting case statistics..."
        />
      )}
      {!isLoading && (
        <VictoryChart
          theme={VictoryTheme.material}
          width={width - 50}
          height={height}
          scale={{ x: 'time', y: 'linear' }}
          containerComponent={
            <ZoomCursorContainer
              zoomDomain={zoomedDomain}
              zoomDimension="x"
              cursorComponent={<Cursor />}
              cursorLabel={({ datum }) => ``}
              cursorDimension="x"
              cursorLabelComponent={<CustomLabel />}
              onZoomDomainChange={handleZoomChange}
              onCursorChange={onCursorChange}
            />
          }
        >
          <VictoryAxis dependentAxis={false} axisComponent={<div />} />
          <VictoryAxis
            domain={zoomedDomain.y}
            dependentAxis
            orientation="left"
            label="Cases Opened/Closed"
            style={{
              axisLabel: {
                padding: 50,
              },
            }}
          />
          <VictoryAxis
            offsetX={50}
            dependentAxis
            orientation="right"
            label="Cases Outstanding"
            style={{
              axisLabel: {
                padding: 50,
              },
            }}
            tickFormat={(t) =>
              Math.round(
                normalizeBetweenTwoRanges(
                  t,
                  zoomedDomain.y,
                  casesOutstandingRange,
                ),
              )
            }
          />

          <VictoryArea
            data={scaledData.filter((x) => x.date <= today)}
            x="date"
            y="casesOutstanding"
            style={{
              data: {
                fill: colorPalette[2],
                fillOpacity: 0.4,
                strokeWidth: 2,
              },
            }}
          />

          <VictoryArea
            data={scaledData.filter((x) => x.date >= today)}
            x="date"
            y="casesOutstanding"
            style={{
              data: {
                fill: colorPalette[2],
                fillOpacity: 0.2,
                strokeWidth: 2,
                strokeDasharray: '5',
              },
            }}
          />

          <VictoryLine
            data={scaledData.filter(priorDataFilter)}
            x="date"
            y="casesOpened"
            interpolation="natural"
            style={{
              data: {
                stroke: () => colorPalette[0],
              },
            }}
          />
          <VictoryLine
            data={scaledData.filter(futureDataFilter)}
            x="date"
            y="casesOpened"
            interpolation="natural"
            style={{
              data: {
                stroke: () => colorPalette[0],
                strokeDasharray: '5',
              },
            }}
          />

          <VictoryLine
            data={scaledData.filter(priorDataFilter)}
            x="date"
            y="casesClosed"
            interpolation="natural"
            style={{
              data: {
                stroke: () => colorPalette[1],
              },
            }}
          />
          <VictoryLine
            data={scaledData.filter(futureDataFilter)}
            x="date"
            y="casesClosed"
            interpolation="natural"
            style={{
              data: {
                stroke: () => colorPalette[1],
                strokeDasharray: () => '5',
              },
            }}
          />

          <VictoryLine
            name="Today-Line"
            labels={['Today']}
            labelComponent={<VictoryLabel dx={-25} y={70} />}
            x={() => today}
          />

          <VictoryScatter
            data={events}
            dataComponent={<Point />}
            labels={({ datum }) => {
              switch (datum.status) {
                case 'ADDED': {
                  return `${datum.name} Started on ${format(datum.x, 'MM/dd')}`;
                }
                case 'REMOVED': {
                  return `${datum.name} Ended on ${format(datum.x, 'MM/dd')}`;
                }
              }
            }}
            labelComponent={
              <VictoryTooltip
                pointerLength={0}
                flyoutStyle={{ fill: 'white' }}
              />
            }
          />

          <VictoryLegend
            data={[
              { name: 'Cases Opened', symbol: { fill: colorPalette[0] } },
              { name: 'Cases Closed', symbol: { fill: colorPalette[1] } },
              { name: 'Cases Outstanding', symbol: { fill: colorPalette[2] } },
            ]}
            orientation="horizontal"
            centerTitle
            x={width / 2 - 150}
            y={10}
          />
        </VictoryChart>
      )}
    </div>
  );
};

const ResponsiveChart = withResizeDetector(Workload);
export default ResponsiveChart;
