import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import MultiSelect from 'app/filters/components/MultiSelect';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import { tiersQuery } from 'app/filters/queries';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(tiersQuery, { id: 'v1:Tiers:Reference' }, token)
    .then(throwIfErrors)
    .then((response) => response.data.node.tiers)
    .then((tiers: Array<{ key: string; value: string }>) =>
      OrderedSet(
        tiers.map(
          (t) =>
            new SelectValueRecord({
              text: t.key,
              value: t.value,
            }),
        ),
      ),
    );

class FormularyTierConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'formularyTiers',
    title: 'Formulary Tier',
    targetType: IssueFilterType.Claim,
    options: (token: string) => fetchOptions(token),
    component: (props: any) => <MultiSelect {...props} />,
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.formularyTier)) return true;

      return value.contains(workItem.claim.formularyTier);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new FormularyTierConfig();
