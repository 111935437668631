import { buffers } from 'redux-saga';
import { actionChannel, call, cancel, fork, take } from 'redux-saga/effects';
import { delay } from './generic';

export default function* throttle(ms, pattern, task, ...args) {
  const throttleChannel = yield actionChannel(pattern, buffers.sliding(1));
  let currentlyRunningTask = null;

  while (true) {
    const action = yield take(throttleChannel);

    if (currentlyRunningTask != null) {
      // A newer task came through, so cancel the old one that's still running.
      yield cancel(currentlyRunningTask);
    }

    currentlyRunningTask = yield fork(task, ...args, action);
    yield call(delay, ms);
  }
}
