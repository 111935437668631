import { Record, Set } from 'immutable';
import {
  getTextOperatorConfig,
  IssueFilterType,
  TextComparisonOperators,
} from 'app/filters/constants';
import { textComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  TextComparisonBackendRecord,
  TextComparisonRecord,
  TextComparisonOperator,
  IFilterConfiguration,
} from 'app/filters/types';
import TextComparison from '../../components/TextComparison';

class PatientIdConfig
  extends Record({
    ...textComparisonDefaults,
    name: 'patientId',
    title: 'Patient Id',
    targetType: IssueFilterType.Patient,
    defaultValue: () =>
      new TextComparisonRecord({
        text: undefined,
        operator: new TextComparisonOperator({
          text: getTextOperatorConfig(TextComparisonOperators.StartsWith).text,
          value: TextComparisonOperators.StartsWith,
        }),
      }),
    options: () =>
      Set([
        new TextComparisonOperator({
          text: getTextOperatorConfig(TextComparisonOperators.StartsWith).text,
          value: TextComparisonOperators.StartsWith,
        }),
      ]),
    component: (props: any) => (
      <TextComparison
        inputType="text"
        placeholder="Enter patient id"
        {...props}
      />
    ),
  })
  implements
    IFilterConfiguration<
      TextComparisonRecord,
      Set<TextComparisonOperator>,
      TextComparisonBackendRecord,
      TextComparisonBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new PatientIdConfig();
