import { ErrorRecord, GraphQlErrorRecord } from 'app/common/types';
import { SearchType } from 'app/search/types';
import {
  SEARCH_CLEAR_RESULTS,
  SEARCH_FAIL,
  SEARCH_START,
  SEARCH_SUCCESS,
} from './constants';

export function search(searchString: string, searchTypes: SearchType[]) {
  return { type: SEARCH_START, payload: { search: searchString, searchTypes } };
}

export function searchSuccess(data: any) {
  return { type: SEARCH_SUCCESS, payload: { data } };
}

export function searchFail(error: Array<ErrorRecord | GraphQlErrorRecord>) {
  return { type: SEARCH_FAIL, payload: { error } };
}

export function clearSearchResult() {
  return { type: SEARCH_CLEAR_RESULTS, payload: {} };
}
