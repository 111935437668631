import { subMonths } from 'date-fns';
import { omit } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import {
  patientQuery,
  drugOverviewQuery,
  claimViewerQuery,
  opioidViewQuery,
  upsertPatientMutation,
} from './queries';

export function fetchPatient(id, token) {
  return fetchGraphqlQuery(patientQuery, { id }, token).then(throwIfErrors);
}

export function fetchPatientDrugOverview(patientId, token) {
  return fetchGraphqlQuery(
    drugOverviewQuery,
    {
      patientId,
      filledAfter: subMonths(new Date(), 10),
    },
    token,
  ).then(throwIfErrors);
}

export function fetchPatientClaimView(patientId, token) {
  return fetchGraphqlQuery(
    claimViewerQuery,
    {
      patientId,
    },
    token,
  ).then(throwIfErrors);
}

export function fetchPatientOpioidView(patientId, token) {
  return fetchGraphqlQuery(
    opioidViewQuery,
    {
      patientId,
      filledAfter: subMonths(new Date(), 10),
    },
    token,
  ).then(throwIfErrors);
}

export async function upsertPatient(patient, token) {
  const result = await fetchGraphqlQuery(
    upsertPatientMutation,
    {
      patient: {
        id: patient.patientId,
        phoneNumbers: patient.phoneNumbers.map(x => ({
          phoneNumber: x.phoneNumber,
          isDeleted: x.isDeleted,
          isPrimary: x.isPrimary,
        }))
      },
    },
    token,
  );

  return { errors: result.errors, patient: result.data.upsertPatient };
}
