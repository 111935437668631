import {
  USERS_FETCH_START,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAIL,
} from './constants';

export function fetchUsers() {
  return { type: USERS_FETCH_START, payload: {} };
}

export function fetchUsersSuccess(users) {
  return { type: USERS_FETCH_SUCCESS, payload: { users } };
}

export function fetchUsersFail(error) {
  return { type: USERS_FETCH_FAIL, payload: { error } };
}
