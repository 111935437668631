import { Search, AddCircleOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { sortBy, compose, path, filter, prop } from 'ramda';
import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import { Scrollbars } from 'react-custom-scrollbars';
import TextInput from '../../../common/components/TextInput';
import { containsCaseInsensitive } from '../../../common/utilities/generic';
import styles from './FilterFinder.css';

const Loader = () => (
  <ContentLoader
    height={625}
    width={200}
    speed={2}
    backgroundColor={'#e5e5e5'}
    foregroundColor={'#d6d6d6'}
  >
    <circle cx="10" cy="16" r="8" />
    <rect x="25" y="8" rx="5" ry="5" width="142" height="16" />
    <circle cx="10" cy="43" r="8" />
    <rect x="25" y="35" rx="5" ry="5" width="84" height="16" />
    <circle cx="10" cy="70" r="8" />
    <rect x="25" y="62" rx="5" ry="5" width="72" height="16" />
    <circle cx="10" cy="97" r="8" />
    <rect x="25" y="89" rx="5" ry="5" width="160" height="16" />
    <circle cx="10" cy="124" r="8" />
    <rect x="25" y="116" rx="5" ry="5" width="97" height="16" />
    <circle cx="10" cy="151" r="8" />
    <rect x="25" y="143" rx="5" ry="5" width="85" height="16" />
  </ContentLoader>
);

class FilterFinder extends Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filterTerm: '',
    };
  }

  handleSearchValueChange = (value) => {
    this.setState({ filterTerm: value });
  };

  render() {
    const { filters, isFetching, onApply } = this.props;
    const { filterTerm } = this.state;

    let displayedFilters;
    const getFilterTitle = prop('title');
    const sortByTitle = sortBy(getFilterTitle);
    if (filterTerm) {
      const filterByTitle = filter(
        compose(containsCaseInsensitive(filterTerm), getFilterTitle),
      );
      displayedFilters = sortByTitle(filterByTitle(filters));
    } else {
      displayedFilters = sortByTitle(filters);
    }

    const allFiltersApplied =
      displayedFilters.length === 0 && !filterTerm && !isFetching;
    const noResults =
      displayedFilters.length === 0 && filterTerm && !isFetching;

    return (
      <div className={styles.container}>
        <div>
          <TextInput
            disableUnderline
            leftIcon={<Search className={styles.searchIcon} />}
            placeholder="Search"
            onChange={this.handleSearchValueChange}
            disabled={isFetching}
            classes={{ input: styles.input, root: styles.inputContainer }}
          />
        </div>
        <Scrollbars className={styles.filtersContainer}>
          {isFetching && <Loader />}
          {allFiltersApplied && (
            <div className={styles.filtersMessage}>All filters applied</div>
          )}
          {noResults && <div className={styles.filtersMessage}>No results</div>}
          {!isFetching &&
            displayedFilters.length > 0 &&
            displayedFilters.map((filter) => (
              <div
                key={filter.key}
                className={styles.filter}
                onClick={() => onApply(filter.schema.id, true)}
              >
                <AddCircleOutline className={styles.filterAddIcon} />
                <div className={styles.filterName}>{filter.title}</div>
              </div>
            ))}
        </Scrollbars>
      </div>
    );
  }
}

export default FilterFinder;
