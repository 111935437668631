export const INTERACTION_SAVE = 'INTERACTIONS/INTERACTION_SAVE_UPSERT_START';
export const INTERACTION_SAVE_SUCCESS =
  'INTERACTIONS/INTERACTION_SAVE_UPSERT_SUCCESS';
export const INTERACTION_SAVE_FAIL =
  'INTERACTIONS/INTERACTION_SAVE_UPSERT_FAIL';

export const INTERACTION_ADD = 'INTERACTIONS/INTERACTION_ADD';

export const INTERACTION_FIELD_DEFINTION_FETCH_START =
  'INTERACTIONS/INTERACTION_FIELD_DEFINITION_FETCH_START';
export const INTERACTION_FIELD_DEFINTION_FETCH_SUCCESS =
  'INTERACTIONS/INTERACTION_FIELD_DEFINITION_FETCH_SUCCESS';
export const INTERACTION_FIELD_DEFINTION_FETCH_FAIL =
  'INTERACTIONS/INTERACTION_FIELD_DEFINITION_FETCH_FAIL';

export const CASE_LEVEL_RESPONSE = 'CASE';
export const INTERACTION_LEVEL_RESPONSE = 'INTERACTION';

export enum ContactType {
  Patient = 'Patient',
  Pharmacy = 'Pharmacy',
  Prescriber = 'Prescriber',
}
