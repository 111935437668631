import PropTypes from 'prop-types';
import { reduce, add, isNil } from 'ramda';
import React, { Fragment, memo } from 'react';
import ContentLoader from 'react-content-loader';
import { Column, Table, AutoSizer, InfiniteLoader } from 'react-virtualized';
import 'react-virtualized/styles.css';
import ErrorBoundary from 'app/common/components/ErrorBoundary';
import HeaderCell from './HeaderCell';
import styles from './Table.css';

const defaultNoRowsRenderer =
  (columnDefinitions, rowCount) => (width, isFetching) => {
    const totalWidth = reduce(
      (acc, x) => add(acc, x.width),
      0,
      columnDefinitions,
    );
    const row = reduce(
      (acc, x) => {
        const cellWidth = (width - 26) * (x.width / totalWidth);
        return {
          defs: [
            {
              key: x.id,
              x: acc.currWidth,
              width:
                cellWidth * (x.placeholderWidth ? x.placeholderWidth : 0.75),
            },
            ...acc.defs,
          ],
          currWidth: acc.currWidth + cellWidth,
        };
      },
      { defs: [], currWidth: 26 },
      columnDefinitions,
    ).defs;

    return (
      <Fragment>
        {!isFetching && <div className={styles.listMessage}>No Results</div>}
        {isFetching && (
          <div className={styles.loaderContainer}>
            <ContentLoader
              className={styles.loader}
              height={rowCount * 46}
              width={width}
              speed={2}
              backgroundColor="#e5e5e5"
              foregroundColor="#d6d6d6"
              preserveAspectRatio="none"
              style={{ height: `${rowCount * 46}px` }}
            >
              {[...Array(rowCount)].map((x, i) => (
                <Fragment key={`Row${i}`}>
                  <rect
                    key={`${x}${1}`}
                    x="0"
                    y={(1 + i) * 45 + i}
                    rx="5"
                    ry="5"
                    width={width}
                    height="1"
                  />
                  {row.map((y) => (
                    <rect
                      x={y.x}
                      y={i * 46 + 14}
                      rx="3"
                      ry="3"
                      width={y.width}
                      height="16"
                      key={`${y.x}${1}`}
                    />
                  ))}
                </Fragment>
              ))}
            </ContentLoader>
          </div>
        )}
      </Fragment>
    );
  };

// if there are more pages, set to size + 20
const remoteRowCount = (hasNextPage, data) =>
  hasNextPage ? data.size + 20 : data.size;

const isRowLoaded = (index, data) => index < data.size;

const rowClassName = ({ index }) => (index < 0 ? styles.headerRow : styles.row);

const rowGetter = (index, data) => data.toList().get(index);

const rowStyle = (index, applyStyleIndex) =>
  index === applyStyleIndex
    ? { fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 0, .2)' }
    : {};

const cellDataGetter = ({ dataKey, rowData }) => {
  if (isNil(rowData)) return null;
  return rowData[dataKey];
};

const placeholder = <div className={styles.placeholder} />;

const CellRenderer = memo(({ rowData, def }) => {
  const component = rowData ? def.render(rowData) : placeholder;

  if (component === undefined) {
    return null;
  }
  return <ErrorBoundary>{component}</ErrorBoundary>;
});

const DataTable = ({
  data,
  columnDefinitions,
  isSortEnabled,
  onSort,
  sortBy,
  sortDirection,
  loadMoreRows,
  hasNextPage,
  isFetching,
  scrollToIndex,
  onScroll,
  scrollTop,
  onHeaderClick,
  noRowsRenderer: noRowsRendererFactory,
  isFetchingEstimatedRows,
}) => {
  const renderData = data || new Map();
  const totalWidth = reduce(
    (acc, x) => add(acc, x.width),
    0,
    columnDefinitions,
  );
  const noRowsRenderer = noRowsRendererFactory(
    columnDefinitions,
    isFetchingEstimatedRows,
  );

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => isRowLoaded(index, renderData)}
      loadMoreRows={loadMoreRows}
      rowCount={remoteRowCount(hasNextPage, renderData)}
      threshold={25}
      minimumBatchSize={50}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ height, width }) => (
            <Table
              headerHeight={46}
              rowHeight={46}
              height={height}
              className={styles.table}
              width={width}
              rowCount={remoteRowCount(hasNextPage, renderData)}
              rowGetter={({ index }) => rowGetter(index, renderData)}
              rowClassName={rowClassName}
              sort={onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              scrollToAlignment="center"
              scrollToIndex={scrollToIndex}
              rowStyle={({ index }) => rowStyle(index, scrollToIndex)}
              onRowsRendered={onRowsRendered}
              onScroll={onScroll}
              scrollTop={scrollTop}
              gridClassName={styles.grid}
              ref={(el) => {
                registerChild(el);
              }}
              noRowsRenderer={() => noRowsRenderer(width, isFetching)}
              onHeaderClick={onHeaderClick}
            >
              {columnDefinitions.map((def) => (
                <Column
                  label={def.name}
                  key={def.id}
                  dataKey={def.id}
                  cellDataGetter={cellDataGetter}
                  cellRenderer={({ rowData }) => (
                    <CellRenderer rowData={rowData} def={def} />
                  )}
                  headerRenderer={(args) => <HeaderCell {...args} />}
                  width={width * (def.width / totalWidth)}
                  className={styles.columnContent}
                  disableSort={
                    !isSortEnabled ||
                    (isNil(def.disableSort) ? false : def.disableSort)
                  }
                  headerClassName={styles.headerContent}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};
DataTable.propTypes = {
  data: PropTypes.object,
  scrollToIndex: PropTypes.number,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isSortEnabled: PropTypes.bool.isRequired,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  loadMoreRows: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingEstimatedRows: PropTypes.number,
  onScroll: PropTypes.func,
  scrollTop: PropTypes.number,
  onHeaderClick: PropTypes.func,
  noRowsRenderer: PropTypes.func,
};

DataTable.defaultProps = {
  noRowsRenderer: defaultNoRowsRenderer,
  isFetchingEstimatedRows: 50,
  data: null,
  isSortEnabled: false,
  loadMoreRows: () => {},
  hasNextPage: false,
};

export default memo(DataTable);
