import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from '@material-ui/core';
import { toDirectedGraph } from '@xstate/graph';
import {
  addIndex,
  allPass,
  complement,
  filter,
  findIndex,
  forEach,
  groupBy,
  is,
  isEmpty,
  isNil,
  map,
  pipe,
  propSatisfies,
  values,
} from 'ramda';
import React, { useState, createRef, forwardRef, useEffect } from 'react';
import { GraphView } from 'react-digraph';
import ReactDOM from 'react-dom';
import styles from './WorkflowEditor.css';
import { graphs, machines } from './machines';

const EdgeType = {
  simple: 'SIMPLE',
  compound: 'COMPOUND',
  root: 'ROOT',
  circular: 'CIRCULAR',
};

const NodeType = {
  root: 'ROOT',
  status: 'STATUS',
};

const GraphConfig = {
  NodeTypes: {
    empty: {
      // required to show empty nodes
      shapeId: '#empty', // relates to the type property of a node
      shape: (
        <symbol viewBox="0 0 100 100" id="empty" key="0">
          <circle cx="50" cy="50" r="45" className="shape"></circle>
        </symbol>
      ),
    },
    root: {
      shapeId: '#root',
      shape: (
        <symbol viewBox="0 0 100 100" id="root">
          <circle cx="50" cy="50" r="45" fill="black"></circle>
        </symbol>
      ),
    },
    state: {
      shapeId: '#state',
      shape: (
        <symbol viewBox="0 0 154 54" width="154" height="54" id="state">
          <rect x="0" y="0" rx="2" ry="2" width="154" height="54" />
        </symbol>
      ),
    },
    custom: {
      // required to show empty nodes
      typeText: 'Custom',
      shapeId: '#custom', // relates to the type property of a node
      shape: (
        <symbol viewBox="0 0 50 25" id="custom" key="0">
          <ellipse cx="50" cy="25" rx="50" ry="25"></ellipse>
        </symbol>
      ),
    },
  },
  NodeSubtypes: {},
  EdgeTypes: {
    emptyEdge: {
      // required to show empty edges
      shapeId: '#emptyEdge',
      shape: <symbol viewBox="0 0 50 50" id="emptyEdge" key="0"></symbol>,
    },
  },
};

const isNotNil = complement(isNil);
const isNotEmpty = complement(isEmpty);

function hasChildren(node) {
  return allPass([
    is(Object),
    propSatisfies(isNotNil, 'children'),
    propSatisfies(isNotEmpty, 'children'),
  ])(node);
}

function flattenXStateGraph(init, node) {
  const acc = {
    nodes: init.nodes.concat([node]),
    edges: init.edges.concat(node.edges),
  };

  if (!hasChildren(node)) {
    return acc;
  }

  return node.children.reduce(flattenXStateGraph, acc);
}

function toReactDigraph(machine) {
  const xStateGraph = toDirectedGraph(machine);
  const graph = flattenXStateGraph(
    {
      nodes: [],
      edges: [],
    },
    xStateGraph,
  );
  const isRootEdge = (x) => x.source.id === xStateGraph.id;
  const rootLevelEdges = pipe(
    filter(isRootEdge),
    addIndex(map)((x, i) => ({
      id: x.id,
      source: `${x.source.id}-${i}`,
      target: x.target.id,
      type: 'emptyEdge',
      edgeType: EdgeType.root,
      edge: x,
    })),
  )(graph.edges);

  graph.edges.filter(isRootEdge);
  const compoundEdgeKey = (x) => `${x.source.id}${x.target.id}`;
  const compoundEdges = pipe(
    filter(complement(isRootEdge)),
    groupBy(compoundEdgeKey),
    filter((x) => x.length > 1),
    values,
    map((x) => ({
      source: x[0].source.id,
      target: x[0].target.id,
      type: 'emptyEdge',
      edgeType: EdgeType.compound,
      compoundData: x,
    })),
  )(graph.edges);
  const isCompoundEdge = (edge) =>
    compoundEdges
      .flatMap((x) => x.compoundData)
      .map((x) => x.id)
      .includes(edge.id);

  const isCircularEdge = (x) => x.source.id === x.target.id;
  const simpleEdges = pipe(
    filter(complement(isCompoundEdge)),
    filter(complement(isRootEdge)),
    filter(complement(isCircularEdge)),
    map((x) => ({
      source: x.source.id,
      target: x.target.id,
      type: 'emptyEdge',
      edgeType: EdgeType.simple,
      edge: x,
    })),
  )(graph.edges);

  return {
    compoundEdges,
    graph: {
      nodes: graph.nodes
        .filter((x) => x.id !== xStateGraph.id)
        .map((x) => ({
          id: x.id,
          nodeType: NodeType.status,
          status: x.stateNode.key,
          type: 'state',
        }))
        .concat(
          rootLevelEdges.map((e) => ({
            id: e.source,
            nodeType: NodeType.root,
            type: 'state',
          })),
        ),
      edges: compoundEdges.concat(rootLevelEdges).concat(simpleEdges),
    },
  };
}

const edgeDescriptionHeight = 50;
const edgeDescriptionWidth = 150;

const formatLabelText = (text) =>
  text.replace('INTERACTION_COMPLETED_FIELD_', '').replace('_', ' ');

const EdgeDescription = forwardRef(({ edge }, ref) => {
  let details;
  switch (edge.edgeType) {
    case EdgeType.simple:
      details = <div>{formatLabelText(edge.edge.label.text)}</div>;
      break;
    case EdgeType.compound:
      details = (
        <div className={styles.compoundEdge}>
          {edge.compoundData.map((x) => (
            <div>{formatLabelText(x.label.text)}</div>
          ))}
          {edge.compoundData.map((x) => (
            <div>{x.transition?.cond?.type}</div>
          ))}
        </div>
      );
      break;
    case EdgeType.root:
      details = <div>{formatLabelText(edge.edge.label.text)}</div>;
      break;
    case EdgeType.circular:
      details = <div>Circular</div>;
      break;
    default:
      details = <div>Default</div>;
      break;
  }
  return (
    <div
      style={{ height: edgeDescriptionHeight, width: edgeDescriptionWidth }}
      ref={ref}
    >
      <div className={styles.edgeContainer}>{details}</div>
    </div>
  );
});

const WorkFlowEditor = ({
  value,
  onChange,
  label,
  error,
  disabled,
  helperText,
  key,
  variant = 'outlined',
  classes,
}) => {
  const init = toReactDigraph(machines[0]);
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [graph, setGraph] = useState(graphs.case);
  const [graphSelected, setGraphSelected] = useState('case');
  const [portals, setPortals] = useState({});
  const [portalRefs, setPortalRefs] = useState({});
  const editorRef = createRef();

  useEffect(() => {
    setGraph(graphs[graphSelected]);
  }, [graphSelected]);
  const handleSave = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOnUpdateNode = (_, nodes) => {
    forEach((x) => {
      const index = findIndex((y) => y.id === x.id, graph.nodes);
      graph.nodes[index] = x;
    })(nodes);
    setGraph(graph);
  };

  const handleAfterRenderEdge = (
    id,
    element,
    edge,
    edgeContainer,
    isEdgeSelected,
  ) => {
    if (!isNil(editorRef.current)) {
      if (isNil(portals[id])) {
        const ref = createRef();
        portals[id] = ReactDOM.createPortal(
          <EdgeDescription ref={ref} edge={edge} />,
          editorRef.current,
        );
        portalRefs[id] = ref;
        setPortalRefs(portalRefs);
        setPortals(portals);
      }

      if (!isNil(portalRefs[id].current)) {
        const edgeRect =
          edgeContainer.children[0]?.children[0]?.children[0]?.getBoundingClientRect();
        if (!isNil(edgeRect)) {
          portalRefs[id].current.style.zIndex = 99999;
          portalRefs[id].current.style.top = `${
            edgeRect.top + edgeRect.height / 2 - edgeDescriptionHeight / 2
          }px`;
          portalRefs[id].current.style.left = `${
            edgeRect.left + edgeRect.width / 2 - edgeDescriptionWidth / 2
          }px`;
          portalRefs[id].current.style.position = 'fixed';
        }
      }
    }
  };

  const RootNode = ({ data }) => {
    const height = 35;
    const width = 35;
    return (
      <g width={width} height={height} className={styles.node}>
        <svg
          viewBox="0 0 50 50"
          x={-width / 2}
          y={-height / 2}
          width={width}
          height={height}
        >
          <circle cx="25" cy="25" r="25" />
          <text
            x="25"
            y="25"
            dominantBaseline="middle"
            textAnchor="middle"
            textRendering="optimizeLegibility"
            fill="white"
          >
            All
          </text>
        </svg>
      </g>
    );
  };

  const StatusNode = ({ data }) => {
    const height = 50;
    const width = 100;
    let fill;
    switch (data.status) {
      case 'OPEN':
        fill = '#2196f3';
        break;
      case 'INPROGRESS':
        fill = '#f4ac00';
        break;
      case 'DONE':
        fill = '#80c644';
        break;
      default:
        fill = '#2196f3';
        break;
    }

    return (
      <svg
        viewBox="0 0 100 50"
        x={-width / 2}
        y={-height / 2}
        width={width}
        height={height}
        className={styles.node}
      >
        <rect x="0" y="0" width="100" rx="4" height="50" fill={fill} />
        <text
          x="50"
          y="25"
          height="12"
          width="100"
          dominantBaseline="middle"
          textAnchor="middle"
          textRendering="optimizeLegibility"
          fill="white"
        >
          {data.status}
        </text>
      </svg>
    );
  };
  const handleRenderNode = (nodeRef, data, id, selected, hovered) => {
    switch (data.nodeType) {
      case NodeType.root:
        return <RootNode key={id} data={data} />;
      case NodeType.status:
        return <StatusNode data={data} key={id} />;
      default:
        break;
    }
  };

  const inputId = `${key}-input-outlined`;
  return (
    <>
      <FormControl
        classes={classes}
        variant={variant}
        focused={isOpen}
        disabled={disabled}
      >
        <InputLabel htmlFor={inputId} error={error}>
          {label}
        </InputLabel>
        <OutlinedInput
          id={inputId}
          label={label}
          readOnly
          value={value}
          classes={{ input: styles.input }}
          onClick={() => {
            if (!disabled) {
              setOpen(true);
            }
          }}
          error={error}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
      <Dialog maxWidth={false} open={isOpen}>
        <Paper classes={{ root: styles.container }}>
          <div className={styles.header}>
            <div className={styles.headerText}>Workflows</div>
            <Select
              variant="outlined"
              value={graphSelected}
              readOnly
              onChange={(e) => setGraphSelected(e.target.value)}
            >
              <MenuItem key="queue_patient" value="queue_patient">
                Queue (Patient)
              </MenuItem>
              <MenuItem key="case" value="case">
                Case
              </MenuItem>
            </Select>
          </div>
          <div ref={editorRef} className={styles.graphContainer}>
            <GraphView
              nodeKey="id"
              nodes={graph.nodes}
              edges={graph.edges}
              selected={selected}
              nodeTypes={GraphConfig.NodeTypes}
              nodeSubtypes={GraphConfig.NodeSubtypes}
              edgeTypes={GraphConfig.EdgeTypes}
              afterRenderEdge={handleAfterRenderEdge}
              allowMultiselect
              onSelect={setSelected}
              onUpdateNode={handleOnUpdateNode}
              renderNode={handleRenderNode}
            />
          </div>
          <div className={styles.submitRow}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Done
            </Button>
          </div>
          {values(portals)}
        </Paper>
      </Dialog>
    </>
  );
};

export default WorkFlowEditor;
