import { prop, pipe, curry } from 'ramda';
import { SessionStateRecord } from './types';

const getSessionState = (state: any): SessionStateRecord =>
  prop('sessionState')(state);

export const getSession = pipe(getSessionState, prop('session'));

export const getSessionById = curry((state: any, id: string) => {
  return getSession(state).get(id);
});
