import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import TextComparison from 'app/filters/components/TextComparison';
import { numericOperatorConfig, IssueFilterType } from 'app/filters/constants';
import { numericComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  handleNumericComparisonOnChange,
  numericToTextComparisonConverter,
} from 'app/filters/definitions/utility';
import {
  NumericComparison,
  NumericComparisonBackendRecord,
  IClaimFilterConfiguration,
  NumericOperator,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';
import { WorkItemRecord } from 'app/workitems/types';

const summaryFormatter = (value: NumericComparison) => {
  if (isNil(value.value)) return 'Any';
  return `${numericOperatorConfig(value.operator.value).summary} 
    ${value.value} prescribers`;
};

class HistoricOpioidProvidersConfig
  extends Record({
    ...numericComparisonDefaults,
    name: 'historicalOpioidPrescribers',
    title: 'Opioid Prescribers',
    targetType: IssueFilterType.Claim,
    summaryFormatter,
    component: (props: any) => (
      <TextComparison
        {...props}
        inputType="number"
        placeholder="Enter prescriber count"
        onChange={handleNumericComparisonOnChange(props)}
        value={numericToTextComparisonConverter(props.value)}
      />
    ),
    storedValueType: StoredFilterType.NumericComparison,
    filterWorkItem: (
      workItem: WorkItemRecord,
      record: NumericComparisonBackendRecord,
    ) => {
      if (isNil(record.number)) return true;
      if (isNil(workItem.claim.historicalOpioidPrescriberCount)) return false;
      return numericOperatorConfig(record.operator).filter(
        workItem.claim.historicalOpioidPrescriberCount,
        record.number,
      );
    },
  })
  implements
    IClaimFilterConfiguration<
      NumericComparison,
      OrderedSet<NumericOperator>,
      NumericComparisonBackendRecord,
      NumericComparisonBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new HistoricOpioidProvidersConfig();
