import {
  parseJson,
  post,
  addcontentTypeJsonToHeader,
} from '../common/utilities/api';
import { AUTH_REDIRECT_TOKEN_URL, AUTH_REDIRECT_CLIENT_ID } from './constants';

export function fetchToken(code, verifier) {
  const body = {
    code,
    client_id: AUTH_REDIRECT_CLIENT_ID,
    grant_type: 'authorization_code',
    redirect_uri: window.location.origin,
  };

  if (verifier) {
    body.code_verifier = verifier;
  }

  return post(AUTH_REDIRECT_TOKEN_URL, addcontentTypeJsonToHeader(), body)
    .then(parseJson)
    .then((data) => ({
      idToken: data.id_token,
      accessToken: data.access_token,
    }));
}
