import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import {
  endOfToday,
  isToday,
  intervalToDuration,
  sub,
  startOfDay,
} from 'date-fns';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { useRef, useState, useEffect } from 'react';
import { DurationRecord } from '../../duration';
import { Interval, Duration } from '../../types';
import DateRangePicker from '../DateRangePicker';
import styles from './DateRange.css';

const DateRange = ({ value, options, onChange }) => {
  const [customRangeOpen, setCustomRangeOpen] = useState(false);
  const customRangeRef = useRef();
  useEffect(() => {
    if (customRangeOpen) customRangeRef.current.click();
  }, [customRangeOpen]);

  const isTrailing = !isToday(value.end);
  // We need to figure out if any of the options match the given interval
  // Currently only supports accuracy down to the day
  const duration = new DurationRecord({
    ...intervalToDuration(value),
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const selected = new Set(options.map((x) => x.value))
    .filter((x) => x.equals(duration))
    .first();

  const isCustom = isTrailing || customRangeOpen || isNil(selected);

  const handleSelectChange = (event) => {
    if (event.target.value === 'custom') {
      setCustomRangeOpen(true);
    } else {
      onChange(
        new Interval({
          start: startOfDay(sub(new Date(), event.target.value)),
          end: endOfToday(),
        }),
      );
    }
  };

  const handleCustomChange = (valueIn) => {
    onChange(
      new Interval({
        start: valueIn.start,
        end: valueIn.end,
      }),
    );
  };

  return (
    <>
      <FormControl margin="normal" fullWidth>
        <InputLabel>Within</InputLabel>
        <Select
          value={isCustom ? 'custom' : selected}
          onChange={handleSelectChange}
        >
          {options.map((v) => (
            <MenuItem key={v.text} value={v.value}>
              {v.text}
            </MenuItem>
          ))}
          <MenuItem key="custom" value="custom">
            Custom Range
          </MenuItem>
        </Select>
      </FormControl>
      {isCustom && (
        <DateRangePicker
          range
          autoFocus
          fullWidth
          ref={customRangeRef}
          value={value}
          onChange={handleCustomChange}
          onClose={() => setCustomRangeOpen(false)}
        />
      )}
    </>
  );
};

DateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
};

export default DateRange;
