import { addMonths, subDays } from 'date-fns';
import { normalize, schema } from 'normalizr';
import { mergeRight } from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getAccessToken } from '../user/selectors';
import { claim } from '../workitems/types';
import {
  fetchPatientClaimsHistorySuccess,
  fetchPatientClaimsHistoryFail,
  fetchPatientDrugHistorySuccess,
  fetchPatientDrugHistoryFail,
} from './actions';
import { fetchPatientHistory, fetchPatientDrugHistory } from './api';
import {
  PATIENT_CLAIMS_HISTORY_FETCH_START,
  PATIENT_DRUG_HISTORY_FETCH_START,
} from './constants';

export function* fetchPatientClaimsHistoryWorker(action) {
  try {
    const { patientId, filledDate } = action.payload;

    const token = yield select(getAccessToken);
    const startDate = addMonths(filledDate, -6);
    const endDate = addMonths(filledDate, 6);

    const filters = {
      patientId,
      priorDateRange: {
        start: startDate,
        end: filledDate,
      },
      futureDateRange: {
        start: filledDate,
        end: endDate,
      },
    };

    const claims = yield call(fetchPatientHistory, filters, token);

    const data = normalize(claims, new schema.Array(claim));
    yield put(fetchPatientClaimsHistorySuccess(data));
  } catch (e) {
    yield put(fetchPatientClaimsHistoryFail(e));
  }
}

export function* fetchPatientClaimsHistoryWatcher() {
  yield takeLatest(
    PATIENT_CLAIMS_HISTORY_FETCH_START,
    fetchPatientClaimsHistoryWorker,
  );
}

export function* fetchPatientDrugHistoryWorker(action) {
  try {
    const { patientId, drug, daysBack, filledDate } = action.payload;

    const token = yield select(getAccessToken);

    const startDate = subDays(filledDate, daysBack);

    let filters = {
      patientId,
      filledDateRange: {
        start: startDate,
        end: filledDate,
      },
    };

    filters = mergeRight(filters, drug);

    const claims = yield call(fetchPatientDrugHistory, filters, token);
    const data = normalize(claims, new schema.Array(claim));

    yield put(fetchPatientDrugHistorySuccess(data));
  } catch (e) {
    yield put(fetchPatientDrugHistoryFail(e));
  }
}

export function* fetchPatientDrugHistoryWatcher() {
  yield takeLatest(
    PATIENT_DRUG_HISTORY_FETCH_START,
    fetchPatientDrugHistoryWorker,
  );
}
