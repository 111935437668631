import {
  HYDRATING_AUTH,
  HYDRATING_AUTH_DONE,
  HYDRATE,
  HYDRATE_FAIL,
} from './constants';

export function hydratingAuthDone() {
  return { type: HYDRATING_AUTH_DONE, payload: { key: HYDRATING_AUTH } };
}

export function hydrate(state) {
  return { type: HYDRATE, payload: { state } };
}

export function hydrateFail(error) {
  return { type: HYDRATE_FAIL, payload: { error } };
}
