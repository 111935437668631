import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { GraphqlTypes } from 'app/common/types';
import { IssueFilterType } from 'app/filters/constants';
import { enumDefaults } from 'app/filters/definitions/defaults';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';
import { formatLICSLevel } from 'app/workitems/utility';

const optionsTextFormatter = (value: string) =>
  formatLICSLevel(value).toUpperCase();

class LicsLevelConfig
  extends Record({
    ...enumDefaults(GraphqlTypes.licsLevelEnum, optionsTextFormatter),
    name: 'licsLevels',
    title: 'LICS Level',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.licsLevel)) return false;

      return value.contains(workItem.claim.licsLevel);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      Set<string>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new LicsLevelConfig();
