import { has, isNil } from 'ramda';
import { GRAPHQL_URL } from './constants';
import {
  post,
  parseJson,
  addAuthorizationToHeader,
  addcontentTypeJsonToHeader,
} from './utilities/api';
import graphql from './utilities/graphql';

export function throwIfErrors(response) {
  if (!isNil(response.errors)) {
    throw new Error(JSON.stringify(response.errors));
  }
  return response;
}

const createHeaders = (token) =>
  addcontentTypeJsonToHeader(addAuthorizationToHeader(token));

export async function fetchGraphqlQuery(query, variables, token) {
  const result = await post(GRAPHQL_URL, createHeaders(token), {
    query,
    variables,
  });

  return parseJson(result);
}

export function deleteNode(id, token) {
  return fetchGraphqlQuery(
    graphql`
      mutation delete($id: String!) {
        delete(id: $id) {
          id
        }
      }
    `,
    { id },
    token,
  ).then(throwIfErrors);
}
