export const COMMENT_ADD = 'COMMENTS/COMMENT_ADD';
export const COMMENT_ADD_SUCCESS = 'COMMENTS/COMMENT_ADD_SUCCESS';
export const COMMENT_ADD_FAIL = 'COMMENTS/COMMENT_ADD_FAIL';

export const COMMENT_EDIT = 'COMMENTS/COMMENT_EDIT';
export const COMMENT_EDIT_SUCCESS = 'COMMENTS/COMMENT_EDIT_SUCCESS';
export const COMMENT_EDIT_FAIL = 'COMMENTS/COMMENT_EDIT_FAIL';

export const COMMENT_DELETE = 'COMMENTS/COMMENT_DELETE';
export const COMMENT_DELETE_SUCCESS = 'COMMENTS/COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAIL = 'COMMENTS/COMMENT_DELETE_FAIL';
