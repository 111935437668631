import {
  BESTPRACTICES_OVERVIEW_FETCH_START,
  BESTPRACTICES_OVERVIEW_FETCH_SUCCESS,
  BESTPRACTICES_OVERVIEW_FETCH_FAIL,
} from '../../bestpractices/constants';
import { BestPracticeOverviewUiRecord } from '../types';

const reducer = (state = new BestPracticeOverviewUiRecord(), action) => {
  switch (action.type) {
    case BESTPRACTICES_OVERVIEW_FETCH_START: {
      return state.merge({ isFetching: true, error: null });
    }
    case BESTPRACTICES_OVERVIEW_FETCH_SUCCESS: {
      return state.merge({ isFetching: false, error: null });
    }
    case BESTPRACTICES_OVERVIEW_FETCH_FAIL: {
      return state.merge({ isFetching: false, error: action.payload.error });
    }
    default:
      return state;
  }
};

export default reducer;
