import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { MODIFIER_TITLES } from '../../constants';
import styles from './RuleModifier.css';

const RuleModifier = ({ id, appropriate, inappropriate, notReviewed }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{MODIFIER_TITLES[id]}</div>
      <div className={styles.details}>
        {notReviewed > 0 && (
          <div className={classnames(styles.redText, styles.row)}>
            <div className={styles.col}>Not Reviewed</div>
            <div className={classnames(styles.col, styles.number)}>
              {notReviewed}
            </div>
          </div>
        )}
        {appropriate + inappropriate > 0 && (
          <Fragment>
            <div className={styles.row}>
              <div className={styles.col}>Reviewed</div>
              <div className={classnames(styles.col, styles.number)}>
                {appropriate + inappropriate}
              </div>
            </div>
            <div className={styles.indent}>
              <div className={classnames(styles.row)}>
                <div className={styles.col}>Appropriate</div>
                <div className={classnames(styles.col, styles.number)}>
                  {appropriate}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>Inappropriate</div>
                <div className={classnames(styles.col, styles.number)}>
                  {inappropriate}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

RuleModifier.propTypes = {
  id: PropTypes.string.isRequired,
  notReviewed: PropTypes.number.isRequired,
  appropriate: PropTypes.number.isRequired,
  inappropriate: PropTypes.number.isRequired,
};

export default RuleModifier;
