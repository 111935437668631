import {
  DRUG_OVERVIEW_FETCH_START,
  PATIENT_FETCH_START,
  DRUG_OVERVIEW_FETCH_SUCCESS,
  PATIENT_FETCH_SUCCESS,
  DRUG_OVERVIEW_FETCH_FAIL,
  PATIENT_FETCH_FAIL,
  CLAIM_VIEWER_FETCH_START,
  CLAIM_VIEWER_FETCH_FAIL,
  CLAIM_VIEWER_FETCH_SUCCESS,
} from '../../patient/constants';
import { PatientProfileRecord } from '../types';

const patient = {
  fetch: 'isFetchingPatient',
  hasFetched: 'hasFetchedPatient',
  error: 'fetchPatientError',
};
const drugOverview = {
  fetch: 'isFetchingDrugOverview',
  hasFetched: 'hasFetchedDrugOverview',
  error: 'fetchDrugOverviewError',
};
const claimViewer = {
  fetch: 'isFetchingClaimViewer',
  hasFetched: 'hasFetchedClaimViewer',
  error: 'fetchClaimViewerError',
};
const opioids = {
  fetch: 'isFetchingOpioids',
  hasFetched: 'hasFetchedOpioids',
  error: 'fetchOpioidsError',
};

const typeMap = {
  [PATIENT_FETCH_START]: patient,
  [PATIENT_FETCH_SUCCESS]: patient,
  [PATIENT_FETCH_FAIL]: patient,
  [DRUG_OVERVIEW_FETCH_START]: drugOverview,
  [DRUG_OVERVIEW_FETCH_SUCCESS]: drugOverview,
  [DRUG_OVERVIEW_FETCH_FAIL]: drugOverview,
  [CLAIM_VIEWER_FETCH_START]: claimViewer,
  [CLAIM_VIEWER_FETCH_SUCCESS]: claimViewer,
  [CLAIM_VIEWER_FETCH_FAIL]: claimViewer,
};

const reducer = (state = new PatientProfileRecord(), action) => {
  switch (action.type) {
    case DRUG_OVERVIEW_FETCH_START:
    case PATIENT_FETCH_START:
    case CLAIM_VIEWER_FETCH_START: {
      const entry = typeMap[action.type];
      return state.merge({
        [entry.fetch]: true,
        [entry.error]: null,
      });
    }
    case DRUG_OVERVIEW_FETCH_SUCCESS:
    case PATIENT_FETCH_SUCCESS:
    case CLAIM_VIEWER_FETCH_SUCCESS: {
      const entry = typeMap[action.type];
      return state.merge({
        [entry.fetch]: false,
        [entry.hasFetched]: action.payload.id,
        [entry.error]: null,
      });
    }
    case DRUG_OVERVIEW_FETCH_FAIL:
    case PATIENT_FETCH_FAIL:
    case CLAIM_VIEWER_FETCH_FAIL: {
      const entry = typeMap[action.type];
      return state.merge({
        [entry.fetch]: false,
        [entry.error]: action.payload.error,
      });
    }
    default:
      return state;
  }
};

export default reducer;
