import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ColorChip.css';

const ColorChip = ({ className, horizontal, color }) => (
  <div
    className={classnames({
      [styles.colorChip]: true,
      [className]: true,
      [styles.horizontal]: horizontal,
    })}
    style={{ backgroundColor: `var(--${color})` }}
  >
    &nbsp;
  </div>
);

ColorChip.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  color: PropTypes.oneOf(['orange', 'blue']),
};

ColorChip.defaultProps = {
  className: '',
  horizontal: false,
  color: 'orange',
};

export default ColorChip;
