import graphql from '../common/utilities/graphql';

export const prescriberFragmentFactory = (name) => graphql`
  fragment ${name} on PrescriberType {
    id
    entityId
    name
    npi
    groupPracticeId
    organizationName
    primarySpecialty
    phoneNumber
  }
`;
