import {
  BESTPRACTICES_CATEGORY_FETCH_START,
  BESTPRACTICES_CATEGORY_FETCH_SUCCESS,
  BESTPRACTICES_CATEGORY_FETCH_FAIL,
  BESTPRACTICES_CATEGORY_UPDATE_DATE,
} from '../../bestpractices/constants';
import { BestPracticeCategoryUiRecord } from '../types';

const reducer = (state = new BestPracticeCategoryUiRecord(), action) => {
  switch (action.type) {
    case BESTPRACTICES_CATEGORY_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case BESTPRACTICES_CATEGORY_FETCH_SUCCESS:
      return state.merge({ isFetching: false, error: null });
    case BESTPRACTICES_CATEGORY_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    case BESTPRACTICES_CATEGORY_UPDATE_DATE:
      return state.merge({
        selectedDate: action.payload.dateRange,
      });
    default:
      return state;
  }
};

export default reducer;
