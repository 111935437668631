import { Set } from 'immutable';
import { CASES_FILTER_UPDATE } from '../constants';
import { CasesFilterStateRecord } from '../types';

function reducer(state = new CasesFilterStateRecord(), action) {
  switch (action.type) {
    case CASES_FILTER_UPDATE: {
      const { assignees, campaigns, statuses } = action.payload.filters;
      return new CasesFilterStateRecord({
        assignees: new Set(assignees),
        campaigns: new Set(campaigns),
        statuses: new Set(statuses),
      });
    }
    default:
      return state;
  }
}

export default reducer;
