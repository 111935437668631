import classnames from 'classnames';
import React from 'react';
import Tag from '../../../common/components/Tag/Tag';
import styles from './WorkItemStatus.css';

const WorkItemStatus = ({ status, bold }) => {
  let className;
  if (bold) {
    className = classnames({
      [styles.tagBold]: true,
      [styles.blueBold]: status.type === 'OPEN',
      [styles.orangeBold]: status.type === 'INPROGRESS',
      [styles.greenBold]: status.type === 'DONE',
    });
  } else {
    className = classnames({
      [styles.tag]: true,
      [styles.blue]: status.type === 'OPEN',
      [styles.orange]: status.type === 'INPROGRESS',
      [styles.green]: status.type === 'DONE',
      [styles.tagBold]: bold,
    });
  }

  return <Tag className={className} text={status.name} />;
};

export default WorkItemStatus;
