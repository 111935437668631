import { OrderedSet } from 'immutable';
import { values, map, prop } from 'ramda';
import {
  WORKITEMLIST_FILTERS_CLEAR,
  WORKITEMLIST_FILTERS_UPDATE,
  WORKITEMLIST_FILTER_SET_APPLIED,
} from 'app/ui/constants';
import { VIEWTEMPLATE_APPLY } from '../../viewtemplates/constants';
import {
  WORKITEMS_QUERY_FETCH_SUCCESS,
  WORKITEMS_QUERY_START,
  WORKITEMS_QUERY_CLEAR,
} from '../constants';

const reducer = (state = new OrderedSet(), action) => {
  switch (action.type) {
    case WORKITEMS_QUERY_CLEAR:
    case VIEWTEMPLATE_APPLY:
    case WORKITEMLIST_FILTERS_CLEAR:
    case WORKITEMLIST_FILTER_SET_APPLIED:
    case WORKITEMLIST_FILTERS_UPDATE: {
      return new OrderedSet();
    }
    case WORKITEMS_QUERY_FETCH_SUCCESS: {
      const { isContinuation, data } = action.payload;
      const results = new OrderedSet(
        map(prop('id'), values(data.entities.workItems)),
      );
      if (state.equals(results)) {
        return state;
      }

      if (isContinuation) {
        return state.merge(results);
      }
      return results;
    }
    default:
      return state;
  }
};

export default reducer;
