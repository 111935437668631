import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
} from '@material-ui/core';
import { Set } from 'immutable';
import { is } from 'ramda';
import { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { campaignEditFilterOpen } from 'app/campaigns/actions';
import { IssueFilterType } from 'app/filters/constants';
import FilterSideBar from 'app/filters/containers/FilterSideBar/FilterSideBar';
import filterDefinitions from 'app/filters/definitions';
import {
  campaignEditFetchFilterOptionsStart,
  campaignEditFiltersClear,
  campaignEditFilterSetApplied,
  campaignEditFilterSetEdited,
  campaignEditUpdateFilter,
} from 'app/ui/actions';
import { getCampaignEditAppliedFilters } from 'app/ui/selectors';
import { getAccessToken } from 'app/user/selectors';
import { FilterRecord } from 'app/viewtemplates/types';
import styles from './FilterEditor.css';

const FilterEditor = forwardRef(
  (
    {
      value,
      onChange,
      label,
      error,
      disabled,
      helperText,
      variant = 'outlined',
      classes,
    },
    ref,
  ) => {
    const [isOpen, setOpen] = useState(false);
    const [issueFilterType, setIssueFilterType] = useState(
      value.issueFilterType ?? IssueFilterType.Patient,
    );
    const dispatch = useDispatch();

    const filteredDefinitions = filterDefinitions.filter(
      (x) => x.targetType === issueFilterType,
    );
    const appliedFilters = useSelector((state) =>
      getCampaignEditAppliedFilters(state),
    );
    const token = useSelector(getAccessToken);

    const handleFilterOpen = () => {
      let fetchOperations = Set();
      const filters = value.filters.map((filter) => {
        const config = filteredDefinitions.find((x) => x.name === filter.name);
        const options = config.options(token);
        const needToFetchOptions = is(Promise, options);
        if (needToFetchOptions) {
          fetchOperations = fetchOperations.add({ id: config.id, options });
        }
        return {
          id: config.id,
          name: filter.name,
          value: filter.value,
          options: !needToFetchOptions ? options : undefined,
        };
      });
      dispatch(campaignEditFilterOpen(filters));

      fetchOperations.forEach((operation) => {
        dispatch(
          campaignEditFetchFilterOptionsStart(operation.id, operation.options),
        );
      });

      setOpen(true);
    };

    const handleSave = () => {
      onChange(
        value.merge({
          issueFilterType,
          filters: appliedFilters
            .map((filter, id) => {
              const config = filteredDefinitions.get(id);
              return new FilterRecord({
                name: filter.name,
                value: config.filterToTemplateValueConverter(filter.value),
                valueType: config.storedValueType,
              });
            })
            .toSet(),
        }),
      );
      setOpen(false);
    };

    const handleCancel = () => {
      setOpen(false);
      setIssueFilterType(value.issueFilterType ?? IssueFilterType.Claim);
    };

    const inputId = `${label}-input-outlined`;
    return (
      <>
        <FormControl
          classes={classes}
          variant={variant}
          focused={isOpen}
          disabled={disabled}
        >
          <InputLabel htmlFor={inputId} error={error}>
            {label}
          </InputLabel>
          <OutlinedInput
            id={inputId}
            label={label}
            ref={ref}
            readOnly
            value={value?.filters
              .map(
                (x) =>
                  filterDefinitions
                    .filter((y) => y.targetType === value.issueFilterType)
                    .find((y) => y.name === x.name).title,
              )
              .join(', ')}
            classes={{ input: styles.input }}
            onClick={() => {
              if (!disabled) {
                handleFilterOpen();
              }
            }}
            error={error}
          />
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </FormControl>
        <Dialog maxWidth={false} open={isOpen}>
          <Paper classes={{ root: styles.container }}>
            <div className={styles.header}>
              <div className={styles.headerText}>Campaign Filters</div>
              <Select
                defaultValue={issueFilterType}
                variant="outlined"
                color="secondary"
                label="Filter Type"
                onChange={(e) => {
                  dispatch(campaignEditFiltersClear());
                  setIssueFilterType(e.target.value);
                }}
              >
                <MenuItem value={IssueFilterType.Patient}>Patient</MenuItem>
                <MenuItem value={IssueFilterType.Claim}>Claim</MenuItem>
              </Select>
            </div>
            <div className={styles.filterSidebarContainer}>
              <FilterSideBar
                filterType={issueFilterType}
                appliedFilters={appliedFilters}
                onApplyAction={campaignEditFilterSetApplied}
                onEditAction={campaignEditFilterSetEdited}
                onFilterUpdate={campaignEditUpdateFilter}
                onFetchOptionsAction={campaignEditFetchFilterOptionsStart}
              />
            </div>
            <div className={styles.submitRow}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Done
              </Button>
            </div>
          </Paper>
        </Dialog>
      </>
    );
  },
);

const FilterEditorContainer = FilterEditor;

export default FilterEditorContainer;
