import { createAction } from '@reduxjs/toolkit';
import {
  SIDEBAR_EXPAND,
  SIDEBAR_MINIMIZE,
  FILTER_SIDEBAR_EXPAND,
  FILTER_SIDEBAR_MINIMIZE,
  SNACKBAR_CLOSE,
  WORKITEM_LIST_SCROLL_TOP_CHANGE,
  NOTIFICATION_PANEL_OPEN,
  NOTIFICATION_PANEL_CLOSE,
  WORKITEMLIST_FILTER_SET_EDITED,
  WORKITEMLIST_FILTER_SET_APPLIED,
  WORKITEMLIST_FILTERS_CLEAR,
  CAMPAIGN_EDIT_FILTER_SET_APPLIED,
  CAMPAIGN_EDIT_FILTER_SET_EDITED,
  WORKITEMLIST_FILTERS_UPDATE,
  CAMPAIGN_EDIT_FILTER_UPDATE,
  WORKITEMLIST_FILTER_FETCH_OPTIONS_FAIL,
  WORKITEMLIST_FILTER_FETCH_OPTIONS_SUCCESS,
  WORKITEMLIST_FILTER_FETCH_OPTIONS_START,
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_SUCCESS,
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_FAIL,
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_START,
  CAMPAIGN_EDIT_FILTERS_CLEAR,
} from './constants';
import { FilterFetchStart, SetApplied, SetEditing, SetValue } from './types';

export function expandSidebar() {
  return { type: SIDEBAR_EXPAND, payload: {} };
}

export function minimizeSidebar() {
  return { type: SIDEBAR_MINIMIZE, payload: {} };
}

export function expandFilterSidebar() {
  return { type: FILTER_SIDEBAR_EXPAND, payload: {} };
}

export function minimizeFilterSidebar() {
  return { type: FILTER_SIDEBAR_MINIMIZE, payload: {} };
}

export function closeSnackbar() {
  return { type: SNACKBAR_CLOSE, payload: {} };
}

export function workItemListScrollTopChange(scrollTop: number) {
  return { type: WORKITEM_LIST_SCROLL_TOP_CHANGE, payload: { scrollTop } };
}

export function openNotificationPanel() {
  return { type: NOTIFICATION_PANEL_OPEN, payload: {} };
}

export function closeNotificationPanel() {
  return { type: NOTIFICATION_PANEL_CLOSE, payload: {} };
}

export const workItemListFilterSetEdited = createAction(
  WORKITEMLIST_FILTER_SET_EDITED,
  (id, isEditing) => {
    return {
      payload: new SetEditing({
        id,
        isEditing,
      }),
    };
  },
);

export const workItemlistFilterSetApplied = createAction(
  WORKITEMLIST_FILTER_SET_APPLIED,
  (
    id: string,
    name: string,
    defaultValue: any,
    options: any,
    isApplied: boolean,
  ) => {
    return {
      payload: new SetApplied({
        id,
        name,
        options,
        defaultValue,
        isApplied,
      }),
    };
  },
);

export const workItemListFiltersClear = createAction(
  WORKITEMLIST_FILTERS_CLEAR,
);

export const workItemListUpdateFilters = createAction(
  WORKITEMLIST_FILTERS_UPDATE,
  (id: string, value: unknown) => {
    return {
      payload: new SetValue({
        id,
        value,
      }),
    };
  },
);

export const campaignEditFiltersClear = createAction(
  CAMPAIGN_EDIT_FILTERS_CLEAR,
);

export const campaignEditFilterSetApplied = createAction(
  CAMPAIGN_EDIT_FILTER_SET_APPLIED,
  (
    id: string,
    name: string,
    defaultValue: any,
    options: any,
    isApplied: boolean,
  ) => {
    return {
      payload: new SetApplied({
        id,
        name,
        options,
        defaultValue,
        isApplied,
      }),
    };
  },
);

export const campaignEditFilterSetEdited = createAction(
  CAMPAIGN_EDIT_FILTER_SET_EDITED,
  (id, isEditing) => {
    return {
      payload: new SetEditing({
        id,
        isEditing,
      }),
    };
  },
);

export const campaignEditUpdateFilter = createAction(
  CAMPAIGN_EDIT_FILTER_UPDATE,
  (id: string, value: unknown) => {
    return {
      payload: new SetValue({
        id,
        value,
      }),
    };
  },
);

export const workItemListFetchFilterOptionsSuccess = createAction(
  WORKITEMLIST_FILTER_FETCH_OPTIONS_SUCCESS,
  (id, options) => {
    return {
      payload: {
        id,
        options,
      },
    };
  },
);

export const workItemListFetchFilterOptionsFail = createAction(
  WORKITEMLIST_FILTER_FETCH_OPTIONS_FAIL,
  (id, error) => {
    return {
      payload: {
        id,
        error,
      },
    };
  },
);

export const workItemListFetchFilterOptionsStart = createAction(
  WORKITEMLIST_FILTER_FETCH_OPTIONS_START,
  (id, resolve) => {
    return {
      payload: new FilterFetchStart({
        id,
      }),
      meta: {
        resolve,
        success: workItemListFetchFilterOptionsSuccess,
        fail: workItemListFetchFilterOptionsFail,
      },
    };
  },
);

export const campaignEditFetchFilterOptionsSuccess = createAction(
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_SUCCESS,
  (id, options) => {
    return {
      payload: {
        id,
        options,
      },
    };
  },
);

export const campaignEditFetchFilterOptionsFail = createAction(
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_FAIL,
  (id, error) => {
    return {
      payload: {
        id,
        error,
      },
    };
  },
);

export const campaignEditFetchFilterOptionsStart = createAction(
  CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_START,
  (id, resolve) => {
    return {
      payload: new FilterFetchStart({
        id,
      }),
      meta: {
        resolve,
        success: campaignEditFetchFilterOptionsSuccess,
        fail: campaignEditFetchFilterOptionsFail,
      },
    };
  },
);
