import { navigate } from '@reach/router';
import { normalize } from 'normalizr';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { genericGraphQlWorker } from '../common/sagas';
import {
  fetchCaseFail,
  fetchCasesQueryFail,
  fetchCasesQuerySuccess,
  fetchCaseSuccess,
  upsertCaseFail,
  upsertCaseSuccess,
  bulkCreateCasesFail,
  bulkCreateCasesSuccess,
} from './actions';
import {
  fetchCaseDetails,
  fetchCases,
  upsertCase,
  bulkCreateCases,
} from './api';
import {
  CASES_QUERY_FETCH_START,
  CASE_FETCH,
  CASE_UPSERT,
  BULK_CASE_UPSERT,
} from './constants';
import { caseSchema, casesQuerySchema } from './types';

export function* fetchCaseWorker(action) {
  const { id } = action.payload;
  yield call(
    genericGraphQlWorker,
    (token) => call(fetchCaseDetails, id, token),
    (response) =>
      put(fetchCaseSuccess(id, normalize(response.case, caseSchema))),
    (error) => fetchCaseFail(id, error),
  );
}

export function* fetchCaseWatcher() {
  yield takeLatest(CASE_FETCH, fetchCaseWorker);
}

export function* fetchCasesQueryWorker(action) {
  const { filter, sortBy, sortDirection, continuationToken } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) =>
      call(fetchCases, filter, sortBy, sortDirection, token, continuationToken),
    (response) =>
      put(
        fetchCasesQuerySuccess(
          normalize(response.data.results, casesQuerySchema),
          response.data.continuationToken,
          continuationToken !== null,
        ),
      ),
    (error) => fetchCasesQueryFail(error),
  );
}

export function* fetchCasesQueryWatcher() {
  yield takeLatest(CASES_QUERY_FETCH_START, fetchCasesQueryWorker);
}

export function* upsertCaseWorker(action) {
  const { optimisticId, case: caseRecord } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(upsertCase, caseRecord, token),
    (response) => {
      const data = normalize(response.data, caseSchema);
      return put(upsertCaseSuccess(optimisticId, data.result, data));
    },
    (error) => upsertCaseFail(optimisticId, action, new Date(), error),
  );
}

export function* upsertCaseWatcher() {
  yield takeEvery(CASE_UPSERT, upsertCaseWorker);
}

export function* bulkCreateCasesWorker(action) {
  const { optimisticId, bulkCreateCasesRecord } = action.payload;

  yield call(
    genericGraphQlWorker,
    (token) => call(bulkCreateCases, bulkCreateCasesRecord, token),
    (response) => {
      return put(bulkCreateCasesSuccess(optimisticId, response.data));
    },
    (error) => bulkCreateCasesFail(optimisticId, action, new Date(), error),
  );
}

export function* bulkCreateCasesWatcher() {
  yield takeEvery(BULK_CASE_UPSERT, bulkCreateCasesWorker);
}
