import {
  SESSION_UPSERT,
  SESSION_UPSERT_FAIL,
  SESSION_UPSERT_SUCCESS,
} from 'app/session/constants';
import { SessionInputRecord } from 'app/session/types';

export function upsertSession(
  optimisticId: string,
  id: string,
  session: SessionInputRecord,
) {
  return { type: SESSION_UPSERT, payload: { optimisticId, id, session } };
}

export function upsertSessionSuccess(
  optimisticId: string,
  id: string,
  data: any,
) {
  return { type: SESSION_UPSERT_SUCCESS, payload: { optimisticId, id, data } };
}

export function upsertSessionFail(
  optimisticId: string,
  id: string,
  error: any,
) {
  return { type: SESSION_UPSERT_FAIL, payload: { optimisticId, id, error } };
}
