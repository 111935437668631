import graphql from '../common/utilities/graphql';

export const bestPracticeQuery = graphql`
  query (
    $bestPracticeCategories: [String]
    $dateRange: DateRangeInputGraphType!
  ) {
    cmsBestPractices(bestPracticeCategory: $bestPracticeCategories) {
      id
      name
      bestPractices {
        name
        id
        rules {
          id
          description
          results(dateRange: $dateRange) {
            inappropriate
            notReviewed
            appropriate
            date
            modifiers {
              id
              inappropriate
              notReviewed
              appropriate
            }
          }
        }
      }
    }
  }
`;
