import {
  EXPORT_CASE_DOWNLOAD_FETCH_FAIL,
  EXPORT_DOWNLOAD_FAIL,
} from '../../export/constants';
import {
  VIEWTEMPLATE_QUICKSAVE_FAIL,
  VIEWTEMPLATE_QUICKSAVE_SUCCESS,
} from '../../viewtemplates/constants';
import { WORKITEMS_SORT_DISABLED } from '../../workitems/constants';
import { SNACKBAR_CLOSE } from '../constants';
import { SnackbarRecord } from '../types';

const reducer = (state = new SnackbarRecord(), action) => {
  switch (action.type) {
    case SNACKBAR_CLOSE:
      return state.merge({
        isOpen: false,
      });
    case VIEWTEMPLATE_QUICKSAVE_FAIL:
      return state.merge({
        isOpen: true,
        message: 'An unexpected error occurred while saving the template.',
      });
    case WORKITEMS_SORT_DISABLED:
      return state.merge({
        isOpen: true,
        message:
          'Custom sorting is disabled because the Work Item List contains over 1,000 items.',
      });
    case VIEWTEMPLATE_QUICKSAVE_SUCCESS:
      return state.merge({
        isOpen: true,
        message: 'Template Successfully Saved',
      });
    case EXPORT_DOWNLOAD_FAIL:
    case EXPORT_CASE_DOWNLOAD_FETCH_FAIL:
      if (action.payload.status === 413 || action.payload.status === 504)
        //                   Entity Too Large                  Gateway Timeout
        return state.merge({
          isOpen: true,
          message:
            'The export was too large. Please try a smaller date range or add more filters.',
        });

      return state.merge({
        isOpen: true,
        message:
          'An error occurred during the export. Please contact support if the problem persists.',
      });
    default:
      return state;
  }
};

export default reducer;
