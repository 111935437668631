import { Record, Map, Set, OrderedSet } from 'immutable';
import { isNil } from 'ramda';
import { ReactElement } from 'react';
import { GpiLevel } from '../common/constants';
import { DurationRecord } from '../common/duration';
import { StoredFilterType } from '../viewtemplates/constants';
import { WorkItemRecord } from '../workitems/types';
import {
  getTextOperatorConfig,
  IssueFilterType,
  LateToFillMatch,
  MatchStrings,
  numericOperatorConfig,
  NumericOperators,
  TextComparisonOperators,
} from './constants';

export enum InitialOpioidSupplyWindow {
  Thirty = 'Thirty',
  Sixty = 'Sixty',
}
export class GPILevelValueRecord extends Record({
  text: '',
  value: GpiLevel.Six,
}) {}

export class InitialOpioidWindowSelectValueRecord extends Record({
  text: '',
  value: InitialOpioidSupplyWindow.Sixty,
}) {}

export class DayRangeValueRecord extends Record({ text: '', value: 0 }) {}

export class DateRangeSelectValue extends Record({
  text: '1 day',
  value: new DurationRecord({ days: 1 }),
}) {}

/** @remarks The window type improves upon this greatly, consider migrating */
export class DateRangeOffset extends Record({
  /** The number of days to offset the start date. */
  start: 0,
  /** The number of days to offset the end date. */
  end: 0,
}) {}

export class NumericOperator extends Record({
  text: numericOperatorConfig(NumericOperators.Greater).text,
  value: NumericOperators.Greater,
}) {}

export class NumericComparison extends Record({
  value: undefined as number | undefined,
  operator: new NumericOperator(),
}) {}

export class KeyValueRecord extends Record({
  key: '',
  value: '',
}) {}

// TODO could we default to empty string?
// We have a use case for records with the same shape here but different typings
// on the value prop ex. string, number, enums etc
// Sadly typescript does not support passing generic typings onto the base class
// without some hacks
// https://github.com/microsoft/TypeScript/issues/36406
export class SelectValueRecord extends Record({
  text: '',
  value: '',
}) {}

export class MatchStringsInputRecord extends Record({
  match: MatchStrings.Any,
  values: Set<string>(),
}) {}

export class MatchesClaimValuesRecord extends Record({
  isMatching: true,
  gpiLevel: new GPILevelValueRecord(),
  dayRange: new DayRangeValueRecord(),
}) {}

export class MatchesClaimOptionsRecord extends Record({
  gpiLevels: Set<GPILevelValueRecord>(),
  dayRanges: Set<DayRangeValueRecord>(),
}) {}

export class MatchesClaimBackendRecord extends Record({
  hasPaidClaim: true,
  gpiLevel: GpiLevel.Six,
  window: 0,
}) {}

export class SchemaRecord extends Record({
  id: '',
  name: '',
  type: '',
  required: false,
}) {}

export const FiltersStateRecord = Record({
  isFetching: false,
  error: null,
  filters: Map(),
});

export class NumericComparisonBackendRecord extends Record({
  number: undefined as number | undefined,
  operator: NumericOperators.Equal,
}) {}

export class WindowRecord extends Record({
  start: null as DurationRecord | null,
  end: null as DurationRecord | null,
}) {}

export class WindowBackendRecord extends Record({
  start: null as string | null,
  end: null as string | null,
}) {}

export class LateToFillFilterBackendRecord extends Record({
  matchType: LateToFillMatch.Gpi,
  gpis: OrderedSet<KeyValueRecord>(),
  therapies: OrderedSet<KeyValueRecord>(),
  window: new WindowBackendRecord(),
  excludeIssuesBeforeCampaignStartDate: false,
}) {}

export class LateToFillFilterRecord extends Record({
  matchType: LateToFillMatch.Gpi,
  gpis: OrderedSet<SelectValueRecord>(),
  therapies: OrderedSet<SelectValueRecord>(),
  window: new WindowRecord(),
  excludeIssuesBeforeCampaignStartDate: false,
}) {}

export class DosingScheduleBackendRecord extends Record({
  gpiPrefixes: OrderedSet<KeyValueRecord>(),
  dosingInterval: null as string | null,
  maxDoses: null as number | null,
}) {}

export class DosingScheduleFilterBackendRecord extends Record({
  exceedsSchedule: false,
  dosingSchedule: new DosingScheduleBackendRecord(),
}) {}

export class DosingScheduleRecord extends Record({
  gpiPrefixes: Set<SelectValueRecord>(),
  dosingInterval: null as null | DurationRecord,
  maxDoses: null as number | null,
}) {}

export class DosingScheduleFilterRecord extends Record({
  exceedsSchedule: false,
  dosingSchedule: new DosingScheduleRecord(),
}) {}

export enum BoundaryTypes {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE',
}

export class BoundaryRecord extends Record({
  type: BoundaryTypes.Inclusive,
  value: 0,
}) {}

export class NumericRangeRecord extends Record({
  minimum: undefined as BoundaryRecord | undefined,
  maximum: undefined as BoundaryRecord | undefined,
}) {}

export class TextComparisonOperator extends Record({
  text: getTextOperatorConfig(TextComparisonOperators.Contains).text,
  value: TextComparisonOperators.Contains,
}) {}

export class TextComparisonRecord extends Record({
  text: '',
  operator: new TextComparisonOperator(),
}) {}

export class TextComparisonBackendRecord extends Record({
  text: '',
  operator: TextComparisonOperators.Contains,
}) {}

export class InitialOpioidSupplyFilterRecord extends Record({
  numericComparison: new NumericComparison(),
  window: new InitialOpioidWindowSelectValueRecord(),
}) {}

export class InitialOpioidSupplyTemplateRecord extends Record({
  numericComparison: new NumericComparisonBackendRecord(),
  window: InitialOpioidSupplyWindow.Thirty,
}) {}

export class NumericSelectOptionsRecord extends Record({
  numericComparison: OrderedSet<NumericOperator>(),
  select: OrderedSet<InitialOpioidWindowSelectValueRecord>(),
}) {}

export interface IFilterConfiguration<
  FilterValue,
  FilterOptions,
  QueryValue,
  StoredValue,
> {
  /** The name of the filter, this much match up with the name of the top level property in graphql */
  name: string;

  /** A unique ID */
  id: string;

  /** The title of the filter used for display */
  readonly title: string;

  /** The availability of the filter in the ui is determined via this */
  enabled: boolean;

  /** A function which will return the component used for display when editing */
  component: (props: any) => ReactElement;

  /** The options available to the component */
  options: (token: string) => FilterOptions | Promise<FilterOptions> | void;

  /** A default value for the filter when applied */
  defaultValue: () => FilterValue;

  /** Specifies the value type used for storage */
  storedValueType: StoredFilterType;

  /** Used to render the summary text */
  summaryFormatter: (value: FilterValue) => string;

  /** Converts the filter value to the value used in the query object */
  valueToQueryConverter: (value: FilterValue) => QueryValue;

  /** Converts the template value to the filter value */
  templateToFilterValueConverter: (value: StoredValue) => FilterValue;

  /** Converts the filter value to the template value */
  filterToTemplateValueConverter: (value: FilterValue) => StoredValue;

  /** Converts the plain stored javascript object to the stored value type */
  storedValueConstructor: (value: any) => StoredValue;

  /** The target the filter applies to */
  targetType: IssueFilterType;

  /** Workflow specific denotes that the filter is not part of what makes the "issue" */
  workflowSpecific: boolean;
}

export interface IClaimFilterConfiguration<
  FilterValue,
  FilterOptions,
  QueryValue,
  StoredValue,
> extends IFilterConfiguration<
    FilterValue,
    FilterOptions,
    QueryValue,
    StoredValue
  > {
  /** Used by claim filters to do client side filtering */
  filterWorkItem: (
    workItem: WorkItemRecord,
    value: QueryValue,
    userId: string | undefined,
  ) => boolean;
}

export class FetchFiltersResponse extends Record({
  filters: [],
  filterType: '',
}) {}

const convertToBoundary = (
  value: number | undefined,
  type: BoundaryTypes,
): undefined | BoundaryRecord => {
  if (isNil(value)) return undefined;
  return new BoundaryRecord({
    value: Number(value),
    type,
  });
};

export const comparisonToRangeConverter = (value: NumericComparison) => {
  switch (value.operator.value) {
    case NumericOperators.Equal: {
      return new NumericRangeRecord({
        minimum: convertToBoundary(value.value, BoundaryTypes.Inclusive),
        maximum: convertToBoundary(value.value, BoundaryTypes.Inclusive),
      });
    }
    case NumericOperators.Greater: {
      return new NumericRangeRecord({
        minimum: convertToBoundary(value.value, BoundaryTypes.Exclusive),
      });
    }
    case NumericOperators.Less: {
      return new NumericRangeRecord({
        maximum: convertToBoundary(value.value, BoundaryTypes.Exclusive),
      });
    }
    default: {
      throw new Error('Operator not supported');
    }
  }
};

export class MultiSelectMatchFilterRecord extends Record({
  match: MatchStrings.Any,
  values: Set<SelectValueRecord>(),
}) {}

export class MultiSelectMatchStoredValue extends Record({
  match: MatchStrings.Any,
  values: Set<KeyValueRecord>(),
}) {}

export class GreaterThanFillsFromDateFilterRecord extends Record({
  fromDate: null as Date | null,
  therapies: new MultiSelectMatchFilterRecord(),
  numberOfFills: new NumericComparison(),
}) {}

export class GreaterThanFillsFromDateFilterBackendRecord extends Record({
  fromDate: null as Date | null,
  therapies: new MultiSelectMatchStoredValue(),
  numberOfFills: new NumericComparisonBackendRecord(),
}) {}

export class LessThanFillsFromDateFilterRecord extends Record({
  fromDate: null as Date | null,
  therapies: new MultiSelectMatchFilterRecord(),
  numberOfFills: new NumericComparison(),
}) {}

export class LessThanFillsFromDateFilterBackendRecord extends Record({
  fromDate: null as Date | null,
  therapies: new MultiSelectMatchStoredValue(),
  numberOfFills: new NumericComparisonBackendRecord(),
}) {}

export class AgeRangeFilterRecord extends Record({
  fromDate: null as Date | null,
  minAge: undefined as number | undefined,
  maxAge: undefined as number | undefined,
}) {}

export class AgeRangeFilterBackendRecord extends Record({
  fromDate: null as Date | null,
  minAge: undefined as number | undefined,
  maxAge: undefined as number | undefined,
}) {}
