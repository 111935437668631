import { OrderedSet, Record } from 'immutable';
import { isEmpty } from 'ramda';
import TextComparison from 'app/filters/components/TextComparison/TextComparison';
import { IssueFilterType, numericOperatorConfig } from 'app/filters/constants';
import { numericComparisonDefaults } from 'app/filters/definitions/defaults';
import {
  handleNumericComparisonOnChange,
  numericToTextComparisonConverter,
} from 'app/filters/definitions/utility';
import {
  IFilterConfiguration,
  NumericComparison,
  NumericComparisonBackendRecord,
  NumericOperator,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';

const summaryFormatter = (value: NumericComparison) => {
  if (isEmpty(value.value)) return 'All';
  return `${numericOperatorConfig(value.operator.value).summary} 
    ${value.value} years old`;
};

class AgeConfig
  extends Record({
    ...numericComparisonDefaults,
    name: 'age',
    title: 'Age',
    targetType: IssueFilterType.Patient,
    summaryFormatter,
    component: (props: any) => (
      <TextComparison
        {...props}
        inputType="number"
        placeholder="Enter age"
        onChange={handleNumericComparisonOnChange(props)}
        value={numericToTextComparisonConverter(props.value)}
        min="0"
      />
    ),
    storedValueType: StoredFilterType.NumericComparison,
  })
  implements
    IFilterConfiguration<
      NumericComparison,
      OrderedSet<NumericOperator>,
      NumericComparisonBackendRecord,
      NumericComparisonBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new AgeConfig();
