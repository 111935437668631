import { Record, Map, Set } from 'immutable';
import { schema } from 'normalizr';
import { SelectValueRecord } from 'app/filters/types';
import { MatchStrings } from '../filters/constants';
import { userSchema } from '../users/types';
import { StoredFilterType } from './constants';

export const ViewTemplatesStateRecord = Record({
  isFetching: false,
  error: null,
  viewTemplates: Map(),
});

export class ViewTemplateRecord extends Record({
  id: null as string | null,
  name: '',
  isPublic: false,
  createdBy: '',
  filters: Set<FilterRecord>(),
  tags: Set(),
  sortBy: '',
  sortDirection: '',
  count: null as number | null,
  isCountStale: false,
}) {}

export class FilterRecord extends Record({
  name: '',
  value: '' as any,
  valueType: StoredFilterType.Boolean,
}) {}

export const templateSchema = new schema.Entity('viewTemplates', {
  createdBy: userSchema,
});

export const templatesSchema = new schema.Array(templateSchema);
