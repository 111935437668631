import { Map } from 'immutable';
import { isNil } from 'ramda';
import {
  VIEWTEMPLATE_APPLY,
  VIEWTEMPLATE_DELETE_SUCCESS,
} from 'app/viewtemplates/constants';
import {
  FILTERS_FETCH_START,
  FILTERS_FETCH_SUCCESS,
  FILTERS_FETCH_FAIL,
} from './constants';
import { FiltersStateRecord, SchemaRecord } from './types';

const initialState = new FiltersStateRecord();
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTERS_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case FILTERS_FETCH_SUCCESS: {
      const update = action.payload.filters.map((schema) => {
        const exists = state.filters.get(schema.id);

        if (isNil(exists)) {
          return [schema.id, new SchemaRecord(schema)];
        }

        return [schema.id, schema];
      });

      const newMap = Map(update);

      return state.mergeDeep({
        isFetching: false,
        filters: newMap,
      });
    }
    case FILTERS_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    default:
      return state;
  }
};

export default reducer;
