import { ListItem } from '@material-ui/core';
import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import ChipInput from 'app/filters/components/ChipInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import { createAutoCompleteOptionsQueryHandler } from 'app/filters/definitions/utility';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

const handler = createAutoCompleteOptionsQueryHandler(
  'excludeCarriers',
  'CARRIERS',
);
class ExcludeCarrierConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'excludeCarriers',
    title: 'Exclude Carrier',
    targetType: IssueFilterType.Claim,
    options: (token: string) => handler(token),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => (
      <ChipInput
        placeholder="Enter Carriers"
        noOptionsFoundComponent={<ListItem>Invalid carrier...</ListItem>}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.carrier)) return false;

      return !value.contains(workItem.claim.carrier);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExcludeCarrierConfig();
