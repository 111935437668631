import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { SearchResultRecord, SearchType } from 'app/search/types';
import { isErrored, isRunning } from 'app/ui/selectors';
import { search, clearSearchResult } from '../../actions';
import Search from '../../components/Search';
import { getSearchResults } from '../../selectors';

export type SearchContainerPropTypes = {
  isOpen: boolean;
  onClick: (result: SearchResultRecord) => void;
  onClose: () => void;
  searchTypes: SearchType[];
  suggestedResults?: List<SearchResultRecord>;
};

const SearchContainer = ({
  onClick,
  isOpen,
  onClose,
  searchTypes,
  suggestedResults = List(),
}: SearchContainerPropTypes) => {
  const dispatch = useDispatch();

  const statusAction = search('', []);
  const results = useSelector(getSearchResults);
  const isSearching = useSelector((state) => isRunning(state, statusAction));
  const error = useSelector((state) => isErrored(state, statusAction));

  const handleSearch = (phrase: string) => {
    dispatch(search(phrase, searchTypes));
  };
  const handleClear = () => dispatch(clearSearchResult());

  return (
    <Search
      onSearch={handleSearch}
      onClear={handleClear}
      searchTypes={searchTypes}
      suggestedResults={suggestedResults}
      results={results}
      isSearching={isSearching}
      error={error}
      isOpen={isOpen}
      onClick={onClick}
      onClose={onClose}
    />
  );
};

export default SearchContainer;
