import { Map } from 'immutable';
import { curry, map, pipe, prop, sort, descend, propOr, groupBy } from 'ramda';
import { mapToValuesArray } from '../common/utilities/generic';
import { getUser } from '../users/selectors';

const getCampaignsState = (state) => state.campaignsState;

const denormalizeCampaign = curry((state, campaign) => {
  return campaign?.merge({
    assignees: campaign.assignees.map((x) => getUser(state, x)),
    owner: getUser(state, campaign.owner),
  });
});
const getDenormalizedCampaigns = (state) =>
  pipe(
    getCampaignsState,
    prop('campaigns'),
    map(denormalizeCampaign(state)),
  )(state);

export const getCampaigns = pipe(getDenormalizedCampaigns, (x) => x.toSet());
export const getLatestCampaigns = pipe(
  getDenormalizedCampaigns,
  mapToValuesArray,
  groupBy(prop('entityId')),
  map(pipe(sort(descend(prop('version'))), propOr(null, 0))),
  (x) => new Map(x),
);

export const getCampaignById = (state, id) =>
  getDenormalizedCampaigns(state).get(id);

export const getCampaignByEntityId = (state, entityId) =>
  getLatestCampaigns(state).get(entityId);
