import { isNil } from 'ramda';
import { mapFiltersForCommit } from 'app/filters/utility';
import { CampaignRecord } from './types';

export function mapCampaignForCommit(campaign: any) {
  const update = { ...campaign };
  if (!isNil(campaign.issueFilter?.filters)) {
    const filters = mapFiltersForCommit(campaign.issueFilter.filters);
    update.issueFilter = { ...campaign.issueFilter, filters };
  }
  return update;
}
