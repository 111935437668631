import { List, OrderedSet, Set } from 'immutable';
import { prop, pipe, map } from 'ramda';
import { getClaim } from 'app/claims/selectors';
import { getPharmacy } from 'app/pharmacies/selectors';
import { getPrescriber } from 'app/prescribers/selectors';
import { graphqlSelector } from '../common/hooks/useQuery';
import { toArray } from '../common/utilities/generic';
import graphql from '../common/utilities/graphql';
import { getPatient } from '../patient/selectors';
import { SearchResultRecord, SearchType } from './types';

const getSearchState = prop('searchState');
const getClaimIds = pipe(getSearchState, prop('claims'));
const getPatientIds = pipe(getSearchState, prop('patients'));
const getCaseIds = pipe(getSearchState, prop('cases'));
const getPrescriberIds = pipe(getSearchState, prop('prescribers'));
const getPharmacyIds = pipe(getSearchState, prop('pharmacies'));

export const convertClaimToSearchResult = (x) =>
  new SearchResultRecord({
    type: SearchType.Claims,
    id: x.id,
    // TODO is entityId: x.workItem indicative of need for aunique type
    // per search result type so we can pass along more info?
    entityId: x.workItem,
    primaryText: x.claimNumber,
    secondaryText: x.patientId,
  });

export const convertCaseToSearchResult = (x) =>
  new SearchResultRecord({
    type: SearchType.Cases,
    id: x.id,
    entityId: x.entityId,
    primaryText: x.number,
    secondaryText: x.campaign?.name,
  });

const getClaimSearchResults = (state) => {
  const getClaimById = getClaim(state);
  return new OrderedSet(
    pipe(
      getClaimIds,
      toArray,
      map(pipe(getClaimById, convertClaimToSearchResult)),
    )(state),
  );
};

export const convertPatientToSearchResult = (x) =>
  new SearchResultRecord({
    type: SearchType.Patients,
    id: x.id,
    entityId: x.entityId,
    primaryText: x.patientId,
    secondaryText: x.name,
  });

export const convertPrescriberToSearchResult = (x) =>
  new SearchResultRecord({
    type: SearchType.Prescribers,
    id: x.id,
    entityId: x.entityId,
    primaryText: x.npi,
    secondaryText: x.name,
  });

export const convertPharmacyToSearchResult = (x) =>
  new SearchResultRecord({
    type: SearchType.Pharmacies,
    id: x.id,
    entityId: x.entityId,
    primaryText: x.npi,
    secondaryText: x.name,
  });

const getPatientSearchResults = (state) =>
  pipe(
    getPatientIds,
    map(getPatient(state)),
    map(convertPatientToSearchResult),
  )(state);
const getPrescriberSearchResults = (state) =>
  pipe(
    getPrescriberIds,
    map(getPrescriber(state)),
    map(convertPrescriberToSearchResult),
  )(state);
const getPharmacySearchResults = (state) =>
  pipe(
    getPharmacyIds,
    map(getPharmacy(state)),
    map(convertPharmacyToSearchResult),
  )(state);

const getCaseSearchResults = (state) => {
  const { cases } = graphqlSelector(
    state,
    graphql`
      query cases($ids: [ID]!) {
        cases(ids: $ids) {
          id
          number
          campaign {
            name
          }
        }
      }
    `,
    { ids: getCaseIds(state) },
  );

  return new Set(map(convertCaseToSearchResult)(cases));
};

export const getSearchResults = (state) => {
  return List().withMutations((results) =>
    results
      .concat(getPatientSearchResults(state))
      .concat(getCaseSearchResults(state))
      .concat(getClaimSearchResults(state))
      .concat(getPrescriberSearchResults(state))
      .concat(getPharmacySearchResults(state)),
  );
};
