import { parseISO } from 'date-fns';
import { Map, Set } from 'immutable';
import { isNil } from 'ramda';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../../cases/constants';
import { COMMENT_ADD_SUCCESS } from '../../comments/constants';
import { OPIOID_DETAIL_FETCH_SUCCESS } from '../../patient/constants';
import { QUEUE_FETCH_NEXT_SESSION_SUCCESS } from '../../queue/constants';
import { SEARCH_SUCCESS } from '../../search/constants';
import {
  WORKITEMS_QUERY_FETCH_SUCCESS,
  WORKITEM_DETAILS_FETCH_SUCCESS,
  WORKITEM_UPSERT_SUCCESS,
} from '../constants';
import { WorkItemRecord } from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case WORKITEM_UPSERT_SUCCESS: {
      const workItems = new Map(action.payload.data.entities.workItems);
      return state.merge(
        workItems.map((item) => {
          const existingItem =
            state.get(item.id)?.merge(item) ?? new WorkItemRecord(item);

          return existingItem.merge({
            dateCreated:
              item.dateCreated !== undefined
                ? parseISO(item.dateCreated)
                : existingItem.dateCreated,
          });
        }),
      );
    }
    case SEARCH_SUCCESS:
    case WORKITEMS_QUERY_FETCH_SUCCESS:
    case OPIOID_DETAIL_FETCH_SUCCESS:
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case WORKITEM_DETAILS_FETCH_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS: {
      const workItems = new Map(action.payload.data.entities.workItems);
      return state.merge(
        workItems.map((item) => {
          let existingItem = state.get(item.id);
          if (existingItem) {
            existingItem = existingItem.merge({
              ...item,
              comments:
                item.comments !== undefined
                  ? new Set(item.comments)
                  : existingItem.comments,
            });
          } else {
            existingItem = new WorkItemRecord({
              ...item,
              comments: new Set(item.comments),
            });
          }

          if (!isNil(item.dateCreated)) {
            existingItem = existingItem.merge({
              dateCreated: parseISO(item.dateCreated),
            });
          }

          return existingItem;
        }),
      );
    }
    case COMMENT_ADD_SUCCESS: {
      const { data } = action.payload;
      const workItemId = data.result;
      const existingItem = state.get(workItemId);

      if (existingItem) {
        const comments = data.entities.workItems[workItemId].comments;
        return state.merge({
          [workItemId]: existingItem.mergeDeep({
            comments: new Set(comments),
          }),
        });
      }

      return state;
    }
    default:
      return state;
  }
};

export default reducer;
