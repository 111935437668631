import { combineReducers } from 'redux-immutable';
import { BestPracticeStateRecord } from '../types';
import bestPractices from './bestpractices';
import categories from './categories';
import rules from './rules';

const reducer = combineReducers(
  {
    categories,
    bestPractices,
    rules,
  },
  BestPracticeStateRecord,
);

export default reducer;
