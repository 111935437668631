import { Map, Record, Set } from 'immutable';
import { schema } from 'normalizr';
import { CaseRecord, caseSchema } from 'app/cases/types';
import { InteractionRecord, interactionSchema } from 'app/interactions/types';
import { userSchema } from 'app/users/types';
import { UserTypeRecord } from 'app/workitems/types';

export enum SessionStatus {
  Created = 'CREATED',
  Claimed = 'CLAIMED',
  Abandoned = 'ABANDONED',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED',
  TimedOut = 'TIMEDOUT',
}

export const activeStatuses = [SessionStatus.Claimed, SessionStatus.Created];

export class SessionRecord extends Record({
  id: undefined as string | undefined,
  status: undefined as SessionStatus | undefined,
  primaryCase: undefined as string | CaseRecord | undefined,
  interactions: Set<string>() as Set<string> | InteractionRecord[],
  secondaryCases: Set<string>() as Set<string> | Set<CaseRecord> | CaseRecord[],
  sessionStart: undefined as Date | undefined,
  sessionEnd: undefined as Date | undefined,
  user: undefined as string | UserTypeRecord | undefined,
  __typename: 'SessionType',
}) {}

export class SessionInputRecord extends Record({
  id: null as string | null,
  status: SessionStatus.Created,
  primaryCase: '',
}) {}

export class SessionStateRecord extends Record({
  session: Map<string, SessionRecord>(),
  activeSession: undefined as string | undefined,
}) {}

export const sessionSchema = new schema.Entity(
  'session',
  {
    primaryCase: caseSchema,
    secondaryCases: new schema.Array(caseSchema),
    interactions: new schema.Array(interactionSchema),
    user: userSchema,
  },
  {
    processStrategy: (value: any) => ({
      ...value,
      interactions:
        value.interactions?.map((x: any) => ({
          ...x,
          caseSession: value.id,
        })) ?? [],
    }),
  },
);

interactionSchema?.define({
  caseSession: sessionSchema,
});

caseSchema?.define({
  caseSessions: new schema.Array(sessionSchema),
});
