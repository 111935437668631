import { subDays } from 'date-fns';
import { Interval } from '../common/types';

export const BESTPRACTICES_OVERVIEW_FETCH_START =
  'BESTPRACTICES/BESTPRACTICE_OVERVIEW_FETCH_START';
export const BESTPRACTICES_OVERVIEW_FETCH_SUCCESS =
  'BESTPRACTICES/BESTPRACTICE_OVERVIEW_FETCH_SUCCESS';
export const BESTPRACTICES_OVERVIEW_FETCH_FAIL =
  'BESTPRACTICES/BESTPRACTICE_OVERVIEW_FETCH_FAIL';

export const BESTPRACTICES_CATEGORY_FETCH_START =
  'BESTPRACTICES/BESTPRACTICE_CATEGORY_FETCH_START';

export const BESTPRACTICES_CATEGORY_FETCH_SUCCESS =
  'BESTPRACTICES/BESTPRACTICE_CATEGORY_FETCH_SUCCESS';

export const BESTPRACTICES_CATEGORY_FETCH_FAIL =
  'BESTPRACTICES/BESTPRACTICE_CATEGORY_FETCH_FAIL';

export const BESTPRACTICES_CATEGORY_UPDATE_DATE =
  'BESTPRACTICES/BESTPRACTICE_CATEGORY_UPDATE_DATE';

export const defaultDateRange = new Interval({
  end: new Date(),
  start: subDays(new Date(), 30),
});

export const MODIFIER_TITLES = {
  'protected-class': 'Protected Class',
};

export const AGGREGATION_PERIODS = {
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
  LAST_7_DAYS: 'last 7 days',
  DAILY: 'daily',
  PROJECTED: 'projected',
};
