import { Step, StepLabel, Stepper, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { List } from 'immutable';
import {
  pipe,
  find,
  prop,
  flatten,
  isNil,
  isEmpty,
  any,
  filter,
  defaultTo,
  nth,
  cond,
  equals,
  always,
  is,
  isNotNil,
} from 'ramda';
import { useEffect, useState } from 'react';
import { CaseRecord } from 'app/cases/types';
import { GraphQlErrorRecord } from 'app/common/types';
import { debugLog } from 'app/common/utilities/generic';
import graphql from 'app/common/utilities/graphql';
import { useOptimisticMutation, useQuery } from 'app/common/utilities/hooks';
import {
  InteractionRecord,
  InteractionResponses,
  InteractionStatus,
} from 'app/interactions/types';
import { getContactTypeFromInteraction } from 'app/interactions/utility';
import { PatientRecord } from 'app/patient/types';
import { PharmacyRecord } from 'app/pharmacies/types';
import { PrescriberRecord } from 'app/prescribers/types';
import { OutreachStatus } from 'app/queue/types';
import CaseSelector from 'app/session/components/CaseSelector/CaseSelector';
import CreateInteraction from 'app/session/components/OutreachSession/CreateInteraction/CreateInteraction';
import LoadingOutreachSession from 'app/session/components/OutreachSession/LoadingOutreachSession';
import NoInteraction from 'app/session/components/OutreachSession/NoInteraction/NoInteraction';
import RecordInteraction from 'app/session/components/OutreachSession/RecordInteraction/RecordInteraction';
import StartInteraction from 'app/session/components/OutreachSession/StartInteraction/StartInteraction';
import { SessionRecord } from 'app/session/types';
import { sessionWorkHasBeenStarted } from 'app/session/utility';
import {
  fetchInteractionFieldDefinitions,
  saveInteraction,
} from '../../../interactions/actions';
import {
  CASE_LEVEL_RESPONSE,
  INTERACTION_LEVEL_RESPONSE,
} from '../../../interactions/constants';
import styles from './OutreachSession.css';

const nextStatus = cond([
  [
    equals(OutreachStatus.NoInteraction),
    always(OutreachStatus.CreateInteraction),
  ],
  [
    equals(OutreachStatus.CreateInteraction),
    always(OutreachStatus.StartInteraction),
  ],
  [
    equals(OutreachStatus.StartInteraction),
    always(OutreachStatus.RecordInteraction),
  ],
  [
    equals(OutreachStatus.RecordInteraction),
    always(OutreachStatus.NoInteraction),
  ],
]);

const filterInProgressInteractions = filter<InteractionRecord>(
  (interaction) =>
    interaction.status === InteractionStatus.InProgress ||
    interaction.status === InteractionStatus.Created,
);

const getOutreachStatusFromSession = (session: SessionRecord) => {
  if (isNil(session)) return OutreachStatus.NoInteraction;
  if (!sessionWorkHasBeenStarted(session)) return OutreachStatus.NoInteraction;

  const inProgress = filterInProgressInteractions(
    session.interactions as InteractionRecord[],
  );

  if (isEmpty(inProgress)) return OutreachStatus.NoInteraction;

  const interactionResponses = any(
    (x: any) => x.level === INTERACTION_LEVEL_RESPONSE,
    inProgress[0].responses as InteractionResponses[],
  );

  const caseResponses = any(
    (x: any) => x.level === CASE_LEVEL_RESPONSE,
    inProgress[0].responses as InteractionResponses[],
  );

  if (interactionResponses === false && caseResponses === false)
    return OutreachStatus.CreateInteraction;
  if (caseResponses === false) return OutreachStatus.StartInteraction;
  if (caseResponses) return OutreachStatus.RecordInteraction;
};

type OutreachSessionProps = {
  session: SessionRecord;
  activeCase: string;
  onChangeActiveCase: (caseItem: CaseRecord) => void;
  onComplete: () => void;
  onCancel: () => void;
  cancelText: string;
  skipable: boolean;
  isCompleting: boolean;
  isCanceling: boolean;
  isLoading: boolean;
  hasFetched: boolean;
  isSavingErrored: boolean;
  savingErrors: string[];
};

const OutreachSession = ({
  session,
  activeCase: activeCaseId,
  onChangeActiveCase,
  onComplete,
  onCancel,
  cancelText,
  skipable,
  isCompleting,
  isCanceling,
  isLoading,
  hasFetched,
  isSavingErrored,
  savingErrors,
}: OutreachSessionProps) => {
  const interaction: InteractionRecord = pipe(
    defaultTo([]) as any,
    filterInProgressInteractions as any,
    nth(0) as any,
  )(session?.interactions as InteractionRecord[]) as InteractionRecord;

  const secondaryCases: CaseRecord[] = interaction?.cases
    ? (interaction.cases as CaseRecord[]).filter(
        (x) => x.id !== (session.primaryCase as CaseRecord).id,
      )
    : [];

  const suggestedCases: CaseRecord[] = interaction?.suggestedCases
    ? (interaction.suggestedCases as CaseRecord[])
    : [];

  const [status, setStatus] = useState(getOutreachStatusFromSession(session));
  const [isContinuing, setIsContinuing] = useState(false);
  const [isCancelingInteraction, setIsCancelingInteraction] = useState(false);
  const [contactType, setContactType] = useState(
    getContactTypeFromInteraction(interaction),
  );

  const {
    query: dispatchFieldDefinitionQuery,
    isRunning: isFetchingFieldDefinitions,
    isErrored: errorFetchingInteractionDetails,
  } = useQuery(
    fetchInteractionFieldDefinitions,
    graphql`
      ## A bit weird since we just want error state
      query {
        __typename
      }
    `,
    {},
  );

  const {
    mutation: saveInteractionMutation,
    isErrored: isSaveInteractionErrored,
    isRunning: isSaveInteractionRunning,
    hasCompleted: hasSaveInteractionCompleted,
  } = useOptimisticMutation(saveInteraction);

  const findActiveCase = (): CaseRecord | null => {
    if (
      interaction &&
      interaction.suggestedCases &&
      (interaction.suggestedCases as CaseRecord[]).length
    ) {
      const caseIndex = (interaction.suggestedCases as CaseRecord[]).findIndex(
        (x) => x.id === activeCaseId,
      );
      if (caseIndex > -1) {
        return (interaction.suggestedCases as CaseRecord[])[caseIndex];
      }
    }
    if (
      interaction &&
      interaction.cases &&
      (interaction.cases as CaseRecord[]).length > 0
    ) {
      const caseIndex = (interaction.cases as CaseRecord[]).findIndex(
        (x) => x.id === activeCaseId,
      );
      if (caseIndex > -1) {
        return (interaction.cases as CaseRecord[])[caseIndex];
      }
    }
    if (session && session.primaryCase) {
      return session.primaryCase as CaseRecord;
    }
    return null;
  };

  // Leaving this search in
  const activeCase = !isLoading && hasFetched ? findActiveCase() : null;

  const handleCancelInteraction = () => {
    if (!isNil(interaction.id)) {
      saveInteractionMutation(interaction.id, {
        ...interaction,
        contact: (
          interaction.contact as
            | PatientRecord
            | PrescriberRecord
            | PharmacyRecord
        ).id,
        cases: (interaction.cases as CaseRecord[]).map((x) => x.id) as string[],
        status: InteractionStatus.Cancelled,
        caseSession: session.id,
      });
      setIsCancelingInteraction(true);
      setStatus(OutreachStatus.NoInteraction);
    }
  };

  const handleOnContinue = () => {
    setIsContinuing(true);
  };

  const handleCreateInteraction = () => {
    const newInteraction = new InteractionRecord({
      status: InteractionStatus.InProgress,
      caseSession: session.id,
      contact: (
        (session.primaryCase as CaseRecord).contact as
          | PatientRecord
          | PharmacyRecord
          | PrescriberRecord
      ).id as string,
      dateContacted: new Date(),
      cases: [(session.primaryCase as CaseRecord).id as string],
    });

    saveInteractionMutation(null, newInteraction);
    setContactType(contactType);
    setIsContinuing(true);
  };

  const handleAddCaseToInteraction = (newCase: CaseRecord) => {
    const caseIds = (interaction.cases as CaseRecord[]).map(
      (caseItem) => caseItem.id,
    ) as string[];
    caseIds.push(newCase.id as string);
    const newInteraction = new InteractionRecord({
      ...interaction,
      caseSession: session.id,
      cases: caseIds,
      contact: (
        interaction.contact as PatientRecord | PharmacyRecord | PrescriberRecord
      ).id as string,
    });
    saveInteractionMutation(interaction.id, newInteraction);
  };

  const handleRemoveCaseFromInteraction = (newCase: CaseRecord) => {
    const newInteraction = new InteractionRecord({
      ...interaction,
      caseSession: session.id,
      cases: (interaction.cases as CaseRecord[])
        .filter(
          (caseItem) =>
            caseItem.id !== newCase.id ||
            caseItem.id === (session.primaryCase as CaseRecord).id,
        )
        .map((caseItem) => caseItem.id) as string[],
      contact: (
        interaction.contact as PatientRecord | PharmacyRecord | PrescriberRecord
      ).id as string,
    });
    saveInteractionMutation(interaction.id, newInteraction);
  };

  const handleOnContactChange = (i: any) => {
    const newInteraction = {
      ...i,
      caseSession: session.id,
      cases: i.cases.map((x: any) => x.id),
      contact: i.contact,
      status: InteractionStatus.InProgress,
    };

    saveInteractionMutation(newInteraction.id, newInteraction);
  };

  const handleOnChange = (i: any) => {
    const newInteraction = {
      ...i,
      caseSession: session.id,
      cases: i.cases.map((x: any) => x.id),
      contact: i.contact.id,
      status: InteractionStatus.InProgress,
    };

    saveInteractionMutation(newInteraction.id, newInteraction);
  };

  const OnCreateInteractionComplete = (i: any) => {
    saveInteractionMutation(i.id, i);
    setIsContinuing(true);
  };

  const handleOnCompleteInteraction = () => {
    const newInteraction = new InteractionRecord({
      ...interaction,
      caseSession: session.id,
      cases: (interaction.cases as CaseRecord[]).map((x) => x.id) as string[],
      contact: (
        interaction.contact as PatientRecord | PrescriberRecord | PharmacyRecord
      ).id as string,
      status: InteractionStatus.Completed,
    });

    saveInteractionMutation(newInteraction.id, newInteraction);
    setIsContinuing(true);
  };

  useEffect(() => {
    setStatus(getOutreachStatusFromSession(session));
  }, [session?.id]);

  useEffect(() => {
    const newContactType = getContactTypeFromInteraction(interaction);
    if (isNotNil(newContactType) && contactType !== newContactType) {
      setContactType(newContactType);
    }
    if (isNotNil(newContactType)) {
      dispatchFieldDefinitionQuery(newContactType, activeCaseId);
    }
  }, [getContactTypeFromInteraction(interaction)]);

  useEffect(() => {
    if (isSaveInteractionErrored) {
      setIsContinuing(false);
      setIsCancelingInteraction(false);
    } else if (!isSaveInteractionRunning) {
      if (isContinuing) {
        setStatus(nextStatus(status as OutreachStatus));
        setIsContinuing(false);
      } else if (isCancelingInteraction) {
        setStatus(getOutreachStatusFromSession(session));
        setIsCancelingInteraction(false);
      }
    }
  }, [
    isSaveInteractionErrored,
    isSaveInteractionRunning,
    isContinuing,
    isCancelingInteraction,
  ]);

  if ((isLoading || !hasFetched) && !isSavingErrored) {
    return <LoadingOutreachSession />;
  }

  if (!hasFetched && isSavingErrored) {
    let errorMessage =
      'Unexpected error occured while creating session. Please try again.';

    if (
      savingErrors.length === 1 &&
      is(GraphQlErrorRecord, savingErrors[0]) &&
      savingErrors[0].path.isEmpty() &&
      !isNil(savingErrors[0].message)
    ) {
      errorMessage = savingErrors[0].message;
    }

    return (
      <div className={styles.container}>
        <div className={styles.interactionErrors}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
          {!skipable && (
            <Button
              className={styles.errorCloseBtn}
              variant="outlined"
              onClick={onCancel}
            >
              Close
            </Button>
          )}
        </div>
      </div>
    );
  }

  const checkForClosedInteractions = () => {
    return (session.interactions as InteractionRecord[]).some((x) => {
      return x.status === InteractionStatus.Completed;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.caseSelectorContainer}>
        <CaseSelector
          session={session}
          activeCase={activeCaseId}
          secondaryCases={secondaryCases}
          suggestedCases={suggestedCases}
          isRunning={isSaveInteractionRunning}
          onChange={onChangeActiveCase}
          addCase={handleAddCaseToInteraction}
          removeCase={handleRemoveCaseFromInteraction}
        />
      </div>
      <div className={styles.interactionContainer}>
        <Stepper
          activeStep={status}
          alternativeLabel
          classes={{ root: styles.progressStepper }}
        >
          <Step key={OutreachStatus.NoInteraction}>
            <StepLabel />
          </Step>
          <Step key={OutreachStatus.CreateInteraction}>
            <StepLabel />
          </Step>
          <Step key={OutreachStatus.StartInteraction}>
            <StepLabel />
          </Step>
          <Step key={OutreachStatus.RecordInteraction}>
            <StepLabel />
          </Step>
        </Stepper>
        {status === OutreachStatus.NoInteraction && (
          <NoInteraction
            isCanceling={isCanceling}
            isCompleting={isCompleting}
            isRunning={isSaveInteractionRunning}
            createInteraction={handleCreateInteraction}
            onSkip={onCancel}
            onComplete={onComplete}
            skipable={skipable}
            sessionHasInteraction={checkForClosedInteractions()}
          />
        )}
        {status === OutreachStatus.CreateInteraction && (
          <CreateInteraction
            session={session}
            interaction={interaction}
            onInteractionChange={handleOnContactChange}
            onInteractionCreateComplete={OnCreateInteractionComplete}
            isCreatingInteraction={isContinuing}
            isCanceling={isCanceling}
            isRunning={isSaveInteractionRunning}
            onCancel={handleCancelInteraction}
            cancelText={cancelText}
            setStatus={setStatus}
            isErrored={isSaveInteractionErrored}
            isCancelingErrored={isSavingErrored}
          />
        )}
        {status === OutreachStatus.RecordInteraction && (
          <RecordInteraction
            activeCase={activeCaseId}
            isErrored={isSaveInteractionErrored}
            interaction={interaction}
            onChange={handleOnChange}
            onChangeActiveCase={onChangeActiveCase}
            onComplete={handleOnCompleteInteraction}
            isCompleting={isContinuing}
            isRunning={isSaveInteractionRunning}
            setStatus={setStatus}
            isCanceling={isCancelingInteraction}
          />
        )}
        {status === OutreachStatus.StartInteraction && (
          <StartInteraction
            activeCase={activeCaseId}
            isErrored={isSaveInteractionErrored}
            interaction={interaction}
            onChange={handleOnChange}
            onChangeActiveCase={onChangeActiveCase}
            onCancel={handleCancelInteraction}
            onContinue={handleOnContinue}
            isCompleting={isContinuing}
            isCanceling={isCancelingInteraction}
            isRunning={isSaveInteractionRunning}
          />
        )}
      </div>
    </div>
  );
};

export default OutreachSession;
