import { Map } from 'immutable';
import { isNil } from 'ramda';

const includeActions = ['_FETCH', '_UPSERT', '_DELETE'];

const reducer = (state = new Map(), action) => {
  const matchResult = includeActions.filter((x) => action.type.indexOf(x) >= 0);
  if (
    matchResult.length === 1 &&
    isNil(action.payload.optimisticId) === false &&
    isNil(action.payload.id) === false
  ) {
    return state.set(action.payload.optimisticId, action.payload.id);
  }
  return state;
};

export default reducer;
