import { subMonths, areIntervalsOverlapping, maxTime, minTime } from 'date-fns';
import { prop, pipe, curry, isNil } from 'ramda';
import { getClaimsById } from 'app/claims/selectors';
import filterDefinitions from 'app/filters/definitions';
import { getWorkItem } from 'app/workitems/selectors';
import { getCasesByIds } from '../cases/selectors';
import { getViewTemplates } from '../viewtemplates/selectors';
import { getFilterFunctionForTemplate } from '../viewtemplates/utility';
import { PatientRecord, OpioidIssueRecord } from './types';

const getPatientsState = prop('patientsState');

export const getPatients = pipe(getPatientsState, prop('patients'));

export const getPatientClaims = curry((state, id) => {
  const claimIds = (getPatients(state).get(id) || new PatientRecord()).claims;
  return getClaimsById(state, claimIds);
});

const getPatientWorkItems = curry((state, id) => {
  const claimIds = (getPatients(state).get(id) || new PatientRecord()).claims;
  return getClaimsById(state, claimIds)
    .map((x) => getWorkItem(state, x?.workItem))
    .filter((x) => x !== null);
});

export const getPatient = curry((state, id) => {
  return getPatients(state).get(id) || new PatientRecord();
});

export const getDrugOverviewClaims = (state, id) => {
  return getPatientClaims(state, id).filter(
    (x) =>
      x.claimStatus === 'Paid' &&
      !isNil(x.gpi) &&
      !isNil(x.prescriptionEnd) &&
      new Date(x.prescriptionEnd) >= subMonths(new Date(), 12) &&
      x.isSecondaryPayment !== true,
  );
};

export const getOpioidOverviewClaims = (state, id) => {
  const earliestDate = subMonths(new Date(), 12);
  return getPatientClaims(state, id).filter(
    (x) =>
      !isNil(x.gpi) &&
      (x.drugContainsOpioid || x.isDrugOpioidCombinator) &&
      ((x.claimStatus === 'Paid' &&
        !isNil(x.prescriptionEnd) &&
        new Date(x.prescriptionEnd) >= earliestDate) ||
        (x.claimStatus === 'Rejected' &&
          !isNil(x.filledDate) &&
          new Date(x.filledDate) >= earliestDate)) &&
      x.isSecondaryPayment === false,
  );
};

export const getOpioidNaiveWindows = (state, id) =>
  getPatient(state, id)?.opioidNaiveWindows;

export const getOpioidIssues = (state, id) => {
  const claims = getPatientWorkItems(state, id);
  const rules = getViewTemplates(state)
    .filter((x) => x.tags.contains('opioid-issue'))
    .map((template) => {
      let text;
      let name;
      const tag = template.tags
        .intersect([
          'opioid-200-mme',
          'opioid-naive-high-supply',
          'opioid-multiple-prescribers',
          'opioid-naive-high-dosage',
          'opioid-combinator',
          'opioid-high-initial-30',
          'opioid-high-initial-60',
        ])
        .first();
      switch (tag) {
        case 'opioid-200-mme':
          text = (x) =>
            `${x.claim.patientTotalMorphineMilligramEquivalent.toFixed(
              0,
            )} Total MME`;
          name = 'Total MME Hard Edit Violations';
          break;
        case 'opioid-naive-high-supply':
          text = (x) => `${x.claim.daysSupply} Days Supply`;
          name = 'Opioid Naive Days Supply Violations';
          break;
        case 'opioid-multiple-prescribers':
          text = (x) =>
            `${
              x.claim.historicalOpioidPrescriberCount +
              x.claim.historicalOpioidPharmacyCount
            } Historical Providers`;
          name = 'Multiple Providers Warning';
          break;
        case 'opioid-naive-high-dosage':
          text = (x) =>
            `${x.claim.patientTotalMorphineMilligramEquivalent.toFixed(0)} MME`;
          name = 'Opioid Naive High Dosage Warning';
          break;
        case 'opioid-combinator':
          text = (x) => x.claim.drugName;
          name = 'Opioid Combinator Interaction Warning';
          break;
        case 'opioid-high-initial-30':
          text = (x) => `${x.claim.initialOpioidSupplyThirtyDays} Days of use`;
          name = 'Risk of Continued Opioid Use (30 Days)';
          break;
        case 'opioid-high-initial-60':
          text = (x) => `${x.claim.initialOpioidSupplySixtyDays} Days of use`;
          name = 'Risk of Continued Opioid Use (62 Days)';
          break;
        default:
          text = () => 'Unconfigured issue template';
          name = template.name;
          break;
      }
      return {
        template,
        name,
        filterFunction: getFilterFunctionForTemplate(
          template,
          filterDefinitions,
          '',
        ),
        text,
      };
    });

  return claims
    .flatMap((workItem) => {
      return rules
        .filter((rule) => rule.filterFunction(workItem))
        .valueSeq()
        .map(
          (rule) =>
            new OpioidIssueRecord({
              id: `${rule.template.id}-${workItem.id}`,
              rule: rule.name,
              text: rule.text(workItem),
              primaryClaim: workItem,
              claims: claims.filter(
                (y) =>
                  (y.claim.drugContainsOpioid ||
                    y.claim.isDrugOpioidCombinator) &&
                  // TODO:  Update this, seems very hacky, but when going from workitem to patient, these fields aren't filled out yet
                  // so we cannot make this determination.
                  areIntervalsOverlapping(
                    {
                      start: workItem.claim.prescriptionStart ?? maxTime,
                      end: workItem.claim.prescriptionEnd ?? maxTime,
                    },
                    {
                      start: y.claim.prescriptionStart ?? minTime,
                      end: y.claim.prescriptionEnd ?? minTime,
                    },
                  ) &&
                  y.claim.isSecondaryPayment === false &&
                  y.claim.claimStatus === 'Paid' &&
                  y.id !== workItem.id,
              ),
            }),
        );
    })
    .filter(
      (issue) =>
        new Date(issue.primaryClaim.claim.prescriptionEnd) >=
        subMonths(new Date(), 12),
    );
};

export const getOpioidIssueCount = (state, id) => {
  return getOpioidIssues(state, id)
    .filter(
      (x) =>
        x.primaryClaim.status?.type === 'OPEN' ||
        x.primaryClaim.status?.type === 'INPROGRESS',
    )
    .count();
};

export const getOutstandingCaseCount = (state, id) => {
  const patient = getPatient(state, id);
  return getCasesByIds(state, patient.cases)
    .filter((x) => x.status === 'OPEN' || x.status === 'INPROGRESS')
    .count();
};
