import { ListItem, IconButton, Tooltip } from '@material-ui/core';
import { Edit, Error, Delete, Warning } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { Component, memo } from 'react';
import ContentLoader from 'react-content-loader';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Avatar from '../../../common/components/Avatar';
import Tag from '../../../common/components/Tag';
import styles from './ViewTemplateCard.css';

export class ViewTemplateCard extends Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isFetchingCount: PropTypes.bool.isRequired,
    viewTemplate: ImmutablePropTypes.recordOf({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isPublic: PropTypes.bool.isRequired,
      count: PropTypes.number,
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    isDeleteable: PropTypes.bool.isRequired,
    onDeleteRequest: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  countFormatter = (count) => (count > 999 ? '999+' : count);

  handleOnEdit = (event) => {
    const { onEdit, viewTemplate } = this.props;
    event.stopPropagation();
    event.preventDefault();
    onEdit(viewTemplate.id);
  };

  handleDeleteOnClick = (event) => {
    const { onDeleteRequest, viewTemplate } = this.props;
    event.stopPropagation();
    event.preventDefault();
    onDeleteRequest(viewTemplate.id);
  };

  handleOnSelect = () => {
    const { onSelect, viewTemplate, isSelected } = this.props;

    if (!isSelected) {
      onSelect(viewTemplate.id);
    }
  };

  render() {
    const {
      isSelected,
      isFetchingCount,
      viewTemplate,
      isEditable,
      isDeleteable,
      error,
    } = this.props;

    const isFetchingFirstTime = isNil(viewTemplate.count) && isFetchingCount;

    const isSystemTemplate = viewTemplate.tags.includes('system');

    return (
      <ListItem
        button={!isSelected}
        divider
        onClick={this.handleOnSelect}
        selected={isSelected}
        className={styles.listItem}
      >
        <div className={styles.tagPanel}>
          {isFetchingFirstTime && (
            <ContentLoader
              className={styles.countLoader}
              height={22.7}
              width={22.7}
              speed={2}
              backgroundColor="#e0e0e0"
              foregroundColor="#d6d6d6"
            >
              <rect
                key={`count-loader-${viewTemplate.id}`}
                x="0"
                y="0"
                rx="2"
                ry="2"
                width="22.7"
                height="22.7"
              />
            </ContentLoader>
          )}
          {!error && !isFetchingFirstTime && (
            <Tag
              className={classnames({
                [styles.green]: viewTemplate.count === 0,
                [styles.orange]: viewTemplate.count > 0,
                [styles.red]: viewTemplate.count > 100,
              })}
              text={this.countFormatter(viewTemplate.count)}
            />
          )}
          {!isFetchingCount && error && <Error style={{ fill: '#f72c2c' }} />}
          {/* TODO Update the query count grains to reduce the amount of false positives here */}
          {/* {!error && viewTemplate.isCountStale && (
            <Tooltip title="Counts may be inaccurate" orientation="top">
              <Warning className={styles.warnIcon} fontSize="small" />
            </Tooltip>
          )} */}
        </div>
        <div className={styles.contentPanel}>
          <div className={isSelected ? styles.selectedTitle : styles.title}>
            {viewTemplate.name}
          </div>
        </div>
        <div className={styles.tagPanel}>
          {isEditable && (
            <Tooltip title="Edit Template" placement="left">
              <IconButton onClick={this.handleOnEdit}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {isDeleteable && (
            <Tooltip title="Delete Template" placement="left">
              <IconButton onClick={this.handleDeleteOnClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {!viewTemplate.isPublic && (
            <Tooltip title="You own this template" placement="left">
              <Tag text="Private" />
            </Tooltip>
          )}
          {isSystemTemplate && (
            <Tooltip
              arrow
              title="This template is managed by Reveal"
              placement="left"
            >
              <Tag text="System" />
            </Tooltip>
          )}
          {viewTemplate.isPublic && !isSystemTemplate && (
            <Avatar
              classes={{ root: styles.avatar }}
              user={viewTemplate.createdBy}
            />
          )}
        </div>
      </ListItem>
    );
  }
}

export default memo(ViewTemplateCard);
