import { pipe, not } from 'ramda';
import { createSelector } from 'reselect';
import { IssueFilterType } from 'app/filters/constants';
import filterDefinitions from 'app/filters/definitions';
import { getAppliedFilters } from 'app/ui/selectors';

export const isFetching = (state) => state.exportState.isFetching;
export const canExport = pipe(isFetching, not);

export const getFilterQuery = createSelector(getAppliedFilters, (filters) => {
  const definitions = filterDefinitions.filter(
    (x) => x.targetType === IssueFilterType.Claim,
  );
  return filters.mapEntries((entry) => {
    const def = definitions.get(entry[0]);
    return [def.name, def.valueToQueryConverter(entry[1].value)];
  });
});
