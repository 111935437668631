import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,
  Button,
  Fab,
} from '@material-ui/core';
import { Add, FilterList } from '@material-ui/icons';
import { Link } from '@reach/router';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch, useSelector } from 'react-redux';
import { permissions } from 'app/auth/permissions';
import { fetchCampaignsStart } from 'app/campaigns/actions';
import { getLatestCampaigns } from 'app/campaigns/selectors';
import {
  applyCasesSort,
  clearCasesQuery,
  fetchCasesQuery,
  updateCaseFilter,
} from 'app/cases/actions';
import { getCaseList, getCasesFilters } from 'app/cases/selectors';
import { CasesFilterStateRecord } from 'app/cases/types';
import HeaderBar from 'app/common/components/HeaderBar/HeaderBar';
import Table from 'app/common/components/Table';
import UnexpectedError from 'app/common/components/UnexpectedError/UnexpectedError';
import ExportCaseButton from 'app/export/containers/CaseExport/CaseExportButton';
import { SelectValueRecord } from 'app/filters/types';
import {
  getCaseListContinuation,
  getCaseListSortBy,
  getCaseListSortDirection,
  hasCompleted,
  isErrored,
  isRunning,
} from 'app/ui/selectors';
import { fetchUsers } from 'app/users/actions';
import { getUsersWithPermission } from 'app/users/selectors';
import WorkItemStatus from 'app/workitems/components/WorkItemStatus/WorkItemStatus';
import { WorkItemStatusRecord } from 'app/workitems/types';
import styles from './CaseList.css';
import columnDefinitions from './columns';

// TODO may be able to abstract this via the multiselect component we use for work item filters?
const MultiSelectDropDown = ({
  label,
  id,
  options,
  value,
  onChange,
  optionRenderer,
}) => (
  <FormControl variant="outlined" className={styles.formControl}>
    <InputLabel id={id}>{label}</InputLabel>
    <Select
      multiple
      variant="outlined"
      labelId={id}
      label={label}
      value={value.toArray()}
      onChange={(event) => onChange(id, event.target.value)}
      renderValue={(v) => {
        return options
          .filter((x) => v.includes(x.value))
          .map((x) => x.text)
          .join(', ');
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          classes={{ gutters: styles.dropdownGutter }}
        >
          <Checkbox checked={value.includes(option.value)} color="primary" />
          {optionRenderer(option)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

MultiSelectDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: ImmutablePropTypes.setOf(
    ImmutablePropTypes.recordOf({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  value: ImmutablePropTypes.setOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  optionRenderer: PropTypes.func,
};

MultiSelectDropDown.defaultProps = {
  value: new Set(),
  optionRenderer: ({ text }) => <ListItemText primary={text} />,
};

const userToSelectValue = (user) =>
  new SelectValueRecord({
    text: user.name || user.email,
    value: user.id,
  });
const campaignToSelectValue = (campaign) =>
  new SelectValueRecord({
    text: campaign.name,
    value: campaign.entityId,
  });

const CaseList = ({
  cases,
  isLoading,
  assigneeOptions,
  onChange,
  filters,
  campaignOptions,
  statuses,
  onFetchNextPage,
  hasNextPage,
  errorFetchingCases,
  onSort,
  sortBy,
  sortDirection,
  onClearFilters,
}) => {
  const assignees = assigneeOptions
    .map(userToSelectValue)
    .toList()
    .sortBy((x) => x.text)
    .insert(
      0,
      new SelectValueRecord({
        text: 'Current User',
        value: 'CurrentUser',
      }),
    )
    .toSet();

  const campaigns = campaignOptions
    .map(campaignToSelectValue)
    .sortBy((option) => option.text.toUpperCase());

  return (
    <div className={styles.container}>
      <HeaderBar>
        <div className={styles.headerContainer}>
          <h1>Cases</h1>
          <FilterList className={styles.headerIcon} />
          <MultiSelectDropDown
            label="Assignees"
            id="assignees"
            value={filters.assignees}
            options={assignees}
            onChange={onChange}
          />
          <MultiSelectDropDown
            label="Campaigns"
            id="campaigns"
            value={filters.campaigns}
            options={campaigns}
            onChange={onChange}
          />
          <MultiSelectDropDown
            label="Status"
            id="statuses"
            value={filters.statuses}
            options={statuses}
            onChange={onChange}
            optionRenderer={(option) => (
              <WorkItemStatus
                bold
                status={
                  new WorkItemStatusRecord({
                    name: option.text,
                    type: option.value,
                  })
                }
              />
            )}
          />
          {(!filters.assignees.isEmpty() ||
            !filters.campaigns.isEmpty() ||
            !filters.statuses.isEmpty()) && (
            <Button
              classes={{ root: styles.clearButton }}
              onClick={onClearFilters}
            >
              Clear Filters
            </Button>
          )}
        </div>
        <div className={styles.headerRight}>
          <ExportCaseButton />
        </div>
      </HeaderBar>
      <div className={styles.table}>
        {!errorFetchingCases && (
          <Table
            data={cases}
            isFetching={isLoading}
            columnDefinitions={columnDefinitions}
            loadMoreRows={onFetchNextPage}
            hasNextPage={hasNextPage}
            isSortEnabled
            onSort={onSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
        )}
        {errorFetchingCases && <UnexpectedError />}
      </div>
      <Link to="/issues">
        <Fab
          color="primary"
          classes={{ root: styles.create }}
          variant="extended"
        >
          <div className={styles.fabContainer}>
            <Add />
            Create Case
          </div>
        </Fab>
      </Link>
      <Link to="/uploadcases">
        <Fab
          color="primary"
          classes={{ root: styles.bulkcreate }}
          variant="extended"
        >
          <div className={styles.fabContainer}>
            <Add />
            Bulk Create Cases
          </div>
        </Fab>
      </Link>
    </div>
  );
};

CaseList.propTypes = {
  cases: ImmutablePropTypes.setOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  assigneeOptions: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  onChange: PropTypes.func,
  filters: ImmutablePropTypes.recordOf({
    assignees: ImmutablePropTypes.setOf(PropTypes.string),
    campaigns: ImmutablePropTypes.setOf(PropTypes.string),
  }),
  campaignOptions: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  statuses: ImmutablePropTypes.setOf(ImmutablePropTypes.record).isRequired,
  onFetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  errorFetchingCases: PropTypes.bool,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['ASC', 'DESC']),
  onClearFilters: PropTypes.func.isRequired,
};

CaseList.defaultProps = {
  cases: new Set(),
  assigneeOptions: new Set(),
  onChange: () => {},
  filters: new CasesFilterStateRecord(),
  campaignOptions: new Set(),
  onFetchNextPage: () => {},
  hasNextPage: false,
  errorFetchingCases: false,
  onSort: () => {},
  sortBy: 'DateOpened',
  sortDirection: 'DESC',
};

const CaseListContainer = () => {
  const dispatch = useDispatch();
  const cases = useSelector((state) => getCaseList(state));
  const continuationToken = useSelector((state) =>
    getCaseListContinuation(state),
  );
  const isLoading = useSelector((state) => isRunning(state, fetchCasesQuery()));
  const hasLoaded = useSelector((state) =>
    hasCompleted(state, fetchCasesQuery()),
  );
  const assignees = useSelector((state) =>
    getUsersWithPermission(state, permissions.CAMPAIGN_ASSIGNEE),
  );
  const filters = useSelector((state) => getCasesFilters(state));
  const campaigns = useSelector((state) => getLatestCampaigns(state));
  const statuses = new Set([
    new SelectValueRecord({
      text: 'Open',
      value: 'OPEN',
    }),
    new SelectValueRecord({
      text: 'In Progress',
      value: 'INPROGRESS',
    }),
    new SelectValueRecord({
      text: 'Done',
      value: 'DONE',
    }),
  ]);
  const errorFetchingCases = useSelector((state) =>
    isErrored(state, fetchCasesQuery()),
  );
  const sortBy = useSelector((state) => getCaseListSortBy(state));
  const sortDirection = useSelector((state) => getCaseListSortDirection(state));

  const handleFilterChange = (name, value) => {
    dispatch(updateCaseFilter(filters.merge({ [name]: value })));
  };

  const handleFetchNextPage = () => {
    dispatch(
      fetchCasesQuery(filters, sortBy, sortDirection, continuationToken),
    );
  };

  const handleOnSort = ({ sortBy, sortDirection }) => {
    dispatch(applyCasesSort(sortBy, sortDirection));
  };

  const handleClearFilters = () => {
    dispatch(updateCaseFilter(new CasesFilterStateRecord()));
  };

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCampaignsStart());
  }, []);

  useEffect(() => {
    dispatch(clearCasesQuery());
    dispatch(fetchCasesQuery(filters, sortBy, sortDirection, null));
  }, [filters, sortBy, sortDirection]);

  return (
    <CaseList
      cases={cases}
      isLoading={isLoading}
      assigneeOptions={assignees}
      campaignOptions={campaigns.toSet()}
      onChange={handleFilterChange}
      filters={filters}
      onFetchNextPage={handleFetchNextPage}
      hasNextPage={!isNil(continuationToken)}
      errorFetchingCases={errorFetchingCases}
      statuses={statuses}
      onSort={handleOnSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onClearFilters={handleClearFilters}
    />
  );
};

CaseListContainer.propTypes = {};
CaseListContainer.defaultProps = {};

export default CaseListContainer;
