import { Link } from '@reach/router';
import classnames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import Tag from '../../../common/components/Tag';
import styles from './columns.css';

const formatDate = (date) => format(date, 'MM/dd/yyyy');

const columnDefinitions = [
  {
    id: 'ClaimNumber',
    name: 'Claim Number',
    width: 3,
    valueSelector: (workItem) => {
      // If claim numbers are integers, sort them like integers
      // Otherwise, sort them like strings
      const parsed = parseInt(workItem.claim.claimNumber, 10);
      if (Number.isNaN(parsed)) return workItem.claim.claimNumber;
      return parsed;
    },
    render: (workItem) => (
      <Link to={workItem.id}>{workItem.claim.claimNumber}</Link>
    ),
    placeholderWidth: 0.7,
  },
  {
    id: 'AdjudicatedDate',
    name: 'Adjudication Date',
    width: 2,
    valueSelector: (workItem) => workItem.claim.adjudicatedDate,
    render: (workItem) => formatDate(workItem.claim.adjudicatedDate),
    placeholderWidth: 0.4,
  },
  {
    id: 'ClaimStatus',
    name: 'Status',
    width: 1.5,
    valueSelector: (workItem) => workItem.claim.claimStatus,
    render: (workItem) => workItem.claim.claimStatus,
    placeholderWidth: 0.5,
  },
  {
    id: 'PatientId',
    name: 'Patient',
    width: 2,
    valueSelector: (workItem) => workItem.claim.patientId,
    render: (workItem) => workItem.claim.patientId,
    placeholderWidth: 0.7,
  },
  {
    id: 'RejectCodes',
    name: 'Reject Codes',
    width: 2,
    valueSelector: (workItem) => workItem.claim.rejectCodes.join(' '),
    render: (workItem) => workItem.claim.rejectCodes.join(', '),
    placeholderWidth: 0.3,
  },
  {
    id: 'RejectMessages',
    name: 'Primary Message',
    width: 5,
    valueSelector: (workItem) => workItem.claim.rejectMessages.first(),
    render: (workItem) => workItem.claim.rejectMessages.first(),
    placeholderWidth: 0.6,
  },
  {
    id: 'DrugName',
    name: 'Drug Name',
    width: 5,
    valueSelector: (workItem) => (workItem.claim.drugName || '').toUpperCase(),
    render: (workItem) => workItem.claim.drugName,
    placeholderWidth: 0.6,
  },
  {
    id: 'Assignee',
    name: 'Assignee',
    width: 2,
    valueSelector: (workItem) =>
      ((workItem.assignee && workItem.assignee.name) || '').toUpperCase(),
    render: (workItem) =>
      workItem.assignee ? workItem.assignee.name : 'Unassigned',
    placeholderWidth: 0.7,
  },
  {
    id: 'WorkItemStatus',
    name: 'Work Item Status',
    width: 2,
    valueSelector: (workItem) => workItem.status.id,
    render: (workItem) =>
      workItem.status && (
        <Tag
          className={classnames({
            [styles.tag]: true,
            [styles.blue]: workItem.status.type === 'OPEN',
            [styles.orange]: workItem.status.type === 'INPROGRESS',
            [styles.green]: workItem.status.type === 'DONE',
          })}
          text={workItem.status.name}
        />
      ),
    placeholderWidth: 0.5,
  },
];

export default columnDefinitions;
