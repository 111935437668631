import { Link } from '@reach/router';
import classnames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import Tag from '../../../common/components/Tag';
import styles from './columns.css';

const formatDate = (date) => format(date, 'MM/dd/yyyy');

const columnDefinitions = [
  {
    id: 'ClaimNumber',
    name: 'Claim Number',
    render: (claim) => (
      <Link to={`/workitems/${claim.workItem}`}>{claim.claimNumber}</Link>
    ),
    width: 1,
  },
  {
    id: 'FilledDate',
    name: 'Filled Date',
    render: (claim) => formatDate(claim.filledDate),
    width: 1,
  },
  {
    id: 'AdjudicatedDate',
    name: 'Adjudication Date',
    render: (claim) => formatDate(claim.adjudicatedDate),
    width: 1,
  },
  {
    id: 'ClaimStatus',
    name: 'Status',
    render: (claim) => claim.claimStatus,
    width: 1,
  },
  {
    id: 'DrugName',
    name: 'Drug Name',
    render: (claim) => claim.drugName,
    width: 1,
  },
  {
    id: 'PharmacyName',
    name: 'Pharmacy Name',
    render: (claim) => claim.pharmacyName,
    width: 1,
  },
  {
    id: 'DrugContainsOpioid',
    name: '',
    render: (claim) =>
      claim.drugContainsOpioid && (
        <Tag className={classnames(styles.tag, styles.red)} text="Opioid" />
      ),
    width: 0.25,
  },
];

export default columnDefinitions;
