import { Button } from '@material-ui/core';
import { inspect } from '@xstate/inspect';
import React, { useEffect, useState } from 'react';
import { Machine, interpret, assign, createMachine } from 'xstate';
import {
  caresource40rejects,
  caresource891rejects,
  caresourceAdherence,
  caresourceOtc,
  manualCaseStatus,
} from 'app/campaigns/components/BusinessProcessViewer/machines';
import styles from './BusinessProcessViewer.css';

function threeAttempts(context, event) {
  return context.attempts === 3;
}

const caseMachine = Machine(
  {
    id: 'case',
    initial: 'OPEN',
    context: {
      attempts: 0,
    },
    on: {
      INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED: 'DONE',
      REVEAL_NO_EFFECTIVE_ISSUES: 'DONE',
    },
    states: {
      OPEN: {
        on: {
          REVEAL_INTERACTION_STARTED: 'INPROGRESS',
        },
      },
      INPROGRESS: {
        always: [{ target: 'DONE', cond: threeAttempts }],
        on: {
          INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_REACHED: 'DONE',
          INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOTREACHED: {
            actions: assign({
              attempts: (context, event) => context.attempts + 1,
            }),
          },
        },
      },
      DONE: {
        type: 'final',
      },
    },
  },
  {
    guards: {
      threeAttempts,
    },
  },
);

export const patientMachine2 = Machine(
  {
    id: 'patient',
    initial: 'ACTIVE',
    context: {
      attempts: 0,
    },
    states: {
      ACTIVE: {
        on: {
          INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_REACHED: {
            target: 'PAUSED',
            actions: assign({
              attempts: 0,
            }),
          },
          INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOTREACHED: {
            target: 'PAUSED',
            actions: ['incrementAttempts'],
          },
        },
      },
      PAUSED: {
        after: {
          PAUSE_GIVEN_ATTEMPTS: 'ACTIVE',
        },
      },
    },
  },
  {
    actions: {
      incrementAttempts: assign({
        attempts: (c, e) => c.attempts + 1,
      }),
    },
    guards: {
      threeAttempts: (context, event) => {
        return context.attempts === 3;
      },
    },
    delays: {
      PAUSE_GIVEN_ATTEMPTS: (c, e) => (c.attempts < 3 ? 1000 : 3000),
    },
  },
);

export const prescriberMachine = Machine(
  {
    id: 'prescriber',
    initial: 'ACTIVE',
    context: {
      attempts: 0,
    },
    states: {
      ACTIVE: {
        on: {
          INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_REACHED: {
            target: 'PAUSED',
            actions: assign({
              attempts: 0,
            }),
          },
          INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOTREACHED: {
            target: 'PAUSED',
            actions: ['incrementAttempts'],
          },
        },
      },
      PAUSED: {
        after: {
          PAUSE_GIVEN_ATTEMPTS: 'ACTIVE',
        },
      },
    },
  },
  {
    actions: {
      incrementAttempts: assign({
        attempts: (c, e) => c.attempts + 1,
      }),
    },
    delays: {
      PAUSE_GIVEN_ATTEMPTS: 1000,
    },
  },
);

const machines = [
  createMachine(caresourceAdherence),
  createMachine(caresourceOtc),
  createMachine(caresource40rejects),
  createMachine(caresource891rejects),
  createMachine(manualCaseStatus),
  patientMachine2,
  prescriberMachine,
];
const BusinessProcessViewer = () => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    // Danger zone, inspect is supposed to be called once statically early in the code
    // This is here because we need the iframe to be rendered out first before calling inspect()
    // some context https://github.com/davidkpiano/xstate/issues/1467
    const inspector = inspect({
      url: 'https://statecharts.io/inspect',
    });

    setServices(
      machines.map((machine) => {
        const service = interpret(machine, { devTools: true });
        service.start();
        return service;
      }),
    );

    return () => {
      services.forEach((x) => x.stop());
      inspector.disconnect();
    };
  }, []);

  const handleReset = () => {
    services.forEach((x) => x?.stop());
    services.forEach((x) => x?.start());
  };

  return (
    <div className={styles.container}>
      <div>
        <Button
          onClick={handleReset}
          variant="contained"
          className={styles.reset}
        >
          Reset
        </Button>
      </div>
      <iframe data-xstate className={styles.iframe} title="XState Inspector" />
    </div>
  );
};

export default BusinessProcessViewer;
