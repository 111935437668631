export const EXPORT_DOWNLOAD_REQUEST = 'EXPORT/DOWNLOAD_REQUEST';

export const EXPORT_DOWNLOAD_FAIL = 'EXPORT/DOWNLOAD_FAIL';

export const EXPORT_DOWNLOAD_COMPLETE = 'EXPORT/DOWNLOAD_COMPLETE';

export const EXPORT_CASE_DOWNLOAD_FETCH_START =
  'EXPORT/CASE_DOWNLOAD_FETCH_START';

export const EXPORT_CASE_DOWNLOAD_FETCH_SUCCESS =
  'EXPORT/CASE_DOWNLOAD_FETCH_SUCCESS';

export const EXPORT_CASE_DOWNLOAD_FETCH_FAIL =
  'EXPORT/CASE_DOWNLOAD_FETCH_FAIL';
