import graphql from '../common/utilities/graphql';

export const usersQuery = graphql`
  query {
    users {
      id
      name
      permissions
    }
  }
`;
