import { TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { List } from 'immutable';
import { isNil } from 'ramda';
import { useState } from 'react';
import PhoneNumber, {
  MultiPhoneNumber,
} from 'app/common/components/PhoneNumber/PhoneNumber';
import { GraphqlTypes } from 'app/common/types';
import BasicInfoItem from 'app/patient/components/BasicInfoItem/BasicInfoItem';
import SearchContainer from 'app/search/containers/Search/Search';
import { SearchResultRecord, SearchType } from 'app/search/types';
import styles from './InteractionContact.css';

const ContactPhoneNumber = ({ contact }: { contact: any }) => {
  let phoneNumber = null;

  switch (contact.__typename) {
    case GraphqlTypes.Patient:
    case GraphqlTypes.Pharmacy: {
      phoneNumber = contact.phoneNumbers;
      break;
    }
    case GraphqlTypes.Prescriber: {
      phoneNumber = contact.phoneNumber;
      break;
    }
    default: {
      phoneNumber = null;
      break;
    }
  }

  if (isNil(phoneNumber)) return <div />;

  let phoneComponent = null;
  switch (contact.__typename) {
    case GraphqlTypes.Patient:
    case GraphqlTypes.Pharmacy: {
      phoneComponent = (
        <MultiPhoneNumber value={contact.phoneNumbers} readOnly />
      );
      break;
    }
    case GraphqlTypes.Prescriber: {
      phoneComponent = <PhoneNumber number={contact.phoneNumber} />;
      break;
    }
    default: {
      return <div />;
    }
  }

  return <BasicInfoItem label="Phone" value={phoneComponent} />;
};

interface InteractionContactBaseProp {
  contact: any;
  disableSwitching?: boolean;
}

interface InteractionContactReadOnly extends InteractionContactBaseProp {
  suggestedContacts?: never;
  onContactSearchClick?: never;
}

interface InteractionContactEditable extends InteractionContactBaseProp {
  suggestedContacts: List<SearchResultRecord>;
  onContactSearchClick: (result: { id: string }) => void;
}

type InteractionContactProp =
  | InteractionContactReadOnly
  | InteractionContactEditable;

const InteractionContact = ({
  contact,
  disableSwitching = false,
  suggestedContacts,
  onContactSearchClick = () => {},
}: InteractionContactProp) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  return (
    <div className={styles.interactionContent}>
      <div className={styles.contactSummary}>
        <BasicInfoItem
          label="Contact"
          value={
            disableSwitching ? (
              contact?.name
            ) : (
              <TextField
                className={styles.contactInput}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <SearchIcon color="action" className={styles.searchIcon} />
                  ),
                  classes: {
                    input: styles.contactInput,
                  },
                }}
                value={contact?.name}
                onClick={() => setIsSearchOpen(true)}
              />
            )
          }
        />
        {!isNil(contact) && <ContactPhoneNumber contact={contact} />}
      </div>
      <SearchContainer
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        onClick={onContactSearchClick}
        searchTypes={[SearchType.Prescribers, SearchType.Pharmacies]}
        suggestedResults={List(suggestedContacts)}
      />
    </div>
  );
};
export default InteractionContact;
