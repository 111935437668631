import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  List,
  withMobileDialog,
} from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { sortBy, prop } from 'ramda';
import React, { Component, memo } from 'react';
import ContentLoader from 'react-content-loader';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { mapToValuesArray } from '../../../common/utilities/generic';
import ViewTemplateCard from '../../containers/ViewTemplateCard';
import styles from './ViewTemplateSelect.css';

function ListLoader({ rowCount }) {
  const randomNumberGenerator = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const randomBoolean = () => Math.random() >= 0.5;

  return (
    <ContentLoader
      className={styles.badgeLoader}
      height={rowCount * 45}
      width={600}
      speed={2}
      backgroundColor="#e0e0e0"
      foregroundColor="#d6d6d6"
    >
      {[...Array(rowCount)].map((x, i) => {
        const isEditable = randomBoolean();
        return [
          <rect
            key={`templateCount${x}`}
            x="16"
            y={i * 45 + 12}
            rx="2"
            ry="2"
            width="22.7"
            height="22.7"
          />,
          <rect
            key={`templateTitle${x}`}
            x="56"
            y={i * 45 + 13}
            rx="5"
            ry="5"
            width={randomNumberGenerator(100, 300)}
            height="18"
          />,
          <rect
            key={`templatePrivate${x}`}
            x="523"
            y={i * 45 + 12}
            rx="4"
            ry="4"
            width="65"
            height="21"
            style={{
              visibility: isEditable && randomBoolean() ? 'visible' : 'hidden',
            }}
          />,
          <rect
            key={`templateBorder${x}`}
            x="0"
            y={i * 45 + 45}
            rx="0"
            ry="0"
            width="600"
            height="1"
          />,
        ];
      })}
    </ContentLoader>
  );
}

ListLoader.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

class ViewTemplateSelect extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string,
    viewTemplates: ImmutablePropTypes.mapOf(
      ImmutablePropTypes.contains({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        isPublic: PropTypes.bool.isRequired,
      }),
      PropTypes.string.isRequired,
    ).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    fullScreen: PropTypes.bool,
    isDeleteDialogOpen: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    onDeleteRequest: PropTypes.func.isRequired,
    onDeleteConfirm: PropTypes.func.isRequired,
    onDeleteCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedValue: null,
    fullScreen: false,
    deleteError: null,
  };

  handleOnValueChange = (event, value) => {
    const { onValueChange } = this.props;
    onValueChange(value);
  };

  render() {
    const {
      viewTemplates,
      isFetching,
      selectedValue,
      isOpen,
      onRequestClose,
      onEdit,
      onValueChange,
      userId,
      fullScreen,
      isDeleteDialogOpen,
      isDeleting,
      deleteError,
      onDeleteRequest,
      onDeleteConfirm,
      onDeleteCancel,
    } = this.props;
    const getTemplateName = prop('name');
    const sortByName = sortBy(getTemplateName);

    const sortedTemplates = sortByName(mapToValuesArray(viewTemplates));

    return (
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onRequestClose}
        className={styles.dialog}
      >
        <div
          className={classnames({
            [styles.container]: !fullScreen,
            [styles.containerFullscreen]: fullScreen,
          })}
        >
          <div className={styles.header}>
            <h1 className={styles.headerLeft}>Templates</h1>
            {viewTemplates.size !== 0 && isFetching && (
              <CircularProgress size={24} />
            )}
          </div>
          <List className={styles.list} disablePadding>
            {isFetching && viewTemplates.size === 0 && (
              <ListLoader rowCount={30} />
            )}
            {sortedTemplates.map((template) => (
              <ViewTemplateCard
                key={template.id}
                viewTemplate={template}
                isEditable={userId === template.createdBy.id}
                isDeleteable={userId === template.createdBy.id}
                onDeleteRequest={onDeleteRequest}
                onEdit={onEdit}
                onSelect={onValueChange}
                isSelected={selectedValue === template.id}
              />
            ))}
          </List>
          <div className={styles.footer}>
            <Button onClick={onRequestClose}>Close</Button>
          </div>
        </div>

        <Dialog open={isDeleteDialogOpen}>
          <DialogContent className={styles.confirmText}>
            Are you sure you want to delete this template?
          </DialogContent>
          {deleteError && (
            <DialogContent className={styles.error}>
              Error encountered while deleting template.
            </DialogContent>
          )}
          <DialogActions>
            <Button
              onClick={onDeleteConfirm}
              variant="contained"
              color="primary"
              autoFocus
              disabled={isDeleting || !isDeleteDialogOpen}
            >
              {!isDeleting && isDeleteDialogOpen ? (
                'Delete'
              ) : (
                <CircularProgress size={20} />
              )}
            </Button>
            <Button onClick={onDeleteCancel} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withMobileDialog({ breakpoint: 'xs' })(memo(ViewTemplateSelect));
