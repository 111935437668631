import { List } from 'immutable';
import { InteractionFieldDefinitionRecord } from '../../campaigns/types';
import {
  INTERACTION_LEVEL_RESPONSE,
  INTERACTION_FIELD_DEFINTION_FETCH_SUCCESS,
} from '../constants';
import { ContactMethodFieldDefinitionRecord } from '../types';

function reducer(state = new ContactMethodFieldDefinitionRecord(), action) {
  switch (action.type) {
    case INTERACTION_FIELD_DEFINTION_FETCH_SUCCESS: {
      const { fieldDefinitions, contact } = action.payload;

      return state.merge({
        phone: state.phone.merge({
          [contact]: new List(
            fieldDefinitions.map(
              (x) =>
                new InteractionFieldDefinitionRecord({
                  ...x,
                  options: new List(x.options),
                  level: INTERACTION_LEVEL_RESPONSE,
                }),
            ),
          ),
        }),
      });
    }
    default:
      return state;
  }
}

export default reducer;
