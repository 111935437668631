import graphql from '../common/utilities/graphql';

export const autocompleteQueryFactory = (
  fragment: string,
  fragmentName: string,
) =>
  graphql`
  ${fragment}

  query autocomplete($search: String, $autocompleteType: [AutocompleteTypeEnum]!) {
    autocomplete(search: $search, autocompleteTypes: $autocompleteType) {
      ...${fragmentName}
    }
  }

`;

export const stringAutoCompleteFragmentFactory = (name: string) =>
  graphql`
  fragment ${name} on StringType {
    value
  }
`;

export const rejectCodeAutoCompleteFragmentFactory = (name: string) =>
  graphql`
  fragment ${name} on RejectCodeType {
    code
    description
  }
  `;

export const userAutoCompleteFragmentFactory = (name: string) =>
  graphql`
  fragment ${name} on UserType {
    id
    name
  }
`;

export const filterTypesQuery = graphql`
  query filterTypes($name: String!) {
    fields: __type(name: $name) {
      inputFields {
        name
        type {
          ...fieldType
          type: ofType {
            ...fieldType
            type: ofType {
              ...fieldType
              type: ofType {
                ...fieldType
                type: ofType {
                  ...fieldType
                }
              }
            }
          }
        }
      }
      enumValues {
        name
      }
    }
  }

  fragment fieldType on __Type {
    kind
    name
    inputFields {
      name
      defaultValue
      # We recurse on the complex input type here to look for nested input type such as a non-null list of enums (3 layers deep)
      type {
        name
        kind
        ofType {
          name
          kind
          ofType {
            name
            kind
            enumValues {
              name
              description
            }
          }
        }
      }
    }
    enumValues {
      name
      description
    }
  }
`;

export const tiersQuery = graphql`
  query tiers($id: ID!) {
    node(id: $id) {
      ... on TiersType {
        tiers {
          key
          value
        }
      }
    }
  }
`;

export const paTypesQuery = graphql`
  query priorAuthTypes($id: ID!) {
    node(id: $id) {
      ... on PriorAuthorizationType {
        priorAuthTypes {
          key
          value
        }
      }
    }
  }
`;

export const coverageStatusSchedulesQuery = graphql`
  query coverageStatusSchedules($id: ID!) {
    node(id: $id) {
      ... on CoverageStatusScheduleType {
        coverageStatusSchedules {
          key
          value
        }
      }
    }
  }
`;

export const drugListsQuery = graphql`
  query drugLists {
    drugLists {
      id
      name
    }
  }
`;
