import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import {
  isViewTemplateCountFetching,
  getViewTemplateCountError,
} from '../../../ui/selectors';
import ViewTemplateCard from '../../components/ViewTemplateCard';

export class ViewTemplateCardContainer extends Component {
  static propTypes = {
    isSelected: PropTypes.bool.isRequired,
    isFetchingCount: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    viewTemplate: ImmutablePropTypes.recordOf({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isPublic: PropTypes.bool.isRequired,
      count: PropTypes.number,
    }).isRequired,
  };

  render() {
    const {
      isSelected,
      isFetchingCount,
      isEditable,
      onEdit,
      onSelect,
      viewTemplate,
      isDeleteable,
      onDeleteRequest,
      error,
    } = this.props;
    return (
      <ViewTemplateCard
        isFetchingCount={isFetchingCount}
        viewTemplate={viewTemplate}
        isEditable={isEditable}
        onEdit={onEdit}
        onSelect={onSelect}
        isSelected={isSelected}
        isDeleteable={isDeleteable}
        onDeleteRequest={onDeleteRequest}
        error={error}
      />
    );
  }
}

export default connect((state, props) => ({
  isFetchingCount: isViewTemplateCountFetching(state, props.viewTemplate.id),
  error: getViewTemplateCountError(state, props.viewTemplate.id),
}))(ViewTemplateCardContainer);
