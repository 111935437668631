import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import { GraphqlTypes } from 'app/common/types';
import { IssueFilterType } from 'app/filters/constants';
import { enumDefaults } from 'app/filters/definitions/defaults';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';
import { formatLineOfBusiness } from 'app/workitems/utility';

const optionTextFormatter = (value: string) =>
  formatLineOfBusiness(value).toUpperCase();

class LineOfBusinessConfig
  extends Record({
    ...enumDefaults(GraphqlTypes.lineOfBusinessEnum, optionTextFormatter),
    name: 'lineOfBusiness',
    title: 'Line of Business',
    targetType: IssueFilterType.Claim,
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.lineOfBusiness)) return false;

      return value.contains(workItem.claim.lineOfBusiness);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      Set<string>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new LineOfBusinessConfig();
