import {
  Divider,
  List,
  ListItem,
  Paper,
  Checkbox,
  Fab,
} from '@material-ui/core';
import { isWithinInterval, subDays } from 'date-fns';
import { filter, pipe, map, reduce, mean, max } from 'ramda';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { VictoryBar } from 'victory';
import CampaignList from '../../containers/CampaignList';
import styles from './CampaignDashboard.css';
import Workload from './Workload';
import { campaigns, dataSelector } from './Workload/realisticData';

const color = (x, max) => {
  const val = Math.abs(x);
  switch (true) {
    case val >= max * 0.9:
      return '#f72c2c'; // red
    case val < max * 0.9 && val >= max * 0.5:
      return '#f4ac00'; // orange
    case val < max * 0.5 && val >= 0:
    default:
      return '#80c644'; // green
  }
};

const CampaignSelect = ({
  campaigns,
  selectedCampaigns,
  onSelectCampaigns,
}) => {
  const data = pipe(
    map((x) => dataSelector([x.id])),
    map(
      pipe(
        filter((x) =>
          isWithinInterval(x.date, {
            start: subDays(new Date(), 30),
            end: new Date(),
          }),
        ),
        reduce(
          (acc, value) => {
            // TODO use a record here to get rid of all this copy junk
            const newAcc = {
              ...acc,
              data: [...acc.data],
              rollingClosed: [...acc.rollingClosed],
              rollingOpened: [...acc.rollingOpened],
            };
            newAcc.rollingClosed.push(value.casesClosed);
            newAcc.rollingOpened.push(value.casesOpened);
            if (newAcc.rollingClosed.length > 7) newAcc.rollingClosed.shift();
            if (newAcc.rollingOpened.length > 7) newAcc.rollingOpened.shift();
            const overUnder =
              mean(newAcc.rollingClosed) - mean(newAcc.rollingOpened);
            newAcc.data.push({
              date: value.date,
              overUnder,
            });
            newAcc.max = max(newAcc.max, Math.abs(overUnder));
            return newAcc;
          },
          { max: 0, data: [], rollingOpened: [], rollingClosed: [] },
        ),
        (x) => {
          return map((dataPoint) => ({
            x: dataPoint.date,
            y: dataPoint.overUnder,
            color: color(dataPoint.overUnder, x.max),
          }))(x.data);
        },
      ),
    ),
  )(campaigns);

  return (
    <>
      <Scrollbars>
        <List>
          {campaigns.map((campaign, i) => (
            <>
              <ListItem
                button
                className={styles.campaignSelectItem}
                selected={selectedCampaigns.includes(campaign.id)}
                onClick={() => {
                  if (selectedCampaigns.includes(campaign.id)) {
                    return onSelectCampaigns(
                      selectedCampaigns.filter((c) => c !== campaign.id),
                    );
                  }
                  return onSelectCampaigns(
                    selectedCampaigns.concat(campaign.id),
                  );
                }}
              >
                <Checkbox checked={selectedCampaigns.includes(campaign.id)} />
                <div className={styles.campaignSelectContent}>
                  <div className={styles.campaignSelectTitle}>
                    {campaign.name}
                  </div>
                  <VictoryBar
                    padding={{ top: 5, bottom: 5, left: 15, right: 15 }}
                    height={100}
                    barRatio={1}
                    style={{ data: { fill: ({ datum }) => datum.color } }}
                    data={data[i]}
                  />
                </div>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
        {selectedCampaigns.length > 0 && (
          <Fab
            variant="extended"
            classes={{ root: styles.campaignSelectClearFab }}
            onClick={() => onSelectCampaigns([])}
          >
            Clear Selections
          </Fab>
        )}
      </Scrollbars>
    </>
  );
};

const CampaignDashboard = ({ campaigns }) => {
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Campaigns</h1>
      </header>
      {/* <div className={styles.overview}>
        <Paper className={styles.campaignChart}>
          <Workload selectedCampaigns={selectedCampaigns} />
        </Paper>
        <Paper className={styles.campaignSelect}>
          <CampaignSelect
            campaigns={campaigns}
            selectedCampaigns={selectedCampaigns}
            onSelectCampaigns={setSelectedCampaigns}
          />
        </Paper>
      </div> */}
      <div className={styles.list}>
        <CampaignList />
      </div>
    </div>
  );
};

CampaignDashboard.defaultProps = {
  campaigns,
};
export default CampaignDashboard;
