import { CircularProgress, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Link } from '@reach/router';
import { format as formatDate, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { find, isEmpty, isNil, nth } from 'ramda';
import React, { Fragment } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { StepTherapyGroup } from '../../../../claims/types';
import PhoneNumber from '../../../../common/components/PhoneNumber/PhoneNumber';
import {
  pluralize,
  formatCurrency,
} from '../../../../common/utilities/generic';
import { LineOfBusiness, QlEditType, ClaimStatus } from '../../../constants';
import {
  formatQuantityLimit,
  formatLineOfBusiness,
  formatQlEditType,
  formatMultiSourceCode,
  formatLICSLevel,
  formatPharmacyType,
  formatDiagnosisCode,
} from '../../../utility';
import styles from './WorkItemDetails.css';

function format(value) {
  if (isNil(value)) {
    return '';
  }
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  if (value instanceof Date) {
    return formatDate(value, 'MM/dd/yyyy');
  }

  return String(value);
}

function formatHighlightedBool(value) {
  if (value) {
    return <span className={styles.detailHighlighted}>Yes</span>;
  }
  return 'No';
}

function formatValueOrNone(shouldRenderValue, value) {
  if (!shouldRenderValue) {
    return <span className={styles.detailFaded}>None</span>;
  }
  if (isNil(value)) {
    return '';
  }
  return String(value);
}

function formatMme(value) {
  return `${parseFloat(value.toFixed(3))} mg`;
}

function formatPatientAge(dob, age) {
  if (!isNil(dob)) {
    let value = format(dob);

    if (!isNil(age)) {
      value = `${value} (${age} years)`;
    }

    return value;
  }

  return '';
}

function formatSeniorSavingsModel(seniorSavingsModel) {
  return (
    <>
      {formatHighlightedBool(seniorSavingsModel.exceedsCopay)}{' '}
      <span class={styles.detailSecondary}>
        (Max: {formatCurrency(seniorSavingsModel.maxCopay)})
      </span>
    </>
  );
}

function formatDrugLists(drugLists) {
  return drugLists.map((dl) => <div>{dl.name}</div>);
}

const HeaderRow = ({ label, value }) => (
  <div className={styles.detailHeader}>
    <div className={styles.detailLabel}>{label}</div>
    <div className={styles.detailValue}>{value}</div>
  </div>
);

HeaderRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
};

const DetailRow = ({ tooltip, label, value }) => {
  const Wrapper = ({ children }) =>
    !isNil(tooltip) ? (
      <Tooltip
        classes={{ tooltip: styles.detailRowTooltip }}
        title={tooltip}
        placement="top-start"
      >
        <div style={{ display: 'flex' }}>
          <span>{children}</span>
          <HelpOutline classes={{ root: styles.detailRowHelper }} />
        </div>
      </Tooltip>
    ) : (
      <>{children}</>
    );

  return (
    <div className={styles.detailRow}>
      <div className={styles.detailLabel}>
        <Wrapper>{label}</Wrapper>
      </div>
      <div className={styles.detailValue}>{value}</div>
    </div>
  );
};

DetailRow.propTypes = {
  tooltip: PropTypes.node,
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

DetailRow.defaultProps = {
  tooltip: null,
};

const SubPanel = ({ title, children }) => (
  <div className={styles.subPanel}>
    <div className={styles.subPanelHeader}>{title}</div>
    {children}
  </div>
);

SubPanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ClaimDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow label="Claim" value={format(claim.claimNumber)} />
    <DetailRow
      label="Adjudication Date"
      value={
        claim.adjudicatedDate
          ? format(new Date(claim.adjudicatedDate))
          : format(claim.adjudicatedDate)
      }
    />
    <DetailRow
      label="Filled Date"
      value={
        claim.filledDate
          ? format(new Date(claim.filledDate))
          : format(claim.filled)
      }
    />
    <DetailRow label="Status" value={format(claim.claimStatus)} />
    <DetailRow label="Copay" value={formatCurrency(claim.copay)} />
    {claim.seniorSavingsModel && claim.seniorSavingsModel.exceedsCopay && (
      <DetailRow
        label="Exceeds Senior Savings Copay"
        value={formatSeniorSavingsModel(claim.seniorSavingsModel)}
      />
    )}
    <DetailRow
      label={pluralize('Rejection Code', claim.rejectCodes.length)}
      value={format(
        !isEmpty(claim.rejectCodes) ? claim.rejectCodes.join(', ') : '',
      )}
    />
    <DetailRow
      label={pluralize('Rejection Message', claim.rejectMessages.length)}
      value={claim.rejectMessages.map((message) => (
        <div key={message}>{format(message)}</div>
      ))}
    />
  </div>
);

ClaimDetails.propTypes = {
  claim: PropTypes.shape({
    claimNumber: PropTypes.string,
    adjudicatedDate: PropTypes.instanceOf(Date),
    filledDate: PropTypes.instanceOf(Date),
    claimStatus: PropTypes.string,
    copay: PropTypes.number,
    rejectCodes: PropTypes.oneOfType([
      ImmutablePropTypes.setOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    rejectMessages: PropTypes.oneOfType([
      ImmutablePropTypes.setOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
  }).isRequired,
};

const PrescriptionDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow label="Rx Number" value={format(claim.rxNumber)} />
    <DetailRow label="Drug Name" value={format(claim.drugName)} />
    <DetailRow label="NDC" value={format(claim.ndc)} />
    <DetailRow label="RXCUI" value={format(claim.rxcui)} />
    <DetailRow label="GPI" value={format(claim.gpi)} />
    <DetailRow label="Drug Lists" value={formatDrugLists(claim.drugLists)} />
    <DetailRow
      label="Protected Class Drug"
      value={formatHighlightedBool(claim.isDrugProtectedClass)}
    />
    <DetailRow
      label="Quantity Dispensed"
      value={format(claim.quantityDispensed)}
    />
    <DetailRow label="Days Supplied" value={format(claim.daysSupply)} />
    <DetailRow label="Compound Drug" value={format(claim.isDrugCompound)} />
    <DetailRow label="OTC Drug" value={format(claim.isDrugOverTheCounter)} />
    <DetailRow
      label="Multi-Source Code"
      value={formatValueOrNone(
        claim.multiSourceCode,
        formatMultiSourceCode(claim.multiSourceCode),
      )}
    />
    <DetailRow
      label={pluralize('Diagnosis Code', claim.diagnosisCodes.length)}
      value={claim.diagnosisCodes.map((dc) => (
        <div key={dc.code}>{formatDiagnosisCode(dc)}</div>
      ))}
    />
  </div>
);

PrescriptionDetails.propTypes = {
  claim: PropTypes.shape({
    rxNumber: PropTypes.string,
    drugName: PropTypes.string,
    ndc: PropTypes.string,
    rxcui: PropTypes.string,
    isDrugProtectedClass: PropTypes.bool,
    isDrugCompound: PropTypes.bool,
    isDrugOverTheCounter: PropTypes.bool,
    multiSourceCode: PropTypes.string,
    quantityDispensed: PropTypes.number,
    daysSupply: PropTypes.number,
    diagnosisCodes: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        code: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

const CoverageDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow label="Coverage" />
    <DetailRow
      label="Line of Business"
      value={formatLineOfBusiness(claim.lineOfBusiness)}
    />
    <DetailRow
      label="Coordination of Beneifts Indicator"
      value={format(claim.coordinationOfBenefitsIndicator)}
    />
    <DetailRow label="Carrier" value={format(claim.carrier)} />
    <DetailRow label="Account" value={format(claim.account)} />
    <DetailRow label="Group" value={format(claim.group)} />
    <DetailRow
      label="Benefit Contract"
      value={format(claim.benefitContractId)}
    />
    <DetailRow label="LICS Level" value={formatLICSLevel(claim.licsLevel)} />
    <DetailRow
      label="Drug On Formulary"
      value={format(claim.isDrugOnFormulary)}
    />
    {claim.lineOfBusiness === LineOfBusiness.medicarePartD && (
      <DetailRow
        label="Drug Coverage Status"
        value={format(claim.coverageStatusSchedule)}
      />
    )}
    <DetailRow label="Tier (Formulary)" value={format(claim.formularyTier)} />
    <DetailRow label="Tier (Claim Record)" value={format(claim.tier)} />
  </div>
);

CoverageDetails.propTypes = {
  claim: PropTypes.shape({
    lineOfBusiness: PropTypes.string,
    carrier: PropTypes.string,
    account: PropTypes.string,
    group: PropTypes.string,
    benefitContractId: PropTypes.string,
    licsLevel: PropTypes.string,
    isDrugOnFormulary: PropTypes.bool,
    formularyTier: PropTypes.string,
    coverageStatusSchedule: PropTypes.string,
    tier: PropTypes.string,
  }).isRequired,
};

// TODO:  The null collalese is a massive hack.
// We use this same component in the queue and the work items
// the queue gives back the denormalized claim and patient
// the work item details only gives the id.
// ideally here we would always have the denormalized version, however
// since GraphQL isn't setup on the front end to provide those details and we haven't
// workitems to that pattern, when we try to denormalize in the selector we start to see
// circular dependencies.
const PatientDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow
      label="Patient"
      value={
        <Link to={`/patients/${claim.patient?.id ?? claim.patient}`}>
          {claim.patientId}
        </Link>
      }
    />
    <DetailRow label="Name" value={format(claim.patientName)} />
    <DetailRow
      label="Date of Birth"
      value={formatPatientAge(claim.patientDob, claim.patientAge)}
    />
    <DetailRow
      label="Patient Is Eligible"
      value={format(claim.isPatientEligible)}
    />
    <DetailRow
      label="Eligibility"
      value={
        (claim.eligibilityEffectiveDate
          ? format(new Date(claim.eligibilityEffectiveDate))
          : format(claim.eligibilityEffectiveDate)) +
        String.fromCharCode(8212) +
        (claim.eligibilityTerminationDate
          ? format(new Date(claim.eligibilityTerminationDate))
          : format(claim.eligibilityTerminationDate))
      }
    />
    <DetailRow
      label="Patient in Transition"
      value={formatHighlightedBool(claim.isPatientInPlanTransition)}
    />
    <DetailRow
      label="Patient in LTC"
      value={formatHighlightedBool(claim.isPatientInLongTermCare)}
    />
    <DetailRow
      label="Patient Has Cancer"
      value={formatHighlightedBool(claim.patientHasCancer)}
    />
  </div>
);

PatientDetails.propTypes = {
  claim: PropTypes.shape({
    patientId: PropTypes.string,
    patientName: PropTypes.string,
    patientDob: PropTypes.instanceOf(Date),
    eligibilityEffectiveDate: PropTypes.instanceOf(Date),
    eligibilityTerminationDate: PropTypes.instanceOf(Date),
    isPatientInPlanTransition: PropTypes.bool,
    patientHasCancer: PropTypes.bool,
  }).isRequired,
};

const TemplateMatches = ({ templateMatches, activeTemplateId }) => {
  let headerTemplate = null;
  if (!isEmpty(templateMatches)) {
    headerTemplate = find((t) => t.id === activeTemplateId)(templateMatches);
  } else if (templateMatches.length === 1) {
    headerTemplate = nth(0)(templateMatches);
  }
  return (
    <div className={styles.panel}>
      <HeaderRow label="Template" value={format(headerTemplate?.name)} />
      {!isEmpty(templateMatches) && (
        <DetailRow
          label="Templates"
          value={templateMatches.map((template) => (
            <div key={template.id}>{format(template.name)}</div>
          ))}
        />
      )}
    </div>
  );
};

TemplateMatches.propTypes = {
  templateMatches: PropTypes.array.isRequired,
  activeTemplateId: PropTypes.string,
};

const PharmacyDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow label="Pharmacy" value={format(claim.pharmacyId)} />
    <DetailRow label="Name" value={format(claim.pharmacyName)} />
    <DetailRow
      label="Address"
      value={
        <Fragment>
          {format(claim.pharmacyAddress1)}
          {claim.pharmacyAddress1 && <br />}
          {format(claim.pharmacyCity)}
          {claim.pharmacyCity && claim.pharmacyState && ', '}
          {format(claim.pharmacyState)}
        </Fragment>
      }
    />
    <DetailRow
      label="Phone"
      value={<PhoneNumber number={claim.pharmacyPhone} />}
    />
    <DetailRow
      label="Pharmacy Type"
      value={formatPharmacyType(claim.pharmacyType)}
    />
  </div>
);

PharmacyDetails.propTypes = {
  claim: PropTypes.shape({
    pharmacyId: PropTypes.string,
    pharmacyName: PropTypes.string,
    pharmacyAddress1: PropTypes.string,
    pharmacyCity: PropTypes.string,
    pharmacyPhone: PropTypes.string,
    pharmacyType: PropTypes.string,
  }).isRequired,
};

const PrescriberDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow label="Prescriber NPI" value={format(claim.prescriberNpi)} />
  </div>
);

PrescriberDetails.propTypes = {
  claim: PropTypes.shape({
    prescriberNpi: PropTypes.string,
  }).isRequired,
};

const UtilizationManagementDetails = ({ claim }) => (
  <div className={styles.panel}>
    <h5 className={styles.detailHeader}>UTILIZATION MANAGEMENT</h5>
    <SubPanel title="QL">
      <DetailRow
        label="Type (Formulary)"
        value={
          claim.quantityLimitType &&
          formatValueOrNone(
            claim.quantityLimitType !== QlEditType.none,
            formatQlEditType(claim.quantityLimitType),
          )
        }
      />
      {claim.quantityLimitType !== QlEditType.none && (
        <DetailRow
          label="Limit (Formulary)"
          value={formatQuantityLimit(
            claim.quantityLimitAmount,
            claim.quantityLimitDays,
            claim.quantityLimitPerDay,
          )}
        />
      )}
      {claim.quantityLimitType === QlEditType.qtyOverTime && (
        <DetailRow
          label="Previous Fills Quantity"
          value={format(claim.quantityPreviouslyFilledInLimitWindow)}
        />
      )}
      <DetailRow
        label="Quantity (Claim)"
        value={formatQuantityLimit(
          claim.quantityDispensed,
          claim.daysSupply,
          claim.quantityPerDay,
        )}
      />
      {claim.quantityLimitType !== QlEditType.none && (
        <DetailRow
          label="Claim Exceeds QL"
          value={formatHighlightedBool(claim.quantityLimitPerDayExceeded)}
        />
      )}
    </SubPanel>
    <SubPanel title="DS">
      <DetailRow
        label="Days Supply Limit"
        value={formatValueOrNone(
          claim.daySupplyLimit && claim.daySupplyLimit > 0,
          claim.daySupplyLimit,
        )}
      />
      <DetailRow
        label="Claim Exceeds Days Supply Limit"
        value={formatHighlightedBool(claim.daySupplyLimitExceeded)}
      />
    </SubPanel>
    <SubPanel title="PA">
      <DetailRow
        label="Type (Formulary)"
        value={formatValueOrNone(
          claim.formularyRequiresPriorAuth,
          claim.formularyPriorAuthTypeCode,
        )}
      />
      {claim.formularyRequiresPriorAuth && (
        <DetailRow
          label="Group (Formulary)"
          value={format(claim.formularyPriorAuthGroup)}
        />
      )}
      <DetailRow label="Claim Has PA" value={format(claim.hasPriorAuth)} />
      {claim.hasPriorAuth && (
        <DetailRow
          label={pluralize('PA Number', claim.priorAuthNumbers.length)}
          value={claim.priorAuthNumbers.map((pa) => (
            <div key={pa}>{pa}</div>
          ))}
        />
      )}
    </SubPanel>
    <SubPanel title="ST">
      <DetailRow
        label="Type (Formulary)"
        value={formatValueOrNone(
          claim.stepTherapyRequired,
          claim.stepTherapyType,
        )}
      />
      {claim.stepTherapyRequired && (
        <DetailRow
          label={pluralize('Group', claim.stepTherapyGroups.length)}
          value={claim.stepTherapyGroups.map((group) => (
            <div key={group.step + group.description}>
              {group.step} - {group.description}
            </div>
          ))}
        />
      )}
    </SubPanel>
  </div>
);

UtilizationManagementDetails.propTypes = {
  claim: PropTypes.shape({
    quantityLimitType: PropTypes.string,
    quantityLimitAmount: PropTypes.number,
    quantityLimitDays: PropTypes.number,
    quantityLimitPerDay: PropTypes.number,
    quantityDispensed: PropTypes.number,
    daysSupply: PropTypes.number,
    quantityPerDay: PropTypes.number,
    hasPriorAuth: PropTypes.bool,
    priorAuthNumbers: PropTypes.oneOfType([
      ImmutablePropTypes.setOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    formularyRequiresPriorAuth: PropTypes.bool,
    formularyPriorAuthTypeCode: PropTypes.string,
    formularyPriorAuthGroup: PropTypes.string,
    stepTherapyRequired: PropTypes.bool,
    stepTherapyType: PropTypes.string,
    stepTherapyGroups: PropTypes.oneOfType([
      ImmutablePropTypes.setOf(StepTherapyGroup),
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
  }).isRequired,
};

const SafetyDetails = ({ claim }) => (
  <div className={styles.panel}>
    <HeaderRow label="Safety" />
    <DetailRow
      label="Drug Contains Opioid"
      value={formatHighlightedBool(claim.drugContainsOpioid)}
    />
    <DetailRow
      label="Drug Has Combinator Interaction"
      value={formatHighlightedBool(claim.hasOpioidCombinatorImpact)}
    />
    <DetailRow
      label="Patient Is Opioid Naive"
      value={formatHighlightedBool(claim.isPatientOpioidNaive)}
    />
    {claim.drugContainsOpioid && claim.dailyMorphineMilligramEquivalent && (
      <DetailRow
        label="Daily MME (Claim)"
        value={formatMme(claim.dailyMorphineMilligramEquivalent)}
      />
    )}
    {!isNil(claim.patientTotalMorphineMilligramEquivalent) && (
      <DetailRow
        label="Daily MME (Patient)"
        value={formatMme(claim.patientTotalMorphineMilligramEquivalent)}
      />
    )}
    {!isNil(claim.patientPotentialTotalMorphineMilligramEquivalent) &&
      claim.claimStatus === ClaimStatus.Rejected && (
        <DetailRow
          label="Potential Daily MME (Patient)"
          value={formatMme(
            claim.patientPotentialTotalMorphineMilligramEquivalent,
          )}
        />
      )}
    {!isNil(claim.historicalOpioidPrescriberCount) && (
      <DetailRow
        tooltip="Number of distinct prescribers with opioid prescriptions in the last 180 days"
        label="Opioid Prescribers"
        value={format(claim.historicalOpioidPrescriberCount)}
      />
    )}
    {!isNil(claim.historicalOpioidPharmacyCount) && (
      <DetailRow
        tooltip="Number of distinct pharmacies with opioid fills in the last 180 days"
        label="Opioid Pharmacies"
        value={format(claim.historicalOpioidPharmacyCount)}
      />
    )}
  </div>
);

SafetyDetails.propTypes = {
  claim: PropTypes.shape({
    drugContainsOpioid: PropTypes.bool,
    hasOpioidCombinatorImpact: PropTypes.bool,
    isPatientOpioidNaive: PropTypes.bool,
    dailyMorphineMilligramEquivalent: PropTypes.number,
    patientPotentialTotalMorphineMilligramEquivalent: PropTypes.number,
  }).isRequired,
};

const WorkItemDetails = ({
  workItem,
  isFetchingWorkItem,
  errorFetchingWorkItem,
  hasFetchedDetails,
  templateMatches,
  activeTemplateId,
}) => (
  <Scrollbars
    className={styles.workItemBody}
    renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
    renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
  >
    {errorFetchingWorkItem && (
      <div className={styles.errorContainer}>
        There was an unexpected error loading the work item.
      </div>
    )}
    {isFetchingWorkItem && !errorFetchingWorkItem && (
      <div className={styles.loadingContainer}>
        <CircularProgress size={40} />
      </div>
    )}
    {/* TODO:  Maybe move this into its own component that accepts a claim instead of workitem*/}
    {workItem &&
      hasFetchedDetails &&
      !isFetchingWorkItem &&
      !errorFetchingWorkItem && (
        <div className={styles.panelContainerWrapper}>
          <div className={styles.panelContainer}>
            <div className={styles.column}>
              {templateMatches.length > 0 && (
                <TemplateMatches
                  templateMatches={templateMatches}
                  activeTemplateId={activeTemplateId}
                />
              )}
              <ClaimDetails claim={workItem.claim} />
              <PrescriptionDetails claim={workItem.claim} />
              <CoverageDetails claim={workItem.claim} />
            </div>
            <div className={styles.column}>
              <PatientDetails claim={workItem.claim} />
              <PharmacyDetails claim={workItem.claim} />
              <PrescriberDetails claim={workItem.claim} />
              <SafetyDetails claim={workItem.claim} />
              <UtilizationManagementDetails claim={workItem.claim} />
            </div>
          </div>
        </div>
      )}
  </Scrollbars>
);

WorkItemDetails.propTypes = {
  workItem: PropTypes.oneOfType([
    ImmutablePropTypes.recordOf({
      claim: ImmutablePropTypes.record.isRequired,
    }),
    PropTypes.shape({
      claim: PropTypes.object.isRequired,
    }),
  ]),
  isFetchingWorkItem: PropTypes.bool.isRequired,
  errorFetchingWorkItem: PropTypes.object,
  templateMatches: PropTypes.array,
  activeTemplateId: PropTypes.string,
};

WorkItemDetails.defaultProps = {
  workItem: null,
  activeTemplateId: null,
  templateMatches: [],
};

export default WorkItemDetails;
