import { ContentState, EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Comment from '../../components/comment';

const CommentContainer = ({
  id,
  author,
  message,
  dateCreated,
  error,
  onEdit,
  onDelete,
  onErrorClick,
  isSaving,
  isOwner,
}) => {
  const editorState = EditorState.createWithContent(message);
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(editorState);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const displayedMessage = isEditing ? editedMessage : editorState;
  const handleErrorClick = () =>
    onErrorClick(error, {
      id,
      author: author.id,
      dateCreated,
      message,
    });

  return (
    <Comment
      id={id}
      author={author}
      dateCreated={dateCreated}
      isEditing={isEditing}
      onEditRequest={() => setIsEditing(true)}
      onEdit={setEditedMessage}
      onEditCancel={() => {
        setIsEditing(false);
        setEditedMessage(EditorState.createWithContent(message));
      }}
      onSaveClick={(x) => {
        setIsEditing(false);
        onEdit(id, editedMessage.getCurrentContent());
      }}
      isDeleteDialogOpen={isDeleteDialogOpen}
      onDeleteRequest={() => setIsDeleteDialogOpen(true)}
      onDeleteConfirm={() => {
        setIsDeleteDialogOpen(false);
        onDelete(id);
      }}
      onDeleteCancel={() => setIsDeleteDialogOpen(false)}
      error={error}
      onErrorClick={handleErrorClick}
      message={displayedMessage}
      isSaving={isSaving}
      isOwner={isOwner}
    />
  );
};

CommentContainer.propTypes = {
  id: PropTypes.string.isRequired,
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  message: PropTypes.instanceOf(ContentState).isRequired,
  dateCreated: PropTypes.instanceOf(Date).isRequired,
  error: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onErrorClick: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

CommentContainer.defaultProps = {
  error: false,
  disabled: false,
};

export default CommentContainer;
