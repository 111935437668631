export const PATIENT_FETCH_START = 'PATIENT/PATIENT_FETCH_START';
export const PATIENT_FETCH_SUCCESS = 'PATIENT/PATIENT_FETCH_SUCCESS';
export const PATIENT_FETCH_FAIL = 'PATIENT/PATIENT_FETCH_FAIL';

export const DRUG_OVERVIEW_FETCH_START = 'PATIENT/DRUG_OVERVIEW_FETCH_START';
export const DRUG_OVERVIEW_FETCH_SUCCESS =
  'PATIENT/DRUG_OVERVIEW_FETCH_SUCCESS';
export const DRUG_OVERVIEW_FETCH_FAIL = 'PATIENT/DRUG_OVERVIEW_FETCH_FAIL';

export const CLAIM_VIEWER_FETCH_START = 'PATIENT/CLAIM_VIEWER_FETCH_START';
export const CLAIM_VIEWER_FETCH_SUCCESS = 'PATIENT/CLAIM_VIEWER_FETCH_SUCCESS';
export const CLAIM_VIEWER_FETCH_FAIL = 'PATIENT/CLAIM_VIEWER_FETCH_FAIL';

export const OPIOID_DETAIL_FETCH_START = 'PATIENT/OPIOID_DETAIL_FETCH_START';
export const OPIOID_DETAIL_FETCH_SUCCESS =
  'PATIENT/OPIOID_DETAIL_FETCH_SUCCESS';
export const OPIOID_DETAIL_FETCH_FAIL = 'PATIENT/OPIOID_DETAIL_FETCH_FAIL';

export const PATIENT_UPSERT_START = 'PATIENT/PATIENT_UPSERT_START';
export const PATIENT_UPSERT_SUCCESS = 'PATIENT/PATIENT_UPSERT_SUCCESS';
export const PATIENT_UPSERT_FAIL = 'PATIENT/PATIENT_UPSERT_FAIL';
