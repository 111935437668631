import {
  CheckBox as Reviewed,
  AddCircle as NotReviewed,
} from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { sortBy, path } from 'ramda';
import React, { forwardRef } from 'react';
import ContentLoader from 'react-content-loader';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { aggregateSummaries } from '../../utilities';
import BestPracticeRuleCard from '../BestPracticeRuleCard';
import styles from './BestPracticeSection.css';

const sortByScore = sortBy(path(['riskScore', 'score']));

const renderIcon = (bestPractice) => {
  const claimsInRules = aggregateSummaries(bestPractice.rules);

  if (claimsInRules.notReviewed > 0) {
    return <NotReviewed className={styles.notReviewed} />;
  }

  return <Reviewed className={styles.reviewed} />;
};

const BestPracticeSection = forwardRef(
  ({ bestPractice, isFetching, focusedRule }, ref) => (
    <div
      ref={ref}
      className={classnames({
        [styles.container]: true,
        [styles.opaque]:
          focusedRule && !bestPractice.rules.find((x) => x.id === focusedRule),
      })}
    >
      {isFetching && (
        <ContentLoader
          speed={2}
          width={800}
          height={110}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className={styles.loading}
        >
          <rect x="0" y="0" rx="2" ry="2" width="18" height="18" />
          <rect x="30" y="0" rx="2" ry="2" width="300" height="20" />
          <rect x="30" y="36" rx="2" ry="2" width="350" height="13" />
          <rect x="30" y="57" rx="2" ry="2" width="4" height="56" />
          <rect x="42" y="58" rx="2" ry="2" width="250" height="10" />
          <rect x="42" y="72" rx="2" ry="2" width="200" height="10" />
          <rect x="42" y="86" rx="2" ry="2" width="200" height="10" />
          <rect x="42" y="100" rx="2" ry="2" width="200" height="10" />
        </ContentLoader>
      )}
      {!isFetching && (
        <div className={styles.details}>
          <div className={styles.status}>{renderIcon(bestPractice)}</div>
          <div>
            <div className={styles.title}>{bestPractice.name}</div>
            {sortByScore(bestPractice.rules.toArray()).map((rule) => (
              <BestPracticeRuleCard
                key={rule.id}
                rule={rule}
                isFetching={isFetching}
                className={classnames({
                  [styles.opaque]: focusedRule && focusedRule !== rule.id,
                })}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  ),
);

BestPracticeSection.propTypes = {
  bestPractice: ImmutablePropTypes.recordOf({
    name: PropTypes.string,
    rules: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  }),
  isFetching: PropTypes.bool.isRequired,
  focusedRule: PropTypes.string,
};

export default BestPracticeSection;
