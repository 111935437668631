import { Machine, assign } from 'xstate';

export const caseMachine = Machine({
  id: 'case',
  initial: 'OPEN',
  context: {
    attempts: 0,
  },
  on: {
    INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOOUTREACHNEEDED: 'DONE',
    REVEAL_NO_EFFECTIVE_ISSUES: 'DONE',
  },
  states: {
    OPEN: {
      on: {
        REVEAL_INTERACTION_STARTED: 'INPROGRESS',
      },
    },
    INPROGRESS: {
      always: [{ target: 'DONE', cond: { type: 'threeAttempts' } }],
      on: {
        INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_REACHED: 'DONE',
        INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOTREACHED: {
          target: 'INPROGRESS',
          actions: ['incrementAttempts'],
        },
      },
    },
    DONE: {
      type: 'final',
    },
  },
});

export const patientMachine = Machine({
  id: 'queue_patient',
  initial: 'ACTIVE',
  context: {
    attempts: 0,
  },
  states: {
    ACTIVE: {
      on: {
        INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_REACHED: {
          target: 'PAUSED',
          actions: assign({
            attempts: 0,
          }),
        },
        INTERACTION_COMPLETED_FIELD_CONTACTOUTCOME_NOTREACHED: {
          target: 'PAUSED',
          actions: ['incrementAttempts'],
        },
      },
    },
    PAUSED: {
      after: {
        PAUSE_GIVEN_ATTEMPTS: 'ACTIVE',
      },
    },
  },
});

export const machines = [patientMachine, caseMachine];

// This is a cut corner to be able to start with nice x/y coordinates for the nodes
// This will eventually live as part of the definition services side
const caseGraph = {
  nodes: [
    {
      id: 'case.OPEN',
      nodeType: 'STATUS',
      status: 'OPEN',
      type: 'state',
      x: -56,
      y: -173.6666717529297,
    },
    {
      id: 'case.INPROGRESS',
      nodeType: 'STATUS',
      status: 'INPROGRESS',
      type: 'state',
      x: -56.33333206176758,
      y: -30.66666603088379,
    },
    {
      id: 'case.DONE',
      nodeType: 'STATUS',
      status: 'DONE',
      type: 'state',
      x: -59,
      y: 117.33333587646484,
    },
    {
      id: 'case-0',
      nodeType: 'ROOT',
      type: 'state',
      x: 209,
      y: 117.33333587646484,
    },
  ],
  edges: [
    {
      source: 'case.INPROGRESS',
      target: 'case.DONE',
      type: 'emptyEdge',
      edgeType: 'COMPOUND',
      compoundData: [
        {
          source: 'case.INPROGRESS',
          target: 'case.DONE',
          label: { text: 'INTERACTION_COMPLETED_FIELD_REACHED_TRUE' },
        },
        { source: 'case.INPROGRESS', target: 'case.DONE', label: { text: '' } },
      ],
    },
    {
      id: 'case:0:0',
      source: 'case-0',
      target: 'case.DONE',
      type: 'emptyEdge',
      edgeType: 'ROOT',
      edge: {
        source: 'case',
        target: 'case.DONE',
        label: { text: 'INTERACTION_COMPLETED_FIELD_NEEDSOUTREACH_FALSE' },
      },
    },
    {
      source: 'case.OPEN',
      target: 'case.INPROGRESS',
      type: 'emptyEdge',
      edgeType: 'SIMPLE',
      edge: {
        source: 'case.OPEN',
        target: 'case.INPROGRESS',
        label: { text: 'REVEAL_INTERACTION_STARTED' },
      },
    },
  ],
};

const patientGraph = {
  nodes: [
    {
      id: 'queue_patient.ACTIVE',
      nodeType: 'STATUS',
      status: 'ACTIVE',
      type: 'state',
      x: -118.66666412353516,
      y: 67.33333587646484,
    },
    {
      id: 'queue_patient.PAUSED',
      nodeType: 'STATUS',
      status: 'PAUSED',
      type: 'state',
      x: -119.33333587646484,
      y: -152.6666717529297,
    },
  ],
  edges: [
    {
      source: 'queue_patient.ACTIVE',
      target: 'queue_patient.PAUSED',
      type: 'emptyEdge',
      edgeType: 'COMPOUND',
      compoundData: [
        {
          source: 'queue_patient.ACTIVE',
          target: 'queue_patient.PAUSED',
          label: { text: 'INTERACTION_COMPLETED_FIELD_REACHED_TRUE' },
        },
        {
          source: 'queue_patient.ACTIVE',
          target: 'queue_patient.PAUSED',
          label: { text: 'INTERACTION_COMPLETED_FIELD_REACHED_FALSE' },
        },
      ],
    },
    {
      source: 'queue_patient.PAUSED',
      target: 'queue_patient.ACTIVE',
      type: 'emptyEdge',
      edgeType: 'SIMPLE',
      edge: {
        source: 'queue_patient.PAUSED',
        target: 'queue_patient.ACTIVE',
        label: {
          text: 'xstate.after(PAUSE_GIVEN_ATTEMPTS)#queue_patient.PAUSED',
        },
      },
    },
  ],
};

export const graphs = { queue_patient: patientGraph, case: caseGraph };
