import { createAction } from '@reduxjs/toolkit';
import {
  PATIENT_FETCH_START,
  PATIENT_FETCH_SUCCESS,
  PATIENT_FETCH_FAIL,
  DRUG_OVERVIEW_FETCH_START,
  DRUG_OVERVIEW_FETCH_SUCCESS,
  DRUG_OVERVIEW_FETCH_FAIL,
  CLAIM_VIEWER_FETCH_START,
  CLAIM_VIEWER_FETCH_SUCCESS,
  CLAIM_VIEWER_FETCH_FAIL,
  OPIOID_DETAIL_FETCH_START,
  OPIOID_DETAIL_FETCH_SUCCESS,
  OPIOID_DETAIL_FETCH_FAIL,
  PATIENT_UPSERT_START,
  PATIENT_UPSERT_SUCCESS,
  PATIENT_UPSERT_FAIL,
} from './constants';

export function fetchPatient(id: string) {
  return { type: PATIENT_FETCH_START, payload: { id } };
}

export function fetchPatientSuccess(id: string, patient: any) {
  return {
    type: PATIENT_FETCH_SUCCESS,
    payload: { id, data: patient },
  };
}

export function fetchPatientFail(id: string, error: any) {
  return { type: PATIENT_FETCH_FAIL, payload: { id, error } };
}

export function fetchPatientDrugOverview(id: string, patientId: string) {
  return { type: DRUG_OVERVIEW_FETCH_START, payload: { id, patientId } };
}

export function fetchPatientDrugOverviewSuccess(id: string, data: any) {
  return { type: DRUG_OVERVIEW_FETCH_SUCCESS, payload: { id, data } };
}

export function fetchPatientDrugOverviewFail(error: any) {
  return { type: DRUG_OVERVIEW_FETCH_FAIL, payload: { error } };
}

export function fetchPatientClaimView(id: string, patientId: string) {
  return { type: CLAIM_VIEWER_FETCH_START, payload: { id, patientId } };
}

export function fetchPatientClaimViewSuccess(id: string, data: any) {
  return { type: CLAIM_VIEWER_FETCH_SUCCESS, payload: { id, data } };
}

export function fetchPatientClaimViewFail(error: any) {
  return { type: CLAIM_VIEWER_FETCH_FAIL, payload: { error } };
}

export function fetchPatientOpioidView(id: string, patientId: string) {
  return { type: OPIOID_DETAIL_FETCH_START, payload: { id, patientId } };
}

export function fetchPatientOpioidViewSuccess(id: string, data: any) {
  return { type: OPIOID_DETAIL_FETCH_SUCCESS, payload: { id, data } };
}

export function fetchPatientOpioidViewFail(id: string, error: any) {
  return { type: OPIOID_DETAIL_FETCH_FAIL, payload: { id, error } };
}

export const upsertPatientStart = createAction(
  PATIENT_UPSERT_START,
  (id: string, patient: any) => {
    return {
      payload: {
        id,
        patient,
      },
    };
  },
);

export const upsertPatientSuccess = createAction(
  PATIENT_UPSERT_SUCCESS,
  (id: string, data: any) => {
    return {
      payload: {
        id,
        data,
      },
    };
  },
);

export const upsertPatientFail = createAction(
  PATIENT_UPSERT_FAIL,
  (id: string, error) => {
    return {
      payload: {
        id,
        error,
      },
    };
  },
);
