import { Button, CircularProgress } from '@material-ui/core';
import styles from './ExportButton.css';

type ExportComponentProps = {
  disabled: boolean;
  handleClick: () => void;
  isFetching: boolean;
};

const Export = ({
  disabled,
  handleClick,
  isFetching,
}: ExportComponentProps) => (
  <Button disabled={disabled || isFetching} onClick={handleClick}>
    {isFetching ? (
      <>
        <CircularProgress
          variant="indeterminate"
          size={22}
          className={styles.loading}
        />
        Building Export...
      </>
    ) : (
      'Export'
    )}
  </Button>
);

export default Export;
