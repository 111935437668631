import { fetchGraphqlQuery } from '../common/api';
import {
  fetchCaseDetailsQuery,
  fetchCasesQuery,
  upsertCaseMutation,
  bulkCreateCasesMutation,
} from './queries';

export async function fetchCaseDetails(id, token) {
  const result = await fetchGraphqlQuery(fetchCaseDetailsQuery, { id }, token);

  return { errors: result.errors, case: result.data.node };
}

export async function fetchCases(
  filter,
  sortBy,
  sortOrder,
  token,
  continuationToken = null,
) {
  const result = await fetchGraphqlQuery(
    fetchCasesQuery,
    {
      filter,
      sortBy,
      sortOrder,
      continuationToken,
    },
    token,
  );

  return { errors: result.errors, data: result.data.cases };
}

export async function upsertCase(caseRecord, token) {
  const result = await fetchGraphqlQuery(
    upsertCaseMutation,
    { case: caseRecord },
    token,
  );

  return { errors: result.errors, data: result.data.upsertCase };
}

export async function bulkCreateCases(bulkCreateCasesRecord, token) {
  const result = await fetchGraphqlQuery(
    bulkCreateCasesMutation,
    { bulkCreateCasesInput: bulkCreateCasesRecord },
    token,
  );

  return { errors: result.errors, data: result.data.bulkCreateCases };
}
