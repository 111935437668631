export const QUEUE_FETCH_NEXT_SESSION = 'QUEUE/QUEUE_NEXT_SESSION_FETCH_START';
export const QUEUE_FETCH_NEXT_SESSION_SUCCESS =
  'QUEUE/QUEUE_NEXT_SESSION_FETCH_SUCCESS';
export const QUEUE_FETCH_NEXT_SESSION_FAIL =
  'QUEUE/QUEUE_NEXT_SESSION_FETCH_FAIL';

export const QUEUE_COMPLETE_INTERACTION =
  'QUEUE/QUEUE_COMPLETE_INTERACTION_UPSERT_START';
export const QUEUE_COMPLETE_INTERACTION_SUCCESS =
  'QUEUE/QUEUE_COMPLETE_INTERACTION_UPSERT_SUCCESS';
export const QUEUE_COMPLETE_INTERACTION_FAIL =
  'QUEUE/QUEUE_COMPLETE_INTERACTION_UPSERT_FAIL';

export const QUEUE_SKIP_SESSION = 'QUEUE/QUEUE_SKIP_SESSION_UPSERT_START';
export const QUEUE_SKIP_SESSION_SUCCESS =
  'QUEUE/QUEUE_SKIP_SESSION_UPSERT_SUCCESS';
export const QUEUE_SKIP_SESSION_FAIL = 'QUEUE/QUEUE_SKIP_SESSION_UPSERT_FAIL';

export const QUEUE_FETCH_STATS = 'QUEUE/QUEUE_STATS_FETCH_START';
export const QUEUE_FETCH_STATS_SUCCESS = 'QUEUE/QUEUE_STATS_FETCH_SUCCESS';
export const QUEUE_FETCH_STATS_FAIL = 'QUEUE/QUEUE_STATS_FETCH_FAIL';

export const QUEUE_FETCH_CONTEXT_FOR_CONTACT =
  'QUEUE/QUEUE_CONTEXT_FOR_CONTACT_FETCH_START';
export const QUEUE_FETCH_CONTEXT_FOR_CONTACT_SUCCESS =
  'QUEUE/QUEUE_CONTEXT_FOR_CONTACT_FETCH_SUCCESS';
export const QUEUE_FETCH_CONTEXT_FOR_CONTACT_FAIL =
  'QUEUE/QUEUE_CONTEXT_FOR_CONTACT_FETCH_FAIL';
export const QUEUE_SESSION_DISMOUNT = 'QUEUE_SESSION_DISMOUNT';
