import { isNil, isNotNil } from 'ramda';
import { CaseRecord } from 'app/cases/types';
import SaveButton from 'app/common/components/SaveButton/SaveButton';
import InteractionContact from 'app/interactions/components/InteractionContact/InteractionContact';
import InteractionForm from 'app/interactions/components/InteractionForm/InteractionForm';
import { InteractionRecord } from 'app/interactions/types';
import { getContactTypeFromInteraction } from 'app/interactions/utility';
import styles from './StartInteraction.css';

type StartInteractionProps = {
  interaction: InteractionRecord;
  activeCase: string;
  onChange: (i: any) => void;
  onChangeActiveCase: (c: CaseRecord) => void;
  onContinue: () => void;
  onCancel: () => void;
  isErrored: boolean;
  isCompleting: boolean;
  isCanceling: boolean;
  isRunning: boolean;
};

const StartInteraction = ({
  interaction,
  activeCase,
  onChange,
  onChangeActiveCase,
  onContinue,
  onCancel,
  isErrored,
  isCompleting,
  isCanceling,
  isRunning,
}: StartInteractionProps) => {
  return (
    <>
      <div className={styles.interactionHeader}>
        <h3>Start Interaction</h3>
      </div>
      <>
        <InteractionContact contact={interaction?.contact} disableSwitching />
        {interaction &&
          interaction.cases &&
          (interaction.cases as CaseRecord[]).length > 0 &&
          isNotNil(activeCase) && (
            <InteractionForm
              isLoading={isNil(interaction)}
              interaction={interaction}
              activeCase={activeCase}
              onChange={onChange}
              onCaseChange={onChangeActiveCase}
              contactType={getContactTypeFromInteraction(interaction)}
              interactionLevelOnly
              readOnly={isCanceling || isCompleting}
              caseLevelOnly={false}
              showCampaignTitle={false}
            />
          )}
      </>
      <div className={styles.interactionFooter}>
        {isErrored && (
          <div className={styles.interactionErrors}>
            Unexpected error while saving interaction. Please try again.
          </div>
        )}
        <div className={styles.interactionButtons}>
          <SaveButton
            variant="outlined"
            color="default"
            className={styles.interactionButton}
            onClick={onCancel}
            isSaving={isCanceling}
            disabled={isCompleting || isRunning}
          >
            Cancel
          </SaveButton>
          <SaveButton
            className={styles.interactionButton}
            isSaving={isCompleting}
            disabled={isCanceling || isRunning}
            onClick={onContinue}
          >
            Continue
          </SaveButton>
        </div>
      </div>
    </>
  );
};

export default StartInteraction;
