import {
  Button,
  MenuItem,
  Paper,
  SvgIcon,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { is, isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaignsStart } from 'app/campaigns/actions';
import { getLatestCampaigns } from 'app/campaigns/selectors';
import { CampaignRecord } from 'app/campaigns/types';
import { bulkCreateCases } from 'app/cases/actions';
import { BulkCreateCasesRecord } from 'app/cases/types';
import SaveButton from 'app/common/components/SaveButton';
import { ErrorRecord, GraphQlErrorRecord } from 'app/common/types';
import { useOptimisticMutation } from 'app/common/utilities/hooks';
import Message from '../../../common/components/Message/Message';
import styles from './UploadCases.css';

export type UploadCasesPropTypes = {
  campaigns: CampaignRecord[];
  isSaving: boolean;
  errors: GraphQlErrorRecord[];
  onSubmit: (cases: BulkCreateCasesRecord) => void;
  onCancel: () => void;
  isUploading: boolean;
};

const prepPatientArray = (patientListString: string): string[] => {
  const patientArray = patientListString.split(',');
  const cleanPatientArray = patientArray.map((patient) => {
    return patient.replaceAll(/[^\dA-Za-z]/g, '').toLocaleLowerCase();
  });
  return cleanPatientArray;
};

const UploadCases = ({
  campaigns,
  isSaving,
  errors,
  onCancel,
  onSubmit,
  isUploading,
}: UploadCasesPropTypes) => {
  const {
    handleSubmit,
    control,
    formState: { errors: fieldErrors },
  } = useForm();

  let formError = false;

  errors.forEach((error) => {
    if (is(GraphQlErrorRecord, error)) {
      if (error.path.count() > 1) {
        const field = error.path.get(2);

        // TODO the server side errors do not get cleared out on the form when user changes value
        // Checking for dirty flag however does not clear after a failed attempt
        if (!isNil(field) && isNil(fieldErrors[field])) {
          fieldErrors[field] = { type: 'server', message: error.message };
        }
      } else {
        formError = true;
      }
    }
    if (is(ErrorRecord, error)) {
      formError = true;
    }
  });

  return (
    <div className={styles.container}>
      <Paper className={styles.formContainer}>
        {isUploading && (
          <Message icon={<CircularProgress />} message="Uploading cases..." />
        )}
        {!isUploading && (
          <form
            className={styles.form}
            onSubmit={handleSubmit((data: any) => {
              onSubmit(
                new BulkCreateCasesRecord({
                  campaignEntityId: data.campaign,
                  patients: prepPatientArray(data.patients),
                }),
              );
            })}
          >
            <div className={styles.formRow}>
              <Paper elevation={2} className={styles.headerIcon}>
                <SvgIcon fontSize="large">
                  <Description />
                </SvgIcon>
              </Paper>
              <div className={styles.headerText}>Bulk Create Cases</div>
            </div>
            <div className={styles.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="outlined"
                    classes={{ root: styles.input }}
                    label="Campaign"
                    disabled={isUploading}
                    helperText={
                      fieldErrors.campaign ? (
                        <>
                          {fieldErrors.campaign.type === 'required' && (
                            <div>Campaign is required</div>
                          )}
                          {fieldErrors.campaign.type === 'server' && (
                            <div>
                              {fieldErrors.campaign.message?.toString()}
                            </div>
                          )}
                        </>
                      ) : null
                    }
                    error={!isNil(fieldErrors.campaign)}
                  >
                    {campaigns.map(
                      (x) =>
                        x.issueFilter.issueFilterType === 'Patient' && (
                          <MenuItem key={x.entityId} value={x.entityId}>
                            {x.name}
                          </MenuItem>
                        ),
                    )}
                  </TextField>
                )}
                name="campaign"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={null}
              />
            </div>
            <div className={styles.formRow}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    label="Comma separated Patients List"
                    variant="outlined"
                    minRows={10}
                    classes={{ root: styles.input }}
                    disabled={isUploading}
                    helperText={
                      fieldErrors.patients ? (
                        <>
                          {fieldErrors.patients.type === 'required' && (
                            <div>Patients are required</div>
                          )}
                          {fieldErrors.patients.type === 'server' && (
                            <div>{fieldErrors.patients.message?.toString()}</div>
                          )}
                        </>
                      ) : (
                        <>
                          The comma separated list of patients to create cases
                          for.
                        </>
                      )
                    }
                    error={!isNil(fieldErrors.patients)}
                  />
                )}
                name="patients"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={null}
              />
            </div>
            {formError && (
              <div className={styles.formErrorText}>
                Something went wrong, please try again or contact us.
              </div>
            )}
            <div className={styles.submitRow}>
              <Button disabled={isSaving || isUploading} onClick={onCancel}>
                Cancel
              </Button>
              <SaveButton
                type="submit"
                isSaving={isSaving}
                disabled={isUploading}
              >
                Create
              </SaveButton>
            </div>
          </form>
        )}
      </Paper>
    </div>
  );
};

const UploadCasesContainer = () => {
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);

  const {
    mutation,
    optimisticId,
    createdId: newId,
    isRunning,
    hasCompleted,
    isErrored,
    errors,
  } = useOptimisticMutation(bulkCreateCases);

  const campaigns = useSelector((state) => getLatestCampaigns(state));
  useEffect(() => {
    dispatch(fetchCampaignsStart());
  }, []);

  const handleUploadCases = (bulkCreateCasesRecord: BulkCreateCasesRecord) => {
    setIsUploading(true);
    mutation(bulkCreateCasesRecord);
  };

  const handleCancel = () => {
    navigate('/cases');
  };

  useEffect(() => {
    if (hasCompleted && !isErrored) {
      navigate('/cases');
    }
    if (isErrored) {
      setIsUploading(false);
    }
  }, [hasCompleted, isErrored]);

  return (
    <UploadCases
      campaigns={campaigns
        .toSet()
        .sortBy((x: CampaignRecord) => x.name?.toUpperCase())}
      onSubmit={handleUploadCases}
      onCancel={handleCancel}
      isSaving={isRunning}
      errors={errors}
      isUploading={isUploading}
    />
  );
};

export default UploadCasesContainer;
