/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable indent */
import { format } from 'date-fns';
import { OrderedSet, Record } from 'immutable';
import AgeRange from 'app/filters/components/AgeRange';
import { IssueFilterType } from 'app/filters/constants';
import { commonClassDefaultsFactory } from 'app/filters/definitions/defaults';
import {
  IFilterConfiguration,
  AgeRangeFilterRecord,
  AgeRangeFilterBackendRecord,
  SelectValueRecord,
} from 'app/filters/types';
import { StoredFilterType } from 'app/viewtemplates/constants';

const summaryFormatter = (value: AgeRangeFilterRecord) => {
  const asOfFlag = value.fromDate !== null && value.fromDate !== undefined;
  return `Age from  
    ${value.minAge} 
    through
    ${value.maxAge} 
    years old
    ${asOfFlag ? ' as of ' : ''}
    ${asOfFlag ? format(new Date(value.fromDate), 'MM/dd/yyyy') : ''} 
    `;
};

class AgeRangeConfig
  extends Record({
    ...commonClassDefaultsFactory(AgeRangeFilterRecord),
    name: 'ageRange',
    title: 'Age Range',
    targetType: IssueFilterType.Patient,
    summaryFormatter,
    component: (props: any) => <AgeRange {...props} />,
    storedValueType: StoredFilterType.AgeRange,
    storedValueConstructor: (value: {
      minAge: number;
      maxAge: number;
      fromDate: Date;
    }) => {
      return new AgeRangeFilterBackendRecord({
        minAge: value.minAge,
        maxAge: value.maxAge,
        fromDate: value.fromDate,
      });
    },
    templateToFilterValueConverter: (value: AgeRangeFilterBackendRecord) =>
      new AgeRangeFilterRecord({
        minAge: value.minAge,
        maxAge: value.maxAge,
        fromDate: value.fromDate,
      }),
    filterToTemplateValueConverter: (value: AgeRangeFilterRecord) =>
      new AgeRangeFilterBackendRecord({
        minAge: value.minAge,
        maxAge: value.maxAge,
        fromDate: value.fromDate,
      }),
    defaultValue: () =>
      new AgeRangeFilterRecord({
        minAge: 0,
        maxAge: 0,
        fromDate: null,
      }),
  })
  implements
    IFilterConfiguration<
      AgeRangeFilterRecord,
      OrderedSet<SelectValueRecord>,
      AgeRangeFilterRecord,
      AgeRangeFilterBackendRecord
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new AgeRangeConfig();
