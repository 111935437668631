import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import { upsertCommentMutation } from './queries';

export function upsertComment(workItemId, commentId, message, token) {
  return fetchGraphqlQuery(
    upsertCommentMutation,
    { workItemId, commentId, message },
    token,
  ).then(throwIfErrors);
}
