export const CAMPAIGN_ADD = 'CAMPAIGN/CAMPAIGN_ADD_UPSERT_START';
export const CAMPAIGN_ADD_SUCCESS = 'CAMPAIGN/CAMPAIGN_ADD_UPSERT_SUCCESS';
export const CAMPAIGN_ADD_FAIL = 'CAMPAIGN/CAMPAIGN_ADD_UPSERT_FAIL';

export const CAMPAIGN_EDIT = 'CAMPAIGN/CAMPAIGN_EDIT_UPSERT_START';
export const CAMPAIGN_EDIT_SUCCESS = 'CAMPAIGN/CAMPAIGN_EDIT_UPSERT_SUCCESS';
export const CAMPAIGN_EDIT_FAIL = 'CAMPAIGN/CAMPAIGN_EDIT_UPSERT_FAIL';

export const CAMPAIGN_CLONE_UPSERT = 'CAMPAIGN/CAMPAIGN_CLONE_UPSERT_START';
export const CAMPAIGN_CLONE_UPSERT_SUCCESS =
  'CAMPAIGN/CAMPAIGN_CLONE_UPSERT_SUCCESS';
export const CAMPAIGN_CLONE_UPSERT_FAIL = 'CAMPAIGN/CAMPAIGN_CLONE_UPSERT_FAIL';

export const CAMPAIGN_DELETE_START = 'CAMPAIGN/CAMPAIGN_DELETE_START';
export const CAMPAIGN_DELETE_SUCCESS = 'CAMPAIGN/CAMPAIGN_DELETE_SUCCESS';
export const CAMPAIGN_DELETE_FAIL = 'CAMPAIGN/CAMPAIGN_DELETE_FAIL';

export const CAMPAIGN_QUERY_FETCH_START = 'CAMPAIGN/CAMPAIGN_QUERY_FETCH_START';
export const CAMPAIGN_QUERY_FETCH_SUCCESS =
  'CAMPAIGN/CAMPAIGN_QUERY_FETCH_SUCCESS';
export const CAMPAIGN_QUERY_FETCH_FAIL = 'CAMPAIGN/CAMPAIGN_QUERY_FETCH_FAIL';

export const CAMPAIGN_DETAILS_FETCH_START =
  'CAMPAIGN/CAMPAIGN_DETAILS_FETCH_START';
export const CAMPAIGN_DETAILS_FETCH_SUCCESS =
  'CAMPAIGN/CAMPAIGN_DETAILS_FETCH_SUCCESS';
export const CAMPAIGN_DETAILS_FETCH_FAIL =
  'CAMPAIGN/CAMPAIGN_DETAILS_FETCH_FAIL';

export const CAMPAIGN_EDIT_FILTER_EDIT_OPEN =
  'CAMPAIGN/CAMPAIGN_EDIT_FILTER_EDIT_OPEN';
