import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import { filterTypesQuery } from './queries';

export function fetchFilters(type, token) {
  return fetchGraphqlQuery(
    filterTypesQuery,
    {
      name: type,
    },
    token,
  )
    .then(throwIfErrors)
    .then((response) => response.data.fields.inputFields);
}
