import { Link } from '@reach/router';
import classnames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import Tag from '../../../common/components/Tag/Tag';
import styles from './columns.css';

const formatDate = (date) => format(date, 'MM/dd/yyyy');

const columnDefinitions = [
  {
    id: 'ClaimNumber',
    name: 'Claim Number',
    width: 3,
    valueSelector: (claim) => {
      // If claim numbers are integers, sort them like integers
      // Otherwise, sort them like strings
      const parsed = parseInt(claim.claimNumber, 10);
      if (Number.isNaN(parsed)) return claim.claimNumber;
      return parsed;
    },
    render: (claim) => (
      <Link to={`/workitems/${claim.workItem}`}>{claim.claimNumber}</Link>
    ),
    placeholderWidth: 0.7,
  },
  {
    id: 'FilledDate',
    name: 'Filled Date',
    width: 2,
    valueSelector: (claim) => claim.filledDate,
    render: (claim) => formatDate(claim.filledDate),
    placeholderWidth: 0.4,
  },
  {
    id: 'ClaimStatus',
    name: 'Status',
    width: 1.5,
    valueSelector: (claim) => claim.claimStatus,
    render: (claim) => claim.claimStatus,
    placeholderWidth: 0.5,
  },
  {
    id: 'RxNumber',
    name: 'RxNumber',
    width: 1.5,
    valueSelector: (claim) => claim.rxNumber,
    render: (claim) => claim.rxNumber,
  },
  {
    id: 'RejectCodes',
    name: 'Reject Codes',
    width: 2,
    valueSelector: (claim) => claim.rejectCodes,
    render: (claim) => claim.rejectCodes.join(', '),
    placeholderWidth: 0.3,
  },
  {
    id: 'RejectMessages',
    name: 'Primary Message',
    width: 5,
    valueSelector: (claim) => claim.rejectMessage,
    render: (claim) => claim.rejectMessage,
    placeholderWidth: 0.6,
  },
  {
    id: 'DrugName',
    name: 'Drug Name',
    width: 5,
    valueSelector: (claim) => claim.drugName?.toUpperCase(),
    render: (claim) => claim.drugName,
    placeholderWidth: 0.6,
  },
  {
    id: 'Assignee',
    name: 'Assignee',
    width: 2,
    valueSelector: (claim) => claim.assignee?.toUpperCase(),
    render: (claim) => (claim.assignee ? claim.assignee : 'Unassigned'),
    placeholderWidth: 0.7,
  },
  {
    id: 'status',
    name: 'Work Item Status',
    width: 2,
    valueSelector: (claim) => claim.status,
    render: (claim) =>
      claim.status && (
        <Tag
          className={classnames({
            [styles.tag]: true,
            [styles.blue]: claim.status === 'Open',
            [styles.orange]: claim.status === 'In Progress',
            [styles.green]: claim.status === 'Done',
          })}
          text={claim.status}
        />
      ),
    placeholderWidth: 0.5,
  },
];

export default columnDefinitions;
