import { AnyAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { OrderedMap, Set } from 'immutable';
import { isNil } from 'ramda';
import { combineReducers } from 'redux-immutable';
import filterDefinitions from 'app/filters/definitions';
import { campaignEditFilterOpen } from '../../campaigns/actions';
import { IFilterConfiguration } from '../../filters/types';
import {
  campaignEditFetchFilterOptionsStart,
  campaignEditFetchFilterOptionsSuccess,
  campaignEditFiltersClear,
  campaignEditFilterSetApplied,
  campaignEditFilterSetEdited,
  campaignEditUpdateFilter,
} from '../actions';
import { ComponentRecord } from '../types';
import {
  handleFilterFetchStart,
  handleFilterFetchSuccess,
  handleFilterUpdated,
  handleSetApplied,
  handleSetEditing,
} from './filterComponentHelpers';

const filterReducer = createReducer(
  OrderedMap<string, ComponentRecord>(),
  (builder) => {
    builder
      .addCase(campaignEditFiltersClear, () =>
        OrderedMap<string, ComponentRecord>(),
      )
      .addCase(campaignEditFilterSetEdited, (state, action) =>
        handleSetEditing(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(campaignEditFilterSetApplied, (state, action) =>
        handleSetApplied(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(campaignEditUpdateFilter, (state, action) =>
        handleFilterUpdated(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(campaignEditFilterOpen, (state, action: AnyAction) => {
        const filters = action.payload.filters as Set<any>;

        const componentRecords = filters
          .toArray()
          .map(
            (filter: {
              id: string;
              name: string;
              value: any;
              options: any;
            }) => {
              // TODO: Questionable typing fix typing workaround.
              const config = filterDefinitions.get(
                filter.id,
              ) as IFilterConfiguration<any, any, any, any>;

              const { id, name, value, options } = filter;

              if (isNil(config))
                throw new Error(`Cannot find config for ${name}`);

              return [
                id,
                new ComponentRecord({
                  name,
                  options,
                  defaultValue: config.defaultValue(),
                  isEditing: false,
                  value: config.templateToFilterValueConverter(value),
                }),
              ];
            },
          ) as [[string, ComponentRecord]];

        return OrderedMap<string, ComponentRecord>(componentRecords);
      })
      .addCase(campaignEditFetchFilterOptionsSuccess, (state, action) =>
        handleFilterFetchSuccess(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addCase(campaignEditFetchFilterOptionsStart, (state, action) =>
        handleFilterFetchStart(
          state as unknown as OrderedMap<string, ComponentRecord>,
          action,
        ),
      )
      .addDefaultCase((state) => state);
  },
);

const campaignEditReducer = combineReducers({
  filters: filterReducer,
});

export default campaignEditReducer;
