import { OrderedSet, Record, Set } from 'immutable';
import { isNil } from 'ramda';
import ChipInput from 'app/filters/components/ChipInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringArrayDefaults } from 'app/filters/definitions/defaults';
import {
  IClaimFilterConfiguration,
  SelectValueRecord,
  KeyValueRecord,
} from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class PharmacyIdConfig
  extends Record({
    ...stringArrayDefaults,
    name: 'pharmacyId',
    title: 'Pharmacy',
    targetType: IssueFilterType.Claim,
    component: (props: any) => (
      <ChipInput
        optionsListOnly={false}
        placeholder="Enter Pharmacy IDs"
        {...props}
      />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: Set<string>) => {
      if (value.isEmpty()) return true;
      if (isNil(workItem.claim.pharmacyId)) return false;
      const { pharmacyId } = workItem.claim;
      return value.some((x) => pharmacyId === x);
    },
  })
  implements
    IClaimFilterConfiguration<
      OrderedSet<SelectValueRecord>,
      OrderedSet<SelectValueRecord>,
      Set<string>,
      OrderedSet<KeyValueRecord>
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new PharmacyIdConfig();
