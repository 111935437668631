import { Record, Map } from 'immutable';

export enum WorkflowTypes {
  Case = 'CASE',
  QueuePatient = 'QUEUE_PATIENT',
  QueuePrescriber = 'QUEUE_PRESCRIBER',
}

export class WorkflowDefinitionRecord extends Record({
  id: undefined as string | undefined,
  entityId: undefined as string | undefined,
  documentVersion: undefined as number | undefined,
  name: 'Default',
  workflowType: undefined as WorkflowTypes | undefined,
}) {}

export class WorkflowDefinitionsStateRecord extends Record({
  workflowDefinitions: Map(),
}) {}
