import { combineReducers } from 'redux-immutable';
import {
  PATIENT_CLAIMS_HISTORY_FETCH_START,
  PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS,
  PATIENT_CLAIMS_HISTORY_FETCH_FAIL,
  PATIENT_DRUG_HISTORY_FETCH_START,
  PATIENT_DRUG_HISTORY_FETCH_SUCCESS,
  PATIENT_DRUG_HISTORY_FETCH_FAIL,
} from '../../claims/constants';
import {
  COMMENT_ADD,
  COMMENT_ADD_SUCCESS,
  COMMENT_ADD_FAIL,
  COMMENT_DELETE,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_FAIL,
  COMMENT_EDIT,
  COMMENT_EDIT_SUCCESS,
  COMMENT_EDIT_FAIL,
} from '../../comments/constants';
import { persistReducer } from '../../hydrate/persist';
import {
  WORKITEM_DETAILS_FETCH_START,
  WORKITEM_DETAILS_FETCH_SUCCESS,
  WORKITEM_DETAILS_FETCH_FAIL,
  WORKITEM_STATUSES_FETCH_START,
  WORKITEM_STATUSES_FETCH_SUCCESS,
  WORKITEM_STATUSES_FETCH_FAIL,
  WORKITEM_UPSERT_START,
  WORKITEM_UPSERT_SUCCESS,
  WORKITEM_UPSERT_FAIL,
} from '../../workitems/constants';
import {
  WorkItemDetailRecord,
  DetailRecord,
  StatusesRecord,
  PatientDrugHistoryRecord,
  CommentsRecord,
  PatientClaimsHistoryRecord,
} from '../types';

const detailReducer = (state = new DetailRecord(), action) => {
  switch (action.type) {
    case WORKITEM_DETAILS_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case WORKITEM_DETAILS_FETCH_SUCCESS:
      return state.merge({ isFetching: false, error: null });
    case WORKITEM_DETAILS_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    case WORKITEM_UPSERT_START:
      return state.merge({ isSaving: true });
    case WORKITEM_UPSERT_SUCCESS:
      return state.merge({ isSaving: false });
    case WORKITEM_UPSERT_FAIL:
      return state.merge({ isSaving: false });
    default:
      return state;
  }
};

const statusesReducer = (state = new StatusesRecord(), action) => {
  switch (action.type) {
    case WORKITEM_STATUSES_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case WORKITEM_STATUSES_FETCH_SUCCESS:
      return state.merge({ isFetching: false, error: null });
    case WORKITEM_STATUSES_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    default:
      return state;
  }
};

const commentsReducer = (state = new CommentsRecord(), action) => {
  switch (action.type) {
    case COMMENT_ADD: {
      const id = action.payload.comment.id;
      return state.merge({
        addingComments: state.addingComments.add(id),
        erroredComments: state.erroredComments.delete(id),
      });
    }
    case COMMENT_ADD_SUCCESS: {
      const id = action.payload.optimisticId;
      return state.merge({
        addingComments: state.addingComments.delete(id),
        erroredComments: state.erroredComments.delete(id),
      });
    }
    case COMMENT_ADD_FAIL:
    case COMMENT_EDIT_FAIL:
    case COMMENT_DELETE_FAIL: {
      const id = action.payload.optimisticId || action.payload.id;
      return state.merge({
        erroredComments: state.erroredComments.add(id),
      });
    }
    case COMMENT_DELETE: {
      const id = action.payload.id;
      return state.merge({
        deletingComments: state.deletingComments.add(id),
        erroredComments: state.erroredComments.delete(id),
      });
    }
    case COMMENT_DELETE_SUCCESS: {
      const id = action.payload.id;
      return state.merge({
        deletingComments: state.deletingComments.delete(id),
        erroredComments: state.erroredComments.delete(id),
      });
    }
    case COMMENT_EDIT: {
      const id = action.payload.id;
      return state.merge({
        editingComments: state.editingComments.add(id),
        erroredComments: state.erroredComments.delete(id),
      });
    }
    case COMMENT_EDIT_SUCCESS: {
      const id = action.payload.id;
      return state.merge({
        editingComments: state.editingComments.delete(id),
        erroredComments: state.erroredComments.delete(id),
      });
    }
    default:
      return state;
  }
};

const patientClaimsHistoryReducer = (
  state = new PatientClaimsHistoryRecord(),
  action,
) => {
  switch (action.type) {
    case PATIENT_CLAIMS_HISTORY_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case PATIENT_CLAIMS_HISTORY_FETCH_SUCCESS:
      return state.merge({ isFetching: false, error: null });
    case PATIENT_CLAIMS_HISTORY_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    default:
      return state;
  }
};

const patientDrugHistoryReducer = (
  state = new PatientDrugHistoryRecord(),
  action,
) => {
  switch (action.type) {
    case PATIENT_DRUG_HISTORY_FETCH_START:
      return state.merge({ isFetching: true, error: null });
    case PATIENT_DRUG_HISTORY_FETCH_SUCCESS:
      return state.merge({ isFetching: false, error: null });
    case PATIENT_DRUG_HISTORY_FETCH_FAIL:
      return state.merge({ isFetching: false, error: action.payload.error });
    default:
      return state;
  }
};

export default combineReducers(
  {
    detail: detailReducer,
    statuses: statusesReducer,
    comments: persistReducer('ui/comments', commentsReducer),
    patientClaimsHistory: patientClaimsHistoryReducer,
    patientDrugHistory: patientDrugHistoryReducer,
  },
  new WorkItemDetailRecord(),
);
