import { convertToRaw } from 'draft-js';
import { normalize } from 'normalizr';
import { isNil } from 'ramda';
import { select, call, put, take } from 'redux-saga/effects';
import { deleteNode } from '../common/api';
import { getAccessToken } from '../user/selectors';
import { workItem as workItemSchema } from '../workitems/types';
import {
  addCommentSuccess,
  addCommentFail,
  editCommentSuccess,
  editCommentFail,
  deleteCommentFail,
  deleteCommentSuccess,
} from './actions';
import { upsertComment } from './api';
import { COMMENT_ADD, COMMENT_EDIT, COMMENT_DELETE } from './constants';
import { comments as commentsSchema } from './types';
import { mapResponseToComments } from './utility';

export function* addCommentWorker(action) {
  const { workItemId, comment } = action.payload;
  try {
    const token = yield select(getAccessToken);
    const response = yield call(
      upsertComment,
      workItemId,
      null,
      JSON.stringify(convertToRaw(comment.message)),
      token,
    );

    if (isNil(response.errors)) {
      const comments = mapResponseToComments([response.data.upsertComment]);
      const workItem = {
        id: workItemId,
        comments,
      };
      const data = normalize(workItem, workItemSchema);

      yield put(addCommentSuccess(comment.id, data));
    } else {
      yield put(
        addCommentFail(workItemId, comment.id, response.errors, new Date()),
      );
    }
  } catch (e) {
    yield put(addCommentFail(workItemId, comment.id, e, new Date()));
  }
}

export function* addCommentSource() {
  while (true) {
    const action = yield take(COMMENT_ADD);
    yield call(addCommentWorker, action);
  }
}

export function* editCommentWorker(action) {
  const { id, workItemId, message } = action.payload;
  try {
    const token = yield select(getAccessToken);
    const response = yield call(
      upsertComment,
      null,
      id,
      JSON.stringify(convertToRaw(message)),
      token,
    );

    if (isNil(response.errors)) {
      const comments = normalize(
        mapResponseToComments([response.data.upsertComment]),
        commentsSchema,
      );
      yield put(editCommentSuccess(id, comments));
    } else {
      yield put(editCommentFail(id, workItemId, response.errors, new Date()));
    }
  } catch (e) {
    yield put(editCommentFail(id, workItemId, e, new Date()));
  }
}

export function* editCommentSource() {
  while (true) {
    const action = yield take(COMMENT_EDIT);
    yield call(editCommentWorker, action);
  }
}

export function* deleteCommentWorker(action) {
  const { id, workItemId } = action.payload;
  try {
    const token = yield select(getAccessToken);
    const response = yield call(deleteNode, id, token);

    if (isNil(response.errors)) {
      yield put(deleteCommentSuccess(id));
    } else {
      yield put(deleteCommentFail(id, workItemId, response.errors, new Date()));
    }
  } catch (e) {
    yield put(deleteCommentFail(id, workItemId, e, new Date()));
  }
}

export function* deleteCommentSource() {
  while (true) {
    const action = yield take(COMMENT_DELETE);
    yield call(deleteCommentWorker, action);
  }
}
