import {
  GraphQLObjectType,
  GraphQLID,
  graphqlSync,
  GraphQLSchema,
  GraphQLList,
  GraphQLString,
  GraphQLNonNull,
  GraphQLScalarType,
  GraphQLBoolean,
  GraphQLInt,
  GraphQLFloat,
  GraphQLUnionType,
  GraphQLInterfaceType,
} from 'graphql';
import { isNil } from 'ramda';
import { GraphqlTypes } from 'app/common/types';
import { IssueRecord } from 'app/issues/types';
import { getPharmacy } from 'app/pharmacies/selectors';
import { PharmacyRecord } from 'app/pharmacies/types';
import { getContextForContact } from 'app/queue/selectors';
import { getSession, getSessionById } from 'app/session/selector';
import { getWorkItem } from 'app/workitems/selectors';
import { getCampaignById } from '../../campaigns/selectors';
import { getCase, getCasesByIds } from '../../cases/selectors';
import { getClaim, getClaims } from '../../claims/selectors';
import { ClaimRecord } from '../../claims/types';
import { getInteraction } from '../../interactions/selectors';
import { getIssue } from '../../issues/selectors';
import { getPatient } from '../../patient/selectors';
import { PatientRecord } from '../../patient/types';
import { getPrescriber } from '../../prescribers/selectors';
import { PrescriberRecord } from '../../prescribers/types';
import { getUser } from '../../users/selectors';

function getContactFromId(state, contactId) {
  // This is either a patient or prescriber or pharmacy, lets hope for no collisions
  const patient = getPatient(state, contactId);
  if (!isNil(patient?.id)) return patient;
  const prescriber = getPrescriber(state, contactId);

  if (!isNil(prescriber?.id)) return prescriber;

  const pharmacy = getPharmacy(state, contactId);
  return pharmacy;
}

function recordToGraphqlType(value) {
  switch (value.constructor) {
    case PrescriberRecord:
      return GraphqlTypes.Prescriber;
    case PatientRecord:
      return GraphqlTypes.Patient;
    case ClaimRecord:
      return GraphqlTypes.Claim;
    case PharmacyRecord:
      return GraphqlTypes.Pharmacy;
    case IssueRecord:
      return 'Issue';
    default:
      throw new Error(
        'Unsupported record type provided to recordToGraphqlType()',
      );
  }
}

const dateScalar = new GraphQLScalarType({
  name: 'Date',
  parseValue(value) {
    return new Date(value);
  },
  serialize(value) {
    return value;
  },
});

const NodeInterface = new GraphQLInterfaceType({
  name: 'Node',
  fields: {
    id: { type: GraphQLID },
  },
  resolveType(value) {
    return recordToGraphqlType(value);
  },
});

const eligibilitySpanType = new GraphQLObjectType({
  name: 'EligibilitySpan',
  fields: {
    eligibilityStartDate: { type: dateScalar },
    eligibilityTermDate: { type: dateScalar },
  },
});

const eligibilityForGroupsType = new GraphQLObjectType({
  name: 'EligibilityForGroups',
  fields: {
    group: { type: GraphQLString },
    eligibilities: { type: new GraphQLList(eligibilitySpanType) },
  },
});

const eligibilityType = new GraphQLObjectType({
  name: 'Eligibility',
  fields: {
    eligibilityMatchingKey: { type: GraphQLString },
    patientNumber: { type: GraphQLString },
    startDate: { type: dateScalar },
    termDate: { type: dateScalar },
    eligibilityCarrier: { type: GraphQLString },
    eligibilityForGroups: { type: new GraphQLList(eligibilityForGroupsType) },
  },
});

const intervalType = new GraphQLObjectType({
  name: 'Interval',
  fields: {
    start: { type: dateScalar },
    end: { type: dateScalar },
  },
});

const phoneNumberType = new GraphQLObjectType({
  name: 'PhoneNumber',
  fields: {
    phoneNumber: { type: GraphQLString },
    isPrimary: { type: GraphQLBoolean },
    source: { type: GraphQLString },
    addedOn: { type: dateScalar },
  },
});

const patientType = new GraphQLObjectType({
  name: GraphqlTypes.Patient,
  interfaces: [NodeInterface],
  fields: {
    id: { type: GraphQLID },
    patientId: { type: GraphQLString },
    entityId: { type: GraphQLString },
    name: { type: GraphQLString },
    gender: { type: GraphQLString },
    dateOfBirth: { type: dateScalar },
    phoneNumbers: { type: new GraphQLList(phoneNumberType) },
    hasActiveCancerDiagnosis: { type: GraphQLBoolean },
    eligibility: { type: new GraphQLList(eligibilityType) },
    cancerDiagnoses: { type: new GraphQLList(intervalType) },
    opioidNaiveWindows: { type: new GraphQLList(intervalType) },
    followUpDate: { type: dateScalar },
  },
});

const interactionFieldDefinitionType = new GraphQLObjectType({
  name: 'InteractionFieldDefinition',
  fields: {
    name: { type: GraphQLString },
    type: { type: GraphQLString },
    options: { type: new GraphQLList(GraphQLString) },
    level: { type: GraphQLString },
  },
});

const campaignType = new GraphQLObjectType({
  name: 'Campaign',
  fields: {
    id: { type: GraphQLID },
    entityId: { type: GraphQLID },
    name: { type: GraphQLString },
    prefix: { type: GraphQLString },
    contact: { type: GraphQLString },
    target: { type: GraphQLString },
    interactionFieldDefinitions: {
      type: new GraphQLList(interactionFieldDefinitionType),
    },
    caseWorkflowDefinitionId: { type: GraphQLString },
    includeCasesInQueue: { type: GraphQLBoolean },
  },
});

const userType = new GraphQLObjectType({
  name: 'User',
  fields: {
    id: { type: GraphQLID },
    name: { type: GraphQLString },
    email: { type: GraphQLString },
    pictureUrl: { type: GraphQLString },
    permissions: { type: new GraphQLList(GraphQLString) },
  },
});

const responseType = new GraphQLObjectType({
  name: 'Response',
  fields: {
    level: { type: GraphQLString },
    name: { type: GraphQLString },
    value: { type: GraphQLString },
    caseId: { type: GraphQLString },
  },
});

const workItemType = new GraphQLObjectType({
  name: 'WorkItem',
  fields: {
    id: { type: GraphQLID },
  },
});

const matchedPaidClaimType = new GraphQLObjectType({
  name: 'MatchedPaidClaim',
  fields: {
    claimId: { type: GraphQLString },
    gpi: { type: GraphQLString },
    adjudicatedDate: { type: dateScalar },
    filledDate: { type: dateScalar },
  },
});

const stepTherapyGroupType = new GraphQLObjectType({
  name: 'StepTherapyGroup',
  fields: {
    step: { type: GraphQLString },
    description: { type: GraphQLString },
  },
});

const seniorSavingsModelType = new GraphQLObjectType({
  name: 'SeniorSavingsModel',
  fields: {
    exceedsCopay: { type: GraphQLBoolean },
    maxCopay: { type: GraphQLFloat },
  },
});

const drugListType = new GraphQLObjectType({
  name: 'DrugList',
  fields: {
    id: { type: GraphQLID },
    name: { type: GraphQLString },
  },
});

const gpiType = new GraphQLObjectType({
  name: 'GPI',
  fields: {
    gpi: { type: GraphQLString },
    description: { type: GraphQLString },
    level: { type: GraphQLString },
  },
});

const diagnosisCodeType = new GraphQLObjectType({
  name: 'DiagnosisCode',
  fields: {
    code: { type: GraphQLString },
    description: { type: GraphQLString },
  },
});

const prescriberType = new GraphQLObjectType({
  name: GraphqlTypes.Prescriber,
  interfaces: [NodeInterface],
  fields: {
    id: { type: GraphQLID },
    entityId: { type: GraphQLString },
    gender: { type: GraphQLString },
    phoneNumber: { type: GraphQLString },
    groupPracticeId: { type: GraphQLString },
    groupPracticeMemberCount: { type: GraphQLInt },
    name: { type: GraphQLString },
    npi: { type: GraphQLString },
    organizationName: { type: GraphQLString },
    primarySpecialty: { type: GraphQLString },
    secondarySpecialty: { type: new GraphQLList(GraphQLString) },
  },
});

const pharmacyType = new GraphQLObjectType({
  name: GraphqlTypes.Pharmacy,
  interfaces: [NodeInterface],
  fields: {
    id: { type: GraphQLID },
    entityId: { type: GraphQLString },
    name: { type: GraphQLString },
    npi: { type: GraphQLString },
    phoneNumbers: { type: new GraphQLList(phoneNumberType) },
  },
});

const claimType = new GraphQLObjectType({
  name: GraphqlTypes.Claim,
  interfaces: [NodeInterface],
  fields: {
    id: { type: GraphQLID },
    claimNumber: { type: GraphQLString },
    claimStatus: { type: GraphQLString },
    adjudicatedDate: { type: dateScalar },
    filledDate: { type: dateScalar },
    rxNumber: { type: GraphQLString },
    drugName: { type: GraphQLString },
    ndc: { type: GraphQLString },
    quantityDispensed: { type: GraphQLFloat },
    daysSupply: { type: GraphQLFloat },
    rejectCodes: { type: new GraphQLList(GraphQLString) },
    rejectMessages: { type: new GraphQLList(GraphQLString) },
    copay: { type: GraphQLFloat },
    workItem: {
      type: workItemType,
      resolve: (source, _, context) => {
        return { id: source.workItem };
      },
    },
    isDrugOnFormulary: { type: GraphQLBoolean },
    isDrugProtectedClass: { type: GraphQLBoolean },
    isDrugCompound: { type: GraphQLBoolean },
    isDrugOverTheCounter: { type: GraphQLBoolean },
    coverageStatusSchedule: { type: GraphQLString },
    licsLevel: { type: GraphQLString },
    multiSourceCode: { type: GraphQLString },
    isDrugOpioidCombinator: { type: GraphQLBoolean },
    drugContainsOpioid: { type: GraphQLBoolean },
    hasOpioidCombinatorImpact: { type: GraphQLBoolean },
    isPatientOpioidNaive: { type: GraphQLBoolean },
    dailyMorphineMilligramEquivalent: { type: GraphQLFloat },
    patientTotalMorphineMilligramEquivalent: { type: GraphQLFloat },
    patientPotentialTotalMorphineMilligramEquivalent: { type: GraphQLFloat },
    patientEndingTotalMorphineMilligramEquivalent: { type: GraphQLFloat },
    formularyRequiresPriorAuth: { type: GraphQLBoolean },
    formularyPriorAuthGroup: { type: GraphQLString },
    formularyPriorAuthTypeCode: { type: GraphQLString },
    isPatientInLongTermCare: { type: GraphQLBoolean },
    isPatientInPlanTransition: { type: GraphQLBoolean },
    receivedTransitionFill: { type: GraphQLBoolean },
    pharmacy: {
      type: pharmacyType,
      resolve: (source, _, context) => {
        return getPharmacy(context.state, source.pharmacy);
      },
    },
    pharmacyId: { type: GraphQLString },
    pharmacyName: { type: GraphQLString },
    pharmacyAddress1: { type: GraphQLString },
    pharmacyAddress2: { type: GraphQLString },
    pharmacyCity: { type: GraphQLString },
    pharmacyState: { type: GraphQLString },
    pharmacyPhone: { type: GraphQLString },
    pharmacyType: { type: GraphQLString },
    patient: {
      type: patientType,
      resolve: (source, _, context) => {
        return getPatient(context.state, source.patient);
      },
    },
    patientId: { type: GraphQLString },
    prescriber: {
      type: prescriberType,
      resolve: (source, _, context) => {
        return getPrescriber(context.state, source.prescriber);
      },
    },
    prescriberNpi: { type: GraphQLString },
    patientName: { type: GraphQLString },
    patientDob: { type: dateScalar },
    patientAge: { type: GraphQLInt },
    refillWindowStart: { type: dateScalar },
    prescriptionStart: { type: dateScalar },
    prescriptionEnd: { type: dateScalar },
    eligibilityEffectiveDate: { type: dateScalar },
    eligibilityTerminationDate: { type: dateScalar },
    carrier: { type: GraphQLString },
    account: { type: GraphQLString },
    group: { type: GraphQLString },
    benefitContractId: { type: GraphQLString },
    hasPriorAuth: { type: GraphQLBoolean },
    priorAuthNumbers: { type: new GraphQLList(GraphQLString) },
    tier: { type: GraphQLString },
    formularyTier: { type: GraphQLString },
    tierMismatch: { type: GraphQLBoolean },
    stepTherapyRequired: { type: GraphQLBoolean },
    stepTherapyType: { type: GraphQLString },
    stepTherapyGroups: { type: new GraphQLList(stepTherapyGroupType) },
    rxcui: { type: GraphQLString },
    gpi: { type: GraphQLString },
    gpi10Description: { type: GraphQLString },
    gpi14Description: { type: GraphQLString },
    quantityLimitType: { type: GraphQLString },
    quantityLimitAmount: { type: GraphQLFloat },
    quantityLimitDays: { type: GraphQLFloat },
    quantityLimitPerDay: { type: GraphQLFloat },
    quantityLimitPerDayExceeded: { type: GraphQLBoolean },
    quantityPerDay: { type: GraphQLFloat },
    quantityPreviouslyFilledInLimitWindow: { type: GraphQLFloat },
    daySupplyLimit: { type: GraphQLInt },
    daySupplyLimitExceeded: { type: GraphQLBoolean },
    lineOfBusiness: { type: GraphQLString },
    matchedPaidClaims: { type: new GraphQLList(matchedPaidClaimType) },
    historicalOpioidPharmacyCount: { type: GraphQLInt },
    historicalOpioidPrescriberCount: { type: GraphQLInt },
    historicalOpioidPrescribers: { type: new GraphQLList(prescriberType) },
    continuedFrom: { type: GraphQLString },
    isSecondaryPayment: { type: GraphQLBoolean },
    hasBeenRefilled: { type: GraphQLBoolean },
    fillExpirationDate: { type: dateScalar },
    patientHasCancer: { type: GraphQLBoolean },
    initialOpioidSupplyThirtyDays: { type: GraphQLFloat },
    initialOpioidSupplySixtyDays: { type: GraphQLFloat },
    seniorSavingsModel: { type: seniorSavingsModelType },
    drugLists: { type: new GraphQLList(drugListType) },
    coordinationOfBenefitsIndicator: { type: GraphQLString },
    isPatientEligible: { type: GraphQLBoolean },
    diagnosisCodes: { type: new GraphQLList(diagnosisCodeType) },
    diseaseStates: { type: new GraphQLList(GraphQLString) },
  },
});

const targetType = new GraphQLUnionType({
  name: 'Target',
  types: [patientType, prescriberType],
  resolveType(value) {
    return recordToGraphqlType(value);
  },
});

const supportingDocumentsType = new GraphQLUnionType({
  name: 'SupportingDocument',
  types: [patientType, claimType],
  resolveType(value) {
    return recordToGraphqlType(value);
  },
});

const issueType = new GraphQLObjectType({
  name: 'Issue',
  interfaces: [NodeInterface],
  fields: {
    id: { type: GraphQLID },
    number: { type: GraphQLString },
    createdDate: { type: dateScalar },
    effectiveDates: { type: new GraphQLList(intervalType) },
    target: {
      type: targetType,
      resolve: (source, _, context) => {
        // This is a hack, backend leverages the revealid, should we do the same here?
        const patient = getPatient(context.state, source.target);
        if (!isNil(patient?.id)) return patient;
        return getPrescriber(context.state, source.contact);
      },
    },
    campaign: {
      type: campaignType,
      resolve: (source, _, context) => {
        return getCampaignById(context.state, source.campaign);
      },
    },
    supportingDocuments: {
      type: new GraphQLList(supportingDocumentsType),
      resolve: (source, _, context) => {
        return source.supportingDocuments.map((x) => {
          const claim = getClaims(context.state)?.get(x);

          if (!isNil(claim?.id)) return claim;

          return getPatient(context.state, x);
        });
      },
    },
  },
});

const contactType = new GraphQLUnionType({
  name: 'CaseContact',
  types: [patientType, prescriberType, pharmacyType],
  resolveType(value) {
    return recordToGraphqlType(value);
  },
});

const caseType = new GraphQLObjectType({
  name: GraphqlTypes.Case,
  fields: () => ({
    id: { type: GraphQLID },
    number: { type: GraphQLString },
    dateOpened: { type: dateScalar },
    followUpDate: { type: dateScalar },
    status: { type: GraphQLString },
    contact: {
      type: contactType,
      resolve: (source, _, context) => {
        return getContactFromId(context.state, source.contact);
      },
    },
    issues: {
      type: new GraphQLNonNull(new GraphQLList(issueType)),
      resolve: (source, _, context) => {
        return source.issues.map((x) => getIssue(context.state, x));
      },
    },
    interactions: {
      type: new GraphQLNonNull(new GraphQLList(interactionType)),
      resolve: (source, _, context) => {
        return source.interactions.map((x) => getInteraction(context.state, x));
      },
    },
    caseSessions: {
      type: new GraphQLNonNull(new GraphQLList(caseSessionType)),
      resolve: (source, _, context) => {
        return source.caseSessions.map((x) => getSessionById(context.state, x));
      },
    },
    campaign: {
      type: campaignType,
      resolve: (source, _, context) => {
        return getCampaignById(context.state, source.campaign);
      },
    },
    target: {
      type: targetType,
      resolve: (source, _, context) => {
        // This is a hack, backend leverages the revealid, should we do the same here?
        const patient = getPatient(context.state, source.target);
        if (!isNil(patient?.id)) return patient;
        return getPrescriber(context.state, source.target);
      },
    },
    assignee: {
      type: userType,
      resolve: (source, _, context) => {
        return getUser(context.state, source.assignee);
      },
    },
  }),
});

const InteractionFieldType = new GraphQLObjectType({
  name: 'InteractionField',
  fields: {
    name: { type: GraphQLString },
    type: { type: GraphQLString },
    options: { type: new GraphQLList(GraphQLString) },
    level: { type: GraphQLString },
  },
});

const interactionType = new GraphQLObjectType({
  name: 'Interaction',
  fields: {
    id: { type: GraphQLID },
    dateContacted: { type: dateScalar },
    user: {
      type: userType,
      resolve: (source, _, context) => {
        return getUser(context.state, source.user);
      },
    },
    status: { type: GraphQLString },
    cases: {
      type: new GraphQLList(caseType),
      resolve: (source, _, context) => {
        return getCasesByIds(context.state, source.cases);
      },
    },
    suggestedCases: {
      type: new GraphQLList(caseType),
      resolve: (source, _, context) => {
        return getCasesByIds(context.state, source.suggestedCases);
      },
    },
    responses: {
      type: new GraphQLList(responseType),
    },
    contact: {
      type: contactType,
      resolve: (source, _, context) => {
        return getContactFromId(context.state, source.contact);
      },
    },
  },
});

const caseSessionType = new GraphQLObjectType({
  name: 'CaseSessionType',
  fields: {
    id: { type: GraphQLID },
    status: { type: GraphQLString },
    user: {
      type: userType,
      resolve: (source, _, context) => {
        return getUser(context.state, source.user);
      },
    },
    primaryCase: {
      type: caseType,
      resolve: (source, _, context) => {
        return getCase(context.state, source.primaryCase);
      },
    },
    interactions: {
      type: new GraphQLList(interactionType),
      resolve: (source, _, context) => {
        return source.interactions.map((x) => getInteraction(context.state, x));
      },
    },
    secondaryCases: {
      type: new GraphQLList(caseType),
      resolve: (source, _, context) => {
        return getCasesByIds(context.state, source.secondaryCases);
      },
    },
    sessionStart: { type: dateScalar },
    sessionEnd: { type: dateScalar },
  },
});

const contactContextType = new GraphQLObjectType({
  name: 'ContactContext',
  fields: {
    lockedBy: {
      type: userType,
      resolve: (source, _, context) => {
        return getUser(context.state, source.lockedBy);
      },
    },
  },
});

const queryType = new GraphQLObjectType({
  name: 'Query',
  fields: {
    node: {
      type: NodeInterface,
      args: {
        id: { type: GraphQLID },
      },
      resolve: (source, { id }, context) => {
        // TODO:  this isn't maintainable...
        const patient = getPatient(context.state, id);
        if (!isNil(patient?.id)) return patient;
        const prescriber = getPrescriber(context.state, id);
        if (!isNil(prescriber?.id)) return prescriber;
        const pharmacy = getPharmacy(context.state, id);
        if (!isNil(pharmacy?.id)) return pharmacy;
        const workItem = getWorkItem(context.state, id);
        if (!isNil(workItem)) return workItem;
        const claim = getClaim(context.state, id);
        if (!isNil(claim?.id)) return claim;
        return getIssue(context.state, id);
      },
    },
    case: {
      type: caseType,
      args: {
        id: { type: GraphQLID },
      },
      resolve: (source, { id }, context) => {
        return getCase(context.state, id);
      },
    },
    cases: {
      type: new GraphQLList(caseType),
      args: {
        ids: { type: new GraphQLList(GraphQLID) },
      },
      resolve: (source, { ids }, context) => {
        return getCasesByIds(context.state, ids);
      },
    },
    interaction: {
      type: interactionType,
      args: {
        id: { type: GraphQLID },
      },
      resolve: (source, { id }, context) => {
        return getInteraction(context.state, id);
      },
    },
    interactions: {
      type: new GraphQLList(interactionType),
      args: {
        ids: { type: new GraphQLList(GraphQLID) },
      },
      resolve: (source, { ids }, context) => {
        return ids.map((x) => getInteraction(context.state, x));
      },
    },
    session: {
      type: caseSessionType,
      args: {
        id: { type: GraphQLID },
      },
      resolve: (source, { id }, context) => {
        return getSessionById(context.state, id);
      },
    },
    issues: {
      type: new GraphQLList(issueType),
      args: {
        ids: { type: new GraphQLList(GraphQLID) },
      },
      resolve: (source, { ids }, context) => {
        return ids.map((x) => getIssue(context.state, x));
      },
    },
    contextForContact: {
      type: contactContextType,
      args: { contactEntityId: { type: GraphQLID } },
      resolve: (source, { contactEntityId }, context) => {
        return getContextForContact(context.state, contactEntityId);
      },
    },
  },
});

const schema = new GraphQLSchema({ query: queryType });

export function graphqlSelector(state, query, variables) {
  const result = graphqlSync({
    schema,
    source: query,
    contextValue: { state },
    variableValues: variables,
  });
  if (!isNil(result.errors)) throw new Error(result.errors.toString());
  return result.data;
}
