import { OrderedSet } from 'immutable';
import { groupBy, map, pipe, prop } from 'ramda';
import { SEARCH_SUCCESS, SEARCH_CLEAR_RESULTS, SEARCH_FAIL } from './constants';
import { SearchStateRecord } from './types';

const reducer = (state = new SearchStateRecord(), action) => {
  switch (action.type) {
    case SEARCH_SUCCESS: {
      const { result } = action.payload.data;

      const groupedIds = pipe(
        groupBy(prop('schema')),
        map(map(prop('id'))),
      )(result);

      return state.merge({
        patients: new OrderedSet(groupedIds.patient ?? []),
        prescribers: new OrderedSet(groupedIds.prescriber ?? []),
        cases: new OrderedSet(groupedIds.case ?? []),
        claims: new OrderedSet(groupedIds.claim ?? []),
        pharmacies: new OrderedSet(groupedIds.pharmacy ?? []),
      });
    }
    case SEARCH_FAIL:
    case SEARCH_CLEAR_RESULTS:
      return state.merge({
        claims: new OrderedSet(),
        patients: new OrderedSet(),
        cases: new OrderedSet(),
        prescribers: new OrderedSet(),
        pharmacies: new OrderedSet(),
      });
    default:
      return state;
  }
};

export default reducer;
