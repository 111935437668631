import { navigate } from '@reach/router';
import { random, genSeed } from 'pure-random';
import { equals, isNil, min } from 'ramda';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  take,
  put,
  select,
  race,
  takeLatest,
} from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { UNEXPECTED_ERROR_URL, INACTIVE_URL } from '../common/constants';
import {
  store,
  remove,
  get,
  b64UrlEncode,
  b64Decode,
  delay,
  retryAsync,
} from '../common/utilities/generic';
import { hydratingAuthDone } from '../hydrate/actions';
import { getAccessToken, getIdToken } from '../user/selectors';
import {
  fetchToken as fetchTokenStart,
  fetchTokenFail,
  refreshTokenRetry,
  refreshTokenFail,
  fetchTokenSuccess,
  signInSuccess,
  signInFailed,
  userInactiveSignOut,
} from './actions';
import { fetchToken } from './api';
import {
  AUTH_STORAGE_KEY_TOKEN_ID,
  AUTH_STORAGE_KEY_TOKEN_ACCESS,
  AUTH_STORAGE_KEY_VERIFIER,
  AUTH_STORAGE_KEY_STATE,
  USER_SIGN_IN,
  USER_IS_NOT_AUTHENTICATED,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_OUT,
  USER_INACTIVE,
  AUTH_REDIRECT_RESPONSE_TYPE_CODE,
  AUTH_REDIRECT_RESPONSE_TYPE_TOKEN,
  AUTH_REDIRECT_PROMPT_NONE,
} from './constants';
import {
  redirectToAuthLogin,
  createChallenge,
  isTokenExpired,
  createAuthRedirectUrl,
  redirectToExternalUrl,
  tokenExpiresIn,
  decodeJwtToken,
  silentIframeAuthentication,
  createLogoutUrl,
  silentIframeLogout,
} from './utility';

function validateToken(token) {
  return !isNil(token);
}
const getMinExpiration = (a, b) => min(tokenExpiresIn(a), tokenExpiresIn(b));

export function* inactive() {
  const {
    payload: { redirect },
  } = yield take(USER_INACTIVE);

  yield call(navigate, INACTIVE_URL, { state: { previousLocation: redirect } });

  yield all([
    call(remove, AUTH_STORAGE_KEY_STATE),
    call(remove, AUTH_STORAGE_KEY_VERIFIER),
    call(remove, AUTH_STORAGE_KEY_TOKEN_ID),
    call(remove, AUTH_STORAGE_KEY_TOKEN_ACCESS),
  ]);

  const url = createLogoutUrl();

  yield call(silentIframeLogout, url);

  yield put(userInactiveSignOut());
}

export function* silentRefresh() {
  const nonce = yield call(uuid);
  const url = createAuthRedirectUrl(
    AUTH_REDIRECT_RESPONSE_TYPE_TOKEN,
    null,
    null,
    AUTH_REDIRECT_PROMPT_NONE,
    nonce,
    true,
  );

  const { result } = yield race({
    result: call(silentIframeAuthentication, url),
    timeout: call(delay, 20000),
  });

  if (result) {
    if (validateToken(result.idToken) === false) {
      throw new Error('Invalid ID Token Returned');
    }
    if (validateToken(result.accessToken) === false) {
      throw new Error('Invalid Access Token Returned');
    }
    return result;
  }

  throw new Error('Silent iframe refresh timed out');
}

export function* refreshLoop(
  seed = genSeed(),
  resumeChannel = eventChannel((emit) => {
    window.addEventListener('resume', emit, { capture: true });
    return () => window.removeEventListener('resume', emit, { capture: true });
  }),
) {
  yield take(USER_SIGN_IN_SUCCESS);

  try {
    while (true) {
      const [idToken, accessToken] = yield all([
        select(getIdToken),
        select(getAccessToken),
      ]);

      const currentMinExpiration = getMinExpiration(accessToken, idToken);

      // delay until between 45 and 60 seconds before expiration
      const { inactive } = yield race({
        refreshToken: call(
          delay,
          currentMinExpiration - random(seed, 45, 60).value * 1000,
        ),
        inactive: take(USER_INACTIVE),
        resume: take(resumeChannel),
      });

      if (!isNil(inactive)) {
        break;
      }

      // This is some old code, think it is here to help with cross tab token sharing
      let [newIdToken, newAccessToken] = yield all([
        call(get, AUTH_STORAGE_KEY_TOKEN_ID),
        call(get, AUTH_STORAGE_KEY_TOKEN_ACCESS),
      ]);
      const newMinExpiration = getMinExpiration(newIdToken, newAccessToken);

      if (newMinExpiration <= 60000) {
        try {
          yield put(fetchTokenStart());
          const tokens = yield retryAsync(
            () => call(silentRefresh),
            (error, retryCount) => put(refreshTokenRetry(retryCount, error)),
            3,
          );
          newIdToken = tokens.idToken;
          newAccessToken = tokens.accessToken;
        } catch (e) {
          yield put(fetchTokenFail(e));
          throw e;
        }

        yield all([
          call(store, AUTH_STORAGE_KEY_TOKEN_ID, newIdToken),
          call(store, AUTH_STORAGE_KEY_TOKEN_ACCESS, newAccessToken),
        ]);
        const { name, email, picture, sub } = decodeJwtToken(newIdToken);
        const { permissions } = decodeJwtToken(newAccessToken);
        yield put(
          fetchTokenSuccess(
            newIdToken,
            newAccessToken,
            email,
            name,
            picture,
            sub,
            permissions,
          ),
        );
      } else if (newMinExpiration > currentMinExpiration) {
        const { name, email, picture, sub } = decodeJwtToken(newIdToken);
        const { permissions } = decodeJwtToken(newAccessToken);
        yield put(
          fetchTokenSuccess(
            newIdToken,
            newAccessToken,
            email,
            name,
            picture,
            sub,
            permissions,
          ),
        );
      }
    }
  } catch (e) {
    yield put(refreshTokenFail(e));
  }
}

export function* authenticateUserWorker(action) {
  try {
    const [storedState, verifier] = yield all([
      call(get, AUTH_STORAGE_KEY_STATE),
      call(get, AUTH_STORAGE_KEY_VERIFIER),
    ]);

    yield all([
      call(remove, AUTH_STORAGE_KEY_STATE),
      call(remove, AUTH_STORAGE_KEY_VERIFIER),
    ]);

    if (action.payload.state) {
      const callbackState = JSON.parse(b64Decode(action.payload.state));
      if (!equals(storedState, callbackState)) {
        throw new Error('Stored state and query state do not match');
      }
    }

    yield put(fetchTokenStart());
    let tokens;
    try {
      tokens = yield call(fetchToken, action.payload.code, verifier);
    } catch (e) {
      yield put(fetchTokenFail(e));
      throw e;
    }

    if (
      !isNil(tokens) &&
      !isNil(tokens.idToken) &&
      !isNil(tokens.accessToken)
    ) {
      yield all([
        call(store, AUTH_STORAGE_KEY_TOKEN_ID, tokens.idToken),
        call(store, AUTH_STORAGE_KEY_TOKEN_ACCESS, tokens.accessToken),
      ]);

      const { name, email, picture, sub } = decodeJwtToken(tokens.idToken);
      const { permissions } = decodeJwtToken(tokens.accessToken);
      yield put(
        fetchTokenSuccess(
          tokens.idToken,
          tokens.accessToken,
          email,
          name,
          picture,
          sub,
          permissions,
        ),
      );

      yield put(
        signInSuccess(
          tokens.idToken,
          tokens.accessToken,
          email,
          name,
          picture,
          sub,
          permissions,
        ),
      );
    } else {
      // TODO testing new Error('foo') is a pita, figure out how to do it
      const error = 'Invalid token returned';
      yield put(fetchTokenFail(error));
      throw error;
    }

    if (storedState && storedState.redirect) {
      yield call(navigate, storedState.redirect, { replace: true });
    } else {
      yield call(navigate, '/');
    }
  } catch (e) {
    yield put(signInFailed(e));
    yield all([
      call(remove, AUTH_STORAGE_KEY_STATE),
      call(remove, AUTH_STORAGE_KEY_VERIFIER),
      call(remove, AUTH_STORAGE_KEY_TOKEN_ID),
      call(remove, AUTH_STORAGE_KEY_TOKEN_ACCESS),
    ]);
    yield call(navigate, UNEXPECTED_ERROR_URL);
  }
}

export function* authenticateUserWatcher() {
  // TODO not sure that takelatest is what we are looking for here
  // as it is implying that the authenticate user saga is cancellable
  yield takeLatest(USER_SIGN_IN, authenticateUserWorker);
}

export function* userIsNotAuthenticated(verifierFactory) {
  const {
    payload: { redirect },
  } = yield take(USER_IS_NOT_AUTHENTICATED);

  yield all([
    call(remove, AUTH_STORAGE_KEY_TOKEN_ID),
    call(remove, AUTH_STORAGE_KEY_TOKEN_ACCESS),
  ]);

  const verifier = verifierFactory();
  yield call(store, AUTH_STORAGE_KEY_VERIFIER, verifier);

  const challenge = createChallenge(verifier);
  const state = { redirect: redirect || null };

  yield call(store, AUTH_STORAGE_KEY_STATE, state);
  yield call(
    redirectToAuthLogin,
    AUTH_REDIRECT_RESPONSE_TYPE_CODE,
    challenge,
    b64UrlEncode(JSON.stringify(state)),
  );
}

export function* userIsNotAuthenticatedSaga() {
  yield userIsNotAuthenticated(() => uuid().replace(/-|=/g, ''));
}

export function* hydrateAuth() {
  // Check if we already have a token
  const [storedIdToken, storedAccessToken] = yield all([
    call(get, AUTH_STORAGE_KEY_TOKEN_ID),
    call(get, AUTH_STORAGE_KEY_TOKEN_ACCESS),
  ]);
  if (!isNil(storedIdToken) && !isNil(storedAccessToken)) {
    try {
      const areTokensValid =
        !isTokenExpired(storedIdToken) && !isTokenExpired(storedAccessToken);

      if (areTokensValid) {
        const { email, name, picture, sub } = decodeJwtToken(storedIdToken);
        const { permissions } = decodeJwtToken(storedAccessToken);
        yield put(
          signInSuccess(
            storedIdToken,
            storedAccessToken,
            email,
            name,
            picture,
            sub,
            permissions,
          ),
        );
      }
    } catch (e) {
      yield all([
        call(remove, AUTH_STORAGE_KEY_TOKEN_ID),
        call(remove, AUTH_STORAGE_KEY_TOKEN_ACCESS),
      ]);
    }
  }
  yield put(hydratingAuthDone());
}

export function* logout() {
  yield take(USER_SIGN_OUT);

  yield all([
    call(remove, AUTH_STORAGE_KEY_STATE),
    call(remove, AUTH_STORAGE_KEY_VERIFIER),
    call(remove, AUTH_STORAGE_KEY_TOKEN_ID),
    call(remove, AUTH_STORAGE_KEY_TOKEN_ACCESS),
  ]);
  const redirect = createLogoutUrl(window.location.origin);

  yield call(redirectToExternalUrl, redirect);
}
