import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  CircularProgress,
} from '@material-ui/core';
import {
  HelpOutline,
  ErrorOutline,
  CheckCircleOutline,
} from '@material-ui/icons';
import { Link } from '@reach/router';
import { format as formatDate } from 'date-fns';
import { Set } from 'immutable';
import {
  isNil,
  uniqBy,
  pipe,
  prop,
  length,
  join,
  map,
  filter,
  complement,
  isEmpty,
  trim,
  uniq,
} from 'ramda';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ColorChip from '../../../../common/components/ColorChip/ColorChip';
import Message from '../../../../common/components/Message/Message';
import Tag from '../../../../common/components/Tag/Tag';
import { toArray } from '../../../../common/utilities/generic';
import WorkItemStatus from '../../../../workitems/components/WorkItemStatus/WorkItemStatus';
import OpioidOverviewChart from '../../../containers/OpioidOverviewChart/OpioidOverviewChart';
import BasicInfoItem from '../../BasicInfoItem';
import styles from './Opioids.css';

const format = (value) => {
  if (value === true) return 'Yes';
  if (value === false) return 'No';
};

function formatMme(value) {
  if (isNil(value)) return null;
  return `${parseFloat(value.toFixed(3))} mg`;
}

const ClaimSummary = ({ workItem, isPrimary }) => {
  const tags = [];
  const { claim } = workItem;
  if (claim.drugContainsOpioid) {
    tags.push(<Tag text="Opioid" bold color="red" />);
  }
  if (claim.isDrugOpioidCombinator) {
    tags.push(<Tag text="Combinator" bold color="yellow" />);
  }
  if (claim.isPatientOpioidNaive) {
    tags.push(<Tag text="Naive" bold color="orange" />);
  }
  return (
    <Paper className={styles.paper}>
      <ColorChip
        horizontal
        color={isPrimary ? 'blue' : 'orange'}
        className={styles.colorChip}
      />
      <div className={styles.tagRow}>{tags}</div>
      <BasicInfoItem label="Rx Number" value={claim.rxNumber} />
      <BasicInfoItem
        label="Claim"
        value={
          <Link to={`/workitems/${claim.workItem}`}>{claim.claimNumber}</Link>
        }
      />
      <BasicInfoItem
        label="Status"
        value={<WorkItemStatus status={workItem.status} />}
      />
      <BasicInfoItem label="Drug" value={claim.drugName} />
      <BasicInfoItem label="Prescriber" value={claim.prescriber?.npi} />
      <BasicInfoItem label="Days Supply" value={claim.daysSupply} />
      <BasicInfoItem label="Quantity" value={claim.quantityDispensed} />
      <BasicInfoItem label="Has PA" value={format(claim.hasPriorAuth)} />
      <BasicInfoItem
        label="In LTC"
        value={format(claim.isPatientInLongTermCare)}
      />
      <BasicInfoItem
        label="MME (Claim)"
        value={formatMme(claim.dailyMorphineMilligramEquivalent)}
      />
      <BasicInfoItem
        isLastItem
        label="MME (Patient)"
        value={formatMme(claim.patientTotalMorphineMilligramEquivalent)}
      />
    </Paper>
  );
};

ClaimSummary.defaultProps = {
  isPrimary: false,
};

const PrescriberSummary = ({ prescribers }) => {
  const uniqueGroupCount = pipe(
    toArray,
    uniqBy(prop('groupPracticeId')),
    filter(complement(isNil)),
    length,
  )(prescribers);

  const specialties = pipe(
    toArray,
    map(prop('primarySpecialty')),
    filter(complement(isNil)),
    map(trim),
    filter(complement(isEmpty)),
    uniq,
    join(', '),
  )(prescribers);

  const uniquePrescribersCount = pipe(
    toArray,
    uniqBy(prop('npi')),
    filter(complement(isNil)),
    length,
  )(prescribers);

  return (
    <Paper className={styles.paper}>
      <BasicInfoItem
        label="Opioid Prescribers"
        value={uniquePrescribersCount}
      />
      <BasicInfoItem label="Groups" value={uniqueGroupCount} />
      <BasicInfoItem
        label="Specialties"
        value=""
        isLastItem={specialties.length === 0}
      />
      {specialties.length > 0 && (
        <BasicInfoItem isLastItem label="" value={specialties} />
      )}
    </Paper>
  );
};

PrescriberSummary.defaultProps = {
  prescribers: new Set(),
};

const Opioids = ({ patient, issues, isFetching, hasFetched, isErrored }) => {
  const [selectedIssueId, setSelectedIssueId] = useState(null);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const onTourClose = () => setIsTourOpen(false);

  const groupedIssues = issues
    .groupBy((issue) => issue.rule)
    .sortBy((v, k) => k)
    .map((x) =>
      x.sortBy((issue) => issue.primaryClaim.claim.filledDate).reverse(),
    )
    .entrySeq();

  const selectedIssue = issues.find((x) => x.id === selectedIssueId);
  const prescribers =
    selectedIssue?.primaryClaim?.claim?.historicalOpioidPrescribers;

  if (
    hasFetched &&
    isNil(selectedIssueId) &&
    isNil(groupedIssues.first()) === false
  ) {
    setSelectedIssueId(groupedIssues.first()[1].first().id);
  }

  return (
    <div className={styles.container}>
      {!isFetching && isErrored && (
        <Message
          className={styles.error}
          icon={<ErrorOutline />}
          message="We encountered an unexpected error while fetching this patients opioids. Please refresh."
        />
      )}
      {(!isErrored || isFetching) && (
        <div className={styles.row}>
          <Paper className={styles.overview}>
            {!hasFetched && (
              <Message
                icon={<CircularProgress />}
                message="Gettings issues..."
              />
            )}
            {hasFetched && issues.count() === 0 && (
              <Message
                icon={<CheckCircleOutline className={styles.noIssuesIcon} />}
                message="No issues"
              />
            )}
            {hasFetched && issues.count() > 0 && (
              <Scrollbars
                renderThumbVertical={(props) => (
                  <div {...props} className={styles.thumbVertical} />
                )}
                renderTrackVertical={(props) => (
                  <div {...props} className={styles.trackVertical} />
                )}
              >
                <List classes={{ root: styles.issueList }}>
                  {groupedIssues.map((group) => (
                    <>
                      <ListSubheader>{group[0]}</ListSubheader>
                      {group[1].map((x) => (
                        <ListItem
                          key={x.id}
                          button
                          selected={selectedIssueId === x.id}
                          onClick={() => setSelectedIssueId(x.id)}
                        >
                          <ListItemIcon>
                            <div className={styles.statusContainer}>
                              <WorkItemStatus
                                status={x.primaryClaim.status}
                                bold
                              />
                            </div>
                          </ListItemIcon>
                          <ListItemText
                            primary={x.text}
                            secondary={`RX ${
                              x.primaryClaim.claim.rxNumber
                            } filled on ${formatDate(
                              x.primaryClaim.claim.filledDate,
                              'MM/dd/yyyy',
                            )}`}
                          />
                        </ListItem>
                      ))}
                    </>
                  ))}
                </List>
              </Scrollbars>
            )}
          </Paper>
          <Paper className={styles.drugOverview}>
            <div className={styles.paperTitle}>
              Opioid Overview{' '}
              <HelpOutline
                classes={{ root: styles.detailRowHelper }}
                onClick={() => setIsTourOpen(true)}
              />
            </div>
            <OpioidOverviewChart
              id={patient.id}
              hasFetched={hasFetched}
              selectedIssueId={selectedIssueId}
              issues={issues}
              isTourOpen={isTourOpen}
              onTourClose={onTourClose}
            />
          </Paper>
        </div>
      )}
      {!isErrored && hasFetched && selectedIssue && (
        <div className={styles.row}>
          <div className={styles.rowGroup}>
            <div className={styles.rowGroupTitle}>Problem Claim:</div>
            <ClaimSummary workItem={selectedIssue.primaryClaim} isPrimary />
          </div>
          {selectedIssue.claims.count() > 0 && (
            <div className={styles.rowGroupCenter}>
              <div className={styles.rowGroupTitle}>
                {selectedIssue.claims.count()} Overlapping Claims:
              </div>
              <div className={styles.rowGroupContentWrap}>
                {selectedIssue.claims.map((x) => (
                  <ClaimSummary workItem={x} />
                ))}
              </div>
            </div>
          )}
          {isNil(prescribers) === false && (
            <div className={styles.rowGroup}>
              <div className={styles.rowGroupTitle}>
                Historical Prescribers:
              </div>
              <PrescriberSummary prescribers={prescribers} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Opioids;
