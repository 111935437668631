import { OrderedSet } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import { GraphqlTypes } from 'app/common/types';
import {
  autocompleteQueryFactory,
  filterTypesQuery,
  rejectCodeAutoCompleteFragmentFactory,
  stringAutoCompleteFragmentFactory,
} from 'app/filters/queries';
import { SelectValueRecord, TextComparisonRecord } from 'app/filters/types';

export const createAutoCompleteOptionsQueryHandler = (
  name: string,
  autoCompleteType: string,
) => {
  const query = autocompleteQueryFactory(
    stringAutoCompleteFragmentFactory(name),
    name,
  );

  return (token: string) =>
    fetchGraphqlQuery(
      query,
      { search: null, autocompleteType: autoCompleteType },
      token,
    )
      .then(throwIfErrors)
      .then((response) => response.data.autocomplete)
      .then((results: Array<{ value: string }>) =>
        OrderedSet(
          results.map(
            (c) =>
              new SelectValueRecord({
                text: c.value,
                value: c.value,
              }),
          ),
        ),
      );
};

export const createEnumOptionsQueryHandler = (
  graphqlType: GraphqlTypes,
  formatter?: (type: string) => string,
) => {
  return (token: string) =>
    fetchGraphqlQuery(filterTypesQuery, { name: graphqlType }, token)
      .then(throwIfErrors)
      .then(
        (response: {
          data: {
            fields: {
              enumValues: {
                name: string;
              }[];
            };
          };
        }) => response.data.fields.enumValues,
      )
      .then((values: { name: string }[]) =>
        OrderedSet(
          values.map(
            (c) =>
              new SelectValueRecord({
                text: formatter === undefined ? c.name : formatter(c.name),
                value: c.name,
              }),
          ),
        ),
      );
};

export const createRejectCodesOptionsQueryHandler = () => {
  const fragmentName = 'rejectCodes';
  const query = autocompleteQueryFactory(
    rejectCodeAutoCompleteFragmentFactory(fragmentName),
    fragmentName,
  );

  return (token: string) =>
    fetchGraphqlQuery(
      query,
      { search: null, autocompleteType: 'REJECTCODE' },
      token,
    )
      .then(throwIfErrors)
      .then((response) => response.data.autocomplete)
      .then((users: Array<{ code: string; description: string }>) =>
        OrderedSet(
          users.map(
            (u) =>
              new SelectValueRecord({
                text: u.code,
                value: u.code,
              }),
          ),
        ),
      );
};

export const handleNumericComparisonOnChange = (props: any) => {
  return (value: TextComparisonRecord) => {
    props.onChange(
      props.value.merge({
        value: Number.parseInt(value.text),
        operator: value.operator,
      }),
    );
  };
};

// TODO:  I tried using NumericComparison, but typings are failing me
// TextOperators != NumericOperators
export const numericToTextComparisonConverter = (value: any) => {
  return new TextComparisonRecord({
    text: isNil(value.value) ? value.value : value.value.toString(),
    operator: value.operator,
  });
};
