import { Map } from 'immutable';
import { omit } from 'ramda';
import { SEARCH_SUCCESS } from 'app/search/constants';
import {
  CASES_QUERY_FETCH_SUCCESS,
  CASE_FETCH_SUCCESS,
} from '../cases/constants';
import { INTERACTION_SAVE_SUCCESS } from '../interactions/constants';
import {
  ISSUES_TARGET_SEARCH_FETCH_SUCCESS,
  ISSUES_QUERY_FETCH_SUCCESS,
  ISSUE_UPSERT_SUCCESS,
} from '../issues/constants';
import { OPIOID_DETAIL_FETCH_SUCCESS } from '../patient/constants';
import {
  QUEUE_FETCH_NEXT_SESSION_SUCCESS,
  QUEUE_SKIP_SESSION_SUCCESS,
} from '../queue/constants';
import { PrescribersStateRecord, PrescriberRecord } from './types';

function clean(prescriber) {
  return omit(['__typename'], prescriber);
}

function reducer(state = new PrescribersStateRecord(), action) {
  switch (action.type) {
    case QUEUE_FETCH_NEXT_SESSION_SUCCESS:
    case INTERACTION_SAVE_SUCCESS:
    case QUEUE_SKIP_SESSION_SUCCESS:
    case CASE_FETCH_SUCCESS:
    case OPIOID_DETAIL_FETCH_SUCCESS:
    case ISSUES_QUERY_FETCH_SUCCESS:
    case ISSUES_TARGET_SEARCH_FETCH_SUCCESS:
    case SEARCH_SUCCESS:
    case ISSUE_UPSERT_SUCCESS:
    case CASES_QUERY_FETCH_SUCCESS: {
      const prescribers = new Map(action.payload.data.entities.prescribers);
      return state.merge({
        prescribers: state.prescribers.merge(
          prescribers.map((p) => {
            const prescriber = clean(p);
            const existingPrescriber = state.prescribers.get(prescriber.id);
            if (existingPrescriber) {
              return existingPrescriber.merge(prescriber);
            }
            return new PrescriberRecord(prescriber);
          }),
        ),
      });
    }
    default:
      return state;
  }
}

export default reducer;
