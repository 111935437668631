import { Map } from 'immutable';
import { isNil } from 'ramda';
import { OptimisticUpdateRecord } from 'app/common/types';

export const deletePropsFromOptimistic = (
  state: Map<string, Map<string, any>>,
  id: string,
  propsToRemove: Array<string>,
) => {
  const optimisticProps = state.get(id);

  if (optimisticProps) {
    const newOptimisticProps = optimisticProps.deleteAll(propsToRemove);

    if (newOptimisticProps.isEmpty()) {
      return state.delete(id);
    }

    return state.merge({
      [id]: newOptimisticProps,
    });
  }
  return state;
};

export const deletePropsFromOptimisticUpdate = (
  state: Map<string, OptimisticUpdateRecord>,
  id: string,
  propsToRemove: Array<string>,
) => {
  const optimisticUpdate = state.get(id);

  if (!isNil(optimisticUpdate)) {
    const newOptimisticProps = optimisticUpdate.merge({
      updates: optimisticUpdate.updates.deleteAll(propsToRemove),
    });

    if (newOptimisticProps.updates.isEmpty()) {
      return state.delete(id);
    }

    return state.merge({
      [id]: newOptimisticProps,
    });
  }
  return state;
};
