import {
  WORKFLOW_DEFINITION_QUERY_FETCH_FAIL,
  WORKFLOW_DEFINITION_QUERY_FETCH_START,
  WORKFLOW_DEFINITION_QUERY_FETCH_SUCCESS,
} from './constants';

export function fetchWorkflowDefinitions() {
  return {
    type: WORKFLOW_DEFINITION_QUERY_FETCH_START,
    payload: {},
  };
}

export function fetchWorkflowDefinitionsSuccess(workflowDefinitions) {
  return {
    type: WORKFLOW_DEFINITION_QUERY_FETCH_SUCCESS,
    payload: { workflowDefinitions },
  };
}

export function fetchWorkflowDefinitionsFail(error) {
  return {
    type: WORKFLOW_DEFINITION_QUERY_FETCH_FAIL,
    payload: { error },
  };
}
