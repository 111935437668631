import { Map, Set } from 'immutable';
import {
  BESTPRACTICES_CATEGORY_FETCH_SUCCESS,
  BESTPRACTICES_OVERVIEW_FETCH_SUCCESS,
} from '../constants';
import { BestPracticeRecord } from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case BESTPRACTICES_CATEGORY_FETCH_SUCCESS:
    case BESTPRACTICES_OVERVIEW_FETCH_SUCCESS: {
      const bestPractices = new Map(
        action.payload.categories.entities.bestPractices,
      );

      return state.mergeDeep(
        bestPractices.map(
          (bestPractice) =>
            new BestPracticeRecord({
              ...bestPractice,
              rules: new Set(bestPractice.rules),
            }),
        ),
      );
    }
    default:
      return state;
  }
};

export default reducer;
