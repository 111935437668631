import { Record } from 'immutable';
import { isNil, isEmpty } from 'ramda';
import TextInput from 'app/common/components/TextInput';
import { IssueFilterType } from 'app/filters/constants';
import { stringFilterDefaults } from 'app/filters/definitions/defaults';
import { IClaimFilterConfiguration } from 'app/filters/types';
import { WorkItemRecord } from 'app/workitems/types';

class PrescriberNpiConfig
  extends Record({
    ...stringFilterDefaults,
    name: 'prescriberNpi',
    title: 'Prescriber NPI',
    targetType: IssueFilterType.Claim,
    component: (props: any) => (
      <TextInput debounced placeholder="Enter Prescriber NPI" {...props} />
    ),
    filterWorkItem: (workItem: WorkItemRecord, value: string) => {
      if (isEmpty(value)) return true;
      if (isNil(workItem.claim.prescriberNpi)) return false;

      return workItem.claim.prescriberNpi === value;
    },
  })
  implements IClaimFilterConfiguration<string, undefined, string, string>
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new PrescriberNpiConfig();
