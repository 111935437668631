import {
  Dialog,
  DialogContent,
  List as ListHtml,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  CircularProgress,
} from '@material-ui/core';
import {
  Person,
  Assignment,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Search as SearchIcon,
  Flag,
  ErrorOutline,
  AssignmentInd,
  LocalHospital,
  LocalPharmacy,
} from '@material-ui/icons';
import { Link, navigate } from '@reach/router';
import { List, OrderedSet } from 'immutable';
import { cond, equals, isNotNil, path, pipe, prop, props } from 'ramda';
import React, {
  useState,
  createRef,
  useEffect,
  Fragment,
  ReactNode,
  useRef,
} from 'react';
import ContentLoader from 'react-content-loader';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { oxfordJoin } from 'app/common/utilities/generic';
import { SearchResultRecord, SearchType } from 'app/search/types';
import ButtonIcon from '../../../common/components/ButtonIcon';
import Message from '../../../common/components/Message';
import TextInput from '../../../common/components/TextInput';
import styles from './Search.css';

const getResultName = path(['result', 'name']);
const getResultNpi = path(['result', 'npi']);
const getResultPatientId = path(['result', 'patientId']);

const getSearchType = prop('searchType');
const isPatientType = pipe(getSearchType, equals(SearchType.Patients));
const isPrescriberType = pipe(getSearchType, equals(SearchType.Prescribers));
const isClaimType = pipe(getSearchType, equals(SearchType.Claims));
const isCaseType = pipe(getSearchType, equals(SearchType.Cases));
const isPharmacyType = pipe(getSearchType, equals(SearchType.Pharmacies));

const getIconFromResult = cond([
  [isPatientType, () => <Person />],
  [isPrescriberType, () => <LocalHospital />],
  [isClaimType, () => <Assignment />],
  [isCaseType, () => <AssignmentInd />],
  [isPharmacyType, () => <LocalPharmacy />],
]);

const MyLoader = () => (
  <ContentLoader
    height={258}
    width={520}
    speed={2}
    backgroundColor={'#e5e5e5'}
    foregroundColor={'#d6d6d6'}
  >
    <rect x="12" y="24" rx="5" ry="5" width="55" height="16" />
    <rect x="0" y="54" width="510" height="30" />
    {[...Array(6)].map((x, i) => (
      <Fragment key={`Row${i}`}>
        <rect
          x="16"
          y={(i + 1) * 26 + 70}
          rx="3"
          ry="3"
          height="16"
          width="16"
        />
        <rect
          x="50"
          y={(i + 1) * 26 + 70}
          rx="5"
          ry="5"
          width="115"
          height="16"
        />
        <rect
          x="405"
          y={(i + 1) * 26 + 70}
          rx="5"
          ry="5"
          width="80"
          height="16"
        />
      </Fragment>
    ))}
  </ContentLoader>
);

type SearchProps = {
  isOpen: boolean;
  searchTypes: SearchType[];
  onClose: () => void;
  onSearch: (v: string) => void;
  suggestedResults: List<SearchResultRecord>;
  results: List<SearchResultRecord>;
  isSearching: boolean;
  onClear: () => void;
  onClick: (r: any) => void;
  error: boolean;
};

const Search = ({
  isOpen,
  searchTypes,
  onClose,
  onSearch,
  suggestedResults,
  results,
  isSearching,
  onClear,
  onClick,
  error,
}: SearchProps) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [searchText, setSearchText] = useState('');
  const activeLink = useRef<HTMLDivElement>(null);
  let searchResults = results;

  if (searchText === '' && suggestedResults.count() > 0) {
    searchResults = suggestedResults;
  }

  const indexedSearchResults = searchResults.map((x: any, i: any) => ({
    result: x,
    searchType: x.type,
    index: i,
  }));

  const handleClose = () => {
    onClose();
    onClear();
    setSearchText('');
    setSelectedItem(0);
  };

  const handleClick = (result: any) => {
    handleClose();
    onClick(result);
  };

  const handleTextInputChange = (text: string) => {
    if (text.startsWith(searchText) === false) {
      onClear();
    }
    setSearchText(text);
    setSelectedItem(0);
    if (text !== '') {
      onSearch(text);
    }
  };

  useEffect(() => {
    if (activeLink.current != null) {
      activeLink.current.scrollIntoView(false);
    }
  }, [selectedItem]);

  const resultCount = indexedSearchResults.size;

  const handleSearchKeyDown = (event: any) => {
    switch (event.key) {
      case 'ArrowDown':
      case 'Tab': {
        if (selectedItem < resultCount - 1) {
          setSelectedItem(selectedItem + 1);
        } else {
          setSelectedItem(0);
        }
        event.stopPropagation();
        event.preventDefault();
        break;
      }
      case 'ArrowUp': {
        if (selectedItem > 0) {
          setSelectedItem(selectedItem - 1);
        } else if (resultCount > 0) {
          setSelectedItem(resultCount - 1);
        }
        event.stopPropagation();
        event.preventDefault();
        break;
      }
      case 'Escape': {
        handleClose();
        event.stopPropagation();
        event.preventDefault();
        break;
      }
      case 'Enter': {
        if (resultCount > 0) {
          const item = indexedSearchResults.get(selectedItem);
          if (isNotNil(item)) {
            handleClick(item.result);
            handleClose();
            event.stopPropagation();
            event.preventDefault();
          }
        }
        break;
      }
      default:
        break;
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogContent classes={{ root: styles.searchInputContainer }}>
        <TextInput
          debounced
          value={searchText}
          autoFocus
          fullWidth
          placeholder="Search"
          variant="outlined"
          onKeyDown={handleSearchKeyDown}
          onChange={handleTextInputChange}
          rightIcon={isSearching && resultCount > 0 && <CircularProgress />}
        />
      </DialogContent>
      <DialogContent dividers classes={{ root: styles.searchList }}>
        {resultCount > 0 && !error && (
          <ListHtml id="searchResult" dense>
            {indexedSearchResults
              .groupBy((x: any) => x.searchType)
              .map((grouping: any, type: any) => (
                <>
                  <ListSubheader
                    classes={{ root: styles.searchSubheader }}
                    key={type}
                  >
                    {type === SearchType.Patients && 'Patients'}
                    {type === SearchType.Claims && 'Claims'}
                    {type === SearchType.Cases && 'Cases'}
                    {type === SearchType.Pharmacies && 'Pharmacies'}
                    {type === SearchType.Prescribers && 'Prescribers'}
                  </ListSubheader>
                  {grouping.map((result: any) => (
                    <ListItem
                      button
                      selected={result.index === selectedItem}
                      key={result.result.id}
                      ref={result.index === selectedItem ? activeLink : null}
                      onClick={() => handleClick(result.result)}
                    >
                      <ListItemIcon>{getIconFromResult(result)}</ListItemIcon>
                      <ListItemText>
                        <div className={styles.resultText}>
                          <span className={styles.primaryInfo}>
                            {result.result.primaryText}
                          </span>
                          <span className={styles.secondaryInfo}>
                            {result.result.secondaryText}
                          </span>
                        </div>
                      </ListItemText>
                    </ListItem>
                  ))}
                </>
              ))
              .valueSeq()}
          </ListHtml>
        )}
        {resultCount === 0 && searchText === '' && !isSearching && !error && (
          <Message
            icon={<SearchIcon />}
            message={`Try searching for ${oxfordJoin(
              searchTypes.map((x) => x.toLowerCase()),
              ',',
              'or',
            )}.`}
          />
        )}
        {resultCount === 0 && searchText !== '' && !isSearching && !error && (
          <Message
            icon={<Flag />}
            message={`We could not find anything that matches "${searchText}".`}
          />
        )}
        {error && (
          <Message
            icon={<ErrorOutline />}
            message={`We encountered an unexpected error while searching for "${searchText}".`}
          />
        )}
        {isSearching && resultCount === 0 && <MyLoader />}
      </DialogContent>
      <div className={styles.controlsContainer}>
        <div className={styles.controlsSection}>
          <ButtonIcon>TAB</ButtonIcon>
          <span className={styles.spaced}>or</span>
          <ButtonIcon dense>
            <KeyboardArrowUp classes={{ root: styles.controlIcon }} />
          </ButtonIcon>
          <ButtonIcon dense>
            <KeyboardArrowDown classes={{ root: styles.controlIcon }} />
          </ButtonIcon>
          to navigate
        </div>
        <div className={styles.controlsRight}>
          <div className={styles.controlsSection}>
            <ButtonIcon>ENTER</ButtonIcon>
            <span className={styles.spaced}>to select</span>
          </div>
          <div className={styles.divider} />
          <div className={styles.controlsSection}>
            <ButtonIcon>ESC</ButtonIcon> to dismiss
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Search;
