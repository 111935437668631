import { defaultTo, find, isNil, nth, pipe, prop, isNotNil } from 'ramda';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { CaseRecord } from 'app/cases/types';
import {
  useMutation,
  useOptimisticMutation,
  useQuery,
} from 'app/common/utilities/hooks';
import { InteractionRecord, InteractionStatus } from 'app/interactions/types';
import { outreachQuery } from 'app/queue/containers/Outreach/queries';
import { upsertSession } from 'app/session/actions';
import {
  SessionInputRecord,
  SessionRecord,
  SessionStatus,
} from 'app/session/types';
import { TargetType } from '../../../issues/types';
import { fetchPatientDrugOverview } from '../../../patient/actions';
import { isRunningAny, isErrored } from '../../../ui/selectors';
import {
  fetchNextSessionInQueue,
  skipSessionInQueue,
  fetchQueueStats,
  clearSessionLock,
} from '../../actions';
import Outreach from '../../components/Outreach';
import { getLockedSessionId, getQueueStats } from '../../selectors';

type OutreachContainerProps = {
  sessionId: string;
  queueStats: any;
  areStatsLoading: boolean;
};

const OutreachContainer = ({
  sessionId,
  queueStats,
  areStatsLoading,
}: OutreachContainerProps) => {
  const [activeCase, setActiveCase] = useState<string>();
  const [isCompleting, setIsCompleting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    mutation: upsertMutation,
    isErrored: isUpsertErrored,
    isRunning: isUpsertRunning,
    hasCompleted: hasupsertCompleted,
  } = useOptimisticMutation(upsertSession);

  const {
    mutation: skipMutation,
    isErrored: isSkipErrored,
    isRunning: isSkipRunning,
  } = useMutation(skipSessionInQueue);

  const {
    query: fetchNextSessionQuery,
    result: { session },
    isRunning: isFetchingNextSession,
    hasCompleted: hasFetchedNextSession,
    isErrored: errorFetchingNextSession,
  } = useQuery(fetchNextSessionInQueue, outreachQuery, {
    id: sessionId,
  });

  const errorOnQueueStats = useSelector((state) =>
    isErrored(state, fetchQueueStats()),
  );

  useEffect(() => {
    if (isNil(session)) {
      fetchNextSessionQuery();
    } else {
      dispatch(fetchQueueStats());
      setActiveCase(session.primaryCase.id);
    }
  }, [session?.id]);

  useEffect(() => () => dispatch(clearSessionLock() as any), []);

  const getCaseArrayFromSession = (session: SessionRecord) => {
    let caseArray: CaseRecord[] = [session.primaryCase as CaseRecord];
    if (
      session.interactions &&
      (session.interactions as InteractionRecord[]).length
    ) {
      const inProgressInteraction = (
        session.interactions as InteractionRecord[]
      ).find(
        (x: InteractionRecord) => x.status === InteractionStatus.InProgress,
      );
      if (inProgressInteraction) {
        if (
          inProgressInteraction.cases &&
          (inProgressInteraction.cases as CaseRecord[]).length
        ) {
          caseArray = caseArray.concat(
            inProgressInteraction.cases as CaseRecord[],
          );
        }
        if (
          inProgressInteraction.suggestedCases &&
          (inProgressInteraction.suggestedCases as CaseRecord[]).length
        ) {
          caseArray = caseArray.concat(
            inProgressInteraction.suggestedCases as CaseRecord[],
          );
        }
      }
    }

    return caseArray;
  };

  useEffect(() => {
    // TODO:  Should it be up to this component to fetch the drug overview or should that
    // really be something that the drug overview component fetches.
    if (isNotNil(activeCase)) {
      // Leaving this search in
      const { campaign, target } = pipe(
        getCaseArrayFromSession,
        find((x: any) => x.id === activeCase),
      )(session);

      if (campaign.target === TargetType.Patient) {
        dispatch(fetchPatientDrugOverview(target.id, target.patientId));
      }
    }
  }, [activeCase]);

  const handleOnChangeActiveCase = (caseItem: CaseRecord) => {
    setActiveCase(caseItem.id);
  };

  const handleOnComplete = () => {
    const newSession = new SessionInputRecord({
      id: session.id,
      status: SessionStatus.Completed,
      primaryCase: session.primaryCase.id,
    });

    upsertMutation(newSession.id, newSession);
    setIsCompleting(true);
  };

  const handleOnSkip = () => {
    skipMutation(session.id);
  };

  useEffect(() => {
    if (isUpsertErrored) {
      setIsCompleting(false);
    }
    if (
      session?.status === SessionStatus.Completed &&
      hasupsertCompleted &&
      !isUpsertRunning &&
      !isUpsertErrored
    ) {
      setIsCompleting(false);
      fetchNextSessionQuery();
    }
  }, [isUpsertRunning, session?.status, hasupsertCompleted, isUpsertErrored]);

  // TODO This irritates me, the setState lags behind for active case so
  // active case from previous interaction sticks around for a bit and causes exceptions
  let activeCaseOverride = activeCase;

  if (
    !isNil(session) &&
    !isFetchingNextSession &&
    hasFetchedNextSession &&
    pipe(
      getCaseArrayFromSession,
      defaultTo([]),
      find((x) => x.id === activeCase),
      prop('id'),
      isNil,
    )(session)
  ) {
    activeCaseOverride = pipe(
      getCaseArrayFromSession,
      defaultTo([]),
      nth(0),
      prop('id'),
    )(session);
  }

  return (
    <Outreach
      session={session}
      onNext={fetchNextSessionInQueue}
      onSkip={handleOnSkip}
      activeCase={activeCaseOverride as string}
      onComplete={handleOnComplete}
      onChangeActiveCase={handleOnChangeActiveCase}
      isSkipping={isSkipRunning}
      isCompleting={isCompleting}
      isLoading={isFetchingNextSession}
      hasFetched={hasFetchedNextSession}
      queueStats={queueStats}
      areStatsLoading={areStatsLoading}
      errorOnSkip={isSkipErrored}
      errorOnComplete={isUpsertErrored}
      errorFetchingNextInteraction={errorFetchingNextSession}
      errorOnQueueStats={errorOnQueueStats}
    />
  );
};

export default connect((state) => {
  const session = getLockedSessionId(state);

  return {
    sessionId: session,
    queueStats: getQueueStats(state),
    areStatsLoading: isRunningAny(state, fetchQueueStats()),
  };
})(OutreachContainer);
