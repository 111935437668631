import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import { searchQuery } from './queries';

export function autocomplete(search, type, token) {
  return fetchGraphqlQuery(
    searchQuery,
    { search, autocompleteTypes: type },
    token,
  ).then(throwIfErrors);
}
