import { Button, MenuItem, Paper, SvgIcon, TextField } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { is, isNil } from 'ramda';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCampaignsStart } from 'app/campaigns/actions';
import { getLatestCampaigns } from 'app/campaigns/selectors';
import { CampaignRecord } from 'app/campaigns/types';
import SaveButton from 'app/common/components/SaveButton';
import { graphqlSelector } from 'app/common/hooks/useQuery';
import { ErrorRecord, GraphQlErrorRecord } from 'app/common/types';
import graphql from 'app/common/utilities/graphql';
import { useOptimisticMutation } from 'app/common/utilities/hooks';
import { upsertIssue } from 'app/issues/actions';
import SearchInput from 'app/issues/components/CreateIssue/SearchInput';
import { issueFragmentFactory } from 'app/issues/selectors';
import { IssueUpsertRecord } from 'app/issues/types';
import styles from './CreateIssue.css';

export type CreateIssuePropTypes = {
  campaigns: CampaignRecord[];
  isSaving: boolean;
  errors: GraphQlErrorRecord[];
  onSubmit: (issue: IssueUpsertRecord) => void;
  onCancel: () => void;
};

const CreateIssue = ({
  campaigns,
  isSaving,
  errors,
  onCancel,
  onSubmit,
}: CreateIssuePropTypes) => {
  const {
    handleSubmit,
    control,
    formState: { errors: fieldErrors },
  } = useForm();

  let formError = false;

  errors.forEach((error) => {
    if (is(GraphQlErrorRecord, error)) {
      if (error.path.count() > 1) {
        const field = error.path.get(2);

        // TODO the server side errors do not get cleared out on the form when user changes value
        // Checking for dirty flag however does not clear after a failed attempt
        if (!isNil(field) && isNil(fieldErrors[field])) {
          fieldErrors[field] = { type: 'server', message: error.message };
        }
      } else {
        formError = true;
      }
    }
    if (is(ErrorRecord, error)) {
      formError = true;
    }
  });

  return (
    <div className={styles.container}>
      <Paper className={styles.formContainer}>
        <form
          className={styles.form}
          onSubmit={handleSubmit((data: any) => {
            onSubmit(
              new IssueUpsertRecord({
                campaignEntityId: data.campaign,
                supportingDocument: data.target,
              }),
            );
          })}
        >
          <div className={styles.formRow}>
            <Paper elevation={2} className={styles.headerIcon}>
              <SvgIcon fontSize="large">
                <Description />
              </SvgIcon>
            </Paper>
            <div className={styles.headerText}>Create Issue</div>
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  classes={{ root: styles.input }}
                  label="Campaign"
                  helperText={
                    fieldErrors.campaign ? (
                      <>
                        {fieldErrors.campaign.type === 'required' && (
                          <div>Campaign is required</div>
                        )}
                        {fieldErrors.campaign.type === 'server' && (
                          <div>{fieldErrors.campaign.message?.toString()}</div>
                        )}
                      </>
                    ) : null
                  }
                  error={!isNil(fieldErrors.campaign)}
                >
                  {campaigns.map((x) => (
                    <MenuItem key={x.entityId} value={x.entityId}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              name="campaign"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue={null}
            />
          </div>
          <div className={styles.formRow}>
            <Controller
              render={({ field: { onChange, value } }) => (
                <SearchInput
                  onChange={onChange}
                  value={value}
                  classes={{ root: styles.input }}
                  label="Target"
                  helperText={
                    fieldErrors.target ? (
                      <>
                        {fieldErrors.target.type === 'required' && (
                          <div>target is required</div>
                        )}
                        {fieldErrors.target.type === 'server' && (
                          <div>{fieldErrors.target.message?.toString()}</div>
                        )}
                      </>
                    ) : (
                      <div>
                        The claim or patient that this issue will be linked to.
                      </div>
                    )
                  }
                  error={!isNil(fieldErrors.target)}
                />
              )}
              name="target"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue=""
            />
          </div>
          {formError && (
            <div className={styles.formErrorText}>
              Something went wrong, please try again or contact us.
            </div>
          )}
          <div className={styles.submitRow}>
            <Button disabled={isSaving} onClick={onCancel}>
              Cancel
            </Button>
            <SaveButton type="submit" isSaving={isSaving}>
              Create
            </SaveButton>
          </div>
        </form>
      </Paper>
    </div>
  );
};

const CreateIssueContainer = () => {
  const dispatch = useDispatch();

  const {
    mutation,
    optimisticId,
    createdId: newId,
    isRunning,
    hasCompleted,
    isErrored,
    errors,
  } = useOptimisticMutation(upsertIssue);

  const campaigns = useSelector((state) => getLatestCampaigns(state));
  useEffect(() => {
    dispatch(fetchCampaignsStart());
  }, []);

  const handleCreateIssue = (issue: IssueUpsertRecord) => {
    mutation(issue);
  };

  const handleCancel = () => {
    navigate('/issues');
  };

  const createdIssuesQuery = graphql`
    ${issueFragmentFactory('issueFields')}
    query node($id: ID!) {
      issue: node(id: $id) {
        ...issueFields
      }
    }
  `;
  const { issue }: any = useSelector((state) =>
    graphqlSelector(state, createdIssuesQuery, {
      id: newId ?? optimisticId ?? '',
    }),
  );

  useEffect(() => {
    if (hasCompleted && !isErrored) {
      navigate('/issues', { state: { newIssue: issue } });
    }
  }, [hasCompleted, isErrored]);

  return (
    <CreateIssue
      campaigns={campaigns
        .toSet()
        .sortBy((x: CampaignRecord) => x.name?.toUpperCase())}
      onSubmit={handleCreateIssue}
      onCancel={handleCancel}
      isSaving={isRunning}
      errors={errors}
    />
  );
};

export default CreateIssueContainer;
