import { SIDEBAR_EXPAND, SIDEBAR_MINIMIZE } from '../constants';
import { SideBarRecord } from '../types';

const reducer = (state = new SideBarRecord(), action) => {
  switch (action.type) {
    case SIDEBAR_EXPAND:
      return state.merge({ isMinimized: false });
    case SIDEBAR_MINIMIZE:
      return state.merge({ isMinimized: true });
    default:
      return state;
  }
};

export default reducer;
