import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GRAPHQL_URL } from '../../../common/constants';
import { getAccessToken } from '../../../user/selectors';
import { post, addAuthorizationToHeader } from '../../utilities/api';

export class GraphiQLContainer extends Component {
  static propTypes = { token: PropTypes.string.isRequired };

  graphQLFetcher = (graphQLParams) => {
    const { token } = this.props;
    const headers = addAuthorizationToHeader(token);
    headers.append('Content-Type', 'application/json');
    return post(GRAPHQL_URL, headers, graphQLParams).then((response) =>
      response.json(),
    );
  };

  render() {
    return <GraphiQL fetcher={this.graphQLFetcher} />;
  }
}

export default connect((state) => ({ token: getAccessToken(state) }))(
  GraphiQLContainer,
);
