import { Set } from 'immutable';
import {
  TOKEN_FETCH_START,
  TOKEN_FETCH_SUCCESS,
  TOKEN_FETCH_FAIL,
  TOKEN_REFRESH_FAIL,
  USER_SIGN_IN_FAIL,
  USER_SIGN_IN_SUCCESS,
  USER_INACTIVE_SIGN_OUT,
} from '../auth/constants';
import {
  VIEWTEMPLATE_APPLY,
  VIEWTEMPLATE_CREATE_SUCCESS,
  VIEWTEMPLATE_DELETE_SUCCESS,
} from '../viewtemplates/constants';
import { UserStateRecord, TokenMetaRecord } from './types';

const reducer = (state = new UserStateRecord(), action) => {
  switch (action.type) {
    case TOKEN_FETCH_START:
      return state.mergeDeep({ token: { isFetching: true, error: null } });
    case TOKEN_FETCH_SUCCESS:
      return state.merge({
        token: new TokenMetaRecord({
          isFetching: false,
          idToken: action.payload.idToken,
          accessToken: action.payload.accessToken,
        }),
        email: action.payload.email,
        picture: action.payload.picture,
        name: action.payload.name,
        userId: action.payload.userId,
        permissions: new Set(action.payload.permissions),
      });
    case TOKEN_FETCH_FAIL:
      return state.mergeDeep({
        token: { isFetching: false, error: action.payload.error },
      });
    case TOKEN_REFRESH_FAIL:
      return state.mergeDeep({ token: { error: action.payload.error } });
    case USER_SIGN_IN_FAIL:
      return state.mergeDeep({
        token: { idToken: null, accessToken: null },
        error: action.payload.error,
      });
    case USER_SIGN_IN_SUCCESS:
      return state.merge({
        token: new TokenMetaRecord({
          idToken: action.payload.idToken,
          accessToken: action.payload.accessToken,
        }),
        email: action.payload.email,
        picture: action.payload.picture,
        name: action.payload.name,
        userId: action.payload.userId,
        permissions: new Set(action.payload.permissions),
      });
    case USER_INACTIVE_SIGN_OUT:
      return state.merge({
        token: new TokenMetaRecord(),
        email: null,
        picture: null,
        name: null,
        userId: null,
        permissions: new Set(),
      });
    case VIEWTEMPLATE_APPLY:
      return state.merge({
        activeViewTemplateId: action.payload.viewTemplate.id,
      });
    case VIEWTEMPLATE_CREATE_SUCCESS:
      return state.merge({
        activeViewTemplateId: action.payload.viewTemplate.id,
      });
    case VIEWTEMPLATE_DELETE_SUCCESS: {
      if (action.payload.id === state.activeViewTemplateId) {
        return state.merge({
          activeViewTemplateId: null,
        });
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
