import { combineReducers } from 'redux-immutable';
import { CasesStateRecord } from '../types';
import cases from './cases';
import filters from './filters';
import optimistic from './optimistic';

export default combineReducers(
  {
    cases,
    filters,
    optimistic,
  },
  () => new CasesStateRecord(),
);
