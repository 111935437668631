import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
} from '@material-ui/core';
import React, { useState } from 'react';
import { TextComparisonRecord } from 'app/filters/types';
import TextComparison from '../TextComparison/TextComparison';
import styles from './NumericSelectCombo.css';

const OptionSelector = ({ title, selected, options, onChange }) => {
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel>Within initial days of</InputLabel>
      <Select
        value={selected.value}
        onChange={(event) => onChange(event.target.value)}
        input={<Input />}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const NumericSelectCombo = (props) => {
  return (
    <div>
      <FormControl margin="normal" fullWidth>
        <InputLabel classes={{ root: styles.textLabel }}>
          Days of opioid use
        </InputLabel>
        <TextComparison
          {...props}
          inputType="number"
          placeholder="Enter supply threshold"
          options={props.options.numericComparison}
          value={
            new TextComparisonRecord({
              text: props.value.numericComparison.value.toString(),
              operator: props.value.numericComparison.operator,
            })
          }
          onChange={(value) => {
            props.onChange(
              props.value.merge({
                numericComparison: props.value.numericComparison.merge({
                  value: Number.parseInt(value.text),
                  operator: value.operator,
                }),
              }),
            );
          }}
        />
      </FormControl>
      <OptionSelector
        selected={props.value.window}
        options={props.options.select}
        onChange={(value) => {
          props.onChange(
            props.value.merge({
              window: props.options.select.find((v) => v.value === value),
            }),
          );
        }}
      />
    </div>
  );
};

export default NumericSelectCombo;
