/* eslint-disable unicorn/switch-case-braces */
import { Map } from 'immutable';
import { equals, gt, lt } from 'ramda';

export const FILTERS_FETCH_START = 'FILTER/FILTERS_FETCH_START';
export const FILTERS_FETCH_SUCCESS = 'FILTER/FILTERS_FETCH_SUCCESS';
export const FILTERS_FETCH_FAIL = 'FILTER/FILTER_FETCH_FAIL';

export enum MatchStrings {
  Any = 'ANY',
  All = 'ALL',
}

export enum LateToFillMatch {
  Gpi = 'GPI',
  Therapy = 'THERAPY',
}

export enum NumericOperators {
  Equal = 'Equal',
  Less = 'Less',
  Greater = 'Greater',
}

export enum IssueFilterType {
  Claim = 'Claim',
  Patient = 'Patient',
}

export const numericOperatorConfig = (operator: NumericOperators) => {
  switch (operator) {
    case NumericOperators.Equal:
      return {
        summary: '',
        text: 'Equal to',
        filter: (a: number, b: number) => equals(a, b),
      };
    case NumericOperators.Less:
      return {
        summary: 'Less than',
        text: 'Less than',
        filter: (a: number, b: number) => lt(a, b),
      };
    case NumericOperators.Greater:
      return {
        summary: 'Greater than',
        text: 'Greater than',
        filter: (a: number, b: number) => gt(a, b),
      };
    default:
      throw new Error('Unknown operator type provided.');
  }
};

export const NUMERIC_OPERATORS = Map({
  [NumericOperators.Equal]: {
    summary: '',
    text: 'Equal to',
    filter: equals,
  },
  [NumericOperators.Less]: {
    summary: 'Less than',
    text: 'Less than',
    filter: lt,
  },
  [NumericOperators.Greater]: {
    summary: 'Greater than',
    text: 'Greater than',
    filter: gt,
  },
});

export const ISO_8601_FORMAT = 'yyyy-MM-dd';

export enum TextComparisonOperators {
  Contains = 'contains',
  DoesNotContain = 'doesNotContain',
  StartsWith = 'startsWith',
}

const containsValue = (strings: string[], value: string) =>
  strings.some((x) => x.toUpperCase().includes(value.toUpperCase()));

export const getTextOperatorConfig = (operator: TextComparisonOperators) => {
  switch (operator) {
    case TextComparisonOperators.Contains:
      return {
        summary: 'Contains',
        text: 'Contains',
        filter: containsValue,
      };
    case TextComparisonOperators.DoesNotContain:
      return {
        summary: 'Does not contain',
        text: 'Does Not Contain',
        filter: (strings: string[], value: string) =>
          !containsValue(strings, value),
      };
    case TextComparisonOperators.StartsWith:
      return {
        summary: 'Starts with',
        text: 'Starts With',
        filter: (strings: string[], value: string) =>
          strings.some((x) => x.toUpperCase().startsWith(value.toUpperCase())),
      };
    default:
      throw new Error('Unknown operator type provided.');
  }
};
