import { Map, Set } from 'immutable';
import {
  BESTPRACTICES_CATEGORY_FETCH_SUCCESS,
  BESTPRACTICES_OVERVIEW_FETCH_SUCCESS,
} from '../constants';
import { BestPracticeCategoryRecord } from '../types';

const reducer = (state = new Map(), action) => {
  switch (action.type) {
    case BESTPRACTICES_CATEGORY_FETCH_SUCCESS:
    case BESTPRACTICES_OVERVIEW_FETCH_SUCCESS: {
      const categories = new Map(action.payload.categories.entities.categories);

      return state.merge(
        categories.map(
          (category) =>
            new BestPracticeCategoryRecord({
              ...category,
              bestPractices: new Set(category.bestPractices),
            }),
        ),
      );
    }
    default:
      return state;
  }
};

export default reducer;
