import { Divider, Button } from '@material-ui/core';
import { interpolateObject } from 'd3-interpolate';
import {
  isBefore,
  isAfter,
  differenceInDays,
  max,
  min,
  isEqual,
  startOfMonth,
} from 'date-fns';
import { Set, Map } from 'immutable';
import PropTypes from 'prop-types';
import {
  map,
  pipe,
  flatten,
  prop,
  sum,
  sort,
  descend,
  isNil,
  has,
  addIndex,
  filter,
  any,
  path,
} from 'ramda';
import React, { Component, createRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { defaultMemoize } from 'reselect';
import {
  VictoryPie,
  VictoryTheme,
  VictoryLegend,
  VictoryContainer,
  VictoryTooltip,
  Rect,
  Style,
} from 'victory';
import DateRangePicker from '../../../common/components/DateRangePicker';
import UnexpectedError from '../../../common/components/UnexpectedError';
import {
  toArray,
  isValidNumber,
  mapToValuesArray,
} from '../../../common/utilities/generic';
import { AGGREGATION_PERIODS } from '../../constants';
import BestPracticeSection from '../BestPracticeSection';
import RiskChart from '../RiskChart';
import RiskLine from '../RiskLine';
import RiskScore from '../RiskScore';
import styles from './BestPracticeCategory.css';

const pieChartWidth = 200;

const getRiskScoreForBestPractice = pipe(
  prop('rules'),
  toArray,
  map(path(['riskScore', 'score'])),
  sum,
);

const sortBestPracticesByRisk = pipe(
  toArray,
  sort(descend(getRiskScoreForBestPractice)),
);

const getRulesInCategory = pipe(
  prop('bestPractices'),
  toArray,
  map((x) => mapToValuesArray(x.rules)),
  flatten,
);

const colorPalette = Style.getColorScale('qualitative');
const getColorMap = pipe(
  getRulesInCategory,
  (x) => {
    if (x.length > colorPalette.length)
      throw new Error('Too many rules for given color palette');
    return x;
  },
  addIndex(map)((v, i) => [v.id, colorPalette[i]]),
  Map,
);

const Flyout = ({ x, y, ...props }) => {
  const labelWidth = 30;
  const labelHeight = 20;
  return (
    <Rect
      {...props}
      x={props.center.x - labelWidth / 2}
      y={props.center.y - labelHeight / 2}
      rx={2}
      height={labelHeight}
      width={labelWidth}
      style={{ fill: 'black' }}
    />
  );
};

const interpolateRiskValue = (priorRisk, futureRisk, xValue) => {
  const priorDate = max(priorRisk.map((x) => x.date).toArray());
  const futureDate = min(futureRisk.map((x) => x.date).toArray());

  const lowValue = priorRisk.find((x) => isEqual(x.date, priorDate));
  const highValue = futureRisk.find((x) => isEqual(x.date, futureDate));

  if (!lowValue && highValue.date > xValue) return highValue;
  if (!highValue && lowValue.date < xValue) return lowValue;

  const interpolator = interpolateObject(lowValue.toJS(), highValue.toJS());
  const daysInRange = differenceInDays(futureDate, priorDate);
  const daysIn = differenceInDays(xValue, priorDate);
  const interpolatedValue = interpolator(daysIn / daysInRange);

  return interpolatedValue;
};

const interpolateProjectedRiskValue = (historicalRisk, xValue) => {
  const priorRisk = historicalRisk.filter(
    (x) => isBefore(x.date, xValue) && x.period === AGGREGATION_PERIODS.MONTHLY,
  );
  const futureRisk = historicalRisk.filter(
    (x) =>
      isAfter(x.date, xValue) && x.period === AGGREGATION_PERIODS.PROJECTED,
  );

  return interpolateRiskValue(priorRisk, futureRisk, xValue);
};

const interpolatePossibleRiskValues = (historicalRisk, xValue) => {
  const values = [];

  if (isAfter(xValue, startOfMonth(new Date()))) {
    values.push(interpolateProjectedRiskValue(historicalRisk, xValue));
  }

  if (isBefore(xValue, new Date())) {
    const priorRisk = historicalRisk.filter((x) => isBefore(x.date, xValue));
    const futureRisk = historicalRisk.filter((x) => isAfter(x.date, xValue));

    values.push(interpolateRiskValue(priorRisk, futureRisk, xValue));
  }

  return values;
};

const createRefsForCategory = defaultMemoize(
  pipe(
    prop('bestPractices'),
    toArray,
    map((x) => [x.id, createRef()]),
    Map,
  ),
);

class BestPracticeCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedRule: null,
      sectionRefs: null,
    };
    this.scrollbarRef = createRef();
  }

  static getDerivedStateFromProps = (props, state) => {
    if (!isNil(props.category)) {
      return { ...state, sectionRefs: createRefsForCategory(props.category) };
    }
    return null;
  };

  handleRuleHover = (rule) => {
    this.setState({ focusedRule: rule }, () => {
      const { category } = this.props;
      const { focusedRule, sectionRefs } = this.state;
      if (focusedRule) {
        const scrollbar = this.scrollbarRef.current;
        const scrollbarOffset = scrollbar.view.getBoundingClientRect().top;
        const bestPracticeId = category.bestPractices.find((bp) =>
          any((r) => r.id === focusedRule)(mapToValuesArray(bp.rules)),
        ).id;

        const sectionOffset = sectionRefs
          .get(bestPracticeId)
          .current.getBoundingClientRect().top;
        const currentScrollTop = scrollbar.getScrollTop();

        scrollbar.scrollTop(currentScrollTop + sectionOffset - scrollbarOffset);
      }
    });
  };

  isRuleFocused = (key) => {
    const { focusedRule } = this.state;
    return key === focusedRule;
  };

  determineOpacity = (key) => {
    const { focusedRule } = this.state;
    return isNil(focusedRule) || this.isRuleFocused(key) ? 1 : 0.5;
  };

  determineStrokeWidth = (key) => {
    const { focusedRule } = this.state;

    return isNil(focusedRule) || !this.isRuleFocused(key) ? 2 : 4;
  };

  riskChartOnCursorChange = (points, props) => {
    if (points && props) {
      const getAllRulesHistoricalRisk = pipe(
        prop('children'),
        map((child) => child.props),
        filter(has('historicalRisk')),
      );

      const cursorDistanceFromRiskLines = new Set(
        getAllRulesHistoricalRisk(props)
          .map((rule) => ({
            id: rule.name,
            values: interpolatePossibleRiskValues(
              rule.historicalRisk,
              points.x,
            ),
          }))
          .map((rule) =>
            rule.values.map((value) => ({
              diff: Math.abs(value.riskScore.score - points.y),
              id: rule.id,
            })),
          )
          .flat(),
      );

      const closestRule = cursorDistanceFromRiskLines.minBy(
        (rule) => rule.diff,
      );

      this.handleRuleHover(closestRule.id);
    }
  };

  riskChartOnMouseOut = () => this.handleRuleHover(null);

  calculateLegendXPosition = () => {
    const { category } = this.props;

    let x = 25;

    const numRulesInCategory = getRulesInCategory(category).length;
    if (numRulesInCategory < 5) {
      // for every rule below 5, add another 15 to the position
      x += 15 * (5 - numRulesInCategory);
    }

    return x;
  };

  calculateSummaryContainerWidth = () => {
    const { category } = this.props;

    const oneRowWidth = 200;
    const numberOfRows = Math.ceil(getRulesInCategory(category).length / 5);

    return pieChartWidth + oneRowWidth * numberOfRows;
  };

  render() {
    const {
      category,
      dateRange,
      isFetching,
      handleDateChange,
      errorFetchingBestPractices,
      riskScores,
      categoryRiskScore,
    } = this.props;
    const { focusedRule, sectionRefs } = this.state;

    const isInitialLoad = isFetching && isNil(category);
    const colorMap = !isNil(category) ? getColorMap(category) : new Map();

    let summaryRiskValue = categoryRiskScore;
    if (!isNil(focusedRule)) {
      const rule = getRulesInCategory(category).find(
        (x) => x.id === focusedRule,
      );
      summaryRiskValue = rule.riskScore.score;
    }

    return (
      <div className={styles.container}>
        <div className={styles.panelLeft}>
          <header className={styles.header}>
            <div className={styles.headerLeft}>
              <h1 className={styles.title}>
                CMS Best Practices: Formulary and Benefit Administration Health
                Check
              </h1>
              <h2 className={styles.category}>{category && category.name}</h2>

              <div className={styles.dateRange}>
                <span className={styles.dateTitle}>Showing results from </span>
                <DateRangePicker
                  classes={{ root: styles.datePicker }}
                  range
                  value={dateRange}
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className={styles.headerRight}>
              <Button onClick={() => window.print()}>PRINT</Button>
            </div>
          </header>

          <div className={styles.content}>
            <Scrollbars ref={this.scrollbarRef}>
              {errorFetchingBestPractices && <UnexpectedError />}
              {!errorFetchingBestPractices && isInitialLoad && (
                <BestPracticeSection isFetching />
              )}
              {!errorFetchingBestPractices &&
                !isInitialLoad &&
                category &&
                category.bestPractices &&
                category.bestPractices.size > 0 &&
                sortBestPracticesByRisk(category.bestPractices).map(
                  (practice) => (
                    <BestPracticeSection
                      key={practice.id}
                      bestPractice={practice}
                      isFetching={isFetching}
                      focusedRule={focusedRule}
                      ref={sectionRefs.get(practice.id)}
                    />
                  ),
                )}
            </Scrollbars>
          </div>
        </div>
        <div className={styles.panelRight}>
          <div className={styles.rightTitle}>Risk Summary</div>
          <div className={styles.panelContent}>
            {!isNil(category) && (
              <div className={styles.riskOverview}>
                <RiskScore
                  className={styles.riskNumber}
                  score={summaryRiskValue}
                />
                {isValidNumber(categoryRiskScore) && categoryRiskScore > 0 && (
                  <VictoryContainer
                    animate
                    height={200}
                    width={this.calculateSummaryContainerWidth()}
                    style={{
                      width: '400px',
                      height: '200px',
                    }}
                  >
                    <VictoryLegend
                      standalone={false}
                      itemsPerRow={5}
                      data={getRulesInCategory(category).map((x) => ({
                        name: x.id,
                      }))}
                      x={pieChartWidth}
                      y={this.calculateLegendXPosition()}
                      style={{
                        data: {
                          opacity: ({ datum }) =>
                            this.determineOpacity(datum.name),
                          fill: ({ datum }) => colorMap.get(datum.name),
                        },
                        labels: {
                          opacity: ({ datum }) =>
                            this.determineOpacity(datum.name),
                        },
                      }}
                    />
                    <VictoryPie
                      standalone={false}
                      animate
                      data={getRulesInCategory(category).map((x) => x.toJS())}
                      labels={() => null}
                      theme={VictoryTheme.material}
                      y="riskScore.score"
                      x="id"
                      width={pieChartWidth}
                      height={200}
                      padding={20}
                      style={{
                        data: {
                          opacity: ({ datum }) =>
                            this.determineOpacity(datum.id),
                          fill: ({ datum }) => colorMap.get(datum.id),
                        },
                      }}
                      events={[
                        {
                          target: 'data',
                          eventHandlers: {
                            onMouseOver: () => {
                              return [
                                {
                                  target: 'data',
                                  mutation: (props) =>
                                    this.handleRuleHover(props.slice.data.id),
                                },
                              ];
                            },
                            onMouseOut: () => {
                              this.handleRuleHover(null);
                            },
                          },
                        },
                      ]}
                    />
                  </VictoryContainer>
                )}
              </div>
            )}
          </div>
          <Divider />
          <div className={styles.rightTitle}>Risk Trends</div>
          <div className={styles.panelContent}>
            {riskScores && (
              <RiskChart
                onCursorChange={this.riskChartOnCursorChange}
                onMouseOut={this.riskChartOnMouseOut}
                isFetching={false}
                style={{ height: 200, width: 600 }}
              >
                {mapToValuesArray(
                  riskScores.map((x, key) => (
                    <RiskLine
                      historicalRisk={x}
                      style={{
                        data: {
                          opacity: () => this.determineOpacity(key),
                          strokeWidth: () => this.determineStrokeWidth(key),
                          stroke: () => colorMap.get(key),
                        },
                      }}
                      name={key}
                      key={key}
                      labelComponent={
                        <VictoryTooltip
                          style={{ fill: 'white' }}
                          flyoutComponent={<Flyout />}
                          active={key === focusedRule || riskScores.size === 1}
                        />
                      }
                    />
                  )),
                )}
              </RiskChart>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BestPracticeCategory.propTypes = {
  category: ImmutablePropTypes.recordOf({
    id: PropTypes.string,
    name: PropTypes.string,
    bestPractices: ImmutablePropTypes.setOf(ImmutablePropTypes.record),
  }),
  dateRange: ImmutablePropTypes.record,
  isFetching: PropTypes.bool.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  errorFetchingBestPractices: PropTypes.string,
  riskScores: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.set,
    PropTypes.string,
  ),
};

export default BestPracticeCategory;
