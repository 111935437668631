import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect, useSelector } from 'react-redux';
import { IssueFilterType } from 'app/filters/constants';
import Authorize from '../../../auth/containers/Authorize';
import { permissions } from '../../../auth/permissions';
import Unauthorized from '../../../common/components/Unauthorized/Unauthorized';
import UnexpectedError from '../../../common/components/UnexpectedError/UnexpectedError';
import {
  getErrors,
  isRunningAny,
  hasCompletedAll,
  isErrored,
} from '../../../ui/selectors';
import { getUserPermissions } from '../../../user/selectors';
import { fetchUsers } from '../../../users/actions';
import { getUsersWithPermission } from '../../../users/selectors';
import { fetchWorkflowDefinitions } from '../../../workflow/actions';
import { getCaseWorkflowDefinitions } from '../../../workflow/selectors';
import { fetchCampaignDetails, addCampaign, editCampaign } from '../../actions';
import CampaignEdit from '../../components/CampaignEdit/CampaignEdit';
import CampaignEditFetching from '../../components/CampaignEdit/CampaignEditFetching';
import { getCampaignByEntityId } from '../../selectors';
import { CampaignIssuesFilterRecord, CampaignRecord } from '../../types';
import { defaultInteractionFields } from './defaultInteractionFields';

const CampaignEditContainer = ({
  id,
  campaign,
  dispatch,
  isFetching,
  isEditing,
  fetchError,
  isSubmitting,
  submitError,
  hasFetched,
  userPermissions,
  campaignOwners,
  campaignAssignees,
}) => {
  // Little hack to dodge previous submit errors showing up
  // TODO replace this with clearing out the ui fetch reducer
  const [hasSaved, setHasSaved] = useState(false);
  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchWorkflowDefinitions());
    if (isEditing) dispatch(fetchCampaignDetails(id));
    setHasSaved(false);
  }, [id]);

  const workflowDefinitions = useSelector((state) =>
    getCaseWorkflowDefinitions(state),
  );

  const handleSave = (c) => {
    const action = isEditing ? editCampaign(c.entityId, c) : addCampaign(c);
    dispatch(action);
    setHasSaved(true);
  };

  const handleCancel = () => {
    navigate('/campaigns');
  };

  return (
    <Authorize
      permissions={[permissions.CAMPAIGN_EDIT, permissions.CAMPAIGN_ADD]}
      renderOnUnauthorize={<Unauthorized />}
    >
      {isFetching && <CampaignEditFetching />}
      {!isFetching && fetchError && <UnexpectedError />}
      {hasFetched && !isFetching && !fetchError && (
        <CampaignEdit
          campaign={campaign}
          isEditing={isEditing}
          onSave={handleSave}
          errors={submitError}
          isSaving={isSubmitting}
          onCancel={handleCancel}
          userPermissions={userPermissions}
          campaignOwners={campaignOwners}
          campaignAssignees={campaignAssignees}
          workflowDefinitions={workflowDefinitions}
        />
      )}
    </Authorize>
  );
};

CampaignEditContainer.propTypes = {
  id: PropTypes.string.isRequired,
  campaign: ImmutablePropTypes.record,
  campaignAssignees: ImmutablePropTypes.setOf(ImmutablePropTypes.record)
    .isRequired,
  campaignOwners: ImmutablePropTypes.setOf(ImmutablePropTypes.record)
    .isRequired,
  userPermissions: ImmutablePropTypes.setOf(PropTypes.string).isRequired,
  workflowDefinitions: ImmutablePropTypes.setOf(ImmutablePropTypes.record)
    .isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool,
  submitError: PropTypes.array.isRequired,
};

CampaignEditContainer.defaultProps = {
  campaign: new CampaignRecord().merge({
    interactionFieldDefinitions: defaultInteractionFields,
    issueFilter: new CampaignIssuesFilterRecord(),
  }),
  fetchError: null,
};

export default connect((state, props) => {
  const campaignEntityId = props.id;
  const isEditing = props.id !== 'create';
  const fetchActions = [fetchUsers(), fetchWorkflowDefinitions()];
  const submitActions = [];
  if (isEditing) {
    submitActions.push(editCampaign(campaignEntityId));
    fetchActions.push(fetchCampaignDetails(campaignEntityId));
  } else {
    submitActions.push(addCampaign());
  }
  return {
    id: campaignEntityId,
    isEditing,
    userPermissions: getUserPermissions(state),
    campaignAssignees: getUsersWithPermission(
      state,
      permissions.CAMPAIGN_ASSIGNEE,
    ),
    campaignOwners: getUsersWithPermission(state, permissions.CAMPAIGN_OWNER),
    campaign: getCampaignByEntityId(state, campaignEntityId),
    isFetching: isRunningAny(state, ...fetchActions),
    hasFetched: hasCompletedAll(state, ...fetchActions),
    fetchError: isErrored(state, ...fetchActions),
    submitError: getErrors(state, ...submitActions),
    hasSubmitted: hasCompletedAll(state, ...submitActions),
    isSubmitting: isRunningAny(state, ...submitActions),
  };
})(CampaignEditContainer);
