import { OrderedSet, Record } from 'immutable';
import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from 'app/common/api';
import { IssueFilterType } from 'app/filters/constants';
import { matchStringsDefaults } from 'app/filters/definitions/defaults';
import { filterTypesQuery } from 'app/filters/queries';
import {
  IFilterConfiguration,
  MultiSelectMatchFilterRecord,
  SelectValueRecord,
  MatchStringsInputRecord,
  MultiSelectMatchStoredValue,
} from 'app/filters/types';

const fetchOptions = (token: string) =>
  fetchGraphqlQuery(
    filterTypesQuery,
    { name: 'MatchDiseaseStateInputType' },
    token,
  )
    .then(throwIfErrors)
    .then(
      (response: {
        data: {
          fields: {
            inputFields: {
              name: string;
              type: {
                type: {
                  type: { enumValues: { name: string; description: string }[] };
                };
              };
            }[];
          };
        };
      }) =>
        response.data.fields.inputFields.find((x) => x.name === 'values')?.type
          .type.type.enumValues,
    )
    .then((values) => {
      if (isNil(values))
        throw new Error('No enum values found for disease state');
      return values;
    })
    .then((enumValues: { name: string; description: string }[]) =>
      OrderedSet(
        enumValues.map(
          (v) =>
            new SelectValueRecord({
              text: v.description,
              value: v.name,
            }),
        ),
      ),
    );

class ExcludeDiseaseStatesConfig
  extends Record({
    ...matchStringsDefaults,
    name: 'excludeDiseaseStates',
    title: 'Exclude Disease States',
    targetType: IssueFilterType.Patient,
    options: (token: string) => fetchOptions(token),
  })
  implements
    IFilterConfiguration<
      MultiSelectMatchFilterRecord,
      OrderedSet<SelectValueRecord>,
      MatchStringsInputRecord,
      MultiSelectMatchStoredValue
    >
{
  get id() {
    return this.name + this.targetType;
  }
}

export default new ExcludeDiseaseStatesConfig();
