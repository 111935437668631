import { Link } from '@reach/router';
import { format } from 'date-fns';
import { descend, isNil, nth, pipe, prop, sort } from 'ramda';
import React from 'react';
import CaseStatus from '../../../../cases/components/CaseStatus';

const formatDate = (date) => (!isNil(date) ? format(date, 'MM/dd/yyyy') : '');
const getMostRecentInteraction = pipe(
  sort(descend(prop('dateContacted'))),
  nth(0),
  prop('dateContacted'),
  formatDate,
);

const columnDefinitions = [
  {
    id: 'CaseNumber',
    name: 'Case Number',
    valueSelector: (c) => c.id,
    render: ({ id, number }) => <Link to={`/cases/${id}`}>{number}</Link>,
    width: 1,
  },
  {
    id: 'Campaign',
    name: 'Campaign',
    valueSelector: (c) => c.campaign.name,
    render: (c) => c.campaign.name,
    width: 1,
  },
  {
    id: 'Status',
    name: 'Status',
    valueSelector: (c) => c.status,
    render: (c) => <CaseStatus status={c.status} />,
    width: 1,
  },
  {
    id: 'dateOpened',
    name: 'Date Opened',
    valueSelector: (c) => c.dateOpened,
    render: (c) => formatDate(c.dateOpened),
    width: 1,
  },
  {
    id: 'interactionCount',
    name: 'Outreach Attempts',
    valueSelector: (c) => c.interactions.length,
    render: (c) => c.interactions.length,
    width: 1,
  },
  {
    id: 'lastInteraction',
    name: 'Last Interaction',
    valueSelector: (c) => getMostRecentInteraction(c.interactions),
    render: (c) => getMostRecentInteraction(c.interactions),
    width: 1,
  },
  {
    id: 'CaseFollowUpDate',
    name: 'Case Follow Up Date',
    valueSelector: (c) => c.followUpDate,
    render: (c) => (c.followUpDate ? formatDate(c.followUpDate) : ''),
    disableSort: true,
    width: 1,
  },
  {
    id: 'ContactFollowUpDate',
    name: 'Contact Follow Up Date',
    valueSelector: (c) => c.target.followUpDate,
    render: (c) =>
      c.target.followUpDate ? formatDate(c.target.followUpDate) : '',
    disableSort: true,
    width: 1,
  },
];

export default columnDefinitions;
