import { Select, MenuItem } from '@material-ui/core';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TextInput from '../../../common/components/TextInput';
import styles from './TextComparison.css';

const OperatorSelector = ({
  currentOperator,
  operators,
  handleOperatorChange,
  hideComparisonOptions,
}) => {
  const menuItems = operators.map((operator) => (
    <MenuItem key={operator.value} value={operator.value}>
      {operator.text}
    </MenuItem>
  ));

  return (
    <Select
      value={currentOperator.value}
      onChange={(event) => handleOperatorChange(event.target.value)}
      autoWidth
      fullWidth
      floatingLabelStyle={{ color: 'black' }}
      style={{
        display: hideComparisonOptions ? 'none' : '',
      }}
    >
      {menuItems}
    </Select>
  );
};

OperatorSelector.propTypes = {
  currentOperator: ImmutablePropTypes.recordOf({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  handleOperatorChange: PropTypes.func.isRequired,
  operators: ImmutablePropTypes.listOf(
    ImmutablePropTypes.recordOf({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  hideComparisonOptions: PropTypes.bool,
};

class TextComparison extends Component {
  static propTypes = {
    value: ImmutablePropTypes.recordOf({
      operator: ImmutablePropTypes.recordOf({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    /** The options for operators */
    options: ImmutablePropTypes.setOf(
      ImmutablePropTypes.recordOf({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ),
    /** Gets called when the user changes a value */
    onChange: PropTypes.func.isRequired,
    inputType: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    hideComparisonOptions: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
    options: Set(),
    hideComparisonOptions: false,
    min: '',
    max: '',
  };

  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = { value };
  }

  handleValueChange = (newValue) => {
    const { onChange, value } = this.props;
    onChange(value.merge({ text: !newValue ? null : newValue }));
  };

  handleOperatorChange = (operator) => {
    const { onChange, value, options } = this.props;
    onChange(
      value.merge({ operator: options.find((v) => v.value === operator) }),
    );
  };

  render() {
    const {
      value,
      options,
      placeholder,
      inputType,
      hideComparisonOptions,
      min,
      max,
    } = this.props;

    return (
      <div className={styles.container}>
        <OperatorSelector
          currentOperator={value.operator}
          operators={options.toList()}
          handleOperatorChange={this.handleOperatorChange}
          hideComparisonOptions={hideComparisonOptions}
        />
        <TextInput
          type={inputType}
          onChange={this.handleValueChange}
          value={this.state.value.text}
          placeholder={placeholder}
          debounced
          min={min}
          max={max}
        />
      </div>
    );
  }
}

export default TextComparison;
