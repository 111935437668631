import {
  WORKITEMS_QUERY_FETCH_NEXT,
  WORKITEMS_QUERY_FETCH_START,
  WORKITEMS_QUERY_FETCH_SUCCESS,
  WORKITEMS_QUERY_START,
  WORKITEMS_QUERY_FETCH_FAIL,
  WORKITEMS_SORT_APPLY,
  WORKITEMS_SORT_DISABLED,
  WORKITEM_DETAILS_FETCH_START,
  WORKITEM_DETAILS_FETCH_SUCCESS,
  WORKITEM_DETAILS_FETCH_FAIL,
  WORKITEM_SET_ASSIGNEE,
  WORKITEM_UPSERT_START,
  WORKITEM_UPSERT_SUCCESS,
  WORKITEM_UPSERT_FAIL,
  WORKITEM_SET_STATUS,
  WORKITEM_STATUSES_FETCH_START,
  WORKITEM_STATUSES_FETCH_SUCCESS,
  WORKITEM_STATUSES_FETCH_FAIL,
  WORKITEMS_QUERY_CLEAR,
} from './constants';

export function fetchWorkItemsNextPage() {
  return { type: WORKITEMS_QUERY_FETCH_NEXT, payload: {} };
}

export function fetchWorkItems() {
  return {
    type: WORKITEMS_QUERY_START,
    payload: {},
  };
}

export function fetchWorkItemsQuery(
  filter,
  sortBy,
  sortOrder,
  continuationToken,
) {
  return {
    type: WORKITEMS_QUERY_FETCH_START,
    payload: { filter, continuationToken, sortBy, sortOrder },
  };
}

export function fetchWorkItemsQuerySuccess(data, isContinuation, elapsedTime) {
  return {
    type: WORKITEMS_QUERY_FETCH_SUCCESS,
    payload: { data, isContinuation },
    meta: { elapsedTime },
  };
}

export function fetchWorkItemsQueryFail(error) {
  return { type: WORKITEMS_QUERY_FETCH_FAIL, payload: { error } };
}

export function applyWorkItemSort(sortBy, sortDirection) {
  return { type: WORKITEMS_SORT_APPLY, payload: { sortBy, sortDirection } };
}

export function notifySortDisabled(columnData) {
  return { type: WORKITEMS_SORT_DISABLED, payload: columnData };
}

export function fetchWorkItemDetails(id) {
  return { type: WORKITEM_DETAILS_FETCH_START, payload: { id } };
}

export function fetchWorkItemDetailsSuccess(data, elapsedTime) {
  return {
    type: WORKITEM_DETAILS_FETCH_SUCCESS,
    payload: { data },
    meta: { elapsedTime },
  };
}

export function fetchWorkItemDetailsFail(id, error) {
  return { type: WORKITEM_DETAILS_FETCH_FAIL, payload: { id, error } };
}

export function setAssignee(workItemId, userId, name) {
  return { type: WORKITEM_SET_ASSIGNEE, payload: { workItemId, userId, name } };
}

export function setStatus(workItemId, status, name) {
  return { type: WORKITEM_SET_STATUS, payload: { workItemId, status, name } };
}

export function upsertWorkItem(workItem, source) {
  return { type: WORKITEM_UPSERT_START, payload: { workItem, source } };
}

export function upsertWorkItemSuccess(data) {
  return { type: WORKITEM_UPSERT_SUCCESS, payload: { data } };
}

export function upsertWorkItemFail(id, source, timestamp, error) {
  return {
    type: WORKITEM_UPSERT_FAIL,
    payload: { id, source, timestamp, error },
  };
}

export function fetchWorkItemStatuses() {
  return { type: WORKITEM_STATUSES_FETCH_START, payload: {} };
}

export function fetchWorkItemStatusesSuccess(statuses) {
  return { type: WORKITEM_STATUSES_FETCH_SUCCESS, payload: { statuses } };
}

export function fetchWorkItemStatusesFail(error) {
  return { type: WORKITEM_STATUSES_FETCH_FAIL, payload: { error } };
}

export function clearWorkItemsQuery() {
  return { type: WORKITEMS_QUERY_CLEAR, payload: {} };
}
