import createSagaMonitor from '@clarketm/saga-monitor';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { channel, buffers } from 'redux-saga';
import { persistStore } from '../hydrate/persist';
import {
  loggingMiddleware,
  setupPrintLogging,
} from '../middleware/logging/logging';
import rootReducer from '../reducer';
import saga from '../saga';
import { getUserId } from '../user/selectors';

function withDevTools(middleware) {
  const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : null;
  if (!devTools) {
    return middleware;
  }
  return compose(middleware, devTools);
}

export default function configureStore(initialState) {
  let sagaMiddleware;
  if (process.env.NODE_ENV !== 'production') {
    sagaMiddleware = createSagaMiddleware({ sagaMonitor: createSagaMonitor() });
  } else {
    sagaMiddleware = createSagaMiddleware();
  }

  const logChannel = channel(buffers.expanding());

  let middleware = applyMiddleware(
    sagaMiddleware,
    loggingMiddleware(logChannel),
  );

  if (process.env.NODE_ENV !== 'production') {
    middleware = withDevTools(middleware);
  }

  const store = middleware(createStore)(rootReducer, initialState);

  persistStore(store, getUserId);
  setupPrintLogging(store);

  sagaMiddleware.run(saga, logChannel);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducer', () => {
      // eslint-disable-next-line global-require, import/newline-after-import
      const nextRootReducer = require('../reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
