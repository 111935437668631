import { isNil } from 'ramda';
import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import { bestPracticeQuery } from './queries';

export function fetchBestPractices(dateRange, token, category = null) {
  const bestPracticeCategories = isNil(category) ? null : [category];

  return fetchGraphqlQuery(
    bestPracticeQuery,
    { dateRange, bestPracticeCategories },
    token,
  )
    .then(throwIfErrors)
    .then((response) => response.data.cmsBestPractices);
}
