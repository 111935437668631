import {
  Divider,
  Menu,
  MenuItem,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import {
  Assignment,
  Notifications,
  MoreVert,
  QuestionAnswer,
  CheckCircle,
  DeveloperBoard,
  Search,
  Phone,
  AssignmentInd,
} from '@material-ui/icons';
import { Link } from '@reach/router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Tooltip } from 'react-tippy';
import Authorize from '../../../auth/containers/Authorize';
import { permissions } from '../../../auth/permissions';
import NotificationPanel from '../../../notifications/containers/NotificationPanel';
import Campaign from '../../images/campaign.svg';
import Rcon from '../../images/rcon.svg';
import RevealLogoWhite from '../../images/reveal-logo-white.svg';
import Avatar from '../Avatar/Avatar';
import Tag from '../Tag';
import styles from './SideBar.css';

const UserContainer = ({ user, onUserMenuButtonClick }) => (
  <div className={styles.userContainer}>
    <Avatar user={user} />
    <div className={styles.userName}>{user.name}</div>
    <IconButton
      className={styles.userOptionsButton}
      onClick={onUserMenuButtonClick}
    >
      <MoreVert className={styles.userOptionsIcon} />
    </IconButton>
  </div>
);

UserContainer.propTypes = {
  user: PropTypes.object.isRequired,
  onUserMenuButtonClick: PropTypes.func.isRequired,
};

const UserContainerMinimized = ({ onUserMenuButtonClick, user }) => (
  <div className={styles.userContainer}>
    <IconButton onClick={onUserMenuButtonClick}>
      <Avatar user={user} />
    </IconButton>
  </div>
);

UserContainerMinimized.propTypes = {
  user: PropTypes.object.isRequired,
  onUserMenuButtonClick: PropTypes.func.isRequired,
};

const UserMenu = ({
  isUserMenuOpen,
  onSignOut,
  sideBarRef,
  onRequestClose,
}) => (
  <Menu
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    anchorEl={sideBarRef.current}
    getContentAnchorEl={null}
    open={isUserMenuOpen}
    onClose={onRequestClose}
  >
    <MenuItem onClick={onSignOut}>Logout</MenuItem>
  </Menu>
);

UserMenu.propTypes = {
  isUserMenuOpen: PropTypes.bool.isRequired,
  onSignOut: PropTypes.func.isRequired,
  sideBarRef: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

const SideBar = ({
  isMinimized,
  onToggleMinimize,
  onSignOut,
  user,
  notificationCount,
  onNotificationPanelRequestClose,
  onNotificationPanelRequestOpen,
  isNotificationPanelOpen,
  onSearchClick,
}) => {
  const sideBarRef = useRef();
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);

  const handleUserMenuButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // TODO make this crossbrowser compatible
    if (event.nativeEvent.which !== 3) {
      setUserMenuOpen(true);
    }
  };

  const handleNotificationClick = () => {
    if (isNotificationPanelOpen) {
      onNotificationPanelRequestClose();
    } else {
      onNotificationPanelRequestOpen();
    }
  };

  const handleRequestClose = () => {
    setUserMenuOpen(false);
  };

  const feedbackUrl = `mailto:reveal_support@hds-rx.com?Subject=Reveal Customer Feedback&body=Tell us how we can help below!%0D%0A%0D%0A%0D%0A%0D%0A-------------------------------------------------------%0D%0APlease do not delete the following debugging information:%0D%0A${user.id}`;

  const height = 350;
  const notificationPanelStyles = useSpring({
    config: { tension: 250, friction: 20, clamp: true },
    to: isNotificationPanelOpen
      ? {
          height,
          width: 328,
          opacity: 1,
        }
      : {
          height: 0,
          width: 0,
          opacity: 0,
        },
  });

  return (
    <div
      className={
        isMinimized ? styles.containerMinimized : styles.containerMaximized
      }
      ref={sideBarRef}
      // onClick={onToggleMinimize}
    >
      <div className={styles.branding}>
        {isMinimized && (
          <Link className={styles.brandingIcon} to="/">
            <Rcon />
          </Link>
        )}
        {!isMinimized && <RevealLogoWhite className={styles.brandingIcon} />}
      </div>
      <Divider className={styles.divider} />
      <div className={styles.itemContainer}>
        <Tooltip arrow title="Work Items" position="right" trigger="mouseenter">
          <Link
            getProps={({ isCurrent }) => ({
              className: classnames({
                [styles.item]: true,
                [styles.selected]: isCurrent,
              }),
            })}
            to="/workitems"
          >
            <Assignment />
            <div className={styles.itemText}>Work Items</div>
          </Link>
        </Tooltip>
        <Authorize permissions={permissions.CAMPAIGN_ACCESS}>
          <Tooltip arrow title="Queue" position="right" trigger="mouseenter">
            <Link
              getProps={({ isCurrent }) => ({
                className: classnames({
                  [styles.item]: true,
                  [styles.selected]: isCurrent,
                }),
              })}
              to="/queue"
            >
              <Phone />
              <div className={styles.itemText}>Queue</div>
            </Link>
          </Tooltip>
        </Authorize>
        <Authorize permissions={permissions.CAMPAIGN_ACCESS}>
          <Tooltip arrow title="Cases" position="right" trigger="mouseenter">
            <Link
              getProps={({ isCurrent }) => ({
                className: classnames({
                  [styles.item]: true,
                  [styles.selected]: isCurrent,
                }),
              })}
              to="/cases"
            >
              <AssignmentInd />
              <div className={styles.itemText}>Cases</div>
            </Link>
          </Tooltip>
        </Authorize>
        <Authorize permissions={permissions.CAMPAIGN_ACCESS}>
          <Tooltip
            arrow
            title="Campaigns"
            position="right"
            trigger="mouseenter"
          >
            <Link
              getProps={({ isCurrent }) => ({
                className: classnames({
                  [styles.item]: true,
                  [styles.selected]: isCurrent,
                }),
              })}
              to="/campaigns"
            >
              <SvgIcon>
                <Campaign />
              </SvgIcon>
              <div className={styles.itemText}>Campaigns</div>
            </Link>
          </Tooltip>
        </Authorize>
        <Tooltip
          arrow
          title="Health Check"
          position="right"
          trigger="mouseenter"
        >
          <Link
            getProps={({ isCurrent }) => ({
              className: classnames({
                [styles.item]: true,
                [styles.selected]: isCurrent,
              }),
            })}
            to="/best-practices"
          >
            <CheckCircle />
            <div className={styles.itemText}>Health Check</div>
          </Link>
        </Tooltip>
        <Tooltip arrow title="Search" position="right" trigger="mouseenter">
          <button
            type="button"
            className={classnames(styles.button, styles.item)}
            onClick={onSearchClick}
          >
            <Search />
            <div className={styles.itemText}>Search</div>
          </button>
        </Tooltip>
        <Authorize permissions={permissions.GRAPHIQL_ACCESS}>
          <Tooltip
            arrow
            title="API Explorer"
            position="right"
            trigger="mouseenter"
          >
            <Link
              getProps={({ isCurrent }) => ({
                className: classnames({
                  [styles.item]: true,
                  [styles.selected]: isCurrent,
                }),
              })}
              to="/graphiql"
            >
              <DeveloperBoard />
              <div className={styles.itemText}>API Explorer</div>
            </Link>
          </Tooltip>
        </Authorize>
      </div>
      <div className={styles.itemContainerFooter}>
        <Tooltip
          arrow
          title="Notifications"
          position="right"
          trigger="mouseenter"
          disabled={isNotificationPanelOpen}
        >
          <button
            type="button"
            className={classnames(styles.button, styles.item)}
            onClick={handleNotificationClick}
          >
            {notificationCount > 0 && (
              <Tag
                className={styles.notificationTag}
                text={notificationCount}
              />
            )}
            <Notifications className={styles.notification} />
            <div className={styles.itemText}>Notifications</div>
          </button>
        </Tooltip>
        <Tooltip arrow title="Feedback" position="right" trigger="mouseenter">
          <a className={styles.item} href={feedbackUrl}>
            <QuestionAnswer />
            <div className={styles.itemText}>Feedback</div>
          </a>
        </Tooltip>
      </div>
      <div className={styles.footer}>
        {!isMinimized && (
          <UserContainer
            user={user}
            onUserMenuButtonClick={handleUserMenuButtonClick}
          />
        )}
        {isMinimized && (
          <UserContainerMinimized
            user={user}
            onUserMenuButtonClick={handleUserMenuButtonClick}
          />
        )}
      </div>
      <UserMenu
        isUserMenuOpen={isUserMenuOpen}
        onSignOut={onSignOut}
        sideBarRef={sideBarRef}
        onRequestClose={handleRequestClose}
      />
      <animated.div
        className={styles.notificationPanelContainer}
        style={notificationPanelStyles}
      >
        <NotificationPanel
          className={styles.notificationPanel}
          onRequestClose={onNotificationPanelRequestClose}
        />
      </animated.div>
    </div>
  );
};

SideBar.propTypes = {
  isMinimized: PropTypes.bool.isRequired,
  onToggleMinimize: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  notificationCount: PropTypes.number.isRequired,
  isNotificationPanelOpen: PropTypes.bool.isRequired,
  onNotificationPanelRequestClose: PropTypes.func.isRequired,
  onNotificationPanelRequestOpen: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default SideBar;
