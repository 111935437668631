import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './FilterTitle.css';

const FilterTitle = ({ title, onSetEditing, isEditing }) => (
  <div
    className={styles.titleContainer}
    onClick={() => onSetEditing(!isEditing)}
  >
    <div className={styles.title}>{title}</div>
    <div className={styles.minimize}>
      {isEditing && <KeyboardArrowUp />}
      {!isEditing && <KeyboardArrowDown />}
    </div>
  </div>
);

FilterTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onSetEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default FilterTitle;
