import { fetchGraphqlQuery, throwIfErrors } from '../common/api';
import { patientClaimsHistoryQuery, patientDrugHistoryQuery } from './queries';

export function fetchPatientHistory(filters, token) {
  return fetchGraphqlQuery(patientClaimsHistoryQuery, filters, token)
    .then(throwIfErrors)
    .then((response) =>
      response.data.prior.claims.concat(response.data.future.claims),
    );
}

export function fetchPatientDrugHistory(filters, token) {
  return fetchGraphqlQuery(patientDrugHistoryQuery, { filters }, token)
    .then(throwIfErrors)
    .then((response) => response.data.claims.claims);
}
