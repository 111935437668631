export const SIDEBAR_MINIMIZE = 'UI/SIDEBAR_MINIMIZE';
export const SIDEBAR_EXPAND = 'UI/SIDEBAR_EXPAND';
export const FILTER_SIDEBAR_MINIMIZE = 'UI/FILTER_SIDEBAR_MINIMIZE';
export const FILTER_SIDEBAR_EXPAND = 'UI/FILTER_SIDEBAR_EXPAND';
export const SNACKBAR_CLOSE = 'UI/SNACKBAR_CLOSE';
export const WORKITEM_LIST_SCROLL_TOP_CHANGE =
  'UI/WORKITEM_LIST_SCROLL_TOP_CHANGE';
export const NOTIFICATION_PANEL_OPEN = 'UI/NOTIFICATION_PANEL_OPEN';
export const NOTIFICATION_PANEL_CLOSE = 'UI/NOTIFICATION_PANEL_CLOSE';

export const WORKITEMLIST_FILTER_SET_APPLIED =
  'UI/WORKITEMLIST_FILTER_SET_APPLIED';
export const WORKITEMLIST_FILTER_SET_EDITED =
  'UI/WORKITEMLIST_FILTER_SET_EDITED';
export const WORKITEMLIST_FILTERS_CLEAR = 'UI/WORKITEMLIST_FILTERS_CLEAR';
export const WORKITEMLIST_FILTERS_UPDATE = 'UI/WORKITEMLIST_FILTERS_UPDATE';

export const WORKITEMLIST_FILTER_FETCH_OPTIONS_START =
  'UI/WORKITEMLIST_FILTER_OPTIONS_FETCH_START';
export const WORKITEMLIST_FILTER_FETCH_OPTIONS_SUCCESS =
  'UI/WORKITEMLIST_FILTER_OPTIONS_FETCH_SUCCESS';
export const WORKITEMLIST_FILTER_FETCH_OPTIONS_FAIL =
  'UI/WORKITEMLIST_FILTER_OPTIONS_FETCH_FAIL';

export const CAMPAIGN_EDIT_FILTER_SET_APPLIED =
  'UI/CAMPAIGN_EDIT_FILTER_SET_APPLIED';
export const CAMPAIGN_EDIT_FILTER_SET_EDITED =
  'UI/CAMPAIGN_EDIT_FILTER_SET_EDITED';
export const CAMPAIGN_EDIT_FILTER_UPDATE = 'UI/CAMPAIGN_EDIT_FILTER_UPDATE';
export const CAMPAIGN_EDIT_FILTERS_CLEAR = 'UI/CAMPAIGN_EDIT_FILTERS_CLEAR';

export const CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_START =
  'UI/CAMPAIGN_EDIT_FILTER_OPTIONS_FETCH_START';
export const CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_SUCCESS =
  'UI/CAMPAIGN_EDIT_FILTER_OPTIONS_FETCH_SUCCESS';
export const CAMPAIGN_EDIT_FILTER_FETCH_OPTIONS_FAIL =
  'UI/CAMPAIGN_EDIT_FILTER_OPTIONS_FETCH_FAIL';
