import { Paper } from '@material-ui/core';
import { Add, Call } from '@material-ui/icons';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  Skeleton,
} from '@material-ui/lab';
import classnames from 'classnames';
import { format } from 'date-fns';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import {
  T,
  always,
  cond,
  defaultTo,
  filter,
  isEmpty,
  isNil,
  isNotNil,
  nth,
  pipe,
  prop,
  propEq,
  sortBy,
} from 'ramda';
import { useSelector } from 'react-redux';
import { useSpring, animated, config } from 'react-spring';
import ErrorBoundary from 'app/common/components/ErrorBoundary/ErrorBoundary';
import { InteractionStatus } from 'app/interactions/types';
import {
  getCompletedInteractions,
  isPatientRecord,
  isPharmacyRecord,
  isPrescriberRecord,
} from 'app/interactions/utility';
import { isSessionActive } from 'app/session/utility';
import Avatar from '../../../common/components/Avatar';
import { graphqlSelector } from '../../../common/hooks/useQuery';
import SupportAgent from '../../../common/images/supportAgent.svg';
import { mapIndexed } from '../../../common/utilities/generic';
import graphql from '../../../common/utilities/graphql';
import {
  CASE_LEVEL_RESPONSE,
  INTERACTION_LEVEL_RESPONSE,
} from '../../constants';
import styles from './Timeline.css';

const TimelineLoading = () => (
  <div className={styles.loading}>
    {new Array(Math.floor(Math.random() * 3 + 1)).fill('').map((x, i) => (
      <div className={styles.loadingInteraction} key={`loading-${i}`}>
        <Skeleton
          variant="circle"
          width={50}
          height={50}
          className={styles.loadingLeft}
        />
        <Skeleton
          variant="rect"
          width="80%"
          height={100}
          className={styles.loadingRight}
        />
      </div>
    ))}
  </div>
);

const getContactOutcomeStyle = (outcome) => {
  switch (outcome) {
    case 'Reached':
    case 'Reached - Needs Call Back':
      return styles.success;
    case 'Not Reached':
      return styles.failure;
    default:
      return;
  }
};

const InteractionItem = ({
  date,
  title,
  author,
  description,
  responses,
  drawConnector,
  isActive = false,
}) => {
  // TODO:  How do we want to define what is success and what is not?
  // This is super hacky.
  const contactOutcome = responses?.find((x) => x.name === 'Contact Outcome');

  const activeProps = useSpring({
    from: { opacity: 0.5 },
    to: [{ opacity: 1 }, { opacity: 0.5 }],
    loop: true,
    config: config.slow,
  });

  return (
    <TimelineItem
      classes={{
        missingOppositeContent: styles.missingOppositeContent,
      }}
    >
      <TimelineSeparator>
        <animated.div style={isActive ? activeProps : {}}>
          <TimelineDot
            classes={{
              root: classnames(
                styles.timelineDot,
                getContactOutcomeStyle(contactOutcome?.value),
                isActive ? styles.active : null,
              ),
            }}
          >
            {(() => {
              if (title.startsWith('Called')) {
                return <Call />;
              }

              if (title === 'Case Created') {
                return <Add />;
              }
              if (isActive) {
                return <SupportAgent />;
              }
              return <></>;
            })()}
          </TimelineDot>
        </animated.div>
        {drawConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent classes={{ root: styles.contentRoot }}>
        <Paper classes={{ root: styles.content }} elevation={3}>
          <div className={styles.contentHeader}>
            <div className={styles.headerLeft}>
              <div className={styles.contentTitle}>{title}</div>
              <div className={styles.timestamp}>
                {format(date, `MM/dd/yyyy 'at' h:mm a`)}
              </div>
            </div>
            {!isNil(author) && (
              <div className={styles.author}>
                <Avatar classes={{ root: styles.avatar }} user={author} />
              </div>
            )}
          </div>
          <div>
            <div className={styles.timelineContentRow}>{description}</div>
            {!isNil(responses) &&
              responses.map((r) => (
                <div key={r.name} className={styles.timelineContentRow}>
                  <div className={styles.field}>{r.name}:</div>
                  {/* TODO THIS IS MEGA BAD */}
                  {r.name.includes('Notes') && (
                    <Editor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(JSON.parse(r.value)),
                      )}
                      readOnly
                    />
                  )}
                  {/* TODO AHHHHHHH */}
                  {!r.name.includes('Notes') && r.value}
                </div>
              ))}
          </div>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

InteractionItem.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string,
  }),
  description: PropTypes.string,
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  drawConnector: PropTypes.bool.isRequired,
};

InteractionItem.defaultProps = {
  responses: null,
  author: null,
  description: null,
};

const InteractionTimeline = ({ activeCaseId, isLoading }) => {
  const query = graphql`
    query case($id: ID) {
      case(id: $id) {
        id
        dateOpened
        caseSessions {
          sessionStart
          status
          user {
            id
            name
            email
            pictureUrl
          }
        }
        interactions {
          id
          dateContacted
          status
          user {
            id
            name
            email
            pictureUrl
          }
          responses {
            caseId
            level
            name
            value
          }
          contact {
            __typename
          }
        }
        campaign {
          name
        }
      }
    }
  `;

  const { case: activeCase } = useSelector((state) =>
    graphqlSelector(state, query, { id: activeCaseId }),
  );

  const renderTitle = cond([
    [isPatientRecord, always('Called Member')],
    [isPrescriberRecord, always('Called Prescriber')],
    [isPharmacyRecord, always('Called Pharmacy')],
    [T, ''],
  ]);

  const activeSession = pipe(
    prop('caseSessions'),
    filter(isSessionActive),
    nth(0),
  )(activeCase);

  const userWithActiveSession = pipe(
    defaultTo({ user: null }),
    prop('user'),
  )(activeSession);

  const completedInteractions = getCompletedInteractions(
    activeCase?.interactions,
  );

  return (
    <ErrorBoundary>
      {isLoading && <TimelineLoading />}
      {isLoading === false && (
        <Timeline classes={{ root: styles.root }}>
          <InteractionItem
            title="Case Created"
            date={activeCase.dateOpened}
            description={activeCase.campaign.name}
            drawConnector={
              !isEmpty(completedInteractions) || isNotNil(userWithActiveSession)
            }
          />
          {pipe(
            sortBy(prop('dateContacted')),
            mapIndexed((item, index) => (
              <InteractionItem
                key={item.id}
                date={item.dateContacted}
                title={renderTitle(item.contact)}
                author={item.user}
                responses={item.responses
                  .filter(
                    (r) =>
                      r.caseId === activeCase.id ||
                      r.level === INTERACTION_LEVEL_RESPONSE,
                  )
                  .sort((a, b) => {
                    if (
                      a.level === CASE_LEVEL_RESPONSE &&
                      b.level === INTERACTION_LEVEL_RESPONSE
                    )
                      return 1;
                    if (
                      a.level === INTERACTION_LEVEL_RESPONSE &&
                      b.level === CASE_LEVEL_RESPONSE
                    )
                      return -1;
                    return 0;
                  })}
                drawConnector={
                  index !== completedInteractions.length - 1 ||
                  isNotNil(userWithActiveSession)
                }
              />
            )),
          )(completedInteractions)}
          {isNotNil(userWithActiveSession) && (
            <InteractionItem
              key="active-outreach-session"
              title="Currently Outreaching..."
              date={activeSession.sessionStart}
              author={userWithActiveSession}
              isActive
            />
          )}
        </Timeline>
      )}
    </ErrorBoundary>
  );
};

InteractionTimeline.propTypes = {
  activeCaseId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

InteractionTimeline.defaultProps = {
  activeCaseId: null,
};

export default InteractionTimeline;
